import ControlModel from "../../models/controlModel";
import { MODEL_CONTROL_TYPE } from "../../utils/enums";

export function createAddControlModel({ ...args }) {
  return new ControlModel({
    isLayout: args.isLayout || false,
    url: args.url || "",
    type: MODEL_CONTROL_TYPE.add,
    text: args.text,
    openCourierShiftPopup: args.openCourierShiftPopup,
    openDateRange: args.openDateRange
  });
}
