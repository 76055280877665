import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import FreshChat from 'react-freshchat';
import environment from "environment";
import classNames from "classnames";
import "./index.scss";

import InfoMessageService from '../../../services/infoMessage';
import User from '../../../services/user';
import ChatService from '../../../services/chatService';

import IconButton from '../IconButton';

class FreshChatControl extends Component {
  componentWillUnmount() {
    ChatService.instance.destroyChatWidget();
  }

  handleOnInit = widget => {
    if(widget) {
      ChatService.instance.setChatWidget(widget);
    }
  }

  handleOnClick = () => {
    return ChatService.instance.openChatWidget();   
  }

  getInitConfig = () => {
    return {
      token: environment.freshChatToken,
      config: { headerProperty: { hideChatButton: true }},
      ...ChatService.instance.getInitConfiguration(),
      onInit: this.handleOnInit
    };
  }
  
  render() {
    const { t, showSupportChat, currentUser, notification } = this.props;
    const isShow = InfoMessageService.instance.checkAction();

    return ((showSupportChat || isShow)
        ? <div className={classNames("freshChatButton", { info: isShow, notification: notification })}>
            <IconButton isIcon={true} iconName="freshChat" tooltipText={t("header.SUPPORT_CHAT_LABEL")} onClick={this.handleOnClick} />
            {currentUser && (<FreshChat {...this.getInitConfig()} />)}
        </div>
        : <span></span>
      )
  }
}

const mapStateToProps = state => {
  const currentUser = User.instance.getCurrentUserInfo();
  return {
    currentUser,
    showSupportChat: (state.restaurant.data.configuration || {}).showSupportChat,    
    notification: !!state.notifications.data.accountTopNotification
  };
};

export default connect(mapStateToProps)(withTranslation()(FreshChatControl));
