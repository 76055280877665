import { CUSTOMERS_UPDATE, CUSTOMER_REVIEWS_UPDATE, CUSTOMER_UPDATE } from "./customer-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    reviews: []
};

export default function customer(state = initState, action) {
    switch (action.type) {
    case REMOVE_ALL_DATA:
        return initState;
    case CUSTOMER_REVIEWS_UPDATE:
        return {
            ...state,
            reviews: action.data
        };
    case CUSTOMERS_UPDATE:
        return {
            ...state,
            customers: action.data
        };
    case CUSTOMER_UPDATE:
        if(!action.data || !action.data.consumerId) return state;
        return {
            ...state,
            customers: (state.customers || []).map(customer=> customer.consumerId === action.data.consumerId
                ? { ...customer, ...action.data }
                : customer
            )
        };
    default:
        return state;
    }
}