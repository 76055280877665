import i18n from "../../i18n";

import SectionModel from "../../models/sectionModel";
import {CONTROL_TYPE, MARKETPLACE_SETTINGS_MODEL, MODEL_CONTROL_TYPE, MODEL_TYPE, TYPE_ACTION} from "../../utils/enums";
import { getInitModel } from "../initModels";

import { GIFT_CARD, GIFT_CARD_GET, GIFT_CARD_PRODUCT } from '../../store/reducers/restaurant/restaurant-actions';
import {createControlModel} from "../controlModelFactory";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {
  getLoyaltyGiftCardProductNameById,
  isMobileWidth,
} from '../restaurant';
import { getHistoryLocationParams } from '../../utils/routesHelper.js';

export function createGiftCardSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.giftCard.ENABLED_LABEL",
        accessor: "enabled",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "",
        accessor: "header",
        settingsAaccessor: "header_settings",
        isEditable: true,
        isShow: data=> data.enabled,
        type: CONTROL_TYPE.imageEdit
      },
      {
        title: "settings.orderingmenu.MARKETPLACE_GIFTCARDS_OPTIONS_LABEL",
        accessor: "name",
        isEditable: true,
        isShow: data=> data.enabled,
        type: CONTROL_TYPE.text
      },      
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_EMAIL_LABEL",
        accessor: "email",
        isEditable: true,
        isShow: data=> data.enabled,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_PHONE_LABEL",
        accessor: "phone",
        isEditable: true,
        isShow: data=> data.enabled,
        type: CONTROL_TYPE.text
      },
      {
        title: "new-order-directive.ADDRESS_LABEL",
        accessor: "address",
        isEditable: true,
        isShowIcon: true,
        withEmpty: true,
        isShow: data=> data.enabled,
        type: CONTROL_TYPE.address
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTIONS_LABEL",
        accessor: "products",
        model: new SectionModel({
          isDraggable: true,
          typeModel: MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts,
          actionName: GIFT_CARD_PRODUCT,
          columns: [
            {
              title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_NAME_LABEL",
              accessor: "name",
              width: "200px",
              url: item=> MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit + "?id=" + item.id,
              type: CONTROL_TYPE.linkControl
            },            
            {
              title: "",
              accessor: "enabled",
              label: {
                onLabel: "settings.giftCard.MENU_BUSINESS_PRODUCT_ENABLED_LABEL",
                offLabel: "settings.giftCard.MENU_BUSINESS_PRODUCT_DISABLED_LABEL",
              },
              type: CONTROL_TYPE.switch,
              isEditable: true,
              width: "100px"
            },
            {
              title: '',
              width: "40px",
              type: CONTROL_TYPE.link,
              url: item=> MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit + "?id=" + item.id,
              iconName: "editItem",
              tooltipText: i18n.t("settings.giftCard.MENU_BUSINESS_PRODUCT_EDIT_LABEL")
            }
          ]
        }),
        isEditable: true,
        isFillContent: true,
        newItemUrl: MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsNew,
        isShowNewItemControl: true,
        isShow: data=> data.enabled,
        type: CONTROL_TYPE.editTable
      }
    ],
    actionName: GIFT_CARD,
    actionLoad: GIFT_CARD_GET,
    initNewItemModel: item => item,
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: MARKETPLACE_SETTINGS_MODEL.giftCard,
    saveControlsConfig: {
      cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
    },
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: "header.menu.MARKETPLACE_SETTINGS",
      }),
      createBreadcrumbModel({
        label: "settings.marketplaceMessage.LOYALTY",
      }),
    ],
    label: "settings.giftCard.MENU_LABEL",
    mappingLambda: state => {
      const businessMenu = (state.restaurant.data.giftCard && state.restaurant.data.giftCard.businessMenu) || {};
      return ({
        id: "giftCard",
        ...businessMenu,
        enabled: state.restaurant.data.giftCard && state.restaurant.data.giftCard.enabled,
        products: ((businessMenu.categories || [])[0] || {})["products"] || [],
        address: { 
          address: {
            city: businessMenu.address_city,
            street: businessMenu.address,
          },
          location: {
            latitude: businessMenu.location && (businessMenu.location.latitude || businessMenu.location.lat),
            longitude: businessMenu.location && (businessMenu.location.longitude || businessMenu.location.lng)
          }
        }
      });
    }
  });
}

export function createGiftCardProductSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "",
        accessor: "images",
        settingsAaccessor: "image_settings",
        isEditable: true,
        type: CONTROL_TYPE.imageEdit
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_ENABLED_LABEL",
        accessor: "enabled",
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_NAME_LABEL",
        accessor: "name",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_LABEL",
        accessor: "price",
        isEditable: true,
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_SKU",
        accessor: "sku",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_BEFORE_DISCOUNT",
        accessor: "price_before_discount",
        isEditable: true,
        type: CONTROL_TYPE.number,
        useColorRed: (item) => item.price_before_discount < item.price && item.price_before_discount
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_MAX",
        accessor: "max",
        isEditable: true,
        type: CONTROL_TYPE.number,
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_DESCRIPTION_LABEL",
        accessor: "description",
        isEditable: true,
        type: CONTROL_TYPE.textArea
      }
    ],
    actionLoad: GIFT_CARD_GET,
    actionName: GIFT_CARD_PRODUCT,
    initNewItemModel: item => getInitModel(MODEL_TYPE.categoryProductModel, item),
    typeAction: args.typeAction,
    typeModel: args.typeAction === TYPE_ACTION.edit
        ? MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit
        : MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsNew,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.giftCard,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: "header.menu.MARKETPLACE_SETTINGS",
      }),
      createBreadcrumbModel({
        label: "settings.marketplaceMessage.LOYALTY",
      }),
      createBreadcrumbModel({
        label: "settings.giftCard.MENU_LABEL",
      }),
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return args.typeAction === TYPE_ACTION.edit ? getLoyaltyGiftCardProductNameById(id) : i18n.t("settings.giftCard.NEW_GIFT_CARD");
    },
    mappingLambda: state => {
      const businessMenu = (state.restaurant.data.giftCard && state.restaurant.data.giftCard.businessMenu) || {};
      return ((businessMenu.categories || [])[0] || {})["products"] || [];
    }
  });
}
