export const providerModel = (item = {}) => {

    let enableSchedule = false;
    if(item && item.autoAssignmentSchedule) {
        item.autoAssignmentSchedule.forEach((element) => {
            if(element.lapses.length > 0) {
                enableSchedule = true;
            }
        });
    }

    if(item.enableSchedule) {
        enableSchedule = true;
    }

    return {
    id: item.id || "",
    provider: item.provider || 0,
    providerName: item.providerName || "",
    enabled: item.enabled || false,
    priority: item.priority || 0,
    venueId: item.venueId || "",
    virtual: item.virtual || false,
    virtualAutomaticDelivery: item.virtualAutomaticDelivery || 0,
    autoAssignmentTimeout: item.autoAssignmentTimeout || 0,
    autoAssignmentRetries: item.autoAssignmentRetries || 0,
    autoAssignment: item.autoAssignment || false,
    order: item.order || item.priority || 0,
    password: item.password || "",
    isDirty: false,
    refreshedValue: null,
    autoAssignmentRegionIds: item.autoAssignmentRegionIds || [],
    enableSchedule: enableSchedule,
    autoAssignmentSchedule: item.autoAssignmentSchedule || null,
    user: item.user || "",
}}

export const providersApiMap = (providers, regions, id) => {
    const currentProvider = providers.find(f => f.id === id) || {};
    if(currentProvider && currentProvider.autoAssignmentRegionIds &&  currentProvider.autoAssignmentRegionIds.length > 0) {
        currentProvider.autoAssignmentRegionIds = currentProvider.autoAssignmentRegionIds.map((e) => {
            if(regions) {
                const currentRegion = regions.find((region) => region.id === e);
                if(currentRegion) {
                    return currentRegion.name;
                }
            }
            return e;
        });
    }
    return currentProvider
}

export const transformProvidersToApi = (data, regions) => {
    return data.AutoAssignmentRegionIds.map((region) => {
        const currentRegion = regions.find((e) => e.name === region);
        return currentRegion ? currentRegion.id : region
    });
}
