import React from 'react';

import SectionModel from '../../models/sectionModel';
import {
    COURIER_DELIVERY_FEES_SETTING,
    COURIER_DELIVERY_FEES_SETTING_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    getMerchantDeliveryCostCalculationModeItems,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    PRICE_CALCULATION_MODE,
    TYPE_SECTION_MODEL,
} from '../../utils/enums';
import { getInitModel } from '../initModels';

import RegionDeliveryPrice from '../../app/components/RegionDeliveryPrice';
import { isEmptyMode} from '../../utils/objects-util';
import { getRegions, isMobileWidth } from '../restaurant';
import { createControlModel } from '../controlModelFactory';
import { createBreadcrumbModel } from '../createBreadcrumbModel';

export function createCourierDeliveryFeesSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.map.COURIER_PRICE_CALCULATION_MODE',
                accessor: 'calculationMode',
                isEditable: true,
                connect: () => ({
                    values: getMerchantDeliveryCostCalculationModeItems(),
                }),
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.map.GENERAL_DELIVERY_PRICE',
                accessor: 'maxDeliveryPrice',
                isEditable: true,
                inlineType: 'inlineOdd',
                isShow: value =>
                    !isEmptyMode(value.calculationMode) &&
                    value.calculationMode === PRICE_CALCULATION_MODE.fixedRegionBased,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.personalSettings.shipmentPriceSettings.LABEL',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.personalSettings.shipmentPriceSettings.BASIS_PRICE',
                        accessor: 'basisPrice',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.personalSettings.shipmentPriceSettings.BASIS_DISTANCE_IN_METERS',
                        accessor: 'basisDistanceInMeters',
                        isEditable: true,
                        inlineType: 'inlineEven',
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.personalSettings.shipmentPriceSettings.PRICE_PER_KM',
                        accessor: 'pricePerKm',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.personalSettings.shipmentPriceSettings.MAX_DELIVERY_PRICE',
                        accessor: 'maxDeliveryPrice',
                        isEditable: true,
                        inlineType: 'inlineEven',
                        type: CONTROL_TYPE.number,
                    },
                ],
                isShow: value =>
                    !isEmptyMode(value.calculationMode) &&
                    value.calculationMode !== PRICE_CALCULATION_MODE.fixedRegionBased,
            },
            {
                title: 'settings.map.DEPOT_DELIVERY_PRICE_PERREGION_HEADER_LABEL',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                isShow: value =>
                    !isEmptyMode(value.calculationMode) && value.calculationMode !== PRICE_CALCULATION_MODE.distanceBased,
                items: [
                    {
                        accessor: 'regionConfigurations',
                        itemValue: {
                            key: 'id',
                            component: function(item, onChange, onDelete) {
                                return (
                                    <RegionDeliveryPrice
                                        item={item}
                                        handleOnChange={onChange}
                                        handleOnDeleteItem={onDelete}
                                    />
                                );
                            },
                        },
                        isEditable: true,
                        type: CONTROL_TYPE.listItem,
                    },
                    {
                        title: 'settings.users.DEPOT_USERS_ADD_BUTTON',
                        accessor: 'regionConfigurations',
                        values: function(item) {
                            const selectedRegions = (item && item.regionConfigurations).map(m => m.id);
                            return getRegions()
                                .filter(f => !selectedRegions.includes(f.id))
                                .map(region => ({ ...region, key: region.id, value: region.name }));
                        },
                        isEditable: true,
                        type: CONTROL_TYPE.selectWithAdd,
                    },
                ],
            },
        ],
        actionName: COURIER_DELIVERY_FEES_SETTING,
        actionLoad: COURIER_DELIVERY_FEES_SETTING_GET,
        initNewItemModel: item => getInitModel(MODEL_TYPE.courierDeliveryFees, item),
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: TYPE_SECTION_MODEL.courierDeliveryFees,
        saveControlsConfig: {
            cancel: { title: 'basic.RESET_BUTTON', action: () => {}, isInit: true },
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        label: 'settings.courierDeliveryFeesSettings.LABEL',
        breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SETTINGS_LABEL' })],
        mappingLambda: state => {
            return {
                id: 'courierDeliveryFees',
                ...state.restaurant.data.shipmentPriceSettings,
            };
        },
    });
}
