import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";

export function depotServerModel(data) {
  return {
    Id: data.id,
    Name: data.name,
    Address: {
      Address: getInitModel(
        MODEL_TYPE.addressServerModel,
        data.address.address
      ),
      Location: getInitModel(
        MODEL_TYPE.locationServerModel,
        data.address.location
      )
    },
    Phone: data.phone,
    MaximumArrivalTime: data.maximumArrivalTime,
    MaxDeliveryTime: data.maxDeliveryTime,
    MaxOrderCoolingTime: data.maxOrderCoolingTime,
	AdministrativeName: data.administrativeName,
    PreorderTimeWindowEpsilon: data.preorderTimeWindowEpsilon,
    DefaultControlCenterId: data.defaultControlCenterId,
    HideCouriers: data.hideCouriers,
    AutoExternalAssignment: data.autoExternalAssignment,
    AllowOrderCreation: data.allowOrderCreation,
    Color: data.color,
    SmsBrand : data.smsBrand,
    DepotAsControlCenter : data.depotAsControlCenter
  };
}
