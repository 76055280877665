import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { actionAlarmsBarSetShow } from "../../../store/reducers/notification/notification-actions";
import { getAlarms } from "../../../services/notification";

import IconButton from "../IconButton";
import FreshChatControl from "../FreshChatControl";

const HeaderUserControls = ({
  t,
  alarms,
  alarmsBarSetShow,
  isOpenAlarmsBar,
  showSupportChat,
}) => {
  const handleOnShowAlarms = () => {
    return new Promise((resolve) => {
      resolve();
      alarmsBarSetShow(!isOpenAlarmsBar);
    });
  };

  return (
    <>
      <div className={classNames("headerUserControls")}>
        <div className={classNames("headerUserControl")}>
          <IconButton
            isIcon={true}
            iconName="notif"
            tooltipText={t("notification.NOTIFICATIONS_LABEL")}
            onClick={handleOnShowAlarms}
          />
          {alarms.length > 0 && (
            <div className={classNames("badge")}>{alarms.length}</div>
          )}
        </div>
        {showSupportChat && (
          <div className={classNames("headerUserControl")}>
            <FreshChatControl />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isOpenAlarmsBar: state.notifications.data.isOpenAlarmsBar,
  alarms: getAlarms(state.notifications.data.alarms),
  showSupportChat:
    state.restaurant.data.configuration &&
    state.restaurant.data.configuration.showSupportChat,
  showBillingItem: !!((state.restaurant.data || {}).recurlyConfiguration || {}).accountId
});

const mapDispatchToProps = dispatch => {
  return {
    alarmsBarSetShow: data => dispatch(actionAlarmsBarSetShow(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HeaderUserControls));
