import React from 'react';
import i18n from '../../i18n';

import { getPromotionFilter } from '../filter';
import { createControlModel } from '../controlModelFactory';
import {
    getCategories,
    getDepots,
    getListPromotionNameById,
    getMessages,
    getNameForCategory,
    isMobileWidth,
} from '../restaurant';

import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';

import {
    LIST_PROMOTION_SETTING,
    LIST_PROMOTION_SETTING_DRAG,
    LIST_PROMOTION_SETTING_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE, getFormatTypes,
    getMarketplaceSearchTypes, getPositioningTypes,
    MARKETPLACE_SEARCH_TYPES,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
} from '../../utils/enums';
import { getInitModel } from '../initModels';
import { defaultPromiseResolve } from '../../utils/objects-util';

import IconButton from '../../app/components/IconButton';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { getHistoryLocationParams } from '../../utils/routesHelper.js';

export function createListPromotionSectionModel() {
    return new SectionModel({
        filterOptions: createListPromotionSectionFilter(),
        columns: [
            {
                title: 'settings.listPromotion.NAME_LABEL',
                component: (item) => item.label || item.name
            },
            {
                title: 'settings.listPromotion.VISIBLE_LABEL',
                component: function(item) {
                    return (
                        <IconButton
                            isIcon={true}
                            active={item.visible}
                            iconName={'see'}
                            tooltipText={
                                item.visible
                                    ? i18n.t('settings.listPromotion.VISIBILITY_VISIBLE_LABEL')
                                    : i18n.t('settings.listPromotion.VISIBILITY_HIDDEN_LABEL')
                            }
                            onClick={defaultPromiseResolve}
                        />
                    );
                },
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: MARKETPLACE_SETTINGS_MODEL.listPromotionEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.listPromotion.EDIT_TOOLTIP_LABEL'),
            },
        ],
        actionLoad: LIST_PROMOTION_SETTING_GET,
        actionName: LIST_PROMOTION_SETTING,
        dragActionName: LIST_PROMOTION_SETTING_DRAG,
        typeModel: MARKETPLACE_SETTINGS_MODEL.listPromotion,
        controls: [
            createControlModel({ type: MODEL_CONTROL_TYPE.add, url: MARKETPLACE_SETTINGS_MODEL.listPromotionNew }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
            createControlModel({ type: MODEL_CONTROL_TYPE.back, isShow: isMobileWidth }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
        ],
        label: 'settings.listPromotion.LABEL',
        mappingLambda: state => {const data = state.restaurant.data.listPromotion || []
            return data.map(item => ({
                name: item.name_translations ? item.name_translations[i18n.language] : '',
                ...item
            }))
        },
    });
}

export function createListPromotionEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: '',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.listPromotion.VISIBLE_LABEL',
                        accessor: 'visible',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.listPromotion.SHUFFLE',
                        accessor: 'shuffle',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.listPromotion.HIGHLIGHTED',
                        accessor: 'highlighted',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.listPromotion.HIDE_VIDEO_CONTROLS',
                        accessor: 'hide_video_controls',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.listPromotion.MARGINS',
                        accessor: 'margins',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.listPromotion.TITLE',
                        accessor: 'label',
                        isEditable: true,
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'settings.listPromotion.LIST_TYPE_LABEL',
                        accessor: 'list_type',
                        connect: () => ({
                            values: getMarketplaceSearchTypes(),
                        }),
                        isEditable: true,
                        dataToRefresh: {business_ids: []},
                        type: CONTROL_TYPE.select,
                    },
                    {
                        title: 'settings.listPromotion.ROW_ITEMS_COUNT',
                        accessor: 'row_items_count',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.listPromotion.POSITIONING',
                        accessor: 'positioning',
                        isEditable: true,
                        connect: () => ({
                            values: getPositioningTypes(),
                        }),
                        type: CONTROL_TYPE.select,
                    },
                    {
                        title: 'settings.listPromotion.FORM_FACTOR',
                        accessor: 'form_factor',
                        isEditable: true,
                        connect: () => ({
                            values: getFormatTypes(),
                        }),
                        type: CONTROL_TYPE.select,
                    },
                    {
                        title: 'settings.businessCategories.CATEGORY_NAME',
                        accessor: 'name_translations',
                        isEditable: true,
                        isFullWidth: true,
                        type: CONTROL_TYPE.languageEditor,
                    },
                ],
            },
            {
                title: 'settings.listPromotion.BUSINESS_ITEMS_HEADER_LABEL',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.listPromotion.BUSINESS_ADD_BUTTON',
                        accessor: 'business_ids',
                        connect: state => {
                            const depots = (state.restaurant.data && state.restaurant.data.depots) || [];
                            return {
                                values: depots
                                    .filter(depot => depot.businessId)
                                    .map(depot => ({ key: depot.businessId, value: depot.name })),
                            };
                        },
                        values: function(item) {
                            return getDepots()
                                .filter(
                                    depot =>
                                        depot.businessId &&
                                        !(item.business_ids || []).find(f => f.key === depot.businessId)
                                )
                                .map(depot => ({ key: depot.businessId, value: depot.name }));
                        },
                        isEditable: true,
                        type: CONTROL_TYPE.selectWithAdd,
                    },
                    {
                        accessor: 'business_ids',
                        itemValue: {
                            key: 'key',
                            accessor: 'value',
                            isReadonly: true,
                        },
                        isReadonly: true,
                        isEditable: true,
                        isDraggable: true,
                        type: CONTROL_TYPE.listItem,
                    },
                ],
                isShow: item => item.list_type === MARKETPLACE_SEARCH_TYPES.none,
            },
            {
                title: 'settings.listPromotion.MESSAGES',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.listPromotion.MESSAGES',
                        accessor: 'business_ids',
                        connect: state => {
                            const messages = (state.marketplace.data && state.marketplace.data.message) || [];
                            return {
                                values: messages
                                    .map(mes => ({ key: mes.id, value: mes.label || mes.title })),
                            };
                        },
                        values: function(item) {
                            return getMessages()
                                .filter(
                                    mes =>
                                        mes.id &&
                                        !(item.business_ids || []).find(f => f.key === mes.is)
                                )
                                .map(mes => ({ key: mes.id, value: mes.label || mes.title }));
                        },
                        isEditable: true,
                        type: CONTROL_TYPE.selectWithAdd,
                    },
                    {
                        accessor: 'business_ids',
                        itemValue: {
                            key: 'key',
                            accessor: 'value',
                            isReadonly: true,
                        },
                        isReadonly: true,
                        isEditable: true,
                        isDraggable: true,
                        type: CONTROL_TYPE.listItem,
                    },
                ],
                isShow: item => item.list_type === MARKETPLACE_SEARCH_TYPES.messages,
            },
            {
                title: 'settings.listPromotion.CATEGORIES',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.listPromotion.CATEGORIES',
                        accessor: 'business_ids',
                        connect: state => {
                            const categories = (state.restaurant.data && state.restaurant.data.businessCategory) || [];
                            return {
                                values: categories
                                    .map(cat => ({ key: cat.id, value: cat.id })),
                            };
                        },
                        values: function(item) {
                            return getCategories()
                                .filter(
                                    cat =>
                                        cat.id &&
                                        !(item.business_ids || []).find(f => f.key === cat.is)
                                )
                                .map(mes => ({ key: mes.id, value: getNameForCategory(mes) }));
                        },
                        isEditable: true,
                        type: CONTROL_TYPE.selectWithAdd,
                    },
                    {
                        accessor: 'business_ids',
                        itemValue: {
                            key: 'key',
                            accessor: 'value',
                            isReadonly: true,
                        },
                        isReadonly: true,
                        isEditable: true,
                        isDraggable: true,
                        type: CONTROL_TYPE.listItem,
                    },
                ],
                isShow: item => item.list_type === MARKETPLACE_SEARCH_TYPES.categories,
            },
        ],
        actionName: LIST_PROMOTION_SETTING,
        actionLoad: LIST_PROMOTION_SETTING_GET,
        initNewItemModel: item => getInitModel(MODEL_TYPE.listPromotionModel, item),
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? MARKETPLACE_SETTINGS_MODEL.listPromotionEdit
                : MARKETPLACE_SETTINGS_MODEL.listPromotionNew,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.listPromotion,
        controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back, isShow: isMobileWidth })],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                label: 'settings.listPromotion.LABEL',
            })
        ],
        getLabel: () => {
            const { id } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit ? getListPromotionNameById(id) : i18n.t('settings.listPromotion.NEW_LABEL');
        },
        mappingLambda: state => state.restaurant.data.listPromotion || [],
    });
}

function createListPromotionSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(promo) {
        return getPromotionFilter(this.selected, promo);
    });

    return filterOptionsModel;
}
