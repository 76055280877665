import { all, fork } from 'redux-saga/effects';

import routingSaga from './sagas/routing-saga';
import initSaga from './sagas/init-saga';
import authenticationSaga from './sagas/authentication-saga';
import restaurantSaga from './sagas/restaurant-saga';
import userModelSaga from './sagas/userModel-saga';
import orderSaga from './sagas/order-saga';
import orderEditSaga from './sagas/orderEdit-saga';
import markerSaga from './sagas/marker-saga';
import checkUserAuthenticationSaga from './sagas/checkUserAuthentication-saga';
import supportChat from './sagas/supportChat-saga';
import billingSaga from './sagas/billing-saga';
import customer from './sagas/customer-saga';
import editCustomerOrder from './sagas/editCustomerOrder-saga';
import reconciliationModelSaga from './sagas/reconciliationModel-saga.js';

export default function* rootSaga() {
  yield all([
    fork(initSaga),
    fork(authenticationSaga),
    fork(userModelSaga),
    fork(reconciliationModelSaga),
    fork(restaurantSaga),
    fork(billingSaga),
    fork(orderSaga),
    fork(orderEditSaga),
    fork(markerSaga),
    fork(checkUserAuthenticationSaga),
    fork(routingSaga),
    fork(supportChat),
    fork(customer),
    fork(editCustomerOrder),
  ]);
}
