import {DELIVERY_TYPE, ORDER_TYPE} from "../utils/enums";

export function orderPriceServerModel(data) {
    return {
        business_id: data.id,
        products: (data.products || []).map(el => el.product),
        delivery_type: data.type === ORDER_TYPE.delivery ? DELIVERY_TYPE.delivery : DELIVERY_TYPE.takeAway,
        delivery_datatime: data.targetTime,
        location: data.type === ORDER_TYPE.takeAway ? null : {
            lng: data.location && (data.location.lng || data.location.longitude),
            lat: data.location && (data.location.lat || data.location.latitude),
        },
        coupon: data.coupon,
        requested_bonus: data.requestedBonus,
        driver_tip: data.driverTip
    }
}