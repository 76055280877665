import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import {createBrowserHistory, createMemoryHistory} from 'history';
import rootReducer from './root-reducer';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import {batchDispatchMiddleware} from 'redux-batched-actions';
import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/react';
import logger from "redux-logger";
import rootSaga from './root-saga';
import environment from 'environment';
import {batchedSubscribe} from '../utils/sagaHelper';

// A nice helper to tell us if we're on the server
export const isServer = !(typeof window !== 'undefined' && window.document && window.document.createElement);

function createGlobalStore(url = '/') {
    // Create a history depending on the environment
    const history = isServer
        ? createMemoryHistory({
            initialEntries: [url],
        })
        : createBrowserHistory();

    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();
    let middleware = [thunk, routerMiddleware(history), sagaMiddleware];

    // Dev tools are helpful
    if (process.env.NODE_ENV === 'development' && !isServer) {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }

        middleware = middleware.concat(logger).concat(batchDispatchMiddleware);
        // middleware = middleware.concat(batchDispatchMiddleware);
    }

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        // Optionally pass options listed below
    });
    enhancers.push(sentryReduxEnhancer);

    const debounceNotify = (notify, args) => {
        const { batched } = args.additionalParams || {};

        if (batched) {
            debounce(() => notify(), environment.debounceNotifyWait, { maxWait: environment.pusherDebounceNotify });
        } else {
            notify();
        }
    };

    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        applyMiddleware(createReduxWaitForMiddleware()),
        ...enhancers,
        batchedSubscribe(debounceNotify)
    );

    // Do we have preloaded state available? Great, save it.
    const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

    // Delete it once we have it stored in a variable
    if (!isServer) {
        delete window.__PRELOADED_STATE__;
    }

    // Create the store
    const store = createStore(
        connectRouter(history)(combineReducers({ ...rootReducer, router: connectRouter(history) })),
        initialState,
        composedEnhancers,
        composeWithDevTools(applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history)))
    );

    sagaMiddleware.run(rootSaga);

    return {
        store,
        history,
    };
}

const currentStore = createGlobalStore();

export default currentStore;
