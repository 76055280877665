import {
  calculateStringTimespan,
  calculateNumber,
} from '../app/components/SectionItemAudienceFilter/audienceFilterHelpers.js';
import { deleteKeysIfNull } from '../utils/objects-util.js';
import i18n from '../i18n.js';

export const marketplaceCampaignModel = data => ({
  id: data?.id || '',
  enabled: data?.enabled || false, //required
  campaign_type: data?.campaign_type || 0, //required
  start_date: data?.start_date || '', //required
  recurring_type: data?.recurring_type || 0, //required
  name: data?.name || '',
  message: data?.message || '',
  message_title: data?.message_title || '',
  audience_channel: data?.audience_channel === 0 ? null : data?.audience_channel || null,
  audience_filter: data?.audience_filter || null, // getAudienceFilterModel
  bonus_info: data?.bonus_info || null,
  auto_add_quantity: data?.auto_add_quantity || 0,
  allow_multiple_discounts: data?.allow_multiple_discounts || false,
});

export const marketplaceCampaignExecutionsModel = data => ({
  campaign_type: data?.campaign_type,
  name: data?.name,
  message: data?.message,
  message_title:  data?.message_title,
  bonus_info: data?.bonus_info,
  audience: data?.audience,
});

export const calcTimeSpan = (audience_filter, isString = false) => {
  const calcFunction = isString ? calculateStringTimespan : calculateNumber;
  return audience_filter?.map(filter => {
    if (filter.hasOwnProperty('timespan_value') && filter.hasOwnProperty('string_value')) {
      return {
        ...filter,
        timespan_value: calcFunction(filter.timespan_value, filter.string_value),
      };
    } else {
      return filter;
    }
  });
};

export const marketplaceCampaignServerModel = data => {
  return {
    ...data,
    start_date: data?.start_date ? data.start_date : new Date(),
    audience_filter: calcTimeSpan(data.audience_filter, true),
    bonus_info: data?.audience_channel !== null ? null : data?.bonus_info
  };
};

export const marketplaceCampaignClientModel = data => {
  const audienceFilter = data?.audience_filter?.map(item => deleteKeysIfNull(item)) || null;

  return {
    ...data,
    execution: i18n.t('settings.campaigns.EXECUTIONS'),
    audience_filter: calcTimeSpan(audienceFilter),
  };
};
