import React from "react";
import classNames from "classnames";
import "./index.scss";

import { ORDER_STATE, ICONS_MARKER_TYPE } from "../../../utils/enums";

const StatusOrder = ({ order }) => (
  <div
    className={classNames("statusOrder", {
      bag:
        order.markerType === ICONS_MARKER_TYPE.empty &&
        order.state === ORDER_STATE.ready,
      ready:
        order.markerType === ICONS_MARKER_TYPE.empty &&
        order.state === ORDER_STATE.onroute,
      "on-way":
        order.markerType === ICONS_MARKER_TYPE.point &&
        order.state === ORDER_STATE.onroute,
      "status-ok": order.markerType === ICONS_MARKER_TYPE.checked
    })}
  />
);

export default StatusOrder;
