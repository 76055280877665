import { call, put, takeEvery, select } from "redux-saga/effects";

import {
    GET_BILLING_CARDS,
    BILLING_CARD_ADD,
    BILLING_ADDRESSES_GET,
    BILLING_ADDRESSES_ADD,
    BILLING_INFO_GET,
    BILLING_INFO_PLANS_GET,
    BILLING_INFO_PLANS_SET,
    BILLING_CARD_SET,
    BILLING_CARD_DELETE,
    BILLING_CARD_EDIT,
    BILLING_ADDRESSES_DELETE,
    updateBillingInfo,
    BILLING_ADDRESSES_EDIT,
    BILLING_ADDRESSES_SET_ADDRESS,
    BILLING_INFO_USAGE_GET,
    BILLING_INFO_UPDATE_DATA,
    deleteBillingPaymentAC, addNewBillingPayment, setBillingPayments,
} from "../reducers/billing/billing-actions";
import { actionAccountTopNotification } from "../reducers/notification/notification-actions";
import {
    addBillingAddress,
    deleteBillingAddress,
    getBillingAddresses,
    getBillingInfo,
    getBillingPlans,
    setAccountPlan,
    getAccountCards,
    billingCardAdd,
    billingCardDelete,
    billingCardEdit,
    editBillingAddress,
    setBillingAddress,
    getBillingUsages, updateAccountCard
} from "../../api/billing-request";

import BillingService from "../../services/billing";

import { getErrorMessage, requestResultIsOk } from "../../utils/request-util";
import { objectKeysToUpperLowerCase } from "../../utils/objects-util";
import { createSaga, ErrorData } from "../../utils/sagaHelper";

import { getBilling } from "../selectors";
import {getInitModel} from "../../services/initModels.js";
import {MODEL_TYPE} from "../../utils/enums.js";

function* getBillingInfoSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getBillingInfo);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* billingPlansGetSaga(action) {
    yield* createSaga(function*() {
        const billing = yield select(getBilling);
        if(!billing || !billing.info) {
            yield put({ type: BILLING_INFO_GET });
        }

        const requestResult = yield call(getBillingPlans);
        if(requestResultIsOk(requestResult)) {            
            yield put(updateBillingInfo({ plans: objectKeysToUpperLowerCase(requestResult.data) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* billingPlanSetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(setAccountPlan, action.data);
        if(requestResultIsOk(requestResult)) {
            if(requestResult.data) {
                yield put(updateBillingInfo({ info: objectKeysToUpperLowerCase(requestResult.data) }));
            }            
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getBillingCardsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getAccountCards);
        if(requestResultIsOk(requestResult)) {
            yield put(setBillingPayments([requestResult.data] || []));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* billingCardSetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateAccountCard, action.data.id);
        if(requestResultIsOk(requestResult)) {
            const creditCards = yield select(state => state.billing.creditCards);
            yield put(setBillingPayments(
                creditCards.map(card => ({ ...card, default: card.id === action.data.id }))
            ));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* billingCardDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(billingCardDelete, action.data);
        if(requestResultIsOk(requestResult)) {
            yield put(deleteBillingPaymentAC({ id: action.data }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* addBillingCardSaga(action) {
    yield* createSaga(function*() {
        delete action.data.id;
        action.data.default = true;
        if(action.data.expiry.includes('/')) {
            action.data.expiry = action.data.expiry.slice(0, 2) + '/' + action.data.expiry.slice(2);
        }
        const card = getInitModel(MODEL_TYPE.creditCard, action.data);
        const requestResult = yield call(billingCardAdd, card);
        if(requestResultIsOk(requestResult)) {
            yield put(addNewBillingPayment(requestResult.data));
            return;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editBillingCardSaga(action) {
    yield* createSaga(function*() {
        const { tokenId, billingInfoId } = action.data;
        const requestResult = yield call(billingCardEdit, billingInfoId, tokenId);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ cards: objectKeysToUpperLowerCase(requestResult.data || []) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* getBillingAddressesSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getBillingAddresses);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ shipmentAddresses: objectKeysToUpperLowerCase(requestResult.data || []) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* deleteBillingAddressSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteBillingAddress, action.data);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ shipmentAddresses: objectKeysToUpperLowerCase(requestResult.data || []) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* billingAddressSetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(setBillingAddress, action.data);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ shipmentAddresses: objectKeysToUpperLowerCase(requestResult.data || []) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* addBillingAddressSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(addBillingAddress, action.data);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ shipmentAddresses: objectKeysToUpperLowerCase(requestResult.data || []) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* editBillingAddressSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(editBillingAddress, action.data.id, action.data);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ shipmentAddresses: objectKeysToUpperLowerCase(requestResult.data || []) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult)); 
        }
    }, action.type);
}

function* billingUsagesGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getBillingUsages);
        if(requestResultIsOk(requestResult)) {
            yield put(updateBillingInfo({ usages: objectKeysToUpperLowerCase(requestResult.data) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* updateBillingInfoDataSaga(action) {
    yield* createSaga(function*() {
        yield put(updateBillingInfo({ info: objectKeysToUpperLowerCase(action.data) }));
        const billing = yield select(getBilling);
        const topNotificationData = BillingService.instance.getAccountTopNotificationData(billing);
        yield put(actionAccountTopNotification(topNotificationData));
    }, action.type);
}

export default function* () {
    yield takeEvery(BILLING_INFO_GET, getBillingInfoSaga);
    yield takeEvery(BILLING_INFO_PLANS_GET, billingPlansGetSaga);    
    yield takeEvery(BILLING_INFO_PLANS_SET, billingPlanSetSaga); 
    yield takeEvery(GET_BILLING_CARDS, getBillingCardsSaga);
    yield takeEvery(BILLING_CARD_ADD, addBillingCardSaga);
    yield takeEvery(BILLING_CARD_EDIT, editBillingCardSaga);    
    yield takeEvery(BILLING_CARD_SET, billingCardSetSaga); 
    yield takeEvery(BILLING_CARD_DELETE, billingCardDeleteSaga);
    yield takeEvery(BILLING_ADDRESSES_GET, getBillingAddressesSaga);
    yield takeEvery(BILLING_ADDRESSES_ADD, addBillingAddressSaga);   
    yield takeEvery(BILLING_ADDRESSES_EDIT, editBillingAddressSaga);    
    yield takeEvery(BILLING_ADDRESSES_DELETE, deleteBillingAddressSaga);
    yield takeEvery(BILLING_ADDRESSES_SET_ADDRESS, billingAddressSetSaga);    
    yield takeEvery(BILLING_INFO_USAGE_GET, billingUsagesGetSaga);
    yield takeEvery(BILLING_INFO_UPDATE_DATA, updateBillingInfoDataSaga);
}