import React, { useEffect, useState } from 'react';
import { getDaysOffsetTypeByNumber, handleMinValueError, SimpleItemSelect } from './audienceFilterHelpers.js';
import {
  AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES,
  CAMPAIGN_AUDIENCE_FILTER_TYPES,
  getCampaignDaysOffsetTypes,
  getCampaignPeriodTypes,
} from '../../../utils/enums.js';
import classNames from 'classnames';
import NumberInput from '../NumberInput/NumberInput.jsx';
import i18n from 'i18next';

const TimeSpanItem = ({ index, filterState, handleChange }) => {
  const [daysOffsetType, setDaysOffsetType] = useState(getDaysOffsetTypeByNumber(filterState?.days_offset) || 0);
  const [isNegativeDaysOffset, setIsNegativeDaysOffset] = useState(false);

  const isAfterOrBeforeType = daysOffsetType >= AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.after;
  const isEqualOperator = filterState?.operator === CAMPAIGN_AUDIENCE_FILTER_TYPES.equal;

  useEffect(() => {
    setIsNegativeDaysOffset(false);
    if (daysOffsetType < AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.after) return;
    setIsNegativeDaysOffset(daysOffsetType === AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.before);
  }, [daysOffsetType]);

  const handleFilterStateChange = (value, key, skipError = false) => {
    let newValue = value?.key ?? value;

    if (key === 'timespan_value' && filterState.string_value) {
      if (newValue < 0) return handleMinValueError(0);
    }

    if (newValue === filterState[key]) return;

    if (key === 'days_offset' && newValue < 0 && isAfterOrBeforeType && !skipError) return handleMinValueError(0);

    if (key === 'days_offset' && isNegativeDaysOffset && !skipError) {
      handleChange(index, { ...filterState, days_offset: newValue * -1 });
      return;
    }

    if (key === 'string_value') {
      handleChange(index, { ...filterState, [key]: newValue });
      return;
    }

    handleChange(index, { ...filterState, [key]: newValue });
  };

  return isEqualOperator ? (
    <>
      <SimpleItemSelect
        width={'120px'}
        value={daysOffsetType}
        values={getCampaignDaysOffsetTypes()}
        handleChange={value => {
          setDaysOffsetType(value.key);

          if (value.key >= AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.after) {
            handleFilterStateChange(0, 'days_offset');
          } else {
            handleFilterStateChange(value, 'days_offset', true);
          }
        }}
      />
      {daysOffsetType >= AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.after && (
        <>
          <div className={classNames('sectionItemNumber')} style={{ width: '60px' }}>
            <div className={classNames('inputItem')}>
              <NumberInput
                value={isNegativeDaysOffset ? filterState?.days_offset * -1 : filterState?.days_offset}
                onChange={value => handleFilterStateChange(value, 'days_offset')}
              />
            </div>
          </div>
          {!!filterState?.days_offset && (
            <div className={'days'}>
              {(isNegativeDaysOffset ? filterState.days_offset * -1 : filterState.days_offset) +
                ' ' +
                i18n.t('enums.campaign.DAYS')}
            </div>
          )}
        </>
      )}
    </>
  ) : (
    <>
      <div className={classNames('sectionItemNumber')} style={{ width: '100px' }}>
        <div className={classNames('inputItem')}>
          <NumberInput
            value={filterState?.timespan_value || 0}
            onChange={value => handleFilterStateChange(value, 'timespan_value')}
          />
        </div>
      </div>
      <SimpleItemSelect
        value={filterState.string_value}
        values={getCampaignPeriodTypes()}
        handleChange={value => handleFilterStateChange(value, 'string_value')}
      />
    </>
  );
};

export default TimeSpanItem;
