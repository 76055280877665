import { CROP_MODE, GRAVITY_MODE } from './enums';
import { getKeyByValue } from './objects-util';

export function convertBlobToBase64(blob) {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      resolve(reader.result);
    };
  });
}

export function getConvertedCloudUrl(defaultUrl, size = 35) {
  if(!defaultUrl) return null;  
  const urlSplit = defaultUrl.split("upload/");
  if(urlSplit.length < 2) return defaultUrl;
  const props = size ? `c_scale,h_${size},w_${size}` : "c_scale";
  return `${urlSplit[0]}upload/${props}/${urlSplit[1]}`;
}

export function getConvertedCloudUrlBySettings(
  defaultUrl, 
  width = 100, 
  height = 35, 
  cropMode = getKeyByValue(CROP_MODE, 0),
  gravityMode = getKeyByValue(GRAVITY_MODE, 0),
  quality = 90
) {
  if(!defaultUrl) return null;  
  const urlSplit = defaultUrl.split("upload/");
  if(urlSplit.length < 2) return defaultUrl;
  return urlSplit[0] + "upload/" +    
    "h_" + height +
    ",w_" + width +
    ",c_" + cropMode +
    ",g_" + gravityMode +
    ",q_" + quality + 
    "/" + urlSplit[1];
}

export function getImageSettings(settings) {
  return {
    crop_mode: 0,
    gravity_mode: 0,
    quality: 90,
    quality_mode: 0,
    ...settings || {}
  }
}

export function getBlobFromUrl(myImageUrl) {
  return new Promise((resolve, reject) => {
      if(!myImageUrl) resolve(myImageUrl);
      let request = new XMLHttpRequest();
      request.open('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
          resolve(request.response);
      };
      request.onerror = reject;
      request.send();
  })
}

export function fetchMarkerAvatarBase64(url, isFull = false, id) {
  return new Promise(resolve => {
    const cloudUrl = isFull ? url : getConvertedCloudUrl(url, 35);
    if(!cloudUrl) 
      resolve(url);
    getBlobFromUrl(cloudUrl).then(blob=> {
      convertBlobToBase64(blob).then(image=> {
        resolve(id ? { id, image } : image)
      }, ()=> resolve(null))
    }, ()=> resolve(null));
  });
}

export function isBase64Image(image) {
  return image && image.includes("data:image/");
}

export function isBase64Video(video) {
  return video && video.includes("data:video/");
}

export function imageExists(image_url) {
  if(!image_url) return false;
  const http = new XMLHttpRequest();
  http.open('HEAD', image_url, false);
  http.send();
  return http.status !== 404 && http.status !== 400;
}

export async function downloadImage(
    imageSrc,
    nameOfDownload = 'image.png',
) {
  const response = await fetch(imageSrc);

  const blobImage = await response.blob();

  const href = URL.createObjectURL(blobImage);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = nameOfDownload;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}