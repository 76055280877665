import React from 'react';
import i18n from '../../i18n';

import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';

import {
    CONTROL_TYPE,
    courierAssociationModes,
    MODEL_CONTROL_TYPE,
    POPUP_ITEM_TYPE,
    TYPE_SECTION_MODEL,
    TYPE_STATUS,
} from '../../utils/enums';
import {getControlCentersFilter, sortByProp} from '../filter';
import { getStaticTime } from '../../utils/convertTime';
import { isRTL } from '../userModel';
import {
    RESTAURANT_CONTROL_CENTER_STATUS_GET,
    SETTINGS_CONTROL_CENTER,
    SETTINGS_CONTROL_CENTER_ACTIVATE,
    SETTINGS_CONTROL_CENTER_CHANGEUSER,
    SETTINGS_CONTROL_CENTER_DEACTIVATE,
} from '../../store/reducers/restaurant/restaurant-actions';

import StatusControl from '../../app/components/StatusControl';
import UserInfoControl from '../../app/components/UserInfoControl';
import { createControlModel } from '../controlModelFactory';
import { getControlCenterNameById, isMobileWidth } from '../restaurant';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import {getHistoryLocationParams} from "../../utils/routesHelper";

export function createControlCentersSectionModel() {
    return new SectionModel({
        filterOptions: createControlCentersSectionFilter(),
        columns: [
            {
                title: 'controlCenters.NAME_LABEL',
                accessor: 'name',
                fixedWidth: '240px',
                width: '240px',
            },
            {
                title: 'controlCenters.OPERATED_BY_LABEL',
                component: function(center) {
                    return center.userModel ? <UserInfoControl value={center.user} /> : '';
                },
                fixedWidth: '420px',
                width: '420px',
            },
            {
                title: 'controlCenters.STARTED_ON_LABEL',
                component: function(center) {
                    return center.info && center.info.startTime
                        ? getStaticTime(center.info.startTime, false, isRTL()) || ''
                        : '';
                },
                fixedWidth: '240px',
                width: '240px',
            },
            {
                title: 'controlCenters.STATUS_LABEL',
                component: function(center) {
                    return (
                        <StatusControl
                            text={i18n.t(!center.info ? 'controlCenters.CLOSED_LABEL' : 'controlCenters.ACTIVE_LABEL')}
                            typeStatus={!center.info ? TYPE_STATUS.textDisabled : TYPE_STATUS.textActive}
                        />
                    );
                },
                width: 'auto',
                fixedWidth: 'auto',
            },
            {
              title: 'controlCenters.ACTIONS',
              width: '80px',
            },
            {
                title: '',
                fixedWidth: '45px',
                width: '45px',
                hideInHeader: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.controlCentersActivate,
                iconName: 'start',
                tooltipText: i18n.t('controlCenters.START_SHIFT_TOOLTIP'),
                isShow: function(controlCenter) {
                    return !controlCenter.alwaysOn && !controlCenter.info;
                },
            },
            {
                title: '',
                fixedWidth: '45px',
                width: '45px',
                hideInHeader: true,
                type: CONTROL_TYPE.link,
                tooltipText: i18n.t('controlCenters.STOP_SHIFT_TOOLTIP'),
                link: TYPE_SECTION_MODEL.controlCentersDeactivate,
                iconName: 'stop',
                isShow: function(controlCenter) {
                    return !controlCenter.alwaysOn && controlCenter.info;
                },
            },
            {
                title: '',
                fixedWidth: '45px',
                width: '45px',
                hideInHeader: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.controlCentersChangeuser,
                iconName: 'reset',
                tooltipText: i18n.t('controlCenters.RESET_SHIFT_TOOLTIP'),
                isShow: function(controlCenter) {
                    return !controlCenter.alwaysOn && controlCenter.info;
                },
            },
            {
                title: '',
                width: '40px',
                hideInHeader: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.controlCentersEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('controlCenters.EDIT_CONTROL_CENTER_TOOLTIP'),
            }
        ],
        key: 'controlCenterId',
        actionName: SETTINGS_CONTROL_CENTER,
        actionLoad: RESTAURANT_CONTROL_CENTER_STATUS_GET,
        tableMinWidth: 1200,
        typeModel: TYPE_SECTION_MODEL.controlCenters,
        isForceUpdate: true,
        showDeleteButton: false,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.depotsNew,
                isLayoutForm: true,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        label: 'header.menu.CONTROL_CENTERS',
        breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SETTINGS_LABEL' })],
        mappingLambda: state => state.restaurant.data.controlCenters || [],
        sortByFunc: sortByProp,
    });
}

export function createControlCentersActivateSectionModel({ ...args }) {
    return new SectionModel({
        key: 'controlCenterId',
        columns: [
            {
                title: 'controlCenters.SELECT_USER_LABEL',
                accessor: 'userId',
                isEditable: true,
                popupItemType: POPUP_ITEM_TYPE.managerItem,
                type: CONTROL_TYPE.usersPopup
            },
            {
                title: 'controlCenters.PULL_ORDERS_LABEL',
                label: {
                    onLabel: 'ON',
                    offLabel: 'OFF',
                },
                accessor: 'pullOrders',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
        ],
        actionName: SETTINGS_CONTROL_CENTER_ACTIVATE,
        initNewItemModel: (item, id) => ({
            controlCenterId: id,
            userId: '',
            pullOrders: true,
        }),
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.controlCentersActivate,
        parentUrl: TYPE_SECTION_MODEL.controlCenters,
        mappingLambda: state => state.restaurant.data.controlCenters || [],
    });
}

export function createControlCentersDeactivateSectionModel({ ...args }) {
    return new SectionModel({
        key: 'controlCenterId',
        columns: [
            {
                title: 'controlCenters.MOVE_DATA_TO_CONTROL_CENTER_LABEL',
                accessor: 'moveDataToControlCenterId',
                connect: state => ({
                    values: [{ key: null, value: '-' }].concat(
                        (state.restaurant.data.controlCenters || [])
                            .filter(
                                f =>
                                    f.controlCenterId !==
                                        state.sectionModel.sectionModels[state.sectionModel.currentModelType].id &&
                                    f.info &&
                                    f.info.startTime &&
                                    !f.info.endTime
                            )
                            .map(center => ({
                                key: center.controlCenterId,
                                value: center.name,
                            }))
                    ),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'controlCenters.FUATURE_DATA_TO_CONTROL_CENTER_LABEL',
                accessor: 'backUpControlCenterId',
                connect: state => ({
                    values: [{ key: null, value: '-' }].concat(
                        (state.restaurant.data.controlCenters || [])
                            .filter(
                                f =>
                                    f.controlCenterId !==
                                        state.sectionModel.sectionModels[state.sectionModel.currentModelType].id &&
                                    f.info &&
                                    f.info.startTime &&
                                    !f.info.endTime
                            )
                            .map(center => ({
                                key: center.controlCenterId,
                                value: center.name,
                            }))
                    ),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
        ],
        actionName: SETTINGS_CONTROL_CENTER_DEACTIVATE,
        initNewItemModel: (item, id) => ({
            controlCenterId: id,
            moveDataToControlCenterId: '',
            backUpControlCenterId: '',
        }),
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.controlCentersActivate,
        parentUrl: TYPE_SECTION_MODEL.controlCenters,
        mappingLambda: state => state.restaurant.data.controlCenters || [],
        saveControlsConfig: {
            save: { isAlwaysActive: true },
        },
    });
}

export function createControlCentersChangeUserSectionModel({ ...args }) {
    return new SectionModel({
        key: 'controlCenterId',
        columns: [
            {
                title: 'controlCenters.SELECT_USER_LABEL',
                accessor: 'userId',
                isEditable: true,
                isRequired: true,
                popupItemType: POPUP_ITEM_TYPE.managerItem,
                type: CONTROL_TYPE.usersPopup
            },
        ],
        actionName: SETTINGS_CONTROL_CENTER_CHANGEUSER,
        initNewItemModel: (item, id) => ({
            controlCenterId: id,
            userId: '',
            pullOrders: false,
        }),
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.controlCentersActivate,
        parentUrl: TYPE_SECTION_MODEL.controlCenters,
        mappingLambda: state => state.restaurant.data.controlCenters || [],
    });
}

export function createControlCentersEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'controlCenters.COURIER_ASSOCIATION_MODE',
                accessor: 'courierAssociationMode',
                isEditable: true,
                connect: () => ({
                    values: courierAssociationModes(),
                }),
                type: CONTROL_TYPE.select,
            },
            {
                title: 'controlCenters.PULL_FROM_ANY_CONTROL_CENTER',
                accessor: 'pullFromAnyControlCenter',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'controlCenters.AUTOMATIC_EXTEND_CYCLE',
                accessor: 'automaticExtendCycle',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'controlCenters.AUTOMATIC_APPROVE_CYCLE',
                accessor: 'automaticApproveCycle',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'controlCenters.RUN_COURIER_APP_IN_POINT_BY_POINT_MODE',
                accessor: 'runCourierAppInPointByPointMode',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'controlCenters.ENABLE_COLLECT_ORDERS_STEP_AT_PICKUP',
                accessor: 'enableCollectOrdersStepAtPickup',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'controlCenters.RUN_COURIER_APP_IN_POINT_BY',
                accessor: 'runCourierAppInPointByPointModeAfterApprove',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },

        ],
        initNewItemModel: (d) => d,
        breadcrumbs: [
            createBreadcrumbModel({ label: 'header.menu.SETTINGS_LABEL' }),
            createBreadcrumbModel({ label: 'header.menu.CONTROL_CENTERS' }),
        ],
        getLabel: () => {
            const { id } = getHistoryLocationParams(window.location.search);
            return getControlCenterNameById(id);
        },
        actionName: SETTINGS_CONTROL_CENTER,
        typeAction: args.typeAction,
        parentUrl: TYPE_SECTION_MODEL.controlCenters,
        typeModel: TYPE_SECTION_MODEL.controlCentersEdit,
        key: 'controlCenterId',
        mappingLambda: state => {
            return state.restaurant.data.controlCenters;
        }
    })
}

function createControlCentersSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(center) {
        return getControlCentersFilter(this.selected, center);
    });

    return filterOptionsModel;
}
