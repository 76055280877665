export const getCourierControlSettingsModel = (data = {}) => {
    return {
        CourierWaitingApproveTimeout: data.courierWaitingApproveTimeout,
        DoNotAllowCycleRejectByCourier: data.doNotAllowCycleRejectByCourier,
        DoNotAllowPullWithoutLocation: data.doNotAllowPullWithoutLocation,
        EnableAwayControlByCourier: data.enableAwayControlByCourier,
        ManagerOnlyShiftControl: data.managerOnlyShiftControl,
        RejectCycleOnCourierApproveTimeout: data.rejectCycleOnCourierApproveTimeout,
        RunCourierAppInPointByPointMode: data.runCourierAppInPointByPointMode,
        LockTasksOrderInCourierApp: data.lockTasksOrderInCourierApp,
        ArrivalDistanceThresholdInMeters: data.arrivalDistanceThresholdInMeters,
    };
}