import {MODEL_CONTROL_TYPE} from '../utils/enums';
import {createAddControlModel} from './controlModels/addControlModel';
import {createBackControlModel} from './controlModels/backControlModel';
import {createComboUrlControlModel} from './controlModels/comboUrlControlModel';
import {createIconControlModel} from './controlModels/iconControl';
import {createSortModeControl} from './controlModels/sortModeControl';
import {createFilterControlModel} from './controlModels/filterControlModel';
import {createImportFileControlModel} from './controlModels/importFileControl.js';
import {currentUserIsDepotRole} from './userModel.js';
import {createActionButtonControlModel} from './controlModels/actionButton.js';
import {createDropdownControlModel} from './controlModels/dropdownModel/DropdownModel.js';
import {createListItemsControlModel} from "./controlModels/listItemsModel/listItemsModel.js";


export function createControlModel({ type, ...args }) {
  const model = {
    [MODEL_CONTROL_TYPE.add]: createAddControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.back]: createBackControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.iconControl]: createIconControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.sortMode]: createSortModeControl({ ...args }),
    [MODEL_CONTROL_TYPE.comboUrl]: createComboUrlControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.filter]: createFilterControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.importFile]: createImportFileControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.actionButton]: createActionButtonControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.listItems]: createListItemsControlModel({ ...args }),
    [MODEL_CONTROL_TYPE.dropdown]: createDropdownControlModel({ ...args }),
  };

  return model[type] || null;
}

export const filtrateControlByDepotRole = (controls, types = [MODEL_CONTROL_TYPE.back]) => {
  if(currentUserIsDepotRole()) {
    return controls.filter(c => !types.includes(c.type));
  }
  return controls;
}
