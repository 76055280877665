import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { actionDndClearData } from "../../../store/reducers/orderEdit/orderEdit-actions";

class DraggableWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.draggableWrapper = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { dnd } = this.props;
    if (prevProps.dnd !== dnd) {
      if (dnd && dnd.item) {
        this.handleOnDragStart();
      } else {
        this.handleOnDragEnd();
      }
    }
  }

  handleOnDragStart = () => {
    window.addEventListener("dragover", this.handleOnDragOver);
  };

  handleOnDragEnd = () => {
    const { dndClearData } = this.props;
    window.removeEventListener("dragover", this.handleOnDragOver);
    dndClearData();
    this.initDraggableWrapperStyle();
  };

  handleOnDragOver = e => {
    const { dnd } = this.props;
    const { item, height, width, diffX, diffY } = dnd || {};
    e.preventDefault();

    if (item) {
      this.draggableWrapper.current.style.height = `${height}px`;
      this.draggableWrapper.current.style.width = `${width}px`;
      this.draggableWrapper.current.style.left = `${e.pageX - diffX}px`;
      this.draggableWrapper.current.style.top = `${e.pageY - diffY}px`;
    }
  };

  initDraggableWrapperStyle = () => {
    this.draggableWrapper.current.style.height = `${0}px`;
    this.draggableWrapper.current.style.width = `${0}px`;
    this.draggableWrapper.current.style.left = `${0}px`;
    this.draggableWrapper.current.style.top = `${0}px`;
  };

  render() {
    const { dnd } = this.props;
    const { item, id } = dnd || {};

    return (
      <div
        id={"draggableWrapper-" + id}
        ref={this.draggableWrapper}
        style={{ pointerEvents: "none" }}
        className={classNames("draggableWrapper")}
      >
        {item}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dnd: state.orderEdit.data.dnd
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dndClearData: () => dispatch(actionDndClearData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableWrapper);
