import { getInitModel } from "../initModels";
import SectionModel from "../../models/sectionModel";
import {
    CONTROL_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE
} from '../../utils/enums';
import { SERVICE_FEE, SERVICE_FEE_GET } from "../../store/reducers/restaurant/restaurant-actions";
import {createControlModel} from "../controlModelFactory";
import {isMobileWidth} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createServiceFeeSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "settings.serviceFee.FIX_VALUE_LABEL",
                accessor: "fixValue",
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: "settings.serviceFee.FIX_PERCENT_LABEL",
                accessor: "fixPercent",
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: "settings.serviceFee.ENABLED_LABEL",
                accessor: "enabled",
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
        ],
        actionName: SERVICE_FEE,
        actionLoad: SERVICE_FEE_GET,
        initNewItemModel: item => getInitModel(MODEL_TYPE.serviceFeeModel, item),
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: MARKETPLACE_SETTINGS_MODEL.serviceFee,
        saveControlsConfig: {
            cancel: {
                title: "basic.RESET_BUTTON",
                action: ()=> {}, isInit: true
            },
            save: {
                action: (item, history)=> {
                    history.push(MARKETPLACE_SETTINGS_MODEL.serviceFee);
                }
            }
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            })
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: "header.menu.MARKETPLACE_SETTINGS",
            }),
        ],
        label: "settings.serviceFee.LABEL",
        mappingLambda: state => state.restaurant.data && state.restaurant.data.serviceFee
    });
}