import { MODEL_TYPE } from "../utils/enums";
import { getDefaultRegion, getRegions } from "../services/restaurant";
import i18n from "../i18n";

export function regionModel(data) {
  const regions = getRegions();
  const id = (data || {}).id || (regions.length
    ? regions?.sort((a, b) => b.id - a.id)[0]["id"] + 1
    : 0);
  const region = {
    id: id,
    name: i18n.t("mapEditor.NEW_REGION_NAME") + id,
    region: ((data || {}).setDefaultRegion && getDefaultRegion()) || [],
    maxDeliveryTime: null,
    pendingOrdersGroupingTimeDelta: null,
    defaultControlCenterId: null,
    modelType: MODEL_TYPE.region
  };

  return { ...region, ...(data || {}) };
}
