import * as Sentry from "@sentry/react";
import User from "./user";
import Store from "../store/store";

import { FDRestoreId } from "../store/reducers/supportChat/supprtChat-actions";

import { objectKeysToUpperLowerCase } from "../utils/objects-util";
import { requestResultIsOk } from "../utils/request-util";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class ChatService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Instantiation failed: use ChatService.getInstance() instead of new.");
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ChatService(singletonEnforcer);
      this[singleton].getWidgetUserProperties.bind(this[singleton]); 
      this[singleton].setWidgetUserProperties.bind(this[singleton]);    
      this[singleton].setRestoreId.bind(this[singleton]);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getInitConfiguration = () => {
    const currentUser = User.instance.getCurrentUserInfo() || {};
    return {
      externalId: sessionStorage.getItem("currentUserName"),
      restoreId: currentUser.supportChatUserId,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phone: currentUser.phoneNumber,
      locale: (currentUser.configuration || {}).language
    }
  }
  
  setChatWidget = (chatWidget) => {
    this.chatWidget = chatWidget;
    this.isLoaded = !!this.chatWidget;
    //Check if exist current user support restore id
    this.checkUserRestoreId();
  }

  getWidgetUserProperties = (widget) => {
    const currentThis = this;
    return new Promise((resolve, reject)=> {
      const chatWidget = widget || currentThis.chatWidget;
      if(chatWidget) {
        chatWidget.user.get(reqUserGet=> {
          if(!requestResultIsOk(reqUserGet)) {
            chatWidget.on("user:created", function(reqUserCreated) {
              if (requestResultIsOk(reqUserCreated, true)) {
                resolve(objectKeysToUpperLowerCase(reqUserCreated.data));
              } else {
                reject("No fresh chat user data");
              }
            });
          } else {
            resolve(objectKeysToUpperLowerCase(reqUserGet.data));
          }
        });
      } else {
        reject("No widget");
      }
    })
  }

  setWidgetUserProperties = (data) => {    
    if(this.chatWidget && this.chatWidget.user) {
      this.chatWidget.user.setProperties(data);
    }
  }

  getChatWidget = () => {
    return this.chatWidget; 
  }

  openChatWidget = () => {
    return new Promise((resolve, reject)=> {
        if(this.chatWidget) {
            resolve();
            this.chatWidget.open();
        } else {
            reject();
        }
    });
  }

  destroyChatWidget = () => {
    this.isLoaded = false;
    if(this.chatWidget) {
      this.chatWidget.off("user:created");
      this.chatWidget.destroy();
    }
  }

  checkUserRestoreId = () => {
    const currentUser = User.instance.getCurrentUserInfo();
    try {
      if(currentUser) {
        if(currentUser.supportChatUserId) {
          const config = this.getInitConfiguration();
          this.setWidgetUserProperties({ ...config });
        } else {
          this.getWidgetUserProperties().then(data=> {
            this.setRestoreId(currentUser.userId, (data || {}).restoreId);
          });
        }        
      }
    } catch (ex) {
      Sentry.captureMessage(`Unexpected error while check user restoreId for user ${(currentUser || {}).userId}`);
    }
  }

  setRestoreId = (userId, restoreId) => {
    if(this.isLoaded && userId) {
      const config = this.getInitConfiguration();
      if(restoreId) {
        const currentUser = User.instance.getCurrentUserInfo();
        if(currentUser && !currentUser.supportChatUserId) {
          Store.store.dispatch(FDRestoreId({ userId, restoreId, config }));          
        }        
      } else {
        this.setWidgetUserProperties({ ...config });
      }      
    }    
  }
}
