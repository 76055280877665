export function billingAddressModel(data) {
    const model = data || {};
    const address = model.address || {};

    return {
        id: model.id || "",
        firstName: model.firstName || "",
        lastName: model.lastName || "",
        company: model.company || "",
        email: model.email || "",
        phone: model.phone || "",
        address: {
            country: address.country || "",
            city: address.city || "",
            postalCode: address.postalCode || "",
            street1: address.street1 || "",
            street2: address.street2 || "",
            region: address.region || ""
        },
        isActive: false
    }
}