import React, {useRef, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {WAITER_TYPES} from '../../../utils/enums';
import {
  actionResetTooltipData,
  actionUpdateTooltipData,
} from '../../../store/reducers/tooltipPortal/tooltipPortal-actions';

import Svg from './Svg';
import {Button} from './Button';
import {WaiterSpan} from './Span';
import {WaiterButtonSpan} from './ButtonSpan';
import {ButtonDiv} from './ButtonDiv';

import 'react-toastify/dist/ReactToastify.css';

const Waiter = ({
  type,
  useSvg,
  useClass,
  useIcon,
  useWrapClass,
  useTextClass,
  spanText,
  tooltipText,
  tooltipIsHTML,
  useSvgClass,
  disabled,
  inProcess,
  handleOnClick,
  errorMessage,
  stopPropagation,
  updateTooltipData, resetTooltipData
}) => {
  const isDisable = disabled || inProcess;
  const hoverRef = useRef();
  const [isShake, setShake] = useState(false);
  const [processing, setProcessing] = useState(false);
  const isProcessing = processing || inProcess;

  const onClick = e => {
    !!tooltipText && resetTooltipData({ event: e, context: this });
    if (isDisable) return;
    if (stopPropagation && e) e.stopPropagation();
    setProcessing(true);

    if (handleOnClick) {
      Promise.resolve(handleOnClick()).then(() => {
            //Fix unmounted component error
            if(hoverRef && hoverRef.current) {
              setProcessing(false);
            }
          }, error => {
            setProcessing(false);
            setShake(true);
            setTimeout(() => {
              setShake(false);
              // toast.error(errorMessage || error || "Error");
            }, 500);
          }
      )
    } else {
      //Fix unmounted component error
      if(hoverRef && hoverRef.current) {
        setProcessing(false);
      }
    }
  };

  const handleOnMouseEnter = e => {
    if(window.innerWidth < 900) {
      return
    }
    !!tooltipText && updateTooltipData({ tooltipText, ref: hoverRef.current, tooltipIsHTML })
  }

  const handleOnMouseLeave = e => {
    !!tooltipText && resetTooltipData({ event: e, context: this })
  }

  return (
    <div ref={hoverRef} style={{ height: "100%" }} className={classNames("waiterButtonWrap", "toolTipText")}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnMouseLeave}
    >
      {type === WAITER_TYPES.buttonSvg && (
        <div className={`waiter-button ${useClass ? useClass : ""}`} onClick={onClick}>
          <Svg processing={isProcessing} useSvg={useSvg} />
        </div>
      )}
      {type === WAITER_TYPES.button && (
        <Button useClass={useClass}
          processing={isProcessing}
          handleOnClick={handleOnClick && onClick}
          spanText={spanText}
          disabled={isDisable}
          isShake={isShake}
        />
      )}
      {type === WAITER_TYPES.svg && (
        <Svg
          useClass={useClass}
          processing={isProcessing}
          isShake={isShake}
          useSvg={useSvg}
          spanText={spanText}
          handleOnClick={handleOnClick && onClick}
        />
      )}
      {type === WAITER_TYPES.span && (
        <WaiterSpan
          useClass={useClass}
          processing={isProcessing}
          spanText={spanText}
          handleOnClick={handleOnClick && onClick}
          isShake={isShake}
        />
      )}
      {type === WAITER_TYPES.buttonSpan && (
        <WaiterButtonSpan
          useClass={useClass}
          useWrapClass={useWrapClass}
          useTextClass={useTextClass}
          processing={isProcessing}
          spanText={spanText}
          handleOnClick={handleOnClick && onClick}
          isShake={isShake}
          useSvg={useSvg}
          useSvgClass={useSvgClass}
          disabled={isDisable}
        />
      )}
      {type === WAITER_TYPES.buttonDiv && (
        <ButtonDiv
          useClass={useClass}
          useIcon={useIcon}
          processing={isProcessing}
          handleOnClick={handleOnClick && onClick}
          isShake={isShake}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateTooltipData: data => dispatch(actionUpdateTooltipData(data)),
    resetTooltipData: data=> dispatch(actionResetTooltipData(data))
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(Waiter));
