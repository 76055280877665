import React from 'react';

import {TYPE_SECTION_MODEL} from '../utils/enums';
import {defaultPromiseResolve} from '../utils/objects-util';

import MenuModel from './menuModel';

import IconButton from '../app/components/IconButton';
import {currentUserIsDepotRole} from "../services/userModel.js";
import {getFirstDepotId} from "../services/restaurant.js";

export function createSettingsMenu() {
  return [
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.users,
      title: "settings.users.USERS",
      icon: "user",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.users)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.cloudPrinters,
      title: "settings.cloudPrinters.CLOUD_PRINTERS",
      icon: "printIcon",
      isShow: () => currentUserIsDepotRole(),
      active: location => getIsActive(location, TYPE_SECTION_MODEL.cloudPrinters)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.deliveryProviders + '?id=' + getFirstDepotId(),
      title: "settings.depotSettingsLabel.PROVIDER",
      icon: "providersettings",
      isShow: () => currentUserIsDepotRole(),
      active: location => getIsActive(location, TYPE_SECTION_MODEL.deliveryProviders)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.depots,
      title: "settings.map.DEPOT_SETTINGS_LABEL",
      icon: "depot",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.depots) || location.pathname.includes("/settings/orderingmenu"),
      isWithIcon: function(state) {
        return ((state &&
          state.restaurant &&
          state.restaurant.data &&
          state.restaurant.data.depots) || []).some(depot => depot.disable_delivery || depot.disable_takeaway || depot.squareUpAuthorizationRequired)
            ? (<IconButton isIcon={true} iconName={"warn"} onClick={defaultPromiseResolve} />)
            : "";
      }
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.generalSettings,
      title: "settings.settings.LABEL",
      icon: "generalsetting",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.generalSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.personalSettings,
      title: "settings.personalSettings.LABEL",
      icon: "personalSettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.personalSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.telegramSettings,
      title: "settings.telegramSettings.LABEL",
      icon: "telegramSettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.telegramSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.regions,
      title: "settings.TITLE_REGIONS",
      icon: "deliveryZone",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.regions)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.serviceCities,
      title: "settings.SERVICE_CITIES_TITLE",
      icon: "serviceCities",
      active: location =>
        getIsActive(location, TYPE_SECTION_MODEL.serviceCities)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.namedPlaces,
      title: "settings.NAMED_PLACES_TITLE",
      icon: "namedPlaces",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.namedPlaces)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.controlCenters,
      title: "header.menu.CONTROL_CENTERS",
      icon: "controlCenters",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.controlCenters)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.operationTiming,
      title: "settings.operationTiming.LABEL",
      icon: "operationTiming",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.operationTiming)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.searchShortcuts,
      title: "settings.searchShortcutsSettings.LABEL",
      icon: "searchTags",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.searchShortcuts)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.courierDeliveryFees,
      title: "settings.courierDeliveryFeesSettings.LABEL",
      icon: "courierDeliveryFees",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.courierDeliveryFees)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.courierAppSettings,
      title: "settings.courierSettings.LABEL",
      icon: "courierappsettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.courierAppSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.businessAppSettings,
      title: "settings.businessAppConfiguration.LABEL",
      icon: "businessAppSettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.businessAppSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.fleet,
      title: "settings.TITLE_FLEET",
      icon: "fleet",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.fleet)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.providers,
      title: "settings.courierSettings.LABEL_PROVIDERS",
      icon: "providersettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.providers)
    })
  ];
}

function getIsActive(location, type) {
  const splitCurrentLocation = location.pathname.split("/");
  const splitType = type.split("/");
  return (splitCurrentLocation[1] === splitType[1] && splitCurrentLocation[2] === splitType[2]);
}
