import React from "react";
import "./style.scss";
import classNames from "classnames";
import IconButton from "../IconButton/IconButton";
import {useTranslation} from "react-i18next";
const ShiftCourierControlDepot = ({selectedDepots, removeDepot, onViewAll}) => {
    const {t} = useTranslation();

    return (
        <div className={classNames("shiftCourierControlDepotsSelect")}>
            <div className={classNames("shiftCourierControlDepotsSelectHeader")}>
                <span className={classNames("labelControl")}>{t("shifts.DEPOT")}: {selectedDepots && selectedDepots.length ? "" : t("shifts.ALL")}</span>
                <span onClick={onViewAll} className={classNames("viewAll")}>{t("shifts.VIEW_ALL")} ></span>
            </div>
            {!!selectedDepots && !!selectedDepots.length && (
                <div className={classNames("selectedDepots")}>
                    {selectedDepots.map(d => (
                        <div key={d.id} className={classNames("depot")}>{d.name} <IconButton onClick={() => removeDepot(d.id)} isIcon iconName={"iconPlus"}/> </div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default ShiftCourierControlDepot;