import {getInitModel} from "../services/initModels";
import {MODEL_TYPE} from "../utils/enums";
import {convertToBoolean} from "../utils/objects-util.js";
import {fixedNumber} from "../utils/math-util.js";

export function categoryProductModel(data) {
    const item = data || {};
    const newItem  = {
        id: item.id,
        name: item.name || "",
        price: item.price || 0,
		sku: item.sku || "",
        description: item.description || "",
        order: item.order || 0,
        featured: item.featured || false,
        upselling: item.upselling || false,
        enabled: item.enabled || false,
        vegan: item.vegan || false,
        vegetarian: item.vegetarian || false,
        gluten_free: item.gluten_free || false,
        allow_comments: item.allow_comments || false,
        spicy: item.spicy || 0,
        ingredients: item.ingredients || [],
        extra_ids: item.extra_ids || [],
        images: item.images,
        image_settings: item.image_settings,
        enable_schedule: item.enable_schedule || false,
        schedule: getInitModel(MODEL_TYPE.scheduleModel, item.schedule),
        max: item.max || 0,
        price_before_discount: item.price_before_discount || 0,
        tag: item.tag || "",
        unit_weight: item.unit_weight || 0,
        weighed: item.weighed || false,
        weight_range: fixedNumber(item.weight_range * 100,2) || 0,
    };

    return newItem;
}

export const categoryProductModelForOverride = (data) => {
    const model = data || {};
    return {
        name: model.name || '',
        id: model.id,
        description: model.description || '',
        allow_comments: convertToBoolean(model.allow_comments) || false,
        max: model.max || 0,
        sku: model.sku || '',
        spicy: model.spicy ? parseInt(model.spicy) : 0,
        vegetarian: convertToBoolean(model.vegetarian) || false,
        gluten_free: convertToBoolean(model.gluten_free) || false,
        vegan: convertToBoolean(model.vegan) || false,
        upselling: convertToBoolean(model.upselling) || false,
        price_before_discount: model.price_before_discount || 0,
        price: model.price || 0,
        tag: model.tag || "",
        unit_weight: model.unit_weight || 0,
        weighed: convertToBoolean(model.weighed) || false,
        weight_range: fixedNumber(model.weight_range * 100, 2) || 0,
    }
}

export const createProductOverrideModel = data => {
    const model = {...data} || {};
    const overridesProperty = [];

    if(model && model.overrides) {
        model.overrides.forEach(ov => {
            if(ov.id === model.id) {
                model[ov.name] = ov.value;
                overridesProperty.push(ov.name);
            }
        })
    }

    return {
        ...categoryProductModelForOverride(model),
        overridesProperty: overridesProperty,
    }
}
