import { getDefaultUserRolesValue, getFullName, separateName } from "../services/userModel";
import { MODEL_TYPE } from "../utils/enums";

export function userModel(user) {
  const userData = user || {};
  
  return {
    allowOpenShift: userData.allowOpenShift || false,
    allowedControlCenters: userData.allowedControlCenters || [],
    capacity: userData.capacity || 0,
    creationDate: userData.creationDate,
    depotId: userData.depotId,
    defaultControlCenterId: userData.defaultControlCenterId,
    email: userData.email || "",
    firstName: userData.firstName || "",
    fullName: getFullName(userData),
    hasPassword: userData.hasPassword || false,
    hourlyRate: userData.hourlyRate || 0,
    id: userData.id,
    lastName: userData.lastName || "",
    phoneNumber: userData.phoneNumber || "",
    roles: {
      ...getDefaultUserRolesValue(),
      ...(userData.roles || {})      
    },
    userName: userData.userName,
    workerId: userData.workerId,
    salaryCalculationMode: userData.salaryCalculationMode || 0,
    userId: userData.userId,
    vehicleType: userData.vehicleType,
    modelType: MODEL_TYPE.user
  }
}

export function userServerModel(user) {
  const userData = user || {};
  const name = separateName(userData.fullName);

  return {
    AllowOpenShift: userData.allowOpenShift || false,
    AllowedControlCenters: userData.allowedControlCenters || [],
    Capacity: userData.capacity || 0,
    CreationDate: userData.creationDate,
    DefaultControlCenterId: userData.defaultControlCenterId,
    Email: userData.email || "",
    FullName: userData.fullName,
    FirstName: name.firstName,
    HasPassword: userData.hasPassword || false,
    HourlyRate: userData.hourlyRate || 0,
    LastName: name.lastName,
    PhoneNumber: userData.phoneNumber || "",
    Roles: Object.keys(userData.roles || [])
      .filter(key=> userData.roles && userData.roles[key])
      .map(key=> ({ Name: key.toFirstCharUpperCase() })),
    UserName: userData.userName,
    WorkerId: userData.workerId,
    SalaryCalculationMode: userData.salaryCalculationMode || 0,
    VehicleType: userData.vehicleType
  }
}