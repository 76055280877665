import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import "./index.scss";

import User from "../../../services/user";
import { getStaticTime } from "../../../utils/convertTime";
import { DELIVERY_STRATEGY } from "../../../utils/enums";

import IconButton from "../IconButton";
import TimeBox from "../TimeBox/TimeBox";

const TimeAsap = ({ value, isWithIcon = false }) => {
    const { t } = useTranslation();
    
    if(!value || value.deliveryStrategy !== DELIVERY_STRATEGY.asap) 
        return (<div className={classnames("hideElement")}></div>);

    const alwaysShowOrderTargetTime = User.instance.getCurrentUserConfigurationProp("alwaysShowOrderTargetTime");
    return (
        <div className={classnames("timeAsap")}>
            {isWithIcon && (
                <IconButton isIcon iconName={alwaysShowOrderTargetTime ? "targetTime" : "time"} 
                    tooltipText={alwaysShowOrderTargetTime
                        ? t("orders.ALWAYS_SHOW_ORDER_TARGET_TIME")
                        : t("restaurateur.orders.ORDER_CREATION_TIME")
                    } 
                />
            )}
            {alwaysShowOrderTargetTime && getStaticTime(value.targetTime, false, true)}
            {!alwaysShowOrderTargetTime && (<TimeBox date={value.date} shortLabel />)}
        </div>
    );
}

export default TimeAsap;