let singleton = Symbol();
let singletonEnforcer = Symbol();
let db = null;

export default class DatabaseStorage {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use DatabaseStorage.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new DatabaseStorage(singletonEnforcer);
      return this[singleton].initDataStorage().then(() => this[singleton]);
    }

    return Promise.resolve(this[singleton]);
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  initDataStorage() {
    const indexedDB =
      window.indexedDB ||
      window.webkitIndexedDB ||
      window.mozIndexedDB ||
      window.OIndexedDB ||
      window.msIndexedDB;
      
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("storagedb");
      request.onupgradeneeded = function() {
        db = request.result;
        let store = db.createObjectStore("datas", { keyPath: "key" });
        store.createIndex("by_storageData", "storageData");
        store.createIndex("by_expireTimePicture", "expireTimePicture");
      };
      request.onsuccess = function(event) {
        db = request.result;
        resolve(request.result);
      };
      request.onerror = function() {
        reject(request.error);
      };
    });
  }

  getStoreDataKeys() {
    return new Promise((resolve, reject) => {
      let store = db
        .transaction(["datas"], "readwrite")
        .objectStore("datas", "datas", { keyPath: "key" });
      let request = store.getAllKeys();

      request.onsuccess = function(response) {
        resolve({ result: response.currentTarget.result });
      };
      request.onerror = function(error) {
        reject(error);
      };
    });
  }

  getStoreDataByKey(key) {
    return new Promise((resolve, reject) => {
      let store = db
        .transaction(["datas"], "readwrite")
        .objectStore("datas", "datas", { keyPath: "key" });
      let request = store.get(key);

      request.onsuccess = function(response) {
        resolve({ data: response.currentTarget.result.storageData });
      };
      request.onerror = function(error) {
        reject(error);
      };
    });
  }

  addToStoreData(key, value) {
    return new Promise((resolve, reject) => {
      let store = db
        .transaction(["datas"], "readwrite")
        .objectStore("datas", "datas", { keyPath: "key" });
      let request = store.put({
        expireTimePicture: null,
        storageData: value,
        key: key
      });
      request.onsuccess = function() {
        resolve();
      };
      request.onerror = function(error) {
        reject(error);
      };
    });
  }
}
