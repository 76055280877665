import { MODEL_TYPE, COURIER_LOCATION_STATE } from "../utils/enums";
export const travelDataModel = {
  accuracy: 0,
  background: false,
  batteryPercentage: 0,
  bearing: 0,
  gpsDisabled: false,
  location: {
    latitude: 0,
    longitude: 0,
    modelType: MODEL_TYPE.payments
  },
  lowPowerMode: 0,
  progress: null,
  speed: 0,
  state: COURIER_LOCATION_STATE.lost,
  stoppedAtAllowedLocation: false,
  timestamp: null,
  modelType: MODEL_TYPE.travelDataModel
};
