import {getWeekDays} from "../utils/enums";

export function scheduleModel(data) {

    return (data && data.length > 0)
        ? data.map((schedule, index)=> {
            const weekDay = getWeekDays().find(f=> f.key === index);
            return ({ ...schedule, id: index, name: weekDay.value });
        })
        : getWeekDays().map(weekDay=> ({ id: weekDay.key, name: weekDay.value, enabled: true, lapses: [] }));
}
