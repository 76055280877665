import MenuModel from './menuModel';

import { MARKETPLACE_SETTINGS_MODEL } from '../utils/enums';

export function createDeliveryMenu() {
    return [
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.deliveryZones,
            title: "settings.deliveryZones.LABEL",
            icon: "deliveryZone",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.deliveryZones)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.customerDeliveryPrice,
            title: "settings.customerDeliveryPrice.LABEL",
            icon: "deliveryPrice",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.customerDeliveryPrice),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.driverTip,
            title: "settings.driverTip.MENU_LABEL",
            icon: "driverTip",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.driverTip)
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
