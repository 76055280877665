import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./index.scss";

import { CYCLE_STATE } from "../../../utils/enums";
import { Avatar } from "../Avatar/Avatar";
import { getFullName } from "../../../services/userModel";
import VehicleIcon from "../VehicleIcon";
import {List} from "react-virtualized";
import classNames from "classnames";
import AutoSizer from "react-virtualized-auto-sizer";

class AssociatePopupGroup extends React.PureComponent {
  isCourierHasOrders = courier => {
    const { orders } = this.props;
    return !!orders.filter(order => order.groupId === courier.userId)?.length;
  };

  rowRenderer = ({ index, key, style}, checked) => {
    const {
      t,
      couriers,
      handleChecked
    } = this.props;

    const courier = couriers[index];

    return (
        <div key={courier.userId + key}
            style={style}
            className="courierGroupItem"
            onClick={e => handleChecked(courier.userId, e)}
        >
          <Avatar avatar={courier.avatarUrl} color={courier.color} />
          <div className="courierInfo">
                <span className="courierInfoTitle">
                  <VehicleIcon value={courier.vehicleType} isActive={true} />&nbsp;&nbsp;
                  {getFullName(courier)}
                </span>
            {courier.cycle && courier.cycle.state === CYCLE_STATE.started && (
              <>
                {this.isCourierHasOrders(courier) && (
                    <span className="courierInfoLabel">
                        {t("dialogs.couriers.AWAITING_APPROVAL_LABEL")}
                      </span>
                )}
                {!this.isCourierHasOrders(courier) && (
                    <span className="courierInfoLabel">
                        {t("dialogs.couriers.WAITING_FOR_ORDERS")}
                      </span>
                )}
              </>
            )}
            {courier.cycle && courier.cycle.state === CYCLE_STATE.approved && (
              <span className="courierInfoLabel">
                {t("dialogs.couriers.READY_LABEL")}
              </span>
            )}
            {courier.cycle && courier.cycle.state === CYCLE_STATE.deliveryStart && (
              <span className="courierInfoLabel">
                {t("dialogs.couriers.ON_ROUTE_LABEL")}
              </span>
            )}
          </div>
          <div className="courierCheck">
            <input
                type="checkbox"
                id={`checkbox-${courier.userId}`}
                name="check"
                checked={checked === courier.userId}
                onChange={e => handleChecked(courier.userId, e)}
            />
            <label htmlFor={`checkbox-${courier.userId}`} />
          </div>
        </div>
    );
  };

  render() {
    const {
      couriers,
      title,
      emptyTitleText,
      checked
    } = this.props;
    const makeHeight = couriers?.length * 60 > window.innerHeight ? window.innerHeight : couriers.length * 60;

    return (
      <div className="courierGroupContainer">
        <div className="headerGroup">
          <span>{title ? title : ""}</span>
        </div>
        <div style={{height: makeHeight}}>
          {!!couriers.length && (<AutoSizer >
            {({height, width}) => {
              return <List
                  className={classNames("listContent")}
                  width={width}
                  height={height}
                  rowHeight={60}
                  rowRenderer={(data) => this.rowRenderer(data, checked)}
                  rowCount={couriers.length}
                  overscanRowCount={1}
              />
            }}
          </AutoSizer>)}
        </div>

        {(!couriers || !couriers.length) &&
          emptyTitleText && (
            <div className="containerEmpty">
              <span className="empty">{emptyTitleText}</span>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.order.data.orders || []
});

export default connect(mapStateToProps)(withTranslation()(AssociatePopupGroup));
