import ControlModel from '../../models/controlModel';
import { MODEL_CONTROL_TYPE } from '../../utils/enums.js';
import { useDispatch } from 'react-redux';
import { createAction } from '../../utils/sagaHelper.js';
import React from 'react';
import classNames from 'classnames';
import IconButton from '../../app/components/IconButton/IconButton.jsx';

export function createImportFileControlModel({ ...args }) {
  return new ControlModel({
    action: args.action || '',
    tooltipText: args.tooltipText || '',
    text: args.text || '',
    icon: args.icon || '',
    isShow:
      args.isShow ||
      function() {
        return true;
      },
    type: MODEL_CONTROL_TYPE.importFile,
    callback: args.callback || null,
  });
}

export const ImportFileControl = ({ control }) => {
  const dispatch = useDispatch();
  const { action, text, tooltipText, icon, callback } = control; // icon

  const handleImportFile = event => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file, file?.name);
    dispatch(createAction(action, { file: formData })).then(response => {
      callback && callback(response);
    });
  };

  return (
    <div>
      <label htmlFor={'file-upload'} style={{ width: 'auto' }}>
        <div className={classNames("btn", "confirm", "filterTriggerControl")}
        >
          <span className={"filterTriggerControlTitle"}>{text}</span>
          <IconButton isIcon iconName={icon} tooltipText={tooltipText} />
        </div>
      </label>
      <input
        id={'file-upload'}
        name={'file-upload'}
        style={{ display: 'none' }}
        type={'file'}
        value={''}
        accept='.xlsx'
        onChange={event => handleImportFile(event)}
      />
    </div>
  );
};
