import React, {createRef, PureComponent} from "react";
import classNames from "classnames";
import "./index.scss";

import {isRTL} from "../../../services/userModel";
import {sortItemsByProp} from "../../../services/filter";
import {defaultPromiseResolve, isEmpty} from "../../../utils/objects-util";

import IconButton from "../IconButton";

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      beforeValue: '',
      valueText: props.value === 0 ? props.value : props.value || "",
      isOpen: false
    };

    this.ref = createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    try {
      document.removeEventListener("click", this.handleClickOutside, false);
    } catch(e) {}
  }

  componentDidUpdate(prevProps, prevState) {

    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ valueText: value || "" });
    }
  }

  isExistValue = (values, propName, value) => {
    return values.find(item => !item.skipForExistCheck && this.getLabelItemValue(item, propName) === value);
  };

  onInputChange = inputValue => {
    const { values, searchBy, onChange, onRemove, canPrint = true } = this.props;
    if(!canPrint) return

    if(onChange)
      onChange(inputValue);
    if(onRemove && !inputValue)
      onRemove();
    this.setState({ valueText: inputValue || "", isOpen: true });

    const existValue = this.isExistValue(values, searchBy, inputValue)
    if(existValue) {
      this.handleOnSelectItem(existValue, true);
    }
  };

  handleClickOutside = event => {
    if (this.ref && this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({ isOpen: false });

      const { valueText, beforeValue } = this.state;
      const { isCanBeEmpty, values, searchBy, ignoreBeforeValue } = this.props;
      const isCanBeEmptyValue = isCanBeEmpty ?? true;

      const existValue = this.isExistValue(values, searchBy, valueText);

      if(!ignoreBeforeValue) {
        if((valueText === '' && !isCanBeEmptyValue) || (isCanBeEmptyValue && !existValue)) {
          this.setState({ valueText: beforeValue, isOpen: false });
        }
      }
    }
  };

  handleOnBlur = () => {
    const { valueText, beforeValue } = this.state;
    const { isCanBeEmpty } = this.props;
    const isCanBeEmptyValue = isCanBeEmpty ?? true;

    if(valueText === '' && !isCanBeEmptyValue) {
      this.setState({ valueText: beforeValue, isOpen: false });
    }
  }

  handleOnClick = () => {
    const { isOpen } = this.state;
    const { readonly, values, searchBy } = this.props;

    if(!readonly) {
      const { valueText, beforeValue } = this.state

      const existValue = this.isExistValue(values, searchBy, valueText)

      this.setState({ isOpen: !isOpen });
      this.setState({ beforeValue: existValue ? valueText : beforeValue});
    }
  };

  handleOnSelectItem = (item, withoutClose) => {
    const { onSelect } = this.props;
    const { isOpen } = this.state;
    this.setState({ valueText: this.getLabelItemValue(item), isOpen: withoutClose ? isOpen : false, textWasChanged: false }, () => onSelect(item));
  };

  getLabelItemValue = (item, prop) => {
    const labelField = prop || this.props.labelField;
    let labelValue = "";

    if(Array.isArray(labelField)) {
      labelField.forEach(element => {
        if(!isEmpty(item[element]))
          labelValue += ` ${item[element]}`;
      });
    } else {
      labelValue = item[labelField || "value"];
    }

    return labelValue || "";
  }

  getItems = () => {
    const { values, searchBy, sortBy, searchOff } = this.props;
    const { valueText } = this.state;

    const items = valueText && !searchOff && !this.isExistValue(values, searchBy, valueText)
        ? values.filter(item => this.getLabelItemValue(item, searchBy).toString()?.toLowerCase()?.includes(valueText?.toString()?.toLowerCase()))
        : values;

    return items && (sortBy ? sortItemsByProp(items, sortBy) : items);
  }

  handleOnFocus = () => {
    const { onFocus, readonly } = this.props;
    if(!readonly && onFocus) {
      onFocus();
    }
  }

  render() {
    const {
      placeholder,
      valueField,
      readonly,
      isRequired,
      isChanged,
      isColors,
      accentuationValue,
      isAccentuationByValue
    } = this.props;
    const { valueText, isOpen } = this.state;

    return (
        <div ref={this.ref}
             className={classNames("selectEditable", {
               rtl: isRTL(),
               readonly: readonly,
               required: isRequired,
               changed: isChanged
             })}
        >
          <div className={classNames("inputItem", { required: isRequired })}>
            <input
                placeholder={placeholder || ""}
                type="text"
                value={valueText}
                onClick={this.handleOnClick}
                onChange={e => !readonly && this.onInputChange(e.currentTarget.value)}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
            />
            {isColors && valueText && (
                <span className={classNames("colorItem")} style={{ backgroundColor: valueText }}></span>
            )}
          </div>
          <div className={"drop-down-icon-wrapper"}>
            <div className={classNames("drop-down-icon")} />
          </div>
          <div className={classNames("select-items", { visible: isOpen })}>
            {this.getItems().map((item, index) => (
                <div key={`key-${item[valueField || "key"]}-${this.getLabelItemValue(item)}-${index}`}
                     className={classNames("select-item", {
                       selected: (accentuationValue && accentuationValue === this.getLabelItemValue(item)) ||
                           (isAccentuationByValue && valueText === this.getLabelItemValue(item)),
                       isColors: isColors,
                       isGroupTitle: item?.isGroupTitle
                     })}
                     onClick={() => !item?.isGroupTitle && this.handleOnSelectItem(item)}
                >
                  <div className={classNames("itemText", { isColors: isColors })}>{this.getLabelItemValue(item)}</div>
                  {!isColors && ((accentuationValue && accentuationValue === this.getLabelItemValue(item)) ||
                      (isAccentuationByValue && valueText === this.getLabelItemValue(item))) && (
                      <IconButton isIcon={true} iconName={"applyActive"} onClick={defaultPromiseResolve} />
                  )}
                  {isColors && (
                      <span className={classNames("colorItem")} style={{ backgroundColor: item[valueField || "key"] }}></span>
                  )}
                  {isColors && ((accentuationValue && accentuationValue === this.getLabelItemValue(item)) ||
                      (isAccentuationByValue && valueText === this.getLabelItemValue(item))) && (
                      <IconButton isIcon={true} iconName={"applyActive"} onClick={defaultPromiseResolve} />
                  )}
                </div>
            ))}
          </div>
        </div>
    );
  }
}

export default Dropdown;
