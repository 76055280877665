import React, {useState} from 'react';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import './index.scss';
import i18n from '../../../i18n';
import IconButton from '../IconButton/IconButton';
import CurrentShiftCourierEditPopup from '../CurrentShiftCourierEditPopup';
import {createAction} from '../../../utils/sagaHelper';
import {
  AVAILABLE_CONTROL_CENTERS,
  USER_CURRENT_SHIFTS_EDIT,
} from '../../../store/reducers/userModel/userModel-actions';
import {connect} from 'react-redux';
import {FLEET_SETTINGS_GET} from "../../../store/reducers/restaurant/restaurant-actions.js";

const CurrentShiftCourierEdit = ({ updateHistoryShift, courier, updateCurrentShift, selectedControlCenter, availableControlCenters, getAvailableControlCenters, fleet, getDefaultFleet }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [controlCenterId, setControlCenterId] = useState(null);
  const [selectedFleet, setSelectedFleet] = useState(null);
  const courierControlCenter =  courier?.cycle?.controlCenterId || selectedControlCenter

  const handleOnClick = () => {
    const courierId = courier && courier.userId;
    getAvailableControlCenters(courierId);
    getDefaultFleet();

    setPopupOpen(true);
  };

  const handleOnCourierShift = (courierId, data) => {
    if (courierId) {
      const shiftId = updateHistoryShift ? courier.shiftId : null;
      return updateCurrentShift({
        courierId: courierId,
        vehicleId: selectedFleet,
        controlCenterId: controlCenterId || courierControlCenter,
        shiftId,
        ...data
      }).then(() => setPopupOpen(false));
    }
  };

  const hanldeOnPopupClose = data => {
    const courierId = courier && courier.userId;
    handleOnCourierShift(courierId, data);
  };

  return (
    <div className={classNames('currentShiftCourierEdit')}>
      <IconButton
        isIcon
        iconName={'editItem'}
        tooltipText={i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP')}
        onClick={() => handleOnClick()}
      />
      {popupOpen && (
        <CurrentShiftCourierEditPopup
          courierCycle={courier?.cycle}
          popupOpen={popupOpen}
          fleet={fleet}
          setSelectedFleet={setSelectedFleet}
          hanldeOnPopupClose={hanldeOnPopupClose}
          setControlCenterId={setControlCenterId}
          selectedControlCenter={courierControlCenter}
          controlCenterId={controlCenterId}
          availableControlCenters={availableControlCenters}
          onClose={() => setPopupOpen(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedControlCenter: state.restaurant.data.selectedControlCenter,
    availableControlCenters: state.restaurant.data.availableControlCenters,
    fleet: state.restaurant.data.fleet,
  };
};

const mapDispatchToProps = dispatch => ({
  updateCurrentShift: data => dispatch(createAction(USER_CURRENT_SHIFTS_EDIT, data)),
  getAvailableControlCenters: data => dispatch(createAction(AVAILABLE_CONTROL_CENTERS, data)),
  getDefaultFleet: () => dispatch(createAction(FLEET_SETTINGS_GET, [])),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CurrentShiftCourierEdit));
