export function extraModel(data) {
    let model = data || {};

    return {
        id: model.id || "",
        name: model.name || "",
        enabled: model.enabled || false,
        description: model.description || "",
        options: model.options || []
    }
}

export function extraModelOverride(data) {
    let model = data || {};

    return {
        id: model.id || "",
        name: model.name || "",
        description: model.description || "",
    }
}

export function extraModelWithOverrides(data){
    const model = {...data} || {};
    const overridesProperty = [];

    if(model.overrides) {
        model.overrides
            .filter(el => el.id === model.id)
            .forEach(ov => {
                if(ov.id === model.id) {
                    model[ov.name] = ov.value;
                    overridesProperty.push(ov.name);
                }
            })
    }

    return {
        ...extraModelOverride(model),
        overridesProperty: overridesProperty || [],
    }
}
