import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {saveAs} from 'file-saver';
import Store from '../store';

import {
    actionAddDepotUser,
    actionAddNewMarketplaceMessage,
    actionAddNewMarketplacePage,
    actionControlCenterRefresh,
    actionDeleteMarketplaceMessage,
    actionDeleteMarketplacePage,
    actionDepotDelete,
    actionDepotEdit,
    actionDepotNew,
    actionGetBusinessCategories,
    actionGetMessagesData,
    actionGetOperationTiming,
    actionGetSettingsDeliveryZones,
    actionLoadFleet,
    actionLoadFleetTelemetry,
    actionLoadFleetUpdate,
    actionLoadCloudPrintersSetting,
    actionLoadCloudPrintersSettingDetails,
    actionLoadProvidersSetting,
    actionLoadProvidersSettingDetails,
    actionLoadRestaurantFail,
    actionLoadRestaurantSuccess,
    actionRestaurantStateUpdate,
    actionRestaurantUpdate,
    actionSaveDepotAvatar,
    actionSaveDepotsAvatars,
    actionSaveDepotUsers,
    actionSaveShifts,
    actionSaveShiftsHistory,
    actionSetBusinessMenuAvatar,
    actionSetBusinessMenuHeaderMini,
    actionSetCategoryProductAvatar,
    actionSetDepotAvatar,
    actionSetDepotPaymentState,
    actionSetGiftCardBusinessMenuAvatar,
    actionSetGiftCardProductAvatar,
    actionSetRestaurantPaymentState,
    actionUpdateCloudPrintersSetting,
    actionUpdateMarketplaceMessage,
    actionUpdateMarketplaceMessageGalley,
    actionUpdateMarketplaceMessageGalleyAdd,
    actionUpdateMarketplacePage,
    actionUpdateProvidersSetting,
    actionUpdateRestaurantControlCenters,
    actionUpdateRestaurantDeliveryZone,
    BUSINESS_CATEGORY_ADD,
    BUSINESS_CATEGORY_DELETE,
    BUSINESS_CATEGORY_EDIT,
    BUSINESS_CATEGORY_GET,
    BUSINESS_CATEGORY_UPDATE,
    BUSINESS_MENU_GET,
    BUSINESS_PARENT_MENU_GET,
    BUSINESS_PARENT_MENU_UPDATE,
    CHECK_SQUAREUP_AUTHORIZED_REQUIRED,
    CLOUD_PRINTERS_SETTING_ADD,
    CLOUD_PRINTERS_SETTING_DELETE,
    CLOUD_PRINTERS_SETTING_DETAILS_GET,
    CLOUD_PRINTERS_SETTING_EDIT,
    CLOUD_PRINTERS_SETTING_GET,
    CONTROL_CENTER_REFRESH,
    COPY_TELEGRAM_COMMAND,
    COURIER_DELIVERY_FEES_SETTING_EDIT,
    COURIER_DELIVERY_FEES_SETTING_GET,
    COURIER_SETTINGS_EDIT,
    COURIER_SETTINGS_GET,
    CURRENT_SHIFT_USERS,
    DEPOT_BUSINESS_DEEP_LINK_CREATE,
    DEPOT_BUSINESS_PRODUCT_DEEP_LINK_CREATE,
    DEPOT_BUSINESS_TRANSLATION_EDIT,
    DEPOT_BUSINESS_TRANSLATION_EXPORT,
    DEPOT_BUSINESS_TRANSLATION_GET,
    DEPOT_BUSINESS_TRANSLATION_IMPORT,
    DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_ADD,
    DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_DELETE,
    DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_EDIT,
    DEPOT_INIT_DATA_GET,
    DEPOT_MENU_COPY_EDIT,
    DEPOT_OPERATION_MODE_SETTING_ADD,
    DEPOT_OPERATION_MODE_SETTING_DELETE,
    DEPOT_OPERATION_MODE_SETTING_EDIT,
    DEPOT_OPERATION_MODE_SETTING_GET,
    DEPOT_OPERATION_MODE_SETTING_GET_ALL,
    DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_EDIT,
    DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_RESET,
    DEPOT_SETTING_ADD,
    DEPOT_SETTING_DELETE,
    DEPOT_SETTING_EDIT,
    DEPOTMENU_GET,
    DEPOTMENU_SETTING_CHECK,
    DEPOTMENU_SETTING_EDIT,
    DEPOTPAYMECREDIT_SETTING_EDIT,
    DEPOTPAYMENT_STATE,
    DEPOTPAYMEWALLET_SETTING_ADD,
    DEPOTPRICE_SETTING_APPLY_TO_ADD,
    DEPOTPRICE_SETTING_EDIT,
    DEPOTUSERS_SETTING_ADD,
    DEPOTUSERS_SETTING_DELETE,
    DEPOTUSERS_SETTING_EDIT,
    DEPOTUSERS_SETTING_PASSWORD_EDIT,
    DRIVER_TIP_EDIT,
    DRIVER_TIP_GET,
    FLEET_SETTINGS_ADD,
    FLEET_SETTINGS_DELETE,
    FLEET_SETTINGS_EDIT,
    FLEET_SETTINGS_GET,
    FLEET_SETTINGS_TELEMETRY_BLINK,
    FLEET_SETTINGS_TELEMETRY_GET,
    FLEET_SETTINGS_UNMOUNT,
    GENERAL_SETTING_EDIT,
    GENERAL_SETTING_GET,
    GET_BUSINESS_BY_QUERY,
    GET_DEPOT_PRICE,
    GET_DEPOT_USERS,
    GET_ORDER_STAT,
    GET_ORDERING_MENU_BUSINESS_EXTRA,
    GET_PROVIDER_SHIFT_HISTORY,
    GET_USER_SHIFT_HISTORY,
    GIFT_CARD_BUSINESS_UPDATE_AVATAR,
    GIFT_CARD_EDIT,
    GIFT_CARD_GET,
    GIFT_CARD_PRODUCT_ADD,
    GIFT_CARD_PRODUCT_DELETE,
    GIFT_CARD_PRODUCT_EDIT,
    GIFT_CARD_PRODUCT_REORDER,
    GIFT_CARD_PRODUCT_UPDATE,
    GIFT_CARD_PRODUCT_UPDATE_AVATAR,
    LIST_PROMOTION_SETTING_ADD,
    LIST_PROMOTION_SETTING_DELETE,
    LIST_PROMOTION_SETTING_DRAG,
    LIST_PROMOTION_SETTING_EDIT,
    LIST_PROMOTION_SETTING_GET,
    LOAD_DEPOT_AVATARS,
    LOAD_DEPOT_USER_BY_ID,
    LOAD_RESTAURANT,
    LOYALTY_CAMPAIGNS_ADD,
    LOYALTY_CAMPAIGNS_AUDIENCE_FILTER_COUNT_GET,
    LOYALTY_CAMPAIGNS_EDIT,
    LOYALTY_CAMPAIGNS_EXECUTIONS_ADD,
    LOYALTY_CAMPAIGNS_EXECUTIONS_GET,
    LOYALTY_CAMPAIGNS_EXECUTIONS_UPDATE_BY_ID,
    LOYALTY_CAMPAIGNS_GET,
    LOYALTY_CAMPAIGNS_TOGGLE_ENABLED,
    MARKETPLACE_DEEP_LINK_CREATE,
    MARKETPLACE_MESSAGE_ADD,
    MARKETPLACE_MESSAGE_DELETE,
    MARKETPLACE_MESSAGE_EDIT,
    MARKETPLACE_MESSAGE_TOGGLE_ENABLED,
    MARKETPLACE_MESSAGE_UPDATE,
    MARKETPLACE_PAGES_ADD,
    MARKETPLACE_PAGES_DELETE,
    MARKETPLACE_PAGES_EDIT,
    MARKETPLACE_PAGES_TOGGLE_ENABLED,
    MARKETPLACE_PAGES_UPDATE,
    MARKETPLACE_SETTINGS_EDIT,
    MENU_TEMPLATE_ADD,
    MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING_EDIT,
    MENU_TEMPLATE_DELETE,
    MENU_TEMPLATE_EDIT,
    MENU_TEMPLATE_GET,
    MESSAGE_GALLERY_DELETE,
    NAMED_PLACES_ADD,
    NAMED_PLACES_DELETE,
    NAMED_PLACES_EDIT,
    NAMED_PLACES_GET,
    OPERATION_TIMING_SETTING_ADD,
    OPERATION_TIMING_SETTING_DELETE,
    OPERATION_TIMING_SETTING_EDIT,
    OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY,
    OPERATION_TIMING_SETTINGS_GET,
    ORDERING_MENU_BUSINESS_CATEGORIES_ADD,
    ORDERING_MENU_BUSINESS_CATEGORIES_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORIES_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORIES_LIST_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORIES_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_DEPOT_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_ADD,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DEPOT_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ADD,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DEPOT_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_REORDER,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_ADD,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DEPOT_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_REORDER,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_DEPOT_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_GET,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_REORDER,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_ADD,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_AVATAR,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DRAG_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_EDIT,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_UPDATE,
    ORDERING_MENU_BUSINESS_DISCOUNTS_ADD,
    ORDERING_MENU_BUSINESS_DISCOUNTS_DELETE,
    ORDERING_MENU_BUSINESS_DISCOUNTS_EDIT,
    ORDERING_MENU_BUSINESS_EXTRA_ADD,
    ORDERING_MENU_BUSINESS_EXTRA_DELETE,
    ORDERING_MENU_BUSINESS_EXTRA_EDIT,
    ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_ADD,
    ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_DELETE,
    ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_EDIT,
    ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_REORDER,
    ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_UPDATE,
    ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_ADD,
    ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_DELETE,
    ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_EDIT,
    ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_REORDER,
    ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_UPDATE,
    ORDERING_MENU_BUSINESS_EXTRA_UPDATE,
    ORDERING_MENU_BUSINESS_GALLERY_ADD,
    ORDERING_MENU_BUSINESS_GALLERY_DELETE,
    ORDERING_MENU_BUSINESS_GALLERY_EDIT,
    ORDERING_MENU_BUSINESS_GALLERY_REORDER,
    ORDERING_MENU_BUSINESS_INFO_EDIT,
    ORDERING_MENU_BUSINESS_PRODUCT_DELETE_OVERRIDE,
    ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_EDIT,
    ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_EDIT,
    ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_EDIT,
    ORDERING_MENU_BUSINESS_SCHEDULE_EDIT,
    ORDERING_MENU_DELIVERY_ZONES_ADD,
    ORDERING_MENU_DELIVERY_ZONES_DELETE,
    ORDERING_MENU_DELIVERY_ZONES_EDIT,
    ORDERING_MENU_DELIVERY_ZONES_LIST_UPDATE,
    ORDERING_MENU_DELIVERY_ZONES_UPDATE,
    ORDERING_MENU_GET,
    PARENT_MENU_OVERRIDE_CATEGORY_EDIT,
    PARENT_MENU_OVERRIDE_DELETE,
    PARENT_MENU_OVERRIDE_EXTRA_EDIT,
    PARENT_MENU_OVERRIDE_OPTION_EDIT,
    PARENT_MENU_OVERRIDE_PRODUCT_EDIT,
    PARENT_MENU_OVERRIDE_SUBOPTION_EDIT,
    PAYMENTS_CONFIGURATION_SETTING_EDIT,
    PROVIDERS_SETTING_ADD,
    PROVIDERS_SETTING_DELETE,
    PROVIDERS_SETTING_DETAILS_GET,
    PROVIDERS_SETTING_EDIT,
    PROVIDERS_SETTING_GET,
    PUSHER_DEPOT_SERVICES_REFRESH,
    REFERRAL_SETTINGS_EDIT,
    REFERRAL_SETTINGS_GET,
    REGION_SETTING_ADD,
    REGION_SETTING_DELETE,
    REGION_SETTING_EDIT,
    RESTAURANT_CONTROL_CENTER_STATUS_GET,
    RESTAURANT_CONTROL_CENTERS_STATUS_UPDATE,
    RESTAURANT_PAYME_WALLET_SETTING_ADD,
    RESTAURANT_PAYMENT_STATE,
    RESTAURANT_SET_MODE,
    SEARCH_SHORTCUT_SETTING_ADD,
    SEARCH_SHORTCUT_SETTING_DELETE,
    SERVICE_CITIES_SETTING_ADD,
    SERVICE_CITIES_SETTING_DELETE,
    SERVICE_CITIES_SETTING_EDIT,
    SERVICE_CITIES_SETTING_GET,
    SERVICE_CITIES_SETTING_TOGGLE,
    SERVICE_FEE_EDIT,
    SERVICE_FEE_GET,
    SET_ACTIVATE_MODEL,
    SET_DEPOT_AVATAR,
    SET_MENU_CREATE_ORDER,
    SET_ORDERING_MENU_BUSINESS_INFO_AVATAR,
    SET_ORDERING_MENU_BUSINESS_INFO_HEADER_MINI,
    setBusinessMenu,
    setDepotProviders,
    setOrdersProvider,
    setParentBusinessMenu,
    setShiftProviders,
    SETTINGS_CONTROL_CENTER_ACTIVATE_EDIT,
    SETTINGS_CONTROL_CENTER_CHANGEUSER_EDIT,
    SETTINGS_CONTROL_CENTER_DEACTIVATE_EDIT,
    SETTINGS_CONTROL_CENTER_EDIT,
    SETTINGS_CONTROL_CENTER_GET,
    SETTINGS_DELIVERY_ZONES_ADD,
    SETTINGS_DELIVERY_ZONES_DELETE,
    SETTINGS_DELIVERY_ZONES_EDIT,
    SETTINGS_DELIVERY_ZONES_GET,
    SETTINGS_DELIVERY_ZONES_LIST_UPDATE,
    SETTINGS_OFFERS_ADD,
    SETTINGS_OFFERS_DELETE,
    SETTINGS_OFFERS_EDIT,
    SETTINGS_OFFERS_GET,
    SHIFT_PROVIDERS_GET,
    SHIFTS_GET,
    SHIPMENT_PRICE_CONFIGURATION_EDIT,
    SHIPMENT_PRICE_CONFIGURATION_GET,
    TELEGRAM_SETTING_EDIT,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_DELETE,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_EDIT,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_EDIT,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_EDIT,
    LOYALTY_CAMPAIGNS_DELETE,
    EXECUTE_COMPAIGN,
    LOYALTY_SETTINGS_REGISTER_VOUCHER_EDIT,
    LOYALTY_SETTINGS_CASHBACK_EDIT, LOYALTY_SETTINGS_GET,
    BUSINESS_APP_CONFIGURATION_GET,
    BUSINESS_APP_CONFIGURATION_EDIT,
    CANCEL_REASON_GET,
    CANCEL_REASON_ADD,
    CANCEL_REASON_EDIT, CANCEL_REASON_DELETE, MARKETPLACE_MENU_CLEAR_OVERRIDE,
} from '../reducers/restaurant/restaurant-actions';
import {
    actionRemoveMarker,
    actionUpdateDepotMarker,
    actionUpdateDepotsMarkers,
} from '../reducers/marker/marker-actions';
import {
    actionNewUser,
    actionUpdateControlCenters,
    actionUpdateUser,
    actionUpdateUserById,
    DEPOT_INTEGRATIONS_ADD,
    DEPOT_INTEGRATIONS_DELETE,
    DEPOT_INTEGRATIONS_GET,
    DEPOT_INTEGRATIONS_UPDATE,
    DEPOT_PROVIDER_ADD,
    DEPOT_PROVIDER_EDIT,
    DEPOT_PROVIDER_UPDATE,
    DEPOT_PROVIDERS_DELETE,
    DEPOT_PROVIDERS_GET,
    DEPOT_PROVIDERS_REORDER,
    DEPOT_PROVIDERS_UNMOUNT,
    DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION,
    DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION_SHORT,
    DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK,
    DEPOT_SQUARE_ASSOCIATED_LOCATION_EDIT,
    DEPOT_SQUARE_ASSOCIATED_REVOKE,
} from '../reducers/userModel/userModel-actions';

import {
    dataURItoBlob,
    isSameObjects,
    isValidStringNoSpaces,
    mergeArrays,
    objectKeysToUpperLowerCase,
    someObject,
} from '../../utils/objects-util';
import {
    getAllBusinessSuboption,
    getAllEnabledOverrideDataFromMenu,
    getAllExtra,
    getAllOption,
    getAllSuboption,
    getBusinessExtra,
    getBusinessProduct,
    getDeliveryZoneData,
    getDepot,
    getDepotModeId, getDepotNameById,
    getOveridesArray,
    getReportAverageDeliveryTimeStatistics,
    getReportDeliveryTimeStatistics,
    isDisableCitySave,
    isUniqueRegionNames,
    prepareOverrideObject,
} from '../../services/restaurant';

import {
    addBusinessMenuDiscount,
    addBusinessOffer,
    addDepot,
    addDepotIntegration,
    addDepotUser,
    addFleet,
    addMarketplaceCampaign,
    addMenuTemplate,
    addNamedPlace,
    addRestaurantDeliveryZones,
    addSearchShortcut,
    addServiceCity,
    addSettingCloudPrinters,
    addSettingExternalProviders,
    addSettingProviders,
    businessCategoryAdd,
    businessCategoryEdit,
    businessCategoryExtraAdd,
    businessCategoryExtraEdit,
    businessCategoryExtraOptionActivate,
    businessCategoryExtraOptionAdd,
    businessCategoryExtraOptionDelete,
    businessCategoryExtraOptionReorder,
    businessCategoryExtraOptionUpdate,
    businessCategoryExtraRemove,
    businessCategoryExtraSubOptionActivate,
    businessCategoryExtraSubOptionAdd,
    businessCategoryExtraSubOptionDelete,
    businessCategoryExtraSubOptionEdit,
    businessCategoryExtraSubOptionReorder,
    businessCategoryExtraUpdate,
    businessCategoryRemove,
    businessCategoryReorder,
    businessGalleryAdd,
    businessGalleryDelete,
    businessGalleryEdit,
    businessGalleryReorder,
    businessMenuCategoryActivate,
    businessMenuCategoryAdd,
    businessMenuCategoryDelete,
    businessMenuCategoryEdit,
    businessMenuCategoryExtraDelete,
    businessMenuCategoryExtrasActive,
    businessMenuCategoryExtrasAdd,
    businessMenuCategoryExtrasOptionActivate,
    businessMenuCategoryExtrasOptionAdd,
    businessMenuCategoryExtrasOptionDelete,
    businessMenuCategoryExtrasOptionReorder,
    businessMenuCategoryExtrasOptionUpdate,
    businessMenuCategoryExtrasSuboptionActivate,
    businessMenuCategoryExtrasSuboptionAdd,
    businessMenuCategoryExtrasSuboptionDelete,
    businessMenuCategoryExtrasSuboptionReorder,
    businessMenuCategoryExtrasSuboptionUpdate,
    businessMenuCategoryExtrasUpdate,
    businessMenuCategoryProductActivate,
    businessMenuCategoryProductAdd,
    businessMenuCategoryProductDelete,
    businessMenuCategoryProductEdit,
    businessMenuCategoryProductIngredientAdd,
    businessMenuCategoryProductIngredientDelete,
    businessMenuCategoryProductIngredientReorder,
    businessMenuCategoryProductIngredientUpdate,
    businessMenuCategoryProductsReorder,
    businessMenuCategoryReorder,
    changeControlCenterShift,
    changeDepotPassword,
    checkDepotMenu,
    depotCopyTemplateMenu,
    checkSquareUpAuthorizedRequired,
    controlCenterEdit,
    createBusinessDeepLink,
    createBusinessProductDeepLink,
    createMarketplaceCampaignExecutions,
    createMarketplaceDeepLink,
    createMarketplaceMessage,
    createMarketplacePage,
    createOverrideMenu,
    deleteBusinessMenuDiscount,
    deleteBusinessOffer,
    deleteDeliveryZone,
    deleteFleet, deleteMarketplaceCampaign,
    deleteMarketplaceMessage,
    deleteMarketplacePage,
    deleteMenuTemplate,
    deleteNamedPlace,
    deleteRestaurantDeliveryZone,
    deleteSearchShortcut,
    deleteServiceCity,
    deleteSettingCloudPrinters,
    deleteSettingExternalProviders,
    deleteSettingProviders,
    depotDefaultRegionControlCenterAdd,
    depotDefaultRegionControlCenterDelete,
    depotDefaultRegionControlCenterGet,
    depotDefaultRegionControlCenterUpdate,
    depotMenuCopy,
    depotPaymentState,
    depotWallet,
    editDepot,
    editDepotUser,
    editFleet, editLoyaltySettingsCashback, editLoyaltySettingsRegisterVoucher,
    editMarketplaceCampaign,
    editNamedPlace,
    editServiceCity,
    editSettingCloudPrinters,
    endControlCenterShift, executeCompaings,
    exportDepotBusinessTranslation, fetchAddCancelReason,
    fetchBusinessCategory, fetchCancelReason, fetchDeleteCancelReason, fetchEditCancelReason,
    fetchOperations,
    fetchRestaurant,
    fetchSettingCloudPrinters,
    fetchSettingCloudPrintersDetails,
    fetchSettingExternalProvidersById,
    fetchSettingProviderDetails,
    fetchSettingProviders,
    generateDepotAuthorizationUrl,
    getAppShipmentPriceSettings, getBusinessAppSettings,
    getBusinessMenu,
    getBusinessOffer,
    getBusinessWithQuery,
    getControlCenterActive,
    getControlCenterStatus,
    getCourierControlSettings,
    getCourierDisplaySettings,
    getCourierInterfaceSettings,
    getDepotBusinessTranslation,
    getDepotDeliveryPrice,
    getDepotIntegrations,
    getDepotOperationMode,
    getDepotSquareLocations,
    getDepotUsers,
    getDriverTip,
    getFleet,
    getFleetById,
    getFleetTelemetry,
    getFleetTelemetryBlink,
    getGlobalMenuTemplate,
    getListPromotion, getLoyaltySettings,
    getMarketplaceCampaign,
    getMarketplaceCampaignAudienceFilterCount,
    getMarketplaceCampaignExecutions,
    getMenuTemplate,
    getNamedPlaces,
    getReferralSettings,
    getReportOrderStatisticsByDate,
    getRestaurantDeliveryZones,
    getServiceCity,
    getServiceFee,
    getShipmentPriceSettings,
    giftCardEnable,
    giftCardGet,
    importDepotBusinessTranslation,
    listPromotionAdd,
    listPromotionDelete,
    listPromotionReorder,
    listPromotionUpdate, marketplaceMenuClearOverride,
    marketplaceMessageReorder,
    marketplacePagesReorder,
    messageGalleryAdd,
    messageGalleryDelete,
    messageGalleryEdit,
    messageGalleryImageBase64,
    messageGalleryReorder,
    overrideParentBusinessMenu,
    removeDepot,
    removeDepotSquareLocation,
    removeDepotUser,
    removeRegion,
    restaurantDeliveryZoneActivate,
    restaurantPaymentState,
    restaurantWallet,
    setActivateModel,
    setAppShipmentPriceSettings,
    setBusinessCategoryAvatarBase64,
    setBusinessGalleryImageBase64,
    setDeliveryZone,
    setDepotAvatarBase64,
    setDepotBusinessTranslation,
    setDepotDeliveryPrice,
    setDepotSquareAssociatedLocation,
    setDepotSquareAssociatedRevoke,
    setMarketplaceBannerBase64,
    setMarketplacePageBannerBase64,
    setMarketplaceSettingsHeader,
    setMode,
    setNewRegionInfo,
    setNotificationSettings,
    setNumericalSettings,
    setServiceFee,
    setShipmentPriceSettings,
    startControlCenterShift,
    telegramSettings,
    toggleActivateMarketplaceCampaign,
    toggleServiceCity, updateBusinessAppSettings,
    updateBusinessMenuCategoryProductAvatar,
    updateBusinessMenuDiscount,
    updateBusinessMenuHeaderAvatar,
    updateBusinessMenuHeaderMini,
    updateBusinessMenuInfo,
    updateBusinessOffer,
    updateCourierControlSettings,
    updateCourierDisplaySettings,
    updateCourierInterfaceSettings,
    updateDeliveryZone,
    updateDepotCategoryStates,
    updateDepotIntegration,
    updateDepotMenu,
    updateDepotOperationMode,
    updateDriverTip,
    updateEnableDeliveryZone,
    updateMarketplaceCampaignExecutionById,
    updateMarketplaceMessage,
    updateMarketplacePage,
    updateMarketplaceSettings,
    updateMenuTemplate,
    updateOperations,
    updateOrderingMenuDeliveryZone,
    updateOverrideMenu,
    updateReferralSettings,
    updateRegionInfo,
    updateRestaurantDeliveryZones,
    updateSchedule,
} from '../../api/restaurant-requests';
import {
    getConfigurationRegions,
    getControlCenter,
    getMenuTemplates,
    getNotifications,
    getProvidersAll,
    getRestaurant,
    getRestaurantDepots,
    getSectionModel,
    getUserModel,
} from '../selectors';
import {
    CREDIT_ACCEPT,
    EXTERNAL_COURIER_PROVIDER,
    ICONS_MARKER_TYPE,
    INTEGRATION_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_TYPE,
    NEW_BUSINESS_MENU_TYPE,
    OFFER_DISCOUNT_TARGET,
    ORDER_STATE,
    ORDERING_MENU_TYPE_SECTION_MODEL,
    SHIFT_TYPE,
    TYPE_EDIT_ACTION,
    TYPE_SECTION_DEPOT_MODEL,
    TYPE_SECTION_MODEL,
} from '../../utils/enums';
import {getInitModel} from '../../services/initModels';
import i18n from '../../i18n';

import Pusher from '../../services/pusher';
import MarkerIcons from '../../services/markericons';
import AudioService from '../../services/audio';
import {currentUserIsDepotRole, getFullName, getRolesFromUser, isValidPasswordData} from '../../services/userModel';
import {locationIsEmpty} from '../../services/order';

import {getISOTime, isDatesValid, minutesToTime, prepareEndDate, prepareStartDate} from '../../utils/convertTime';
import {checkRequestResultStatus, getErrorMessage, requestResultIsOk} from '../../utils/request-util';
import {fetchMarkerAvatarBase64, isBase64Image, isBase64Video} from '../../utils/image-util';
import {createAction, createSaga, ErrorData, ErrorDataTable} from '../../utils/sagaHelper';
import {getHistoryLocationParams} from '../../utils/routesHelper';

import {
    createVirtualDeliveryProvider,
    deleteVirtualDeliveryProvider,
    editDeliveryProvider,
    fetchDeliveryProviders,
    fetchExternalProviders,
    fetchShiftProviders,
    getReportDeliveryTimeByDate,
    getShift,
    getShifts,
    getShiftsProvider,
    reorderDeliveryProviders,
    updateVirtualDeliveryProvider,
} from '../../api/user-requests';
import {actionSetSectionItemsLoading} from '../reducers/sectionsWrapperData/sectionsWrapperData-actions';
import {
    actionAlarmAdd,
    actionAlarmDelete,
    actionAlarmRestart,
    actionNotificationUpdate,
    NOTIFICATION_ADD,
    NOTIFICATION_DELETE,
    NOTIFICATIONS_RESTART,
} from '../reducers/notification/notification-actions';
import {setMarketplace, setMarketplacePages} from '../reducers/marketplace/marketplace-actions';
import {actionGlobalPortalDataUpdate} from '../reducers/globalPortalData/globalPortalData-actions';
import {
    addMenuTemplateAC,
    deleteMenuTemplateAC,
    setGlobalMenuTemplates,
    setMenuTemplatesAC,
    updateMenuTemplateAC,
} from '../reducers/menuTemplate/menuTemplate-actions';
import {getCallCenterMenu, getMergedBusinessMenuById} from '../../api/order-requests';
import {
    GET_ORDERS_PROVIDER,
    MARKETPLACE_MESSAGE_GALLERY_ADD,
    MARKETPLACE_MESSAGE_GALLERY_EDIT,
    MARKETPLACE_MESSAGE_GALLERY_REORDER,
} from '../reducers/order/order-actions';
import moment from 'moment';
import {getUserByIdSaga, getUsersByIdsSaga} from './userModel-saga.js';
import {
    marketplaceCampaignClientModel,
    marketplaceCampaignExecutionsModel,
    marketplaceCampaignServerModel,
} from '../../models/marketplaceCampaignModel.js';
import {settingsTranslationsServerModel} from '../../models/settingsTranslationsModel.js';
import {getIsSectionsWrapperLoading} from '../selectors.js';
import {providersApiMap, transformProvidersToApi} from '../../models/providerModel.js';
import {
    actionGlobalPopupMessage,
    actionGlobalPopupMessageInit
} from "../reducers/globalPopupMessage/globalPopupMessage-actions.js";
import {fixedNumber} from "../../utils/math-util.js";

export function* restaurantSaga(action) {
    try {
        if (action.restaurant && action.keys) {
            const currentRestaurant = yield select(getRestaurant);
            const restaurant = objectKeysToUpperLowerCase(action.restaurant);
            const namedPlaces = objectKeysToUpperLowerCase(action.namedPlaces);
            const consumerOrderMenu = objectKeysToUpperLowerCase(action.consumerOrderMenu);
            const operations = objectKeysToUpperLowerCase(action.operations);
            let appShipmentPriceSettings = {};

            if (action.appShipmentPriceSettings) {
                Object.keys(action.appShipmentPriceSettings).forEach(key => {
                    appShipmentPriceSettings[`app${key}`] = action.appShipmentPriceSettings[key];
                });
            }
            yield put(
                actionLoadRestaurantSuccess({
                    ...restaurant,
                    namedPlaces: namedPlaces,
                    appShipmentPriceConfiguration: appShipmentPriceSettings,
                    consumerOrderMenu: consumerOrderMenu || currentRestaurant.consumerOrderMenu,
                    operations,
                    initialControlCenters: restaurant.controlCenters,
                })
            );
            return true;
        }

        return false;
    } catch (e) {
        yield put(actionLoadRestaurantFail('Error load restaurant'));
    }
}

export function* controlCenterStatusSaga(action) {
    yield* createSaga(function*() {
        const api = action.data ? getControlCenterActive : getControlCenterStatus;
        yield put(actionSetSectionItemsLoading(true));

        const requestResult = yield call(api, action.data);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data || []);
                const restaurant = yield select(getRestaurant);
                const users = (restaurant.controlCenters || []).map(center => {
                    const newControlCenter = data.find(f => f.controlCenterId === center.controlCenterId) || {};
                    return {
                        userId: (newControlCenter.info && newControlCenter.info.userId) || center.userModel,
                        controlCenterId: newControlCenter.controlCenterId || center.controlCenterId,
                    };
                });

                const centers = mergeArrays(
                    restaurant.controlCenters || [],
                    data.map(availableControlCenter => ({
                        ...availableControlCenter,
                        userModel: availableControlCenter.info && availableControlCenter.info.userId,
                        info: availableControlCenter.info || {
                            activeOrders: 0,
                            couriersOnShift: 0,
                            startTime: '',
                            modelType: MODEL_TYPE.controlCenterInfo,
                        },
                    })),
                    'controlCenterId',
                    'controlCenterId'
                );

                let userModel = yield select(getUserModel)

                const usersIds = users.flatMap(user => typeof user.userId === 'string' ? user.userId : [])
                let newCenters = []

                if(!usersIds.every(userId => userModel.some(user => user.userId === userId))) {
                    yield getUsersByIdsSaga({ data: { ids: usersIds } })

                    userModel = yield select(getUserModel)
                }

                newCenters = centers.map(center => ({
                    ...center,
                    user: userModel?.find(user => user?.userId === center?.userModel) || ''
                }))

                yield put(actionUpdateControlCenters(users.filter(f => f.userId)));
                yield put(actionUpdateRestaurantControlCenters(newCenters));

                yield put(actionSetSectionItemsLoading(false));
            }
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* controlCentersStateUpdateSaga(action) {
    yield* createSaga(function*() {
        const data = action.data || [];
        const restaurant = yield select(getRestaurant);

        if (restaurant.controlCenters && restaurant.controlCenters.length > 0) {
            const users = restaurant.controlCenters.reduce((acc, center) => {
                const newControlCenter = data.find(f => f.controlCenterId === center.controlCenterId) || {};
                const userId = (newControlCenter.info && newControlCenter.info.userId) || center.userModel;

                if (userId) {
                    acc.push({ userId, controlCenterId: newControlCenter.controlCenterId || center.controlCenterId });
                }

                return acc;
            }, []);

            const centers = restaurant.controlCenters.map(center => {
                const newControlCenter = data.find(f => f.controlCenterId === center.controlCenterId);

                return newControlCenter
                    ? {
                          ...center,
                          userModel: newControlCenter.info && newControlCenter.info.userId,
                          info: newControlCenter.info && {
                              startTime: '',
                              couriersOnShift: 0,
                              activeOrders: 0,
                              ...newControlCenter.info,
                          },
                      }
                    : { controlCenterId: center.controlCenterId, name: center.name };
            });

            if (!isSameObjects(restaurant.controlCenters, centers)) {
                yield all([put(actionUpdateControlCenters(users)), put(actionUpdateRestaurantControlCenters(centers))]);
                Pusher.instance.resubscribeUpdatedControlCenters();
            }
        }
    }, action.type);
}

export function* fetchDepotAvatarsSaga() {
    const restaurant = yield select(getRestaurant) || {};
    let results = yield all([
        ...(restaurant.depots || []).map(depot => {
            return depot.logoUrl ? call(fetchMarkerAvatarBase64, depot.logoUrl, false, depot.id) : null;
        }),
    ]);

    results = results.filter(f => f);
    const resData = (restaurant.depots || []).map(depot => {
        const resDataItem = results.find(res => res.id === depot.id);
        return {
            id: depot.id,
            avatar: resDataItem && resDataItem.image,
            depot,
        };
    });

    yield all([put(actionSaveDepotsAvatars(resData)), put(actionUpdateDepotsMarkers(resData))]);
}

function* setModeSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(setMode, action.data.automode);
        if (requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(actionRestaurantStateUpdate({ ...restaurant, state: action.data.automode }));
        }
    }, action.type);
}

function* depotOperationModeGetSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(getDepotOperationMode, parentId || id);

        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            const depot = restaurant.depots.find(f => f.id === (parentId || id));
            const newDepot = { ...depot, operationConfiguration: objectKeysToUpperLowerCase(requestResult.data) };

            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error save');
        }
    }, action.type);
}

function* depotInitDataSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(getDepotOperationMode, parentId || id);
        yield put(setParentBusinessMenu(null));

        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            const depot = restaurant.depots.find(f => f.id === (parentId || id));
            const newDepot = { ...depot, operationConfiguration: objectKeysToUpperLowerCase(requestResult.data) };

            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error save');
        }
    }, action.type);
}

function* depotOperationModeAddSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(f => f.id === (parentId || id));
        const mewOperation = getInitModel(MODEL_TYPE.operationTiming, action.data);
        const body = {...depot.operationConfiguration, profiles: depot.operationConfiguration.profiles.concat(mewOperation) };
        const requestResult = yield call(updateDepotOperationMode, parentId || id, objectKeysToUpperLowerCase(body, true));
        if (requestResult && requestResult.status === 200) {
            const newDepot = { ...depot, operationConfiguration: body };
            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error save');
        }
    }, action.type);
}

function* depotOperationModeEditSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(f => f.id === (parentId || id));
        const newOperation = getInitModel(MODEL_TYPE.operationTiming, action.data);
        const operations = depot.operationConfiguration.profiles.map(el => el.name === action.data.id ? newOperation : el );
        const body = { ...depot.operationConfiguration, profiles: operations };
        const requestResult = yield call(updateDepotOperationMode, parentId || id, objectKeysToUpperLowerCase(body, true));

        if (requestResult && requestResult.status === 200) {
            const newDepot = { ...depot, operationConfiguration: {...depot.operationConfiguration, profiles: operations } };
            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error edit');
        }
    }, action.type);
}

function* depotOperationModeDeleteSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(f => f.id === (parentId || id));
        const operations = depot.operationConfiguration.profiles.filter(el => el.name !== action.data.id);
        const body = { ...depot.operationConfiguration, profiles: operations };
        const requestResult = yield call(updateDepotOperationMode, parentId || id, objectKeysToUpperLowerCase(body, true));

        if (requestResult && requestResult.status === 200) {
            const newDepot = {
                ...depot, operationConfiguration: {
                    ...depot.operationConfiguration,
                    profiles: operations
                }
            };
            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error edit');
        }
    }, action.type);
}

function* changeDepotOperationModeActiveSaga(action){
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(f => f.id === (parentId || id));
        const newConfigOperation = { ...depot.operationConfiguration, activeProfile: action.data.activeProfile };
        const requestResult = yield call(updateDepotOperationMode, parentId || id, objectKeysToUpperLowerCase(newConfigOperation, true));

        if (requestResult && requestResult.status === 200) {
            const newDepot = { ...depot, operationConfiguration: newConfigOperation };
            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error save');
        }
    }, action.type);
}

function* changeDepotOperationModeActiveByDefaultSaga(action){
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(f => f.id === (parentId || id));
        const newConfigOperation = { ...depot.operationConfiguration, activeProfile: "" };
        const requestResult = yield call(updateDepotOperationMode, parentId || id, objectKeysToUpperLowerCase(newConfigOperation, true));

        if (requestResult && requestResult.status === 200) {
            const newDepot = { ...depot, operationConfiguration: newConfigOperation };
            yield put(actionDepotEdit(newDepot));
        } else {
            return new ErrorData('Error save');
        }
    }, action.type);
}

function* depotEditSaga(action) {
    yield* createSaga(function*() {
        const newDepot = action.data;
        const requestResult = yield call(editDepot, getInitModel(MODEL_TYPE.depotServerModel, newDepot));

        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            const depot = restaurant.depots.find(f => f.id === newDepot.id);

            yield put(actionDepotEdit(newDepot));
            if (depot.logoUrl !== newDepot.logoUrl && isBase64Image(newDepot.logoUrl)) {
                yield put(actionSetDepotAvatar({ ...newDepot, avatar: newDepot.logoUrl }));
            }
        } else {
            return new ErrorData('Error save');
        }
    }, action.type);
}

function* depotAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const depots = restaurant.depots;
        let newDepot = action.data;

        if (!newDepot.name) {
            return new ErrorData(i18n.t('settings.map.DEPOT_NAME_REQUIRED_ERROR_MSG'));
        } else if (depots.some(innerDepot => innerDepot.id !== newDepot.id && innerDepot.name === newDepot.name)) {
            return new ErrorData(i18n.t('settings.map.DEPOT_NAME_UNIQUE_ERROR_MSG'));
        } else if (!newDepot.phone) {
            return new ErrorData(i18n.t('settings.map.DEPOT_PHONE_REQUIRED_ERROR_MSG'));
        } else if (locationIsEmpty(newDepot.address.location)) {
            return new ErrorData(i18n.t('settings.map.DEPOT_LOCATION_REQUIRED_ERROR_MSG'));
        }

        const requestResult = yield call(addDepot, getInitModel(MODEL_TYPE.depotServerModel, newDepot));

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            newDepot.id = requestResult.data;
            newDepot.depotId = requestResult.data;

            yield put(actionDepotNew({ ...newDepot, avatar: null }));
            yield put(actionUpdateDepotMarker(newDepot));

            if (isBase64Image(newDepot.logoUrl)) {
                yield put(actionSetDepotAvatar({ ...newDepot, avatar: newDepot.logoUrl }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotDeleteSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(removeDepot, id);

        if (requestResult && requestResult.status === 200) {
            yield all([put(actionDepotDelete({ id })), put(actionRemoveMarker([id]))]);
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* setDepotAvatarSaga(action) {
    yield* createSaga(function*() {
        let { id, avatar, depotId } = action.data;
        const requestResult = yield call(setDepotAvatarBase64, id || depotId, dataURItoBlob(avatar));
        if (requestResult && requestResult.status === 200) {
            yield all([put(actionSaveDepotAvatar({ avatar, depotId })), put(actionUpdateDepotMarker(action.data))]);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotUserEditSaga(action) {
    yield* createSaga(function*() {
        const { depotId, userId } = action.data;
        const data = getInitModel(MODEL_TYPE.userServerModel, action.data);
        const requestResult = yield call(editDepotUser, depotId || userId, userId, data);

        if(requestResultIsOk(requestResult)) {
            yield put(actionUpdateUser(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getDepotUsersSaga(action) {
    yield* createSaga(function*() {
        yield put(actionSetSectionItemsLoading(true));

        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id.replace('?id=', '');
        const requestResult = yield call(getDepotUsers, { depotId: id, ...action.data });

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            const data = objectKeysToUpperLowerCase(requestResult.data)

            const users = data?.page?.map(item => {
                const roles = getRolesFromUser(item);
                return {
                    ...getInitModel(MODEL_TYPE.depotUserModel, item),
                    id: item.userId,
                    depotId: id,
                    authorized: { courier: roles.courier || item.hasDevices, deliveryManager: item.hasPassowrd },
                    roles: roles,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    phoneNumber: item.phoneNumber,
                    userName: item.userName,
                    hasPassword: item.hasPassword,
                };
            });
            const depots = yield select(getRestaurantDepots);
            const depotUsers = depots.find(depot => depot.id === id)?.depotUsers || []

            if(depotUsers?.length && action?.data?.num !== 0) {
                yield put(actionAddDepotUser({ depotId: id, data: users, }));
                return {
                    users,
                    totalCount: data?.totalCount || 0
                }
            } else {
                yield put(actionSaveDepotUsers({ id: id, data: users, }));
                return {
                    users,
                    totalCount: data?.totalCount || 0
                }
            }

        } else {
            yield put(
                actionSaveDepotUsers({
                    id: id,
                    data: [],
                })
            );
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* checkDepotMenuSaga(action) {
    yield* createSaga(function*() {
        const { businessId } = action.data;
        const requestResult = yield call(getBusinessMenu, businessId);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            if (requestResult.data.template_business_id) {
                Store.history.push(ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu + '?id=' + businessId);
            } else {
                Store.history.push(ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit + '?id=' + businessId);
            }

            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getDepotMenuSaga(action) {
    yield* createSaga(function*() {
        const { businessId } = action.data;
        const requestResult = yield call(getBusinessMenu, businessId);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotMenuCopySaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessId = action.data.business_id;
        const templateId = action.data.template_id || action.data.global_template_id;
        const toDepotId = action.data.id;
        const fromDepotId = (
            (restaurant && restaurant.depots && restaurant.depots.find(depot => depot.businessId === businessId)) ||
            {}
        ).id;

        let requestResult = null;

        if (action.data.type === NEW_BUSINESS_MENU_TYPE.business&&fromDepotId) {
                requestResult = yield depotMenuCopy(fromDepotId, toDepotId);
            }


        if (action.data.type === NEW_BUSINESS_MENU_TYPE.empty) {
            requestResult = yield call(checkDepotMenu, toDepotId);
        }

        if (action.data.type === NEW_BUSINESS_MENU_TYPE.template) {
            requestResult = yield call(checkDepotMenu, toDepotId, { TemplateBusinessId: templateId });
        }
        if (action.data.type === NEW_BUSINESS_MENU_TYPE.fromtemplate) {
            requestResult = yield call(depotCopyTemplateMenu, toDepotId, { TemplateBusinessId: templateId });
        }

        if (requestResultIsOk(requestResult, true)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            yield put(actionDepotEdit(data));
            Store.history.push(ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit + '?id=' + data.businessId);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getDepotPriceSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id.replace('?id=', '');
        const requestResult = yield call(getDepotDeliveryPrice, id);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            let restaurant = yield select(getRestaurant);
            let newDepot = restaurant.depots.find(f => f.id === id);

            let price = getInitModel(MODEL_TYPE.depotDeliveryPriceModel);
            price.deliveryPrice = data.deliveryPrice;
            price.basisPrice = data.basisPrice;
            price.basisDistanceInMeters = data.basisDistanceInMeters;
            price.pricePerKm = data.pricePerKm;
            price.calculationMode = data.calculationMode;

            if (data.regionConfigurations) {
                data.regionConfigurations.forEach(region => {
                    price.regionConfigurations.push({
                        ...getInitModel(MODEL_TYPE.regionDeliveryPriceModel, region),
                        depotId: id,
                        deliveryPrice: region.deliveryPrice,
                    });
                });
            }

            yield put(actionDepotEdit({ ...newDepot, price }));

            restaurant = yield select(getRestaurant);
            return restaurant.depots;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* saveDepotUserPasswordSaga(action) {
    yield* createSaga(function*() {
        const item = action.data;

        if (!item.userName) {
            return new ErrorData(i18n.t("settings.map.DEPOT_NAME_REQUIRED_ERROR_MSG"));
        } else if (
            !isValidStringNoSpaces(item.newPassword) ||
            !isValidStringNoSpaces(item.retypePassword) ||
            !isValidPasswordData(item)
        ) {
            return new ErrorData(i18n.t("settings.map.PASSWORD_MISMATCH_MSG"));
        }

        const data = getInitModel(MODEL_TYPE.credentialsServerModel, {
            userName: item.userName,
            newPassword: item.newPassword
        });

        if(item.depotId && item.userId) {
            const requestResult = yield call(changeDepotPassword, item.depotId, item.userId, data);
            if (requestResultIsOk(requestResult)) {
                yield put(actionUpdateUserById(item.userId));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* editDepotPriceSaga(action) {
    yield* createSaga(function*() {
        const price = action.data;

        let configuration = {
            DeliveryPrice: price.deliveryPrice,
            BasisPrice: price.basisPrice,
            CalculationMode: price.calculationMode,
            BasisDistanceInMeters: price.basisDistanceInMeters,
            PricePerKm: price.pricePerKm,
            RegionConfigurations: price.regionConfigurations.map(val => ({
                RegionId: val.id || val.regionId,
                DeliveryPrice: val.deliveryPrice || 0,
            })),
        };
        const requestResult = yield call(setDepotDeliveryPrice, price.id, configuration);

        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            const newDepot = restaurant.depots.find(f => f.id === price.id);
            yield put(actionDepotEdit({ ...newDepot, price }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editDepotMenuSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(updateDepotMenu, id);

        if (requestResult && requestResult.status === 200) {
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotUserAddSaga(action) {
    yield* createSaga(function*() {
        const { depotId } = action.data;
        const data = getInitModel(MODEL_TYPE.userServerModel, action.data);
        const requestResult = yield call(addDepotUser, depotId, data);

        if(requestResultIsOk(requestResult)) {
            yield put(actionNewUser(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotUserRemoveSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const depotId = sectionModels[currentModelType].id;
        const userId = action.data.id;

        if (depotId && userId) {
            const requestResult = yield call(removeDepotUser, depotId, userId);
            if (requestResultIsOk(requestResult)) {
                yield put(actionUpdateUser(objectKeysToUpperLowerCase(requestResult.data)));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData(`Delete depot(${depotId}) user(${userId}) failed.`);
        }
    }, action.type);
}


function* manuTemplateBusinessCategoryEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessCategories = restaurant.businessMenu?.business_categories || []
        const businessCategoryId = action.data.id

        let categories;
        if(businessCategories.includes(businessCategoryId)){
            categories = businessCategories.filter(business => business !== businessCategoryId)
        } else {
            businessCategories.push(businessCategoryId)
            categories = businessCategories
        }

        const requestResult = yield call(updateDepotCategoryStates, restaurant.businessMenu.id, categories);

        if (requestResult && requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotPaymeWalletSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const data = objectKeysToUpperLowerCase(
            {
                ...action.data,
                sellerGender: action.data.sellerGender ? 1 : 0,
            },
            true
        );
        const depotId = sectionModels[currentModelType].id;
        const requestResult = yield call(depotWallet, depotId, data);

        if (requestResult && requestResult.status === 200) {
            const requestResultRestaurant = yield call(fetchRestaurant);

            if (requestResultRestaurant && requestResultRestaurant.status === 200) {
                const depot = objectKeysToUpperLowerCase(
                    requestResultRestaurant.data.Depots.find(f => f.Id === depotId)
                );

                yield put(actionDepotEdit(depot));
                Store.history.push(TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit + '?id=' + depotId);
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotPaymeCreditSaga(action) {
    yield* createSaga(function*() {
        // const sectionModel = yield select(getSectionModel);
        // const { paymentsConfiguration } = yield select(getRestaurant);
        // const { currentModelType, sectionModels } = sectionModel;
        // const depotId = sectionModels[currentModelType].id;
        const { creditAcceptType, /* paymeWalletExist, paymeWalletSignupUrl, */ acceptCash, acceptCreditCard, acceptCashOnTakeAway } = action.data;

        const data = {
            OnlineState: creditAcceptType.value,
            AcceptCash: creditAcceptType.value === CREDIT_ACCEPT.selling
                ? acceptCash
                : creditAcceptType.selected.indexOf('acceptCash') > -1,
            AcceptCashOnTakeAway: creditAcceptType.value === CREDIT_ACCEPT.selling
                ? acceptCashOnTakeAway
                : creditAcceptType.selected.indexOf('acceptCashOnTakeAway') > -1,
            AcceptCreditCard: creditAcceptType.value === CREDIT_ACCEPT.selling
                ? acceptCreditCard
                : creditAcceptType.selected.indexOf('acceptCreditCard') > -1
        };

        yield put(actionSetDepotPaymentState({ depot: action.data, data }));
        Store.history.push(TYPE_SECTION_MODEL.depots);
        // if (data.OnlineState === CREDIT_ACCEPT.selling) {
        //     if (paymentsConfiguration && paymentsConfiguration.overrideDepotSettings) {
        //         yield put(actionSetDepotPaymentState({ depot: action.data, data }));
        //         Store.history.push(TYPE_SECTION_MODEL.depots);
        //     } else {
        //         if (!data.AcceptCash && !data.AcceptCreditCard) {
        //             return new ErrorData(i18n.t('settings.paymeWallet.CREDIT_ACCEPT_ORDERS_ERROR_SELECT'));
        //         } else {
        //             if (data.AcceptCreditCard) {
        //                 if (paymeWalletExist) {
        //                     yield put(actionSetDepotPaymentState({ depot: action.data, data }));
        //                     Store.history.push(TYPE_SECTION_MODEL.depots);
        //                 } else {
        //                     yield put(
        //                         actionDepotEdit({
        //                             ...action.data,
        //                             ...objectKeysToUpperLowerCase(data),
        //                         })
        //                     );
        //
        //                     yield put(
        //                         actionGlobalPopupMessage({
        //                             isShow: true,
        //                             isLink: !!paymeWalletSignupUrl,
        //                             message: i18n.t('settings.paymeWallet.CREDIT_ACCEPT_NOT_READY'),
        //                             onClose: () => {
        //                                 Store.store.dispatch(actionGlobalPopupMessageInit());
        //                                 Store.history.push(TYPE_SECTION_MODEL.depotpaymewalletNew + '?id=' + depotId);
        //                             },
        //                         })
        //                     );
        //                 }
        //             } else {
        //                 yield put(actionSetDepotPaymentState({ depot: action.data, data }));
        //                 Store.history.push(TYPE_SECTION_MODEL.depots);
        //             }
        //         }
        //     }
        // } else {
        //     yield put(actionSetDepotPaymentState({ depot: action.data, data }));
        //     Store.history.push(TYPE_SECTION_MODEL.depots);
        // }
    }, action.type);
}

function* depotPaymentStateSaga(action) {
    yield* createSaga(function*() {
        const { depot, data } = action.data;
        const requestResult = yield call(depotPaymentState, depot.id, data);
        if (requestResult && requestResult.status === 200) {
            yield put(
                actionDepotEdit({
                    ...depot,
                    ...objectKeysToUpperLowerCase(data),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getDepotUserById(action) {
    yield* createSaga(function*() {
        const { id, parentId } = getHistoryLocationParams(window.location.search);

        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(depot => depot.id === parentId);
        const isUserExist = depot?.depotUsers?.some(user => user?.userId === id)

        if(isUserExist) return

        yield getUserByIdSaga({ data: { id }})
    }, action.type)
}

function* restaurantPaymentStateSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(restaurantPaymentState, action.data);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    paymentsConfiguration: {
                        ...restaurant.paymentsConfiguration,
                        ...objectKeysToUpperLowerCase(action.data),
                    },
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* restaurantPaymentsConfigurationEditSaga(action) {
    yield* createSaga(function*() {
        const { acceptCash, acceptCreditCard, acceptCashOnTakeAway, overrideDepotSettings, requireVerifiedPhoneOnCash, enabled } = action.data;
        const walletData = {
            enabled: enabled,
            RequireVerifiedPhoneOnCash: requireVerifiedPhoneOnCash,
            OverrideDepotSettings: overrideDepotSettings,
            AcceptCash: acceptCash,
            AcceptCreditCard: acceptCreditCard,
            AcceptCashOnTakeAway: acceptCashOnTakeAway,
        };

        yield put(actionSetRestaurantPaymentState(walletData));
    }, action.type);
}

function* restaurantPaymeCreditSaga(action) {
    yield* createSaga(function*() {
        const data = objectKeysToUpperLowerCase(
            {
                ...action.data,
                sellerGender: action.data.sellerGender ? 1 : 0,
            },
            true
        );
        const requestResult = yield call(restaurantWallet, data);

        if (requestResult && requestResult.status === 200) {
            Store.history.push(MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* generalSettingEditSaga(action) {
    yield* createSaga(function*() {
        const {
            lostAlertTime,
            orderIsLateAlertTime,
            orderIsLateWarningTime,
            stopAlertTime,
            courierReadyTimeDelay,
            orderIsUnassignedTime,
            pickupLoadTime,
            maxOrdersPerCourier,
            orderUnloadTime,
            readinessHeadsUpTime,
            readinessHeadsUpTimeForScheduledOrders,maxTakeAwayRedinessTime,
            maxPickupsCollectionTime,
            maxPickupDistance,
            neighborhoodRadiusTime,
            takeAwaySupported,
            allowCityOnlyAddress,
            assistOutOfRegion,
            groupingStrategy,
            optimizationStrategy,
            courierAssociationMode,
            postRejectOrderCoolDownPeriod,
            newOrderNotificationPriorityPeriod,
            newOrderNotificationPriorityRadiusInMeters,
            hideCouriers,
            newOrderNotificationMode,
            maxBicycleServiceDistance,
            hideReadinessTimeOnOrderCard,
            showOrderPaymentStatus,
            cardPaymentDefaultIsNotPaid,
            isCanEditBusinessInfo,
            useCarVehicleForExtraSizeOrders,
            automaticExtendCycle,
            automaticApproveCycle,
        } = action.data;

        const apiCalls = [
            call(
                setNotificationSettings,
                lostAlertTime,
                orderIsLateAlertTime,
                orderIsLateWarningTime,
                stopAlertTime,
                courierReadyTimeDelay,
                orderIsUnassignedTime
            ),
            call(
                setNumericalSettings,
                {
                pickupLoadTime,
                maxOrdersPerCourier,
                orderUnloadTime,
                readinessHeadsUpTime,readinessHeadsUpTimeForScheduledOrders,
                maxTakeAwayRedinessTime,
                maxPickupsCollectionTime,
                maxPickupDistance,
                neighborhoodRadiusTime,
                takeAwaySupported,
                allowCityOnlyAddress,
                assistOutOfRegion,
                groupingStrategy,
                optimizationStrategy,
                courierAssociationMode,
                postRejectOrderCoolDownPeriod,
                newOrderNotificationPriorityPeriod,
                newOrderNotificationPriorityRadiusInMeters,
                hideCouriers,
                newOrderNotificationMode,
                maxBicycleServiceDistance,
                hideReadinessTimeOnOrderCard,
                showOrderPaymentStatus,
                cardPaymentDefaultIsNotPaid,
                isCanEditBusinessInfo,
                useCarVehicleForExtraSizeOrders,
                    automaticExtendCycle,
                    automaticApproveCycle,
                }
            ),
        ];


        const apiCallResults = yield all(apiCalls);

        if (someObject(apiCallResults, result => result.status && result.status !== 200)) {
            return new ErrorData('Error save settings');
        }

        const restaurant = yield select(getRestaurant);

        yield put(
            actionRestaurantUpdate({
                configuration: {
                    ...restaurant.configuration,
                    pickupLoadTime,
                    maxOrdersPerCourier,
                    orderUnloadTime,
                    readinessHeadsUpTime,
                    readinessHeadsUpTimeForScheduledOrders,maxTakeAwayRedinessTime,
                    maxPickupsCollectionTime,
                    maxPickupDistance,
                    neighborhoodRadiusTime,
                    takeAwaySupported,
                    allowCityOnlyAddress,
                    assistOutOfRegion,
                    groupingStrategy,
                    optimizationStrategy,
                    courierAssociationMode,
                    postRejectOrderCoolDownPeriod,
                    newOrderNotificationPriorityPeriod,
                    newOrderNotificationPriorityRadiusInMeters,
                    hideCouriers,
                    newOrderNotificationMode,
                    maxBicycleServiceDistance,
                    hideReadinessTimeOnOrderCard,
                    showOrderPaymentStatus,
                    cardPaymentDefaultIsNotPaid,
                    isCanEditBusinessInfo,
                    useCarVehicleForExtraSizeOrders,
                    automaticExtendCycle,
                    automaticApproveCycle,
                },
                notificationConfiguration: {
                    ...restaurant.notificationConfiguration,
                    lostAlertTime,
                    orderIsLateAlertTime,
                    orderIsLateWarningTime,
                    stopAlertTime,
                    courierReadyTimeDelay,
                    orderIsUnassignedTime
                }
            })
        );
    }, action.type);
}

function* shipmentPriceConfigurationEditSaga(action) {
    yield* createSaga(function*() {
        const {
            appbasisPrice,
            appbasisDistanceInMeters,
            apppricePerKm,
            appmaxDeliveryPrice,
            appmode,
        } = action.data;


        const requestResult = yield call(
          setAppShipmentPriceSettings,
          appbasisPrice,
          appbasisDistanceInMeters,
          apppricePerKm,
          appmaxDeliveryPrice,
          appmode
        );

        if(requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(
              actionRestaurantUpdate({
                  appShipmentPriceConfiguration:
                    {
                        ...restaurant.appShipmentPriceConfiguration,
                        appbasisPrice,
                        appbasisDistanceInMeters,
                        apppricePerKm,
                        appmaxDeliveryPrice,
                        appmode,
                    }
              })
            );
        } else {
            return new ErrorData('Error save settings');
        }

    }, action.type);
}

function* shipmentPriceConfigurationGetSaga(action) {
    yield* createSaga(function*() {
        const appShipmentPriceConfiguration = yield call(getAppShipmentPriceSettings);

        let appShipmentPriceSettings = {};
        if (requestResultIsOk(appShipmentPriceConfiguration, true)) {
            const data = objectKeysToUpperLowerCase(appShipmentPriceConfiguration.data);
            Object.keys(data).forEach(key => {
                appShipmentPriceSettings[`app${key}`] = data[key];
            });
        }

        const restaurant = yield select(getRestaurant);
        yield put(
          actionRestaurantUpdate({
              ...restaurant,
              appShipmentPriceConfiguration: appShipmentPriceSettings,
          })
        );
    }, action.type);
}

function* generalSettingGetSaga(action) {
    yield* createSaga(function*() {
        const restaurantShipmentPriceConfiguration = yield call(getShipmentPriceSettings)

        const restaurant = yield select(getRestaurant);
        yield put(
            actionRestaurantUpdate({
                ...restaurant,
                restaurantShipmentPriceConfiguration:
                    requestResultIsOk(restaurantShipmentPriceConfiguration, true) &&
                    objectKeysToUpperLowerCase(restaurantShipmentPriceConfiguration.data),
            })
        );
    }, action.type);
}

function* getBusinessAppSettingsSaga(action) {
    yield* createSaga(function*() {
        const request = yield call(getBusinessAppSettings);

        const restaurant = yield select(getRestaurant);
        if(requestResultIsOk(request)) {
            yield put(
                actionRestaurantUpdate({
                    ...restaurant, businessAppSettings: objectKeysToUpperLowerCase(request.data),
                })
            );
        }
    }, action.type);
}

function* updateBusinessAppSettingsSaga(action) {
    yield* createSaga(function*() {
        const request = yield call(updateBusinessAppSettings, action.data);

        if(!requestResultIsOk(request)) {
            return new ErrorData(getErrorMessage(request) || 'Error update BusinessAppSettings');
        }
    }, action.type);
}

function* getCourierSettingsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield all({
            courierControlSettings: call(getCourierControlSettings),
            courierDisplaySettings: call(getCourierDisplaySettings),
            courierInterfaceSettings: call(getCourierInterfaceSettings),
        });

        const { courierControlSettings, courierDisplaySettings, courierInterfaceSettings } = requestResult;

        if(!requestResultIsOk(courierControlSettings) || !requestResultIsOk(courierDisplaySettings)) {
            return new ErrorData('Error get settings');
        }

        const restaurant = yield select(getRestaurant);
        yield put(
            actionRestaurantUpdate({
                ...restaurant,
                courierControlSettings: objectKeysToUpperLowerCase(courierControlSettings.data),
                courierDisplaySettings: objectKeysToUpperLowerCase(courierDisplaySettings.data),
                courierInterfaceSettings: objectKeysToUpperLowerCase(courierInterfaceSettings.data),
            })
        );
    }, action.type);
}

function *updateCourierSettingsSaga(action) {
    yield* createSaga(function*() {
        yield all({
            courierControlSettings: call(updateCourierControlSettings, getInitModel(MODEL_TYPE.courierAppSettings, action.data)),
            courierDisplaySettings: call(updateCourierDisplaySettings, getInitModel(MODEL_TYPE.courierDisplaySettings, action.data)),
            courierInterfaceSettings: call(updateCourierInterfaceSettings, getInitModel(MODEL_TYPE.courierInterfaceSettings, action.data)),
        });
    }, action.type);
}

function* telegramSettingEditSaga(action) {
    yield* createSaga(function*() {
        const {
            requireWaitingTime,
            showMap,
            notifyDeliveredOrder,
            notifyCourierDispatched,
            waitingTimes,
            allowOrderDeletion,
            enablePhoneQuery,
            allowOrderSetVIP,
            allowOrderDelay,
            requirePhoneNumberOnOrderCreation,
            hideReadyButton
        } = action.data;
        const requestResult = yield call(telegramSettings, {
            requireWaitingTime,
            showMap,
            notifyDeliveredOrder,
            notifyCourierDispatched,
            waitingTimes,
            allowOrderDeletion,
            enablePhoneQuery,
            allowOrderSetVIP,
            allowOrderDelay,
            requirePhoneNumberOnOrderCreation,
            hideReadyButton
        });

        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    telegramConfiguration: {
                        requireWaitingTime,
                        showMap,
                        notifyDeliveredOrder,
                        notifyCourierDispatched,
                        waitingTimes,
                        allowOrderDeletion,
                        enablePhoneQuery,
                        allowOrderSetVIP,
                        allowOrderDelay,
                        requirePhoneNumberOnOrderCreation,
                        hideReadyButton
                    },
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceMassageAddSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(createMarketplaceMessage, action.data);
        if (requestResult && requestResult.status === 200) {
            yield put(actionAddNewMarketplaceMessage(requestResult.data));
            if (action.data.banner && (isBase64Image(action.data.banner) || isBase64Video(action.data.banner))) {
                const requestResultWithBanner = yield call(
                    setMarketplaceBannerBase64,
                    requestResult.data.id,
                    dataURItoBlob(action.data.banner),
                    isBase64Video(action.data.banner)
                );
                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(actionUpdateMarketplaceMessage(requestResultWithBanner.data));
                }
            }
            return requestResult.data;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplacePagesAddSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(createMarketplacePage, action.data);
        if (requestResult && requestResult.status === 200) {
            yield put(actionAddNewMarketplacePage(requestResult.data));
            if (action.data.banner && (isBase64Image(action.data.banner) || isBase64Video(action.data.banner))) {
                const requestResultWithBanner = yield call(
                    setMarketplacePageBannerBase64,
                    requestResult.data.id,
                    dataURItoBlob(action.data.banner),
                    isBase64Video(action.data.banner),
                );
                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(actionUpdateMarketplacePage(requestResultWithBanner.data));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceMassageEditSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateMarketplaceMessage, action.data);
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateMarketplaceMessage(objectKeysToUpperLowerCase(requestResult.data)));
            if (action.data.banner && (isBase64Image(action.data.banner) || isBase64Video(action.data.banner))) {
                const requestResultWithBanner = yield call(
                    setMarketplaceBannerBase64,
                    requestResult.data.id,
                    dataURItoBlob(action.data.banner),
                    isBase64Video(action.data.banner)
                );
                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(actionUpdateMarketplaceMessage(objectKeysToUpperLowerCase(requestResultWithBanner.data)));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplacePageEditSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateMarketplacePage, action.data);
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateMarketplacePage(objectKeysToUpperLowerCase(requestResult.data)));
            if (action.data.banner && (isBase64Image(action.data.banner) || isBase64Video(action.data.banner))) {
                const requestResultWithBanner = yield call(
                    setMarketplacePageBannerBase64,
                    requestResult.data.id,
                    dataURItoBlob(action.data.banner),
                    isBase64Video(action.data.banner),
                );
                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(actionUpdateMarketplacePage(objectKeysToUpperLowerCase(requestResultWithBanner.data)));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceToggleEnabledSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateMarketplaceMessage, { ...action.data, enabled: action.data.enabled });
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateMarketplaceMessage(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplacePagesToggleEnabledSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateMarketplacePage, { ...action.data, enabled: action.data.enabled });
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateMarketplacePage(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceMessageDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteMarketplaceMessage, action.data.id);
        if (requestResult && requestResult.status === 200) {
            yield put(actionDeleteMarketplaceMessage(objectKeysToUpperLowerCase(action.data.id)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplacePageDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteMarketplacePage, action.data.id);
        if (requestResult && requestResult.status === 200) {
            yield put(actionDeleteMarketplacePage(objectKeysToUpperLowerCase(action.data.id)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceMessageUpdateSaga(action) {
    yield* createSaga(function*() {
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(marketplaceMessageReorder, data);

        if (requestResult && requestResult.status === 200) {
            yield put(setMarketplace(action.data.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplacePageUpdateSaga(action) {
    yield* createSaga(function*() {
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(marketplacePagesReorder, data);

        if (requestResult && requestResult.status === 200) {
            yield put(setMarketplacePages(action.data.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceSettignsUpdateSaga(action) {
    yield* createSaga(function*() {
        const serverModel = getInitModel(MODEL_TYPE.marketplaceSettings, action.data);

        const resultRequest = yield call(updateMarketplaceSettings, serverModel);
        if (resultRequest.status === 200 && resultRequest.data) {
            yield put(
                actionRestaurantUpdate({ paymentsConfiguration: objectKeysToUpperLowerCase(resultRequest.data) })
            );

            if (action.data.headerUrl && isBase64Image(action.data.headerUrl)) {
                const requestResultWithBanner = yield call(
                    setMarketplaceSettingsHeader,
                    dataURItoBlob(action.data.headerUrl)
                );
                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    const resultRequest = yield call(updateMarketplaceSettings, serverModel);
                    yield put(
                        actionRestaurantUpdate({
                            paymentsConfiguration: objectKeysToUpperLowerCase(resultRequest.data),
                        })
                    );
                }
            }
        }
    }, action.type);
}

function* regionSettingAddSaga(action) {
    yield* createSaga(function*() {
        const region = {
            ...action.data,
            maxDeliveryTime: minutesToTime(action.data.maxDeliveryTime),
            pendingOrdersGroupingTimeDelta: minutesToTime(action.data.pendingOrdersGroupingTimeDelta),
        };
        const restaurant = yield select(getRestaurant);
        const regions = mergeArrays(restaurant.configuration.regions, [region]);

        if (!isUniqueRegionNames(regions)) {
            return new ErrorData(i18n.t('settings.UNIQUE_REGION_NAME_ERROR_MSG'));
        }

        const requestResult = yield call(
            setNewRegionInfo,
            getInitModel(MODEL_TYPE.restaurantRegionServerModel, region)
        );

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data);
                yield put(
                    actionRestaurantUpdate({
                        configuration: {
                            ...restaurant.configuration,
                            regions: mergeArrays(restaurant.configuration.regions, [data]),
                        },
                    })
                );
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* regionSettingEditSaga(action) {
    yield* createSaga(function*() {
        const region = {
            ...action.data,
            maxDeliveryTime: minutesToTime(action.data.maxDeliveryTime),
            pendingOrdersGroupingTimeDelta: minutesToTime(action.data.pendingOrdersGroupingTimeDelta),
        };
        const restaurant = yield select(getRestaurant);
        const regions = mergeArrays(restaurant.configuration.regions, [region]);

        if (!isUniqueRegionNames(regions)) {
            return new ErrorData(i18n.t('settings.UNIQUE_REGION_NAME_ERROR_MSG'));
        }

        const requestResult = yield call(
            updateRegionInfo,
            getInitModel(MODEL_TYPE.restaurantRegionServerModel, region)
        );

        if (requestResult && requestResult.status === 200) {
            yield put(
                actionRestaurantUpdate({
                    configuration: {
                        ...restaurant.configuration,
                        regions: regions,
                    },
                })
            );
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* regionSettingRemoveSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(removeRegion, id);

        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    configuration: {
                        ...restaurant.configuration,
                        regions: restaurant.configuration.regions.filter(f => f.id !== id),
                    },
                })
            );
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* providersSettingGetSaga(action) {
    yield* createSaga(function*() {

        const requestResult = yield call(
            fetchSettingProviders,
            {}
        );

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data);

                yield put(actionLoadProvidersSetting(data));
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* providersSettingDetailsGetSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);

        const providers = yield select(getProvidersAll);
        const currentProvider = providers.find((e) => e.id === id);
        let requestResult = null;

        if(currentProvider && currentProvider.provider === EXTERNAL_COURIER_PROVIDER.delivApp) {
            requestResult = yield call(
                fetchSettingExternalProvidersById,
                currentProvider.id
            );

        } else {
            requestResult = yield call(
                fetchSettingProviderDetails,
                id && typeof id ==="string" ? id.toLowerCase() + "Settings" : ""
            );
        }

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data);

                yield put(actionLoadProvidersSettingDetails(data));
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* providersSettingAddSaga(action) {
    yield* createSaga(function*() {
        const providerForm = {
            ...action.data,
        };

        const providers = yield select(getProvidersAll);
        const currentProvider = providers.find((e) => e.name === providerForm.provider);
        let requestResult = null;

        if(currentProvider && currentProvider.provider === EXTERNAL_COURIER_PROVIDER.delivApp) {
            requestResult = yield call(addSettingExternalProviders, providerForm?.provider, {EntityId: currentProvider.id});

        } else {
            requestResult = yield call(addSettingProviders, providerForm?.provider && typeof providerForm?.provider ==="string" ? providerForm?.provider.toLowerCase() + "Settings" : "", providerForm);
        }

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(PROVIDERS_SETTING_GET);

            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* providersSettingEditSaga(action) {
    yield* createSaga(function*() {
        const providerForm = { ...action.data };

        const requestResult = yield call(addSettingProviders, providerForm?.provider && typeof providerForm?.provider ==="string" ? providerForm?.provider.toLowerCase() + "Settings" : "", providerForm);

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(PROVIDERS_SETTING_GET);
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }

    }, action.type);
}

function* providersSettingRemoveSaga(action) {
    yield* createSaga(function*() {
        const { item } = action.data;

        const providers = yield select(getProvidersAll);
        const currentProvider = providers.find((e) => e.id === item.id);
        let requestResult = null;

        if(currentProvider && currentProvider.provider === EXTERNAL_COURIER_PROVIDER.delivApp) {
            requestResult = yield call(deleteSettingExternalProviders, currentProvider.id);

        } else {
            requestResult = yield call(deleteSettingProviders, item?.id && typeof item?.id ==="string" ? item?.id.toLowerCase() + "Settings" : "");
        }

        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateProvidersSetting({...item, isActive: false}))

        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* cloudPrintersSettingGetSaga(action) {
    yield* createSaga(function*() {

        const requestResult = yield call(
            fetchSettingCloudPrinters,
            {}
        );

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data);

                yield put(actionLoadCloudPrintersSetting(data));
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* cloudPrintersSettingDetailsGetSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);

        const requestResult = yield call(
            fetchSettingCloudPrintersDetails,
            id
        );

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data);

                yield put(actionLoadCloudPrintersSettingDetails(data));
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* cloudPrintersSettingAddSaga(action) {
    yield* createSaga(function*() {
        const providerForm = {
            ...action.data,
        };

        const requestResult = yield call(addSettingCloudPrinters, providerForm);

        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                // something after add
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* cloudPrintersSettingEditSaga(action) {
    yield* createSaga(function* () {
        const {id, ...providerForm} = {
            ...action.data,
        };

        const requestResult = yield call(editSettingCloudPrinters, providerForm, id);


        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionUpdateCloudPrintersSetting({...providerForm, id}))
            }
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }

    }, action.type);
}

function* cloudPrintersSettingRemoveSaga(action) {
    yield* createSaga(function*() {
        const { item } = action.data;
        const requestResult = yield call(deleteSettingCloudPrinters, item?.id);

        if (requestResult && requestResult.status === 200) {

        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* serviceCityAddSaga(action) {
    yield* createSaga(function*() {
        if (isDisableCitySave(action.data.name)) {
            return new ErrorData(i18n.t('settings.map.DUPLICATE_PLACES'));
        }

        const restaurant = yield select(getRestaurant);
        const requestResult = yield call(addServiceCity, getInitModel(MODEL_TYPE.serviceCities, action.data));
        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({
                configuration: {
                    ...restaurant.configuration,
                    serviceCities: restaurant.configuration.serviceCities.concat(action.data.name)
                }
            }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceCityEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const serviceCities = restaurant.serviceCities;
        const city = (serviceCities || []).find(sc => sc.id === action.data.id);
        if (city.name !== action.data.name && isDisableCitySave(action.data.name)) {
            return new ErrorData(i18n.t('settings.map.DUPLICATE_PLACES'));
        }
        const requestResult = yield call(editServiceCity, action.data.id, getInitModel(MODEL_TYPE.serviceCities, action.data));
        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ serviceCities: objectKeysToUpperLowerCase(requestResult.data) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceCityGetSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const requestResult = yield call(getServiceCity);
        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({
                serviceCities: objectKeysToUpperLowerCase(requestResult.data),
                configuration: {
                    ...restaurant.configuration,
                    serviceCities: requestResult.data.map(sc => sc.Name)
                }
            }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* fleetGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getFleet);

        if (requestResult && requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            yield put(actionLoadFleet(data));

        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}
function* fleetGetTelemetryBlinkSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);

        const requestResult = yield call(getFleetTelemetryBlink, id);

        if (requestResult && requestResult.status === 200) {

        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}
function* fleetGetTelemetrySaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);

        const requestResult = yield call(getFleetTelemetry, id);
        const requestVehicleResult = yield call(getFleetById, id);

        if (requestResult && requestResult.status === 200 && requestVehicleResult && requestVehicleResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            const dataVehicle = objectKeysToUpperLowerCase(requestVehicleResult.data);

            yield put(actionLoadFleetUpdate(dataVehicle)); // {latitude:, longitude: }
            yield put(actionLoadFleetTelemetry(data));

        } else {
             return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}
function* resetFleetTelemetrySaga(action) {
    yield* createSaga(function*() {
        yield put(actionLoadFleetUpdate(null));
        yield put(actionLoadFleetTelemetry(null));
    }, action.type);
}
function* fleetAddSaga(action) {
    yield* createSaga(function*() {
        const data = action.data;
        yield put(actionSetSectionItemsLoading(true));

        const requestResult = yield call(addFleet, data);

        if (requestResult && requestResult.status === 200) {
            yield call(fleetGetSaga, {});
        } else {
            yield put(actionSetSectionItemsLoading(false));

            return new ErrorDataTable(getErrorMessage(requestResult), false);
        }
        yield put(actionSetSectionItemsLoading(false));
    }, action.type);
}

function* fleetEditSaga(action) {
    yield* createSaga(function*() {
        const data = action.data;
        const { id } = getHistoryLocationParams(window.location.search);

        yield put(actionSetSectionItemsLoading(true));

        const requestResult = yield call(editFleet, {data, id: id});

        if (requestResult && requestResult.status === 200) {
            yield call(fleetGetSaga, {});
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
        yield put(actionSetSectionItemsLoading(false));
    }, action.type);
}

function* fleetDeleteSaga(action) {
    yield* createSaga(function*() {
        const vehicleData = action.data;
        yield put(actionSetSectionItemsLoading(true));


        const requestResult = yield call(deleteFleet, vehicleData?.item?.vehicleId);

        if (requestResult && requestResult.status === 200) {
            yield call(fleetGetSaga, {});
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
        yield put(actionSetSectionItemsLoading(false));
    }, action.type);
}

function* serviceCityToggleSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const serviceCities = restaurant.configuration.serviceCities;

        // wait backend
        const requestResult = yield call(toggleServiceCity, action.data.name, action.data);

        if (requestResult.data.status === 200) {
            yield put(
                actionRestaurantUpdate({
                    configuration: {
                        ...restaurant.configuration,
                        serviceCities: serviceCities.filter(city => city !== action.data.name),
                    },
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    });
}

function* serviceCityRemoveSaga(action) {
    yield* createSaga(function*() {
        const { id, item: { name } } = action.data;
        const restaurant = yield select(getRestaurant);
        const serviceCities = restaurant.serviceCities || [];

        const requestResult = yield call(deleteServiceCity, id);
        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({
                serviceCities: serviceCities.filter(sc => !(sc.id === id || sc.name === id)),
                configuration: {
                    ...restaurant.configuration,
                    serviceCities: restaurant.configuration.serviceCities.filter(sc => sc !== name)
                }
              }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* namedPlaceAddSaga(action) {
    yield* createSaga(function*() {
        const { name, address, region } = action.data;
        const restaurant = yield select(getRestaurant);

        if (!name) {
            return new ErrorData('Name is empty');
        } else if (
            restaurant.namedPlaces.some(place => {
                return place.name && place.name === name;
            })
        ) {
            return new ErrorData(i18n.t('placeEditor.NOT_UNIQUE_PLACE_NAME_ERROR'));
        }

        const requestResult = yield call(addNamedPlace, getInitModel(MODEL_TYPE.namedPlacesServerModel, action.data));
        if (requestResult && requestResult.status === 200) {
            yield put(
                actionRestaurantUpdate({
                    namedPlaces: restaurant.namedPlaces.concat([{ name, address, region }]),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* namedPlaceEditSaga(action) {
    yield* createSaga(function*() {
        const { id, name } = action.data;
        const restaurant = yield select(getRestaurant);

        if (!name) {
            return new ErrorData('Name is empty');
        } else if (restaurant.namedPlaces.some(place => place.name && place.name === name && place.id !== id)) {
            return new ErrorData(i18n.t('placeEditor.NOT_UNIQUE_PLACE_NAME_ERROR'));
        }

        const requestResult = yield call(
            editNamedPlace,
            getInitModel(MODEL_TYPE.namedPlacesServerModel, action.data),
            id
        );
        if (requestResult && requestResult.status === 200) {
            yield put(
                actionRestaurantUpdate({
                    namedPlaces: restaurant.namedPlaces.map((place) =>
                        place.id === id ? { ...place, ...action.data } : place
                    ),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* namedPlaceRemoveSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const requestResult = yield call(deleteNamedPlace, action.data.id);
        if (requestResult && requestResult.status === 200) {
            yield put(
                actionRestaurantUpdate({
                    namedPlaces: restaurant.namedPlaces.filter(place => place.id !== action.data.id),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* namedPlaceGetSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        if (!restaurant.namedPlaces || !restaurant.namedPlaces.length) {
            const requestResult = yield call(getNamedPlaces);
            if (requestResultIsOk(requestResult, true)) {
                yield put(
                    actionRestaurantUpdate({ namedPlaces: objectKeysToUpperLowerCase(requestResult.data) || [] })
                );
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* shiftsSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { dateStart, endDate, user, controlCenter } =
            sectionModels[currentModelType].model.filterOptions.options || {};
        const startShiftDate = dateStart && dateStart.selected;
        const endShiftDate = endDate && endDate.selected;
        const controlCenterId = controlCenter.selected;
        const userId = user.selected;

        Pusher.instance.setShiftHistoryFilterStateChannel(sectionModels[currentModelType].model.filterOptions.options || {});
        yield put(actionSaveShiftsHistory());
        yield put(createAction(GET_ORDERS_PROVIDER));

        if (isDatesValid(startShiftDate, endShiftDate)) {
            yield put(actionSetSectionItemsLoading(true));
            const requestResult = yield call(
                getShifts,
                startShiftDate,
                endShiftDate,
                controlCenterId,
                userId
            );

            if (requestResult && requestResult.status === 200) {
                const data = objectKeysToUpperLowerCase(requestResult.data || []);
                const couriersIds = data.map(courier => courier.courierName)

                //get users and set usersName to Shifts
                yield getUsersByIdsSaga({ data: { ids: couriersIds }})

                const users = yield select(getUserModel)

                const shiftsWithUserName = data.map(shift => ({
                    ...shift,
                    courierName: getFullName((users.find(user => user.userId === shift.courierName)))
                }))

                yield put(actionSaveShifts(shiftsWithUserName));
                yield put(actionSetSectionItemsLoading(false));
            } else {
                yield put(actionSetSectionItemsLoading(false));
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* currentShiftUsersSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { controlCenterIds } =
        sectionModels[currentModelType].model.filterOptions.options || {};
        const selectedControlCenterIds = controlCenterIds && controlCenterIds.selected;

        yield put(actionSaveShiftsHistory());
        yield put(createAction(CONTROL_CENTER_REFRESH, {
            selectedControlCenterMulti: selectedControlCenterIds,
        }));

        const resultRequest = yield call(fetchExternalProviders);

        if (resultRequest && resultRequest.data) {
            const data = objectKeysToUpperLowerCase(resultRequest.data);
            yield put(setOrdersProvider(data));
        } else {
            return new ErrorData('Error');
        }


    }, action.type);
}

function* shiftProvidersSaga(action) {
    yield* createSaga(function*() {
        yield put(actionSetSectionItemsLoading(true));
        yield put(setShiftProviders([]));
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { controlCenter, dateStart, endDate } =
        sectionModels[currentModelType].model.filterOptions.options || {};
        const selectedControlCenter = controlCenter && controlCenter.selected;
        const selectedDateStart = dateStart && dateStart.selected;
        const selectedDateEnd = endDate && endDate.selected;
        Pusher.instance.setShiftProviderHistoryFilterStateChannel(sectionModels[currentModelType].model.filterOptions.options || {});

        const resultRequest = yield call(fetchShiftProviders, {
            StartDate: prepareStartDate(selectedDateStart),
            EndDate: prepareEndDate(selectedDateEnd),
            ControlCenterId: selectedControlCenter,
            UserId: null
        });

        if (resultRequest && resultRequest.data) {
            const data = objectKeysToUpperLowerCase(resultRequest.data);
            yield put(setShiftProviders(data));
            yield put(actionSetSectionItemsLoading(false));
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData('Error');
        }

    }, action.type);
}



function* getUserShiftHistorySaga(action) {
    yield* createSaga(function*() {
        yield put(actionSaveShiftsHistory());
        let restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id?.replace('?id=', '');
        const model = sectionModels[sectionModels[currentModelType].model.parentUrl]?.model || {};
        const selectedCC = model.filterOptions?.options?.controlCenter?.selected || null;
        if(!id){
            return yield put(actionSaveShiftsHistory());
        }

        if(restaurant?.shiftsHistory?.id !== id){
            yield put(actionSaveShiftsHistory());
            restaurant = yield select(getRestaurant);
        }

        const isProvider = (restaurant.ordersProvider || []).find(p => p.id === id);

        let requestResult = null;

        yield put(actionSetSectionItemsLoading(true));

        if (isProvider) {
            const dateStart = model?.getFilterOptionPropValue('dateStart', 'selected') || null;
            const dateEnd = model?.getFilterOptionPropValue('endDate', 'selected') || null;
            requestResult = yield call(
                getShiftsProvider,
                dateStart
                    ? getISOTime(prepareStartDate(new Date(dateStart)))
                    : getISOTime(prepareStartDate(new Date())),
                dateEnd
                    ? getISOTime(prepareEndDate(new Date(dateEnd)))
                    : getISOTime(prepareEndDate(dateStart ? new Date(dateStart) : new Date())),
                selectedCC,
                id
            );
        } else {
            requestResult = yield call(getShift, id);
        }

        if (requestResult && requestResult.status === 200) {
            yield saveUserOrProviderShiftHistory(objectKeysToUpperLowerCase(requestResult.data));
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getProviderShiftHistorySaga(action) {
    yield* createSaga(function*() {
        yield put(actionSaveShiftsHistory());
        let restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id?.replace('?id=', '');
        const model = sectionModels[sectionModels[currentModelType].model.parentUrl]?.model || {};
        const selectedCC = model.filterOptions?.options?.controlCenter?.selected || null;
        if(!id){
            return yield put(actionSaveShiftsHistory());
        }

        if(restaurant?.shiftsHistory?.id !== id){
            yield put(actionSaveShiftsHistory());
            restaurant = yield select(getRestaurant);
        }

        let requestResult = null;

        yield put(actionSetSectionItemsLoading(true));

        const dateStart = model?.getFilterOptionPropValue('dateStart', 'selected') || null;
        const dateEnd = model?.getFilterOptionPropValue('endDate', 'selected') || null;
        requestResult = yield call(
            getShiftsProvider,
            dateStart
                ? getISOTime(prepareStartDate(new Date(dateStart)))
                : getISOTime(prepareStartDate(new Date())),
            dateEnd
                ? getISOTime(prepareEndDate(new Date(dateEnd)))
                : getISOTime(prepareEndDate(dateStart ? new Date(dateStart) : new Date())),
            selectedCC,
            id
        );

        if (requestResult && requestResult.status === 200) {
            yield saveUserOrProviderShiftHistory(objectKeysToUpperLowerCase(requestResult.data));
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* saveUserOrProviderShiftHistory(data) {
    let index = 0;
    let prevOrder = null;
    let isActiveRoute = false;
    data.orders.reverse();
    data.orders.forEach(order => {
        let iconType = 0;
        if (
            prevOrder &&
            (prevOrder.markerType === ICONS_MARKER_TYPE.checked ||
                (prevOrder.markerType === ICONS_MARKER_TYPE.canseled && !isActiveRoute))
        ) {
            iconType = MarkerIcons.instance.convertStateToIcon(order.state, 0);
        } else {
            iconType = MarkerIcons.instance.convertStateToIcon(order.state, index);
        }

        if (!isActiveRoute) {
            isActiveRoute = iconType === ICONS_MARKER_TYPE.point;
        }

        order.markerType = iconType;
        index++;
        prevOrder = order;
    });
    yield put(actionSaveShiftsHistory(data));
    yield put(actionSetSectionItemsLoading(false));
}

function* activateControlCenterSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
            startControlCenterShift,
            getInitModel(MODEL_TYPE.controlCenterActivateServerModel, action.data)
        );

        if(requestResult && requestResult.status === 200) {
            yield getUserByIdSaga({ data: { id: action.data?.userId }})
        }

        if (!requestResult || requestResult.status !== 200) {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* settingsControlCenterEdit(action) {
    yield* createSaga(function*() {
        const resultRequest = yield call(
            controlCenterEdit,
            action.data.controlCenterId,
            getInitModel(MODEL_TYPE.controlCenterEdit, action.data)
        );


        if (resultRequest || resultRequest.status === 200) {
            const controlCenters = yield select(getControlCenter);
            const controlCenterId = action.data.controlCenterId

            const newControlCenters = controlCenters.map(controlCenter => {
                if(controlCenter.controlCenterId === controlCenterId){
                    return {
                        ...controlCenter,
                        ...action.data
                    }
                } else {
                    return controlCenter
                }
            })

            yield put(actionUpdateRestaurantControlCenters(newControlCenters));
        } else {
            return new ErrorData(getErrorMessage(resultRequest));
        }
    }, action.type);
}

function* settingsControlCenterGet(action) {
    yield* createSaga(function*() {
        const resultRequest = yield call(fetchRestaurant);

        if(resultRequest && resultRequest.status === 200) {
            const controlCenters = objectKeysToUpperLowerCase(resultRequest.data.ControlCenters)

            yield put(actionUpdateRestaurantControlCenters(controlCenters));
        }
    }, action.data);
}

function* deactivateControlCenterSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
            endControlCenterShift,
            getInitModel(MODEL_TYPE.controlCenterDeactivateServerModel, action.data)
        );

        if(requestResult && requestResult.status === 200) {
            const controlCenterId = action.data?.controlCenterId
            const restaurant = yield select(getRestaurant);
            const controlCenters = restaurant?.controlCenters
            const newCenters = controlCenters.map(center => {
                if(center.controlCenterId === controlCenterId) {
                    return {
                        controlCenterId: center.controlCenterId,
                        name: center.name,
                        user: ''
                    }
                } else {
                    return center
                }
            })
            yield put(actionUpdateRestaurantControlCenters(newCenters));
        }

        if (!requestResult || requestResult.status !== 200) {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* changeUserControlCenterSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
            changeControlCenterShift,
            getInitModel(MODEL_TYPE.controlCenterActivateServerModel, action.data)
        );

        if(requestResult && requestResult.status === 200) {
            const userId = action.data?.userId
            yield getUserByIdSaga({ data: { id: userId }})
            const restaurant = yield select(getRestaurant);

            const controlCenters = restaurant?.controlCenters
            const controlCenterId = action.data?.controlCenterId
            const newCenters = controlCenters.map(center => {
                if(center.controlCenterId === controlCenterId) {
                    return {
                        ...center,
                        user: userId
                    }
                } else {
                    return center
                }

            })
            yield put(actionUpdateRestaurantControlCenters(newCenters));
        }

        if (!requestResult || requestResult.status !== 200) {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* copyTelegramCommandSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const restaurant = yield select(getRestaurant);

        let textField = document.createElement('textarea');
        textField.innerText = `/setdepot ${restaurant.name} ${id}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }, action.type);
}

function* getOrderStatSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const dateStart = action.data.dateStart || prepareStartDate(new Date());
        const dateEnd = action.data.dateEnd || prepareEndDate(new Date());
        const controlCenterIds = action.data.controlCenterIds;
        const territoryId = action.data.territoryId;

        yield put(actionControlCenterRefresh({selectedControlCenterMulti: controlCenterIds }));

        const requestResult = yield call(getReportOrderStatisticsByDate, dateStart, dateEnd, controlCenterIds, null, territoryId);

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);

            if (data) {
                const deliveredOrders = (data.ordersCount || 0) - (data.canceledOrdersCount || 0);
                let shifts = [];

                const requestResultShifts = yield call(
                    getShifts,
                    getISOTime(dateStart),
                    getISOTime(dateEnd),
                    null,
                    null,
                    controlCenterIds,
                    territoryId,
                );

                if (requestResultIsOk(requestResultShifts, true)) {
                    shifts = objectKeysToUpperLowerCase(requestResultShifts.data);
                }

                let reportDeliveryTime = null;

                const requestResultReportDeliveryTime = yield call(
                    getReportDeliveryTimeByDate,
                    getISOTime(dateStart),
                    getISOTime(dateEnd),
                    null,
                    null,
                    controlCenterIds,
                    territoryId,
                );
                if (requestResultIsOk(requestResultReportDeliveryTime, true)) {
                    reportDeliveryTime = objectKeysToUpperLowerCase(requestResultReportDeliveryTime.data);
                }

                let stat = {
                    marketplaceOrdersCount: data.marketplaceOrdersCount || 0,
                    ordersCount: data.ordersCount || 0,
                    canceledOrdersCount: data.canceledOrdersCount || 0,
                    notDeliveredOnTimeOrdersCount: data.notDeliveredOnTimeOrdersCount || 0,
                    inProccessOrdersCount: data.inProccessOrdersCount || 0,
                    onRouteOrdersCount: data.onRouteOrdersCount || 0,
                    deliveredOrdersCount: data.deliveredOrdersCount || 0,
                    outOfRegionOrdersCount: data.outOfRegionOrdersCount || 0,
                    deliveredOrders: deliveredOrders,
                    controlCenterStatistics: data.controlCenterStatistics.map((controlCenter, index) => {
                       return getControlCenterStatisticFormattedData({
                           object: controlCenter,
                           dataToFindName: restaurant.controlCenters,
                           index: index,
                           idKey: 'controlCenterId',
                           shifts: shifts,
                           reportDeliveryTime: reportDeliveryTime
                       })
                    }),
                    depotStatistics: (data.depotStatistics || []).map((depot, index) => {
                        return getControlCenterStatisticFormattedData({
                            object: depot,
                            dataToFindName: restaurant.depots,
                            index: index,
                            findKey: 'id',
                            idKey: 'depotId',
                            shifts: shifts,
                            reportDeliveryTime: reportDeliveryTime
                        })
                    }),
                    score: !!data.hours ? (deliveredOrders / data.hours).toFixed(2) : null,
                };

                stat.reportDeliveryTimeStatistics = getReportDeliveryTimeStatistics(
                    reportDeliveryTime,
                    stat.controlCenterStatistics
                );
                stat.reportAverageDeliveryTimeStatistics = getReportAverageDeliveryTimeStatistics(reportDeliveryTime);
                if ((stat.controlCenterStatistics || []).length) {
                    stat.reportDeliveryTimeStatistics = stat.reportDeliveryTimeStatistics.map(statistic => ({
                        ...statistic,
                        values: statistic.values.concat([
                            {
                                controlCenter: i18n.t('reports.TOTAL'),
                                controlCenterId: i18n.t('reports.TOTAL'),
                                countOrders: statistic.values.reduce((a, b) => a + b.countOrders, 0),
                                averageDeliveryTime: statistic.values.reduce(
                                    (a, b) => (a === 0 ? b.averageDeliveryTime : (a + b.averageDeliveryTime) / 2),
                                    0
                                ),
                            },
                        ]),
                    }));
                }

                const totalAbsShift = stat.controlCenterStatistics.reduce((a, b) => a + b.shiftHours, 0);
                const totalAbsShiftDepot = (stat.depotStatistics || []).reduce((a, b) => a + b.shiftHours, 0);

                const totalDeliveredOrdersCount = stat.controlCenterStatistics.reduce(
                    (a, b) => a + b.deliveredOrders,
                    0
                );
                const totalDeliveredOrdersCountDepot = (stat.depotStatistics || []).reduce(
                    (a, b) => a + b.deliveredOrders,
                    0
                );

                if(reportDeliveryTime){
                    stat.controlCentersAverageDeliveryTime = reportDeliveryTime.controlCenters.map(val => ({
                        averageDeliveryTime: parseInt(val.averageDeliveryTime / 60),
                        controlCenterId: val.controlCenterId,
                        controlCenterName: ((restaurant.controlCenters || []).find(cc => cc.controlCenterId === val.controlCenterId) || {}).name,
                    }));
                }

                return {
                    ...stat,
                    countCouriers: stat.controlCenterStatistics.reduce((a, b) => a + b.countCouriers, 0),
                    countCouriersDepot: (stat.depotStatistics || []).reduce((a, b) => a + b.countCouriers, 0),
                    unassignedOrders: stat.controlCenterStatistics.reduce((a, b) => a + b.unassignedOrders, 0),
                    unassignedOrdersDepot: (stat.depotStatistics || []).reduce((a, b) => a + b.unassignedOrders, 0),
                    waitingCouriers: stat.controlCenterStatistics.reduce((a, b) => a + b.waitingCouriers, 0),
                    waitingCouriersDepot: (stat.depotStatistics || []).reduce((a, b) => a + b.waitingCouriers, 0),
                    ordersScore:
                        totalDeliveredOrdersCount && totalAbsShift ? totalDeliveredOrdersCount / totalAbsShift : 0,
                    ordersScoreDepot:
                        totalDeliveredOrdersCountDepot && totalAbsShiftDepot ? totalDeliveredOrdersCountDepot / totalAbsShiftDepot : 0,
                    dayAverageDeliveryTime:
                        stat.controlCenterStatistics.reduce((a, b) => a + b.dayAverageDeliveryTime, 0) /
                        stat.controlCenterStatistics.length,
                    dayAverageDeliveryTimeDepot:
                        (stat.depotStatistics || []).reduce((a, b) => a + b.dayAverageDeliveryTime, 0) /
                        (stat.depotStatistics || []).length,
                    shiftHours: totalAbsShift,
                    shiftHoursDepot: totalAbsShiftDepot,
                };
            } else {
                return [];
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export const getControlCenterStatisticFormattedData = ({object, index, idKey, findKey, shifts, reportDeliveryTime, dataToFindName}) => {
    const defaultName = `Control center-${index}-${idKey}`;
    const restaurantControlCenter = (dataToFindName || []).find(
        f => f[findKey || idKey] === object[idKey]
    );
    const couriers =
        shifts.filter(
            f =>
                f.controlCenterId === (object[idKey] || defaultName) &&
                f.shiftType === SHIFT_TYPE.Normal
        ) || [];
    const absShift = couriers.reduce((a, b) => {
        const startDate = moment(b.startDate);
        let endDate = b.endDate ? moment(b.endDate) : moment();
        const diff = endDate.diff(startDate, 'hours', true);

        return a + (diff > 0 ? diff : 0);
    }, 0);
    const controlCenterDeliveredOrders =
        (object.ordersCount || 0) - (object.canceledOrdersCount || 0);

    return {
        ...object,
        [idKey]: object[idKey] || defaultName,
        deliveredOrders: controlCenterDeliveredOrders,
        name: restaurantControlCenter ? restaurantControlCenter.name : defaultName,
        countCouriers: couriers.length,
        unassignedOrders: object.unhandledOrdersCount || 0,
        waitingCouriers: object.outOfRegionOrdersCount || 0,
        ordersScore:
            absShift && controlCenterDeliveredOrders ? controlCenterDeliveredOrders / absShift : 0,
        shiftHours: absShift || 0,
        dayAverageDeliveryTime:
            reportDeliveryTime &&
            reportDeliveryTime.items.reduce((a, b) => {
                if (
                    b.controlCenterId === object[idKey] &&
                    b.state === ORDER_STATE.delivered
                ) {
                    a =
                        a === 0
                            ? new Date(b.deliveryTime) - new Date(b.date)
                            : (a + (new Date(b.deliveryTime) - new Date(b.date))) / 2;
                }

                return a;
            }, 0),
    };
}

function* notificationAddSaga(action) {
    yield* createSaga(function*() {
        const notifications = yield select(getNotifications);
        const notification = objectKeysToUpperLowerCase(action.data);
        yield put(actionNotificationUpdate(notifications.concat(notification.notification)));
        yield put(actionAlarmAdd(notification.notification));

        AudioService.instance.playSound(notification.notification.type);
    }, action.type);
}

function* notificationDeleteSaga(action) {
    yield* createSaga(function*() {
        const notifications = yield select(getNotifications);
        if (action.data && action.data.Id) {
            yield put(actionNotificationUpdate(notifications.filter(f => f.id !== action.data.Id)));
            yield put(actionAlarmDelete({ id: action.data.Id }));
        }
    }, action.type);
}

function* notificationRestartSaga(action) {
    yield* createSaga(function*() {
        yield put(actionNotificationUpdate([]));
        yield put(actionAlarmRestart());
    }, action.type);
}

function* businessCategoryGetSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        if (!restaurant.businessCategory || !restaurant.businessCategory.length) {
            const requestResult = yield call(fetchBusinessCategory);
            if (requestResultIsOk(requestResult, true)) {
                yield put(actionRestaurantUpdate({ businessCategory: objectKeysToUpperLowerCase(requestResult.data) }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* cancelReasonGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchCancelReason);
        if (requestResultIsOk(requestResult, true)) {
            yield put(actionRestaurantUpdate({ cancelReason: objectKeysToUpperLowerCase(requestResult.data) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* cancelReasonAddSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchAddCancelReason, action.data);
        if (requestResultIsOk(requestResult, true)) {
            const {cancelReasons} = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ cancelReason: (cancelReasons || []).concat(objectKeysToUpperLowerCase(requestResult.data)) }));
            return;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* cancelReasonEditSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchEditCancelReason, action.data.id, action.data);
        if (requestResultIsOk(requestResult, true)) {
            const {cancelReason} = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ cancelReason: (cancelReason || []).concat(objectKeysToUpperLowerCase(requestResult.data)) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* cancelReasonDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchDeleteCancelReason, action.data.id);
        if (requestResultIsOk(requestResult)) {
            const {cancelReason} = yield select(getRestaurant);
            const filteredReasons = (cancelReason || []).filter(reason => reason.id !== action.data.id);
            yield put(actionRestaurantUpdate({ cancelReason: filteredReasons }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessDeepLinkCreateSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(createBusinessDeepLink, action.data);
        if (requestResultIsOk(requestResult)) {
            yield put(setBusinessMenu(objectKeysToUpperLowerCase(requestResult.data) ));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessProductDeepLinkCreateSaga(action) {
    yield* createSaga(function*() {
        const businessMenu = yield select(state => state.restaurant.data.businessMenu);
        const { id } = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(createBusinessProductDeepLink, businessMenu.id, id, action.data);
        if (requestResultIsOk(requestResult)) {
            yield put(setBusinessMenu(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketplaceDeepLinkCreateSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(createMarketplaceDeepLink);
        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ paymentsConfiguration: {
                ...restaurant.paymentsConfiguration,
                ...objectKeysToUpperLowerCase(requestResult.data),
            }}));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotBusinessTranslationGetSaga(action) {
    yield* createSaga(function*() {
        const isLoading = yield select(getIsSectionsWrapperLoading);
        if(isLoading) return
        yield put(actionSetSectionItemsLoading(true));
        const restaurant = yield select(getRestaurant);

        yield put(actionRestaurantUpdate({
            translations: {
                values: [],
                language: restaurant?.translations?.language
            },
        }));

        const { businessId, id } = getHistoryLocationParams(window.location.search);
        const language = action?.data?.language || restaurant?.translations?.language || 'en'
        const requestResult = yield call(getDepotBusinessTranslation, { businessId: businessId || id, language });
        if (requestResultIsOk(requestResult)) {
            yield put(actionRestaurantUpdate({
                translations: {
                    values: objectKeysToUpperLowerCase(requestResult.data),
                    language: language
                },
            }));
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
        yield put(actionSetSectionItemsLoading(false));
    }, action.type);
}

function* depotBusinessTranslationUpdateSaga(action) {
    yield* createSaga(function*() {
        const { businessId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const language = restaurant?.translations?.language || 'en'
        let data = action.data?.values?.filter(value => value?.translated_value !== null)
        data = data.map(item => settingsTranslationsServerModel(item))
        const requestResult = yield call(setDepotBusinessTranslation, {data: data, businessId: businessId || id, language });
        if (!requestResultIsOk(requestResult)) {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotBusinessTranslationExportSaga(action) {
    yield* createSaga(function*() {
        const { businessId, id } = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const language = restaurant?.translations?.language || 'en'
        const requestResult = yield call(exportDepotBusinessTranslation, { businessId: businessId || id, language });
        if (requestResultIsOk(requestResult)) {
            const c = requestResult.headers["content-disposition"];
            let fileName = '';
            try {
                fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), "")) || decodeURIComponent(escape(atob(c.split('?')[3])));
            } catch (e) {
                fileName = `Translation ${getDepotNameById(id)}.xlsx`;
            }
            const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const contentType = requestResult.headers["content-type"] || octetStreamMime;
            const blob = new Blob([requestResult.data], { type: contentType });

            saveAs(blob, fileName);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotBusinessTranslationImportSaga(action) {
    yield* createSaga(function*() {
        const file = action.data?.file
        if(!file) return
        const { businessId, id } = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(importDepotBusinessTranslation, {  businessId: businessId || id, file });
        if (requestResultIsOk(requestResult)) {

            yield put(actionSetSectionItemsLoading(true));
            const restaurant = yield select(getRestaurant);
            const language = restaurant?.translations?.language || 'en'
            const requestResult = yield call(getDepotBusinessTranslation, { businessId: businessId || id, language });
            if (requestResultIsOk(requestResult)) {
                yield put(actionRestaurantUpdate({
                    translations: {
                        values: objectKeysToUpperLowerCase(requestResult.data),
                        language: language
                    },
                }));
            } else {
                yield put(actionSetSectionItemsLoading(false));
                return new ErrorData(getErrorMessage(requestResult));
            }

            yield put(actionSetSectionItemsLoading(false));

        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action?.type);
}

function* businessCategoryEditSaga(action) {
    yield* createSaga(function*() {
        const id = action.data.id;
        const logo = action.data.logo;

        if (id && isBase64Image(logo)) {
            yield call(setBusinessCategoryAvatarBase64, id, dataURItoBlob(logo));
        }

        const data = id
            ? {
                  Id: action.data.id,
                  isStore: action.data.isStore,
                  LogoSettings: action.data.logoSettings,
                  NameTranslations: action.data.nameTranslations,
              }
            : {
                  Logo: '',
                  isStore: action.data.isStore,
                  LogoSettings: action.data.logoSettings,
                  NameTranslations: action.data.nameTranslations,
              };
        let requestResult = yield call(!id ? businessCategoryAdd : businessCategoryEdit, data, id);

        if (requestResult && requestResult.status === 200) {
            const newId = requestResult.data.Id;
            let restaurant = yield select(getRestaurant);
            const newData = {
                id: newId,
                logo: requestResult.data.Logo,
                logoSettings: requestResult.data.LogoSettings,
                nameTranslations: requestResult.data.NameTranslations,
                isStore: action.data.isStore,
            };
            const businessCategory = id
                ? restaurant.businessCategory.map(category => (category.id === id ? newData : category))
                : restaurant.businessCategory.concat([newData]);
            yield put(actionRestaurantUpdate({ ...restaurant, businessCategory }));

            if (!id && newId && isBase64Image(logo)) {
                yield call(setBusinessCategoryAvatarBase64, newId, dataURItoBlob(logo));

                requestResult = yield call(businessCategoryEdit, data, newId);
                if (requestResult && requestResult.status === 200) {
                    restaurant = yield select(getRestaurant);
                    yield put(
                        actionRestaurantUpdate({
                            businessCategory: restaurant.businessCategory.map(category =>
                                category.id === newId
                                    ? {
                                          id: newId,
                                          isStore: requestResult.data.IsStore,
                                          logoSettings: requestResult.data.LogoSettings,
                                          logo: requestResult.data.Logo,
                                          nameTranslations: requestResult.data.NameTranslations,
                                      }
                                    : category
                            ),
                        })
                    );
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessCategoryDeleteSaga(action) {
    yield* createSaga(function*() {
        if (action.data.id) {
            const requestResult = yield call(businessCategoryRemove, action.data.id);

            if (requestResult && requestResult.status === 200) {
                const restaurant = yield select(getRestaurant);
                const businessCategory = restaurant.businessCategory.filter(f => f.id !== action.data.id);
                yield put(actionRestaurantUpdate({ ...restaurant, businessCategory }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* businessCategoryUpdateSaga(action) {
    yield* createSaga(function*() {
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(businessCategoryReorder, data);

        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ businessCategory: action.data.data }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* operationTimingAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const operations = {...restaurant.operations};
        const newItem = getInitModel(MODEL_TYPE.operationTiming, action.data);
        operations.profiles = [...operations.profiles, newItem];
        operations.activeProfile = operations.activeProfile ? operations.activeProfile : newItem.name

        const data = objectKeysToUpperLowerCase(operations, true);
        const requestResult = yield call(updateOperations, data);
        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ ...restaurant, operations }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* operationTimingEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const operations = {...restaurant.operations};
        const editedOperation = getInitModel(MODEL_TYPE.operationTiming, action.data);
        operations.profiles = [...operations.profiles]
            .map(p =>
                p.name === action.data.id
                    ? editedOperation
                    : p
            )

        const data = objectKeysToUpperLowerCase(operations, true);
        const requestResult = yield call(updateOperations, data);
        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ ...restaurant, operations }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* operationTimingDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant)
        const operations = restaurant.operations;
        operations.profiles = operations.profiles.filter(o => o.name !== action.data.item.name);

        const data = objectKeysToUpperLowerCase(operations, true);
        const requestResult = yield call(updateOperations, data);

        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ ...restaurant, operations }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* operationTimingToggleDefaultPropertySaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant)
        const operations = restaurant.operations;
        operations[action.data.paramToToggle] = action.data.name;

        const data = objectKeysToUpperLowerCase(operations, true);
        const requestResult = yield call(updateOperations, data);

        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ ...restaurant, operations }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getOrderingMenuSaga(action) {
    yield* createSaga(function*() {
        yield put(actionGetSettingsDeliveryZones());
        yield put(actionGetBusinessCategories());
        yield put(actionGetOperationTiming());
        let restaurant = yield select(getRestaurant);

        const params = getHistoryLocationParams(window.location.search);
        const id = (params.parentId || params.id).replace('?id=', '');

        const depot = restaurant && restaurant.depots.find(d => d.id === id || (d.businessId && d.businessId === id));
        const businessMenuId = (depot && depot.businessId) || id;

        const businessId = restaurant.businessMenu && restaurant.businessMenu.id;
        const extras = restaurant?.businessMenu?.extras || []
        const categories = restaurant?.businessMenu?.categories || []


        if(businessMenuId !== businessId) {
            yield put(setBusinessMenu(null))
        }

        if ((businessMenuId && businessId !== businessMenuId) || !extras.length || !categories.length) {
            const requestResult = yield call(getBusinessMenu, businessMenuId);

            if (requestResult.status !== 200) {
                return new ErrorData(getErrorMessage(requestResult));
            }

            if (requestResult && requestResult.data) {
                const businessMenu = objectKeysToUpperLowerCase(requestResult.data);
                yield put(setBusinessMenu(businessMenu));

                const parentMenu = yield select(state => state.restaurant.data.parentMenu);
                if((businessMenu.template_business_id && parentMenu && parentMenu.id !== businessMenu.template_business_id) || (businessMenu.template_business_id && !parentMenu)) {
                    const requestParentMenu = yield call(getBusinessMenu, businessMenu.template_business_id, true);
                    yield put(setParentBusinessMenu(requestParentMenu.data));
                }

                if(businessMenu?.logo) {
                    const requestResultAvatar = yield call(fetchMarkerAvatarBase64, businessMenu.logo, true);
                    yield put(
                      setBusinessMenu({
                          ...businessMenu,
                          avatar: requestResultAvatar,
                      })
                    );
                }
            }
        }
    }, action.type);
}

function* orderingMenuDeliveryZoneEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const data = getDeliveryZoneData(action.data);

        const requestResult = yield call(updateDeliveryZone, restaurant.businessMenu.id, data);
        if (requestResultIsOk(requestResult, true)) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuDeliveryZoneAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        let data = getDeliveryZoneData(action.data);

        const requestResult = yield call(setDeliveryZone, restaurant.businessMenu.id, data);
        if (requestResultIsOk(requestResult, true)) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuDeliveryZoneDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const requestResult = yield call(deleteDeliveryZone, restaurant.businessMenu.id, action.data.id);

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuScheduleEditSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
            updateSchedule,
            action.data.id,
            action.data.schedule.map(m => ({ enabled: m.enabled, lapses: m.lapses }))
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuBusinessDiscountEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const { start, end, limit, minimum, enable_schedule, schedule } = action.data;
        const d1 = start && new Date(start);
        const d2 = end && new Date(end);

        if (isSameObjects(d1, d2)) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_DATE_ERROR'));
        }

        if (limit && minimum && limit <= minimum) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_LIMIT_ERROR'));
        }

        const hasItemLapses = schedule?.filter(item => item?.lapses?.length)?.length
        if(enable_schedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }

        const requestResult = yield call(updateBusinessMenuDiscount, restaurant.businessMenu.id, action.data);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuBusinessDiscountAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        if(action.data.discount_target === OFFER_DISCOUNT_TARGET.product && action.data.product_id) {
            action.data.business_id = restaurant.businessMenu.id;
        }

        const { start, end, limit, minimum, enable_schedule, schedule } = action.data;
        const d1 = start && new Date(start);
        const d2 = end && new Date(end);

        if (isSameObjects(d1, d2)) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_DATE_ERROR'));
        }

        if (limit && minimum && limit <= minimum) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_LIMIT_ERROR'));
        }

        const hasItemLapses = schedule?.filter(item => item?.lapses?.length)?.length
        if(enable_schedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }

        const requestResult = yield call(addBusinessMenuDiscount, restaurant.businessMenu.id, {
            ...action.data,
            start: getISOTime(action.data.start),
            end: getISOTime(action.data.end),
        });
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuBusinessDiscountDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const requestResult = yield call(deleteBusinessMenuDiscount, restaurant.businessMenu.id, action.data.id);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuBusinessInfoEditSaga(action) {
    yield* createSaga(function*() {
        const { id, businessId } = getHistoryLocationParams(window.location.search);
        const data = getInitModel(MODEL_TYPE.businessInfoServerModel, action.data);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const templatesId = sectionModels[currentModelType].id || id;
        const requestResult = yield call(updateBusinessMenuInfo, businessId || id, data);

        if (requestResultIsOk(requestResult)) {
            const newData = objectKeysToUpperLowerCase(requestResult.data) || data;
            const restaurant = yield select(getRestaurant);
            const menuTemplates = yield select(getMenuTemplates);
            const menuTemplate = menuTemplates.find(f => f.business_id === templatesId);

            if (!menuTemplate) {
                yield put(actionRestaurantUpdate({ consumerOrderMenu: newData }));
            }

            yield put(setBusinessMenu(newData));

            if (
                (restaurant.businessMenu && restaurant.businessMenu.header) !== action.data.header &&
                (isBase64Image(action.data.header) || isBase64Video(action.data.header))
            ) {
                yield put(actionSetBusinessMenuAvatar({
                    businessId: businessId || id,
                    avatar: action.data.header,
                    isVideo: isBase64Video(action.data.header)
                }));
            }

            if (
                (restaurant.businessMenu && restaurant.businessMenu.header_mini) !== action.data.header_mini &&
                (isBase64Image(action.data.header_mini) || isBase64Video(action.data.header_mini))
            ) {
                yield put(actionSetBusinessMenuHeaderMini({
                    businessId: businessId || id,
                    header_mini: action.data.header_mini,
                    isVideo: isBase64Video(action.data.header_mini)
                }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* setBusinessMenuHeaderSaga(action) {
    yield* createSaga(function*() {
        let { businessId, avatar, isVideo } = action.data;
        const requestResult = yield call(updateBusinessMenuHeaderAvatar, businessId, dataURItoBlob(avatar), isVideo);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu({ ...requestResult.data, avatar }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* setBusinessMenuHeaderMiniSaga(action) {
    yield* createSaga(function*() {
        let { businessId, header_mini, isVideo } = action.data;
        const requestResult = yield call(updateBusinessMenuHeaderMini, businessId, dataURItoBlob(header_mini), isVideo);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu({ ...requestResult.data, header_mini }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const requestResult = yield call(businessMenuCategoryDelete, restaurant.businessMenu.id, action.data.id);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));

        const requestResult = yield call(businessMenuCategoryReorder, restaurant.businessMenu.id, data);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const requestResult = yield call(
            businessMenuCategoryEdit,
            restaurant.businessMenu.id,
            action.data.id,
            getInitModel(MODEL_TYPE.categoryServerModel, action.data)
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryListUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const category = restaurant.businessMenu.categories.find(f => f.id === action.data.id);
        let requestResult = null;

        if (category.enabled !== action.data.enabled) {
            requestResult = yield call(businessMenuCategoryActivate, restaurant.businessMenu.id, action.data.id, {
                enabled: action.data.enabled,
            });
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: action.data.id, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const requestResult = yield call(
            businessMenuCategoryAdd,
            restaurant.businessMenu.id,
            getInitModel(MODEL_TYPE.categoryServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const productId = action.data.id;
        const params = getHistoryLocationParams(window.location.search);
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id;
        const menu = restaurant.businessMenu;

        const { enable_schedule, schedule } = action.data;

        const hasItemLapses = schedule?.filter(item => item?.lapses?.length)?.length
        if(enable_schedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }

        const requestResult = yield call(
            businessMenuCategoryProductEdit,
            menu.id,
            categoryId,
            productId,
            getInitModel(MODEL_TYPE.categoryProductServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
            if (isBase64Image(action.data.images) || isBase64Video(action.data.images)) {
                yield put(
                    actionSetCategoryProductAvatar({
                        businessId: menu.id,
                        categoryId,
                        productId,
                        image: action.data.images,
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }

        if (isBase64Image(action.data.images) || isBase64Video(action.data.images)) {
            const requestResultAvatar = yield call(
              updateBusinessMenuCategoryProductAvatar,
              menu.id,
              categoryId,
              productId,
              dataURItoBlob(action?.data?.images),
              isBase64Video(action?.data?.images)
            );

            if (requestResultAvatar && requestResultAvatar.status === 200 && requestResultAvatar.data) {
                yield put(setBusinessMenu(requestResultAvatar.data));
            } else {
                return new ErrorData(getErrorMessage(requestResultAvatar));
            }
        }

    }, action.type);
}

function* businessMenuCategoryProductAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessMenu = restaurant.businessMenu;
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id;

        const requestResult = yield call(
            businessMenuCategoryProductAdd,
            businessMenu.id,
            categoryId,
            getInitModel(MODEL_TYPE.categoryProductServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            const newDepot = requestResult.data;
            yield put(setBusinessMenu(newDepot));

            const oldListProducts = businessMenu.categories.find(f => f.id === categoryId)['products'].map(m => m.id);
            const newIds = requestResult.data.categories
                .find(f => f.id === categoryId)
                ['products'].filter(f => !oldListProducts.includes(f.id))
                .map(m => m.id);

            if (newIds.length === 1 && (isBase64Image(action.data.images) || isBase64Video(action.data.images))) {
                yield put(
                    actionSetCategoryProductAvatar({
                        depot: newDepot,
                        businessId: newDepot.id,
                        categoryId,
                        productId: newIds[0],
                        image: action.data.images,
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessMenu = restaurant.businessMenu;
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const productId = action.data.id;
        const categoryId = sectionModels[currentModelType].id;

        const requestResult = yield call(businessMenuCategoryProductDelete, businessMenu.id, categoryId, productId);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductsDragUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const categoryId = sectionModels[currentModelType].id;
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));

        const requestResult = yield call(
            businessMenuCategoryProductsReorder,
            restaurant.businessMenu.id,
            categoryId,
            data
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductsUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const categoryId = sectionModels[currentModelType].id;
        const category = restaurant.businessMenu.categories.find(f => f.id === categoryId);
        const product = category.products.find(f => f.id === action.data.id);
        let requestResult = null;

        if (product.enabled !== action.data.enabled) {
            requestResult = yield call(
                businessMenuCategoryProductActivate,
                restaurant.businessMenu.id,
                categoryId,
                action.data.id,
                { enabled: action.data.enabled }
            );
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: action.data.id, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductsUpdateAvatarSaga(action) {
    yield* createSaga(function*() {
        const { businessId, categoryId, productId, image } = action.data;

        const requestResult = yield call(
            updateBusinessMenuCategoryProductAvatar,
            businessId,
            categoryId,
            productId,
            dataURItoBlob(image),
            isBase64Video(image)
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { item, value, type, accessor } = action.data;
        const productId = item.id;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;
        const depotId = sectionModels[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit].id;
        const depot = restaurant.depots.find(f => f.id === depotId);
        const category = depot.businessMenu.categories.find(f => f.id === categoryId);
        const product = category.products.find(f => f.id === productId);
        let requestResult = null;

        if (accessor === 'categoryIngredients') {
            switch (type) {
                case TYPE_EDIT_ACTION.add:
                    requestResult = yield call(
                        businessMenuCategoryProductIngredientAdd,
                        depot.businessId,
                        categoryId,
                        productId,
                        { Name: value, Order: product.ingredients.length }
                    );
                    break;
                case TYPE_EDIT_ACTION.edit:
                    let ingredient = product.ingredients[item.categoryIngredients.indexOf(value)];
                    requestResult = yield call(
                        businessMenuCategoryProductIngredientUpdate,
                        depot.businessId,
                        categoryId,
                        productId,
                        ingredient.id,
                        { Name: value, Order: ingredient.order || 0 }
                    );
                    break;
                case TYPE_EDIT_ACTION.delete:
                    ingredient = product.ingredients[value];
                    requestResult = yield call(
                        businessMenuCategoryProductIngredientDelete,
                        depot.businessId,
                        categoryId,
                        productId,
                        ingredient.id
                    );
                    break;
                default:
                    break;
            }
        } else {
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(actionDepotEdit({ ...depot, businessMenu: requestResult.data }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;

        const requestResult = yield call(
            businessMenuCategoryExtrasAdd,
            restaurant.businessMenu.id,
            categoryId,
            getInitModel(MODEL_TYPE.extraServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const extraId = action.data.id;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;

        const requestResult = yield call(
            businessMenuCategoryExtrasUpdate,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            getInitModel(MODEL_TYPE.extraServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const categoryId = sectionModels[currentModelType].id;
        const category = restaurant.businessMenu.categories.find(f => f.id === categoryId);
        const extra = category.extras.find(f => f.id === action.data.id);
        let requestResult = null;

        if (extra.enabled !== action.data.enabled) {
            requestResult = yield call(
                businessMenuCategoryExtrasActive,
                restaurant.businessMenu.id,
                categoryId,
                action.data.id,
                { enabled: action.data.enabled }
            );
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: action.data.id, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const extraId = action.data.id;
        const categoryId = sectionModels[currentModelType].id;

        const requestResult = yield call(
            businessMenuCategoryExtraDelete,
            restaurant.businessMenu.id,
            categoryId,
            extraId
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasOptionUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const value = getInitModel(MODEL_TYPE.productOptionModel, action.data.value);
        const extraId = sectionModels[currentModelType].id;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;
        const option = action.data.item.options.find(f => f.id === action.data.value.id);
        let requestResult = null;

        if (option.enabled !== value.enabled) {
            requestResult = yield call(
                businessMenuCategoryExtrasOptionActivate,
                restaurant.businessMenu.id,
                categoryId,
                extraId,
                value.id,
                { enabled: value.enabled }
            );
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: value.id, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasOptionEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const params = getHistoryLocationParams(window.location.search);
        const extraId = params.subId;
        const categoryId = params.id;

        const requestResult =
            action.data.id || params.subToId
                ? yield call(
                      businessMenuCategoryExtrasOptionUpdate,
                      restaurant.businessMenu.id,
                      categoryId,
                      extraId,
                      action.data.id || params.subToId,
                      getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
                  )
                : yield call(
                      businessMenuCategoryExtrasOptionAdd,
                      restaurant.businessMenu.id,
                      categoryId,
                      extraId,
                      getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
                  );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasOptionDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const params = getHistoryLocationParams(window.location.search);
        const extraId = params.subId;
        const categoryId = params.id;

        const requestResult = yield call(
            businessMenuCategoryExtrasOptionDelete,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            action.data.id
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasOptionReorderSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const extraId = sectionModels[currentModelType].id;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;

        const requestResult = yield call(
            businessMenuCategoryExtrasOptionReorder,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            action.data.items.map(item => ({ id: item.id, order: item.order }))
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasSuboptionUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { item, value } = action.data;
        const params = getHistoryLocationParams(window.location.search);
        const extraId = params.subId;
        const categoryId = sectionModels[currentModelType].model.parentUrl
            ? sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id
            : params.id;
        const suboption = item.suboptions.find(f => f.id === value.id);
        let requestResult = null;

        if (suboption.enabled !== value.enabled) {
            requestResult = yield call(
                businessMenuCategoryExtrasSuboptionActivate,
                restaurant.businessMenu.id,
                categoryId,
                extraId,
                item.id,
                value.id,
                { enabled: value.enabled }
            );
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: value.id, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuProductOverrideExtraSaga(action) {
    yield* createSaga(function*() {
        const { id, subToId} = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const businessId = restaurant.businessMenu && restaurant.businessMenu.id;

        const data = {
            name: action.data.name,
            description: action.data.description,
        };

        const isCategoryExtras = restaurant.businessMenu.categories.some(cat => cat?.extras.some(extra => extra.id === action.data.id))

        let requestResult = isCategoryExtras ? yield call(
            businessMenuCategoryExtrasUpdate,
            restaurant.businessMenu.id,
            id,
            subToId,
            getInitModel(MODEL_TYPE.extraServerModel, action.data)
            )
          :
          yield call(
            businessCategoryExtraEdit,
            data,
            businessId,
            subToId
        );

        yield put(setBusinessMenu(requestResult.data));
    }, action.type);
}

function* businessMenuProductOverrideOptionSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { subToId, id } = getHistoryLocationParams(window.location.search);

        const isCategoryExtras = restaurant.businessMenu.categories.some(cat => cat?.extras.some(extra => extra.id === subToId))

        const requestResult = isCategoryExtras ? yield call(
            businessMenuCategoryExtrasOptionUpdate,
            restaurant.businessMenu.id,
            id,
            subToId,
            action.data.id,
            getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
          ) :
          yield call(
              businessCategoryExtraOptionUpdate,
              restaurant.businessMenu.id,
              subToId,
              action.data.id || id,
              getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
            )

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuProductOverrideSuboptionSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { businessMenu } = yield select(getRestaurant);
        const { subToId, id, subId } = getHistoryLocationParams(window.location.search);

        const category = businessMenu && businessMenu.categories.find(c => c.id === id);
        const product = category && category.products.find(p => p.id === subId);
        const extras = mergeArrays(
          businessMenu.extras,
          mergeArrays(category && category.extras, product && product.extras)
        );
        const extra = extras && extras.find(el => el.id === subToId);
        const optionId = extra.options.find(el => el.suboptions.find(option => option.id === action.data.id)).id || ''

        const isCategoryExtras = restaurant.businessMenu.categories.some(cat => cat?.extras.some(extra => extra.id === subToId))

        const requestResult = isCategoryExtras ?
          yield call(
            businessMenuCategoryExtrasSuboptionUpdate,
            restaurant.businessMenu.id,
            id,
            subToId,
            optionId,
            action.data.id,
            getInitModel(MODEL_TYPE.extraSuboptionServerModel, action.data)
          ) :
          yield call(
            businessCategoryExtraSubOptionEdit,
            restaurant.businessMenu.id,
            subToId,
            optionId,
            action.data.id,
            getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
          )

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* menuTemplateOverrideExtraSaga(action) {
    yield* createSaga(function*(){
        const {id, subId, subToId} = getHistoryLocationParams(window.location.search);

        const find = getBusinessExtra(id, subId, subToId);

        if(find) {
            const overrides = prepareOverrideObject(action.data, find, find.overrides);
            find.overrides = overrides || [];
        }

        return yield;
    }, action.type);
}

function* menuTemplateOverrideOptionSaga(action) {
    yield* createSaga(function*(){
        const {id, subId, subToId} = getHistoryLocationParams(window.location.search);

        const objects = getBusinessExtra(id, subId, subToId);
        const find = (objects && objects.options).find(u => u.id === action.data.id);
        if(find) {
            const overrides = prepareOverrideObject(action.data, find, objects.overrides);
            objects.overrides = overrides || [];
        }
        return yield;
    }, action.type);
}

function* menuTemplateOverrideSuboptionSaga(action) {
    yield* createSaga(function*(){
        const {id, subId, subToId} = getHistoryLocationParams(window.location.search);

        const objects = getAllBusinessSuboption(id, subId, subToId);
        const objectWithOverride = getBusinessExtra(id, subId, subToId);
        const find = (objects || []).find(s => s.id === action.data.id);

        if(find) {
            const overrides = prepareOverrideObject(action.data, find, objectWithOverride.overrides);
            objectWithOverride.overrides = overrides || [];
        }
        return yield;
    }, action.type);
}

function* menuTemplateOverrideSuboptionDeleteSaga(action) {
    yield* createSaga(function*(){
        const {item, column, onPropChange} = action.data;
        const {id, subId, subToId} = getHistoryLocationParams(window.location.search);

        const objects = getAllBusinessSuboption(id, subId, subToId);
        const find = (objects || []).find(s => s.id === item.id);

        if(find) {
            onPropChange && onPropChange({
                [column.accessor]: find[column.accessor],
                overridesProperty: item.overridesProperty.filter(prop => prop !== column.accessor)
            });
        }
        return yield;
    }, action.type);
}

function* menuTemplateOverrideOptionDeleteSaga(action) {
    yield* createSaga(function*(){
        const {item, column, onPropChange} = action.data;
        const {id, subId, subToId} = getHistoryLocationParams(window.location.search);

        const objects = getBusinessExtra(id, subId, subToId);
        const find = (objects && objects.options).find(u => u.id === item.id);

        if(find) {
            onPropChange && onPropChange({
                [column.accessor]: find[column.accessor],
                overridesProperty: item.overridesProperty.filter(prop => prop !== column.accessor)
            });
        }
        return yield;
    }, action.type);
}

function* menuTemplateOverrideExtraDeleteSaga(action) {
    yield* createSaga(function*(){
        const {item, column, onPropChange} = action.data;
        const {id, subId} = getHistoryLocationParams(window.location.search);

        const objects = getBusinessProduct(id, subId);
        const find = (objects && objects.extras).find(u => u.id === item.id);

        if(find) {
            onPropChange && onPropChange({
                [column.accessor]: find[column.accessor],
                overridesProperty: item.overridesProperty.filter(prop => prop !== column.accessor)
            });
        }
        return yield;
    }, action.type);
}

function* parentMenuOverrideDeleteSaga(action) {
    yield* createSaga(function*(){
        const {item, column, onPropChange} = action.data;

        const parentMenu = yield select(state => state.restaurant.data.parentMenu);
        const objects = getAllEnabledOverrideDataFromMenu(parentMenu);
        const find = objects.find(u => u.id === item.id);

        if(find) {
            onPropChange && onPropChange({
                [column.accessor]: find[column.accessor],
                overridesProperty: item.overridesProperty.filter(prop => prop !== column.accessor)
            });

        }
    }, action.type);
}

function* businessMenuProductDeleteOverrideSaga(action) {
    yield* createSaga(function*() {
        const { item, column } = action.data;
        const { businessMenu } = yield select(getRestaurant);
        const { id, subId, subToId } = getHistoryLocationParams(window.location.search);
        const category = businessMenu && businessMenu.categories.find(c => c.id === id);
        const product = category && category.products.find(p => p.id === subId);
        const extra = product && product.extras.find(c => c.id === subToId);

        extra.overrides = extra.overrides.filter(o => !(o.id === item.id && o.name === column.accessor));

        yield put(setBusinessMenu(businessMenu));
    }, action.type);
}

function* businessMenuCategoryExtrasSuboptionReorderSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const params = getHistoryLocationParams(window.location.search);
        const { currentModelType, sectionModels } = sectionModel;
        const optionId = action.data.item.id;
        const extraId = params.subId;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;

        const requestResult = yield call(
            businessMenuCategoryExtrasSuboptionReorder,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            optionId,
            action.data.items.map(item => ({ id: item.id, order: item.order }))
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasSuboptionDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const optionId = sectionModels[currentModelType].id || params.subToId;
        const extraId = params.subId;
        const categoryId = sectionModels[currentModelType].model.parentUrl
            ? sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id
            : params.id;

        const requestResult = yield call(
            businessMenuCategoryExtrasSuboptionDelete,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            optionId,
            action.data.id
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasSuboptionEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const optionId = sectionModels[currentModelType].id || params.subToId;
        const extraId = params.subId;
        const categoryId = sectionModels[currentModelType].model.parentUrl
            ? sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id
            : params.id;

        const requestResult = yield call(
            businessMenuCategoryExtrasSuboptionUpdate,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            optionId,
            action.data.id,
            getInitModel(MODEL_TYPE.extraSuboptionServerModel, action.data)
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryDepotEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessId = restaurant.businessMenu.template_business_id

        const requestResult = yield call(
          businessMenuCategoryEdit,
          businessId,
          action.data.id,
          getInitModel(MODEL_TYPE.categoryServerModel, action.data)
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setParentBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasDepotEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const extraId = action.data.id;
        const parentId = restaurant?.parentMenu.categories.find(cat => cat.extras.find(ex => ex.id === extraId)).id
        const businessId = restaurant.businessMenu.template_business_id

        const requestResult = yield call(
          businessMenuCategoryExtrasUpdate,
          businessId,
          parentId,
          extraId,
          getInitModel(MODEL_TYPE.extraServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setParentBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductDepotEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const productId = action.data.id;
        const parentId = restaurant?.parentMenu.categories.find(cat => cat.products.find(ex => ex.id === productId)).id


        const businessId = restaurant.businessMenu.template_business_id

        const requestResult = yield call(
          businessMenuCategoryProductEdit,
          businessId,
          parentId,
          productId,
          getInitModel(MODEL_TYPE.categoryProductServerModel, action.data)
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setParentBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasOptionDepotEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const optionId = action.data.id
        const category = restaurant?.parentMenu.categories.find(cat => cat.extras.find(ex => ex.options.find(op => op.id === optionId)))
        const extraId = category.extras.find(ex => ex.options.find(op => op.id === optionId)).id
        const businessId = restaurant.businessMenu.template_business_id
        const categoryId = category.id

        const requestResult = yield call(
          businessMenuCategoryExtrasOptionUpdate,
          businessId,
          categoryId,
          extraId,
          optionId,
          getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
        )
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setParentBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasSuboptionEditDepotSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const suboptionId = action.data.id
        const category = restaurant?.parentMenu.categories.find(cat => cat.extras.find(ex => ex.options.find(op => op.suboptions.find(subop => subop.id === suboptionId))))
        const extra = category.extras.find(ex => ex.options.find(op => op.suboptions.find(subop => subop.id === suboptionId)))
        const option = extra.options.find(op => op.suboptions.find(subop => subop.id === suboptionId))
        const businessId = restaurant.businessMenu.template_business_id
        const categoryId = category.id
        const extraId = extra.id
        const optionId = option.id

        const requestResult = yield call(
            businessMenuCategoryExtrasSuboptionUpdate,
            businessId,
            categoryId,
            extraId,
            optionId,
            suboptionId,
            getInitModel(MODEL_TYPE.extraSuboptionServerModel, action.data)
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setParentBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryExtrasSuboptionAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const optionId = sectionModels[currentModelType].id || params.subToId;
        const extraId = params.subId;
        const categoryId = sectionModels[currentModelType].model.parentUrl
            ? sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id
            : params.id;

        const requestResult = yield call(
            businessMenuCategoryExtrasSuboptionAdd,
            restaurant.businessMenu.id,
            categoryId,
            extraId,
            optionId,
            getInitModel(MODEL_TYPE.extraSuboptionServerModel, action.data)
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductIngredientEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const productId = action.data.id;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id;

        const requestResult = yield call(
            businessMenuCategoryProductEdit,
            restaurant.businessMenu.id,
            categoryId,
            productId,
            getInitModel(MODEL_TYPE.categoryProductServerModel, {
                ...action.data,
                ingredients: action.data.ingredients.map(ingredient => ({
                    ...ingredient,
                    id: ingredient.id.includes('NewValue') ? '' : ingredient.id,
                })),
            })
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductIngredientReorderSaga(action) {
    yield* createSaga(function*() {
        const params = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const categoryId = sectionModels[sectionModels[currentModelType].model.parentUrl].id || params.id;

        const requestResult = yield call(
            businessMenuCategoryProductIngredientReorder,
            restaurant.businessMenu.id,
            categoryId,
            action.data.item.id,
            action.data.items.map(item => ({ Id: item.id, Order: item.order }))
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductIngredientUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { item, value } = action.data;
        const { id } = getHistoryLocationParams(window.location.search);
        const productId = item.id;
        const category = restaurant.businessMenu.categories.find(f => f.id === id);
        const product = category.products.find(f => f.id === productId);
        let requestResult = null;

        if (value.id.includes('NewValue')) {
            requestResult = yield call(
                businessMenuCategoryProductIngredientAdd,
                restaurant.businessMenu.id,
                id,
                productId,
                { Name: value.name, Order: product.ingredients.length }
            );
        } else {
            requestResult = yield call(
                businessMenuCategoryProductIngredientUpdate,
                restaurant.businessMenu.id,
                id,
                productId,
                value.id,
                { Name: value.name, Order: value.order || 0 }
            );
        }

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuCategoryProductIngredientDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { id, subId } = getHistoryLocationParams(window.location.search);

        const requestResult = yield call(
            businessMenuCategoryProductIngredientDelete,
            restaurant.businessMenu.id,
            id,
            subId,
            action.data.id
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotDefaultRegionControlCenterEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const depot = restaurant.depots.find(f => f.id === parentId);

        const requestResult = yield call(
            id ? depotDefaultRegionControlCenterUpdate : depotDefaultRegionControlCenterAdd,
            parentId,
            objectKeysToUpperLowerCase(action.data, true)
        );
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(
                    actionDepotEdit({
                        id: parentId,
                        depotControlCenter: mergeArrays(depot.depotControlCenter, [
                            objectKeysToUpperLowerCase(requestResult.data),
                        ]),
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotDefaultRegionControlCenterDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { id } = getHistoryLocationParams(window.location.search);
        const depot = restaurant.depots.find(f => f.id === id);

        const requestResult = yield call(depotDefaultRegionControlCenterDelete, id, action.data.id);
        if (requestResult && requestResult.status === 200) {
            yield put(
                actionDepotEdit({
                    id,
                    depotControlCenter: (depot.depotControlCenter || []).filter(f => f.id !== action.data.id),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* businessMenuEditGallerySaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const requestResult = yield call(businessGalleryEdit, restaurant.businessMenu.id, action.data.id, action.data);

        if (requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));

            if (action.data.file && (isBase64Image(action.data.file) || isBase64Video(action.data.file))) {
                const requestResultWithBanner = yield call(
                    setBusinessGalleryImageBase64,
                    restaurant.businessMenu.id,
                    action.data.id,
                    dataURItoBlob(action.data.file),
                    isBase64Video(action.data.file)
                );

                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(setBusinessMenu(requestResultWithBanner.data));
                }
            }
        }
    }, action.type);
}

function* businessMenuAddGallerySaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const requestResult = yield call(businessGalleryAdd, restaurant.businessMenu.id, action.data);

        if (requestResult && requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));

            const oldIds = restaurant.businessMenu.gallery && restaurant.businessMenu.gallery.map(el => el.id);
            const newId = requestResult.data.gallery.filter(el => !oldIds.includes(el.id));

            if (action.data.file && isBase64Image(action.data.file)) {
                const requestResultWithBanner = yield call(
                    setBusinessGalleryImageBase64,
                    restaurant.businessMenu.id,
                    newId && newId[0].id,
                    dataURItoBlob(action.data.file)
                );

                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(setBusinessMenu(requestResultWithBanner.data));
                }
            }

            if (action.data.file && isBase64Video(action.data.file)) {
                const requestResultWithBanner = yield call(
                    setBusinessGalleryImageBase64,
                    restaurant.businessMenu.id,
                    newId && newId[0].id,
                    dataURItoBlob(action.data.file),
                    true
                );

                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(setBusinessMenu(requestResultWithBanner.data));
                }
            }
        }
    }, action.type);
}

function* businessMenuDeleteGallerySaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const requestResult = yield call(businessGalleryDelete, restaurant.businessMenu.id, action.data.id);

        if (requestResult && requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));
        }
    }, action.type);
}

function* businessMenuReorderGallerySaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(businessGalleryReorder, restaurant.businessMenu.id, data);

        if (requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));
        }
    }, action.type);
}

function* depotDefaultRegionControlCenterGetSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id.replace('?id=', '');
        const requestResult = yield call(depotDefaultRegionControlCenterGet, id);

        if (requestResult && requestResult.status === 200) {
            yield put(
                actionDepotEdit({ id, depotControlCenter: objectKeysToUpperLowerCase(requestResult.data || []) })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getSettingsOffersSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getBusinessOffer);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(actionRestaurantUpdate({ offers: objectKeysToUpperLowerCase(requestResult.data) || [] }));
            yield put(createAction(MENU_TEMPLATE_GET));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getSettingsOffersEditSaga(action) {
    yield* createSaga(function*() {
        const { start, end, limit, minimum, enable_schedule, schedule } = action.data;
        const d1 = start && new Date(start);
        const d2 = end && new Date(end);

        if (isSameObjects(d1, d2)) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_DATE_ERROR'));
        }

        if (limit && minimum && limit <= minimum) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_LIMIT_ERROR'));
        }

        const hasItemLapses = schedule?.filter(item => item?.lapses?.length)?.length
        if(enable_schedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }

        const requestResult = yield call(updateBusinessOffer, action.data);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    offers: restaurant.offers.map(offer =>
                        offer.id === action.data.id
                            ? objectKeysToUpperLowerCase(requestResult.data) || action.data
                            : offer
                    ),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getSettingsOffersAddSaga(action) {
    yield* createSaga(function*() {
        const { start, end, limit, minimum, schedule, enable_schedule } = action.data;
        const d1 = start && new Date(start);
        const d2 = end && new Date(end);

        if (isSameObjects(d1, d2)) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_DATE_ERROR'));
        }

        if (limit && minimum && limit <= minimum) {
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_LIMIT_ERROR'));
        }

        const hasItemLapses = schedule?.filter(item => item?.lapses?.length)?.length
        if(enable_schedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }

        const requestResult = yield call(addBusinessOffer, action.data);
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    offers: restaurant.offers.concat(objectKeysToUpperLowerCase(requestResult.data)),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getSettingsOffersDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteBusinessOffer, action.data.id);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    offers: restaurant.offers.filter(offer => offer.id !== action.data.id),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* setActivateModelSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(setActivateModel, action.data.businessId, action.data.flags);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            const depot = restaurant.depots.find(f => f.businessId === action.data.businessId);
            yield put(actionDepotEdit({ ...depot, businessMenu: { ...depot.businessMenu, ...requestResult.data } }));
            yield put(actionRestaurantUpdate({ consumerOrderMenu: requestResult.data }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* searchShortcutAddSettingsSaga(action) {
    yield* createSaga(function*() {
        if (action.data.name) {
            const requestResult = yield call(addSearchShortcut, action.data.name);
            if (requestResult && requestResult.status === 200) {
                const restaurant = yield select(getRestaurant);
                yield put(
                    actionRestaurantUpdate({
                        configuration: {
                            ...restaurant.configuration,
                            searchShortcuts: (restaurant.configuration.searchShortcuts || []).concat([
                                action.data.name,
                            ]),
                        },
                    })
                );
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData('Empty');
        }
    }, action.type);
}

function* searchShortcutDeleteSettingsSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const shortcut = (restaurant.configuration.searchShortcuts || [])[action.data.id];
        const requestResult = yield call(deleteSearchShortcut, shortcut);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    configuration: {
                        ...restaurant.configuration,
                        searchShortcuts: (restaurant.configuration.searchShortcuts || []).filter(
                            searchShortcut => searchShortcut !== shortcut
                        ),
                    },
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotServicesRefreshSaga(action) {
    yield* createSaga(function*() {
        const data = objectKeysToUpperLowerCase(action.data);

        if (data && Array.isArray(data)) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    depots: data,
                })
            );
        }
    }, action.type);
}

function* giftCardGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(giftCardGet);

        if (requestResult && requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);

            if (data) {
                let giftCard = { ...data };

                if (giftCard.businessId) {
                    const requestResultBusinessMenu = yield call(getBusinessMenu, giftCard.businessId);
                    if (requestResultBusinessMenu && requestResultBusinessMenu.status === 200) {
                        const businessMenu = objectKeysToUpperLowerCase(requestResultBusinessMenu.data) || {};
                        giftCard.businessMenu = businessMenu;
                    }
                }

                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, giftCard }));
            } else {
                return new ErrorData('Empty data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* giftCardProductAddSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const category = ((giftCard.businessMenu || {}).categories || [])[0];

        if (giftCard.businessId && category) {
            const requestResult = yield call(
                businessMenuCategoryProductAdd,
                giftCard.businessId,
                category.id,
                getInitModel(MODEL_TYPE.categoryProductServerModel, action.data)
            );

            if (requestResultIsOk(requestResult)) {
                const data = objectKeysToUpperLowerCase(requestResult.data);
                const oldGiftCardProducts = giftCard.businessMenu.categories[0].products
                const newGiftCardProducts = data.categories[0].products
                const newProduct = newGiftCardProducts.find(product => !oldGiftCardProducts.some(el => el.id === product.id))
                if (data) {
                    yield put(
                        actionRestaurantUpdate({
                            ...restaurant,
                            giftCard: { ...giftCard, businessMenu: data },
                        })
                    );
                    if (isBase64Image(action.data.images) || isBase64Video(action.data.images)) {
                        yield put(
                            actionSetGiftCardProductAvatar({ productId: newProduct.id, image: action.data.images })
                        );
                    }
                } else {
                    return new ErrorData('No data');
                }
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData('Error');
        }
    }, action.type);
}

function* giftCardProductEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const category = ((giftCard.businessMenu || {}).categories || [])[0];

        if (giftCard.businessId && category && action.data.id) {
            const requestResult = yield call(
                businessMenuCategoryProductEdit,
                giftCard.businessId,
                category.id,
                action.data.id,
                getInitModel(MODEL_TYPE.categoryProductServerModel, action.data)
            );

            if (requestResult && requestResult.status === 200) {
                const data = objectKeysToUpperLowerCase(requestResult.data);

                if (data) {
                    yield put(
                        actionRestaurantUpdate({
                            ...restaurant,
                            giftCard: { ...giftCard, businessMenu: data },
                        })
                    );

                    if (isBase64Image(action.data.images) || isBase64Video(action.data.image)) {
                        yield put(
                            actionSetGiftCardProductAvatar({ productId: action.data.id, image: action.data.images })
                        );
                    }
                } else {
                    return new ErrorData('No data');
                }
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData('Error');
        }
    }, action.type);
}

function* giftCardEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const data = getInitModel(MODEL_TYPE.businessInfoServerModel, action.data);

        if (giftCard.enabled !== action.data.enabled) {
            const requestResultCiftCardEnable = yield call(giftCardEnable, action.data.enabled);
            if (requestResultCiftCardEnable && requestResultCiftCardEnable.status === 200) {
                const data = objectKeysToUpperLowerCase(requestResultCiftCardEnable.data);
                if (data) {
                    yield put(actionRestaurantUpdate({ ...restaurant, giftCard: { ...giftCard, ...data } }));
                }
            }
        }

        const requestResult = yield call(updateBusinessMenuInfo, giftCard.businessId, data);
        if (requestResult && requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        giftCard: { ...giftCard, businessMenu: data },
                    })
                );

                if (giftCard.businessMenu.header !== action.data.header && (isBase64Image(action.data.header) || isBase64Video(action.data.header))) {
                    yield put(actionSetGiftCardBusinessMenuAvatar({ image: action.data.header }));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* giftCardProductDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const category = ((giftCard.businessMenu || {}).categories || [])[0];
        const productId = action.data.id;

        const requestResult = yield call(
            businessMenuCategoryProductDelete,
            giftCard.businessId,
            category.id,
            productId
        );
        if (requestResult && requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        giftCard: { ...giftCard, businessMenu: data },
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* giftCardProductUpdateSaga(action) {
    yield* createSaga(function*() {
        const { item, value } = action.data;
        const restaurant = yield select(getRestaurant);
        const businessId = item.id;
        const categoryId = restaurant.giftCard.businessMenu.categories[0].id;
        const productId = value.id;
        const requestResult = yield call(businessMenuCategoryProductActivate, businessId, categoryId, productId, {
            enabled: value.enabled,
        });

        if (requestResult && requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        giftCard: { ...restaurant.giftCard, businessMenu: data },
                    })
                );
            } else {
                return new ErrorData('No data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* giftCardProductDragUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const category = ((giftCard.businessMenu || {}).categories || [])[0];
        const data = action.data.items.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(businessMenuCategoryProductsReorder, giftCard.businessId, category.id, data);

        if (requestResult && requestResult.status === 200) {
            const businessMenu = objectKeysToUpperLowerCase(requestResult.data);
            if (businessMenu) {
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        giftCard: { ...restaurant.giftCard, businessMenu },
                    })
                );
            } else {
                return new ErrorData('No data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* giftCardProductUpdateAvatarSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const category = ((giftCard.businessMenu || {}).categories || [])[0];
        const { productId, image } = action.data;

        if (giftCard.businessMenu && giftCard.businessMenu.id) {
            const requestResult = yield call(
                updateBusinessMenuCategoryProductAvatar,
                giftCard.businessMenu.id,
                category.id,
                productId,
                dataURItoBlob(image),
                isBase64Video(image)
            );

            if (requestResult && requestResult.status === 200 && requestResult.data) {
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        giftCard: { ...giftCard, businessMenu: objectKeysToUpperLowerCase(requestResult.data) },
                    })
                );
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* setGiftCardBusinessMenuHeaderSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const giftCard = restaurant.giftCard || {};
        const { image } = action.data;

        const requestResult = yield call(
            updateBusinessMenuHeaderAvatar,
            giftCard.businessId,
            dataURItoBlob(image),
            isBase64Video(image)
        );
        if (requestResult && requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            yield put(actionRestaurantUpdate({ ...restaurant, giftCard: { ...giftCard, businessMenu: data } }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getReferralSettingsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getReferralSettings);

        if (requestResult.status === 200) {
            const referral = objectKeysToUpperLowerCase(requestResult.data);

            if (referral) {
                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, referral }));
            } else {
                return new ErrorData('Empty data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getLoyaltySettingsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getLoyaltySettings);

        if (requestResult.status === 200) {
            const loyaltySettings = objectKeysToUpperLowerCase(requestResult.data);

            if (loyaltySettings) {
                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, loyaltySettings }));
            } else {
                return new ErrorData('Empty data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editLoyaltySettingsRegisterVoucherSaga(action) {
    yield* createSaga(function*() {
        const {restaurant, loyaltySettings} = yield select(getRestaurant);
        const voucher = action.data.bonus_info
            ? getInitModel(MODEL_TYPE.loyaltyRegisterVoucherServerModel, action.data.bonus_info)
            : getInitModel(MODEL_TYPE.loyaltyRegisterVoucherServerModel, {...getInitModel(MODEL_TYPE.loyaltyRegisterVoucher, loyaltySettings), bonus: 0});
        const requestResult = yield call(editLoyaltySettingsRegisterVoucher, voucher);

        if (requestResult.status === 200) {
            const loyaltySettings = objectKeysToUpperLowerCase(requestResult.data);

            if (loyaltySettings) {
                yield put(actionRestaurantUpdate({ ...restaurant, loyaltySettings }));
            } else {
                return new ErrorData('Empty data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editLoyaltySettingsCashbackSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(editLoyaltySettingsCashback, {...action.data, spendBonusPercent: action.data.spendBonusPercent / 100});

        if (requestResult.status === 200) {
            const loyaltySettings = objectKeysToUpperLowerCase(requestResult.data);

            if (loyaltySettings) {
                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, loyaltySettings }));
            } else {
                return new ErrorData('Empty data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateReferralSettingsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateReferralSettings, action.data);
        if (requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ ...restaurant, referral: action.data }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getDriverTipSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getDriverTip);

        if (requestResult.status === 200) {
            const driverTip = objectKeysToUpperLowerCase(requestResult.data);

            if (driverTip) {
                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, driverTip }));
            } else {
                return new ErrorData('Empty data');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateDriverTipSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateDriverTip, action.data);
        if (requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ ...restaurant, driverTip: action.data }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotPriceSettingApplyToSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id.replace('?id=', '');
        let restaurant = yield select(getRestaurant);
        let currentDepot = restaurant.depots.find(f => f.id === id);

        if (currentDepot && currentDepot.price) {
            const depotsToApplyConfiguration = action.data.depots
                .filter(depot => depot.selected)
                .map(depot => ({
                    id: depot.id,
                    configuration: getInitModel(MODEL_TYPE.depotDeliveryPriceServerModel, currentDepot.price),
                }));

            if (depotsToApplyConfiguration.length) {
                const apiCalls = {};
                depotsToApplyConfiguration.forEach(depot => {
                    apiCalls[depot.id] = call(setDepotDeliveryPrice, depot.id, depot.configuration);
                });

                const apiCallResults = yield all(apiCalls);
                if (someObject(apiCallResults, result => result.status && result.status !== 200)) {
                    return new ErrorData('Error save depot price configuration');
                }
            }
        } else {
            return new ErrorData('Error');
        }
    }, action.type);
}

function* getCourierShipmentSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getShipmentPriceSettings);
        if (requestResult && requestResult.status === 200) {
            const shipmentPriceSettings = objectKeysToUpperLowerCase(requestResult.data);
            if (shipmentPriceSettings) {
                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, shipmentPriceSettings }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getListPromotionSettingsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getListPromotion);
        if (requestResultIsOk(requestResult)) {
            const listPromotion = objectKeysToUpperLowerCase(requestResult.data);
            if (listPromotion) {
                const restaurant = yield select(getRestaurant);
                yield put(actionGetMessagesData());
                yield put(actionGetBusinessCategories());
                yield put(actionRestaurantUpdate({ ...restaurant, listPromotion }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* courierShipmentEditSaga(action) {
    yield* createSaga(function*() {
        const data = getInitModel(MODEL_TYPE.courierDeliveryFeesServer, action.data);
        const requestResult = yield call(setShipmentPriceSettings, data);
        if (requestResult && requestResult.status === 200) {
            const requestResult = yield call(getShipmentPriceSettings);
            const shipmentPriceSettings = objectKeysToUpperLowerCase(requestResult.data);
            if (shipmentPriceSettings) {
                const restaurant = yield select(getRestaurant);
                yield put(actionRestaurantUpdate({ ...restaurant, shipmentPriceSettings }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deliveryZonesGetSaga(action) {
    yield* createSaga(function*() {
        yield put(actionGetOperationTiming());

        const requestResult = yield call(getRestaurantDeliveryZones);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            const deliveryZones = objectKeysToUpperLowerCase(requestResult.data) || [];
            yield put(actionRestaurantUpdate({ ...restaurant, deliveryZones }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* addListPromotionSettingsSaga(action) {
    yield* createSaga(function*() {
        const data = getInitModel(MODEL_TYPE.listPromotionServerModel, action.data);
        const requestResult = yield call(listPromotionAdd, data);

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                const restaurant = yield select(getRestaurant);
                yield put(
                    actionRestaurantUpdate({ ...restaurant, listPromotion: restaurant.listPromotion.concat(data) })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deliveryZonesAddSaga(action) {
    yield* createSaga(function*() {
        const data = getDeliveryZoneData(action.data);
        const requestResult = yield call(addRestaurantDeliveryZones, data);
        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data) || action.data;
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    deliveryZones: restaurant.deliveryZones.map(deliveryZone =>
                        deliveryZone.id === data.id ? data : deliveryZone
                    ),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editListPromotionSettingsSaga(action) {
    yield* createSaga(function*() {
        const data = getInitModel(MODEL_TYPE.listPromotionServerModel, action.data);
        const requestResult = yield call(listPromotionUpdate, action.data.id, data);

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                const restaurant = yield select(getRestaurant);
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        listPromotion: restaurant.listPromotion.map(m => (m.id === data.id ? data : m)),
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deliveryZonesEditSaga(action) {
    yield* createSaga(function*() {
        const data = getDeliveryZoneData(action.data);
        const requestResult = yield call(updateRestaurantDeliveryZones, data);
        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data) || action.data;
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    deliveryZones: restaurant.deliveryZones.map(deliveryZone =>
                        deliveryZone.id === data.id ? data : deliveryZone
                    ),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deleteListPromotionSettingsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(listPromotionDelete, action.data.id);
        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    listPromotion: restaurant.listPromotion.filter(f => f.id !== action.data.id),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deliveryZoneDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteRestaurantDeliveryZone, action.data.id);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    deliveryZones: restaurant.deliveryZones.filter(deliveryZone => deliveryZone.id !== action.data.id),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* dragListPromotionSettingsSaga(action) {
    yield* createSaga(function*() {
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(listPromotionReorder, data);

        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    ...restaurant,
                    listPromotion: action.data.data,
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getMarketplaceCampaign);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const restaurant = yield select(getRestaurant);
                const newCampaigns = requestResult.data.map(item => marketplaceCampaignClientModel(item))
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        campaigns: mergeArrays(restaurant?.campaigns, newCampaigns || [])
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsAudienceFilterCountGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getMarketplaceCampaignAudienceFilterCount, action.data);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                return requestResult.data
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsToggleEnabledSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(toggleActivateMarketplaceCampaign, { campaignId: action?.data?.id, data: action?.data});
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const restaurant = yield select(getRestaurant);
                yield put(
                  actionRestaurantUpdate({
                      ...restaurant,
                      campaigns: restaurant.campaigns.map(item => {
                          if(item.id === action.data?.id) {
                              return {
                                  ...item,
                                  enabled: requestResult.data?.enabled
                              }
                          } else {
                              return item
                          }
                      }),
                  })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* executeCompaingSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(executeCompaings, action?.data?.id);
        if (requestResultIsOk(requestResult)) {

        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsAddSaga(action) {
    yield* createSaga(function*() {
        const newCampaign = marketplaceCampaignServerModel(action.data)
        const requestResult = yield call(addMarketplaceCampaign, newCampaign)

        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const restaurant = yield select(getRestaurant);
                const newCampaign = marketplaceCampaignClientModel(requestResult.data)
                yield put(
                  actionRestaurantUpdate({
                      ...restaurant,
                      campaigns: mergeArrays(restaurant?.campaigns || [], [newCampaign]),
                  })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsDeleteSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteMarketplaceCampaign, action.data.id);

        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(
              actionRestaurantUpdate({
                  ...restaurant,
                  campaigns: restaurant.campaigns.filter(c => c.id !== action.data.id),
              })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}
function* serviceLoyaltyCampaignsEditSaga(action) {
    yield* createSaga(function*() {
        const campaign = marketplaceCampaignServerModel(action.data)
        const requestResult = yield call(editMarketplaceCampaign, { campaignId: campaign?.id, campaign })
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const restaurant = yield select(getRestaurant);
                const newCampaign = marketplaceCampaignClientModel(requestResult.data)
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        campaigns: restaurant.campaigns.map(item => {
                            if(item.id === campaign.id) {
                                return {
                                    ...campaign,
                                    ...newCampaign
                                }
                            } else {
                                return item
                            }
                        }),
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsExecutionsGetSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(getMarketplaceCampaignExecutions, id);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const restaurant = yield select(getRestaurant);
                let newCampaigns = []

                if(!restaurant?.campaigns) {
                    const requestResult = yield call(getMarketplaceCampaign);
                    if (requestResultIsOk(requestResult)) {
                        newCampaigns = requestResult.data.map(item => marketplaceCampaignClientModel(item))
                    }
                }

                yield put(
                  actionRestaurantUpdate({
                      ...restaurant,
                      campaigns: mergeArrays(restaurant?.campaigns, newCampaigns)?.map(item => {
                          if (item?.id === id) {
                              return {
                                  ...item,
                                  executions: requestResult.data || []
                              }
                          } else {
                              return item
                          }
                      })
                  })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsExecutionsUpdateByIdSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);
        const executionId = action?.data?.id
        const requestResult = yield call(updateMarketplaceCampaignExecutionById, {campaignId: id, executionId });
        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            // find campaign
            let newCampaign = restaurant.campaigns?.find(campaign => campaign?.id === id)

            // save new execution
            newCampaign = {
                ...newCampaign,
                executions: newCampaign?.executions?.map(execution => execution?.id === executionId ? requestResult?.data : execution)
            }
            yield put(
              actionRestaurantUpdate({
                  ...restaurant,
                  campaigns: mergeArrays(restaurant.campaigns, [newCampaign])
              })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceLoyaltyCampaignsExecutionsCreateSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);
        const { selectedItems, item } = action?.data || {}
        const executionsModel = marketplaceCampaignExecutionsModel({ ...item, audience: selectedItems })

        const requestResult = yield call(createMarketplaceCampaignExecutions, { campaignId: id, data: executionsModel });

        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            // find campaign
            let newCampaign = restaurant.campaigns?.find(campaign => campaign?.id === id)

            // save new execution
            newCampaign = {
                ...newCampaign,
                executions: mergeArrays(newCampaign?.executions || [], [requestResult?.data])
            }
            yield put(
              actionRestaurantUpdate({
                  ...restaurant,
                  campaigns: mergeArrays(restaurant.campaigns, [newCampaign])
              })
            );
            return requestResult?.data || []
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceFeeGetSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getServiceFee);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const restaurant = yield select(getRestaurant);
                yield put(
                    actionRestaurantUpdate({
                        ...restaurant,
                        serviceFee: objectKeysToUpperLowerCase(requestResult.data),
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* serviceFeeEditSaga(action) {
    yield* createSaga(function*() {
        const data = getInitModel(MODEL_TYPE.serviceFeeServerModel, action.data)
        const requestResult = yield call(setServiceFee, data);
        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(
              actionRestaurantUpdate({
                  ...restaurant,
                  serviceFee: objectKeysToUpperLowerCase(data),
              })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deliveryZonesUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessId = restaurant.businessMenu.id;
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id;
        const { isMarkerplace, enabled, isGlobalZone, zoneIdReference, ...body } = action.data;

        let requestResult;

        if (id && isGlobalZone) {
            if (!action.data.id) {
                requestResult = yield call(setDeliveryZone, businessId, body);
            } else {
                requestResult = yield call(deleteDeliveryZone, businessId, action.data.id);
            }
        } else if (id && isMarkerplace && !isGlobalZone) {
            requestResult = yield call(updateOrderingMenuDeliveryZone, id, action.data.id, { enabled });
        }

        if (requestResult && requestResult.status === 200) {
            const businessMenu = objectKeysToUpperLowerCase(requestResult.data);
            if (businessMenu) {
                yield put(setBusinessMenu(businessMenu));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deliveryZonesUpdateEnableSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const businessId = restaurant.businessMenu.id;
        const { enabled, id } = action.data;
        const requestResult = yield call(updateEnableDeliveryZone, businessId, id, enabled);

        if (requestResult && requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* marketDeliveryZonesUpdateSaga(action) {
    yield* createSaga(function*() {
        const { id, enabled } = action.data;
        const requestResult = yield call(restaurantDeliveryZoneActivate, id, { enabled });
        if (requestResultIsOk(requestResult)) {
            yield put(
                actionUpdateRestaurantDeliveryZone(objectKeysToUpperLowerCase(requestResult.data) || action.data)
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotIntegrationsGetSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { parentId } = getHistoryLocationParams(window.location.search);
        const id = (sectionModels[currentModelType].id || parentId).replace('?id=', '');

        if (id) {
            const requestResult = yield call(getDepotIntegrations, id);
            if (requestResultIsOk(requestResult)) {
                yield put(actionDepotEdit({ id, integrations: objectKeysToUpperLowerCase(requestResult.data) }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* depotIntegrationUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const { parentId } = getHistoryLocationParams(window.location.search);
        const id = (sectionModels[currentModelType].id || parentId).replace('?id=', '');

        if (id) {
            const body = {
                SourceId: action.data.sourceId,
                Enabled: action.data.enabled,
            }

            const requestResult = yield call(updateDepotIntegration, id, body);
            if (requestResultIsOk(requestResult)) {
                const integrations = (restaurant.depots.find(d => d.id === id) || {}).integrations || [];
                const integration = integrations.find(inegr => inegr.sourceId === action.data.sourceId);
                integration.enabled = action.data.enabled;
                yield put(actionDepotEdit({ id, integrations: integrations }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* depotIntegrationsAddSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id.replace('?id=', '');
        const { integrationType } = action.data;

        if (integrationType) {
            if (integrationType === INTEGRATION_TYPE.squareUp) {
                yield depotSquareAssociatedLocationCheckSaga({ data: { id } });
            } else {
                const requestResult = yield call(addDepotIntegration, id, action.data);

                if (requestResult.status === 200 && requestResult.data) {
                    yield put(actionDepotEdit({ id, integrations: objectKeysToUpperLowerCase(requestResult.data) }));
                }
            }
        }
    }, action.type);
}

function* depotIntegrationsDeleteSaga(action) {
    yield* createSaga(function*() {
        const { id } = getHistoryLocationParams(window.location.search);
        const locationId = action.data.id;
        const restaurant = yield select(getRestaurant);
        const depot = restaurant.depots.find(f => f.id === id);
        const integrationLocation = ((depot && depot.integrations) || []).find(f => f.sourceId === locationId) || {};

        if (id && locationId && integrationLocation.integrationType) {
            if (integrationLocation.integrationType === INTEGRATION_TYPE.squareUp) {
                const requestResult = yield call(removeDepotSquareLocation, id, locationId);
                if (requestResultIsOk(requestResult)) {
                    yield put(
                        actionDepotEdit({
                            ...depot,
                            integrations: depot.integrations.filter(f => f.sourceId !== locationId),
                        })
                    );
                }
            }
        }
    }, action.type);
}

function* getMenuTemplateSaga(action) {
    yield* createSaga(function*() {
        const apiCalls = {
      templates: call(getMenuTemplate),
        globalTemplates: call(getGlobalMenuTemplate),
    };
    const apiCallResults = yield all(apiCalls);

    if(requestResultIsOk(apiCallResults.templates)) {
            yield put(setMenuTemplatesAC(objectKeysToUpperLowerCase(apiCallResults.templates.data)));
        }
            if(requestResultIsOk(apiCallResults.globalTemplates)) {
      yield put(setGlobalMenuTemplates(objectKeysToUpperLowerCase(apiCallResults.globalTemplates.data)));
        }
    }, action.type);
}

function* deleteMenuTemplateSaga(action) {
    yield* createSaga(function*() {
        const { item } = action.data || {};
        const id = (item || {}).id;

        if(id) {
            const requestResult = yield call(deleteMenuTemplate, id);
            if (requestResultIsOk(requestResult)) {
                yield put(deleteMenuTemplateAC(id));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData("Error");
        }
    }, action.type);
}
function* addMenuTemplateSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(addMenuTemplate, action.data);
        if (requestResult && requestResult.status === 200) {
            yield put(addMenuTemplateAC(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}
function* updateMenuTemplateSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateMenuTemplate, action.data.id, action.data);
        if (requestResult && requestResult.status === 200) {
            yield put(updateMenuTemplateAC(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getBusinessParentMenuSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        let requestResult = null;
        let requestBusinessMenu = null;
        const { id } = getHistoryLocationParams(window.location.search);

        // fix when opened tabs is parentMenu and without businessMenu
        if (restaurant && !restaurant.businessMenu) {
            requestBusinessMenu = yield call(getBusinessMenu, id, true);
        }
        if (requestBusinessMenu && requestBusinessMenu.status === 200) {
            yield put(setBusinessMenu(requestBusinessMenu.data));
        }

        const businessTemplateId =
            (restaurant && restaurant.businessMenu && restaurant.businessMenu.template_business_id) ||
            (requestBusinessMenu && requestBusinessMenu.data && requestBusinessMenu.data.template_business_id);

        if (businessTemplateId) {
            requestResult = yield call(getBusinessMenu, businessTemplateId, true);
        }

        if (requestResult && requestResult.status === 200) {
            yield put(setParentBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* overrideMenuSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const overrides = (restaurant.businessMenu && restaurant.businessMenu.overrides) || [];
        const hasOverride = overrides.find(f => f.object_id === action.data.id);
        let requestResult = null;

        const data = {
            enabled: action.data.enabled,
        };

        if (!hasOverride) {
            requestResult = yield call(createOverrideMenu, restaurant.businessMenu.id, action.data.id, data);
        }

        if (hasOverride) {
            requestResult = yield call(updateOverrideMenu, restaurant.businessMenu.id, action.data.id, data);
        }

        if (requestResult && requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getOrderingMenuExtraSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);

        if (restaurant.businessMenu && restaurant.businessMenu.id) {
            const requestResult = yield call(getBusinessMenu, restaurant.businessMenu.id);

            if (requestResult && requestResult.status === 200 && requestResult.data) {
                const businessMenu = objectKeysToUpperLowerCase(requestResult.data);
                if(businessMenu?.logo) {
                    const requestResultAvatar = yield call(fetchMarkerAvatarBase64, businessMenu.logo, true);

                    if(requestResultIsOk(requestResultAvatar)) {
                        yield put(setBusinessMenu({ ...businessMenu, avatar: requestResultAvatar.data }));
                    }
                }
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* depotSquareAssociatedLocationCheckSaga(action) {
    yield* createSaga(function*() {
        const { id, isSecondTime } = action.data || {};
        const depotId = id || getDepotModeId();
        const requestResult = yield call(getDepotSquareLocations, depotId);

        if (requestResultIsOk(requestResult)) {
            yield put(actionGlobalPortalDataUpdate({ globalIFrameUrl: null }));
            yield put(
                actionDepotEdit({
                    id: depotId,
                    squareAssociatedLocations: objectKeysToUpperLowerCase(requestResult.data),
                })
            );
            Store.history.push(TYPE_SECTION_MODEL.depotSquareAssociatedLocation + '?id=' + depotId);
        } else {
            if (checkRequestResultStatus(requestResult, 401) && !isSecondTime) {
                const res = yield call(generateDepotAuthorizationUrl, depotId);
                if (requestResultIsOk(res, true)) {
                    if (res.data.Url) {
                        yield put(
                            actionGlobalPortalDataUpdate({
                                globalIFrameUrl: {
                                    url: res.data.Url,
                                    title: 'settings.square.AUTHORIZE_LABEL',
                                    action: DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK,
                                    actionData: { id },
                                },
                            })
                        );
                    } else {
                        return new ErrorData('No url');
                    }
                } else {
                    return new ErrorData(getErrorMessage(res));
                }
            }
        }
    }, action.type);
}

function* orderingMenuExtraEditSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;
        const id = sectionModels[currentModelType].id;
        const restaurant = yield select(getRestaurant);
        const businessIdText = sectionModels[currentModelType].model.businessId;
        const businessId = restaurant.businessMenu && restaurant.businessMenu.id;
        const data = {
            name: action.data.name,
            description: action.data.description,
            enabled: action.data.enabled,
        };
        let requestResult = yield call(
            id === businessIdText ? businessCategoryExtraAdd : businessCategoryExtraEdit,
            data,
            businessId,
            id
        );

        yield put(setBusinessMenu(requestResult.data));
    }, action.type);
}

function* orderingMenuExtraDeleteSaga(action) {
    yield* createSaga(function*() {
        if (action.data.id) {
            const restaurant = yield select(getRestaurant);
            const businessId = restaurant.businessMenu && restaurant.businessMenu.id;
            const requestResult = yield call(businessCategoryExtraRemove, action.data.id, businessId);

            if (requestResult && requestResult.status === 200) {
                yield put(setBusinessMenu(requestResult.data));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* orderingMenuExtraUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const id = action.data.id;
        const businessId = restaurant.businessMenu && restaurant.businessMenu.id;
        const requestResult = yield call(businessCategoryExtraUpdate, businessId, id, { Enabled: action.data.enabled });

        if (requestResult && requestResult.status === 200) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: id, businessId }));
            yield put(setBusinessMenu(requestResult.data));
        }
    }, action.type);
}

function* orderingMenuExtrasOptionEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const extraId =
            sectionModels[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit].id || params.subId;

        const requestResult =
            action.data.id || params.subToId
                ? yield call(
                      businessCategoryExtraOptionUpdate,
                      restaurant.businessMenu.id,
                      extraId,
                      action.data.id || params.subToId,
                      getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
                  )
                : yield call(
                      businessCategoryExtraOptionAdd,
                      restaurant.businessMenu.id,
                      extraId,
                      getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
                  );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasOptionUpdateSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const { item, value } = action.data;
        const extraId = item.id;
        const optionId = value.id;

        const requestResult = yield call(
            businessCategoryExtraOptionActivate,
            restaurant.businessMenu.id,
            extraId,
            optionId,
            { enabled: value.enabled }
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: optionId, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasOptionDeleteSaga(action) {
    yield* createSaga(function*() {
        const params = getHistoryLocationParams(window.location.search);
        const restaurant = yield select(getRestaurant);
        const extraId = params.subId;
        const optionId = action.data.id;
        const requestResult = yield call(
            businessCategoryExtraOptionDelete,
            restaurant.businessMenu.id,
            extraId,
            optionId
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasSubOptionEditSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const extraId =
            sectionModels[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit].id || params.subId;

        const requestResult = action.data.id
            ? yield call(
                  businessCategoryExtraSubOptionEdit,
                  restaurant.businessMenu.id,
                  extraId,
                  params.subToId,
                  action.data.id,
                  getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
              )
            : yield call(
                  businessCategoryExtraSubOptionAdd,
                  restaurant.businessMenu.id,
                  extraId,
                  params.subToId,
                  getInitModel(MODEL_TYPE.productOptionServerModel, action.data)
              );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasSubOptionDeleteSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const extraId =
            sectionModels[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit].id || params.subId;
        const requestResult = yield call(
            businessCategoryExtraSubOptionDelete,
            restaurant.businessMenu.id,
            extraId,
            params.subToId,
            action.data.id
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasSubOptionUpdateSaga(action) {
    yield* createSaga(function*() {
        const { value } = action.data;
        const restaurant = yield select(getRestaurant);
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const params = getHistoryLocationParams(window.location.search);
        const extraId =
            sectionModels[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit].id || params.subId;
        const requestResult = yield call(
            businessCategoryExtraSubOptionActivate,
            restaurant.businessMenu.id,
            extraId,
            params.subToId,
            value.id,
            { enabled: value.enabled }
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(createAction(MARKETPLACE_MENU_CLEAR_OVERRIDE, {objectId: value.id, businessId: restaurant.businessMenu.id}));
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasOptionReorderSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const params = getHistoryLocationParams(window.location.search);
        const extraId = params?.subId;

        const options = action.data?.item?.options || []
        const items = action.data?.items || []

        const isNewItems = options.some(option => items.find(item => item.id === option.id).order !== option.order)

        if(!extraId || !isNewItems) return
        yield put(actionSetSectionItemsLoading(true));

        const requestResult = yield call(
            businessCategoryExtraOptionReorder,
            restaurant.businessMenu.id,
            extraId,
            action.data.items.map(item => ({ id: item.id, order: item.order }))
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
            yield put(actionSetSectionItemsLoading(false));
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderingMenuExtrasSubOptionReorderSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const params = getHistoryLocationParams(window.location.search);
        const optionId = action.data.item.id;
        const extraId = params.subId;

        const requestResult = yield call(
            businessCategoryExtraSubOptionReorder,
            restaurant.businessMenu.id,
            extraId,
            optionId,
            action.data.items.map(item => ({ id: item.id, order: item.order }))
        );
        if (requestResult && requestResult.status === 200 && requestResult.data) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* setMenuCreateOrder(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        const depot = getDepot(restaurant && restaurant.depots, action.data.depotId);
        const requestResult = yield call(getCallCenterMenu, depot.businessId);

        if (requestResultIsOk(requestResult)) {
            yield put(setBusinessMenu(requestResult.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getMergegMenuByBusinessId(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getMergedBusinessMenuById, action.data);

        if (requestResult && requestResult.status === 200) {
            yield put(setBusinessMenu(requestResult.data));
        }
    }, action.type);
}

function* operationTimingGetSaga(action) {
    yield* createSaga(function*() {
        const restaurant = yield select(getRestaurant);
        if (!restaurant.operations) {
            const requestResult = yield call(fetchOperations);
            if (requestResultIsOk(requestResult, true)) {
                yield put(actionRestaurantUpdate({ operations: objectKeysToUpperLowerCase(requestResult.data) }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* operationTimingGetForDepotSaga(action) {
    yield* createSaga(function*() {
        yield all([
            put(createAction(OPERATION_TIMING_SETTINGS_GET)),
            put(createAction(DEPOT_OPERATION_MODE_SETTING_GET))
        ])
    }, action.type);
}

function* depotSquareAssociatedLocationEditSaga(action) {
    yield* createSaga(function*() {
        const { id, squareAssociatedLocations } = action.data;
        const selectedLocation = (squareAssociatedLocations || []).find(location => location.active);
        if (selectedLocation) {
            const requestResult = yield call(setDepotSquareAssociatedLocation, id, { LocationId: selectedLocation.id });
            if (requestResultIsOk(requestResult)) {
                yield put(actionDepotEdit(action.data));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* getDepotProvidersSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const depotId = parentId || id;
        const depots = yield select(getRestaurantDepots);
        const depot = depots.find(f => f.id === depotId) || {};

        const requestResult = yield call(fetchDeliveryProviders, depotId);
        if (requestResultIsOk(requestResult)) {
            const providers = objectKeysToUpperLowerCase(requestResult.data);

            if(id) {
                const regions = yield select(getConfigurationRegions);
                providersApiMap(providers, regions, id);
            }


            yield put(actionDepotEdit({ ...depot, providers: providers }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editDepotProvidersSaga(action) {
    yield* createSaga(function*() {
        const params = getHistoryLocationParams(window.location.search);
        const depotId = params.parentId || params.id;
        const serverData = objectKeysToUpperLowerCase(action.data, true);
        const regions = yield select(getConfigurationRegions);
        const selectedRegionsIds = [];

        if(regions && serverData && serverData.AutoAssignmentRegionIds) {
            regions.forEach((e) => {
                if(serverData.AutoAssignmentRegionIds.find((region) => region === e.name )) {
                    selectedRegionsIds.push(e.id);
                }

            });
            serverData.AutoAssignmentRegionIds = [...selectedRegionsIds];
        }
        const { IsDirty, RefreshedValue, ...newServerData } = serverData;

        if(!newServerData.EnableSchedule) {
            newServerData.AutoAssignmentSchedule = null;
        }

        /*const hasItemLapses = newServerData.AutoAssignmentSchedule?.filter(item => item?.Lapses?.length)?.length;

        if(newServerData.EnableSchedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }*/

        const requestResult = action.data.virtual
            ? yield call(updateVirtualDeliveryProvider, depotId, newServerData)
            : yield call(editDeliveryProvider, depotId, newServerData);

        if (requestResultIsOk(requestResult)) {
            const depots = yield select(getRestaurantDepots);
            const depot = depots.find(f => f.id === depotId) || {};
            const providers = mergeArrays(depot.providers || [], [
                objectKeysToUpperLowerCase(requestResult.data) || action.data,
            ]);

            if(depotId) {
                const regions = yield select(getConfigurationRegions);
                providersApiMap(providers, regions, depotId);
            }

            yield put(actionDepotEdit({ ...depot, providers }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* addDepotProviderSaga(action) {
    yield* createSaga(function*() {
        const { parentId, id } = getHistoryLocationParams(window.location.search);
        const depotId = parentId || id;

        const data = objectKeysToUpperLowerCase(action.data, true) ;
        if(depotId && data && data.AutoAssignmentRegionIds) {
            const regions = yield select(getConfigurationRegions);
            data.AutoAssignmentRegionIds = transformProvidersToApi(data, regions);

        }
        const { IsDirty, RefreshedValue, ...newData } = data;

        if(!newData.EnableSchedule) {
            newData.AutoAssignmentSchedule = null;
        }
/*
        const hasItemLapses = newData.AutoAssignmentSchedule?.filter(item => item?.Lapses?.length)?.length
        if(newData.EnableSchedule && !hasItemLapses){
            return new ErrorData(i18n.t('settings.orderingmenu.MENU_DISCOUNT_SCHEDULE_ERROR'));
        }*/

        const requestResult = yield call(
            createVirtualDeliveryProvider,
            depotId,
            newData
        );
        if (requestResultIsOk(requestResult)) {
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deleteDepotProviderSaga(action) {
    yield* createSaga(function*() {
        if (action.data.item.virtual) {
            const { parentId, id } = getHistoryLocationParams(window.location.search);
            const depotId = parentId || id;
            const requestResult = yield call(deleteVirtualDeliveryProvider, depotId, action.data.id);

            if (requestResultIsOk(requestResult)) {
                const depots = yield select(getRestaurantDepots);
                const depot = depots.find(f => f.id === depotId) || {};
                const providers = (depot.providers || []).filter(f => f.id !== action.data.id);
                yield put(actionDepotEdit({ ...depot, providers }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* depotSquareAssociatedRevokeSaga(action) {
    yield* createSaga(function*() {
        const { typeModel } = action.data;
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const depotId = sectionModels[typeModel].id;

        const requestResult = yield call(setDepotSquareAssociatedRevoke, depotId);

        if (requestResultIsOk(requestResult)) {
            const checkRequiredAuth = yield call(
                checkSquareUpAuthorizedRequired,
                currentUserIsDepotRole() ? null : depotId
            );

            if (requestResultIsOk(checkRequiredAuth)) {
                yield put(
                    actionDepotEdit({
                        id: depotId,
                        squareUpAuthorizationRequired: checkRequiredAuth.data.AuthorizationRequired,
                    })
                );
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* reorderDepotProvidersSaga(action) {
    yield* createSaga(function*() {
        const params = getHistoryLocationParams(window.location.search);
        const depotId = params.parentId || params.id;
        const depots = yield select(getRestaurantDepots);
        const depot = depots.find(f => f.id === depotId) || {};
        yield put(actionDepotEdit({ ...depot, providers: [] }));

        const data = action.data.data.map(m => ({ Id: m.id, Priority: m.priority, Provider: m.provider }));

        const requestResult = yield call(reorderDeliveryProviders, depotId, data);
        if (requestResultIsOk(requestResult)) {
            yield put(actionDepotEdit({ ...depot, providers: objectKeysToUpperLowerCase(requestResult.data) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotSquareAssociatedAuthorizationSaga(action) {
    yield* createSaga(function*() {
        const { typeModel } = action.data;
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const depotId = sectionModels[typeModel].id || getDepotModeId();

        const requestResult = yield call(generateDepotAuthorizationUrl, depotId);
        if (requestResultIsOk(requestResult, true)) {
            if (requestResult.data.Url) {
                yield put(
                    actionGlobalPortalDataUpdate({
                        globalIFrameUrl: {
                            url: requestResult.data.Url,
                            title: 'settings.square.AUTHORIZE_LABEL',
                            action: DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK,
                            actionData: { id: depotId },
                        },
                    })
                );
            } else {
                return new ErrorData('No url');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* depotSquareAssociatedAuthorizationShortSaga(action) {
    yield* createSaga(function*() {
        const { typeModel } = action.data;
        const sectionModel = yield select(getSectionModel);
        const { sectionModels } = sectionModel;
        const depotId = sectionModels[typeModel].id || getDepotModeId();

        const requestResult = yield call(generateDepotAuthorizationUrl, depotId);
        if (requestResultIsOk(requestResult, true)) {
            if (requestResult.data.Url) {
                yield put(
                    actionGlobalPortalDataUpdate({
                        globalIFrameUrl: {
                            url: requestResult.data.Url,
                            title: 'settings.square.AUTHORIZE_LABEL',
                            action: CHECK_SQUAREUP_AUTHORIZED_REQUIRED,
                            actionData: { id: depotId },
                        },
                    })
                );
            } else {
                return new ErrorData('No url');
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* resetDepotProvidersSaga(action) {
    yield* createSaga(function*() {
        yield put(setDepotProviders(null));
    }, action.type);
}

function* checkSquareUpAuthorizedRequiredSaga(action) {
    yield* createSaga(function*() {
        const sectionModel = yield select(getSectionModel);
        const { sectionModels, currentModelType } = sectionModel;
        const depotId = sectionModels[currentModelType].id;

        const requestResult = yield call(checkSquareUpAuthorizedRequired, currentUserIsDepotRole() ? null : depotId);

        if (requestResult && requestResult.status === 200) {
            yield put(
                actionDepotEdit({
                    id: depotId,
                    squareUpAuthorizationRequired: requestResult.data.AuthorizationRequired,
                })
            );
        }
    }, action.type);
}

function* getBusinessByQuerySaga(action) {
  yield* createSaga(function* () {
    const data = {
      minimal_data: action.data.minimalData,
      query: action.data.query,
      search_type: action.data.searchType,
      location: action.data.location || undefined,
    };
    const requestResult = yield call(getBusinessWithQuery, data);

    if(requestResult && requestResult.status === 200) {
      yield put(actionRestaurantUpdate({depotsByQuery: requestResult.data}));
    }

  }, action.type);
}

function* parentMenuOverrideOptionSaga(action) {
    yield* createSaga(function*(){
        const parentMenu = yield select(state => state.restaurant.data.parentMenu);
        const businessMenu = yield select(state => state.restaurant.data.businessMenu);
        const options = getAllOption(parentMenu);
        const find = options.find(u => u.id === action.data.id);
        const option = getInitModel(MODEL_TYPE.optionModelOverride, action.data);
        const oldOption = getInitModel(MODEL_TYPE.optionModelOverride, find);
        const updatedData = getOveridesArray(option, oldOption);

        if(!!updatedData.length) {
            const request = yield call(overrideParentBusinessMenu, businessMenu.id, find.id, updatedData);
            if(request && request.status === 200) {
                yield put(setBusinessMenu(objectKeysToUpperLowerCase(request.data)))
            }
        }
    }, action.type);
}

function* parentMenuOverrideExtraSaga(action) {
    yield* createSaga(function*(){
        const parentMenu = yield select(state => state.restaurant.data.parentMenu);
        const businessMenu = yield select(state => state.restaurant.data.businessMenu);
        const extras = getAllExtra(parentMenu);
        const find = extras.find(u => u.id === action.data.id);
        const extra = getInitModel(MODEL_TYPE.extraModelOverride, action.data);
        const oldExtra = getInitModel(MODEL_TYPE.extraModelOverride, find);
        const updatedData = getOveridesArray(extra, oldExtra);

        if(!!updatedData.length) {
            const request = yield call(overrideParentBusinessMenu, businessMenu.id, find.id, updatedData);
            if(request && request.status === 200) {
                yield put(setBusinessMenu(objectKeysToUpperLowerCase(request.data)))
            }
        }
    }, action.type);
}

function* parentMenuOverrideProductSaga(action) {
    yield* createSaga(function*(){
        const parentMenu = yield select(state => state.restaurant.data.parentMenu);
        const businessMenu = yield select(state => state.restaurant.data.businessMenu);
        const products = ((parentMenu || {}).categories || []).flatMap(c => c.products);
        const find = products.find(u => u.id === action.data.id);
        action.data.weight_range = fixedNumber(action.data.weight_range / 100, 2);
        const product = getInitModel(MODEL_TYPE.categoryProductModelForOverride, action.data);
        const oldProduct = getInitModel(MODEL_TYPE.categoryProductModelForOverride, find);
        product.weight_range = fixedNumber(product.weight_range / 100, 2);
        oldProduct.weight_range = fixedNumber(oldProduct.weight_range / 100, 2);
        const updatedData = getOveridesArray(product, oldProduct);

        if(!!updatedData.length) {
            const request = yield call(overrideParentBusinessMenu, businessMenu.id, find.id, updatedData);
            if(request && request.status === 200) {
                yield put(setBusinessMenu(objectKeysToUpperLowerCase(request.data)))
            }
        }
    }, action.type);
}

function* parentMenuOverrideCategorySaga(action) {
    yield* createSaga(function*(){
        const parentMenu = yield select(state => state.restaurant.data.parentMenu);
        const businessMenu = yield select(state => state.restaurant.data.businessMenu);
        const find = (parentMenu.categories || []).find(u => u.id === action.data.id);
        const category = getInitModel(MODEL_TYPE.categoryModelForOverride, action.data);
        const oldCategory = getInitModel(MODEL_TYPE.categoryModelForOverride, find);
        const updatedData = getOveridesArray(category, oldCategory);

        if(!!updatedData.length) {
            const request = yield call(overrideParentBusinessMenu, businessMenu.id, find.id, updatedData);
            if(request && request.status === 200) {
                yield put(setBusinessMenu(objectKeysToUpperLowerCase(request.data)))
            }
        }
    }, action.type);
}

function* parentMenuOverrideSuboptionSaga(action) {
    yield* createSaga(function*(){
        const parentMenu = yield select(state => state.restaurant.data.parentMenu);
        const businessMenu = yield select(state => state.restaurant.data.businessMenu);
        const suboptions = getAllSuboption(parentMenu);
        const find = suboptions.find(u => u.id === action.data.id);
        const suboption = getInitModel(MODEL_TYPE.suboptionsOverride, action.data);
        const oldSuboption = getInitModel(MODEL_TYPE.suboptionsOverride, find);
        const updatedData = getOveridesArray(suboption, oldSuboption);

        if(!!updatedData.length) {
            const request = yield call(overrideParentBusinessMenu, businessMenu.id, find.id, updatedData);
            if(request && request.status === 200) {
                yield put(setBusinessMenu(objectKeysToUpperLowerCase(request.data)))
            }
        }
    }, action.type);
}

function* addMarketplaceMessageGallerySaga(action) {
    yield* createSaga(function*() {
        const {parentId} = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(messageGalleryAdd, parentId, getInitModel(MODEL_TYPE.messageGalleryServerModel, action.data));
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateMarketplaceMessageGalleyAdd({data: requestResult.data, messageId: parentId}));

            if (action.data.file && (isBase64Image(action.data.file) || isBase64Video(action.data.file))) {
                const requestResultWithBanner = yield call(
                    messageGalleryImageBase64,
                    parentId,
                    requestResult.data.id,
                    dataURItoBlob(action.data.file),
                    isBase64Video(action.data.file)
                );

                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(actionUpdateMarketplaceMessageGalley({data: requestResultWithBanner.data, messageId: parentId}));
                }
            }
        }
    }, action.type);
}

function* editMarketplaceMessageGallerySaga(action) {
    yield* createSaga(function*() {
        const {parentId, id} = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(messageGalleryEdit, parentId, id, getInitModel(MODEL_TYPE.messageGalleryServerModel, action.data));
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateMarketplaceMessageGalley({messageId: parentId, data: requestResult.data}));

            if (action.data.file && (isBase64Image(action.data.file) || isBase64Video(action.data.file))) {
                const requestResultWithBanner = yield call(
                    messageGalleryImageBase64,
                    parentId,
                    id,
                    dataURItoBlob(action.data.file),
                    isBase64Video(action.data.file)
                );

                if (requestResultWithBanner && requestResultWithBanner.status === 200) {
                    yield put(actionUpdateMarketplaceMessageGalley({messageId: parentId, data: requestResultWithBanner.data}));
                }
            }
        }
    }, action.type);
}

function* reorderMarketplaceMessageGallerySaga(action) {
    yield* createSaga(function*() {
        const {id} = getHistoryLocationParams(window.location.search);
        const data = action.data.data.map(m => ({ id: m.id, order: m.order }));
        const requestResult = yield call(messageGalleryReorder, id, data);

        if (requestResultIsOk(requestResult)) {
            yield put(actionUpdateMarketplaceMessage(requestResult.data));
        }
    }, action.type);
}

function* deleteMarketplaceMessageGallerySaga(action) {
    yield* createSaga(function*() {
        const {id} = getHistoryLocationParams(window.location.search);
        const requestResult = yield call(messageGalleryDelete, id, action.data.id);

        if (requestResultIsOk(requestResult)) {
            const messages = yield select(state => state.marketplace.data.message || []);
            const message = messages.find(m => m.id === id);
            message.gallery = message.gallery.filter(g => g.id !== requestResult.data.id);
            yield put(actionUpdateMarketplaceMessage(message));
        }
    }, action.type);
}

function* marketplaceMenuClearOverrideSaga(action) {
    yield* createSaga(function*() {
        if(!window.location.href.includes('marketplace-settings')) {
            return;
        }
        yield put(
            actionGlobalPopupMessage({
                isShow: true,
                message: i18n.t('settings.orderingmenu.MARKETPLACE_MENU_CLEAR_OVERRIDE_TEXT'),
                onConfirm: () => {
                    marketplaceMenuClearOverride(action.data.businessId, action.data.objectId);
                    Store.store.dispatch(actionGlobalPopupMessageInit());
                },
            })
        )
    }, action.type);
}


export default function*() {
    yield takeEvery(LOAD_RESTAURANT, restaurantSaga);
    yield takeEvery(LOAD_DEPOT_AVATARS, fetchDepotAvatarsSaga);
    yield takeEvery(RESTAURANT_CONTROL_CENTER_STATUS_GET, controlCenterStatusSaga);
    yield takeEvery(RESTAURANT_SET_MODE, setModeSaga);
    yield takeEvery(DEPOT_INIT_DATA_GET, depotInitDataSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_GET, depotOperationModeGetSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_ADD, depotOperationModeAddSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_EDIT, depotOperationModeEditSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_DELETE, depotOperationModeDeleteSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_EDIT, changeDepotOperationModeActiveSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_RESET, changeDepotOperationModeActiveByDefaultSaga);
    yield takeEvery(DEPOT_SETTING_EDIT, depotEditSaga);
    yield takeEvery(DEPOT_SETTING_ADD, depotAddSaga);
    yield takeEvery(DEPOT_SETTING_DELETE, depotDeleteSaga);
    yield takeEvery(SET_DEPOT_AVATAR, setDepotAvatarSaga);
    yield takeEvery(DEPOTUSERS_SETTING_EDIT, depotUserEditSaga);
    yield takeEvery(DEPOTUSERS_SETTING_ADD, depotUserAddSaga);
    yield takeEvery(DEPOTUSERS_SETTING_DELETE, depotUserRemoveSaga);
    yield takeEvery(GET_DEPOT_USERS, getDepotUsersSaga);
    yield takeEvery(GET_DEPOT_PRICE, getDepotPriceSaga);
    yield takeEvery(SHIFTS_GET, shiftsSaga);
    yield takeEvery(DEPOTUSERS_SETTING_PASSWORD_EDIT, saveDepotUserPasswordSaga);
    yield takeEvery(DEPOTPRICE_SETTING_EDIT, editDepotPriceSaga);
    yield takeEvery(DEPOTMENU_SETTING_EDIT, editDepotMenuSaga);
    yield takeEvery(GENERAL_SETTING_EDIT, generalSettingEditSaga);
    yield takeEvery(GENERAL_SETTING_GET, generalSettingGetSaga);
    yield takeEvery(SHIPMENT_PRICE_CONFIGURATION_EDIT, shipmentPriceConfigurationEditSaga);
    yield takeEvery(SHIPMENT_PRICE_CONFIGURATION_GET, shipmentPriceConfigurationGetSaga);
    yield takeEvery(COURIER_SETTINGS_GET, getCourierSettingsSaga);
    yield takeEvery(COURIER_SETTINGS_EDIT, updateCourierSettingsSaga);
    yield takeEvery(BUSINESS_APP_CONFIGURATION_GET, getBusinessAppSettingsSaga);
    yield takeEvery(BUSINESS_APP_CONFIGURATION_EDIT, updateBusinessAppSettingsSaga);
    yield takeEvery(TELEGRAM_SETTING_EDIT, telegramSettingEditSaga);
    yield takeEvery(MARKETPLACE_PAGES_ADD, marketplacePagesAddSaga);
    yield takeEvery(MARKETPLACE_MESSAGE_ADD, marketplaceMassageAddSaga);
    yield takeEvery(MARKETPLACE_MESSAGE_EDIT, marketplaceMassageEditSaga);
    yield takeEvery(MARKETPLACE_PAGES_EDIT, marketplacePageEditSaga);
    yield takeEvery(MARKETPLACE_MESSAGE_TOGGLE_ENABLED, marketplaceToggleEnabledSaga);
    yield takeEvery(MARKETPLACE_PAGES_TOGGLE_ENABLED, marketplacePagesToggleEnabledSaga);
    yield takeEvery(MARKETPLACE_MESSAGE_DELETE, marketplaceMessageDeleteSaga);
    yield takeEvery(MARKETPLACE_PAGES_DELETE, marketplacePageDeleteSaga);
    yield takeEvery(REGION_SETTING_EDIT, regionSettingEditSaga);
    yield takeEvery(REGION_SETTING_ADD, regionSettingAddSaga);
    yield takeEvery(FLEET_SETTINGS_GET, fleetGetSaga);
    yield takeEvery(FLEET_SETTINGS_UNMOUNT, resetFleetTelemetrySaga);
    yield takeEvery(FLEET_SETTINGS_ADD, fleetAddSaga);
    yield takeEvery(FLEET_SETTINGS_EDIT, fleetEditSaga);
    yield takeEvery(FLEET_SETTINGS_DELETE, fleetDeleteSaga);
    yield takeEvery(FLEET_SETTINGS_TELEMETRY_BLINK, fleetGetTelemetryBlinkSaga);
    yield takeEvery(FLEET_SETTINGS_TELEMETRY_GET, fleetGetTelemetrySaga);
    yield takeEvery(REGION_SETTING_DELETE, regionSettingRemoveSaga);
    yield takeEvery(PROVIDERS_SETTING_GET, providersSettingGetSaga);
    yield takeEvery(PROVIDERS_SETTING_DETAILS_GET, providersSettingDetailsGetSaga);
    yield takeEvery(PROVIDERS_SETTING_EDIT, providersSettingEditSaga);
    yield takeEvery(PROVIDERS_SETTING_ADD, providersSettingAddSaga);
    yield takeEvery(PROVIDERS_SETTING_DELETE, providersSettingRemoveSaga);
    yield takeEvery(CLOUD_PRINTERS_SETTING_GET, cloudPrintersSettingGetSaga);
    yield takeEvery(CLOUD_PRINTERS_SETTING_DETAILS_GET, cloudPrintersSettingDetailsGetSaga);
    yield takeEvery(CLOUD_PRINTERS_SETTING_EDIT, cloudPrintersSettingEditSaga);
    yield takeEvery(CLOUD_PRINTERS_SETTING_ADD, cloudPrintersSettingAddSaga);
    yield takeEvery(CLOUD_PRINTERS_SETTING_DELETE, cloudPrintersSettingRemoveSaga);
    yield takeEvery(SERVICE_CITIES_SETTING_ADD, serviceCityAddSaga);
    yield takeEvery(SERVICE_CITIES_SETTING_EDIT, serviceCityEditSaga);
    yield takeEvery(SERVICE_CITIES_SETTING_GET, serviceCityGetSaga);
    yield takeEvery(SERVICE_CITIES_SETTING_DELETE, serviceCityRemoveSaga);
    yield takeEvery(SERVICE_CITIES_SETTING_TOGGLE, serviceCityToggleSaga);
    yield takeEvery(NAMED_PLACES_ADD, namedPlaceAddSaga);
    yield takeEvery(NAMED_PLACES_EDIT, namedPlaceEditSaga);
    yield takeEvery(NAMED_PLACES_DELETE, namedPlaceRemoveSaga);
    yield takeEvery(NAMED_PLACES_GET, namedPlaceGetSaga);
    yield takeEvery(GET_USER_SHIFT_HISTORY, getUserShiftHistorySaga);
    yield takeEvery(GET_PROVIDER_SHIFT_HISTORY, getProviderShiftHistorySaga);
    yield takeEvery(CURRENT_SHIFT_USERS, currentShiftUsersSaga);
    yield takeEvery(SHIFT_PROVIDERS_GET, shiftProvidersSaga);
    yield takeEvery(SETTINGS_CONTROL_CENTER_EDIT, settingsControlCenterEdit);
    yield takeEvery(SETTINGS_CONTROL_CENTER_GET, settingsControlCenterGet);
    yield takeEvery(SETTINGS_CONTROL_CENTER_ACTIVATE_EDIT, activateControlCenterSaga);
    yield takeEvery(SETTINGS_CONTROL_CENTER_DEACTIVATE_EDIT, deactivateControlCenterSaga);
    yield takeEvery(SETTINGS_CONTROL_CENTER_CHANGEUSER_EDIT, changeUserControlCenterSaga);
    yield takeEvery(COPY_TELEGRAM_COMMAND, copyTelegramCommandSaga);
    yield takeEvery(GET_ORDER_STAT, getOrderStatSaga);
    yield takeEvery(NOTIFICATION_ADD, notificationAddSaga);
    yield takeEvery(NOTIFICATION_DELETE, notificationDeleteSaga);
    yield takeEvery(NOTIFICATIONS_RESTART, notificationRestartSaga);
    yield takeEvery(DEPOTMENU_SETTING_CHECK, checkDepotMenuSaga);
    yield takeEvery(DEPOTMENU_GET, getDepotMenuSaga);
    yield takeEvery(DEPOT_MENU_COPY_EDIT, depotMenuCopySaga);
    yield takeEvery(DEPOTPAYMEWALLET_SETTING_ADD, depotPaymeWalletSaga);
    yield takeEvery(DEPOTPAYMECREDIT_SETTING_EDIT, depotPaymeCreditSaga);
    yield takeEvery(DEPOTPAYMENT_STATE, depotPaymentStateSaga);
    yield takeEvery(LOAD_DEPOT_USER_BY_ID, getDepotUserById);
    yield takeEvery(GET_ORDERING_MENU_BUSINESS_EXTRA, getOrderingMenuExtraSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_EDIT, orderingMenuExtraEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_ADD, orderingMenuExtraEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_DELETE, orderingMenuExtraDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_UPDATE, orderingMenuExtraUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_ADD, orderingMenuExtrasOptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_EDIT, orderingMenuExtrasOptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_UPDATE, orderingMenuExtrasOptionUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_DELETE, orderingMenuExtrasOptionDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_REORDER, orderingMenuExtrasOptionReorderSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_ADD, orderingMenuExtrasSubOptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_EDIT, orderingMenuExtrasSubOptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_UPDATE, orderingMenuExtrasSubOptionUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_DELETE, orderingMenuExtrasSubOptionDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_REORDER, orderingMenuExtrasSubOptionReorderSaga);
    yield takeEvery(CANCEL_REASON_GET, cancelReasonGetSaga);
    yield takeEvery(CANCEL_REASON_ADD, cancelReasonAddSaga);
    yield takeEvery(CANCEL_REASON_EDIT, cancelReasonEditSaga);
    yield takeEvery(CANCEL_REASON_DELETE, cancelReasonDeleteSaga);
    yield takeEvery(BUSINESS_CATEGORY_GET, businessCategoryGetSaga);
    yield takeEvery(BUSINESS_CATEGORY_EDIT, businessCategoryEditSaga);
    yield takeEvery(BUSINESS_CATEGORY_ADD, businessCategoryEditSaga);
    yield takeEvery(BUSINESS_CATEGORY_DELETE, businessCategoryDeleteSaga);
    yield takeEvery(BUSINESS_CATEGORY_UPDATE, businessCategoryUpdateSaga);
    yield takeEvery(DEPOT_BUSINESS_DEEP_LINK_CREATE, businessDeepLinkCreateSaga);
    yield takeEvery(DEPOT_BUSINESS_PRODUCT_DEEP_LINK_CREATE, businessProductDeepLinkCreateSaga);
    yield takeEvery(MARKETPLACE_DEEP_LINK_CREATE, marketplaceDeepLinkCreateSaga);
    yield takeEvery(DEPOT_BUSINESS_TRANSLATION_GET, depotBusinessTranslationGetSaga);
    yield takeEvery(DEPOT_BUSINESS_TRANSLATION_EDIT, depotBusinessTranslationUpdateSaga);
    yield takeEvery(DEPOT_BUSINESS_TRANSLATION_EXPORT, depotBusinessTranslationExportSaga);
    yield takeEvery(DEPOT_BUSINESS_TRANSLATION_IMPORT, depotBusinessTranslationImportSaga);
    yield takeEvery(MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING_EDIT, manuTemplateBusinessCategoryEditSaga);
    yield takeEvery(PAYMENTS_CONFIGURATION_SETTING_EDIT, restaurantPaymentsConfigurationEditSaga);
    yield takeEvery(RESTAURANT_PAYME_WALLET_SETTING_ADD, restaurantPaymeCreditSaga);
    yield takeEvery(RESTAURANT_PAYMENT_STATE, restaurantPaymentStateSaga);
    yield takeEvery(OPERATION_TIMING_SETTING_EDIT, operationTimingEditSaga);
    yield takeEvery(OPERATION_TIMING_SETTING_ADD, operationTimingAddSaga);
    yield takeEvery(OPERATION_TIMING_SETTING_DELETE, operationTimingDeleteSaga);
    yield takeEvery(OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY, operationTimingToggleDefaultPropertySaga);
    yield takeEvery(ORDERING_MENU_GET, getOrderingMenuSaga);
    yield takeEvery(BUSINESS_MENU_GET, getMergegMenuByBusinessId);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_DEPOT_EDIT, businessMenuCategoryDepotEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DEPOT_EDIT, businessMenuCategoryExtrasDepotEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_DEPOT_EDIT, businessMenuCategoryProductDepotEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DEPOT_EDIT, businessMenuCategoryExtrasOptionDepotEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DEPOT_EDIT, businessMenuCategoryExtrasSuboptionEditDepotSaga);
    yield takeEvery(ORDERING_MENU_DELIVERY_ZONES_EDIT, orderingMenuDeliveryZoneEditSaga);
    yield takeEvery(ORDERING_MENU_DELIVERY_ZONES_ADD, orderingMenuDeliveryZoneAddSaga);
    yield takeEvery(ORDERING_MENU_DELIVERY_ZONES_DELETE, orderingMenuDeliveryZoneDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_SCHEDULE_EDIT, orderingMenuScheduleEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_DISCOUNTS_EDIT, orderingMenuBusinessDiscountEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_DISCOUNTS_ADD, orderingMenuBusinessDiscountAddSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_DISCOUNTS_DELETE, orderingMenuBusinessDiscountDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_INFO_EDIT, orderingMenuBusinessInfoEditSaga);
    yield takeEvery(SET_ORDERING_MENU_BUSINESS_INFO_AVATAR, setBusinessMenuHeaderSaga);
    yield takeEvery(SET_ORDERING_MENU_BUSINESS_INFO_HEADER_MINI, setBusinessMenuHeaderMiniSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORIES_DELETE, businessMenuCategoryDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORIES_UPDATE, businessMenuCategoryUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORIES_EDIT, businessMenuCategoryEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORIES_LIST_UPDATE, businessMenuCategoryListUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORIES_ADD, businessMenuCategoryAddSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_EDIT, businessMenuCategoryProductEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_ADD, businessMenuCategoryProductAddSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DELETE, businessMenuCategoryProductDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DRAG_UPDATE, businessMenuCategoryProductsDragUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_UPDATE, businessMenuCategoryProductsUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_AVATAR, businessMenuCategoryProductsUpdateAvatarSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_UPDATE, businessMenuCategoryProductUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_EDIT, businessMenuCategoryExtrasEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_UPDATE, businessMenuCategoryExtrasUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DELETE, businessMenuCategoryExtrasDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_ADD, businessMenuCategoryExtrasAddSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_UPDATE, businessMenuCategoryExtrasOptionUpdateSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_EDIT, businessMenuCategoryExtrasOptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ADD, businessMenuCategoryExtrasOptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DELETE, businessMenuCategoryExtrasOptionDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_REORDER, businessMenuCategoryExtrasOptionReorderSaga);
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_UPDATE,
        businessMenuCategoryExtrasSuboptionUpdateSaga
    );
    yield takeEvery(ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_EDIT, businessMenuProductOverrideExtraSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_EDIT, businessMenuProductOverrideOptionSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_EDIT, businessMenuProductOverrideSuboptionSaga);
    yield takeEvery(PARENT_MENU_OVERRIDE_DELETE, parentMenuOverrideDeleteSaga);
    yield takeEvery(TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_EDIT, menuTemplateOverrideExtraSaga);
    yield takeEvery(TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_EDIT, menuTemplateOverrideOptionSaga);
    yield takeEvery(TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_EDIT, menuTemplateOverrideSuboptionSaga);
    yield takeEvery(TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE, menuTemplateOverrideSuboptionDeleteSaga);
    yield takeEvery(TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE, menuTemplateOverrideOptionDeleteSaga);
    yield takeEvery(TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_DELETE, menuTemplateOverrideExtraDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_PRODUCT_DELETE_OVERRIDE, businessMenuProductDeleteOverrideSaga);
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_REORDER,
        businessMenuCategoryExtrasSuboptionReorderSaga
    );
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DELETE,
        businessMenuCategoryExtrasSuboptionDeleteSaga
    );
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_EDIT, businessMenuCategoryExtrasSuboptionEditSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_ADD, businessMenuCategoryExtrasSuboptionAddSaga);
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_EDIT,
        businessMenuCategoryProductIngredientEditSaga
    );
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_REORDER,
        businessMenuCategoryProductIngredientReorderSaga
    );
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_UPDATE,
        businessMenuCategoryProductIngredientUpdateSaga
    );
    yield takeEvery(
        ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_DELETE,
        businessMenuCategoryProductIngredientDeleteSaga
    );
    yield takeEvery(ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_GET, depotDefaultRegionControlCenterGetSaga);
    yield takeEvery(DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_EDIT, depotDefaultRegionControlCenterEditSaga);
    yield takeEvery(DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_ADD, depotDefaultRegionControlCenterEditSaga);
    yield takeEvery(DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_DELETE, depotDefaultRegionControlCenterDeleteSaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_GALLERY_EDIT, businessMenuEditGallerySaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_GALLERY_ADD, businessMenuAddGallerySaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_GALLERY_DELETE, businessMenuDeleteGallerySaga);
    yield takeEvery(ORDERING_MENU_BUSINESS_GALLERY_REORDER, businessMenuReorderGallerySaga);
    yield takeEvery(SETTINGS_OFFERS_GET, getSettingsOffersSaga);
    yield takeEvery(SETTINGS_OFFERS_EDIT, getSettingsOffersEditSaga);
    yield takeEvery(SETTINGS_OFFERS_ADD, getSettingsOffersAddSaga);
    yield takeEvery(SETTINGS_OFFERS_DELETE, getSettingsOffersDeleteSaga);
    yield takeEvery(SET_ACTIVATE_MODEL, setActivateModelSaga);
    yield takeEvery(SEARCH_SHORTCUT_SETTING_ADD, searchShortcutAddSettingsSaga);
    yield takeEvery(SEARCH_SHORTCUT_SETTING_DELETE, searchShortcutDeleteSettingsSaga);
    yield takeEvery(PUSHER_DEPOT_SERVICES_REFRESH, depotServicesRefreshSaga);
    yield takeEvery(GIFT_CARD_EDIT, giftCardEditSaga);
    yield takeEvery(GIFT_CARD_GET, giftCardGetSaga);
    yield takeEvery(GIFT_CARD_PRODUCT_ADD, giftCardProductAddSaga);
    yield takeEvery(GIFT_CARD_PRODUCT_EDIT, giftCardProductEditSaga);
    yield takeEvery(GIFT_CARD_PRODUCT_UPDATE, giftCardProductUpdateSaga);
    yield takeEvery(GIFT_CARD_PRODUCT_REORDER, giftCardProductDragUpdateSaga);
    yield takeEvery(GIFT_CARD_PRODUCT_UPDATE_AVATAR, giftCardProductUpdateAvatarSaga);
    yield takeEvery(GIFT_CARD_BUSINESS_UPDATE_AVATAR, setGiftCardBusinessMenuHeaderSaga);
    yield takeEvery(GIFT_CARD_PRODUCT_DELETE, giftCardProductDeleteSaga);
    yield takeEvery(DEPOTPRICE_SETTING_APPLY_TO_ADD, depotPriceSettingApplyToSaga);
    yield takeEvery(COURIER_DELIVERY_FEES_SETTING_GET, getCourierShipmentSaga);
    yield takeEvery(COURIER_DELIVERY_FEES_SETTING_EDIT, courierShipmentEditSaga);
    yield takeEvery(REFERRAL_SETTINGS_EDIT, updateReferralSettingsSaga);
    yield takeEvery(REFERRAL_SETTINGS_GET, getReferralSettingsSaga);
    yield takeEvery(LOYALTY_SETTINGS_GET, getLoyaltySettingsSaga);
    yield takeEvery(LOYALTY_SETTINGS_REGISTER_VOUCHER_EDIT, editLoyaltySettingsRegisterVoucherSaga);
    yield takeEvery(LOYALTY_SETTINGS_CASHBACK_EDIT, editLoyaltySettingsCashbackSaga);
    yield takeEvery(DRIVER_TIP_GET, getDriverTipSaga);
    yield takeEvery(DRIVER_TIP_EDIT, updateDriverTipSaga);
    yield takeEvery(LIST_PROMOTION_SETTING_GET, getListPromotionSettingsSaga);
    yield takeEvery(LIST_PROMOTION_SETTING_ADD, addListPromotionSettingsSaga);
    yield takeEvery(LIST_PROMOTION_SETTING_EDIT, editListPromotionSettingsSaga);
    yield takeEvery(LIST_PROMOTION_SETTING_DELETE, deleteListPromotionSettingsSaga);
    yield takeEvery(LIST_PROMOTION_SETTING_DRAG, dragListPromotionSettingsSaga);
    yield takeEvery(MARKETPLACE_MESSAGE_UPDATE, marketplaceMessageUpdateSaga);
    yield takeEvery(MARKETPLACE_PAGES_UPDATE, marketplacePageUpdateSaga);
    yield takeEvery(MARKETPLACE_SETTINGS_EDIT, marketplaceSettignsUpdateSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_GET, serviceLoyaltyCampaignsGetSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_ADD, serviceLoyaltyCampaignsAddSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_DELETE, serviceLoyaltyCampaignsDeleteSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_EDIT, serviceLoyaltyCampaignsEditSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_AUDIENCE_FILTER_COUNT_GET, serviceLoyaltyCampaignsAudienceFilterCountGetSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_TOGGLE_ENABLED, serviceLoyaltyCampaignsToggleEnabledSaga);
    yield takeEvery(EXECUTE_COMPAIGN, executeCompaingSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_EXECUTIONS_GET, serviceLoyaltyCampaignsExecutionsGetSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_EXECUTIONS_ADD, serviceLoyaltyCampaignsExecutionsCreateSaga);
    yield takeEvery(LOYALTY_CAMPAIGNS_EXECUTIONS_UPDATE_BY_ID, serviceLoyaltyCampaignsExecutionsUpdateByIdSaga);
    yield takeEvery(SERVICE_FEE_GET, serviceFeeGetSaga);
    yield takeEvery(SERVICE_FEE_EDIT, serviceFeeEditSaga);
    yield takeEvery(SETTINGS_DELIVERY_ZONES_GET, deliveryZonesGetSaga);
    yield takeEvery(SETTINGS_DELIVERY_ZONES_ADD, deliveryZonesAddSaga);
    yield takeEvery(SETTINGS_DELIVERY_ZONES_EDIT, deliveryZonesEditSaga);
    yield takeEvery(SETTINGS_DELIVERY_ZONES_DELETE, deliveryZoneDeleteSaga);
    yield takeEvery(ORDERING_MENU_DELIVERY_ZONES_UPDATE, deliveryZonesUpdateEnableSaga);
    yield takeEvery(ORDERING_MENU_DELIVERY_ZONES_LIST_UPDATE, deliveryZonesUpdateSaga);
    yield takeEvery(SETTINGS_DELIVERY_ZONES_LIST_UPDATE, marketDeliveryZonesUpdateSaga);
    yield takeEvery(DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK, depotSquareAssociatedLocationCheckSaga);
    yield takeEvery(DEPOT_SQUARE_ASSOCIATED_LOCATION_EDIT, depotSquareAssociatedLocationEditSaga);
    yield takeEvery(DEPOT_SQUARE_ASSOCIATED_REVOKE, depotSquareAssociatedRevokeSaga);
    yield takeEvery(DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION, depotSquareAssociatedAuthorizationSaga);
    yield takeEvery(DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION_SHORT, depotSquareAssociatedAuthorizationShortSaga);
    yield takeEvery(DEPOT_INTEGRATIONS_GET, depotIntegrationsGetSaga);
    yield takeEvery(DEPOT_INTEGRATIONS_UPDATE, depotIntegrationUpdateSaga);
    yield takeEvery(DEPOT_INTEGRATIONS_ADD, depotIntegrationsAddSaga);
    yield takeEvery(DEPOT_INTEGRATIONS_DELETE, depotIntegrationsDeleteSaga);
    yield takeEvery(MENU_TEMPLATE_GET, getMenuTemplateSaga);
    yield takeEvery(MENU_TEMPLATE_DELETE, deleteMenuTemplateSaga);
    yield takeEvery(MENU_TEMPLATE_ADD, addMenuTemplateSaga);
    yield takeEvery(MENU_TEMPLATE_EDIT, updateMenuTemplateSaga);
    yield takeEvery(BUSINESS_PARENT_MENU_GET, getBusinessParentMenuSaga);
    yield takeEvery(BUSINESS_PARENT_MENU_UPDATE, overrideMenuSaga);
    yield takeEvery(OPERATION_TIMING_SETTINGS_GET, operationTimingGetSaga);
    yield takeEvery(DEPOT_OPERATION_MODE_SETTING_GET_ALL, operationTimingGetForDepotSaga);
    yield takeEvery(RESTAURANT_CONTROL_CENTERS_STATUS_UPDATE, controlCentersStateUpdateSaga);
    yield takeEvery(SET_MENU_CREATE_ORDER, setMenuCreateOrder);
    yield takeEvery(DEPOT_PROVIDERS_GET, getDepotProvidersSaga);
    yield takeEvery(DEPOT_PROVIDERS_REORDER, reorderDepotProvidersSaga);
    yield takeEvery(DEPOT_PROVIDER_EDIT, editDepotProvidersSaga);
    yield takeEvery(DEPOT_PROVIDER_ADD, addDepotProviderSaga);
    yield takeEvery(DEPOT_PROVIDERS_DELETE, deleteDepotProviderSaga);
    yield takeEvery(DEPOT_PROVIDER_UPDATE, editDepotProvidersSaga);
    yield takeEvery(DEPOT_PROVIDERS_UNMOUNT, resetDepotProvidersSaga);
    yield takeEvery(CHECK_SQUAREUP_AUTHORIZED_REQUIRED, checkSquareUpAuthorizedRequiredSaga);
    yield takeEvery(GET_BUSINESS_BY_QUERY, getBusinessByQuerySaga);
    yield takeEvery(PARENT_MENU_OVERRIDE_SUBOPTION_EDIT, parentMenuOverrideSuboptionSaga);
    yield takeEvery(PARENT_MENU_OVERRIDE_OPTION_EDIT, parentMenuOverrideOptionSaga);
    yield takeEvery(PARENT_MENU_OVERRIDE_EXTRA_EDIT, parentMenuOverrideExtraSaga);
    yield takeEvery(PARENT_MENU_OVERRIDE_PRODUCT_EDIT, parentMenuOverrideProductSaga);
    yield takeEvery(PARENT_MENU_OVERRIDE_CATEGORY_EDIT, parentMenuOverrideCategorySaga);
    yield takeEvery(MARKETPLACE_MESSAGE_GALLERY_ADD, addMarketplaceMessageGallerySaga);
    yield takeEvery(MARKETPLACE_MESSAGE_GALLERY_EDIT, editMarketplaceMessageGallerySaga);
    yield takeEvery(MARKETPLACE_MESSAGE_GALLERY_REORDER, reorderMarketplaceMessageGallerySaga);
    yield takeEvery(MESSAGE_GALLERY_DELETE, deleteMarketplaceMessageGallerySaga);
    yield takeEvery(MARKETPLACE_MENU_CLEAR_OVERRIDE, marketplaceMenuClearOverrideSaga);
}
