import {ControlBreadcrumbModel} from "../models/controlBreadcrumbModel";
import i18n from '../i18n.js';

export const createBreadcrumbModel = ({label, getLabel, isShow}) => {
  const translateLabel = label ? i18n.exists(label) ? i18n.t(label) : '' : ''

  return new ControlBreadcrumbModel({
    label: translateLabel,
    getLabel,
    isShow,
  });
}