import {MODEL_TYPE, PAY_METHOD} from "../utils/enums";
export function paymentsModel(payments) {
  const initialFirstPayment = {
    amount: 0,
    paymentInfo: "",
    isCache: true,
    isPaid: false,
    modelType: MODEL_TYPE.payments
  };

  const initialSecondPayment = {
    amount: 0,
    paymentInfo: "",
    isCache: false,
    isPaid: false,
    modelType: MODEL_TYPE.payments
  };

  if (!payments || !payments.length) {
    return [initialFirstPayment, initialSecondPayment];
  } else {
    if (payments.length === 2) {
      return payments;
    } else {
      const firstPayment = payments[0];

      return [
        firstPayment.isCache ? firstPayment : initialFirstPayment,
        !firstPayment.isCache ? firstPayment : initialSecondPayment
      ];
    }
  }
}

export const getPaymentModel = (payments, paymentType) => {
  const defaultPayment = { amount: 0, modelType: MODEL_TYPE.payments, isCache: paymentType === PAY_METHOD.cash, paymentInfo: "", isPaid: false, captured_price: 0 };
  const payment = payments.reduce((acc, cv) => {
    if(cv.paymethod_id === paymentType) {
      return {
        ...acc,
        amount: acc.amount += cv.price,
        captured_price: acc.captured_price += cv.captured_price
      }
    }
    return acc;
  }, { ...defaultPayment });

  if(payment) {
    payment.isPaid = payment.amount && payment.captured_price && (payment.captured_price === payment.amount);
    delete payment.captured_price;
    return payment
  }

  return defaultPayment;
}