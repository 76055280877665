import React from 'react';
import i18n from '../../i18n';

import FilterOptionsModel from '../../models/filterOptionsModel';
import SectionModel from '../../models/sectionModel';
import {createControlModel, filtrateControlByDepotRole} from '../controlModelFactory';
import { getBusinesDiscountFilter } from '../filter';
import { getInitModel } from '../initModels';
import {
    getDepotNameById,
    getDiscountCalculationBaseType,
    getDiscountOrderTypeLabel,
    getDiscountTargetType,
    getDiscountTypeLabel,
} from '../restaurant';

import {
    ORDERING_MENU_BUSINESS_DISCOUNTS,
    ORDERING_MENU_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    DATECONTROL_TYPE,
    DISCOUNT_TYPE,
    discountOrderTypes,
    discountTypes, getCampaignRecurringTypes,
    getOfferCalculationBase,
    getOfferDiscountTarget, LOYALTY_LEVEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE, OFFER_DISCOUNT_TARGET,
    ORDERING_MENU_TYPE_SECTION_MODEL,
    RATE_TYPE,
    rateTypes, TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import { defaultPromiseResolve } from '../../utils/objects-util';
import { getHistoryLocationParams } from '../../utils/routesHelper';

import IconButton from '../../app/components/IconButton';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import {getRestaurantBusinessMenu} from "../../store/selectors";

export function createBusinesDiscountsSection({ ...args }) {
    const { id } = getHistoryLocationParams(args.params);

    return new SectionModel({
        filterOptions: createBusinesDiscountsSectionFilter(),
        columns: [
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_NAME_LABEL',
                accessor: 'name',
                width: '120px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL',
                accessor: 'discount_target',
                component: item => getDiscountTargetType(item.discount_target) || null,
                width: '110px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_LABEL',
                accessor: 'order_type',
                component: item => getDiscountOrderTypeLabel(item.order_type),
                width: '100px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL',
                accessor: 'type',
                component: item => getDiscountTypeLabel(item.type) || null,
                width: '70px',
            },
            {
                title: 'settings.orderingmenu.MENU_RATE_TYPE_LABEL',
                accessor: 'rate_type',
                component: item =>
                    item.rate_type > 0 ? (
                        <IconButton
                            isIcon={true}
                            iconName={item.rate_type === RATE_TYPE.percent ? 'percent' : 'cash'}
                            tooltipText={
                                item.rate_type === RATE_TYPE.percent
                                    ? i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PERCENTAGE')
                                    : i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PRICE')
                            }
                            onClick={defaultPromiseResolve}
                        />
                    ) : null,
                width: '70px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_RATE_LABEL',
                accessor: 'rate',
                width: '70px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNTS_CALCULATION_BASED_ON',
                accessor: 'calculation_base',
                component: item => getDiscountCalculationBaseType(item.calculation_base) || null,
                width: '116px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_MINIMUM_LABEL',
                accessor: 'minimum',
                width: '84px',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_LIMIT_LABEL',
                accessor: 'limit',
                width: '84px',
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "70px",
                type: CONTROL_TYPE.link,
                link: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.orderingmenu.MENU_DISCOUNTS_EDIT_TOOLTIP_LABEL'),
            },
        ],
        hideHeaderItemControls: true,
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_DISCOUNTS,
        tableMinWidth: 1000,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts,
        typeAction: args.typeAction,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
        controls: filtrateControlByDepotRole([
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew + `?parentId=${id}`,
            }),
        ]),
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        label: 'settings.orderingmenu.DEPOT_MENU_DISCOUNTS',
        mappingLambda: state => {
            return (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.offers) || [];
        },
    });
}

export function createBusinesDiscountEditSection({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL',
                accessor: 'discount_target',
                connect: () => ({
                    values: getOfferDiscountTarget(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_LABEL',
                accessor: 'order_type',
                connect: () => ({
                    values: discountOrderTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL',
                accessor: 'type',
                connect: () => ({
                    defaultValue: { key: '', value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_SELECT_LABEL') },
                    values: [
                        { key: '', value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_SELECT_LABEL') },
                    ].concat(discountTypes()),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_RATE_TYPE_LABEL',
                accessor: 'rate_type',
                connect: () => ({
                    defaultValue: { key: '', value: i18n.t('settings.orderingmenu.MENU_RATE_TYPE_SELECT_LABEL') },
                    values: [{ key: '', value: i18n.t('settings.orderingmenu.MENU_RATE_TYPE_SELECT_LABEL') }].concat(
                        rateTypes()
                    ),
                }),
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_RATE_LABEL',
                accessor: 'rate',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNTS_CALCULATION_BASED_ON',
                accessor: 'calculation_base',
                connect: () => ({
                    values: getOfferCalculationBase(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_MINIMUM_LABEL',
                accessor: 'minimum',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_LIMIT_LABEL',
                accessor: 'limit',
                isEditable: true,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_START_LABEL',
                accessor: 'start',
                isEditable: true,
                timeType: DATECONTROL_TYPE.datetime,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_END_LABEL',
                accessor: 'end',
                min: item => item.start,
                isEditable: true,
                timeType: DATECONTROL_TYPE.datetime,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_COUPON_LABEL',
                accessor: 'coupon',
                isEditable: true,
                isShow: item => item && item.type === DISCOUNT_TYPE.coupon,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_SHOW_IN_MARKETPLACE_APP',
                accessor: 'show_in_marketplace',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_SINGLE_USER_LABEL',
                accessor: 'single_user',
                isEditable: true,
                isInline: true,
                isShow: item => item && item.type === DISCOUNT_TYPE.coupon,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.DISCOUNT_BUSINESS_SHEDULE_ENABLED_LABEL',
                accessor: 'enable_schedule',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                accessor: 'product_id',
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_ID_LABEL',
                type: CONTROL_TYPE.select,
                isEditable: true,
                isShow: item => item.discount_target === OFFER_DISCOUNT_TARGET.product,
                connect: (state) => {
                    const businessMenu = getRestaurantBusinessMenu(state);
                    const parentMenuProducts = (state.restaurant.data?.parentMenu?.categories || []).flatMap(cat => cat.products);
                    const products = businessMenu.categories.flatMap(cat => cat.products).concat(parentMenuProducts);
                    return {
                        values: products.map(product => ({ key: product.id, value: product?.name })),
                    }
                },
            },
            {
                title: 'settings.orderingmenu.AUTO_ADD_QUANTITY',
                accessor: 'auto_add_quantity',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isShow: item => item.type === DISCOUNT_TYPE.promotion
            },
            {
                title: 'settings.orderingmenu.ALLOW_MULTIPLE_DISCOUNTS',
                accessor: 'allow_multiple_discounts',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
                isShow: item => item.type === DISCOUNT_TYPE.promotion
            },
            {
                title: 'settings.orderingmenu.RECURRING_TYPE',
                accessor: 'recurring_type',
                connect: () => ({
                    values: getCampaignRecurringTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.USAGE_LIMIT',
                accessor: 'usage_limit',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.LOYALTY_LEVEL',
                accessor: 'loyalty_level',
                connect: () => {
                    const levels = [];
                    Object.entries(LOYALTY_LEVEL).forEach(([key, value]) => levels.push({key: parseInt(key), value}));
                    return {
                        values: [{key: null, value: i18n.t("enums.loyaltyLevel.ALL")}].concat(levels),
                    }
                },
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL',
                accessor: 'schedule',
                isEditable: true,
                isFillContent: true,
                type: CONTROL_TYPE.schedule,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_DISCOUNTS,
        initNewItemModel: item => getInitModel(MODEL_TYPE.offerModel, item),
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, parentId } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(parentId || id);
                },
            }),
        ],
        label: 'settings.orderingmenu.DEPOT_MENU_DISCOUNTS',
        mappingLambda: state => {
            return (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.offers) || [];
        },
    });
}

function createBusinesDiscountsSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(businesDiscount) {
        return getBusinesDiscountFilter(this.selected, businesDiscount);
    });

    return filterOptionsModel;
}
