import { MODEL_TYPE } from "../utils/enums";

export function depotDeliveryPriceModel() {
  return {
    newRegionId: undefined,
    basisPrice: 0,
    deliveryPrice: 0,
    basisDistanceInMeters: 0,
    pricePerKm: 0,
    calculationMode: 0,
    regionConfigurations: new Array(0),
    modelType: MODEL_TYPE.depotDeliveryPriceModel
  };
}
