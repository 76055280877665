export function depotDeliveryPriceServerModel(price) {
    const { deliveryPrice, basisPrice, calculationMode, basisDistanceInMeters, pricePerKm, regionConfigurations } = price || {};

    return {
        DeliveryPrice: deliveryPrice,
        BasisPrice: basisPrice,
        CalculationMode: calculationMode,
        BasisDistanceInMeters: basisDistanceInMeters,
        PricePerKm: pricePerKm,
        RegionConfigurations: (regionConfigurations || []).map(val => ({
            RegionId: val.id || val.regionId,
            DeliveryPrice: val.deliveryPrice || 0
        }))
    };
}