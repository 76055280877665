import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import InvitationPopup from "../InvitationPopup";
import IconButton from "../IconButton";

const GrCodeDepot = ({ t, userInfo }) => {
  const [invitationPopupIsOpen, setInvitationPopupIsOpen] = useState(null);

  const handleInvitationOpen = () => {
    setInvitationPopupIsOpen(true);
  };

  const handleInvitationPopup = () => {
    setInvitationPopupIsOpen(false);
  };


  return (
    <div className={classNames("grCodeContainer")}>
      {!!userInfo && (
        <IconButton isIcon={true}
          iconName={"grCode"}
          tooltipText={t("settings.users.USER_TOOLS_COURIER_INVITE_TOOLTIP")}
          onClick={handleInvitationOpen}
        />
      )}
      {invitationPopupIsOpen && (
        <InvitationPopup
          isOpen={invitationPopupIsOpen}
          courier={userInfo}
          onClose={handleInvitationPopup}
        />
      )}
    </div>
  );
};

export default withTranslation()(GrCodeDepot);
