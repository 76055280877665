import SectionModel from "../../models/sectionModel.js";
import {
    PROVIDERS_SETTING,
    PROVIDERS_SETTING_DETAILS_GET,
    PROVIDERS_SETTING_DETAILS_UNMOUNT,
    PROVIDERS_SETTING_GET
} from "../../store/reducers/restaurant/restaurant-actions.js";
import {
    CONTROL_TYPE,
    EXTERNAL_COURIER_PROVIDER,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    PROVIDERS_TYPE_MODEL,
    TYPE_ACTION,
    TYPE_SECTION_MODEL
} from "../../utils/enums.js";
import FilterOptionsModel from "../../models/filterOptionsModel.js";
import {createControlModel} from "../controlModelFactory.js";
import {getExternalCycleLogo, isMobileWidth} from "../restaurant.js";
import {createBreadcrumbModel} from "../createBreadcrumbModel.js";
import {getInitModel} from "../initModels.js";
import i18n from "i18next";
import {Avatar} from "../../app/components/Avatar/Avatar.jsx";
import React from "react";
import {getHistoryLocationParams} from "../../utils/routesHelper.js";

export function createProvidersSectionModel() {
    return new SectionModel({
        filterOptions: new FilterOptionsModel(),
        columns: [
            {
                title: "settings.providers.NAME",
                accessor: "name",
                width: "100%",
                fixedWidth: "150px",
                component: item => {
                    return (
                        <Avatar hideIconColor avatar={getExternalCycleLogo(item)} title={item?.name}/>
                    )
                }
            },{
                title: 'settings.providers.ACTIONS',
                textAlign: 'center',
                width: '58px',
                headerWidth: '98px',
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.providersEdit,
                isShow: (item) => {
                    return item.provider !== EXTERNAL_COURIER_PROVIDER.delivApp
                },

                iconName: 'editItem',
                tooltipText: i18n.t('basic.EDIT_BUTTON'),
            },
        ],
        actionName: PROVIDERS_SETTING,
        actionLoad: PROVIDERS_SETTING_GET,
        typeModel: TYPE_SECTION_MODEL.providers,
        label: "settings.TITLE_PROVIDERS",
        breadcrumbs: [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.providersNew
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        mappingLambda: state => {
            let result = [];
            const currentProviders = state?.restaurant?.data?.providers;
            if(currentProviders && currentProviders.length > 0) {
                result = currentProviders.filter((e) => e.isActive);
            }
            return result;
        }
    });
}

export function createProvidersEditSectionModel({...args}) {
    return new SectionModel({
        filterOptions: new FilterOptionsModel(),
        columns: [
            {
                title: 'settings.providers.SELECT_INTEGRATION',
                accessor: 'provider',
                isEditable: true,
                type: CONTROL_TYPE.select,
                isShow: () => args.typeAction !== TYPE_ACTION.edit,
                isInlineRow: true,
                connect: state => {
                    let result = state?.restaurant?.data?.providers;
                    result = result ? result.filter((e) => !e.isActive) : [];
                    return {
                        values: result ? result.map((provider) => ({key: provider.name, value: provider.id})) : [],
                    };
                },
            },
            {
                id: "yangoSettings",
                columns: [
                    {
                        title: "settings.providers.DETAILS_CORRECTION_THRESHOLD_IN_METER",
                        accessor: "correctionThresholdInMeter",
                        isEditable: true,
                        type: CONTROL_TYPE.number
                    },
                    {
                        title: "settings.providers.DETAILS_EMERGENCY_CONTACT_NAME",
                        accessor: "emergencyContactName",
                        isEditable: true,
                        type: CONTROL_TYPE.number
                    },
                    {
                        title: "settings.providers.DETAILS_EMERGENCY_CONTACT_PHONE",
                        accessor: "emergencyContactPhone",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_HOST",
                        accessor: "host",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_TOKEN",
                        accessor: "token",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_USE_ORDER_ID",
                        accessor: "useOrderId",
                        isEditable: true,
                        type: CONTROL_TYPE.switch
                    },
                    {
                        title: "settings.providers.DETAILS_USE_YANGO_GEOLOCATION",
                        accessor: "useYangoGeolocation",
                        isEditable: true,
                        type: CONTROL_TYPE.switch
                    },
                ],
                isEditable: true,
                isShow: (item) => {
                    return item && PROVIDERS_TYPE_MODEL.Yango === item.provider
                },
                type: CONTROL_TYPE.group
            },

            {
                id: "woltSettings",
                columns: [
                    {
                        title: "settings.providers.DETAILS_MERCHANT_ID",
                        accessor: "merchantId",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_SUPPORT_EMAIL",
                        accessor: "supportEmail",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_SUPPORT_PHONE",
                        accessor: "supportPhone",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_HOST",
                        accessor: "host",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                ],
                isEditable: true,
                isShow: (item) => {
                    return item && PROVIDERS_TYPE_MODEL.Wolt === item.provider
                },
                type: CONTROL_TYPE.group
            },

            {
                id: "doorDashSettings",
                columns: [
                    {
                        title: "settings.providers.DETAILS_DEVELOPER_ID",
                        accessor: "developerId",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_KEY_ID",
                        accessor: "keyId",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_SIGNING_SECRET",
                        accessor: "signingSecret",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                ],
                isEditable: true,
                isShow: (item) => {
                    return item && PROVIDERS_TYPE_MODEL.DoorDash === item.provider
                },
                type: CONTROL_TYPE.group
            },

            {
                id: "sendimanSettings",
                columns: [
                    {
                        title: "settings.providers.DETAILS_USER",
                        accessor: "user",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_PASSWORD",
                        accessor: "password",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_TEST_MODE",
                        accessor: "testMode",
                        isEditable: true,
                        type: CONTROL_TYPE.switch
                    },
                    {
                        title: "settings.providers.DETAILS_IS_IN_DEBUG_MODE",
                        accessor: "isInDebugMode",
                        isEditable: true,
                        type: CONTROL_TYPE.switch
                    },
                ],
                isEditable: true,
                isShow: (item) => {
                    return item && PROVIDERS_TYPE_MODEL.Sendiman === item.provider
                },
                type: CONTROL_TYPE.group
            },

            {
                id: "haatSettings",
                columns: [
                    {
                        title: "settings.providers.DETAILS_TEST_MODE",
                        accessor: "testMode",
                        isEditable: true,
                        type: CONTROL_TYPE.switch
                    },
                ],
                isEditable: true,
                isShow: (item) => {
                    return item && PROVIDERS_TYPE_MODEL.Haat === item.provider
                },
                type: CONTROL_TYPE.group
            },

            {
                id: "gettSettings",
                columns: [
                    {
                        title: "settings.providers.DETAILS_BUSINESS_ID",
                        accessor: "businessId",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_CLIENT_ID",
                        accessor: "clientId",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_CLIENT_SECRET",
                        accessor: "clientSecret",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                    {
                        title: "settings.providers.DETAILS_PRODUCT_ID",
                        accessor: "productId",
                        isEditable: true,
                        type: CONTROL_TYPE.text
                    },
                ],
                isEditable: true,
                isShow: (item) => {
                    return item && PROVIDERS_TYPE_MODEL.Gett === item.provider
                },
                type: CONTROL_TYPE.group
            },


        ],
        typeAction: args.typeAction,
        actionName: PROVIDERS_SETTING,
        actionOnUnmount: PROVIDERS_SETTING_DETAILS_UNMOUNT,
        actionLoad: args.typeAction === TYPE_ACTION.edit ? PROVIDERS_SETTING_DETAILS_GET : PROVIDERS_SETTING,
        parentUrl: TYPE_SECTION_MODEL.providers,
        label: "",
        getLabel: (item) => item && item.provider ? item.provider : args.typeAction === TYPE_ACTION.new ? i18n.t("settings.TITLE_PROVIDERS") : "",
        initNewItemModel: item =>
            getInitModel(MODEL_TYPE.providers, item),
        breadcrumbs: args.typeAction === TYPE_ACTION.edit ? [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
            createBreadcrumbModel({label: "settings.TITLE_PROVIDERS"}),
        ] : [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
        ],
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? TYPE_SECTION_MODEL.providersEdit
                : TYPE_SECTION_MODEL.providersNew,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: () => true,
            }),
        ],
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            let result = null;
            const currentProviders = state?.restaurant?.data?.providersDetails;
            if(id) {
                result = {...currentProviders, provider: id};
            } else {
                result = {...currentProviders};
            }

            return result ? result : null;
        }
    });
}

