import React from "react";
import classNames from "classnames";
import "./index.scss";

const CheckBox = ({ id, label, value, disabled, onChange, stopPropagation }) => {
  const handleOnClick = e => {
    e.preventDefault();
    if(stopPropagation) {
        e.stopPropagation();
    }
    onChange(!value);
  };

  return (
    <div className={classNames("checkBoxControl")} onClick={handleOnClick}>
      <input
        id={`checkBoxControl-${label || id}`}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={handleOnClick}
      />
      <label
        htmlFor={`checkBoxControl-${label || id}}`}
        className={classNames("checkboxStyled")}
      >
        <span className={'icon-checked'}>
          &#10003; {/*  ✓ symbol  */}
        </span>
      </label>
      {label && (
        <span className={classNames("titleCheck")}>{label || ""}</span>
      )}
    </div>
  );
};

export default CheckBox;
