import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import RoleAvatar from "../RoleAvatar";

import { USER_ROLES } from "../../../utils/enums";

const config = [
  {
    id: "deliveryManager",
    activeDepth: "deliveryManager",
    tooltip: "USER_ROLE_MANAGER_TOOLTIP"
  },
  {
    id: "courier",
    activeDepth: "courier",
    tooltip: "USER_ROLE_COURIER_TOOLTIP"
  },
  {
    id: "supportOperator",
    activeDepth: "supportOperator",
    tooltip: "USER_ROLE_SUPPORT_OPERATOR_TOOLTIP"
  },
  {
    id: "supervisor",
    activeDepth: "supervisor",
    tooltip: "USER_ROLE_SUPERVISOR_TOOLTIP"
  },
  {
    id: "depot",
    activeDepth: "depot",
    tooltip: "USER_ROLE_DEPOT_TOOLTIP"
  },
  {
    id: "controlCenterManager",
    activeDepth: "controlCenterManager",
    tooltip: "USER_ROLE_CONTROL_CENTER_MANAGER_TOOLTIP"
  }
];

const UserRoleAvatar = ({ t, value }) => {
  const [isOpenMobile, toggleMobile] = useState(false);
  const countRoles = value => {
    return Object.values(value.roles).filter(item => Boolean(item)).length;
  };

  const getConfig = () => {
    if(value.roles) {
      const filteredConfig = config.filter(
          item => !!value.roles[USER_ROLES[item.activeDepth]]
      );
      const totalRoles = countRoles(value);
      let desktopConfig = [];
      let mobileConfig = [];
      if (totalRoles > 2 && window.innerWidth < 720) {
        for (let i = 0; i < filteredConfig.length; i++) {
          if (i < 2) {
            desktopConfig.push(filteredConfig[i]);
          } else {
            mobileConfig.push(filteredConfig[i]);
          }
        }
        return {
          desktopConfig,
          mobileConfig
        };
      }
      return {
        desktopConfig: filteredConfig,
        mobileConfig: []
      };
    }
    return {
      desktopConfig: [],
      mobileConfig: []
    }
  };

  const renderMobileAvatars = mobileConfig => {
    if (!mobileConfig.length) {
      return null;
    }
    return (
      <div className="userRoleAvatarWrapper">
        <button
          className={classNames("userRoleAvatarWrapped")}
          onClick={() => toggleMobile(!isOpenMobile)}
        >
          <p className={classNames("userRoleAvatarWrappedCount")}>
            {mobileConfig.length}
          </p>
          <p className={classNames("userRoleAvatarWrappedMore")}>more</p>
        </button>
        {isOpenMobile && (
          <div className="userRoleAvatarContainer">
            {mobileConfig.map(item => {
              return (
                <RoleAvatar
                  key={item.id}
                  active={value.roles[USER_ROLES[item.activeDepth]]}
                  role={USER_ROLES[item.activeDepth]}
                  unapproved={!value.authorized[item.activeDepth]}
                  tooltipText={t(`settings.users.${item.tooltip}`)}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const { desktopConfig, mobileConfig } = getConfig();

  return (
    <div className={classNames("userRoleAvatar")}>
      {desktopConfig.filter(r => r.activeDepth !== USER_ROLES.depot).map(item => {
        return (
          <RoleAvatar
            key={item.id}
            active={value.roles[USER_ROLES[item.activeDepth]]}
            role={USER_ROLES[item.activeDepth]}
            unapproved={!value.authorized[item.activeDepth]}
            tooltipText={t(`settings.users.${item.tooltip}`)}
          />
        );
      })}
      {renderMobileAvatars(mobileConfig)}
    </div>
  );
};

export default withTranslation()(UserRoleAvatar);
