import i18n from "../../i18n";

import SectionModel from "../../models/sectionModel";
import FilterOptionsModel from "../../models/filterOptionsModel";

import {
  SERVICE_CITIES_SETTING, SERVICE_CITIES_SETTING_GET,
  SERVICE_CITIES_SETTING_TOGGLE
} from "../../store/reducers/restaurant/restaurant-actions";
import {
  CONTROL_TYPE,
  MODEL_TYPE,
  TYPE_SECTION_MODEL,
  MODEL_CONTROL_TYPE, TYPE_ACTION
} from "../../utils/enums";
import { getCityFilter, sortItemsByProp } from "../filter";
import {isMobileWidth} from "../restaurant";
import { createControlModel } from "../controlModelFactory";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createServiceCitiesSectionModel() {
  return new SectionModel({
    filterOptions: createServiceCitiesSectionFilter(),
    columns: [
      {
        title: "orders.NAME_LABEL",
        accessor: "name"
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: "90px",
        centered: true,
        type: CONTROL_TYPE.link,
        link: TYPE_SECTION_MODEL.serviceCitiesEdit,
        iconName: "editItem",
        tooltipText: i18n.t("settings.service-city.EDIT_TOOLTIP"),
      },
    ],
    isForceUpdate: true,
    actionLoad: SERVICE_CITIES_SETTING_GET,
    editAction: SERVICE_CITIES_SETTING_TOGGLE,
    actionName: SERVICE_CITIES_SETTING,
    typeModel: TYPE_SECTION_MODEL.serviceCities,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: TYPE_SECTION_MODEL.serviceCitiesNew
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.SERVICE_CITIES_TITLE",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
    ],
    mappingLambda: state => ((state.restaurant.data && state.restaurant.data.serviceCities) || []).map(el => ({...el, id: el.id || el.name})),
    sortByFunc: items=> sortItemsByProp(items)
  });
}

export function createNewServiceCitySectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.service-city.ALLOW_CITY_ONLY_ADDRESS",
        accessor: "allowCityOnlyAddress",
        isEditable: true,
        type: CONTROL_TYPE.switch,
        isInline: true,
        inlineWidth: '49.5%',
      },
      {
        title: "assistance.CITY_LABEL",
        accessor: "name",
        isEditable: true,
        isHideSearch: true,
        type: CONTROL_TYPE.cityAddress,
        isFillContent: true,
      },
    ],
    actionLoad: SERVICE_CITIES_SETTING_GET,
    actionName: SERVICE_CITIES_SETTING,
    initNewItemModel: (d) => {
      return {
        id: (d && d.id) || "",
        name: (d && d.name) || "",
        useAsDefaultCity: (d && d.useAsDefaultCity) || false,
        allowCityOnlyAddress: (d && d.allowCityOnlyAddress) || false,
        location: (d && d.location) || null,
        modelType: MODEL_TYPE.serviceCities
      };
    },
    typeAction: args.typeAction,
    typeModel: args.typeAction === TYPE_ACTION.new ? TYPE_SECTION_MODEL.serviceCitiesNew : TYPE_SECTION_MODEL.serviceCitiesEdit,
    parentUrl: TYPE_SECTION_MODEL.serviceCities,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.SERVICE_CITIES_TITLE",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
    ],
    mappingLambda: state => ((state.restaurant.data &&  state.restaurant.data.serviceCities) || []).map(el => ({...el, id: el.id || el.name}))
  });
}

function createServiceCitiesSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    city
  ) {
    return getCityFilter(this.selected, city);
  });

  return filterOptionsModel;
}
