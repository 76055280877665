import React from "react";
import classNames from "classnames";
import "./index.scss";

import defaultAvatar from "../../../assets/images/avatar.png";

import Color from "../../../services/color";
import { getConvertedCloudUrl } from "../../../utils/image-util";

export const Avatar = ({ avatar, color, useIconColorClass, isLarge, isObject, hideIconColor, isDefaultImageEmpty, onClick, title }) => {
  const imgSize = isLarge ? 52 : 35;
  const imgUrl = isObject ? avatar : getConvertedCloudUrl(avatar, imgSize);

  return (
      <div className={title ? 'courierAvatarTitle' : ''}>
          <div className={classNames("courierAvatar", { large: isLarge })}
               style={{ height: imgSize, width: imgSize}}
               onClick={e=> onClick && onClick(e)}
          >
              {!hideIconColor && (<span className={classNames("courierIconColor", useIconColorClass || "")}
                                        style={{ backgroundColor: color ? Color.instance.getCodeByColorKey(color) : "transparent" }}
              />)}
              {!!imgUrl && (
                  <img alt={`avatar`} src={imgUrl} className={classNames("courierIcon")}
                       height={imgSize} width={imgSize}
                  />
              )}
              {!imgUrl && !isDefaultImageEmpty && (<img alt={`avatar`} src={defaultAvatar} className={classNames("courierIcon")} />)}
              {!imgUrl && isDefaultImageEmpty && (<div className={classNames("imageEmpty")}></div>)}
          </div>
          {title && <div className={'title'}>{title}</div>}
      </div>
  );
};
