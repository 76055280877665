import React from "react";
import { connect } from "react-redux";
import { usePreview } from "react-dnd-preview"
import classNames from "classnames";
import "./index.scss";

import { DND_TYPES } from "../../../utils/constants";

import GroupbarOrderItemPreview from "../GroupbarOrderItem/GroupbarOrderItemPreview";

const DndPreviewControl = () => {
    const {display, itemType, item, style} = usePreview();
    return (
        <div className={classNames("dndPreviewControl")} style={{...style, opacity: 1, zIndex: 99999999}}>
            {itemType === DND_TYPES.group_bar_order && display && item && (
                <GroupbarOrderItemPreview key={`dndPreviewControlItem-${item.id}`} order={item} />
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    orders: state.order.data.orders || []
});

export default connect(mapStateToProps)(DndPreviewControl);