import React from 'react';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import SwitchControl from '../SwitchControl';
import ColumnTitleWithIcon from '../ColumnTitleWithIcon';
import i18n from 'i18next';

const SectionItemSwitch = ({ t, id, column, value, onPropChange, item }) => {
  const handleOnClick = val => {
    if (column.isReadonly) {
      return;
    }

    onPropChange({ [column.accessor]: column?.isNumberValue ? +val : val });
  };

  const labelOn = i18n.t(column && column.label && column.label.onLabel);
  const labelOff = i18n.t(column && column.label && column.label.offLabel);

  return (
    <div
      className={classNames('sectionItemSwitch', column.inlineType, { isInline: column.isInline })}
      style={{
        padding: column.padding,
          width: column.inlineWidth,
          maxHeight: column.maxHeight,
        justifyContent: column.centered ? 'center' : '',
        alignItems: column.alignItems || '',
        marginTop: column.marginTop ? column.marginTop : '',
      }}
    >
      <ColumnTitleWithIcon item={item} column={column} onPropChange={onPropChange} />
      <SwitchControl
        id={`${id || ''} ${column.accessor} ${column.title}`}
        value={value}
        typeColor={column && column.typeColor}
        title={{
          on: labelOn || t('settings.settings.numericalSettings.YES'),
          off: labelOff || t('settings.settings.numericalSettings.NO'),
        }}
        isNoRtl={column.isNoRtl}
        tooltipText={column.tooltipText}
        isReadonly={column && column.isReadonly}
        withLoader={column.withLoader}
        onClick={handleOnClick}
      />
    </div>
  );
};

export default withTranslation()(SectionItemSwitch);
