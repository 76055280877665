import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import User from "../../../services/user";

import MapMarkerOrderNumberIcon from "../MapMarkerOrderNumberIcon";

const MapMarkerOrderIcon = ({ id, marker, avatar, showOrderIdOnMap }) => {
    return (
        <div className={classNames("mapMarkerOrderIcon")}>
            {(!showOrderIdOnMap || !(marker || {}).orderId) && (<img alt={id} src={avatar} />)}
            {showOrderIdOnMap && (marker || {}).orderId && (<MapMarkerOrderNumberIcon value={marker} />)}
        </div>
    );
}

const mapStateToProps = state => ({
    showOrderIdOnMap: User.instance.getCurrentUserConfigurationProp("showOrderIdOnMap")
});

export default connect(mapStateToProps)(MapMarkerOrderIcon);