import environment from "environment";
import { INTERVAL_COURIER_LOCATIONS, PUSHER_DEPOT_CYCLE_REFRESH } from "../store/reducers/userModel/userModel-actions";
import {currentUserIsDepotRole, currentUserIsSupportOperatorRole} from "./userModel";
import {GET_ADMIN_ORDER_PENDING_COUNT} from "../store/reducers/order/order-actions.js";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Interval {
  _timer = [];

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use Interval.instance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Interval(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  init(currentStore) {
    this.store = currentStore;
  }

  start() {
    this._timer.forEach(x => clearInterval(x));

    if(currentUserIsDepotRole()) {
      this._timer.push(setInterval(() => {
        if(window.location.pathname === "/" && this.store) {
          this.store.dispatch({ type: PUSHER_DEPOT_CYCLE_REFRESH, additionalParams: { batched: true } });
        }
      }, environment.requestPendingOrdersTime));
    } else {
      this._timer.push(setInterval(
        () => this.store && this.store.dispatch({ type: INTERVAL_COURIER_LOCATIONS, additionalParams: { batched: true } }),
          environment.requestLocationTime
      ));
    }
    if(currentUserIsSupportOperatorRole()) {
      this._timer.push(setInterval(
          () => this.store && this.store.dispatch({ type: GET_ADMIN_ORDER_PENDING_COUNT, additionalParams: { batched: true } }),
          environment.requestPendingCount
      ));
    }
  }

  stop() {
    this._timer.forEach(x => clearInterval(x));
  }
}
