import MenuModel from './menuModel';

import {MARKETPLACE_SETTINGS_MODEL} from '../utils/enums';

export function createLoyaltyMenu() {
    return [
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.loyaltySettingsCashback,
            title: "settings.loyaltyCashback.MENU_LABEL",
            icon: "loyaltyCashback",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.loyaltySettingsCashback)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.loyaltySettingsRegistrationBonus,
            title: "settings.loyaltyRegisterBonus.MENU_LABEL",
            icon: "loyaltyVoucher",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.loyaltySettingsRegistrationBonus)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.giftCard,
            title: "settings.giftCard.MENU_LABEL",
            icon: "giftCard",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.giftCard)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.referral,
            title: "settings.referral.MENU_LABEL",
            icon: "referral",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.referral)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.campaigns,
            title: "settings.campaigns.MENU_LABEL",
            icon: "campaign",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.campaigns)
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
