import React from 'react';
import { useSelector } from 'react-redux';
import './index.scss';

import AssociatePopupControlCenter from '../AssociatePopupControlCenter';

const AssociatePopupControlCenters = ({ controlCenters, title, checked, handleChecked }) => {
  const isLoading = useSelector(state => state.sectionsWrapperData.isLoading);


  return (
        <div className="courierGroupContainer">
            <div className="headerGroup">
                <span>{title ? title : ''}</span>
                {isLoading && <div className={'loader'} />}
            </div>
            {(controlCenters || [])
                .filter(controlCenter => controlCenter.info && controlCenter.info.userId)
                .map(controlCenter => (
                    <AssociatePopupControlCenter
                        key={controlCenter.controlCenterId}
                        controlCenter={controlCenter}
                        checked={checked}
                        onChecked={handleChecked}
                    />
                ))}
        </div>
    );
};

export default AssociatePopupControlCenters;
