export const galleryModel = item => {
    const value = item || {};

    return {
        type: value.type || 0,
        file_settings: value.file_settings || {
            crop_mode: 0,
            gravity_mode: 0,
            quality: 90,
            quality_mode: 0
        },
        title: value.title || "",
        description: value.description || "",
        order: 0
    };
};