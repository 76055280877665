
import SectionModel from "../../models/sectionModel";
import { TELEGRAM_SETTING } from "../../store/reducers/restaurant/restaurant-actions";
import {CONTROL_TYPE, MODEL_CONTROL_TYPE, TYPE_SECTION_MODEL} from "../../utils/enums";
import {createControlModel} from "../controlModelFactory";
import {isMobileWidth} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createTelegramSettingsSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
        {
            title:"settings.telegramSettings.telegramSettings.REQUIRE_WAITING_TIME_LABEL",
            accessor: "requireWaitingTime",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.SHOW_MAP_LABEL",
            accessor: "showMap",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.NOTIFY_COURIER_DISPATCHED",
            accessor: "notifyCourierDispatched",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.ALLOW_ORDER_DELETION",
            accessor: "allowOrderDeletion",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.NOTIFY_DELIVERED_ORDER",
            accessor: "notifyDeliveredOrder",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.ENABLE_PHONE_QUERY",
            accessor: "enablePhoneQuery",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.ALLOW_ORDER_DELAY",
            accessor: "allowOrderDelay",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.ALLOW_ORDER_VIP",
            accessor: "allowOrderSetVIP",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.HIDE_READY_BUTTON",
            accessor: "hideReadyButton",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.REQUIRE_PHONE_NUMBER",
            accessor: "requirePhoneNumberOnOrderCreation",
            isEditable: true,
            type: CONTROL_TYPE.switch,
            isInline: true,
        },
        {
            title:"settings.telegramSettings.telegramSettings.WAITING_TIMES",
            accessor: "waitingTimes",
            isEditable: true,
            isNumber: true,
            type: CONTROL_TYPE.arrayItems
        }
    ],
    actionName: TELEGRAM_SETTING,
    initNewItemModel: item => item,
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: TYPE_SECTION_MODEL.telegramSettings,
    saveControlsConfig: {
      cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
    },
    controls: [
      createControlModel({
          type: MODEL_CONTROL_TYPE.back,
          isShow: isMobileWidth,
      }),
    ],
    label: "settings.telegramSettings.LABEL",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
    ],
    mappingLambda: state => ({
      id: "telegramsettings",
      ...state.restaurant.data.telegramConfiguration,
      waitingTimesSelected: []
    })
  });
}
