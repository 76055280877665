
import SectionModel from "../../models/sectionModel";
import { ORDERING_MENU_GET, ORDERING_MENU_BUSINESS_SCHEDULE } from "../../store/reducers/restaurant/restaurant-actions";
import {
  ORDERING_MENU_TYPE_SECTION_MODEL,
  CONTROL_TYPE,
  MODEL_TYPE,
  MODEL_CONTROL_TYPE, TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import { getInitModel } from "../initModels";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import {getDepotNameById } from "../restaurant";
import {createControlModel, filtrateControlByDepotRole} from "../controlModelFactory";

export function createBusinesScheduleSection({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.orderingmenu.MENU_BUSINESS_SHEDULE_LAPSE_LABEL",
        accessor: "schedule",
        isEditable: true,
        isFillContent: true,
        type: CONTROL_TYPE.schedule
      }
    ],
    actionLoad: ORDERING_MENU_GET,
    actionName: ORDERING_MENU_BUSINESS_SCHEDULE,
    initNewItemModel: item => {
      return {
        ...(item || {}),
        schedule: getInitModel(MODEL_TYPE.scheduleModel, item && item.schedule)
      };
    },
    typeAction: args.typeAction,
    typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule,
    parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
    stayAfterSave: true,
    saveControlsConfig: {
      cancel: {
        action: (item, history)=> {
          history.push(ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule + history.location.search);
        }
      },
      save: {
        action: (item, history)=> {
          history.push(ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule + history.location.search);
        }
      }
    },
    controls: filtrateControlByDepotRole([
      createControlModel({
        type: MODEL_CONTROL_TYPE.back
      }),
    ]),
    label: "settings.orderingmenu.MENU_BUSINESS_SHEDULE",
    breadcrumbs: [
      createBreadcrumbModel({
        label: "settings.map.DEPOT_SETTINGS_LABEL"
      }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return getDepotNameById(id);
        }
      })
    ],
    mappingLambda: state => state.restaurant.data.businessMenu || {}
  });
}
