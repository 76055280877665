import { UPDATE_TOOLTIP_DATA } from "./tooltipPortal-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
  position: null,
  tooltipText: ""
};

export default function tooltipPortal(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case UPDATE_TOOLTIP_DATA:
      if(action.data.event) {
        const e = action.data.event.toElement || action.data.event.relatedTarget;
        if(!e) return initState;
        if (e.parentNode === action.data.context || e === action.data.context) return state;
      }
      return {
          ...state,
          ...action.data
      };
    default:
      return state;
  }
}
