import { MENU_PRIORITY_UPDATE, TOGGLE_NESTED_MENU } from "./menu-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
  menuHeaderPriority: null,
  isNestedMenuOpen: false,
};

export default function menu(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA: {
      return initState;
    }
    case MENU_PRIORITY_UPDATE:
      return {
        ...state,
        menuHeaderPriority: action.data
      };
    case TOGGLE_NESTED_MENU:
      return {
        ...state,
        isNestedMenuOpen: action.isOpen
      };
    default:
      return state;
  }
}
