import { MODEL_TYPE } from "../utils/enums";

export function shiftModel(shift) {
  const data = shift || {};
  const region = {
    advancePayment: data.advancePayment || 0,
    controlCenterId: data.controlCenterId || "",
    courierName: data.courierName || "",
    distanceTraveled: data.distanceTraveled || 0,
    endDate: data.endDate || null,
    notes: data.notes || "",
    restaurantName: data.restaurantName || "",
    shiftId: data.shiftId || "",
    shiftType: data.shiftType || 0,
    startDate: data.startDate || "",
    timeSpent: data.timeSpent || 0,
    totalUnloadTime: data.totalUnloadTime || 0,
    vehiclePlateId: data.vehiclePlateId || null,
    modelType: MODEL_TYPE.shifts
  };

  return data ? { ...region, ...data } : region;
}
