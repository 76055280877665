import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import {
  getExternalCycleLogo,
  getExternalCyclePriceInfo,
  getPendingOrdersContainerConfig,
} from '../../../services/restaurant.js';
import {PENDING_ORDERS_FILTER_TYPE, VEHICLE_TYPE} from '../../../utils/enums.js';
import {withTranslation} from 'react-i18next';
import {Avatar} from '../Avatar/Avatar.jsx';
import VehicleIcon from '../VehicleIcon/VehicleIcon.jsx';
import {getProviderName, getUserByGroupId,} from '../../../services/userModel.js';
import TimeBox from '../TimeBox/TimeBox.jsx';

const AssociatePopupExternalAssignment = ({
  t,
  checked,
  handleChecked,
  orderData,
  users,
  externalCycles,
  providers,
  extendableCyclesId
}) => {
  const { firstContainerItems } = getPendingOrdersContainerConfig(
    PENDING_ORDERS_FILTER_TYPE.ready,
    orderData,
    users,
    externalCycles
  );

  const [extendableCycles, setExtendableCycles] = useState([]);

  useEffect(() => {
    let field = ''
    let cycles = []

    if(firstContainerItems?.length) {
      field = 'courier_id'
      cycles = firstContainerItems
    } else if (externalCycles?.length) {
      field = 'userId'
      cycles = externalCycles
    }

    const value = cycles.filter(item => extendableCyclesId?.find(id => id === item[field]));

    const uniqueCycles = Array.from(new Set(value?.map(a => a[field])))
      .map(id => {
        return value.find(a => a[field] === id)
      })

    setExtendableCycles(uniqueCycles);
    // eslint-disable-next-line
  }, [extendableCyclesId]);

  const isLoading = useSelector(state => state.sectionsWrapperData.isLoading);

  return (
    <div
      className={classNames('associatePopupExternalAssignment', { hide: !extendableCycles || !extendableCycles?.length })}
    >
      <div className='headerGroup'>
        <span>{t('dashboard.couriersbar.EXTERNAL_CYCLE_ASSIGNMENT_GROUP_LABEL')}</span>
        {isLoading && <div className={'loader'} />}
      </div>
      {extendableCycles.map(extendableCycle => {
        const isExternal = !!extendableCycle?.external_provider_id;
        const courier = isExternal
          ? providers.find(f => f.id === extendableCycle?.external_provider_id)
          : providers.find(f => f.id === extendableCycle?.providerId)
        const deliveredBy = getProviderName(courier)
        const courierAvatar = getExternalCycleLogo(courier)

        const courierInfo = externalCycles.find(f => f.id === extendableCycle.courier_id) ||
          getUserByGroupId(users, extendableCycle.courier_id);

        const userInfo = externalCycles.find(f => f.id === extendableCycle.userId) ||
          getUserByGroupId(users, extendableCycle.userId);

        const price = getExternalCyclePriceInfo(courierInfo || userInfo);

        const id = extendableCycle?.courier_id ||  extendableCycle?.userId

        // const depotName = (group) => {
        //   if(!group) return "";
        //   const state = Store.store.getState();
        //   const orders = state.order.data.orders.filter(f => f.groupId === group.id);
        //
        //   return '/ ' + getDepotName(orders[0])
        // }

        return (
          <div
            key={`${extendableCycle.name}-${extendableCycle.id}`}
            className='courierGroupItem'
            onClick={e => handleChecked(extendableCycle?.id, e, false, id, true)}
          >
            <Avatar avatar={courierAvatar} hideIconColor />
            <div className='courierInfo'>
              <VehicleIcon value={VEHICLE_TYPE.car} isActive={true} />
              &nbsp;&nbsp;
              {deliveredBy}
              {/*<div className="inline">&nbsp;{depotName(extendableCycle)}</div>*/}
            </div>
            {price && (<span className="price">&nbsp;{price}&nbsp;</span>)}

            {courierInfo?.approveValidity && (
              <div className={classNames("info")}><TimeBox date={courierInfo?.approveValidity} /></div>
            )}

            {userInfo?.approveValidity && (
              <div className={classNames("info")}><TimeBox date={userInfo?.approveValidity} /></div>
            )}

            <div className='courierCheck'>
              <input
                type='checkbox'
                id={`checkbox-${extendableCycle?.id}`}
                name='check'
                checked={checked === extendableCycle?.id}
                onChange={e => handleChecked(extendableCycle?.id, e, false, id, true)}
              />
              <label htmlFor={`checkbox-${extendableCycle?.name}`} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    externalCycles: state.externalCycles.data || [],
    users: state.userModel.data || [],
    orderData: state.order.data || {},
    providers: ((state.restaurant || {}).data || {}).ordersProvider || [],
  };
};

export default connect(mapStateToProps)(withTranslation()(AssociatePopupExternalAssignment));
