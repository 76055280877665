import {SET_MARKETPLACE_PAGES, SET_MESSAGES, SET_PRODUCTS} from "./marketplace-actions";
import {
  ADD_MARKETPLACE_MESSAGE,
  ADD_MARKETPLACE_PAGES,
  DELETE_MARKETPlACE_MESSAGE,
  DELETE_MARKETPLACE_PAGE,
  UPDATE_MARKETPLACE_MESSAGE,
  UPDATE_MARKETPLACE_MESSAGE_GALLERY,
  UPDATE_MARKETPLACE_MESSAGE_GALLERY_ADD,
  UPDATE_MARKETPLACE_PAGE
} from "../restaurant/restaurant-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
  data: {
    messages: [],
    pages: [],
    products: []
  },
};

export default function marketplace(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case SET_PRODUCTS:
       return {
         ...state,
         data: {
           ...state.data,
           products: action.data
         }
      };
    case SET_MESSAGES:
      return {
        ...state,
        data: {
          ...state.data, message: action.data
        }
      }
    case ADD_MARKETPLACE_MESSAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          message: [...state.data.message, action.data]
        }
      }
    }
    case UPDATE_MARKETPLACE_MESSAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          message: state.data.message
          .map(marketplace => marketplace.id === action.data.id ? {...action.data} : marketplace)
        }
      }
    }
    case UPDATE_MARKETPLACE_MESSAGE_GALLERY: {
      return {
        ...state,
        data: {
          ...state.data,
          message: state.data.message.map(marketplace => marketplace.id === action.data.messageId
              ? {...marketplace, gallery: marketplace.gallery.map(g => g.id === action.data.data.id
                    ? {...action.data.data}
                    : g
                )}
              : marketplace
          )
        }
      }
    }
    case UPDATE_MARKETPLACE_MESSAGE_GALLERY_ADD: {
      return {
        ...state,
        data: {
          ...state.data,
          message: state.data.message.map(marketplace => marketplace.id === action.data.messageId
              ? {...marketplace, gallery: (marketplace.gallery || []).concat(action.data.data)}
              : marketplace
          )
        }
      }
    }
    case DELETE_MARKETPlACE_MESSAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          message: state.data.message
            .filter(m => m.id !== action.id)
        }
      }
    }
    case SET_MARKETPLACE_PAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          pages: action.data
        }
      }
    }
    case ADD_MARKETPLACE_PAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          pages: [...state.data.pages, action.data]
        }
      }
    }
    case UPDATE_MARKETPLACE_PAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data.pages.map(page => page.id === action.data.id ? {...action.data} : page)
        }
      }
    }
    case DELETE_MARKETPLACE_PAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data.pages.filter(page => page.id !== action.id)
        }
      }
    }
    default:
      return state;
  }
}
