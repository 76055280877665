import {PROVIDERS_TYPE_MODEL} from "../utils/enums.js";

export const providersModel = (item = {}) => {
    if(!item || !item.provider) {
        return {provider: null}
    }
    switch(item.provider) {
        case PROVIDERS_TYPE_MODEL.Yango:
            return {
                provider: (item && item.provider) ? item.provider : "",
                host: (item && item.host) ? item.host : "",
                correctionThresholdInMeter: (item && item.correctionThresholdInMeter) ? item.correctionThresholdInMeter : "",
                emergencyContactName: (item && item.emergencyContactName) ? item.emergencyContactName : "",
                emergencyContactPhone: (item && item.emergencyContactPhone) ? item.emergencyContactPhone : "",
                token: (item && item.token) ? item.token : "",
                useOrderId: (item && item.useOrderId) ? item.useOrderId : false,
                useYangoGeolocation: (item && item.useYangoGeolocation) ? item.useYangoGeolocation : false,
            }
        case PROVIDERS_TYPE_MODEL.Wolt:
            return {
                provider: (item && item.provider) ? item.provider : "",
                userOrderId: (item && item.userOrderId) ? item.userOrderId : "",
                merchantId: (item && item.merchantId) ? item.merchantId : "",
                supportEmail: (item && item.supportEmail) ? item.supportEmail : "",
                supportPhone: (item && item.supportPhone) ? item.supportPhone : "",
                host: (item && item.host) ? item.host : "",
            }
        case PROVIDERS_TYPE_MODEL.DoorDash:
            return {
                provider: (item && item.provider) ? item.provider : "",
                developerId: (item && item.developerId) ? item.developerId : "",
                keyId: (item && item.keyId) ? item.keyId : "",
                signingSecret: (item && item.signingSecret) ? item.signingSecret : "",
            }
        case PROVIDERS_TYPE_MODEL.Sendiman:
            return {
                provider: (item && item.provider) ? item.provider : "",
                user: (item && item.user) ? item.user : "",
                password: (item && item.password) ? item.password : "",
                testMode: (item && item.testMode) ? item.testMode : "",
                isInDebugMode: (item && item.isInDebugMode) ? item.isInDebugMode : "",
            }
        case PROVIDERS_TYPE_MODEL.Haat:
            return {
                provider: (item && item.provider) ? item.provider : "",
                testMode: (item && item.testMode) ? item.testMode : ""
            }
        case PROVIDERS_TYPE_MODEL.Gett:
            return {
                provider: (item && item.provider) ? item.provider : "",
                businessId: (item && item.businessId) ? item.businessId : "",
                clientId: (item && item.clientId) ? item.clientId : "",
                clientSecret: (item && item.clientSecret) ? item.clientSecret : "",
                productId: (item && item.productId) ? item.productId : ""
            }
        default:
            return { }
    }
}
