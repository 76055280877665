import { UPDATE_PORTAL_MAP_DATA } from "./portalMap-actions";
import { REMOVE_ALL_DATA } from "../common-actions";
import { locationIsEmpty } from "../../../services/order";

const initState = {
  markers: [],
  center: { lat: 0, lng: 0 },
  region: null,
  circle: null,
  cursor: null,
  isHidePolygon: false,
  isReadonly: false,
  renderPolygonsFromBusiness: false,
  alwaysShowPolygons: false,
  zoom: null,
  boundsByRegion: false,
  boundsByCircle: false,
};

export default function portalMap(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case UPDATE_PORTAL_MAP_DATA:
      const { isInit, markers, center, region, circle, cursor, isHidePolygon, isReadonly, renderPolygonsFromBusiness, zoom, boundsByRegion, boundsByCircle, alwaysShowPolygons } = action.data;
      return isInit
        ? initState
        : {
            ...state,
            markers: (markers || []).filter(marker => marker && !locationIsEmpty(marker.location)) || [],
            center: center || null,
            region: region || null,
            circle: circle || null,
            cursor: cursor || null,
            isHidePolygon, 
            isReadonly,
            renderPolygonsFromBusiness,
            alwaysShowPolygons,
            zoom,
            boundsByRegion,
            boundsByCircle,
          };
    default:
      return state;
  }
}
