import React, {useEffect, useRef, useState} from "react";
import IconButton from '../IconButton';
import "./style.scss";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {useOnClickOutside} from "../../../services/hooks.js";
import CheckBox from "../CheckBox/index.js";

const DropdownMultiSelect = ({values, selectedValues, placeholderEmpty, placeholderMulti, defaultPlaceholderValues,byValue, isMultiMode , onApply}) => {
    const targetRef = useRef();
    useOnClickOutside(targetRef, () => {
        setOpen(false);
    });
    const defaultMultiMode = isMultiMode ||  selectedValues.length > 1

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [multiMode, setMultiMode] = useState(defaultMultiMode);
    const [temporarySelected, setTemporarySelected] = useState(selectedValues || []);

    useEffect(() => {
        if(JSON.stringify(selectedValues) === JSON.stringify(temporarySelected)) {
            return;
        }
        setTemporarySelected(selectedValues);
        setMultiMode(defaultMultiMode);

        // eslint-disable-next-line
    }, [selectedValues]);

    useEffect(() => {
        if(!multiMode) {
            setTemporarySelected([]);
        }
    }, [multiMode]);

    const handleOnApply = (value) => {
        setFilter('');
        onApply(value || temporarySelected);
        setOpen(false);
    }

    const handleOnCancel = () => {
        setFilter('');
        setOpen(false);
        setMultiMode(defaultMultiMode);
        setTemporarySelected(selectedValues);
    }

    const selectAll = () => {
        setTemporarySelected(values.map(v => v.value));
    }

    const deselectAll = () => {
        setTemporarySelected([]);
    }

    const handleOnSelect = (item) => {
        const exist = temporarySelected.includes(item);
        if(multiMode) {
            if(exist) {
                setTemporarySelected(values => values.filter(value => value !== item));
            } else {
                setTemporarySelected(values => values.concat(item));
            }
        } else {
            if(!exist) {
                setTemporarySelected([item]);
                handleOnApply([item]);
            }
        }
    }

    const isaValueSelected = value => {
        return temporarySelected.includes(value);
    }

    const getPlaceholder = () => {
        if(selectedValues.length === 1) {
            const value = values.find(v => selectedValues[0] === v.value);
            return value ? byValue ? value.value : value.key : placeholderEmpty;
        }
        if(selectedValues.length >= 2) {
            return placeholderMulti;
        }
        if(defaultPlaceholderValues) {
            return defaultPlaceholderValues;
        }

        return placeholderEmpty;
    }

    return (
        <div ref={targetRef} className={classnames('dropdownMultiSelect', {open})}>
            <div className={'header'} onClick={() => setOpen(true)}>
                {open
                    ? <input
                        type='text'
                        className={'filter'}
                        value={filter}
                        onChange={({target}) => setFilter(target.value)}
                        placeholder={placeholderEmpty}
                    />
                    : <div className={'placeholder'}>{getPlaceholder()}</div>
                }
                <IconButton isIcon iconName={'smallArrow'}/>
            </div>

            <div className={'dropdownMenu'}>
                {!multiMode && (
                    <div className={'multiToggle btn'} onClick={() => setMultiMode(true)}>
                        {t('controlCenters.ALLOW_MULTISELECT')}
                    </div>
                )}
                {multiMode && temporarySelected.length !== values.length && (
                    <div className={'multiToggle btn'} onClick={selectAll}>
                        {t('controlCenters.SELECT_ALL')}
                    </div>
                )}
                {multiMode && temporarySelected.length === values.length && (
                    <div className={'multiToggle btn'} onClick={deselectAll}>
                        {t('controlCenters.DESELECT_ALL')}
                    </div>
                )}

                <div className={'list'}>
                    {values?.filter(item => item?.key?.toLowerCase().includes(filter?.toLowerCase())).map(item => (
                        <div
                            className={classnames('listItem')}
                            key={item.value}
                            onClick={() => handleOnSelect(item.value)}
                        >
                            {multiMode && (
                                <CheckBox
                                    label={' '}
                                    onChange={() => handleOnSelect(item.value)}
                                    id={'multi-control-center'}
                                    value={isaValueSelected(item.value)}
                                />
                            )}
                            {byValue ? item.value : item.key}
                        </div>
                    ))}
                </div>
                {multiMode && (
                    <div className={'footerButtons'}>
                        <div className={'btn main'} onClick={() => handleOnApply()}>{t('basic.APPLY_BUTTON')}</div>
                        <div className={'btn'} onClick={handleOnCancel}>{t('basic.CANCEL_BUTTON')}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DropdownMultiSelect;
