export default class SectionModel {
    constructor({
        filterOptions = {},
        columns = [],
        tag = null,
        key = 'id',
        sortByFunc,
        actionName,
        initNewItemModel,
        typeAction,
        urlNewItem,
        showDeleteButton = true,
        isPasswordConfirm,
        stayAfterSave,
        stayAfterApprove,
        footer,
        typeModel,
        parentUrl,
        controls,
        triggerControls,
        tabsControls,
        tableMinWidth,
        isHideControls,
        mappingLambda,
        actionLoad,
        actionOnUnmount,
        saveControlsConfig,
        controlSettings,
        rangePickerAction,
        isCustomEditIcon,
        editControlsConfig,
        isDraggable,
        dragActionName,
        dragSortPropName = 'order',
        editAction,
        isLayoutForm,
        tabs,
        hideCellForSort,
        hideHeaderItemControls,
        tableHeaderControlsWidth,
        editCheckExcludedProps,
        isHideSaveButton,
        isShowSaveConfirmDialog,
        disableGlobalKeyDown,
        businessId,
        checkActiveButtonFromModel,
        markModelAsChanged,
        isMarkedModelAsChanged,
        isForceUpdate,
        stayAfterSaveFn,
        title,
        label,
        breadcrumbs,
        additionalInformation,
        getLabel,
        actionPagination,
        disabledIfEmpty,
        isNumberValue,
        withSearch,
        searchDataId,
        controlSelect,
        shouldCallActionFromSideMenu,
        showNeedSave,
        skipUpdateFilterOptions,
        fixScroll,
    }) {
        this.filterOptions = filterOptions;
        this.columns = columns;
        this.tag = tag;
        this.key = key;
        this.actionName = actionName;
        this.initNewItemModel = initNewItemModel;
        this.typeAction = typeAction;
        this.urlNewItem = urlNewItem;
        this.showDeleteButton = showDeleteButton;
        this.isPasswordConfirm = isPasswordConfirm;
        this.stayAfterSave = stayAfterSave;
        this.stayAfterApprove = stayAfterApprove;
        this.footer = footer;
        this.typeModel = typeModel;
        this.tableMinWidth = tableMinWidth;
        this.parentUrl = parentUrl;
        this.controls = controls || [];
        this.triggerControls = triggerControls || [];
        this.tabsControls = tabsControls || [];
        this.isHideControls = isHideControls;
        this.actionLoad = actionLoad;
        this.actionOnUnmount = actionOnUnmount;
        this.saveControlsConfig = saveControlsConfig;
        this.controlSettings = controlSettings;
        this.rangePickerAction = rangePickerAction;
        this.editControlsConfig = editControlsConfig;
        this.isCustomEditIcon = isCustomEditIcon;
        this.isDraggable = isDraggable;
        this.dragActionName = dragActionName;
        this.dragSortPropName = dragSortPropName;
        this.isLayoutForm = isLayoutForm;
        this.editAction = editAction;
        this.tabs = tabs;
        this.hideCellForSort = hideCellForSort;
        this.hideHeaderItemControls = hideHeaderItemControls;
        this.tableHeaderControlsWidth = tableHeaderControlsWidth;
        this.editCheckExcludedProps = editCheckExcludedProps;
        this.isHideSaveButton = isHideSaveButton;
        this.isShowSaveConfirmDialog = isShowSaveConfirmDialog;
        this.disableGlobalKeyDown = disableGlobalKeyDown;
        this.businessId = businessId;
        this.checkActiveButtonFromModel = checkActiveButtonFromModel;
        this.markModelAsChanged = markModelAsChanged;
        this.isMarkedModelAsChanged = isMarkedModelAsChanged;
        this.searchDataId = searchDataId;
        this.isForceUpdate = isForceUpdate;
        this.stayAfterSaveFn = stayAfterSaveFn;
        this.title = title;
        this.label = label;
        this.breadcrumbs = breadcrumbs || null;
        this.additionalInformation = additionalInformation;
        this.actionPagination = actionPagination;
        this.isNumberValue = isNumberValue;
        this.withSearch = withSearch;
        this.controlSelect = controlSelect;
        this.shouldCallActionFromSideMenu = shouldCallActionFromSideMenu;
        this.skipUpdateFilterOptions = skipUpdateFilterOptions;
        this.fixScroll = fixScroll;

        if (sortByFunc) this.sortByFunc = sortByFunc.bind(this);
        if (mappingLambda) this.mappingLambda = mappingLambda.bind(this);
        if (disabledIfEmpty) this.disabledIfEmpty = disabledIfEmpty.bind(this);
        if (getLabel) this.getLabel = getLabel.bind(this);
        if (shouldCallActionFromSideMenu) this.shouldCallActionFromSideMenu = shouldCallActionFromSideMenu.bind(this);
        if (showNeedSave) this.showNeedSave = showNeedSave.bind(this);
    }

    setFilterOption = (key, prop, value) => {
        if (this.filterOptions.options[key]) {
            this.filterOptions.options[key][prop] = value;
        }
    };

    getFilterOptionPropValue = (key, prop) => {
        return this.filterOptions.options && this.filterOptions.options[key]
            ? this.filterOptions.options[key][prop]
            : null;
    };
}
