import { MODEL_TYPE } from "../utils/enums";
import { getRegions } from "../services/restaurant";

export function regionDeliveryPriceModel(data) {
  const regions = getRegions();
  const region = regions.find(f => f.id === data.regionId);

  return {
    id: (region && region.id) || data.regionId || "",
    regionId: (region && region.id) || data.regionId || "",
    name: (region && region.name) || "",
    deliveryPrice: 0,
    modelType: MODEL_TYPE.regionDeliveryPriceModel
  };
}
