import { call, put, takeEvery, select, all } from "redux-saga/effects";
import {
  LOAD_MARKERS,
  LOAD_ORDER_MARKERS,
  LOAD_COURIER_MARKERS,
  actionLoadOrderMarkers,
  actionUpdateCourierMarker,
  actionSetShakeMarker,
  SHAKE_MARKER,
  actionRemoveMarkers,
  actionUpdateOrdersMarkers,
  actionUpdateAllCouriersMarkers,
  CHECK_MARKERS,
  actionRemoveMarker,
  actionUpdateDepotsMarkers
} from "../reducers/marker/marker-actions";
import { actionLoadUserMarkers } from "../reducers/userModel/userModel-actions";
import { getOrders, getGroups, getUserModel, getMarkers, getRestaurant, getRestaurantDepots } from "../selectors";
import { currentUserIsDepotRole } from "../../services/userModel";
import { TYPE_MARKER } from "../../utils/enums";

export function* markersSaga(action) {
  try {
    const { isLoadUserMarkers } = action.data || {}
    const orders = yield select(getOrders);
    const groups = yield select(getGroups);
    const users = yield select(getUserModel);
    const depots = yield select(getRestaurantDepots);
    const actions = [];

    if (currentUserIsDepotRole()) {
      actions.push(put(actionRemoveMarkers()));
    }

    yield put(actionLoadOrderMarkers({ groups, orders, users }));

    if(isLoadUserMarkers) {
      actions.push(put(actionLoadUserMarkers({ users, orders })));
    }
    
    if(depots.length) {
      actions.push(put(actionUpdateDepotsMarkers(depots)));
    }

    yield all(actions);

    return true;
  } catch (e) {
    console.log("Error markers loading");
  }
}

function* fetchOrderMarkersSaga(data) {
  const { orders, users, groups } = data.data;
  const restaurant = yield select(getRestaurant);
  const depots = restaurant.depots || [];
  const depotsMap = restaurant.depotsMap || new Map();
  const markerGroupsOrders = (orders || []).reduce((accumulator, order) => {
    if(order && !order.isExternalGroup) {
      const groupIndex = accumulator.map(m => m.group.id).indexOf(order.groupId);

      if (groupIndex === -1) {
        const group = groups.find(f => f.id === order.groupId);  
        if(group) {
          accumulator.push({
            group,
            groupOrders: [order],
            user: users.find(f => f.userId === order.groupId)
          });
        }        
      } else {
        accumulator[groupIndex] = {
          ...accumulator[groupIndex],
          groupOrders: accumulator[groupIndex].groupOrders.concat(order)
        };
      }
    }    

    return accumulator;
  }, []);

  yield put(actionUpdateOrdersMarkers({ items: markerGroupsOrders, depots, depotsMap }));
}

function* fetchCourierMarkersSaga(data) {
  yield put(actionUpdateAllCouriersMarkers(data.data));
}

function* shakeMarkerSaga(data) {
  const delay = time => new Promise(resolve => setTimeout(resolve, time));
  yield put(actionSetShakeMarker({ id: data.data }));
  yield call(delay, 550);
  yield put(actionSetShakeMarker({ id: null }));
}

function* checkMarkersSaga() {
  const markers = yield select(getMarkers);
  const orders = yield select(getOrders);
  const orderMarkerIds = markers
    .filter(f=> f.markerType === TYPE_MARKER.order)
    .map(marker=> marker.id);
  const ids = orderMarkerIds
    .filter(id=> !orders.find(order=> order.id === id));
    
  if(ids.length) {
    yield put(actionRemoveMarker(ids));
  }
}

export function* fetchCourierMarkerSaga(data) {
  yield put(actionUpdateCourierMarker(data.data));
}

export default function*() {
  yield takeEvery(LOAD_MARKERS, markersSaga);
  yield takeEvery(LOAD_ORDER_MARKERS, fetchOrderMarkersSaga);
  yield takeEvery(LOAD_COURIER_MARKERS, fetchCourierMarkersSaga);
  yield takeEvery(SHAKE_MARKER, shakeMarkerSaga);
  yield takeEvery(CHECK_MARKERS, checkMarkersSaga);
}
