import SectionModel from '../../models/sectionModel';
import i18n from '../../i18n';
import {
    DEPOT_OPERATION_MODE_SETTING,
    DEPOT_OPERATION_MODE_SETTING_GET,
    DEPOT_OPERATION_MODE_SETTING_GET_ALL,
    DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE,
    DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_RESET,
} from '../../store/reducers/restaurant/restaurant-actions';
import {
    CONTROL_TYPE,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
    TYPE_SECTION_DEPOT_MODEL,
    TYPE_SECTION_MODEL,
} from '../../utils/enums';
import { createControlModel } from '../controlModelFactory';
import { getDepotNameById, getOperationsModeForDepot, isMobileWidth,getRestaurantPaymentEnabled, hasDepotBusinessMenu } from '../restaurant';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import FilterOptionsModel from '../../models/filterOptionsModel';
import { getInitModel } from '../initModels';
import { getHistoryLocationParams } from '../../utils/routesHelper';
import { getOperationModeSectionFilter } from '../filter';

export const createDepotOperationTimingEditSectionModel = ({ ...args }) => {
    return new SectionModel({
        columns: [
            {
                title: "settings.operationTiming.NAME",
                accessor: "name",
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: "settings.operationTiming.MAX_DELIVERY_TIME",
                accessor: "maxDeliveryTime",
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: "settings.operationTiming.WAITING_TIMES",
                accessor: "waitingTimes",
                isEditable: true,
                type: CONTROL_TYPE.arrayItems,
            },
            {
                title: "settings.operationTiming.MARKETPLACE_WAITING_TIMES",
                accessor: "marketplaceWaitingTimes",
                isEditable: true,
                type: CONTROL_TYPE.arrayItems,
                isShow: () => getRestaurantPaymentEnabled() && hasDepotBusinessMenu(),
            }
        ],
        actionLoad: DEPOT_OPERATION_MODE_SETTING_GET,
        actionName: DEPOT_OPERATION_MODE_SETTING,
        initNewItemModel: item => item || getInitModel(MODEL_TYPE.operationTiming),
        typeAction: args.typeAction,
        showDeleteButton: true,
        typeModel:  args.typeAction === TYPE_ACTION.edit ? TYPE_SECTION_DEPOT_MODEL.operationModeEdit : TYPE_SECTION_DEPOT_MODEL.operationModeNew,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        key: "name",
        parentUrl: TYPE_SECTION_DEPOT_MODEL.operationMode,
        getLabel: () => {
            const params = getHistoryLocationParams(window.location.search)
            return params.id;
        },
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(parentId);
                },
            }),
            createBreadcrumbModel({ label: "settings.operationTiming.LABEL" })
        ],
        mappingLambda: state => {
            const params = getHistoryLocationParams(window.location.search || args?.params);
            const depot = state?.restaurant?.data?.depots?.find(d => d.id === (params?.parentId || params?.id));
            const profiles = depot?.operationConfiguration?.profiles?.map(p => ({
                ...p,
                id: p.name
            }))

            return profiles?.find(profile => profile?.id.trim() === params?.id)
        },
    });
}

export const createDepotOperationTimingSectionModel = ({ ...args }) => {
    const { id } = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        filterOptions: createDepotOperationModeSectionFilter(),
        columns: [
            {
                title: "settings.operationTiming.NAME",
                accessor: "name",
            },
            {
                title: "settings.operationTiming.MAX_DELIVERY_TIME",
                accessor: "maxDeliveryTime",
                width: "25%",
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_DEPOT_MODEL.operationModeEdit,
                iconName: "editItem",
                tooltipText: i18n.t("settings.operationTiming.EDIT_TOOLTIP")
            }
        ],
        tabs: [
            {
                title: "settings.operationTiming.LABEL",
                typeModel: TYPE_SECTION_DEPOT_MODEL.operationMode,
                search: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return `?id=${id}`
                },
            },
            {
                title: "settings.operationTiming.OPERATION_DEPOT_ACTIVE",
                typeModel: TYPE_SECTION_DEPOT_MODEL.operationModeActive,
                search: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return `?id=${id}`
                },
            },
        ],
        key: "name",
        typeModel: TYPE_SECTION_DEPOT_MODEL.operationMode,
        parentUrl: TYPE_SECTION_MODEL.depots,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_DEPOT_MODEL.operationModeNew + `?parentId=${id}`,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: "settings.map.DEPOT_SETTINGS_LABEL",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        showDeleteButton: true,
        isForceUpdate: true,
        tableMinWidth: 700,
        label: "settings.operationTiming.LABEL",
        actionName: DEPOT_OPERATION_MODE_SETTING,
        actionLoad: DEPOT_OPERATION_MODE_SETTING_GET,
        mappingLambda: state => {
            const { id, parentId } = getHistoryLocationParams(window.location.search);
            const depot = (state.restaurant.data.depots || []).find(d => d.id === (parentId || id));
            return ((depot?.operationConfiguration || {}).profiles || []).map(p => ({
                ...p,
                id: p.name
            }))
        },
    })
}

function createDepotOperationModeSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(item) {
        return getOperationModeSectionFilter(this.selected, item);
    });

    return filterOptionsModel;
}

export const createDepotOperationTimingActiveSelectSectionModel = ({ ...args }) => {
    return new SectionModel({
        columns: [
            {
                title: "settings.operationTiming.ACTIVE_PROFILE",
                accessor: "activeProfile",
                type: CONTROL_TYPE.select,
                isEditable: true,
                connect: state => {
                    const params = getHistoryLocationParams(window.location.search || args.params);
                    return {
                        values: getOperationsModeForDepot(params.parentId || params.id, state),
                    }
                }
            },
            {
                title: "settings.operationTiming.OPERATION_DEPOT_ACTIVE_RESET",
                accessor: "defaultProfile",
                type: CONTROL_TYPE.button,
                label: "settings.operationTiming.OPERATION_DEPOT_ACTIVE_RESET",
                clickAction: DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_RESET,
            },
        ],
        initNewItemModel: item => {
            return {
                activeProfile: ((item.operationConfiguration || {}).activeProfile || ""),
                id: item.id || "",
            };
        },
        tabs: [
            {
                title: "settings.operationTiming.LABEL",
                typeModel: TYPE_SECTION_DEPOT_MODEL.operationMode,
                search: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return `?id=${id}`
                },
            },
            {
                title: "settings.operationTiming.OPERATION_DEPOT_ACTIVE",
                typeModel: TYPE_SECTION_DEPOT_MODEL.operationModeActive,
                search: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return `?id=${id}`
                },
            },
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: TYPE_SECTION_DEPOT_MODEL.operationModeActive,
        parentUrl: TYPE_SECTION_MODEL.depots,
        label:  i18n.t("settings.operationTiming.OPERATION_DEPOT_ACTIVE"),
        actionName: DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE,
        actionLoad: DEPOT_OPERATION_MODE_SETTING_GET_ALL,
        breadcrumbs: [
            createBreadcrumbModel({
                label: "settings.map.DEPOT_SETTINGS_LABEL",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        mappingLambda: state => {
            const restaurant = state.restaurant.data || {};
            return (restaurant.depots || [])
                .map(depot => ({
                    id: depot?.id,
                    operationConfiguration: depot?.operationConfiguration,
                }));
        },
    });
}
