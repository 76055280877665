import i18n from "../../i18n";

import SectionModel from "../../models/sectionModel";

import {
  ORDERING_MENU_GET,
  ORDERING_MENU_BUSINESS_INFO,
  BUSINESS_CATEGORY,
  BUSINESS_CATEGORY_GET,
  MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING_EDIT,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
  ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
  CONTROL_TYPE,
  MODEL_TYPE,
  TYPE_SWITCH_CONTROL_COLOR,
  MODEL_CONTROL_TYPE,
  MARKETPLACE_SETTINGS_MODEL,
  TYPE_SECTION_DEPOT_MODEL,
  ORDERING_MENU_TYPE_SECTION_MODEL,
} from '../../utils/enums';
import { getInitModel } from "../initModels";
import { getDepotNameById, getMenuTemplateNameById, isDepotByPathname } from '../restaurant';
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import {createControlModel, filtrateControlByDepotRole} from "../controlModelFactory";
import { getBusinesMenuCategoriesFilter, sortByProp } from '../filter';
import FilterOptionsModel from '../../models/filterOptionsModel';
import { Avatar } from '../../app/components/Avatar/Avatar';
import React from 'react';

export function createBusinesInfoEditSectionMenuTemplate({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "",
        accessor: "header",
        settingsAaccessor: "header_settings",
        isEditable: true,
        type: CONTROL_TYPE.imageEdit
      },
      {
        id: "group1",
        columns: [
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_ENABLED_LABEL",
            accessor: "enabled",
            isEditable: true,
            isInline: true,
            type: CONTROL_TYPE.switch
          },
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_DELIVERY_LABEL",
            accessor: "disable_delivery",
            typeColor: TYPE_SWITCH_CONTROL_COLOR.red,
            isEditable: true,
            isInline: true,
            type: CONTROL_TYPE.switch
          },
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_TAKEAWAY_LABEL",
            accessor: "disable_takeaway",
            typeColor: TYPE_SWITCH_CONTROL_COLOR.red,
            isEditable: true,
            isInline: true,
            type: CONTROL_TYPE.switch
          },
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_MESSAGE_LABEL",
            accessor: "message",
            isEditable: true,
            type: CONTROL_TYPE.textArea
          },
        ],
        isEditable: true,
        type: CONTROL_TYPE.group
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_SCHEDULER_ORDERS",
        accessor: "disable_scheduled_orders",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_MESSAGE_ENABLED_LABEL",
        accessor: "message_enabled",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_GIFT",
        accessor: "allow_gift",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_NAME_LABEL",
        accessor: "name",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ABOUT_LABEL",
        accessor: "about",
        isEditable: true,
        type: CONTROL_TYPE.text
      },

      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_KOSHER_LABEL",
        accessor: "kosher",
        isInline: true,
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ALWAYS_DELIVER_LABEL",
        accessor: "always_deliver",
        isInline: true,
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_EMAIL_LABEL",
        accessor: "email",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DESCRIPTION_LABEL",
        accessor: "description",
        isEditable: true,
        type: CONTROL_TYPE.richText
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_PHONE_LABEL",
        accessor: "phone",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_MINIMUM_LABEL",
        accessor: "minimum",
        isEditable: true,
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ZIPCODE_LABEL",
        accessor: "zipcode",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_MINIMUM_TAKEAWAY_LABEL",
        accessor: "minimum_takeaway",
        isEditable: true,
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ADDRESS_NOTES_LABEL",
        accessor: "address_notes",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "new-order-directive.ADDRESS_LABEL",
        accessor: "address",
        isEditable: true,
        isShowIcon: true,
        withEmpty: true,
        type: CONTROL_TYPE.address
      },
      {
        title: "",
        accessor: "address",
        isEditable: true,
        isFillContent: true,
        isHideSearch: true,
        isHidePolygon: true,
        type: CONTROL_TYPE.mapAddress
      }
    ],
    actionLoad: ORDERING_MENU_GET,
    actionName: ORDERING_MENU_BUSINESS_INFO,
    initNewItemModel: item => getInitModel(MODEL_TYPE.businessInfoModel, item),
    typeAction: args.typeAction,
    typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
    stayAfterSave: true,
    saveControlsConfig: {
      cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
    },
    label: "settings.orderingmenu.MENU_BUSINESS_INFO",
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: "settings.orderingmenu.APP_BUILDER",
      }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return getMenuTemplateNameById(id);
        },
      }),
    ],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back
      }),
    ],
    mappingLambda: state => state.restaurant.data.businessMenu || {}
  });
}

export function createDepotMenuTemplateBusinessCategoriesEditSectionModel() {
  const { id } = getHistoryLocationParams(window.location.search);
  const isDepot = !!getDepotNameById(id) || isDepotByPathname()
  return new SectionModel({
    filterOptions: createDepotBusinessCategoriesEditSectionModelMenuTemplateFilter(),
    columns: [
      {
        title: "",
        width: "70px",
        component: function(businessCategory) {
          return <Avatar avatar={businessCategory.logo} hideIconColor={true} isDefaultImageEmpty={true} />;
        },
        type: CONTROL_TYPE.image
      },
      {
        title: "settings.businessCategories.CATEGORY_NAME",
        accessor: 'name'
      },
      {
        title: "",
        controlTitle: "",
        accessor: "isEnabled",
        width: "140px",
        isEditable: true,
        type: CONTROL_TYPE.switch,
        isInline: true,
        withLoader: true,
      }
    ],
    isForceUpdate: true,
    actionName: BUSINESS_CATEGORY,
    actionLoad: BUSINESS_CATEGORY_GET,
    editAction: MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING_EDIT,
    typeModel: isDepot ? ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuCategories : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories,
    parentUrl: isDepot ? TYPE_SECTION_DEPOT_MODEL.depotsEdit : MARKETPLACE_SETTINGS_MODEL.menuTemplate,
    controls: filtrateControlByDepotRole([
      createControlModel({
        type: MODEL_CONTROL_TYPE.back
      }),
    ]),
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: "settings.orderingmenu.APP_BUILDER",
      }),
      createBreadcrumbModel({
        getLabel: () => {
          return isDepot ? getDepotNameById(id) : getMenuTemplateNameById(id);
        },
      }),
    ],
    label: "settings.businessCategories.DEPOT_LABEL",
    sortByFunc: function(items) {
      return sortByProp(items);
    },
    mappingLambda: state => {
      const data = state.restaurant.data.businessCategory || []
      const businessCategories = state.restaurant.data.businessMenu?.business_categories || []
      return data.map(category => {
        const name = category.nameTranslations === null ? category.name : category.nameTranslations[i18n.language]
        return {
          name: name || '',
          isEnabled: businessCategories.includes(category.id),
          ...category
        }
      })
    }
  });
}

function createDepotBusinessCategoriesEditSectionModelMenuTemplateFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(category) {
    return getBusinesMenuCategoriesFilter(this.selected, category);
  });

  return filterOptionsModel;
}