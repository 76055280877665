import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import Waiter from '../Waiter/Waiter';
import {USER_ROLES, WAITER_TYPES} from '../../../utils/enums';
import {ModalPopup} from '../ModalPopup/ModalPopup';
import './style.scss';
import {useTranslation, withTranslation} from 'react-i18next';
import ShiftCourierControlTimeSelect from '../ShiftCourierControlTimeSelect';
import ShiftCourierControlDepotsList from '../ShiftCourierControlDepotsSelect';
import ShiftCourierControlDepot from '../ShiftCourierControlDepot';
import {useSelector} from 'react-redux';
import {getRestaurantDepots} from '../../../store/selectors';
import SwitchControl from '../SwitchControl/SwitchControl';
import SectionItemSelect from '../SectionItemSelect/SectionItemSelect';
import {getControlCenters} from '../../../services/restaurant';
import {getUserRole} from '../../../utils/auth-util';
import {isRTL} from "../../../services/userModel.js";

const getDate = date => {
    if (date === null) return null;
    return date;
};

const CurrentShiftCourierEditPopup =
    ({
     popupOpen,
     hanldeOnPopupClose,
     onClose,
     courierCycle,
     controlCenterId,
     fleet,
     setSelectedFleet,
     selectedControlCenter,
     setControlCenterId,
     availableControlCenters
  }) => {
    const { t } = useTranslation();
    const depots = useSelector(getRestaurantDepots);
    const { startTime, allowedDepots, endTime, shiftType } = courierCycle;
    const courierDepots = allowedDepots ? depots.filter(depot => allowedDepots.includes(depot.id)) : [];
    const hasControlCenters = !!getControlCenters(true)?.length

    const [viewAllMode, setViewAllMode] = useState(false);
    const [isBackup, setIsBackup] = useState(shiftType === 1);

    const [selectedDepots, setSelectedDepots] = useState(courierDepots);
    const [timeSettings, setTimeSettings] = useState({startTime: getDate(startTime), endTime: getDate(endTime)});

    const role = getUserRole().map(item => item?.toLowerCase());
    let [filteredCC, setFilteredCC] = useState(null);
    const isCanStartAnyCCShift = role?.includes(USER_ROLES.deliveryManager.toLowerCase());

    useEffect(() => {
      if (!selectedControlCenter) return
      setControlCenterId(selectedControlCenter)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedControlCenter]);

    const handleUpdateSelectedDepots = depots => {
    setSelectedDepots(depots);
    setViewAllMode(false);
    };

    const handleOnRemoveDepot = removeId => {
    setSelectedDepots(depots => depots.filter(d => d.id !== removeId));
    };

    const onCloseModal = () => {
    return hanldeOnPopupClose({
        shiftType: isBackup ? 1 : 0,
        controlCenterId,
        ...timeSettings,
        allowedDepots: selectedDepots?.map(d => d.id) || [],
    });
    };

    const setTimeSettingsCallBack = useCallback(setTimeSettings, []);

    useEffect(() => {
    if (isBackup && availableControlCenters && availableControlCenters[0] && availableControlCenters[0]?.controlCenterId !== null) {
        setFilteredCC([{controlCenterId: null, name: '-'}, ...availableControlCenters]);
    } else if (!isBackup && filteredCC && filteredCC[0] && filteredCC[0]?.controlCenterId === null) {
        setFilteredCC([...availableControlCenters]);
        if (controlCenterId === null) {
            setControlCenterId(availableControlCenters[0] ? availableControlCenters[0].controlCenterId : null);
        }
    } else {
        setFilteredCC(availableControlCenters?.filter(cc => cc?.name));
    }

    // eslint-disable-next-line
    }, [isBackup, availableControlCenters]);


        return (
            <ModalPopup
                isOpen={popupOpen}
                width={'450px'}
                onClose={onCloseModal}
                content={
                    <div
                        className={classNames('currentShiftCourierEditPopup', {currentShiftCourierEditPopupRtl: isRTL()})}>
                        {viewAllMode && (
                            <ShiftCourierControlDepotsList
                                selectedDepots={selectedDepots}
                                depots={depots}
                                onConfirm={handleUpdateSelectedDepots}
                                onCancel={() => setViewAllMode(false)}
                            />
                        )}
                        {!viewAllMode && (
                            <>
                                <div className={classNames('dataWrapper')}>
                                    <div className={classNames("backupShiftControl")}>
                                        <span
                                            className={classNames("labelControl")}>{t("couriers.BACKUP_SHIFT_TOOLTIP")}</span>
                                        <SwitchControl
                                            value={isBackup}
                                            onClick={setIsBackup}
                                            title={{
                                                on: t("dashboard.orders.ON_LABEL"),
                                                off: t("dashboard.orders.OFF_LABEL")
                                            }}
                                        />
                                    </div>
                                    <ShiftCourierControlTimeSelect
                                        onChange={setTimeSettingsCallBack}
                                        startTime={timeSettings.startTime}
                                        endTime={timeSettings.endTime}
                                    />
                                    <ShiftCourierControlDepot
                                        selectedDepots={selectedDepots}
                                        removeDepot={handleOnRemoveDepot}
                                        onViewAll={() => setViewAllMode(true)}
                                    />
                                    {!!filteredCC?.length &&
                                        <div className={classNames("shiftCourierControlPopupContent")}>
                  <SectionItemSelect
                    value={controlCenterId || selectedControlCenter}
                    isDisabled={!isCanStartAnyCCShift}
                    column={{
                      accessor: "controlCenterId",
                      title: t("shifts.SELECT_CONTROL_CENTER"),
                      values: filteredCC?.map(controlCenter=> ({
                        key: controlCenter.controlCenterId,
                        value: controlCenter.name
                      }))
                    }}
                    onPropChange={val=> setControlCenterId(val.controlCenterId)}
                  />
                </div>}
                  {(fleet && fleet.length > 0) && (
                      <div className={classNames('shiftCourierControlPopupContentVehicles')}>
                          <SectionItemSelect
                              isDisabled={false}
                              value={controlCenterId || selectedControlCenter}
                              column={{
                                  accessor: 'vehicleId',
                                  title: t('shifts.SELECT_FLEET'),
                                  values: fleet?.map(e => ({
                                      key: e.vehicleId,
                                      value: e.plateNumber ? e.plateNumber : e.vehicleId,
                                  })),
                              }}
                              onPropChange={val => setSelectedFleet(val.vehicleId)}
                          />
                      </div>
                  )}
              </div>
              <div className={classNames('currentShiftCourierEditPopupFooter')}>
                <Waiter
                  type={WAITER_TYPES.button}
                  stopPropagation={true}
                  disabled={hasControlCenters && !isBackup && !controlCenterId && !selectedControlCenter}
                  useClass={classNames('btn', 'confirm')}
                  spanText={t('basic.SAVE_BUTTON')}
                  handleOnClick={onCloseModal}
                />
                <div className={classNames('btn', 'denied')} onClick={onClose}>
                  {t('basic.CLOSE_BUTTON')}
                </div>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default withTranslation()(CurrentShiftCourierEditPopup);
