import {
  COURIER_UPDATE,
  CYCLE_STATE_UPDATE,
  CYCLE_UPDATE,
  DELETE_USER,
  LOAD_COURIER_LOCATIONS_SUCCESS,
  LOAD_USERS_FAIL,
  LOAD_USERS_SUCCESS,
  NEW_USER,
  PUSHER_USER_DELETED,
  SAVE_TRAVEL_DATA,
  SET_USERS,
  UPDATE_CONTROL_CENTERS,
  UPDATE_COURIER_LOCATION,
  UPDATE_USER,
  USER_CYCLE_STATE_UPDATE,
  USERS_UPDATE,
} from './userModel-actions';
import {PUSHER_MANUAL_UNASSOCIATE_UPDATE, PUSHER_SET_AWAY, REMOVE_ALL_DATA} from "../common-actions";

import User from "../../../services/user";
import userModule, {currentUserIsDepotRole, initUserModel} from "../../../services/userModel";
import ColorService from "../../../services/color";
import {getInitModel} from "../../../services/initModels";

import {mergeArrays} from "../../../utils/objects-util";
import {CYCLE_STATE, MODEL_TYPE, STATUSES} from "../../../utils/enums";

const initState = {
  data: [],
  status: STATUSES.notInitialized
};

export default function users(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case SAVE_TRAVEL_DATA:
      const notExistedCouriers = (action.data.courierLocations || [])
        .filter(courierLocation=> !state.data.find(user=> user.userId === courierLocation.courierId));
      const result = {
        ...state,
        data: state.data.map(user => {
          const newCycle = (action.data.cycles || []).find(cycle => cycle.courierId === user.userId);
          const userTravelData = user.travelData || getInitModel(MODEL_TYPE.travelDataModel);

          if (newCycle && !newCycle.routeEstimation) {
            newCycle.routeEstimation = getInitModel(MODEL_TYPE.routeEstimation);
          }


          const travelDataResult = (action.data.courierLocations || []).find(f => f.courierId === user.userId) || userTravelData;
          if((userTravelData && userTravelData.vehicleMovementInfo !== null) &&
              (travelDataResult && travelDataResult.vehicleMovementInfo === null)) {
            travelDataResult.vehicleMovementInfo = userTravelData.vehicleMovementInfo;
          }
          return user.roles.courier
            ? {
                ...user,
                roles: {
                  ...user.roles,
                  active: !!(newCycle && newCycle.courierId === user.userId)
                },
                travelData: travelDataResult,
                cycle: newCycle,
                color: user.color || (newCycle && ColorService.instance.getColor())
              }
            : user;
        })
      };

      //Fix getting locations for not existed users for depot role
      if(currentUserIsDepotRole() && notExistedCouriers.length) {
        result.data = result.data.concat(notExistedCouriers.map(courierLocation=> ({
          userId: courierLocation.courierId,
          travelData: courierLocation,
          color: ColorService.instance.getColor(),
          roles: { active: true },
          cycle: { routeEstimation: getInitModel(MODEL_TYPE.routeEstimation) }
        })))
      }

      return result;
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        data: (action.data || []).map(user => {
          const oldUser = (state.data  || []).find(oldUser => oldUser.userId === user.userId);
          return initUserModel({ ...oldUser, ...user });
        }),
        status: STATUSES.success
      };
    case LOAD_COURIER_LOCATIONS_SUCCESS:
      return userModule.updateCourierLocations(state, action.data);
    case LOAD_USERS_FAIL:
      return {
        ...state,
        status: STATUSES.failed
      };
    case UPDATE_COURIER_LOCATION:
      if (!action.data.length) return state;

      return {
        ...state,
        data: state.data.map(user => {
          const newTravelData = action.data.find(
            travelData => travelData.courierId === user.userId
          );
          return newTravelData
            ? {
                ...user,
                travelData: newTravelData
              }
            : user;
        })
      };
    case CYCLE_STATE_UPDATE:
      return userModule.cycleStateUpdate(state, action.data);
    case USER_CYCLE_STATE_UPDATE:
      if (User.instance.skipObjectWithControlCenter(action.data)) return state;
      return {
        ...state,
        data: state.data.map(
          user =>
            action.data.courierId && user.userId === action.data.courierId
              ? {
                  ...user,
                  cycle: { ...user.cycle, state: CYCLE_STATE.deliveryStart }
                }
              : user
        )
      };
    case PUSHER_MANUAL_UNASSOCIATE_UPDATE:
      return state;
    case PUSHER_SET_AWAY:
      return state;
    case PUSHER_USER_DELETED:
      return {
        ...state,
        data: state.data.filter(user => user.userId !== action.data.UserId)
      };
    case COURIER_UPDATE:
      return {
        ...state,
        data: state.data.map(user => user.userId === (action.data.userId || action.data.courierId)
          ? { ...user, ...action.data }
          : user
        )
      };
    case UPDATE_CONTROL_CENTERS:
      return {
        ...state,
        data: state.data.map(user => {
          const item = (action.data || []).find(f => f.userId === user.userId);
          return {
            ...user,
            controlCenterId: item ? item.controlCenterId : null
          };
        })
      };
    case CYCLE_UPDATE:
      return {
        ...state,
        data: state.data.map(
          user =>
            user.userId === action.data.courierId
              ? { ...user, cycle: { ...user.cycle, ...action.data.data } }
              : user
        )
      };
    case NEW_USER:
      return {
        ...state,
        data: mergeArrays(state.data, [initUserModel(action.data)], 'userId', 'userId')
      };
    case UPDATE_USER:
      if(!action.data) return state;
      return {
        ...state,
        data: state.data.map(
          user =>
            user.userId === action.data.userId
              ? initUserModel({
                  ...user,
                  ...action.data
                })
              : user
        )
      };
    case DELETE_USER:
      return {
        ...state,
        data: state.data.filter(f => f.userId !== action.data.userId)
      };
    case SET_USERS: {
      return {
        ...state,
        data: action.data
      };
    }
    case USERS_UPDATE:
      return {
        ...state,
        data: mergeArrays(state.data, action.data || [], "userId", "userId")
      };
    default:
      return state;
  }
}
