import {getPattern} from "./objects-util.js";
import {CONTROL_TYPE, salaryCalculationMode, TYPE_PATTERN, USER_ROLES} from "./enums.js";
import i18n from "../i18n.js";

export const columnsDepotUserEdit = [
    {
        title: "settings.users.USER_EDIT_NAME_LABEL",
        accessor: "fullName",
        isEditable: true,
        pattern: getPattern(TYPE_PATTERN.userNameWithSpaces),
        patternError: i18n.t('settings.users.USER_NAME_PATTERN_ERROR'),
        isRequired: true,
        type: CONTROL_TYPE.text
    },
    {
        title: "settings.users.USER_EDIT_PHONE_LABEL",
        accessor: "phoneNumber",
        isEditable: true,
        isFuncRequired: item => item.roles && item.roles[USER_ROLES.courier],
        hasError: error=> error === "Invalid phone number",
        type: CONTROL_TYPE.text
    },
    {
        title: "settings.users.USER_EDIT_EMAIL_LABEL",
        accessor: "email",
        isEditable: true,
        type: CONTROL_TYPE.text
    },
    {
        title: "settings.users.USER_WORKER_ID",
        accessor: "workerId",
        isEditable: true,
        type: CONTROL_TYPE.text
    },
    {
        title: "settings.users.USER_EDIT_HOURLY_RATE_LABEL",
        accessor: "hourlyRate",
        isEditable: true,
        type: CONTROL_TYPE.number
    },
    {
        title: "settings.users.SALARY_PAYMENT_TYPE",
        accessor: "salaryCalculationMode",
        isEditable: true,
        isShow: item => item.roles && item.roles[USER_ROLES.courier],
        connect: () => ({
            values: salaryCalculationMode(),
        }),
        type: CONTROL_TYPE.select
    },
    {
        title: "settings.users.USER_EDIT_ROLE_LABEL",
        accessor: "roles",
        isEditable: true,
        config: {
            [USER_ROLES.deliveryManager]: true,
            [USER_ROLES.courier]: true,
            [USER_ROLES.supportOperator]: true,
        },
        type: CONTROL_TYPE.roleSelector
    },
    {
        title: "settings.users.USER_ALLOW_OPEN_SHIFT",
        accessor: "allowOpenShift",
        isEditable: true,
        isShow: item=> item.roles && item.roles[USER_ROLES.courier],
        type: CONTROL_TYPE.switch
    },
    {
        title: "settings.users.MAX_ORDERS_PER_COURIER_LABEL",
        accessor: "capacity",
        isEditable: true,
        isShow: item=> item.roles && item.roles[USER_ROLES.courier],
        type: CONTROL_TYPE.number
    },
    {
        title: "settings.users.USER_EDIT_VEHICLE_LABEL",
        accessor: "vehicleType",
        isEditable: true,
        type: CONTROL_TYPE.vehicleSelector,
        isShow: function(item) {
            return item.roles && item.roles[USER_ROLES.courier];
        }
    },
    {
        title: "settings.users.CREATION_DATE",
        accessor: "creationDate",
        isEditable: true,
        type: CONTROL_TYPE.date
    }
]

export const columnGlobalUserEdit = [
    {
        title: 'settings.users.USER_EDIT_NAME_LABEL',
        accessor: 'fullName',
        isEditable: true,
        type: CONTROL_TYPE.text,
    },
    {
        title: 'settings.users.USER_EDIT_PHONE_LABEL',
        accessor: 'phoneNumber',
        isEditable: true,
        hasError: error => error === 'Invalid phone number',
        type: CONTROL_TYPE.text,
    },
    {
        title: 'settings.users.USER_EDIT_EMAIL_LABEL',
        accessor: 'email',
        isEditable: true,
        type: CONTROL_TYPE.text,
    },
    {
        title: 'settings.users.USER_WORKER_ID',
        accessor: 'workerId',
        isEditable: true,
        type: CONTROL_TYPE.text,
    },
    {
        title: 'settings.users.USER_EDIT_HOURLY_RATE_LABEL',
        accessor: 'hourlyRate',
        isEditable: true,
        type: CONTROL_TYPE.number,
    },
    {
        title: 'settings.users.SALARY_PAYMENT_TYPE',
        accessor: 'salaryCalculationMode',
        isEditable: true,
        isShow: item => item.roles && item.roles[USER_ROLES.courier],
        connect: () => ({
            values: salaryCalculationMode()
        }),
        type: CONTROL_TYPE.select,
    },
    {
        title: 'settings.users.USER_EDIT_ROLE_LABEL',
        accessor: 'roles',
        isEditable: true,
        type: CONTROL_TYPE.roleSelector,
    },
    {
        title: 'settings.users.USER_ALLOW_OPEN_SHIFT',
        accessor: 'allowOpenShift',
        isEditable: true,
        isShow: item => item.roles && item.roles[USER_ROLES.courier],
        type: CONTROL_TYPE.switch,
    },
    {
        title: 'settings.users.MAX_ORDERS_PER_COURIER_LABEL',
        accessor: 'capacity',
        isEditable: true,
        isShow: item => item.roles && item.roles[USER_ROLES.courier],
        type: CONTROL_TYPE.number,
    },
    {
        title: 'settings.users.USER_EDIT_VEHICLE_LABEL',
        accessor: 'vehicleType',
        isEditable: true,
        type: CONTROL_TYPE.vehicleSelector,
        isShow: function(item) {
            return item.roles && item.roles[USER_ROLES.courier];
        },
    },
    {
        title: 'settings.map.USERS_COURIER_EDIT_DEFAULT_CONTROL_CENTER',
        accessor: 'defaultControlCenterId',
        connect: state => ({
            values: [{ key: null, value: '-' }]
                .concat(state.restaurant.data.controlCenters || [])
                .map(center => ({ key: center.controlCenterId, value: center.name })),
        }),
        isShow: function(item) {
            return item.roles && item.roles[USER_ROLES.courier];
        },
        isEditable: true,
        type: CONTROL_TYPE.select,
    },
    {
        title: 'settings.users.CREATION_DATE',
        accessor: 'creationDate',
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.date,
    },
    {
        title: 'settings.users.ALLOWED_CONTROL_CENTERS',
        accessor: 'allowedControlCenters',
        isEditable: true,
        isCanBeEmpty: true,
        type: CONTROL_TYPE.controlCentersList,
    },
]