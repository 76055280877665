export const REMOVE_ALL_DATA = "REMOVE_ALL_DATA";
export const PUSHER_CYCLE_STATE_UPDATE = "PUSHER_CYCLE_STATE_UPDATE";
export const PUSHER_CYCLE_DISPATCHED = "PUSHER_CYCLE_DISPATCHED";
export const PUSHER_CYCLE_ROUTE_UPDATE = "PUSHER_CYCLE_ROUTE_UPDATE";
export const PUSHER_MANUAL_UNASSOCIATE_UPDATE =
  "PUSHER_MANUAL_UNASSOCIATE_UPDATE";
export const PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST =
  "PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST";
export const PUSHER_MANUAL_ASSOCIATE_UPDATE = "PUSHER_MANUAL_ASSOCIATE_UPDATE";
export const PUSHER_SET_AWAY = "PUSHER_SET_AWAY";
export const GET_INITIAL_DATA = "GET_INITIAL_DATA";

export const ADD = "_ADD";
export const EDIT = "_EDIT";
export const DELETE = "_DELETE";
export const UPDATE = "_UPDATE";
export const REORDER = "_REORDER";
export const GET = "_GET";
export const EXPORT = "_EXPORT";

export function actionRemoveAllData() {
  return {
    type: REMOVE_ALL_DATA
  };
}
