import { MODEL_TYPE } from "../utils/enums";
import { getInitModel } from "../services/initModels";

export function depotModel(data) {
  const depot = {
    avatar: null,
    id: "",
    name: "",
	administrativeName: "",
    address: {
      address: getInitModel(MODEL_TYPE.address),
      location: getInitModel(MODEL_TYPE.location)
    },
    hasPassword: false,
    hideCouriers: false,
    autoExternalAssignment: false,
    allowOrderCreation: false,
    userName: "",
    phone: "",
    integrations: [],
    maximumArrivalTime: 0,
    maxDeliveryTime: 0,
    maxOrderCoolingTime: 0,
    preorderTimeWindowEpsilon: 0,
    defaultControlCenterId: null,
    color: null,
    modelType: MODEL_TYPE.depot,
    smsBrand:  null,
    depotAsControlCenter : false
  };

  return data
    ? { ...depot, ...data, id: data.id || data.depotId || "" }
    : depot;
}
