export const nullOrEmpty = (value) => {
    return value === null || value === "";
}

export const getCountOfErrorInBulkCustomers = (table) => {
    return table.reduce((acc, cv) => {
        if (!cv.phone_valid.is_valid) {
            acc++;
        }
        if(!cv.datetime.is_valid) {
            acc++;
        }
        if(!cv.address_valid.is_valid) {
            acc++;
        }
        return acc;
    }, 0);
}