import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { getDepotName, isDepotMode } from "../../../services/restaurant";
import { getPlaceOrAddress, getShortOrderId } from '../../../services/order';
import User from '../../../services/user';


const OrderTitleInfo = ({ order, depots, isShowAddress = true, onTitleClick, isWithId }) => {
    const depot = (order && order.depotId && depots.find(f=> f.id === order.depotId)) || {};
    const depotName = getDepotName(order, depot);
    const orderId = getShortOrderId(order);
    const address = getPlaceOrAddress(order);
    const isShowOrderId = (!isDepotMode || !User.instance.getCurrentUserConfigurationProp("isHideOrderId")) && orderId;

    const handleOnTitleClick = e => {
        onTitleClick && onTitleClick(e);
    }

    return (
        <div className={classNames("orderTitleInfo")} onClick={handleOnTitleClick}>
            <div>{depotName}</div>
            {orderId && isWithId && isShowOrderId && (<div><span className="divider">/</span>{orderId}</div>)}
            {isShowAddress && address && (<div><span className="divider">/</span>{getPlaceOrAddress(order)}</div>)}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        depots: state.restaurant.data.depots || []
    }
}

export default connect(mapStateToProps)(OrderTitleInfo);