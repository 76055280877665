import { useState, useRef, useEffect } from "react";

export function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref] // Recall only if ref changes
  );

  return [ref, value];
}

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export const useBubbleClickOutside = (classNames, handler) => {
    const hasBubblingClick = (element) => {
        let currentElement = element;
        while (currentElement.parentNode) {
            currentElement = currentElement.parentNode;
            // eslint-disable-next-line
            if (classNames.some(c => currentElement.classList?.contains(c))) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        const listener = (event) => {
            if(!hasBubblingClick(event.target)) {
                handler();
            }
        }

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };

        // eslint-disable-next-line
    }, [handler, classNames]);

}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (delay !== null) {
            const id = setInterval(() => savedCallback.current(), delay)
            return () => clearInterval(id)
        }
    }, [delay])
}