import React, {useState} from 'react';
import ControlModel from '../../../models/controlModel.js';
import {MODEL_CONTROL_TYPE} from '../../../utils/enums.js';
import IconButton from '../../../app/components/IconButton/index.js';
import classNames from 'classnames';
import './DropdownModel.scss';
import {isRTL} from "../../userModel.js";
import {ActionButtonControl} from "../actionButton.js";

export function createDropdownControlModel({ ...args }) {
  return new ControlModel({
    icon: args.icon || '',
    items: args.items,
    isShow:
      args.isShow ||
      function() {
        return true;
      },
    type: MODEL_CONTROL_TYPE.dropdown,
  });
}
/*
const DropdownControlItem = dropdownItem => {
  const { icon, text, action, isShow, handleOnClick, isActiveElement } = dropdownItem;
  const dispatch = useDispatch();
  // const [inProcess, setInProcess] = useState(false);


  const handleClick = () => {
      /!*if (inProcess) {
          return;
      }*!/
      // setInProcess(true);
      handleOnClick();
      new Promise((resolve, reject) => {
          return dispatch(createAction(action, isShow())).then(
              () => {
                  resolve();
                  // setInProcess(false);
              },
              error => {
                  // setInProcess(false);
              }
          );
      });
  };


  if (!isShow() !== isActiveElement) return null;

  return (
    <div className={classNames('dropdown-item', 'iconButton')} onClick={() => handleClick()}>
      <div className={classNames('waiterButtonWrap')}>
        <span className={classNames('spanImg', 'waiter-tooltip')}>
          <span className={classNames('actionIcon', icon)}></span>
        </span>
      </div>
      <div>{text}</div>
    </div>
  );
};*/

export const DropdownControl = ({ control, model, models, itemAction }) => {
  const { text, tooltipText, icon, items } = control;
  const [isOpen, setIsOpen] = useState(false);
  // const [isActiveElement, setIsActiveElement] = useState(false);
  const isRtl = isRTL();

  /*const handleOnClick = (id) => {
      setIsActiveElement(!isActiveElement);
  };*/

  const getControlComponent = (item, indexItem) => {
      if(item.type === MODEL_CONTROL_TYPE.actionButton) {
          if(!item.isShow || (item.isShow && item.isShow())) {
             return <div key={indexItem}><ActionButtonControl control={item} model={model} models={models} itemAction={itemAction} /></div>
          }
          return null;
      }
      // <DropdownControlItem key={item?.icon + item.text} model={model} isActiveElement={isActiveElement} handleOnClick={handleOnClick} models={models} {...item} />


  }



  return (
    <div className={'dropdownControl'}>
      <div className={classNames('btn', 'confirm', 'dropdownTriggerControl', { dropdownTriggerControlActive: isOpen })} onClick={() => setIsOpen(!isOpen)}>
        <span className={'filterTriggerControlTitle'}>{text}</span>
        <IconButton isIcon iconName={icon} tooltipText={tooltipText} />
      </div>

      <div className={classNames('dropdown-list', {dropdownListRtl: isRtl}, { active: isOpen })}>
        {items.map((item, indexItem) => (
            getControlComponent(item, indexItem)
        ))}
      </div>
    </div>
  );
};
