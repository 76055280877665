import React, {useState} from "react";
import {connect} from "react-redux";
import {saveAs} from "file-saver";
import classNames from "classnames";
import "./index.scss";

import {createAction} from "../../../utils/sagaHelper";
import {WAITER_TYPES} from "../../../utils/enums";

import Waiter from "../Waiter/Waiter";

const LinkDownload = ({ title, actionName, data, downloadAction, icon }) => {
  const [inProcess, setInProcess] = useState(false);
  const handleOnClick = () => {
    return new Promise((resolve, reject) => {
      setInProcess(true);
      if (actionName) {
        downloadAction(actionName, data).then(
          dataBlob => {
            if(dataBlob?.data === null){
              resolve();
              setInProcess(false);
              return;
            }
            if (dataBlob && dataBlob.file && dataBlob.fileName) {
              const blob = new Blob([dataBlob.file], {
                type: dataBlob.contentType
              });
              saveAs(blob, dataBlob.fileName);
              resolve();
              setInProcess(false);
            } else {
              reject();
              setInProcess(false);
            }
          },
          () => {
            reject();
            setInProcess(false);
          }
        );
      } else {
        reject();
        setInProcess(false);
      }
    });
  };

  return (
    <div className={classNames("linkDownload")}>
      {!icon && (
        <div className={classNames("link")} onClick={handleOnClick}>
          {title || "Download"}
        </div>
      )}
      {icon && (
        <Waiter
          tooltipText={title || "Download"}
          useClass={classNames("icon", icon || "defaultIcon")}
          type={WAITER_TYPES.span}
          handleOnClick={handleOnClick}
          stopPropagation={true}
        />
      )}
      {inProcess && <div className={classNames("process")} />}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    downloadAction: (action, data) => dispatch(createAction(action, data))
  };
};

export default connect(null, mapDispatchToProps)(LinkDownload);
