
export const cloudPrintersModel = (item = {}) => {

    return {
        id: item?.id || "",
        prnID: item?.prnID || "",
        language: item?.language || "",
        autoPrintBon: item?.autoPrintBon || "",
        autoPrintNewOrderNotification: item?.autoPrintNewOrderNotification || "",
    }
}
