export const SUPPORT_CHAT_SAGA_INIT = "SUPPORT_CHAT_SAGA_INIT";
export const PULL_SUPPORT_DIALOG = "PULL_SUPPORT_DIALOG";
export const MARK_RESOLVED_SUPPORT_DIALOG = "MARK_RESOLVED_SUPPORT_DIALOG";
export const CREATE_NEW_CHAT = "CREATE_NEW_CHAT";
export const GET_DIALOGS_SUPPORT = "GET_DIALOGS_SUPPORT";
export const AUTH_SUPPORT_CHAT = "AUTH_SUPPORT_CHAT";

export const SAVE_SUPPORT_CHAT_SESSION = "SAVE_SUPPORT_CHAT_SESSION";
export const SET_SHOW_SUPPORT_CHAT = "SET_SHOW_SUPPORT_CHAT";
export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";
export const SET_DIALOGS_SUPPORT_CHAT = "SET_DIALOGS_SUPPORT_CHAT";
export const ADD_DIALOG_SUPPORT_CHAT = "ADD_DIALOG_SUPPORT_CHAT";
export const PULLED_SUPPORT_DIALOG = "PULLED_SUPPORT_DIALOG";

export const FRESH_DESK_RESTORE_ID = "FRESH_DESK_RESTORE_ID";
export const SET_UNREAD_MESSAGE_SAGA = "SET_UNREAD_MESSAGE_SAGA";

export const initSupportChat = (data) => ({
    type: SUPPORT_CHAT_SAGA_INIT,
    payload: data,
});

export const pullSupportChat = (chatId) => ({
    type: PULL_SUPPORT_DIALOG,
    payload: {chatId: chatId},
});

export const markResolvedSupportChat = (chatId) => ({
    type: MARK_RESOLVED_SUPPORT_DIALOG,
    payload: {chatId: chatId},
});

export const saveSupportChatSession = session => ({
    type: SAVE_SUPPORT_CHAT_SESSION,
    payload: session,
});

export const setShowSupportChat = flag => ({
    type: SET_SHOW_SUPPORT_CHAT,
    payload: flag,
});

export const setDialogsSupportChat = dialogs => ({
    type: SET_DIALOGS_SUPPORT_CHAT,
    payload: dialogs,
});

export const addDialogsSupportChat = dialogs => ({
    type: ADD_DIALOG_SUPPORT_CHAT,
    payload: dialogs,
});

export const setUnreadMessages = length => ({
    type: SET_UNREAD_MESSAGES,
    payload: length,
});

export const pulledSupportChat = chatId => ({
    type: PULLED_SUPPORT_DIALOG,
    payload: chatId,
});

export const createNewChat = depotId => ({
    type: CREATE_NEW_CHAT,
    payload: depotId,
});

export const FDRestoreId = data => ({ type: FRESH_DESK_RESTORE_ID, data });