export const GET_ORDER_INFO_AND_MENU = 'GET_ORDER_INFO_AND_MENU';
export const SET_EDIT_CUSTOMER_ORDER_INFO = 'SET_EDIT_CUSTOMER_ORDER_INFO';
export const SET_EDIT_CUSTOMER_ORDER_MENU = 'SET_EDIT_CUSTOMER_ORDER_MENU';
export const SET_EDIT_CUSTOMER_ORDER_SUMMARY = 'SET_EDIT_CUSTOMER_ORDER_SUMMARY';
export const GET_EDIT_CUSTOMER_ORDER_SUMMARY = 'GET_EDIT_CUSTOMER_ORDER_SUMMARY';
export const SET_EDIT_CUSTOMER_ORDER_PRODUCTS = 'SET_EDIT_CUSTOMER_ORDER_PRODUCTS';
export const SET_EDIT_CUSTOMER_ORDER_DELIVERY_TYPE = 'SET_EDIT_CUSTOMER_ORDER_DELIVERY_TYPE';
export const CONFIRM_BATCH_ORDER = 'CONFIRM_BATCH_ORDER';
export const PROCESS_BATCH_ORDER = 'PROCESS_BATCH_ORDER';
export const SET_EDIT_CUSTOMER_ORDER_LOADING = 'SET_EDIT_CUSTOMER_ORDER_LOADING';
export const SET_EDIT_CUSTOMER_ORDER_COURIER_TIP = 'SET_EDIT_CUSTOMER_ORDER_COURIER_TIP';
export const SET_EDIT_CUSTOMER_ORDER_PAYMENTS = 'SET_EDIT_CUSTOMER_ORDER_PAYMENTS';
export const SET_BULK_EMAIL = 'SET_BULK_EMAIL';
export const SET_EDIT_CUSTOMER_ORDER_DELIVERY_STRATEGY = 'SET_EDIT_CUSTOMER_ORDER_DELIVERY_STRATEGY';
export const SET_EDIT_CUSTOMER_ORDER_DELIVERY_TIME = 'SET_EDIT_CUSTOMER_ORDER_DELIVERY_TIME';
export const SET_EDIT_CUSTOMER_ORDER_CUTLERY = 'SET_EDIT_CUSTOMER_ORDER_CUTLERY';
export const SET_EDIT_CUSTOMER_ORDER_CONFIRM = 'SET_EDIT_CUSTOMER_ORDER_CONFIRM';
export const SET_EDIT_CUSTOMER_ORDER_NEW_CONFIRM = 'SET_EDIT_CUSTOMER_ORDER_NEW_CONFIRM';
export const RESET_CUSTOMER_ORDER_EDIT_INFO = 'RESET_CUSTOMER_ORDER_EDIT_INFO';
export const SET_EDIT_CUSTOMER_ORDER_ADDRESS = 'SET_EDIT_CUSTOMER_ORDER_ADDRESS';
export const SET_EDIT_CUSTOMER_GIFT_DATA = 'SET_EDIT_CUSTOMER_GIFT_DATA';
export const SET_EDIT_CUSTOMER_ENABLED_PAYMENTS = 'SET_EDIT_CUSTOMER_ENABLED_PAYMENTS';
export const CONFIRM_EDIT_CUSTOMER_ORDER_BY_STRIPE = 'CONFIRM_EDIT_CUSTOMER_ORDER_BY_STRIPE';
export const CONFIRM_EDIT_CUSTOMER_ORDER_FINAL = 'CONFIRM_EDIT_CUSTOMER_ORDER_FINAL';
export const SET_CONFIRM_EDIT_CUSTOMER_ORDER_LOADING = 'SET_CONFIRM_EDIT_CUSTOMER_ORDER_LOADING';
export const CHECK_USER_BY_PHONE = 'CHECK_USER_BY_PHONE';
export const CREATE_CONSUMER_USER = 'CREATE_CONSUMER_USER';
export const SET_PREVIOUS_ORDERS = 'SET_PREVIOUS_ORDERS';
export const GET_PREVIOUS_ORDERS = 'GET_PREVIOUS_ORDERS';
export const GET_BUSINESS_BY_FILTERS = 'GET_BUSINESS_BY_FILTERS';
export const GET_BUSINESS_MENU = 'GET_BUSINESS_MENU';
export const REPEAT_ORDER = 'REPEAT_ORDER';
export const GET_USER_CARDS_BY_ID = 'GET_USER_CARDS_BY_ID';
export const SET_PREVIOUS_ORDERS_ADDRESS = 'SET_PREVIOUS_ORDERS_ADDRESS';
export const SET_IS_SINGLE_ORDER = 'SET_IS_SINGLE_ORDER';
export const DELETE_TEMPLATE_DRAFT = 'DELETE_TEMPLATE_DRAFT';
export const SET_BUSINESS_BY_FILTERS = 'SET_BUSINESS_BY_FILTERS';
export const SET_EDIT_ORDER_COMMENT = 'SET_EDIT_ORDER_COMMENT';
export const SET_EDIT_ORDER_TERRITORY = 'SET_EDIT_ORDER_TERRITORY';
export const IMPORT_BATCH_TEMPLATE = 'IMPORT_BATCH_TEMPLATE';
export const GET_TEMPLATE_BATCH_BY_ID = 'GET_TEMPLATE_BATCH_BY_ID';
export const EXPORT_BATCH_TEMPLATE = 'EXPORT_BATCH_TEMPLATE';
export const SET_BATCH_CUSTOMERS_TABLE = 'SET_BATCH_CUSTOMERS_TABLE';
export const SET_CANCEL_PAYMENT_IDS = 'SET_CANCEL_PAYMENT_IDS';
export const UPDATE_BATCH_CUSTOMERS_TABLE_ROW = 'UPDATE_BATCH_CUSTOMERS_TABLE_ROW';
export const DELET_BATCH_CUSTOMERS_TABLE_ROW = 'DELET_BATCH_CUSTOMERS_TABLE_ROW';
export const SET_TEMPLATE_BATCH_DRAFT = 'SET_TEMPLATE_BATCH_DRAFT';
export const ADD_TEMPLATE_BATCH_DRAFT = 'ADD_TEMPLATE_BATCH_DRAFT';
export const UPDATE_TEMPLATE_BATCH_DRAFT = 'UPDATE_TEMPLATE_BATCH_DRAFT';
export const VERIFY_SINGLE_TABLE_ROW_BATCH_CUSTOMERS = 'VERIFY_SINGLE_TABLE_ROW_BATCH_CUSTOMERS';
export const DELETE_SINGLE_TABLE_ROW_BATCH_CUSTOMERS = 'DELETE_SINGLE_TABLE_ROW_BATCH_CUSTOMERS';
export const ADD_NEW_SINGLE_TABLE_ROW_BATCH_CUSTOMERS = 'ADD_NEW_SINGLE_TABLE_ROW_BATCH_CUSTOMERS';
export const CHANGE_TEMPORARY_CONSUMER = 'CHANGE_TEMPORARY_CONSUMER';
export const GET_TEMPLATE_BATCH_DRAFTS = 'GET_TEMPLATE_BATCH_DRAFTS';
export const DELETE_BATCH_DRAW_TEMPLATE = 'DELETE_BATCH_DRAW_TEMPLATE';
export const UPDATE_BATCH_TEMPLATE_COMMENT = 'UPDATE_BATCH_TEMPLATE_COMMENT';
export const GET_TEMPLATE_BATCH_ORDER_PRICE = 'GET_TEMPLATE_BATCH_ORDER_PRICE';
export const UPDATE_SELECTED_BUSINESS_CONSUMER = 'UPDATE_SELECTED_BUSINESS_CONSUMER';

export const resetCustomerOrderEditInfo = (payload = {}) => ({
    type: RESET_CUSTOMER_ORDER_EDIT_INFO, payload
})

export const getOrderInfoAndMenu = payload => ({
    type: GET_ORDER_INFO_AND_MENU, data: payload,
});

export const setEditCustomerOrderInfo = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_INFO, payload,
});

export const setEditCustomerOrderData = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_MENU, payload
});

export const setEditCustomerOrderSummary = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_SUMMARY, payload
});

export const getEditCustomerOrderSummary = payload => ({
    type: GET_EDIT_CUSTOMER_ORDER_SUMMARY, payload
});

export const setEditCustomerOrderProducts = payload => ({
   type: SET_EDIT_CUSTOMER_ORDER_PRODUCTS, payload
});

export const setEditCustomerOrderDeliveryType = payload => ({
   type: SET_EDIT_CUSTOMER_ORDER_DELIVERY_TYPE, payload
});

export const setEditCustomerOrderLoading = payload => ({
   type: SET_EDIT_CUSTOMER_ORDER_LOADING, payload
});

export const setEditCustomerOrderCourierTip = payload => ({
   type: SET_EDIT_CUSTOMER_ORDER_COURIER_TIP, payload
});

export const setEditCustomerOrderPayments = payload => ({
   type: SET_EDIT_CUSTOMER_ORDER_PAYMENTS, payload
});

export const setBulkEmail = payload => ({
   type: SET_BULK_EMAIL, payload
});

export const setEditCustomerOrderDeliveryStrategy = payload => ({
   type: SET_EDIT_CUSTOMER_ORDER_DELIVERY_STRATEGY, payload
});

export const setEditCustomerOrderDeliveryTime = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_DELIVERY_TIME, payload
});

export const setEditCustomerOrderCutlery = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_CUTLERY, payload
});

export const setEditCustomerOrderConfirm = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_CONFIRM, payload
});

export const setEditCustomerOrderAddress = payload => ({
    type: SET_EDIT_CUSTOMER_ORDER_ADDRESS, payload
});

export const setEditCustomerOrderGiftOptions = payload => ({
    type: SET_EDIT_CUSTOMER_GIFT_DATA, payload
});

export const setEditCustomerOrderEnabledPayments = payload => ({
    type: SET_EDIT_CUSTOMER_ENABLED_PAYMENTS, payload
});

export const confirmEditCustomerOrderByStripe = payload => ({
    type: CONFIRM_EDIT_CUSTOMER_ORDER_BY_STRIPE, data: payload
});

export const confirmEditCustomerOrderFinal = payload => ({
    type: CONFIRM_EDIT_CUSTOMER_ORDER_FINAL, data: payload
});

export const setConfirmEditCustomerOrderLoading = payload => ({
    type: SET_CONFIRM_EDIT_CUSTOMER_ORDER_LOADING, payload
});

export const setPreviousOrder = payload => ({
    type: SET_PREVIOUS_ORDERS, payload
});

export const getPreviousOrder = payload => ({
    type: GET_PREVIOUS_ORDERS, data: payload
});

export const setPreviousAddressByOrders = payload => ({
    type: SET_PREVIOUS_ORDERS_ADDRESS, payload
});

export const setBusinessOrdersByFilters = payload => ({
    type: SET_BUSINESS_BY_FILTERS, payload
});

export const setEditOrderComment = payload => ({
    type: SET_EDIT_ORDER_COMMENT, payload
});

export const setEditOrderTerritory = payload => ({
    type: SET_EDIT_ORDER_TERRITORY, payload
});

export const setBatchCustomersTable = payload => ({
    type: SET_BATCH_CUSTOMERS_TABLE, payload
});


export const setCancelPaymentIds = payload => ({
    type: SET_CANCEL_PAYMENT_IDS, payload
});

export const updateBatchCustomersTableRow = payload => ({
    type: UPDATE_BATCH_CUSTOMERS_TABLE_ROW, payload
});

export const changeTemporaryConsumer = payload => ({
    type: CHANGE_TEMPORARY_CONSUMER, payload
});

export const addNewBatchCustomersTableRow = payload => ({
    type: ADD_NEW_SINGLE_TABLE_ROW_BATCH_CUSTOMERS, payload
});

export const deleteBatchCustomersTableRow = payload => ({
    type: DELET_BATCH_CUSTOMERS_TABLE_ROW, payload
});

export const setTemplateBatchDrafts = payload => ({
    type: SET_TEMPLATE_BATCH_DRAFT, payload
});

export const addTemplateBatchDrafts = payload => ({
    type: ADD_TEMPLATE_BATCH_DRAFT, payload
});

export const setIsSingleOrder = payload => ({
    type: SET_IS_SINGLE_ORDER, payload
});

export const deleteBatchTemplateDraft = payload => ({
    type: DELETE_TEMPLATE_DRAFT, payload
});

export const updateTemplateBatchDraft = payload => ({
    type: UPDATE_TEMPLATE_BATCH_DRAFT, payload
});