import {
  INIT_SECTION_ITEMS_HEIGHTS,
  SAVE_SECTION_ITEM_HEIGHT,
  SET_SECTION_ITEMS_LOADING,
  UPDATE_SECTION_ITEM_ROW_HEIGHT_DEFAULT,
  REFRESH_SECTION_ITEMS_HEIGHTS
} from "./sectionsWrapperData-actions";
import { REMOVE_ALL_DATA } from "../common-actions";

const initState = {
  heights: {},
  rowHeightDefault: 0,
  isLoading: false,
  isKeepOpenPopup: false
};

export default function sectionsWrapperData(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case INIT_SECTION_ITEMS_HEIGHTS:
      return {
        heights: {},
        rowHeightDefault: state.rowHeightDefault,
        isLoading: false
      };
    case REFRESH_SECTION_ITEMS_HEIGHTS:
      return {
        ...state,
        heights: {
          ...state.heights
        }
      };
    case SAVE_SECTION_ITEM_HEIGHT:
      return {
        ...state,
        heights: {
          ...state.heights,
          [action.data.id]: action.data.height
        }
      };
    case UPDATE_SECTION_ITEM_ROW_HEIGHT_DEFAULT:
      return {
        ...state,
        rowHeightDefault: action.data || 0
      };
    case SET_SECTION_ITEMS_LOADING:
      if (
        (action.data && state.isLoading) ||
        (!action.data && !state.isLoading)
      )
        return state;
      return {
        ...state,
        isLoading: action.data
      };
    default:
      return state;
  }
}
