import React from 'react';
import i18n from '../../i18n';

import FilterOptionsModel from '../../models/filterOptionsModel';
import SectionModel from '../../models/sectionModel';
import { createControlModel } from '../controlModelFactory';
import { getBusinesDiscountFilter } from '../filter';
import { getInitModel } from '../initModels';
import {
    getBusinessMenuOfferNameById,
    getDiscountCalculationBaseType,
    getDiscountOrderTypeLabel, getDiscountTargetType,
    getDiscountTypeLabel,
    getMenuTemplateNameById,
    getNameByIdsBusinessMenu,
} from '../restaurant';

import {
    ORDERING_MENU_BUSINESS_DISCOUNTS,
    ORDERING_MENU_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    DATECONTROL_TYPE,
    DISCOUNT_TYPE,
    discountOrderTypes,
    discountTypes, getOfferCalculationBase,
    getOfferDiscountTarget,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
    RATE_TYPE,
    rateTypes, TYPE_ACTION,
} from '../../utils/enums';
import { defaultPromiseResolve } from '../../utils/objects-util';
import { getHistoryLocationParams } from '../../utils/routesHelper';

import IconButton from '../../app/components/IconButton';
import { createBreadcrumbModel } from '../createBreadcrumbModel';

export function createBusinesDiscountsSectionMenuTemplate({ ...args }) {
    const { id } = getHistoryLocationParams(args.params);

    return new SectionModel({
        filterOptions: createBusinesDiscountsSectionFilter(),
        columns: [
            {
                title: "settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL",
                accessor: "discount_target",
                component: item=> getDiscountTargetType(item.discount_target) || null,
                centered: true
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_LABEL',
                accessor: 'order_type',
                component: item => getDiscountOrderTypeLabel(item.order_type),
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL',
                accessor: 'type',
                component: item => getDiscountTypeLabel(item.type) || null,
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_RATE_TYPE_LABEL',
                accessor: 'rate_type',
                component: item =>
                    item.rate_type > 0 ? (
                        <IconButton
                            isIcon={true}
                            iconName={item.rate_type === RATE_TYPE.percent ? 'percent' : 'cash'}
                            tooltipText={
                                item.rate_type === RATE_TYPE.percent
                                    ? i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PERCENTAGE')
                                    : i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PRICE')
                            }
                            onClick={defaultPromiseResolve}
                        />
                    ) : null,
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_RATE_LABEL',
                accessor: 'rate',
                centered: true,
            },
            {
                title: "settings.orderingmenu.MENU_DISCOUNTS_CALCULATION_BASED_ON",
                accessor: "calculation_base",
                component: item=> getDiscountCalculationBaseType(item.calculation_base) || null,
                centered: true
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_MINIMUM_LABEL',
                accessor: 'minimum',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_LIMIT_LABEL',
                accessor: 'limit',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_NAME_LABEL',
                accessor: 'name',
                centered: true,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.orderingmenu.MENU_DISCOUNTS_EDIT_TOOLTIP_LABEL'),
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_DISCOUNTS,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts,
        typeAction: args.typeAction,
        tableMinWidth: 1200,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew + `?parentId=${id}`,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getMenuTemplateNameById(id);
                },
            }),
        ],
        label: 'settings.orderingmenu.MENU_DISCOUNTS',
        mappingLambda: state => {
            return (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.offers) || [];
        },
    });
}

export function createBusinesDiscountEditSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL',
                accessor: 'type',
                connect: () => ({
                    defaultValue: { key: '', value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_SELECT_LABEL') },
                    values: discountTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_RATE_TYPE_LABEL',
                accessor: 'rate_type',
                connect: () => ({
                    defaultValue: { key: '', value: i18n.t('settings.orderingmenu.MENU_RATE_TYPE_SELECT_LABEL') },
                    values: rateTypes(),
                }),
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_RATE_LABEL',
                accessor: 'rate',
                isEditable: true,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_MINIMUM_LABEL',
                accessor: 'minimum',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_LIMIT_LABEL',
                accessor: 'limit',
                isEditable: true,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_START_LABEL',
                accessor: 'start',
                isEditable: true,
                timeType: DATECONTROL_TYPE.datetime,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_END_LABEL',
                accessor: 'end',
                min: item => item.start,
                isEditable: true,
                inlineType: 'inlineOdd',
                timeType: DATECONTROL_TYPE.datetime,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_LABEL',
                accessor: 'order_type',
                connect: () => ({
                    values: discountOrderTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_COUPON_LABEL',
                accessor: 'coupon',
                isEditable: true,
                isShow: item => item && item.type === DISCOUNT_TYPE.coupon,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_SINGLE_USER_LABEL',
                accessor: 'single_user',
                isEditable: true,
                isShow: item => item && item.type === DISCOUNT_TYPE.coupon,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.orderingmenu.MENU_DISCOUNTS_CALCULATION_BASED_ON",
                accessor: "calculation_base",
                connect: () => ({
                    values: getOfferCalculationBase()
                }),
                isEditable: true,
                type: CONTROL_TYPE.select
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL',
                accessor: 'discount_target',
                connect: () => ({
                    values: getOfferDiscountTarget(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: "settings.orderingmenu.MENU_DISCOUNT_SHOW_IN_MARKETPLACE_APP",
                accessor: "show_in_marketplace",
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: 'settings.orderingmenu.DISCOUNT_BUSINESS_SHEDULE_ENABLED_LABEL',
                accessor: 'enable_schedule',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL',
                accessor: 'schedule',
                isEditable: true,
                isFillContent: true,
                type: CONTROL_TYPE.schedule,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_DISCOUNTS,
        initNewItemModel: item => getInitModel(MODEL_TYPE.offerModel, item),
        typeAction: args.typeAction,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, parentId } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(parentId || id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_DISCOUNTS',
            }),
        ],
        getLabel: () => {
            const { id } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit ? getBusinessMenuOfferNameById(id) : i18n.t('settings.orderingmenu.NEW_MENU_DISCOUNTS');
        },
        mappingLambda: state => {
            return (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.offers) || [];
        },
    });
}

function createBusinesDiscountsSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(businesDiscount) {
        return getBusinesDiscountFilter(this.selected, businesDiscount);
    });

    return filterOptionsModel;
}
