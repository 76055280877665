import { INIT, INIT_START_SUCCESS, INIT_START_FAIL } from "./init-actions";
import { REMOVE_ALL_DATA } from "../common-actions";

const initState = {
  isLoaded: false,
  error: ""
};

export default function init(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case INIT:
      return {
        ...state,
        isLoaded: false
      };
    case INIT_START_SUCCESS:
      return {
        ...state,
        isLoaded: true
      };
    case INIT_START_FAIL:
      return {
        ...state,
        isLoaded: false
      };
    default:
      return state;
  }
}
