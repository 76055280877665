export const CHECK_USER_AUTHENTICATION = "CHECK_USER_AUTHENTICATION";
export const AUTHENTICATION = "AUTHENTICATION";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";
export const AUTHENTICATION_DEAUTHORIZE = "AUTHENTICATION_DEAUTHORIZE";
export const AUTHENTICATION_DEAUTHORIZE_FAIL =
  AUTHENTICATION_DEAUTHORIZE + "_FAIL";
export const AUTHENTICATION_SET = "AUTHENTICATION_SET";
export const LOGOUT = "LOGOUT";

export function actionAuthenticationSet(data) {
  return {
    type: AUTHENTICATION_SET,
    data: data
  };
}
