export const loyaltyRegisterVoucherModel = (data = {}) => {
    const model = {
        bonus: data.registrationBonus || 0,
        checkout_limit_percent: data.registrationBonusCheckoutLimitPercent || 0,
        bonus_expiration: data.registrationBonusExpiration || "",
        bonus_name: data.registrationBonusName || "",
        delivery_type: data.registrationBonusDeliveryType || null,
    }

    return model;
}

export const loyaltyRegisterVoucherServerModel = (data = {}) => {
    const model = {
        RegistrationBonus: data.bonus || 0,
        RegistrationBonusCheckoutLimitPercent: data.checkout_limit_percent || 0,
        RegistrationBonusExpiration: data.bonus_expiration || "",
        RegistrationBonusName: data.bonus_name || "",
        RegistrationBonusDeliveryType: data.delivery_type || null,
    }

    return model;
}

