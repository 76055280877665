import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';
import i18n from '../../../i18n';
import IconButton from '../IconButton/IconButton';
import { createAction } from '../../../utils/sagaHelper';
import { UPDATE_CURRENT_SHIFTS_DEPOT_COURIERS } from '../../../store/reducers/userModel/userModel-actions';
import { connect } from 'react-redux';
import CurrentShiftDepotAssignPopup from '../CurrentShiftDepotAssignPopup';

const CurrentShiftDepotAssign = ({ depot, updateDepotCouriers }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOnClick = () => {
    setPopupOpen(true);
  };

  const handleOnUpdateDepotCouriers = (depotId, data) => {
    if (depotId) {
      return updateDepotCouriers({ depotId: depotId, courierIds: data.courierIds }).then(() => setPopupOpen(false));
    }
  };

  const handleOnPopupClose = data => {
    const depotId = depot && depot.id;
    handleOnUpdateDepotCouriers(depotId, data);
  };

  return (
    <div className={classNames('currentShiftDepotAssign')}>
      <IconButton
        isIcon
        iconName={'userAssignItem'}
        tooltipText={i18n.t('settings.users.USER_TOOLS_COURIER_ASSIGN_TOOLTIP')}
        onClick={() => handleOnClick()}
      />
      {popupOpen && (
        <CurrentShiftDepotAssignPopup
          depotCouriers={depot.users}
          popupOpen={popupOpen}
          handleOnPopupClose={handleOnPopupClose}
          onClose={() => setPopupOpen(false)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateDepotCouriers: data => dispatch(createAction(UPDATE_CURRENT_SHIFTS_DEPOT_COURIERS, data)),
});
export default connect(null, mapDispatchToProps)(withTranslation()(CurrentShiftDepotAssign));
