import React from "react";
import SectionItemSwitch from "../SectionItemSwitch";
import {useDispatch} from "react-redux";
import {createAction} from "../../../utils/sagaHelper";

const SwitchEnableDeliveryZone = ({item, action}) => {
    const dispatch = useDispatch();
    const handleOnPropChange = ({enabled}) => {
        dispatch(createAction(action, { id: item.id, enabled }));
    }

    return (
        <SectionItemSwitch column={{accessor: "enabled"}} value={item.enabled} id={item.id} onPropChange={handleOnPropChange} />
    )
}

export default SwitchEnableDeliveryZone;