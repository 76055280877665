import { call, take, spawn, put } from "redux-saga/effects";

import {
  CHECK_USER_AUTHENTICATION,
  AUTHENTICATION,
  actionAuthenticationSet,
  AUTHENTICATION_DEAUTHORIZE
} from "../reducers/authentication/authentication-actions";

import { refreshAuth } from "../../api/auth-requests";

import {
  getUserConnectionData,
  checkAccessToken,
  checkRefreshToken,
  removeAccessToken,
  logout,
  getRememberMe,
  getCurrentUserName
} from "../../utils/auth-util";

import {
  createSaga,
  ErrorData,
  actionCreateSuccess,
  actionCreateError
} from "../../utils/sagaHelper";
import { requestResultIsOk } from "../../utils/request-util";

function* checkUserAuthenticationSaga(action) {
  yield* createSaga(function*() {
    if (!checkAccessToken()) {
      if (checkRefreshToken()) {
        const rememberMe = getRememberMe();
        const username = getCurrentUserName();
        const requestResult = yield call(refreshAuth);
        if (requestResultIsOk(requestResult)) {
          yield put(actionCreateSuccess(AUTHENTICATION, { data: requestResult.data, rememberMe, username }));
          return getUserConnectionData();
        } else {
          let error = new ErrorData("Error refresh token");
          logout();
          yield put(actionCreateError(AUTHENTICATION_DEAUTHORIZE, error));
          return error;
        }
      } else {
        let error = new ErrorData("Error: check refresh token");

        logout();
        yield put(actionCreateError(AUTHENTICATION_DEAUTHORIZE, error));
        return error;
      }
    } else {
      yield put(actionAuthenticationSet({ isAuthenticated: true }));
      return getUserConnectionData();
    }
  }, action.type);
}

export default function*() {
  let task;
  while (true) {
    const action = yield take(CHECK_USER_AUTHENTICATION);
    if (task && task.isRunning()) continue;
    if (action.data && action.data.isRemoveAcccessToken) removeAccessToken();
    task = yield spawn(checkUserAuthenticationSaga, action);
  }
}
