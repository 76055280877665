import i18n from '../i18n';

import User from './user';
import { getPersonalSettings } from './userModel';

import {
  NOTIFICATION_COLOR_TYPE,
  SEVERITY_NOTIFICATION,
  SOUND_NOTIFICATION,
  TYPE_NOTIFICATION,
  TYPE_STORE_DATA,
} from '../utils/enums';
import { getStoreDataByType } from './storeData';
import {getPlaceOrAddress} from "./order.js";

export function getMessageForSeverity(alarm) {
  const { type, args } = alarm;

  switch (type) {
    case SEVERITY_NOTIFICATION.orderNotDeliveredInTime:
      return i18n
        .t("notification.ORDER_NOT_DELIVERED_IN_TIME")
        .replace("{0}", getArgsForNotDeliveredInTime(args));
    case SEVERITY_NOTIFICATION.courierLost:
      return i18n
        .t("notification.COURIERS_LOST_GROUP")
        .replace("{0}", getArgsForCourierLost(args));
    case SEVERITY_NOTIFICATION.courierStopped:
      return i18n
        .t("notification.COURIERS_STOPPED_GROUP")
        .replace("{0}", getArgsForCourierLost(args));
    case SEVERITY_NOTIFICATION.boxLost:
      return i18n.t("notification.BOX_LOST_GROUP");
    case SEVERITY_NOTIFICATION.orderCanLate:
      return i18n
        .t("notification.ORDER_BEGINING_TO_BE_LATE")
        .replace("{0}", getArgsForNotDeliveredInTime(args));
    case SEVERITY_NOTIFICATION.assistanceExists:
      return i18n
        .t("notification.ASSISTANCE_EXISTS_GROUP")
        .replace("{0}", getArgsForAssistInTime(args));
    case SEVERITY_NOTIFICATION.courierCommingBack:
      return i18n
        .t("notification.COURIERS_COMING_BACK_GROUP")
        .replace("{0}", getArgsForCourierLost(args));
    case SEVERITY_NOTIFICATION.courierWrongRoute:
      return i18n
        .t("notification.COURIER_WRONG_ROUTE_GROUP")
        .replace("{0}", getArgsForCourierLost(args));
    case SEVERITY_NOTIFICATION.courierReadyToDispatch:
      return i18n
        .t("notification.COURIERS_READY_TO_DISPATCH_GROUP")
        .replace("{0}", getArgsForCourierLost(args));
    case SEVERITY_NOTIFICATION.orderIsNotAssignedTimeout:
      return i18n
        .t("notification.ORDER_NOT_ASSIGNED")
        .replace("{0}", getArgsForCourierLost(args));
    case SEVERITY_NOTIFICATION.consumerOrderCreated:
          return i18n
            .t("notification.CONSUMER_ORDER_CREATED");
    default:
      return "";
  }
}

export function isNeedToAlarm(type) {
  const personalSettings = getPersonalSettings(User.instance.getCurrentUserInfo());
  // if(!personalSettings || !personalSettings.roles) return false;
  // if(personalSettings.roles.depot || personalSettings.roles.deliveryManager) return true;

  switch (type) {
    case SEVERITY_NOTIFICATION.orderNotDeliveredInTime:
      return personalSettings.orderNotDeliveredInTimeNotificationEnabled;
    case SEVERITY_NOTIFICATION.courierCommingBack:
      return personalSettings.courierComingBackEnabled;
    case SEVERITY_NOTIFICATION.assistanceExists:
      return personalSettings.assistanceExistsEnabled;
    case SEVERITY_NOTIFICATION.orderCanLate:
      return personalSettings.orderCanLateEnabled;
    case SEVERITY_NOTIFICATION.courierReadyToDispatch:
      return personalSettings.courierReadyToDispatchEnabled;
    case SEVERITY_NOTIFICATION.orderIsNotAssignedTimeout:
      return true;
    case SEVERITY_NOTIFICATION.courierWrongRoute:
      return personalSettings.courierWrongRouteEnabled;
    case SEVERITY_NOTIFICATION.courierLost:
      return personalSettings.courierLostEnabled;
    case SEVERITY_NOTIFICATION.courierStopped:
      return personalSettings.courierStoppedEnabled;
    case "newOrderCreated":
      return personalSettings.newOrderSoundNotification;
    case "newConsumerOrderCreatedFromLoop":
      return true;
    case "newUnreadMessage":
      return true;
    case "newDialogItem":
      return true;
    case SEVERITY_NOTIFICATION.consumerOrderCreated:
      return personalSettings.consumerOrderCreatedNotificationEnabled;
    default:
      return false;
  }
}

export function isNeedToAddSeverity(type) {
  const personalSettings = getPersonalSettings(
    User.instance.getCurrentUserInfo()
  );

  switch (type) {
    case SOUND_NOTIFICATION.newOrderCreated:
      return personalSettings.newOrderSoundNotification;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.orderNotDeliveredInTime]:
      return personalSettings.orderNotDeliveredInTimeNotificationEnabled;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.courierCommingBack]:
      return personalSettings.courierComingBackEnabled;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.assistanceExists]:
      return personalSettings.assistanceExistsEnabled;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.orderCanLate]:
      return personalSettings.orderCanLateEnabled;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.courierReadyToDispatch]:
      return personalSettings.courierReadyToDispatchEnabled;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.orderIsNotAssignedTimeout]:
      return personalSettings.courierReadyTimeDelay;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.courierWrongRoute]:
      return personalSettings.courierWrongRouteEnabled;
    case SOUND_NOTIFICATION[SEVERITY_NOTIFICATION.consumerOrderCreated]:
      return personalSettings.consumerOrderCreatedNotificationEnabled;
    default:
      return true;
  }
}

export function getTypeSeverityNotification(severityNotificatioType) {
  switch (severityNotificatioType) {
    case SEVERITY_NOTIFICATION.boxLost:
    case SEVERITY_NOTIFICATION.orderNotDeliveredInTime:
      return TYPE_NOTIFICATION.errorType;
    case SEVERITY_NOTIFICATION.courierStopped:
    case SEVERITY_NOTIFICATION.courierReadyToDispatch:
    case SEVERITY_NOTIFICATION.orderIsNotAssignedTimeout:
    case SEVERITY_NOTIFICATION.orderCanLate:
      return TYPE_NOTIFICATION.warningType;
    case SEVERITY_NOTIFICATION.assistanceExists:
    case SEVERITY_NOTIFICATION.courierLost:
    case SEVERITY_NOTIFICATION.courierWrongRoute:
      return TYPE_NOTIFICATION.importantWarningType;
    case SEVERITY_NOTIFICATION.courierCommingBack:
      return TYPE_NOTIFICATION.notificationType;
    default:
      return TYPE_NOTIFICATION.warningType;
  }
}

export function getMessageForSeverityTypeNotification(type) {
  switch (type) {
    case TYPE_NOTIFICATION.warningType:
      return {
        header: i18n.t("notification.WARNING_HEADER"),
        imgError: "warning",
        colorBackground: NOTIFICATION_COLOR_TYPE.blue
      };
    case TYPE_NOTIFICATION.importantWarningType:
      return {
        header: i18n.t("notification.IMPORTANT_WARNING_HEADER"),
        imgError: "warning",
        colorBackground: NOTIFICATION_COLOR_TYPE.red
      };
    case TYPE_NOTIFICATION.errorType:
      return {
        header: i18n.t("notification.ERROR_HEADER"),
        imgError: "error",
        colorBackground: NOTIFICATION_COLOR_TYPE.red
      };
    case TYPE_NOTIFICATION.notificationType:
      return {
        header: i18n.t("notification.NOTIFICATION_HEADER"),
        imgError: "notification",
        colorBackground: NOTIFICATION_COLOR_TYPE.blue
      };
    default:
      return {};
  }
}

export function getAlarms(alarms) {
  if (!alarms || !alarms.length) return [];
  const allowMultiNotification = [SEVERITY_NOTIFICATION.orderNotDeliveredInTime, SEVERITY_NOTIFICATION.orderCanLate];
  return alarms
    .filter(alarm=> isNeedToAlarm(alarm.type))
    ?.sort((a, b) => new Date(b.time) - new Date(a.time))
    .reduce((accumulator, alarm) => {
      if (!accumulator.find(f => f.type === alarm.type) || allowMultiNotification.includes(alarm.type)) {
        accumulator.push(alarm);
      }

      return accumulator;
    }, []);

}

export const getIsHideTopNotification = notification => {
  return !notification || !notification.mainMessage;
}

function getArgsForNotDeliveredInTime(args) {
  const orders = getStoreDataByType(TYPE_STORE_DATA.noAssistanceOrders);
  return (
    args.reduce((acc, arg) => {
      const order = orders.find(f => f.id === arg);
      if (order) {
        acc.push(`${order.orderId || ''}${order.orderId ? ',' : ''} ${getPlaceOrAddress(order)}`.trim());
      }
      return acc;
    }, [])[0] || ""
  );
}

function getArgsForAssistInTime(args) {
  const orders = getStoreDataByType(TYPE_STORE_DATA.orders);
  return (
    args.reduce((acc, arg) => {
      const order = orders.find(f => f.id === arg);
      if (order) {
        acc.push(order.orderId || "");
      }
      return acc;
    }, [])[0] || ""
  );
}

function getArgsForCourierLost(args) {
  const couriers = getStoreDataByType(TYPE_STORE_DATA.couriers);
  return (
    args.reduce((acc, arg) => {
      const courier = couriers.find(f => f.userId === arg);
      if (courier) {
        acc.push(courier.firstName + " " + courier.lastName);
      }
      return acc;
    }, [])[0] || ""
  );
}
