import React from "react";
import "./index.scss";
import {getFleetVehicleType} from "../../../services/restaurant.js";
import IconButton from "../IconButton/IconButton.jsx";

export const FleetVehicleType = (vehicleId) => {
    const vehicles = getFleetVehicleType();
    const findVehicle = vehicles.find((e) => e.key === vehicleId);

    if(vehicles && findVehicle){
        return <div className={'fleetVehicleTypeBlock'}>
            <IconButton isIcon={true} iconName={`${findVehicle.icon}`} />
        </div>;
    }
    return <></>;
}
