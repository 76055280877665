import React, {useEffect, useState} from "react";
import classNames from "classnames";
import "./index.scss";
import i18n from "../../../i18n.js";
import {useSelector} from "react-redux";
import {getPeriodsByDate} from "../../../services/restaurant.js";

export const PeriodDatesAndStage = ({periodId}) => {
    const [parsedStage, setParsedStage] = useState('');
    const [date, setDate] = useState("");
    const [stage, setStage] = useState("");

    const periods = useSelector(state => state.reconciliations?.data?.summary?.periods);

    useEffect(() => {

        if(periods?.length > 0){
            const period = periods.find((e) => e.id === periodId);
            if(period){
                const result = getPeriodsByDate(period.startDate,period.endDate);
                setStage(period.stage);
                setDate(result);
            }
        }

    },[periods, periodId]);

    const getStageByNumber = (stage) => {
        let stageResult = '';
        switch(stage) {
            case 0:
                stageResult = i18n.t('stagePeriods.NOT_YET_APPROVED'); break;
            case 1:
                stageResult = i18n.t('stagePeriods.APPROVED'); break;
            case 2:
                stageResult = i18n.t('stagePeriods.EXECUTION'); break;
            case 3:
                stageResult = i18n.t('stagePeriods.PAYMENT_READY'); break;
            case 4:
                stageResult = i18n.t('stagePeriods.DECLINED'); break;
            default:
                stageResult = '';
        }
        return stageResult;
    }

    useEffect(() => {
        setParsedStage(getStageByNumber(stage));
    }, [stage]);


    return <div className={classNames("periodDatesAndStage")}>
        <span className={classNames("periodDatesAndStageDate")}>{date}</span>
        <div className={classNames('periodDatesAndStageStage', `periodDatesAndStageStage${stage}`)}>
            <span className={classNames("periodDatesAndStageCircle")}></span>
            <span>{parsedStage}</span>
        </div>
    </div>

}
