import MenuModel from './menuModel';

import { TYPE_SECTION_MESSAGE_MODEL } from '../utils/enums';
import { getCurrentDepotByBusiness, getRestaurantPaymentEnabled } from '../services/restaurant';
import { ORDERING_MENU_GET } from '../store/reducers/restaurant/restaurant-actions';

export function createMessageMenuSettings() {
    return [
        new MenuModel({
            pathname: TYPE_SECTION_MESSAGE_MODEL.messageInfo,
            title: 'settings.messageSettings.INFO',
            actionLoad: ORDERING_MENU_GET,
            icon: 'depotInfo',
            isShow: () => getRestaurantPaymentEnabled(),
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_MESSAGE_MODEL.messageInfo),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MESSAGE_MODEL.messageGallery,
            actionLoad: ORDERING_MENU_GET,
            title: 'settings.messageSettings.GALLERY',
            icon: 'businessGallery',
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            isShow: () => getRestaurantPaymentEnabled(),
            active: location => getIsActive(location, TYPE_SECTION_MESSAGE_MODEL.messageGallery),
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3] &&
        splitCurrentLocation[4] === splitType[4] &&
        splitCurrentLocation[5] === splitType[5]
    );
}
