import React, { useEffect, useState } from "react";
import {useLocation, withRouter} from "react-router";
import classNames from "classnames";
import "./index.scss";

import InfoMessageService from "../../../services/infoMessage";
import { isRTL } from "../../../services/userModel";
import { getCurrentUserName } from "../../../utils/auth-util";
import {defaultIcons, excludedUrls} from "../../../utils/constants";

import UserControlsMenu from "../UserControlsMenu";
import HeaderUserControls from "../HeaderUserControls";
import Menu from "../Menu";
import AccountTopNotification from "../AccountTopNotification";
import IconButton from "../IconButton/IconButton";
import CurrentTime from '../CurrentTime';

const Header = ({t, showMenu, history }) => {
  const currentUserName = (getCurrentUserName() || "").split("@")[1] || "";
  const logo = defaultIcons[currentUserName] || defaultIcons.default;
  const isCheck = InfoMessageService.instance.checkAction();
  const isShowMenu = showMenu && !isCheck;
  const [isMobileMenuOpened, toggleMobileMenu] = useState(false);
  const isMobile = window.innerWidth < 768
  const isLoginPage = useLocation().pathname.includes('/login')

  useEffect(
    () => {
      // prevent background scroll on mobile devices when menu is opened
      const elem = document.getElementById("mainApp");
      if (elem) {
        if (isMobileMenuOpened) {
          elem.style.overflow = "hidden";
        } else {
          elem.style.overflow = "auto";
        }
      }
    },
    [isMobileMenuOpened]
  );

  const getShowNotification = () =>
    history &&
    history.location &&
    !excludedUrls.includes(history.location.pathname);

  const renderMobileMenu = () => {
    return (
      <div className="headerMenuMobileButton">
        {isShowMenu && <HeaderUserControls />}
        <IconButton
          isIcon={true}
          iconName={"mobileMenu"}
          onClick={() => toggleMobileMenu(!isMobileMenuOpened)}
        />
        <div
          className={classNames(
            "mobileMenuWrapper",
            isMobileMenuOpened && "mobileMenuWrapperOpen"
          )}
        >
          <div className="mobileMenuInner">
            {showMenu && <UserControlsMenu showItemsInPlace />}
            {showMenu && (
              <Menu hideMenu={isCheck} toggleMobileMenu={toggleMobileMenu} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <header id="header" className={classNames({ rtl: isRTL() })}>
      {getShowNotification() && <AccountTopNotification />}
      <div className={classNames("headerData")}>
        <div className={classNames("leftSide")}>
          {logo && (
            <a href="/" className={classNames("logo")}>
              <img alt="Delivr+" src={logo} />
            </a>
          )}
          {!logo && <div className={classNames("logo", "empty")} />}
        </div>
        <div className={classNames("centerSide")}>
          {showMenu && <Menu hideMenu={isCheck} toggleMobileMenu={toggleMobileMenu} />}
          {showMenu && renderMobileMenu()}
        </div>
        <div className={classNames("rightSide")}>
            {showMenu && <UserControlsMenu />}
            {isShowMenu && <HeaderUserControls />}
            {!isMobile && !isLoginPage && <CurrentTime />}
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
