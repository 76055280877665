import React from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import { WAITER_TYPES } from '../../../utils/enums';

import Waiter from '../Waiter/Waiter';

const IconButton = ({ text, tooltipText, tooltipIsHTML, iconName, isIcon, isFullWH, active, disabled, onClick }) => (
  <div className={classNames('iconButton', { fullWH: isFullWH, disabled: disabled, active: active })}>
    {!isIcon && (
      <Waiter
        type={WAITER_TYPES.buttonSpan}
        tooltipText={tooltipText || text || ''}
        tooltipIsHTML={tooltipIsHTML}
        useWrapClass={classNames('btn', 'confirm', 'controlAction')}
        useClass={classNames('actionIcon', iconName)}
        useTextClass={classNames('actionText', iconName)}
        spanText={text}
        stopPropagation={true}
        disabled={disabled}
        handleOnClick={onClick}
      />
    )}
    {isIcon && iconName && (
      <Waiter
        type={WAITER_TYPES.span}
        disabled={disabled}
        tooltipText={tooltipText || text || ''}
        tooltipIsHTML={tooltipIsHTML}
        useClass={classNames('actionIcon', iconName)}
        stopPropagation={true}
        handleOnClick={onClick}
      />
    )}
  </div>
);

export default withTranslation()(IconButton);
