import { UPDATE } from "../common-actions";

export const ACTIVE_STATE_CYCLES = "ACTIVE_STATE_CYCLES";
export const ACTIVE_STATE_CYCLES_UPDATE = ACTIVE_STATE_CYCLES + UPDATE
export const ACTIVE_STATE_CYCLES_SET = "ACTIVE_STATE_CYCLES_SET"
export const ACTIVE_STATE_CYCLE = "ACTIVE_STATE_CYCLE"
export const ACTIVE_STATE_CYCLE_UPDATE = ACTIVE_STATE_CYCLE + UPDATE


export function actionActiveStateCyclesSet(data) {
  return { type: ACTIVE_STATE_CYCLES_SET, data, additionalParams: { batched: true } };
}

export function actionActiveStateCyclesUpdate(data) {
  return { type: ACTIVE_STATE_CYCLES_UPDATE, data, additionalParams: { batched: true } };
}

export function actionActiveStateCycleUpdate(data) {
  return { type: ACTIVE_STATE_CYCLE_UPDATE, data, additionalParams: { batched: true } };
}