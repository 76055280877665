import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import {createAction} from '../../../utils/sagaHelper';

import ColumnTitleWithIcon from '../ColumnTitleWithIcon';
import Dropdown from '../Dropdown';
import { columnIsRequired } from '../../../services/filter.js';

const initValue = { key: '', value: '' };

const SectionItemSelect = ({ ...props }) => {
  const {
    column,
    values,
    value: valueKey,
    defaultValue,
    item,
    getData,
    onPropChange,
    isDisabled,
    isDirty,
    setIsDirty,
  } = props;
  const [value, setValue] = useState(initValue);

  const valueByKey = values?.find(el => el?.key === valueKey) || column?.values?.find(el => el?.key === valueKey);

  if (value.key !== valueKey && valueByKey) {
    setValue(valueByKey);
  }

  const handleOnChange = val => {
    if (column.action && val) {
      getData(column.action, val.key);
    }
    if (value.key !== val.key) {
      if (column.onPropChange) {
        column.onPropChange(val, column, onPropChange);
      } else {
        const dataToRefresh = column.dataToRefresh || {};
        onPropChange({ ...dataToRefresh, [column.accessor]: val['key'] });
      }
    }

    setValue(val);
  };

  useEffect(() => {
    if (column.action && valueKey && !isDirty) {
      setIsDirty(true);

      getData(column.action, valueKey);
    }
    // eslint-disable-next-line
  }, []);

  const DropdownValue = value.value || '';

  if (column.isHideByLength && !(values || []).length) return <></>;
  let placeholder = (defaultValue && defaultValue['value']) || '';
  if (column && column.defaultInputValue) {
    const newPlaceholder = column.defaultInputValue(item);
    if (newPlaceholder && newPlaceholder.value) {
      placeholder = newPlaceholder.value;
    }
  }

  return (
    <div
      key={`propItem-${column.accessor}`}
      className={classNames('sectionItemSelect', {
        required: column.isRequired,
        isInline: column.isInline,
        isInlineRow: column.isInlineRow,
        withoutBorder: column?.withoutBorder,
      })}
      style={{ width: column?.width }}
    >
      <ColumnTitleWithIcon item={item} column={column} onPropChange={onPropChange} />
      <Dropdown
        canPrint={column.hasOwnProperty('isCanPrint') ? column?.isCanPrint : true}
        isCanBeEmpty={column.hasOwnProperty('isCanBeEmpty') ? column?.isCanBeEmpty : true}
        values={column.values ? column.values || [] : values || []}
        placeholder={placeholder}
        labelField='value'
        valueField='key'
        searchBy='value'
        value={DropdownValue}
        isRequired={columnIsRequired(column, item)}
        isAccentuationByValue={column.isAccentuationByValue}
        isUpdateAfterLoad={column?.isUpdateAfterLoad}
        isColors={column.isColors}
        isSearch={column?.isSearch}
        readonly={column.isReadonly || isDisabled}
        onSelect={val => handleOnChange(val)}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getData: (action, data) => dispatch(createAction(action, data)),
  };
};

export default connect(null, mapDispatchToProps)(SectionItemSelect);
