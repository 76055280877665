import {
  UPDATE_ORDER_MARKER,
  UPDATE_DEPOT_MARKER,
  UPDATE_COURIER_MARKER,
  UPDATE_COURIER_LOCATION_MARKER,
  REMOVE_MARKER,
  SET_SHAKE_MARKER,
  REMOVE_MARKERS,
  UPDATE_ORDERS_MARKERS,
  UPDATE_ALL_COURIERS_MARKERS,
  UPDATE_DEPOTS_MARKERS,
  UPDATE_EXTERNAL_CYCLES_MARKERS,
  MARKER_UPDATE
} from "./marker-actions";

import * as Sentry from "@sentry/react";
import Markers from "../../../services/markers";

import { TYPE_MARKER, ORDER_TYPE } from "../../../utils/enums";
import {isSameObjects, arrayToMap} from "../../../utils/objects-util";
import { REMOVE_ALL_DATA } from "../common-actions";
import { locationIsEmpty } from "../../../services/order";

const initState = {
  data: [],
  dataMap: new Map(),
  orderShakeId: null
};

const mapToArray = (map) => {
  return Array.from(map, ([key, value]) => ({...value }));
}

const isSameMarkers = (state, marker) => {
  const oldMarker = state.dataMap.has(marker.id);
  if (!oldMarker) {
    return false;
  }

  const isSame =
    isSameObjects(oldMarker.location, marker.location) &&
    isSameObjects(oldMarker.icon, marker.icon);
  switch (marker.markerType) {
    case TYPE_MARKER.order:
      return isSame && oldMarker.groupId === marker.groupId;
    case TYPE_MARKER.courier:
      return isSame && oldMarker.groupId === marker.groupId;
    case TYPE_MARKER.depot:
      return isSame;
    default:
      return false;
  }
};

export default function markers(state = initState, action) {
  let markers = state.data.slice();
  const dataMap = state.dataMap;

  switch (action.type) {
    case REMOVE_ALL_DATA:
      return {...initState, dataMap: new Map()};
    case REMOVE_MARKERS:
      return {
        ...state,
        dataMap: arrayToMap(mapToArray(state.dataMap).filter(f => f.markerType === TYPE_MARKER.depot || f.markerType === TYPE_MARKER.restaurant))
      };
    case UPDATE_ALL_COURIERS_MARKERS:
      action.data.forEach((user) => {
        let courierMarker = Markers.instance.createMarker(TYPE_MARKER.courier, user);

        if (courierMarker && !isSameMarkers(state, courierMarker)) {
          if (courierMarker && locationIsEmpty(courierMarker.location)) {
            Sentry.captureException(
              `Error UPDATE_ALL_COURIERS_MARKERS. Marker id: ${
                courierMarker.id
              }. Location: ${courierMarker.location}`
            );
          }
          dataMap.set(courierMarker.id, courierMarker)
        }
      });

      return {
        ...state,
        dataMap: dataMap,
      };
    case UPDATE_COURIER_MARKER:
      let courier = action.data;
      if (!courier) {
        return state;
      }

      const oldMarker = state.dataMap.get(courier.userId);
      if (
        oldMarker &&
        isSameObjects(oldMarker.location, courier.location) &&
        oldMarker.location
      ) {
        return state;
      }

      let courierMarker = Markers.instance.createMarker(TYPE_MARKER.courier, courier);

      if (!courierMarker || isSameMarkers(state, courierMarker)) {
        return state;
      }

      dataMap.set(courier.id, courier);

      return {
        ...state,
        dataMap: dataMap,
      };
    case UPDATE_DEPOTS_MARKERS:
      action.data.forEach((item) => {
        const depotMarker = Markers.instance.createMarker(TYPE_MARKER.depot, item);
        if (depotMarker && !isSameMarkers(state, depotMarker)) {
          dataMap.set(depotMarker.id, depotMarker);
        }
      });

      return {
        ...state,
        dataMap: dataMap,
      };
    case UPDATE_DEPOT_MARKER:
      let depot = action.data;
      if (!depot) return state;

      const depotMarker = Markers.instance.createMarker(TYPE_MARKER.depot, depot);
      if (!depotMarker || isSameMarkers(state, depotMarker)) return state;

      dataMap.set(depotMarker.id, depotMarker);

      return {
        ...state,
        dataMap: dataMap,
      };
    case UPDATE_ORDERS_MARKERS:
      // const groupsOrdersMarkers = action.data.items.reduce(
      action.data.items.forEach(
        (currentValue) => {
          if (!currentValue.groupOrders || !currentValue.groupOrders.length) {
            return;
          }

          // const newMarkers = currentValue.groupOrders.reduce(
          currentValue.groupOrders.forEach(
            (order) => {
              if (
                order.type !== ORDER_TYPE.pickUp &&
                order.location &&
                !order.type
              ) {
                let marker = Markers.instance.createMarker(TYPE_MARKER.order,
                  order,
                  currentValue.group,
                  currentValue.user,
                  false,
                  true,
                  action.data.depotsMap.get(order.depotId)
                );

                if (marker) {
                  marker.location = order.location;
                  marker.zIndex = 2;

                  if (!isSameMarkers(state, marker)) {
                    dataMap.set(marker.id, marker);
                    // accumulatorMarkers.push(marker);
                  }
                }
              }

              // return accumulatorMarkers;
            },
            []
          );

          // return accumulator.concat(newMarkers);
        },
        []
      );

      // if (!groupsOrdersMarkers.length) {
      //   return state;
      // }

      // groupsOrdersMarkers.forEach(marker => dataMap.set(marker.id, marker));

      return {
        ...state,
        dataMap: dataMap,
      };
    case UPDATE_ORDER_MARKER:
      let orders = action.data.groupOrders && action.data.groupOrders.filter(f => f.type !== ORDER_TYPE.pickUp);

      if (!action.data.group || !orders) return state;

      orders.forEach((order) => {
        if (order.location && !order.type) {
          let marker = Markers.instance.createMarker(TYPE_MARKER.order,
            order,
            action.data.group,
            action.data.user,
            false,
            false,
            action.data.depots.find(f=> f.id === order.depotId)
          );

          if (marker) {
            marker.location = order.location;
            marker.zIndex = 2;

            if (!isSameMarkers(state, marker)) {
              dataMap.set(marker.id, marker)
            }
          }
        }
      });

      return {
        ...state,
        dataMap: dataMap,
      };
    case UPDATE_COURIER_LOCATION_MARKER:
      let courierLocations = action.data.data;
      const ids = action.data.data.map(m => m.courierId);
      markers = markers.reduce((accumulator, currentValue) => {
        //Return if not exist in courier locations
        if (
          currentValue.markerType === TYPE_MARKER.courier &&
          !ids.includes(currentValue.id)
        )
          return accumulator;

        let index = courierLocations
          .map(m => m.courierId)
          .indexOf(currentValue.id);
        if (index > -1) {
          if (!locationIsEmpty(courierLocations[index].location))
            accumulator.push({
              ...currentValue,
              location: courierLocations[index].location
            });
          courierLocations.splice(index, 1);
        } else {
          if (!locationIsEmpty(currentValue.location))
            accumulator.push(currentValue);
        }
        return accumulator;
      }, []);

      if (courierLocations && courierLocations.length > 0) {
        courierLocations.forEach(courierLocation => {
          let courier = action.data.users.find(
            user => user.userId === courierLocation.courierId
          );

          if (courier) {
            if (
              Markers.instance.canShowMarker(
                courier,
                action.data.orders.filter(f => f.groupId === courier.userId)
              )
            ) {
              let newMarker = Markers.instance.createMarker(
                TYPE_MARKER.courier,
                action.data.users.find(
                  user => user.userId === courierLocation.courierId
                )
              );

              if (newMarker && locationIsEmpty(newMarker.location)) {
                Sentry.captureException(
                  `Error courierLocationsOther. Marker id: ${
                    newMarker.id
                  }. Location: ${newMarker.location}`
                );
              }

              if (newMarker) markers = markers.concat([newMarker]);
            }
          }
        });
      }

      markers.forEach(marker => dataMap.set(marker.id, marker));

      return {
        ...state,
        dataMap: dataMap,
      };
    case REMOVE_MARKER:
      (action.data || []).forEach(id => {
        if(dataMap.has(id)) {
          dataMap.delete(id);
        }
      })
      return {
        ...state,
        dataMap: dataMap,
      };
    case SET_SHAKE_MARKER:
      return {
        ...state,
        orderShakeId: action.data.id
      };
    case UPDATE_EXTERNAL_CYCLES_MARKERS:
      action.data.forEach(marker => {
        dataMap.set(marker.id, marker);
      });
      return {
        ...state,
        dataMap: dataMap,
      };
    case MARKER_UPDATE:
      const markerMap = dataMap.get(action.data.id);
      dataMap.set(action.data.id, {...(markerMap || {}),...action.data})
      return {
        ...state,
        dataMap: dataMap
      };
    default:
      return state;
  }
}
