import environment from "environment";
import DatabaseStorage from "./databaseStorage";
import { PUBLIC_DATA_KEYS } from "../utils/enums";
import { getUserConnectionData } from "../utils/auth-util";
import { TYPE_STORAGE } from "../utils/enums";
import { isEmpty } from "../utils/objects-util";

const DIVIDER = "_";
const TIME_KEY = "time";
const DIVIDER_TIME_KEY = DIVIDER + TIME_KEY;

export function getStoreData(type, id, isPublic) {
  const key = isPublic ? id : generateKey(id);

  switch (type) {
    case TYPE_STORAGE.local:
    case TYPE_STORAGE.session:
      const localStorageData = localStorage[key];
      return !localStorageData ? sessionStorage[key] : localStorageData;
    case TYPE_STORAGE.db:
      return DatabaseStorage.instance.then(instance =>
        instance.getStoreDataByKey(key)
      );
    default:
      break;
  }
}

export function saveStoreData(type, id, isPublic, data, expires) {
  const key = isPublic ? id : generateKey(id);
  if (isNaN(expires)) expires = environment.dataExpiresTime;
  const date = new Date();
  const expireTime =
    expires === 0 ? 0 : date.setSeconds(date.getSeconds() + expires);

  return new Promise((resolve, reject)=> {
    switch (type) {
      case TYPE_STORAGE.local:
        localStorage.setItem(key + DIVIDER_TIME_KEY, expireTime);
        localStorage.setItem(key, data);
        resolve();
        break;
      case TYPE_STORAGE.session:
        sessionStorage.setItem(key + DIVIDER_TIME_KEY, expireTime);
        sessionStorage.setItem(key, data);
        resolve();
        break;
      case TYPE_STORAGE.db:
        return DatabaseStorage.instance.then(instance =>
          instance.addToStoreData(key, data)
        );
      default:
        resolve();
        break;
    }
  });  
}

export function getStoreDataKeys() {
  return DatabaseStorage.instance.then(instance => instance.getStoreDataKeys());
}

export function getStoreDataByKey(id) {
  return DatabaseStorage.instance.then(instance =>
    instance.getStoreDataByKey(id)
  );
}

export function removeStoredData() {
  removeData(PUBLIC_DATA_KEYS.accessToken);
  removeData(PUBLIC_DATA_KEYS.refreshToken);
  removeData(PUBLIC_DATA_KEYS.tokenType);
  removeData(PUBLIC_DATA_KEYS.rememberMe);
  removeData(PUBLIC_DATA_KEYS.currentUserName);
}

export function checkDataExpires(id, isPublic) {
  const timeKey = id.endsWith(DIVIDER_TIME_KEY)
    ? id
    : (isPublic ? id : generateKey(id)) + DIVIDER_TIME_KEY;
  const expireTime = getData(timeKey, true);
  return expireTime === 0 ? false : !expireTime || expireTime < new Date();
}

export function getData(id, isPublic) {
  const key = isPublic ? id : generateKey(id);
  const localStorageData = localStorage[key];
  return !localStorageData ? sessionStorage[key] : localStorageData;
}

export function saveUserData(response, rememberMe, currentUserName) {
  saveStoreData(
    TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.tokenType,
    true,
    response.token_type,
    0
  );
  saveStoreData(
    TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.accessToken,
    true,
    response.access_token,
    response.expires_in || environment.token.lifecookies
  );
  saveStoreData(
    TYPE_STORAGE.local,
    PUBLIC_DATA_KEYS.rememberMe,
    true,
    rememberMe,
    0
  );
  saveStoreData(
    !rememberMe ? TYPE_STORAGE.session : TYPE_STORAGE.local,
    PUBLIC_DATA_KEYS.refreshToken,
    true,
    response.refresh_token,
    0
  );
  saveStoreData(
    TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.currentUserName,
    true,
    currentUserName,
    0
  );
}

export function getDecodedData(id, isPublic) {
  const data = getData(id, isPublic);
  let decodedData = {};

  if (data) {
    const splitted = decodeStr(data).split(".");
    splitted.forEach(encoded => {
      if (encoded.indexOf("-") > -1 || encoded.indexOf("_") > -1) return;
      try {
        const decoded = decodeURIComponent(window.atob(encoded))
        if(!isEmpty(decoded)) {
          Object.assign(decodedData, JSON.parse(decoded));
        }
      } catch (ex) {}
    });
  }
  return decodedData;
}

function decodeStr(input) {
  input = (input || "").replace(/-/g, '+').replace(/_/g, '/');

  const pad = input.length % 4;
  if(pad && pad !== 1) {
    input += new Array(5-pad).join('=');
  }

  return input;
}

export function generateKey(id) {
  const restaurantKey = getUserConnectionData();
  return restaurantKey.rest + DIVIDER + id;
}

export function generateIdsFromKeys(keys) {
  const restaurantKey = getUserConnectionData();
  const filteredKeys = keys.filter(key =>
    key.includes(restaurantKey.rest + DIVIDER)
  );
  return filteredKeys.map(key => key.replace(restaurantKey.rest + DIVIDER, ""));
}

export function removeData(id) {
  let key, timeKey;
  if (id.endsWith(DIVIDER_TIME_KEY)) {
    key = id.substring(0, id.indexOf(DIVIDER_TIME_KEY));
    timeKey = id;
  } else {
    key = generateKey(id);
    timeKey = key + DIVIDER_TIME_KEY;
    const idTime = id + DIVIDER_TIME_KEY;
    localStorage.removeItem(id);
    localStorage.removeItem(idTime);
    sessionStorage.removeItem(id);
    sessionStorage.removeItem(idTime);
  }
  localStorage.removeItem(key);
  localStorage.removeItem(timeKey);
  sessionStorage.removeItem(key);
  sessionStorage.removeItem(timeKey);
}
