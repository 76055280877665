
import SectionModel from "../../models/sectionModel";
import {
    CONTROL_TYPE, MARKETPLACE_SETTINGS_MODEL, MODEL_CONTROL_TYPE, MODEL_TYPE,
} from "../../utils/enums";
import {
    DRIVER_TIP,
    DRIVER_TIP_GET
} from "../../store/reducers/restaurant/restaurant-actions";
import {getInitModel} from "../initModels";
import {createControlModel} from "../controlModelFactory";
import {isMobileWidth} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createDriverTipSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "settings.driverTip.MAX_VALUE",
                accessor: "maxValue",
                isEditable: true,
                type: CONTROL_TYPE.number
            },
            {
                title: "settings.driverTip.PREDEFINED_VALUES",
                accessor: "predefinedValues",
                isEditable: true,
                isNumber: true,
                type: CONTROL_TYPE.arrayItems
            },
            {
                title: "settings.driverTip.ENABLED_LABEL",
                accessor: "enabled",
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            }
        ],
        actionName: DRIVER_TIP,
        actionLoad: DRIVER_TIP_GET,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: MARKETPLACE_SETTINGS_MODEL.driverTip,
        saveControlsConfig: {
            cancel: {
                title: "basic.RESET_BUTTON",
                action: ()=> {}, isInit: true
            }
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            })
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: "header.menu.MARKETPLACE_SETTINGS",
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.DELIVERY',
            }),
        ],
        label: "settings.driverTip.MENU_LABEL",
        mappingLambda: state => getInitModel(MODEL_TYPE.driverTip, state.restaurant.data.driverTip)
    });
}
