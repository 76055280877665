import { MODEL_TYPE } from "../utils/enums";
import { getInitModel } from "../services/initModels";
import ColorService from "../services/color";

export function externalCycleModel(data) {
    const { 
        id, 
        providerName,
        provider,
        providerId,
        avatarUrl,
        controlCenterId,
        startTime,
        freeCancellation,
        approveValidity,
        state,
        orders,
        routeEstimation,
        driverInfo,
        priceInfo,
        color,
        travelData
    } = data || {};

    return {
        approveValidity,
        color: color || ColorService.instance.getColor(),
        controlCenterId: controlCenterId || "",
        driverInfo: driverInfo || getInitModel(MODEL_TYPE.driverInfoModel),
        freeCancellation: freeCancellation || false,
        id,
        isCourierGroup: true,
        isExternalGroup: true,
        orders: orders || [],
        priceInfo: priceInfo || getInitModel(MODEL_TYPE.priceInfoModel),
        provider, //enum: EXTERNAL_COURIER_PROVIDER
        providerName: providerName || "",
        routeEstimation: routeEstimation || getInitModel(MODEL_TYPE.routeEstimation),
        travelData: travelData || null,
        startTime,
        state, //enum: EXTERNAL_CYCLE_STATE,
        userId: id,
        providerId: providerId,
        avatarUrl: avatarUrl,
    }
};