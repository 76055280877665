import React, { useState } from 'react';
import classNames from 'classnames';
import IconButton from '../IconButton';
import './style.scss';
import SearchBox from '../SearchBox';
import { useTranslation } from 'react-i18next';

const CurrentShiftDepotAssignSelect = ({ selectedCouriers, couriers, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  const [localSelectedCouriers, setLocalSelectedCouriers] = useState(selectedCouriers || []);
  const [searchString, setSearchString] = useState('');

  const handleOnConfirm = () => {
    onConfirm(localSelectedCouriers);
  };

  const handleOnAddDepot = c => {
    setLocalSelectedCouriers(cs => [...cs, { firstName: c.firstName, userId: c.userId }]);
  };

  const handleOnRemoveFromSelected = userId => {
    setLocalSelectedCouriers(cs => cs.filter(c => c.userId !== userId));
  };

  const filterCouriers = couriers => {
    const selectedIds = localSelectedCouriers?.map(c => c.userId);
    return couriers?.filter(
      c =>
        !selectedIds?.includes(c.userId) &&
        c.firstName
          ?.toLowerCase()
          ?.trim()
          ?.includes(searchString?.toLowerCase().trim())
    );
  };

  return (
    <div className={classNames('currentShiftDepotAssignSelect')}>
      <SearchBox placeholder={t('shifts.SEARCH_COURIERS')} useValue={searchString} onChange={setSearchString} />
      <div className={'couriers'}>
        <div className={classNames('selectedCouriers')}>
          {localSelectedCouriers.map(c => (
            <div key={`${c.firstName}-${c.userId}`} className={classNames('courier')}>
              {c.firstName}
              <IconButton isIcon iconName={'iconPlus'} onClick={() => handleOnRemoveFromSelected(c.userId)} />
            </div>
          ))}
        </div>
        <div className={'couriersList'}>
          {couriers &&
            filterCouriers(couriers).map(c => (
              <div
                key={`${c.firstName}-${c.userId}`}
                className={classNames('courier')}
                onClick={() => handleOnAddDepot(c)}
              >
                {c.firstName}
                <div className={'emptyCircle'} />
              </div>
            ))}
        </div>
      </div>
      <div className={'controls'}>
        <div className={classNames('btn', 'confirm')} onClick={handleOnConfirm}>
          {t('basic.APPLY_BUTTON')}
        </div>
        <div className={classNames('btn', 'denied')} onClick={onCancel}>
          {t('basic.CANCEL_BUTTON')}
        </div>
      </div>
    </div>
  );
};

export default CurrentShiftDepotAssignSelect;
