import {
  AUTHENTICATION,
  AUTHENTICATION_FAIL,
  AUTHENTICATION_DEAUTHORIZE_FAIL,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_SET
} from "./authentication-actions";
import { saveUserData } from "../../../services/dataStorage";
import { REMOVE_ALL_DATA } from "../common-actions";

const initState = {
  isAuthenticated: false,
  error: ""
};

export default function authentication(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case AUTHENTICATION:
      return {
        ...state
      };
    case AUTHENTICATION_SUCCESS:
      const { data, rememberMe, username } = action.data || {};
      if (!data && !username) {
        return state;
      }
      saveUserData(data, rememberMe, username);

      return {
        ...state,
        isAuthenticated: true,
        error: ""
      };
    case AUTHENTICATION_FAIL:
      return {
        ...state,
        error: action.error
      };
    case AUTHENTICATION_DEAUTHORIZE_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        error: action.error
      };
    case AUTHENTICATION_SET:
      return {
        ...state,
        isAuthenticated: action.data.isAuthenticated,
        error: ""
      };
    default:
      return state;
  }
}
