export const INIT = "INIT";
export const INIT_START = INIT + "_START";
export const INIT_START_SUCCESS = INIT_START + "_SUCCESS";
export const INIT_START_FAIL = INIT_START + "_FAIL";

export function init(payload) {
  return { type: INIT, isLoaded: false, additionalParams: { batched: true } };
}

export function initStart() {
  return { type: INIT_START, additionalParams: { batched: true } };
}
