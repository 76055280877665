import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import {
  getMessageForSeverity,
  getAlarms,
  getMessageForSeverityTypeNotification,
  getTypeSeverityNotification
} from "../../../services/notification";
import { actionAlarmsBarSetShow } from "../../../store/reducers/notification/notification-actions";

const AlarmsBar = ({ t, isOpenAlarmsBar, alarms, alarmsBarSetShow }) => {
  const listAlarms = getAlarms(alarms);
  const getIconName = alarm =>
    getMessageForSeverityTypeNotification(
      getTypeSeverityNotification(alarm.type)
    )["imgError"];
  const getBackgroundColor = alarm =>
    getMessageForSeverityTypeNotification(
      getTypeSeverityNotification(alarm.type)
    )["colorBackground"];

  return (
    <div className={classNames("alarmsBar", { hide: !isOpenAlarmsBar })}>
      <div className={classNames("header")}>
        <div className={classNames("title")}>
          {t("notification.HEADER_LABEL")}
        </div>
        <div className={classNames("close")} onClick={() => alarmsBarSetShow(false)}>
          X
        </div>
      </div>
      <div className={classNames("alarmsBarItems")}>
        {listAlarms.map(alarm => (
          <div key={`alarm-${alarm.id}`} className={classNames("alarmsBarItem")}>
            <div className={classNames("alarmIcon")}
              style={{ backgroundColor: getBackgroundColor(alarm) }}
            >
              <div className={classNames("icon", getIconName(alarm))} />
            </div>
            <div className={classNames("alarmText")}>
              {getMessageForSeverity(alarm)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isOpenAlarmsBar: state.notifications.data.isOpenAlarmsBar,
  alarms: state.notifications.data.alarms || []
});

const mapDispatchToProps = dispatch => {
  return {
    alarmsBarSetShow: data => dispatch(actionAlarmsBarSetShow(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlarmsBar));
