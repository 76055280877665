import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import './index.scss';
import {isRTL} from '../../../services/userModel.js';

const TooltipControl = ({ tooltipText, position, isNeverRtl, tooltipIsHTML }) => {
  const ref = useRef();
  const [currentPos, setCurrentPos] = useState(position);
  const tooltipPosition = (ref && ref.current && ref.current.getBoundingClientRect()) || {};
  const { innerWidth } = window;
  const newPositionTop = (currentPos && currentPos.top - tooltipPosition.height - 8) || 0;
  const isTopOverflow = newPositionTop < 0;
  const newPositionLeft = isTopOverflow
    ? (currentPos && currentPos.left + currentPos.width / 2 - (tooltipPosition.width || 0)) || 0
    : (currentPos && currentPos.left + currentPos.width / 2 - (tooltipPosition.width || 0) / 2) || 0;
  const isLeftOverflow = newPositionLeft < 0;
  const isRightOverflow = isTopOverflow
    ? newPositionLeft + tooltipPosition.width + 250 > innerWidth
    : newPositionLeft + tooltipPosition.width > innerWidth;
  const marginCorrection = 7; //Fix for top/left/right side tooltip cordon overflow

  useEffect(() => {
    setCurrentPos(position);
  }, [position]);

  return (
    <div
      ref={ref}
      className={classNames('tooltipPortal', {
        active: !!tooltipText,
        leftOverflow: isLeftOverflow,
        rightOverflow: isRightOverflow,
        topOverflow: isTopOverflow,
      })}
      style={{
        top:
          isLeftOverflow || isRightOverflow || isTopOverflow
            ? position
              ? position.top + position.height / 2 - tooltipPosition.height / 2
              : 0
            : newPositionTop - marginCorrection,
        left: isLeftOverflow
          ? (position ? position.left + position.width / 2 + 20 : 0) + marginCorrection
          : isRightOverflow
          ? (position ? position.left - tooltipPosition.width - 20 : 0) + marginCorrection
          : newPositionLeft,
      }}
    >
      {tooltipIsHTML && (
          <div className={classNames('tooltipPortalText', { rtl: isRTL() && !isNeverRtl })} dangerouslySetInnerHTML={{__html: tooltipText}}/>
      )}
      {!tooltipIsHTML && (
          <div className={classNames('tooltipPortalText', { rtl: isRTL() && !isNeverRtl })}>{tooltipText}</div>
      )}
      <span className={classNames('tooltipPortalArrow')}></span>
    </div>
  );
};

const TooltipPortal = props => {
  const { tooltipText, position, isNeverRtl, tooltipIsHTML } = props;

  return createPortal(
    <TooltipControl tooltipText={tooltipText} position={position} isNeverRtl={isNeverRtl} tooltipIsHTML={tooltipIsHTML} />,
    document.getElementById('tooltipPortal-container')
  );
};

const mapStateToProps = state => ({
  tooltipText: state.tooltipPortal.tooltipText,
  position: state.tooltipPortal.position,
  isNeverRtl: state.tooltipPortal.isNeverRtl,
  tooltipIsHTML: state.tooltipPortal.tooltipIsHTML,
});

export default connect(mapStateToProps)(TooltipPortal);
