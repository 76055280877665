import React, { createRef } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";
import "./index.scss";

import { DATE_FORMAT, DATE_TIME_FORMAT_DAYTIME, TIME_FORMAT_USER } from "../../../utils/convertTime";
import { DATECONTROL_TYPE, WAITER_TYPES } from "../../../utils/enums";
import { isValidDate } from "../../../utils/objects-util";

import Waiter from "../Waiter/Waiter";
import DatePicker from "../DatePicker/DatePicker.jsx";

class OrderDatetimeControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSetTimeCalendarOpen: false,
      dtValue: null,
      popupLabel: ''
    };

    this.datePickerRef = createRef();
  }

  componentDidMount() {
    const { time } = this.props;
    if(time) {
      this.setState({ dtValue: this.getFormattedValue(time) });
    }
    this.setState({popupLabel: this.getLabel()})
  }

  getLabel = () => {
    const {accessor} = this.props
    switch (accessor){
      case "start" | "startDate":
        return "TITLE_BUTTON_START";
      case "end":
        return "TITLE_BUTTON_FINISH";
      case "creationDate":
        return "TITLE_BUTTON";
      case "sellerBirthdate" :
        return "TITLE_BUTTON_SELLER_BIRTHDATE";
      case "sellerSocialIdIssued":
        return "TITLE_BUTTON_SELLER_SOCIAL_ID_ISSUED";
      case "startDate":
        return "TITLE_BUTTON_START";
      case "endDate":
        return "TITLE_BUTTON_FINISH";
      case "voucherExpiration":
        return "TITLE_VOUCHER_EXPIRATION";
      default:
        return "TITLE_BUTTON_TARGET";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { time } = this.props;

    if(time !== prevProps.time) {
      this.setState({ dtValue: this.getFormattedValue(time) });
    }
  }

  handleSetTimeCalendar = date => {
    const { time, onSelect } = this.props;

    return new Promise(resolve => {
      resolve();

      if (date) {
        const dt = new Date(date);
        dt.setDate(1);
        this.setState({ dtValue: this.getFormattedValue(time) }, ()=> onSelect(date));
      }

      this.handleOnOpenCalendar(false);
    });
  };

  getFormattedValue = timeValue => {
    const { type, format } = this.props;

    if(!timeValue) return "";

    switch (type) {
      case DATECONTROL_TYPE.date:
        return moment(timeValue).format(format || DATE_FORMAT).toString();
      case DATECONTROL_TYPE.time:
        return moment(timeValue).format(format || TIME_FORMAT_USER).toString();
      case DATECONTROL_TYPE.datetime:
        return moment(timeValue).format(format || DATE_TIME_FORMAT_DAYTIME).toString();
      default:
        return timeValue;
    }
  }

  handleOnChange = value => {
    const { isEditable, type, time, min } = this.props;

    if(value && isEditable) {
      this.setState({ dtValue: "" }, ()=> {
        let dDate = moment(time || new Date()).format(DATE_FORMAT).toString();
        let dTime = moment(time || new Date()).format(TIME_FORMAT_USER).toString();

        switch (type) {
          case DATECONTROL_TYPE.date:
            // const textDate = getDateFromText(value);
            if(moment(value).isValid())
              dDate = moment(value).format(DATE_FORMAT).toString();
            break;
          case DATECONTROL_TYPE.time:
            // getTimeFromText
            dDate = moment(time).format("YYYY/MM/DD").toString();
            if(moment(dDate + " " + value).isValid())
              dTime = moment(dDate + " " + value).format(TIME_FORMAT_USER).toString();
            break;
          case DATECONTROL_TYPE.datetime:
            let [date, hours] = value.split(' ');
            date = date.split('/').reverse();
            date = `20${date[0]}/${date[1]}/${date[2]}`;
            const newDate = moment(date + " " + hours);
            // getTimeFromText
            if(moment(newDate).isValid()) {
              dDate = moment(newDate).format("YYYY/MM/DD").toString();
              dTime = moment(newDate).format(TIME_FORMAT_USER).toString();
            }
            break;
          default:
            break;
        }

        const isMoreThenMin = new Date(dDate + " " + dTime) >= new Date(min);
        const result = !min || isMoreThenMin
          ? new Date(dDate + " " + dTime)
          : new Date(min);
        if(isValidDate(result)) {
          this.handleSetTimeCalendar(result.toISOString());
        }
      })
    }
  }

  handleOnOpenCalendar = value => {
    const { isReadonly } = this.props;
    return new Promise(resolve=> {
      resolve();
      if(!isReadonly) {
        this.setState({ isSetTimeCalendarOpen: value });
      }
    });
  }

  render() {
    const { dtValue, popupLabel } = this.state;
    const { t, time, type, label, format, placeholder, alwaysShowDatePicker, hideIcon, hideLabel, isEditable, isReadonly, min } = this.props;
    const hideDateControl = alwaysShowDatePicker ? false : [DATECONTROL_TYPE.time].includes(type);

    return (
      <div className={classNames("orderDatetimeControl")}>
        {!hideLabel && (
          <div className={classNames("leftSide")}>
            {!hideIcon && (<div className={classNames("controlIcon", "target-time-control")} />)}
            <div className={classNames("controlTitle")}>{label || t("assistance.TIME_LABEL")}</div>
          </div>
        )}
        <div ref={this.datePickerRef} className={classNames("centerSide")}>
          <DebounceInput readOnly={!isEditable || isReadonly}
            className={classNames("control-text")}
            debounceTimeout={1500}
            placeholder={placeholder}
            onChange={() => {}}
            onBlur={e=> this.handleOnChange(e.target.value)}
            onClick={() => !isEditable && this.handleOnOpenCalendar(true)}
            value={dtValue || ''}
          />
          <DatePicker
            showTimeControl={[DATECONTROL_TYPE.time, DATECONTROL_TYPE.datetime].includes(type)}
            // hideDateControl={[DATECONTROL_TYPE.time].includes(type)}
            hideDateControl={hideDateControl}
            value={time}
            onSave={this.handleSetTimeCalendar}
            minDate={min}
            isIcon
            format={format}
            iconRender={(
                <Waiter type={WAITER_TYPES.buttonSpan}
                        useWrapClass={classNames("controlButton")}
                        useClass={classNames("input-group-addon")}
                        tooltipText={t(`datetimepicker.${popupLabel}`)}
                        stopPropagation={false}
                />
            )}
            iconClassNames={'controlButton'}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(OrderDatetimeControl);
