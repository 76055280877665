export const SECTION_MODEL_INIT = "SECTION_MODEL_INIT";
export const SECTION_MODEL_UPDATE = "SECTION_MODEL_UPDATE";
export const SECTION_MODEL_UPDATE_MODEL = "SECTION_MODEL_UPDATE_MODEL";
export const SECTION_MODEL_SCROLL_UPDATE = "SECTION_MODEL_SCROLL_UPDATE";
export const SECTION_MODEL_REFRESH = "SECTION_MODEL_REFRESH";
export const SECTION_MODEL_GET = "SECTION_MODEL_GET";

export function actionUpdateSectionModel(data) {
  return { type: SECTION_MODEL_UPDATE, data };
}

export function actionUpdateSectionModelData(data) {
  return { type: SECTION_MODEL_UPDATE_MODEL, data };
}

export function actionRefreshSectionModel(data) {
  return { type: SECTION_MODEL_REFRESH, payload: data };
}