import React, {useState} from 'react';
import classNames from 'classnames';
import {useDispatch} from "react-redux";
import {createAction} from "../../../utils/sagaHelper.js";
import {MODEL_CONTROL_TYPE} from "../../../utils/enums.js";
import './listItemsModel.scss';
import ControlModel from "../../../models/controlModel.js";

export function createListItemsControlModel({ ...args }) {
    return new ControlModel({
        icon: args.icon || '',
        items: args.items,
        customStyles: args.customStyles ? args.customStyles() : {},
        customClassName: args.customClassName,
        isShow:
            args.isShow ||
            function() {
                return true;
            },
        type: MODEL_CONTROL_TYPE.listItems,
    });
}

const ListItemsControlItem = dropdownItem => {
    const { icon, text, action, isShow, handleOnClick, isActiveElement } = dropdownItem;
    const dispatch = useDispatch();
    // const [inProcess, setInProcess] = useState(false);


    const handleClick = () => {
        /*if (inProcess) {
            return;
        }*/
        // setInProcess(true);
        handleOnClick();
        new Promise((resolve, reject) => {
            return dispatch(createAction(action, isShow())).then(
                () => {
                    resolve();
                    // setInProcess(false);
                },
                error => {
                    // setInProcess(false);
                }
            );
        });
    };


    if (!isShow() !== isActiveElement) return null;

    return (
        <div className={classNames('listItems-item', 'iconButton')} onClick={() => handleClick()}>
            <div className={classNames('waiterButtonWrap')}>
        <span className={classNames('spanImg', 'waiter-tooltip')}>
          <span className={classNames('actionIcon', icon)}></span>
        </span>
            </div>
            <div>{text}</div>
        </div>
    );
};

export const ListItemsControl = ({ control, model, models }) => {
    const { items } = control;
    const [isActiveElement, setIsActiveElement] = useState(false);

    const handleOnClick = (id) => {
        setIsActiveElement(!isActiveElement);
    };

    return (
        <div className={classNames("listItemsControl")}>
                {items.map((item) => (
                    <ListItemsControlItem key={item?.icon + item.text} model={model} isActiveElement={isActiveElement} handleOnClick={handleOnClick} models={models} {...item} />
                ))}
        </div>
    );
};
