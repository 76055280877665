import { isNeedToAddSeverity } from "../../../services/notification";

import {
  NOTIFICATIONS_UPDATE,
  ALARMS_ADD,
  ALARMS_DELETE,
  ALARMS_RESTART,
  ALARMS_UPDATE,
  ALARMS_UPDATE_ACTIVE,
  ALARMS_BAR_SET_SHOW,
  ACCOUNT_TOP_NOTIFICATION_UPDATE
} from "./notification-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
  data: {
    notifications: [],
    alarms: [],
    isOpenAlarmsBar: false,
    accountTopNotification: null
  }
};

export default function notifications(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case NOTIFICATIONS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: action.data
        }
      };
    case ALARMS_ADD:
      if (!isNeedToAddSeverity(action.data.type)) return state;
      return {
        ...state,
        data: {
          ...state.data,
          alarms: state.data.alarms.concat([{ ...action.data, active: true }])
        }
      };
    case ALARMS_UPDATE_ACTIVE:
      return {
        ...state,
        data: {
          ...state.data,
          alarms: state.data.alarms.map(
            alarm =>
              alarm.id === action.data.id
                ? { ...alarm, active: action.data.active }
                : alarm
          )
        }
      };
    case ALARMS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          alarms: action.data.reduce((accumulator, alarm) => {
            if (isNeedToAddSeverity(alarm.type))
              accumulator.push({
                ...alarm,
                active: true
              });
            return accumulator;
          }, [])
        }
      };
    case ALARMS_DELETE:
      return {
        ...state,
        data: {
          ...state.data,
          alarms: state.data.alarms.filter(alarm => alarm.id !== action.data.id)
        }
      };
    case ALARMS_RESTART:
      return {
        ...state,
        data: {
          ...state.data,
          alarms: []
        }
      };
    case ALARMS_BAR_SET_SHOW:
      return {
        ...state,
        data: {
          ...state.data,
          isOpenAlarmsBar: action.data
        }
      };
    case ACCOUNT_TOP_NOTIFICATION_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          accountTopNotification: action.data
        }
      };
    default:
      return state;
  }
}
