import init from './reducers/init/init-reducer';
import authentication from './reducers/authentication/authentication-reducer';
import restaurant from './reducers/restaurant/restaurant-reducer';
import userModel from './reducers/userModel/userModel-reducer';
import order from './reducers/order/order-reducer';
import orderEdit from './reducers/orderEdit/orderEdit-reducer';
import marker from './reducers/marker/marker-reducer';
import sectionsWrapperData from './reducers/sectionsWrapperData/sectionsWrapperData-reducer';
import sectionModel from './reducers/routing/routing-reducer';
import portalMap from './reducers/portalMap/portalMap-reducer';
import tooltipPortal from './reducers/tooltipPortal/tooltipPortal-reducer';
import notifications from './reducers/notification/notification-reducer';
import menu from './reducers/menu/menu-reducer';
import globalPopupMessage from './reducers/globalPopupMessage/globalPopupMessage-reducer';
import marketplace from './reducers/marketplace/marketplace-reducer';
import emptyInfo from './reducers/emptyInfo/emptyInfo-reducer';
import globalPortalData from './reducers/globalPortalData/globalPortalData-reducer';
import externalCycles from './reducers/externalCycles/externalCycles-reducer';
import menuTemplate from './reducers/menuTemplate/menuTemplate-reducer';
import loaders from './reducers/loaders/loaders-reducer';
import supportChat from './reducers/supportChat/supportChat-reducer';
import billing from './reducers/billing/billing-reducer';
import customer from './reducers/customer/customer-reducer';
import editCustomerOrderReducer from './reducers/editCustomerOrder/editCustomerOrder-reducer';
import activeStateCycles from './reducers/cycles/cycles-reducer.js';
import reconciliations from './reducers/reconciliationModel/reconciliationModel-reducer.js';

export default {
  init,
  authentication,
  restaurant,
  userModel,
  order,
  marker,
  orderEdit,
  sectionsWrapperData,
  sectionModel,
  portalMap,
  tooltipPortal,
  notifications,
  menu,
  globalPopupMessage,
  marketplace,
  emptyInfo,
  globalPortalData,
  externalCycles,
  activeStateCycles,
  menuTemplate,
  loaders,
  supportChat,
  billing,
  customer,
  editCustomerOrder: editCustomerOrderReducer,
  reconciliations
};
