import i18n from '../../i18n';

import { getMarketplaceFilter, sortByPropNumber } from '../filter';
import { createControlModel } from '../controlModelFactory';
import FilterOptionsModel from './../../models/filterOptionsModel';
import SectionModel from '../../models/sectionModel';

import {
  MARKETPLACE_PAGES,
  MARKETPLACE_PAGES_TOGGLE_ENABLED,
  MARKETPLACE_PAGES_UPDATE,
} from '../../store/reducers/restaurant/restaurant-actions';
import { MARKETPLACE_PAGES_GET } from '../../store/reducers/order/order-actions';

import {
  CONTROL_TYPE,
  getMarketplaceLanguages,
  MARKETPLACE_SETTINGS_MODEL,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  TYPE_ACTION,
  TYPE_PATTERN,
} from '../../utils/enums';
import { getInitModel } from '../initModels';
import { getPattern } from '../../utils/objects-util';
import { getPageNameById, isMobileWidth } from '../restaurant';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { getHistoryLocationParams } from '../../utils/routesHelper.js';

export function createPagesSectionModel() {
  return new SectionModel({
    filterOptions: createServiceCitiesSectionFilter(),
    columns: [
      {
        title: 'orders.NAME_LABEL',
        accessor: 'title',
      },
      {
        title: 'settings.marketplacePages.LANGUAGE',
        accessor: 'language',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.ENABLED',
        accessor: 'enabled',
        isEditable: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: '90px',
        centered: true,
        type: CONTROL_TYPE.link,
        link: MARKETPLACE_SETTINGS_MODEL.pagesEdit,
        iconName: 'editItem',
      },
    ],
    actionName: MARKETPLACE_PAGES,
    typeModel: MARKETPLACE_SETTINGS_MODEL.pages,
    editAction: MARKETPLACE_PAGES_TOGGLE_ENABLED,
    dragActionName: MARKETPLACE_PAGES_UPDATE,
    actionLoad: MARKETPLACE_PAGES_GET,
    isForceUpdate: true,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: MARKETPLACE_SETTINGS_MODEL.pagesNew,
      }),
      createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
      createControlModel({ type: MODEL_CONTROL_TYPE.back, isShow: isMobileWidth }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: 'settings.orderingmenu.APP_BUILDER',
      }),
    ],
    label: 'settings.pages.LABEL',
    sortByFunc: items => sortByPropNumber(items, 'order', false),
    mappingLambda: state => state.marketplace.data.pages || [],
  });
}

export function createNewPagesSectionModel() {
  return new SectionModel({
    columns: [
      {
        title: 'settings.marketplacePages.BODY',
        accessor: 'body',
        showExtraEdit: true,
        type: CONTROL_TYPE.richText,
        isFillContent: true,
        isEditable: true,
      },
      {
        title: 'settings.marketplacePages.ENABLED',
        accessor: 'enabled',
        width: '200px',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplacePages.BANNER_SETTINGS',
        accessor: 'banner',
        settingsAaccessor: 'banner_settings',
        type: CONTROL_TYPE.imageEdit,
        isEditable: true,
      },
      {
        title: 'settings.marketplacePages.TITLE',
        accessor: 'title',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.LANGUAGE',
        accessor: 'language',
        values: getMarketplaceLanguages(),
        isEditable: true,
        type: CONTROL_TYPE.select,
      },
      {
        title: 'settings.marketplacePages.CATEGORY',
        accessor: 'category',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.LINK',
        accessor: 'link',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.SLUG',
        accessor: 'slug',
        isEditable: true,
        isRequired: true,
        pattern: getPattern(TYPE_PATTERN.slug),
        patternError: i18n.t('settings.marketplacePages.PATTERN_ERROR'),
        type: CONTROL_TYPE.text,
      },
    ],
    typeAction: TYPE_ACTION.new,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.pages,
    actionName: MARKETPLACE_PAGES,
    actionLoad: MARKETPLACE_PAGES_GET,
    disableGlobalKeyDown: true,
    mappingLambda: state => state,
    controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back })],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: 'settings.pages.LABEL',
      }),
    ],
    label: 'settings.pages.NEW_LABEL',
    initNewItemModel: () => getInitModel(MODEL_TYPE.marketplacePages),
  });
}

export function createEditPagesSectionModel() {
  return new SectionModel({
    columns: [
      {
        title: 'settings.marketplacePages.BODY',
        accessor: 'body',
        showExtraEdit: true,
        type: CONTROL_TYPE.richText,
        isFillContent: true,
        isEditable: true,
      },
      {
        title: 'settings.marketplacePages.BANNER_SETTINGS',
        accessor: 'banner',
        settingsAaccessor: 'banner_settings',
        type: CONTROL_TYPE.imageEdit,
        isEditable: true,
      },
      {
        title: 'settings.marketplacePages.ENABLED',
        controlTitle: '',
        accessor: 'enabled',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplacePages.TITLE',
        accessor: 'title',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.LANGUAGE',
        accessor: 'language',
        connect: () => ({
          values: getMarketplaceLanguages(),
        }),
        isEditable: true,
        type: CONTROL_TYPE.select,
      },
      {
        title: 'settings.marketplacePages.CATEGORY',
        accessor: 'category',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.LINK',
        accessor: 'link',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplacePages.SLUG',
        accessor: 'slug',
        isEditable: true,
        isRequired: true,
        pattern: getPattern(TYPE_PATTERN.slug),
        patternError: i18n.t('settings.marketplacePages.PATTERN_ERROR'),
        type: CONTROL_TYPE.text,
      },
    ],
    typeAction: TYPE_ACTION.edit,
    actionLoad: MARKETPLACE_PAGES_GET,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.pages,
    actionName: MARKETPLACE_PAGES,
    initNewItemModel: data => getInitModel(MODEL_TYPE.marketplacePages, data),
    controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back })],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: 'settings.orderingmenu.APP_BUILDER',
      }),
      createBreadcrumbModel({
        label: 'settings.pages.LABEL',
      }),
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return getPageNameById(id);
    },
    mappingLambda: state => state.marketplace.data.pages || [],
  });
}

function createServiceCitiesSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(marketplace) {
    return getMarketplaceFilter(this.selected, marketplace);
  });

  return filterOptionsModel;
}
