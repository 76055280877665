import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { defaultPromiseResolve } from "../../../utils/objects-util";

import IconButton from "../IconButton";

const MenuItemIconDepots = ({ depots, check }) => {
    const isShow =  depots.some(depot=> (check || []).some(s=> depot[s]));
    return isShow
        ? (<IconButton isIcon={true} iconName={"warn"} onClick={defaultPromiseResolve} />)
        : (<></>);
}

const mapStateToProps = state => ({
    depots: state.restaurant.data.depots || []
});

export default connect(mapStateToProps)(MenuItemIconDepots);