import { MODEL_TYPE } from "../utils/enums";
export function addressModel(data) {
  const address = {
    city: "",
    country: "",
    entrance: "",
    notes: "",
    organization: "",
    postal: "",
    state: "",
    street: "",
    streetNumber: "",
    location: null,
    modelType: MODEL_TYPE.address
  };

  return data ? { ...address, ...data } : address;
}
