import environment from "environment";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class SentryService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use SentryService.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new SentryService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  init() {
    Sentry.init({ 
        dsn: environment.sentryDsn,
        ignoreErrors:[
            "Non-Error exception captured",
            "Non-Error promise rejection captured"
        ],
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.5,
        normalizeDepth: 10
    });
  }

  stop() {
    if(Sentry && Sentry.close)
      Sentry.close();
  }
}