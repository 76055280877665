import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { getFullName } from "../../../services/userModel";

import { Avatar } from "../Avatar/Avatar";
import VehicleIcon from "../VehicleIcon";

const AssociatePopupControlCenter = ({ key, controlCenter, checked, onChecked, users }) => {
    const controlCenterUser = (controlCenter &&
        controlCenter.info &&
        controlCenter.info.userId &&
        (users || []).find(user => user.userId === controlCenter.info.userId)) || {};

    useEffect(()=> {
        
    }, [controlCenter, checked]);

    if(!controlCenterUser.userId) return null

    return (
        <div key={key} className={classNames("courierGroupItem", "associatePopupControlCenter")}
            onClick={e => onChecked(controlCenter.controlCenterId, e)}
        >
            <Avatar avatar={controlCenterUser.avatarUrl} color={controlCenterUser.color} />
            <div className="courierInfo">
            <span className="courierInfoTitle">
                <VehicleIcon value={controlCenterUser.vehicleType} isActive={true} />&nbsp;&nbsp;
                {controlCenter.name}
            </span>
            <span className="courierInfoLabel">{getFullName(controlCenterUser)}</span>
            </div>
            <div className="courierCheck">
                <input id={`checkbox-${controlCenter.controlCenterId}`} type="checkbox" name="check"
                    checked={checked === controlCenter.controlCenterId}
                    onChange={e => onChecked(controlCenter.controlCenterId, e)}
                />
                <label htmlFor={`checkbox-${controlCenter.controlCenterId}`} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        users: state.userModel.data || []
    };
};

export default connect(mapStateToProps)(AssociatePopupControlCenter);