export function marketplacePagesModel(data) {
    const { id, enabled, title, category, slug, body, banner, banner_settings, order, language, link } = data || {};
    return {
        id: id || "",
        enabled: enabled || false,
        title: title || "",
        category: category || "",
        slug: slug || "",
        body: body || "",
        banner: banner || "",
        banner_settings: banner_settings || ({
            "crop_mode": 0,
            "gravity_mode": 0,
            "quality": 0,
            "quality_mode": 0
        }),
        order: order || 0,
        language: language || "",
        link: link || ""
    }
}
