import React from "react";
import "./index.scss";
import classNames from "classnames";
import preloader from "../../../assets/images/preloader.GIF";

export const WaiterButtonSpan = ({
  useWrapClass,
  useTextClass,
  useClass,
  useSvgClass,
  processing,
  spanText,
  handleOnClick,
  isShake,
  useSvg,
  disabled
}) => (
  <div className={classNames("divSpanImg", "waiter-tooltip", { disabled: processing || disabled, waiterShake: isShake }, useWrapClass)}
    onClick={handleOnClick}
  >
    {useSvg && (
      <svg className={useSvgClass ? useSvgClass : ""}>
        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={useSvg} />
      </svg>
    )}
    {processing && (<img className="preloader" src={preloader} alt="preloader" />)}
    <div className={classNames(useClass)} />
    {!!spanText && (<span className={classNames("titleText", useTextClass)}>{spanText}</span>)}
  </div>
);
