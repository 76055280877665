import i18n from "../i18n";
import { DATE_FORMAT, getFormattedDate } from "../utils/convertTime";
import { BILLING_PATHS, SUBSCRIPTION_STATE } from "../utils/enums";
import { getKeyByValue } from "../utils/objects-util";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class BillingService {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error("Instantiation failed: use BillingService.getInstance() instead of new.");
        }
    }

    static get instance() {
        if (!this[singleton]) {
        this[singleton] = new BillingService(singletonEnforcer);
            return this[singleton];
        }
        return this[singleton];
    }

    static set instance(v) {
        throw new Error("Can't change constant property!");
    }

    getCurrentPlanInfo = data => {
        const currentPlan = data && data.currentPlans && data.currentPlans[0];
        if(!currentPlan) return [];

        //Check is trial
        if(this.checkIsTrial(data)) {
            return [
                { label: i18n.t("billing.subscription.PLAN"), description: currentPlan.name || "-" },
                { label: i18n.t("billing.subscription.STATUS"), description: i18n.t("billing.subscription.TRIAL_LABEL") },
                { label: i18n.t("billing.subscription.TRIAL_ENDS_AT"), description: getFormattedDate(currentPlan.trialEndsAt, DATE_FORMAT) }
            ];
        } else {
            return [
                { label: i18n.t("billing.subscription.PLAN"), description: currentPlan.name || "-" },
                { label: i18n.t("billing.subscription.STATUS"), description: getKeyByValue(SUBSCRIPTION_STATE, currentPlan.status) },
                { label: i18n.t("billing.subscription.RENEWAL_DATE"), description: currentPlan.renewalDate || "-" }
            ];
        }
    }

    getCurrentCardInfo = creditCard => {
        return (!creditCard)
            ? []
            : [
                { label: i18n.t("billing.card.CARD_NUMBER"), description: `${creditCard.cardMask}` },
                { label: i18n.t("billing.card.EXPIRE"), description: creditCard.expiry || "-" }
            ]
    }

    getCurrentBillingDetails = data => {
        const billingDetails = data && data.billingDetails;

        return (!billingDetails)
            ? []
            : [
                { label: i18n.t("billing.address.COMPANY"), description: billingDetails.company || "-" },
                { label: i18n.t("billing.address.BILLING_EMAIL"), description: billingDetails.email || "-" },
                { label: i18n.t("billing.address.BILLING_ADDRESS"), description: this.getBillingInfoAddress(billingDetails.address) }
            ]
    }

    getBillingInfoAddress = address => {
        if (!address) return "";

        let items = [];

        if (address.city) {
            items.push(address.city);
        }

        if (address.street1) {
            items.push(address.street1);
        }

        if (address.street2) {
            items.push(address.street2);
        }

        return items.join(", ");
    }
    
    getAccountTopNotificationData = billingData => {
        const { currentSubscription, creditCard } = (billingData || {}).info || {};

        if(!currentSubscription) return null;

        //No trial and no payment card
        if(!creditCard && !this.checkIsTrial(billingData)) {
            return {
                mainMessage: i18n.t("billing.NO_TRIAL_NO_CARD_ERROR_LABEL"),
                secondaryMessage: i18n.t("billing.GO_TO_PAYMENT_LABEL"),
                secondaryMessageUrl: BILLING_PATHS.plan,
                color: "#ff0000",
                secondaryColor: "#CA0000"
            }
        } else if(this.checkIsTrial(billingData)) {
            return {
                mainMessage: i18n.t("billing.TRIAL_INFO_LABEL"),
                secondaryMessage: `${i18n.t("billing.TRIAL_DATE_INFO_LABEL")}: ${getFormattedDate(currentSubscription.trialEndsAt, DATE_FORMAT)}`,
                secondaryMessageUrl: BILLING_PATHS.plan,
                color: "#ff0000",
                secondaryColor: "#CA0000"
            }
        }
    }

    checkIsNoActiveAccount = billingData => {
        const { currentSubscription, creditCard } = (billingData || {}).info || {};
        return currentSubscription &&
            !creditCard &&
            !this.checkIsTrial(billingData);
    }

    checkIsTrial = billingData => {
        return billingData && 
            billingData.currentSubscription &&
            billingData.trialEndsAt && new Date(billingData.trialEndsAt) >= new Date();
    }
}