export function fleetModel(data) {
  const result = {
    producer: (data?.producer || data?.producer === 0) ? data?.producer : null,
    vehicleType: (data?.vehicleType || data?.vehicleType === 0) ? data?.vehicleType : 0,
    serialNumber: data?.serialNumber || "",
    capacity: data?.capacity || null,
    description: data?.description || "",
    plateNumber: data?.plateNumber || null,
  };

  return result;
}

export function fleetTelemetryModel(data) {
  const result = {
    lat: data?.lat || "",
    lng: data?.lng || "",
    speed: data?.speed ? `${data?.speed} km/h` : "0.0 km/h",
    batteryLevel: data?.batteryLevel ? `${data?.batteryLevel} %` : '0 %',
    readyOn: data?.readyOn || 'false',
    fallState: data?.fallState || "false",
    ignitionOn: data?.ignitionOn || 'off',
    totalMileage: data?.totalMileage ? `${(data?.totalMileage / 1000).toFixed(1)} km` : "",
    vibrationState: data?.vibrationState || "false",
    plateNumber: data?.vehicleData?.plateNumber || "",
    vehicleType: (data?.vehicleData?.vehicleType || data?.vehicleData?.vehicleType === 0) ? data?.vehicleData?.vehicleType : "",
    vehicleId: data?.vehicleData?.vehicleId || "",
    location: data?.location || null,
  };

  return result;
}
