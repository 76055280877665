import i18n from "../i18n";
import Store from "../store/store";

import Pusher from "./pusher";
import { getDepots } from "./restaurant";
import { getControlCenterActive, getUserById, initUserModel } from "./userModel";

import { actionRefreshSectionModel } from "../store/reducers/routing/routing-actions";
import { isSameObjects } from "../utils/objects-util";
import { getUserConnectionData } from "../utils/auth-util";
import UserPilotService from "./userPilotService";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class User {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use User.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new User(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getCurrentUserInfo = () => {
    if (!this.currentUserInfo) {
      const connData = getUserConnectionData();

      if(connData) {
        const user = getUserById(connData.unique_name);
        if(user) {
          const activeControlCenter = getControlCenterActive() || {};
          const controlCenterId = user.controlCenterId || activeControlCenter.controlCenterId;
          User.instance.setCurrentUserInfo({ ...user, controlCenterId }); //Set current user info
          User.instance.setBaseConstants(); //Check base constants
        }
      }
    }

    return this.currentUserInfo;
  };

  getCurrentUserConfigurationProp(prop, isFromConfiguration = false) {
    const config = (this.currentUserInfo || {}).configuration || {};
    return (isFromConfiguration && config[prop]) || (config.visualSettings || {})[prop];
  }

  getBaseConstant(name) {
    return this.baseConstants && this.baseConstants[name];
  }

  setCurrentUserConfiguration(value) {
    this.currentUserInfo.configuration = value;
    if(value && value.language && i18n.language !== value.language) {
      this.setLanguage();
    }  
  }

  setCurrentUserInfo(user, isForceUpdate = false) {
    const newUserInfo = initUserModel(user);
    if(isForceUpdate || 
      !isSameObjects((this.currentUserInfo || {}).userId, (newUserInfo || {}).userId)
    ) {
      this.currentUserInfo = initUserModel(user);
      this.setLanguage();
    }

    UserPilotService.instance.init();
  }

  setLanguage() {
    const { configuration } = this.currentUserInfo || {};
    const { language } = configuration || {};
    
    if(language && i18n.language !== language) {
      i18n.changeLanguage(language || "").then(()=> {
        Store.store.dispatch(actionRefreshSectionModel({
          location: {...window.location},        
          isRefresh: true
        }));
      }); 
    }
  }

  skipObjectWithControlCenter(obj) {
    if (!obj) 
      return;
    const id = typeof obj === "string" ? obj : (obj.ControlCenterId || obj.controlCenterId);
    return id && 
      Pusher.instance.getControlCenterChannel() && 
      !Pusher.instance.getCurrentControlCenters().includes(id);
  }

  setBaseConstants() {
    if(!this.baseConstants && getUserConnectionData()) {
      this.baseConstants = {};
      const depots = getDepots();
      this.baseConstants.isDepotMode = depots.length > 1;
      this.baseConstants.isSingleDepotMode = depots.length === 1;      
    }
  }  
}
