import {getBusinesMenuCategoriesFilter, getMarketplaceFilter, sortByProp, sortByPropNumber} from '../filter';
import { createControlModel } from '../controlModelFactory';
import FilterOptionsModel from './../../models/filterOptionsModel';
import SectionModel from '../../models/sectionModel';

import {
  CANCEL_REASON, CANCEL_REASON_GET,
  MARKETPLACE_MESSAGE,
  MARKETPLACE_MESSAGE_TOGGLE_ENABLED,
  MARKETPLACE_MESSAGE_UPDATE,
  MARKETPLACE_SETTINGS,
  SHIPMENT_PRICE_CONFIGURATION,
  SHIPMENT_PRICE_CONFIGURATION_GET,
} from '../../store/reducers/restaurant/restaurant-actions';
import { MARKETPLACE_MESSAGE_GET } from '../../store/reducers/order/order-actions';

import {
  CONTROL_TYPE,
  getMarketplaceMessageTypes,
  getMerchantDeliveryCostCalculationModeItems,
  MARKETPLACE_SETTINGS_MODEL,
  MODEL_CONTROL_TYPE, TYPE_ACTION,
  TYPE_SECTION_MESSAGE_MODEL,
} from '../../utils/enums';
import {isMobileWidth} from '../restaurant';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import i18n from 'i18next';

export function createMarketplaceSectionModel() {
  return new SectionModel({
    filterOptions: createServiceCitiesSectionFilter(),
    columns: [
      {
        title: 'orders.NAME_LABEL',
        component: item => item.label || item.title,
      },
      {
        title: 'settings.marketplaceMessage.LANGUAGE',
        accessor: 'language',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplaceMessage.TYPE',
        accessor: 'type_enum',
        type: CONTROL_TYPE.text,
      },
      {
        title: '',
        controlTitle: '',
        width: '180px',
        accessor: 'enabled',
        isEditable: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: '90px',
        centered: true,
        type: CONTROL_TYPE.link,
        wrapOnMobileWidth: '600',
        link: TYPE_SECTION_MESSAGE_MODEL.messageInfo,
        iconName: 'editItem',
        tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
        hideWidth: '700',
      },
    ],
    actionName: MARKETPLACE_MESSAGE,
    typeModel: MARKETPLACE_SETTINGS_MODEL.marketplace,
    tableMinWidth: 600,
    editAction: MARKETPLACE_MESSAGE_TOGGLE_ENABLED,
    dragActionName: MARKETPLACE_MESSAGE_UPDATE,
    actionLoad: MARKETPLACE_MESSAGE_GET,
    isForceUpdate: true,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: MARKETPLACE_SETTINGS_MODEL.marketplaceNew,
      }),
      createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: "settings.orderingmenu.APP_BUILDER",
      }),
    ],
    label: 'settings.marketplaceMessage.MARKETPLACE',
    sortByFunc: items => sortByPropNumber(items, 'order', false),
    mappingLambda: state => {
      return (state.marketplace.data.message || []).map(message => ({
        ...message,
        type_enum: (getMarketplaceMessageTypes().find(f => f.key === message.type) || {})['value'] || '',
      }));
    },
  });
}

export function createCustomerDeliveryPriceSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: 'settings.personalSettings.shipmentPriceSettings.BASIS_PRICE',
        accessor: 'appbasisPrice',
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.number,
      },
      {
        title: 'settings.personalSettings.shipmentPriceSettings.BASIS_DISTANCE_IN_METERS',
        accessor: 'appbasisDistanceInMeters',
        isEditable: true,
        type: CONTROL_TYPE.number,
      },
      {
        title: 'settings.personalSettings.shipmentPriceSettings.PRICE_PER_KM',
        accessor: 'apppricePerKm',
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.number,
      },
      {
        title: 'settings.personalSettings.shipmentPriceSettings.MAX_DELIVERY_PRICE',
        accessor: 'appmaxDeliveryPrice',
        isEditable: true,
        type: CONTROL_TYPE.number,
      },
      {
        title: 'settings.personalSettings.shipmentPriceSettings.PRICE_CALCULATION_MODE',
        accessor: 'appmode',
        isEditable: true,
        connect: () => ({
          values: getMerchantDeliveryCostCalculationModeItems(),
        }),
        type: CONTROL_TYPE.select,
      },
    ],
    actionName: SHIPMENT_PRICE_CONFIGURATION,
    actionLoad: SHIPMENT_PRICE_CONFIGURATION_GET,
    initNewItemModel: item => item,
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: MARKETPLACE_SETTINGS_MODEL.customerDeliveryPrice,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.customerDeliveryPrice,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    disabledIfEmpty: state => state?.restaurant?.data?.appShipmentPriceConfiguration,
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS' }
      ),
      createBreadcrumbModel({
        label: 'settings.orderingmenu.DELIVERY',
      }),
    ],
    label: 'settings.customerDeliveryPrice.BREADCRUMBS',
    mappingLambda: state => {
      return {
        id: 'generalsettings',
        ...state.restaurant.data.appShipmentPriceConfiguration,
      };
    },
  });
}

function createServiceCitiesSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(marketplace) {
    return getMarketplaceFilter(this.selected, marketplace);
  });

  return filterOptionsModel;
}

export function createMarketplaceSettingsModel(args) {
  return new SectionModel({
    columns: [
      {
        isEditable: true,
        accessor: 'headerUrl',
        settingsAaccessor: 'headerSettings',
        type: CONTROL_TYPE.imageEdit,
      },
      {
        title: 'settings.marketplaceSettings.SCORING_ENABLED',
        accessor: 'scoringEnabled',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplaceSettings.ALLOW_MULTIPLE_DISCOUNTS',
        accessor: 'allowMultipleDiscounts',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplaceSettings.REQUIRE_VERIFIED_PHONE_ON_CASH',
        accessor: 'requireVerifiedPhoneOnCash',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplaceSettings.REQUIRE_VERIFIED_PHONE_ON_CARD',
        accessor: 'requireVerifiedPhoneOnCard',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplaceSettings.REQUIRE_VERIFIED_PHONE_ON_PROFILE',
        accessor: 'requirePhoneVerificationOnProfile',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.marketplaceSettings.TERMS_CONDITIONS_URL',
        accessor: 'termsAndConditionsUrl',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplaceSettings.PRIVACY_POLICY_URL',
        accessor: 'privacyPolicyUrl',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplaceSettings.ACCESSIBILITY_POLICY_URL',
        accessor: 'accessibilityPolicyUrl',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.marketplaceSettings.ACCESSIBILITY_SITE_URL',
        accessor: 'accessibleSiteUrl',
        isEditable: true,
        type: CONTROL_TYPE.text,
      }
    ],
    actionName: MARKETPLACE_SETTINGS,
    initNewItemModel: item => item,
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: MARKETPLACE_SETTINGS_MODEL.marketplaceSettings,
    saveControlsConfig: {
      cancel: { title: 'basic.RESET_BUTTON', action: () => {}, isInit: true },
    },
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
    ],
    label: 'settings.marketplaceSettings.MENU_LABEL',
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    mappingLambda: state => state.restaurant.data.paymentsConfiguration,
  });
}

export function createCancelReasonEditSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: 'settings.businessCategories.CATEGORY_NAME',
        accessor: 'nameTranslations',
        isEditable: true,
        isFillContent: true,
        type: CONTROL_TYPE.languageEditor,
      },
    ],
    actionName: CANCEL_REASON,
    actionLoad: CANCEL_REASON_GET,
    initNewItemModel: item => {
      return (
          item || {
            nameTranslations: [],
          }
      );
    },
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: "settings.orderingmenu.APP_BUILDER",
      }),
    ],
    label: 'settings.cancelReason.LABEL',
    typeAction: args.typeAction,
    typeModel: args.typeAction === TYPE_ACTION.edit ? MARKETPLACE_SETTINGS_MODEL.cancelReasonEdit : MARKETPLACE_SETTINGS_MODEL.cancelReasonNew,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.cancelReason,
    mappingLambda: state => {
      return state.restaurant.data.cancelReason || [];
    },
  });
}
export function createCancelReasonSectionModel() {
  return new SectionModel({
    filterOptions: createCancelReasonSectionFilter(),
    columns: [
      {
        title: 'settings.cancelReason.REASON',
        accessor: 'name'
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: "90px",
        centered: true,
        type: CONTROL_TYPE.link,
        link: MARKETPLACE_SETTINGS_MODEL.cancelReasonEdit,
        iconName: 'editItem',
        tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
      },
    ],
    actionName: CANCEL_REASON,
    actionLoad: CANCEL_REASON_GET,
    tableMinWidth: 300,
    typeModel: MARKETPLACE_SETTINGS_MODEL.cancelReason,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: MARKETPLACE_SETTINGS_MODEL.cancelReasonNew,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: "settings.orderingmenu.APP_BUILDER",
      }),
    ],
    label: 'settings.cancelReason.LABEL',
    sortByFunc: function(items) {
      return sortByProp(items);
    },
    mappingLambda: state => {
      const data = state.restaurant.data.cancelReason || [];
      return data.filter(reason => !reason.isDeleted).map(reason => {
        const name = reason?.nameTranslations === null ? '' : reason.nameTranslations[i18n.language]
        return {
          name: name || '',
          ...reason,
        }
      })
    }
  });
}

function createCancelReasonSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(businessCategory) {
    return getBusinesMenuCategoriesFilter(this.selected, businessCategory);
  });

  return filterOptionsModel;
}