import { MODEL_TYPE } from "../utils/enums";
import { getInitModel } from "../services/initModels";
export function namedPlacesServerModel(data) {
  const item = data || {};
  return {
    Name: item.name || "",
    Address: {
      Address: getInitModel(
        MODEL_TYPE.addressServerModel,
        item.address.address
      ),
      Location: getInitModel(
        MODEL_TYPE.locationServerModel,
        item.address.location
      )
    },
    Region: item.region.map(reg =>
      getInitModel(MODEL_TYPE.locationServerModel, reg)
    )
  };
}
