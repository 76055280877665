import {
  MARKETPLACE_SETTINGS_MODEL,
  ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
  ORDERING_MENU_TYPE_SECTION_MODEL,
  RECONCILIATIONS_MODEL,
  SHIFTS_MODEL,
  TYPE_ACTION,
  TYPE_SECTION_DEPOT_MODEL,
  TYPE_SECTION_MENUTEMPLATE_MODEL,
  TYPE_SECTION_MESSAGE_MODEL,
  TYPE_SECTION_MODEL,
} from '../utils/enums';
import {
  createUserEditSectionModel,
  createUserPasswordEditSectionModel,
  createUsersSection,
} from './settingsSectionModels/users';
import {
  createBusinessCategoriesSectionModel,
  createBusinessCategoryEditSectionModel,
  createDepotDefaultRegionControlcentersEditSectionModel,
  createDepotDefaultRegionControlcentersSectionModel,
  createDepotEditSectionModel,
  createDepotIntegrationAddSectionModel,
  createDepotIntegrationsSectionModel,
  createDepotMenuCopy,
  createDepotMenuEditSectionModel,
  createDepotPaymeCreditInfoNewSectionModel,
  createDepotPaymeWalletNewSectionModel,
  createDepotPriceCopy,
  createDepotPriceEditSectionModel,
  createDepotProviderEditSectionModel,
  createDepotProvidersSectionModel,
  createDepotSquareAssociatedLocationSectionModel,
  createDepotsSectionModel,
  createDepotTelegramSectionModel,
  createDepotUserEditSectionModel,
  createDepotUserPasswordEditSectionModel,
  createDepotUsersSectionModel,
  createRestaurateurMenuEditSectionModel,
} from './settingsSectionModels/depots';
import {createGeneralSettingsSectionModel} from './settingsSectionModels/generalsettings';
import {
  createPersonalSettingsConfirmPasswordSectionModel,
  createPersonalSettingsSectionModel,
} from './settingsSectionModels/personalSettings';
import {createTelegramSettingsSectionModel} from './settingsSectionModels/telegramSettings';
import {createRegionsEditSectionModel, createRegionsSectionModel} from './settingsSectionModels/regions';
import {
  createNewServiceCitySectionModel,
  createServiceCitiesSectionModel,
} from './settingsSectionModels/serviceCities';
import {createNamedPlacesEditSectionModel, createNamedPlacesSectionModel} from './settingsSectionModels/namedPlaces';
import {
  createCurrentShiftDepotsSectionModel,
  createCurrentShiftEditSectionModel,
  createCurrentShiftHistorySectionModel,
  createCurrentShiftUsersSectionModel,
  createShiftHistorySectionModel,
  createShiftsEditSectionModel,
  createShiftsSectionModel,
  createSummaryShiftProviderSectionModel,
} from './settingsSectionModels/shifts';
import {createOrdersSection} from './settingsSectionModels/ordersSectionModel';
import {
  createControlCentersActivateSectionModel,
  createControlCentersChangeUserSectionModel,
  createControlCentersDeactivateSectionModel,
  createControlCentersEditSectionModel,
  createControlCentersSectionModel,
} from './settingsSectionModels/controlCenters';
import {
  createPaymentsConfigurationSettingsSectionModel,
  createRestaurantPaymeWalletNewSectionModel,
} from './settingsSectionModels/paymentsSettings';
import {
  createOperationTimingSettingsSectionModel,
  createOperationTimingSettingsSectionModelEdit,
} from './settingsSectionModels/operationTimingSettings';
import {
  createMenuDeliveryZoneEditSection,
  createMenuDeliveryZonesSection,
} from './orderingMenuSectionModels/deliveryZonesSection';
import {
  createDepotBusinessTranslationsSectionModelMenuTemplate
} from './orderingMenuMenuTemplateSectionModels/translations.js';
import {
  createBusinesMenuEditSection,
  createBusinesMenuExtraEditSection,
  createBusinesMenuExtraOptionEditSection,
  createBusinesMenuExtraSection,
  createBusinesMenuExtraSuboptionEditSection,
  createBusinesMenuSection,
  createBusinessGallery,
  createBusinessGalleryNew,
  createCategoryExtrasEditSection,
  createCategoryExtrasOptionEditSection,
  createCategoryExtrasSection,
  createCategoryExtrasSuboptionEditSection,
  createCategoryProductIngredientSection,
  createCategoryProductsEditSection,
  createCategoryProductsSection,
  createOrderingMenuEditSection,
  createOverrideExtra,
  createOverrideOption,
  createOverrideSuboption,
  createReviewProductExtra,
} from './orderingMenuSectionModels/categoriesSection';
import {createBusinesScheduleSection} from './orderingMenuSectionModels/businesSchedule';
import {
  createBusinesDiscountEditSection,
  createBusinesDiscountsSection,
} from './orderingMenuSectionModels/businesDiscounts';
import {createBusinesInfoEditSection} from './orderingMenuSectionModels/businesInfo';
import {createSettingsOffersEditSection, createSettingsOffersSection} from './settingsSectionModels/offers';
import {
  createNewSearchShortcutSectionModel,
  createSearchShortcutsSectionModel,
} from './settingsSectionModels/searchShortcut';
import {
  createCancelReasonEditSectionModel, createCancelReasonSectionModel,
  createCustomerDeliveryPriceSectionModel,
  createMarketplaceSectionModel,
  createMarketplaceSettingsModel,
} from './settingsSectionModels/marketplace';
import {createGiftCardProductSectionModel, createGiftCardSectionModel} from './settingsSectionModels/giftCard';
import {createCourierDeliveryFeesSectionModel} from './settingsSectionModels/courierDeliveryFees';
import {createReferralSectionModel} from './settingsSectionModels/referral';
import {
  createListPromotionEditSectionModel,
  createListPromotionSectionModel,
} from './settingsSectionModels/listPromotion';
import {createDriverTipSectionModel} from './settingsSectionModels/driverTip';
import {createServiceFeeSectionModel} from './settingsSectionModels/serviceFee';
import {createDeliveryZoneEditSection, createDeliveryZonesSection} from './settingsSectionModels/deliveryZones';
import {
  createEditPagesSectionModel,
  createNewPagesSectionModel,
  createPagesSectionModel,
} from './settingsSectionModels/pages';
import {
  createMenuTemplateEditSectionModel,
  createMenuTemplateSectionModel,
  createNewMenuTemplateSectionModel,
} from './settingsSectionModels/menuTemplate';
import {
  createParentMenuCategoriesOverride,
  createParentMenuEditSection,
  createParentMenuExtraOverride,
  createParentMenuOptionsOverride,
  createParentMenuProductOverride,
  createParentMenuSection,
  createParentMenuSuboptionsOverride,
} from './orderingMenuSectionModels/createParentMenuSection';
import {
  createBusinesMenuEditSectionMenuTemplate,
  createBusinesMenuExtraEditSectionMenuTemplate,
  createBusinesMenuExtraOptionEditSectionMenuTemplate,
  createBusinesMenuExtraSectionMenuTemplate,
  createBusinesMenuExtraSuboptionEditSectionMenuTemplate,
  createBusinesMenuSectionMenuTemplate,
  createBusinessGalleryMenuTemplate,
  createBusinessGalleryNewMenuTemplate,
  createCategoryExtrasEditSectionMenuTemplate,
  createCategoryExtrasOptionEditSectionMenuTemplate,
  createCategoryExtrasSectionMenuTemplate,
  createCategoryExtrasSuboptionEditSectionMenuTemplate,
  createCategoryProductIngredientSectionMenuTemplate,
  createCategoryProductsEditSectionMenuTemplate,
  createCategoryProductsSectionMenuTemplate,
  createOrderingMenuEditSectionMenuTemplate,
  createOverrideExtraMenuTemplate,
  createOverrideOptionMenuTemplate,
  createOverrideSuboptionMenuTemplate,
  createReviewProductExtraMenuTemplate,
} from './orderingMenuMenuTemplateSectionModels/categoriesSection';
import {
  createBusinesInfoEditSectionMenuTemplate,
  createDepotMenuTemplateBusinessCategoriesEditSectionModel,
} from './orderingMenuMenuTemplateSectionModels/businesInfo';
import {
  createBusinesDiscountEditSectionMenuTemplate,
  createBusinesDiscountsSectionMenuTemplate,
} from './orderingMenuMenuTemplateSectionModels/businesDiscounts';
import {createBusinesScheduleSectionMenuTemplate} from './orderingMenuMenuTemplateSectionModels/businesSchedule';
import {
  createMenuDeliveryZoneEditSectionMenuTemplate,
  createMenuDeliveryZonesSectionMenuTemplate,
} from './orderingMenuMenuTemplateSectionModels/deliveryZonesSection';
import {
  createParentMenuEditSectionMenuTemplate,
  createParentMenuSectionMenuTemplate,
} from './orderingMenuMenuTemplateSectionModels/createParentMenuSection';
import {
  createDepotOperationTimingActiveSelectSectionModel,
  createDepotOperationTimingEditSectionModel,
  createDepotOperationTimingSectionModel,
} from './orderingMenuSectionModels/depotOperationTimingSettings';
import {
  createDepotBusinessDeepLinkEditSectionModel,
  createDepotBusinessProductDeepLinkEditSectionModel,
  createMarketplaceDeepLinkEditSectionModel,
} from './orderingMenuSectionModels/deepLink';
import {createCourierAppSettingsModel} from './settingsSectionModels/courierAppSettingsModel';
import {
  createMessageSettingsEditSectionModel,
  createMessageSettingsGalleyEditSectionModel,
  createMessageSettingsGalleySectionModel,
} from './settingsSectionModels/messageSettings';
import {
  createCampaignsEditSectionModel,
  createCampaignsExecutionsSectionModel,
  createCampaignsSectionModel,
} from './settingsSectionModels/campaigns.js';
import {currentUserIsControlCenterManagerRole} from "./userModel.js";
import {columnGlobalUserEdit, columnsDepotUserEdit} from "../utils/sectionModelHelper.js";
import {GET_PROVIDER_SHIFT_HISTORY, GET_USER_SHIFT_HISTORY} from '../store/reducers/restaurant/restaurant-actions.js';
import {getCourierNameByShiftId, getProviderNameById} from './restaurant.js';
import {createDepotBusinessTranslationsSectionModel} from './orderingMenuSectionModels/translations.js';
import {
  createPeriodsReconciliationsPeriodEditSectionModel,
  createPeriodsReconciliationsSectionModel,
  createSettingsReconciliationsProviderSectionModel,
  createSettingsReconciliationsTemplateEditSectionModel,
  viewPeriodsReconciliationsPeriodEditSectionModel,
} from './settingsSectionModels/reconciliations.js';
import {createProvidersEditSectionModel, createProvidersSectionModel} from "./settingsSectionModels/providers.js";
import {
  createCloudPrintersEditSectionModel,
  createCloudPrintersSectionModel
} from "./settingsSectionModels/cloudPrinters.js";
import {
  createFleetEditSectionModel,
  createFleetSectionModel,
  createFleetTelemetrySectionModel
} from "./settingsSectionModels/fleet.js";
import {
  createLoyaltySettingsCashbackSectionModel,
  createLoyaltySettingsRegisterBonusSectionModel
} from "./settingsSectionModels/loyaltySettings.js";
import {createBusinessAppSettingsModel} from "./settingsSectionModels/businessAppSettings.js";
import {createBreadcrumbModel} from "./createBreadcrumbModel.js";

export function createSettingsSectionModel({ typeModel, ...args }) {
  const settings = {
    [TYPE_SECTION_MODEL.users]: createUsersSection(),
    [TYPE_SECTION_MODEL.usersEdit]: createUserEditSectionModel({
      typeAction: TYPE_ACTION.edit, columns: currentUserIsControlCenterManagerRole() ? columnGlobalUserEdit : columnsDepotUserEdit
    }),
    [TYPE_SECTION_MODEL.usersNew]: createUserEditSectionModel({
      typeAction: TYPE_ACTION.new, columns: currentUserIsControlCenterManagerRole() ? columnGlobalUserEdit : columnsDepotUserEdit
    }),
    [TYPE_SECTION_MODEL.cloudPrinters]: createCloudPrintersSectionModel(),
    [TYPE_SECTION_MODEL.cloudPrintersEdit]: createCloudPrintersEditSectionModel({
      typeAction: TYPE_ACTION.edit
    }),
    [TYPE_SECTION_MODEL.cloudPrintersNew]: createCloudPrintersEditSectionModel({
      typeAction: TYPE_ACTION.new
    }),
    [TYPE_SECTION_MODEL.userConfirmPassword]: createUserPasswordEditSectionModel(
      { typeAction: TYPE_ACTION.edit }
    ),
    [TYPE_SECTION_MODEL.depots]: createDepotsSectionModel(),
    [TYPE_SECTION_DEPOT_MODEL.depotsMenuCopy]: createDepotMenuCopy(),
    [TYPE_SECTION_MODEL.depotsNew]: createDepotEditSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [MARKETPLACE_SETTINGS_MODEL.businessCategories]: createBusinessCategoriesSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.businessCategoriesEdit]: createBusinessCategoryEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.businessCategoriesNew]: createBusinessCategoryEditSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [MARKETPLACE_SETTINGS_MODEL.menuTemplate]: createMenuTemplateSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.menuTemplateNew]: createNewMenuTemplateSectionModel({ typeAction: TYPE_ACTION.new }),
    [TYPE_SECTION_MODEL.depotpaymewalletNew]: createDepotPaymeWalletNewSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [TYPE_SECTION_MODEL.restaurantPaymeWalletNew]: createRestaurantPaymeWalletNewSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [TYPE_SECTION_MODEL.depotsPriceApplyTo]: createDepotPriceCopy({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [TYPE_SECTION_MODEL.depotSquareAssociatedLocation]: createDepotSquareAssociatedLocationSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.generalSettings]: createGeneralSettingsSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.courierAppSettings]: createCourierAppSettingsModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.businessAppSettings]: createBusinessAppSettingsModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.personalSettings]: createPersonalSettingsSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: createPersonalSettingsConfirmPasswordSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.telegramSettings]: createTelegramSettingsSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration]: createPaymentsConfigurationSettingsSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.regions]: createRegionsSectionModel(),
    [TYPE_SECTION_MODEL.regionsEdit]: createRegionsEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.regionsNew]: createRegionsEditSectionModel({
      typeAction: TYPE_ACTION.new
    }),
    [TYPE_SECTION_MODEL.providers]: createProvidersSectionModel(),
    [TYPE_SECTION_MODEL.providersEdit]: createProvidersEditSectionModel({
      typeAction: TYPE_ACTION.edit
    }),
    [TYPE_SECTION_MODEL.providersNew]: createProvidersEditSectionModel({
      typeAction: TYPE_ACTION.new
    }),
    [TYPE_SECTION_MODEL.deliveryProviders]: createDepotProvidersSectionModel({
      ...args,
      pathForEdit: TYPE_SECTION_MODEL.deliveryProvidersEdit,
      isHideBack: true,
      typeModel: TYPE_SECTION_MODEL.deliveryProviders,
      pathForNew: TYPE_SECTION_MODEL.deliveryProvidersNew,
      parentUrl: '',
      breadcrumbs: [
        createBreadcrumbModel({
          label: 'header.menu.SETTINGS_LABEL',
        }),
      ],
      label: 'settings.depotSettingsLabel.PROVIDER',
    }),
    [TYPE_SECTION_MODEL.deliveryProvidersEdit]: createDepotProviderEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      typeModel: TYPE_SECTION_MODEL.deliveryProvidersEdit,
      parentUrl: TYPE_SECTION_MODEL.deliveryProviders,
      isHideBack: true,
      breadcrumbs: [
        createBreadcrumbModel({
          label: 'header.menu.SETTINGS_LABEL',
        }),
        createBreadcrumbModel({
          label: 'settings.depotSettingsLabel.PROVIDER',
        }),
      ],
      ...args,
    }),
    [TYPE_SECTION_MODEL.deliveryProvidersNew]: createDepotProviderEditSectionModel({
      typeAction: TYPE_ACTION.new,
      parentUrl: TYPE_SECTION_MODEL.deliveryProviders,
      typeModel: TYPE_SECTION_MODEL.deliveryProvidersNew,
      isHideBack: true,
      breadcrumbs: [
        createBreadcrumbModel({
          label: 'header.menu.SETTINGS_LABEL',
        }),
        createBreadcrumbModel({
          label: 'settings.depotSettingsLabel.PROVIDER',
        }),
      ],
      ...args,
    }),
    [TYPE_SECTION_MODEL.fleet]: createFleetSectionModel(),
    [TYPE_SECTION_MODEL.fleetEdit]: createFleetEditSectionModel({
      typeAction: TYPE_ACTION.edit
    }),
    [TYPE_SECTION_MODEL.fleetTelemetry]: createFleetTelemetrySectionModel({
      typeAction: TYPE_ACTION.edit
    }),
    [TYPE_SECTION_MODEL.fleetNew]: createFleetEditSectionModel({
      typeAction: TYPE_ACTION.new
    }),
    [TYPE_SECTION_MODEL.serviceCities]: createServiceCitiesSectionModel(),
    [TYPE_SECTION_MODEL.serviceCitiesNew]: createNewServiceCitySectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [TYPE_SECTION_MODEL.serviceCitiesEdit]: createNewServiceCitySectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.namedPlaces]: createNamedPlacesSectionModel(),
    [TYPE_SECTION_MODEL.namedPlacesEdit]: createNamedPlacesEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.namedPlacesNew]: createNamedPlacesEditSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [SHIFTS_MODEL.providers]: createSummaryShiftProviderSectionModel(),
    [SHIFTS_MODEL.history]: createShiftsSectionModel(),
    [SHIFTS_MODEL.historyEdit]: createShiftsEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [SHIFTS_MODEL.shiftHistory]: createShiftHistorySectionModel({
      typeModel,
      ...args,
    }),
    [SHIFTS_MODEL.currentShiftUsers]: createCurrentShiftUsersSectionModel(),
    [SHIFTS_MODEL.currentShiftDepotEdit]: createCurrentShiftEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [SHIFTS_MODEL.currentShiftHistory]: createCurrentShiftHistorySectionModel({
      ...args,
      parentUrl: SHIFTS_MODEL.currentShiftUsers,
      actionLoad: GET_USER_SHIFT_HISTORY,
      getLabel: getCourierNameByShiftId,
    }),
    [SHIFTS_MODEL.currentShiftProviderHistory]: createCurrentShiftHistorySectionModel({
      ...args,
      parentUrl: SHIFTS_MODEL.providers,
      actionLoad: GET_PROVIDER_SHIFT_HISTORY,
      getLabel: getProviderNameById,
    }),
    [SHIFTS_MODEL.currentShiftDepots]: createCurrentShiftDepotsSectionModel(),
    [TYPE_SECTION_MODEL.controlCenters]: createControlCentersSectionModel(),
    [TYPE_SECTION_MODEL.controlCentersEdit]: createControlCentersEditSectionModel({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.controlCentersActivate]: createControlCentersActivateSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.controlCentersDeactivate]: createControlCentersDeactivateSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.controlCentersChangeuser]: createControlCentersChangeUserSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.depotsMenu]: createDepotMenuEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.operationTimingEdit]: createOperationTimingSettingsSectionModelEdit({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MODEL.operationTimingNew]: createOperationTimingSettingsSectionModelEdit({
      ...args,
      typeAction: TYPE_ACTION.new,
    }),
    [TYPE_SECTION_MODEL.operationTiming]: createOperationTimingSettingsSectionModel({ ...args }),
    [MARKETPLACE_SETTINGS_MODEL.offers]: createSettingsOffersSection({
      typeModel,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.offersNew]: createSettingsOffersEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.offersEdit]: createSettingsOffersEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.searchShortcuts]: createSearchShortcutsSectionModel(),
    [TYPE_SECTION_MODEL.searchShortcutsNew]: createNewSearchShortcutSectionModel({ typeAction: TYPE_ACTION.new }),
    [MARKETPLACE_SETTINGS_MODEL.marketplace]: createMarketplaceSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.deepLink]: createMarketplaceDeepLinkEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.pages]: createPagesSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.pagesEdit]: createEditPagesSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.pagesNew]: createNewPagesSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.marketplaceNew]: createMessageSettingsEditSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.giftCard]: createGiftCardSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsNew]: createGiftCardProductSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit]: createGiftCardProductSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_MODEL.courierDeliveryFees]: createCourierDeliveryFeesSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.campaigns]: createCampaignsSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.campaignsEdit]: createCampaignsEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.campaignsNew]: createCampaignsEditSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [MARKETPLACE_SETTINGS_MODEL.campaignsExecutions]: createCampaignsExecutionsSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.referral]: createReferralSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.loyaltySettingsRegistrationBonus]: createLoyaltySettingsRegisterBonusSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.loyaltySettingsCashback]: createLoyaltySettingsCashbackSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.customerDeliveryPrice]: createCustomerDeliveryPriceSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.driverTip]: createDriverTipSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.serviceFee]: createServiceFeeSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [MARKETPLACE_SETTINGS_MODEL.listPromotion]: createListPromotionSectionModel(),
    [MARKETPLACE_SETTINGS_MODEL.listPromotionNew]: createListPromotionEditSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.listPromotionEdit]: createListPromotionEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.deliveryZones]: createDeliveryZonesSection({
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.deliveryZonesEdit]: createDeliveryZoneEditSection({ typeAction: TYPE_ACTION.edit }),
    [MARKETPLACE_SETTINGS_MODEL.deliveryZonesNew]: createDeliveryZoneEditSection({ typeAction: TYPE_ACTION.new }),
    '/orders': createOrdersSection(),
    '/menu': createRestaurateurMenuEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),

    [TYPE_SECTION_DEPOT_MODEL.depotsEditPrice]: createDepotPriceEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotMenu]: createDepotPriceEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotusers]: createDepotUsersSectionModel({
      typeModel,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotusersEdit]: createDepotUserEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotusersNew]: createDepotUserEditSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotusersConfirmPassword]: createDepotUserPasswordEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotIntegrations]: createDepotIntegrationsSectionModel({
      typeModel,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotIntegrationsNew]: createDepotIntegrationAddSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]: createDepotDefaultRegionControlcentersSectionModel({
      typeModel,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersEdit]: createDepotDefaultRegionControlcentersEditSectionModel(
      {
        typeAction: TYPE_ACTION.edit,
        ...args,
      }
    ),
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersNew]: createDepotDefaultRegionControlcentersEditSectionModel(
      {
        typeAction: TYPE_ACTION.new,
        ...args,
      }
    ),
    [TYPE_SECTION_DEPOT_MODEL.provider]: createDepotProvidersSectionModel({
      typeModel,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.providerEdit]: createDepotProviderEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.providerNew]: createDepotProviderEditSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit]: createDepotPaymeCreditInfoNewSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_DEPOT_MODEL.depotsEdit]: createDepotEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_DEPOT_MODEL.telegram]: createDepotTelegramSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_DEPOT_MODEL.operationMode]: createDepotOperationTimingSectionModel({ ...args }),
    [TYPE_SECTION_DEPOT_MODEL.operationModeEdit]: createDepotOperationTimingEditSectionModel({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_DEPOT_MODEL.operationModeNew]: createDepotOperationTimingEditSectionModel({
      ...args,
      typeAction: TYPE_ACTION.new,
    }),
    [TYPE_SECTION_DEPOT_MODEL.operationModeActive]: createDepotOperationTimingActiveSelectSectionModel({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit]: createMenuTemplateEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),

    [TYPE_SECTION_MESSAGE_MODEL.messageInfo]: createMessageSettingsEditSectionModel({ typeAction: TYPE_ACTION.edit }),
    [TYPE_SECTION_MESSAGE_MODEL.messageGallery]: createMessageSettingsGalleySectionModel(),
    [TYPE_SECTION_MESSAGE_MODEL.messageGalleryNew]: createMessageSettingsGalleyEditSectionModel({
      typeAction: TYPE_ACTION.new,
    }),
    [TYPE_SECTION_MESSAGE_MODEL.messageGalleryEdit]: createMessageSettingsGalleyEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),

    [MARKETPLACE_SETTINGS_MODEL.marketplaceSettings]: createMarketplaceSettingsModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),

    [MARKETPLACE_SETTINGS_MODEL.cancelReasonEdit]: createCancelReasonEditSectionModel({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.cancelReasonNew]: createCancelReasonEditSectionModel({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [MARKETPLACE_SETTINGS_MODEL.cancelReason]: createCancelReasonSectionModel({
      ...args,
    }),

    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: createParentMenuSection({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuCategoryOverride]: createParentMenuCategoriesOverride({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuProductOverride]: createParentMenuProductOverride({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuSuboptionsOverride]: createParentMenuSuboptionsOverride({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuOptionsOverride]: createParentMenuOptionsOverride({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuExtraOverride]: createParentMenuExtraOverride({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: createParentMenuEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: createOrderingMenuEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: createMenuDeliveryZonesSection({
      typeModel,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit]: createMenuDeliveryZoneEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew]: createMenuDeliveryZoneEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: createBusinesScheduleSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: createBusinesDiscountsSection({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: createBusinesDiscountEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: createBusinesDiscountEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: createBusinesInfoEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: createBusinesMenuSection({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: createBusinesMenuEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: createBusinesMenuEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: createBusinesMenuExtraSection({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: createBusinesMenuExtraEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: createBusinesMenuExtraEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: createBusinesMenuExtraOptionEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew]: createBusinesMenuExtraOptionEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionEdit]: createBusinesMenuExtraSuboptionEditSection(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionNew]: createBusinesMenuExtraSuboptionEditSection(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: createCategoryProductsSection({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: createCategoryProductsEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsDeepLinkEdit]: createDepotBusinessProductDeepLinkEditSectionModel(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: createCategoryProductsEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientNew]: createCategoryProductIngredientSection(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientEdit]: createCategoryProductIngredientSection(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: createCategoryExtrasSection({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: createCategoryExtrasEditSection({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: createCategoryExtrasEditSection({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: createCategoryExtrasOptionEditSection(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: createCategoryExtrasOptionEditSection(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: createCategoryExtrasSuboptionEditSection(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: createCategoryExtrasSuboptionEditSection(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]: createDepotMenuTemplateBusinessCategoriesEditSectionModel(
      { typeAction: TYPE_ACTION.edit }
    ),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink]: createDepotBusinessDeepLinkEditSectionModel({
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.translations]: createDepotBusinessTranslationsSectionModel({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.reviewProductExtra]: createReviewProductExtra({ ...args }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideExtra]: createOverrideExtra({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideOption]: createOverrideOption({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideSuboption]: createOverrideSuboption({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.gallery]: createBusinessGallery(),
    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryEdit]: createBusinessGalleryNew({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryNew]: createBusinessGalleryNew({
      ...args,
      typeAction: TYPE_ACTION.new,
    }),

    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: createParentMenuSectionMenuTemplate({
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: createParentMenuEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: createOrderingMenuEditSectionMenuTemplate({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: createMenuDeliveryZonesSectionMenuTemplate(
      { typeModel, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit]: createMenuDeliveryZoneEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew]: createMenuDeliveryZoneEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: createBusinesScheduleSectionMenuTemplate({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: createBusinesDiscountsSectionMenuTemplate({
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: createBusinesDiscountEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: createBusinesDiscountEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: createBusinesInfoEditSectionMenuTemplate({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: createBusinesMenuSectionMenuTemplate({
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: createBusinesMenuEditSectionMenuTemplate({
      typeAction: TYPE_ACTION.edit,
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: createBusinesMenuEditSectionMenuTemplate({
      typeAction: TYPE_ACTION.new,
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: createBusinesMenuExtraSectionMenuTemplate(
      { ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: createBusinesMenuExtraEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: createBusinesMenuExtraEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: createBusinesMenuExtraOptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew]: createBusinesMenuExtraOptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionEdit]: createBusinesMenuExtraSuboptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionNew]: createBusinesMenuExtraSuboptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: createCategoryProductsSectionMenuTemplate(
      { ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: createCategoryProductsEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: createCategoryProductsEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientNew]: createCategoryProductIngredientSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientEdit]: createCategoryProductIngredientSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: createCategoryExtrasSectionMenuTemplate(
      { ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: createCategoryExtrasEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: createCategoryExtrasEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: createCategoryExtrasOptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: createCategoryExtrasOptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: createCategoryExtrasSuboptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.edit, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: createCategoryExtrasSuboptionEditSectionMenuTemplate(
      { typeAction: TYPE_ACTION.new, ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]: createDepotMenuTemplateBusinessCategoriesEditSectionModel(
      { ...args }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuTranslations]: createDepotBusinessTranslationsSectionModelMenuTemplate(
      { ...args, typeAction: TYPE_ACTION.edit }
    ),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra]: createReviewProductExtraMenuTemplate({
      ...args,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideExtra]: createOverrideExtraMenuTemplate({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideOption]: createOverrideOptionMenuTemplate({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideSuboption]: createOverrideSuboptionMenuTemplate({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]: createBusinessGalleryMenuTemplate(),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryEdit]: createBusinessGalleryNewMenuTemplate({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryNew]: createBusinessGalleryNewMenuTemplate({
      ...args,
      typeAction: TYPE_ACTION.new,
    }),
    [RECONCILIATIONS_MODEL.settings]: createSettingsReconciliationsProviderSectionModel({ ...args }),
    [RECONCILIATIONS_MODEL.settingsTemplateNew]: createSettingsReconciliationsTemplateEditSectionModel({
      ...args,
      typeAction: TYPE_ACTION.new,
    }),
    [RECONCILIATIONS_MODEL.settingsTemplateEdit]: createSettingsReconciliationsTemplateEditSectionModel({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [RECONCILIATIONS_MODEL.periods]: createPeriodsReconciliationsSectionModel({ ...args }),
    [RECONCILIATIONS_MODEL.periodsView]: viewPeriodsReconciliationsPeriodEditSectionModel({
      ...args,
      typeAction: TYPE_ACTION.edit,
    }),
    [RECONCILIATIONS_MODEL.periodsEdit]: createPeriodsReconciliationsPeriodEditSectionModel({
      ...args,
      parentUrl: RECONCILIATIONS_MODEL.periods,
      typeAction: TYPE_ACTION.edit,
    }),
  };
  return settings[typeModel] || null;
}
