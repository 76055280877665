import { objectKeysToUpperLowerCase } from '../../../utils/objects-util';
import { ADD, DELETE, EDIT, GET, REORDER, UPDATE } from '../common-actions';

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = LOAD_USERS + "_SUCCESS";
export const LOAD_USERS_FAIL = LOAD_USERS + "_FAIL";
export const LOAD_USERS_BY_IDS = LOAD_USERS + "_BY_IDS";
export const LOAD_USERS_BY_ID = LOAD_USERS + "_BY_ID";
export const GET_USERS_BY_AVAILABLE_COURIERS_IDS = "GET_USERS_BY_AVAILABLE_COURIERS_IDS"
export const LOAD_COURIER_LOCATIONS = "LOAD_COURIER_LOCATIONS";
export const LOAD_COURIER_LOCATIONS_SUCCESS =
  LOAD_COURIER_LOCATIONS + "_SUCCESS";
export const LOAD_COURIER_LOCATIONS_FAIL = LOAD_COURIER_LOCATIONS + "_FAIL";
export const INTERVAL_COURIER_LOCATIONS = "INTERVAL_COURIER_LOCATIONS";
export const PUSHER_COURIER_LOCK_UPDATE = "PUSHER_COURIER_LOCK_UPDATE";
export const LOAD_USER_MARKERS = "LOAD_USER_MARKERS";
export const SAVE_TRAVEL_DATA = "SAVE_TRAVEL_DATA";
export const PUSHER_COURIER_SHIFT_CREATED = "PUSHER_COURIER_SHIFT_CREATED";
export const COURIER_UPDATE = "COURIER_UPDATE";
export const COURIER_APPROVE_ORDERS = "COURIER_APPROVE_ORDERS";
export const PUSHER_USER_UPDATED = "PUSHER_USER_UPDATED";
export const PUSHER_USER_DELETED = "PUSHER_USER_DELETED";
export const CYCLE_UPDATE = "CYCLE_UPDATE";
export const CYCLE_STATE_UPDATE = "CYCLE_STATE_UPDATE";
export const UPDATE_COURIER_LOCATION = "UPDATE_COURIER_LOCATION";
export const UPDATE_CONTROL_CENTERS = "UPDATE_CONTROL_CENTERS";
export const USER_CYCLE_STATE_UPDATE = "USER_CYCLE_STATE_UPDATE";
export const START_SHIFT_BACKUP = "START_BACKUP";
export const START_SHIFTS = "START_SHIFTS";
export const AVAILABLE_CONTROL_CENTERS = "GET_AVAILABLE_CONTROL_CENTERS";
export const END_SHIFT = "END_SHIFT";
export const CANCEL_SHIFT = "CANCEL_SHIFT";
export const FINISH_SHIFT = "FINISH_SHIFT";
export const GOAWAY_SHIFT = "GOAWAY_SHIFT";
export const REFRESH_GROUP = "REFRESH_GROUP";
export const DISBAND_GROUP = "DISBAND_GROUP";
export const PUSHER_DEPOT_CYCLE_REFRESH = "PUSHER_DEPOT_CYCLE_REFRESH";
export const COURIER_TO_GROUP = "COURIER_TO_GROUP";
export const NEW_USER = "NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const DELETE_USER = "DELETE_USER";
export const SET_USERS = "SET_USERS";
export const USERS_PAGINATION = "USERS_PAGINATION";
export const COURIER_PAGINATION = "COURIER_PAGINATION";
export const USER_SETTING = "USER_SETTING";
export const USER_SETTING_ADD = USER_SETTING + ADD;
export const USER_SETTING_EDIT = USER_SETTING + EDIT;
export const USER_SETTING_DELETE = USER_SETTING + DELETE;
export const USER_SETTING_PASSWORD = USER_SETTING + "_PASSWORD";
export const USER_SETTING_PASSWORD_EDIT = USER_SETTING_PASSWORD + EDIT;
export const PERSONAL_SETTING = "PERSONAL_SETTING";
export const PERSONAL_SETTING_EDIT = PERSONAL_SETTING + EDIT;
export const PERSONAL_SETTING_PASSWORD = PERSONAL_SETTING + "_PASSWORD";
export const PERSONAL_SETTING_PASSWORD_EDIT = PERSONAL_SETTING_PASSWORD + EDIT;
export const SET_USER_AVATAR = "SET_USER_AVATAR";
export const USER_SHIFTS = "USER_SHIFTS";
export const USER_SHIFTS_EDIT = USER_SHIFTS + EDIT;
export const USER_SHIFTS_DELETE = USER_SHIFTS + DELETE;
export const USER_CURRENT_SHIFTS = "USER_CURRENT_SHIFTS";
export const USER_CURRENT_SHIFTS_EDIT = USER_CURRENT_SHIFTS + EDIT;
export const UPDATE_CURRENT_SHIFTS_DEPOT_COURIERS = "UPDATE_CURRENT_SHIFTS_DEPOT_COURIERS";
export const USER_SHIFT_HISTORY = "USER_SHIFT_HISTORY";
export const USER_SHIFT_DOWNLOAD_PDF = "USER_SHIFT_DOWNLOAD_PDF";
export const USER_SHIFT_COURIERS_CSV = "USER_SHIFT_COURIERS_CSV";
export const DEPOT_SQUARE_ASSOCIATED_LOCATION = "DEPOT_SQUARE_ASSOCIATED_LOCATION";
export const DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK = "DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK";
export const DEPOT_SQUARE_ASSOCIATED_LOCATION_EDIT = "DEPOT_SQUARE_ASSOCIATED_LOCATION" + EDIT;
export const DEPOT_SQUARE_ASSOCIATED_REVOKE = "DEPOT_SQUARE_ASSOCIATED_REVOKE";
export const DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION = "DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION";
export const DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION_SHORT = DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION + "_SHORT";
export const DEPOT_INTEGRATIONS = "DEPOT_INTEGRATIONS";
export const DEPOT_INTEGRATIONS_GET = DEPOT_INTEGRATIONS + GET;
export const DEPOT_INTEGRATIONS_UPDATE = DEPOT_INTEGRATIONS + UPDATE;
export const DEPOT_INTEGRATIONS_ADD = DEPOT_INTEGRATIONS + ADD;
export const DEPOT_INTEGRATIONS_DELETE = DEPOT_INTEGRATIONS + DELETE;
export const DEPOT_PROVIDERS = "DEPOT_PROVIDERS";
export const DEPOT_PROVIDERS_GET = DEPOT_PROVIDERS + GET;
export const DEPOT_PROVIDERS_REORDER = DEPOT_PROVIDERS + REORDER;
export const DEPOT_PROVIDERS_DELETE = DEPOT_PROVIDERS + DELETE;
export const DEPOT_PROVIDERS_UNMOUNT = DEPOT_PROVIDERS + "_UNMOUNT";
export const DEPOT_PROVIDER = "DEPOT_PROVIDER";
export const DEPOT_PROVIDER_UPDATE = DEPOT_PROVIDER + UPDATE;
export const DEPOT_PROVIDER_ADD = DEPOT_PROVIDER + ADD;
export const DEPOT_PROVIDER_EDIT = DEPOT_PROVIDER + EDIT;
export const CHECK_USER_GROUP = "CHECK_USER_GROUP";
export const LOCK_GROUP = "LOCK_GROUP";
export const USERS = "USERS";
export const GET_DEFAULT_USERS_STATE = "GET_DEFAULT_USERS_STATE";
export const USERS_UPDATE = USERS + UPDATE;
export const USERS_CHECK_COLORS = USERS + "_CHECK_COLORS";

export function actionLoadUsers(payload) {
  return { type: LOAD_USERS, payload: { ...payload }, additionalParams: { batched: true } };
}

export function actionLoadUsersSuccess(data) {
  return { type: LOAD_USERS_SUCCESS, data, additionalParams: { batched: true } };
}

export function actionLoadUsersFail(error) {
  return {
    type: LOAD_USERS_FAIL,
    error: error
  };
}

export function actionLoadCourierLocations(payload) {
  return {
    type: LOAD_COURIER_LOCATIONS,
    payload: {
      ...payload
    }, 
    additionalParams: { batched: true }
  };
}

export function actionLoadCourierLocationsSuccess(data) {
  return { type: LOAD_COURIER_LOCATIONS_SUCCESS, data: objectKeysToUpperLowerCase(data), additionalParams: { batched: true } };
}

export function actionLoadCourierLocationsFail(error) {
  return {
    type: LOAD_COURIER_LOCATIONS_FAIL,
    error: error
  };
}

export function actionLoadUserMarkers(data) {
  return { type: LOAD_USER_MARKERS, data, additionalParams: { batched: true } };
}

export function actionSaveTravelData(data) {
  return { type: SAVE_TRAVEL_DATA, data, additionalParams: { batched: true } };
}

export function actionCycleStateUpdate(data) {
  return { type: CYCLE_STATE_UPDATE, data, additionalParams: { batched: true } };
}

export function actionUpdateCourierLocation(data) {
  return { type: UPDATE_COURIER_LOCATION, data, additionalParams: { batched: true } };
}

export function actionCourierUpdate(data) {
  return { type: COURIER_UPDATE, data, additionalParams: { batched: true } };
}

export function actionCourierApproveOrders(data) {
  return {
    type: COURIER_APPROVE_ORDERS,
    data: objectKeysToUpperLowerCase(data)
  };
}

export function actionCycleUpdate(data) {
  return { type: CYCLE_UPDATE, data, additionalParams: { batched: true } };
}

export function actionUpdateControlCenters(data) {
  return {
    type: UPDATE_CONTROL_CENTERS,
    data
  };
}

export function actionUserCycleUpdate(data) {
  return {
    type: USER_CYCLE_STATE_UPDATE,
    data: objectKeysToUpperLowerCase(data)
  };
}

export function actionUpdateUser(data) {
  return { type: UPDATE_USER, data };
}

export function actionNewUser(data) {
  return {
    type: NEW_USER,
    data: data
  };
}

export function actionDeleteUser(data) {
  return {
    type: DELETE_USER,
    data: data
  };
}

export function actionUpdateUserById(data) {
  return {
    type: UPDATE_USER_BY_ID,
    data: data
  };
}

export function actionSetUserAvatar(data) {
  return {
    type: SET_USER_AVATAR,
    data
  };
}

export function actionGetIntervalCourierLocations() {
  return { type: INTERVAL_COURIER_LOCATIONS };
}

export function actionCourierToGroup(data) {
  return { type: COURIER_TO_GROUP, data };
}

export function actionCheckUserGroup(data) {
  return { type: CHECK_USER_GROUP, data };  
}

export function actionLoadUsersByIds(data) {
  return { type: LOAD_USERS_BY_IDS, data };  
}

export function actionUsersUpdate(data) {
  return { type: USERS_UPDATE, data };  
}
export function actionUsersSet(data) {
  return { type: SET_USERS, data };
}

export function actionCheckUsersColors(data) {
  return { type: USERS_CHECK_COLORS, data };  
}