import { getInitModel } from "../../../services/initModels";
import { getItemsColors } from "../../../services/restaurant";

import { MODEL_TYPE } from "../../../utils/enums";
import { mergeArrays } from "../../../utils/objects-util";

import { EXTERNAL_CYCLES_UPDATE_LIST, EXTERNAL_CYCLE_REMOVE, EXTERNAL_CYCLE_UPDATE } from "./externalCycles-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    data: []
};

export default function externalCycles(state = initState, action) {
    const colors = getItemsColors(state.data);
    switch (action.type) {
        case REMOVE_ALL_DATA:
            return initState;
        case EXTERNAL_CYCLES_UPDATE_LIST:
            return {
                ...state,
                data: (action.data || [])
                    .map(externalCycle=> getInitModel(MODEL_TYPE.externalCycleModel, {
                        ...externalCycle, 
                        color: colors[externalCycle.id]
                    }))
            }
        case EXTERNAL_CYCLE_UPDATE:
            if(!action.data) return state;
            return {
                ...state,
                data: mergeArrays(
                    state.data || [], 
                    [getInitModel(MODEL_TYPE.externalCycleModel, { ...action.data, color: colors[action.data.id] })]
                )
            }
        case EXTERNAL_CYCLE_REMOVE:
            if(!Array.isArray(action.data)) return state; 
            return {
                ...state,
                data: state.data.filter(f=> !action.data.includes(f.id))
            }
        default:
            return state;
    }
}