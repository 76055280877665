import ColorService from "../services/color";
import {
  ICONS_MARKER_TYPE,
  ICONS_COURIER_MARKER,
  ORDER_STATE
} from "../utils/enums";
import { formatString } from "../utils/objects-util";
import PLACE_URL from "../assets/images/icons/place_marker.png";

let singleton = Symbol();
let singletonEnforcer = Symbol();

let cachedColors = {};
let whiteCode = ColorService.instance.getDefaultCode();

let TEMPLATE =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" x="0px" y="0px" width="22.688px" height="31.333px" viewBox="0 0 22.688 31.333" enable-background="new 0 0 22.688 31.333" ><g><g><g opacity="0.26">';
TEMPLATE +=
  '<path d="M11.813,0.958c-5.551,0-10.051,4.501-10.051,10.05c0,1.833,0.493,3.546,1.35,5.025l8.701,15.074l8.701-15.074 c0.857-1.479,1.35-3.192,1.35-5.025C21.864,5.459,17.363,0.958,11.813,0.958z"/></g>';
TEMPLATE +=
  '<path fill="{{ bgcolor }}" d="M11.113,0.258c-5.551,0-10.051,4.501-10.051,10.05c0,1.833,0.493,3.546,1.35,5.025l8.701,15.074 l8.701-15.074c0.857-1.479,1.35-3.192,1.35-5.025C21.164,4.759,16.663,0.258,11.113,0.258z"/></g>';
TEMPLATE +=
  '<g><g opacity="0.53"><circle cx="10.745" cy="10.594" r="6"/></g><circle fill="{{ circleColor }}" cx="11.245" cy="11.094" r="6"/></g>';

let TEMPLATE_GROUP =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" x="0px" y="0px" width="22.688px" height="31.333px" viewBox="0 0 22.688 31.333" enable-background="new 0 0 22.688 31.333" ><g><g><g opacity="0.63">';
TEMPLATE_GROUP +=
  '<path d="M11.813,0.958c-5.551,0-10.051,4.501-10.051,10.05c0,1.833,0.493,3.546,1.35,5.025l8.701,15.074l8.701-15.074 c0.857-1.479,1.35-3.192,1.35-5.025C21.864,5.459,17.363,0.958,11.813,0.958z" stroke-width="2" stroke="{{ stcolor }}"/></g>';
TEMPLATE_GROUP +=
  '<path fill="white" d="M11.113,0.258c-5.551,0-10.051,4.501-10.051,10.05c0,1.833,0.493,3.546,1.35,5.025l8.701,15.074 l8.701-15.074c0.857-1.479,1.35-3.192,1.35-5.025C21.164,4.759,16.663,0.258,11.113,0.258z"/></g>';
TEMPLATE_GROUP +=
  '<g><g opacity="0.53"><circle cx="10.745" cy="10.594" r="6"/></g><circle fill="{{ circleColor }}" cx="11.245" cy="11.094" r="6"/></g>';

let TEMPLATE_EMPTY = "</g></svg>";

var TEMPLATE_POINT =
  '<circle fill="#FFFFFF" cx="11.033" cy="10.987" r="3.083"/></g></svg>';

let TEMPLATE_CHECKED =
  '<path fill="#FFFFFF" d="M8.068,11.617c-0.188-0.188-0.291-0.438-0.29-0.702c0-0.264,0.104-0.512,0.292-0.698 c0.185-0.187,0.434-0.29,0.699-0.29c0.265,0,0.514,0.104,0.7,0.292l1.175,1.173l2.852-2.852c0.188-0.187,0.437-0.29,0.702-0.29 c0.264,0,0.513,0.104,0.699,0.291c0.188,0.187,0.291,0.436,0.29,0.701c0,0.264-0.104,0.513-0.291,0.698l-4.252,4.253L8.068,11.617 z"/></g></svg>';

let TEMPLATE_CANCELED =
  '<path fill="#FFFFFF" d="M14.171,12.661l-1.587-1.588l1.571-1.604c0.188-0.186,0.291-0.435,0.291-0.698 c0.001-0.266-0.103-0.515-0.29-0.701c-0.186-0.188-0.435-0.291-0.699-0.291c-0.265,0-0.514,0.104-0.702,0.29l-1.588,1.587 L9.578,8.1C9.393,7.913,9.144,7.809,8.88,7.809c-0.266-0.001-0.515,0.103-0.701,0.29C7.991,8.285,7.888,8.534,7.888,8.798 c0,0.265,0.104,0.514,0.29,0.702l1.572,1.573l-1.556,1.589c-0.188,0.186-0.291,0.435-0.291,0.698 c-0.001,0.266,0.103,0.515,0.29,0.701c0.186,0.188,0.435,0.291,0.699,0.291c0.265,0,0.514-0.104,0.702-0.29l1.573-1.572 l1.604,1.571c0.186,0.188,0.435,0.291,0.698,0.291c0.266,0.001,0.515-0.103,0.701-0.29c0.188-0.186,0.291-0.435,0.291-0.699 C14.461,13.098,14.357,12.849,14.171,12.661z"/></g></svg>';

let TEMPLATE_RESTAURANT =
  '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" x="0px" y="0px" width="41px" height="41.833px" viewBox="0 0 41 41.833" enable-background="new 0 0 41 41.833" xml:space="preserve"><g>';
TEMPLATE_RESTAURANT +=
  '<g><g opacity="0.26"><circle cx="20.7" cy="21.671" r="19"/>		<circle fill="none" stroke="#000000" cx="20.7" cy="21.671" r="19"/></g>';
TEMPLATE_RESTAURANT +=
  '<circle fill="#FFFFFF" cx="20" cy="20.971" r="19"/><circle fill="none" stroke="#CFCFCF" cx="20" cy="20.971" r="19"/></g><g><g opacity="0.53"><circle cx="19.5" cy="20.471" r="13"/></g><circle fill="#EAEAEA" cx="20" cy="20.971" r="13"/></g><g>';
TEMPLATE_RESTAURANT +=
  '<path fill="#686868" d="M19.296,18.693l-4.302,3.789v3.822c0,0.6,0.487,1.087,1.088,1.087h2.392V23.9 c0-0.36,0.292-0.652,0.651-0.652h1.74c0.36,0,0.652,0.292,0.652,0.652v3.49h2.392c0.6,0,1.087-0.487,1.087-1.087v-3.831 l-5.001-4.402L19.296,18.693z M26.223,19.743l-1.227-1.081v-2.372c0-0.48-0.39-0.87-0.87-0.87s-0.869,0.39-0.869,0.87v0.84 l-2.682-2.362c-0.329-0.289-0.821-0.289-1.149,0l-5.648,4.975c-0.36,0.317-0.396,0.867-0.078,1.228 c0.318,0.36,0.867,0.396,1.228,0.078L20,16.58l5.073,4.469c0.165,0.146,0.371,0.217,0.575,0.217c0.24,0,0.48-0.1,0.652-0.295 C26.618,20.61,26.584,20.061,26.223,19.743z"/></g></g></svg>';

let BTOA_TEMPLATE_RESTAURANT = window.btoa(TEMPLATE_RESTAURANT);

let TEMPLATE_RESTAURANT_WITH_AVATAR = '';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<g>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="#FFFFFF" cx="21" cy="20.5" r="19"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<g opacity="0.53">';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="#EAEAEA"  cx="21" cy="20.5" r="14"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '</g>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<image xlink:href="{0}" height="29.5" width="29.5" y="5.7" x="5.7"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="none" stroke="#FFFFFF" stroke-width="5" cx="21" cy="20.5" r="16"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<g opacity="0.26">';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="none" stroke="#000000" stroke-width="1" cx="21" cy="20.5" r="19"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '</g>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="none" stroke="#CFCFCF" cx="21" cy="20.5" r="19"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<g opacity="0.53">';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="none" stroke="#000000" stroke-width="0.8" cx="21" cy="20.5" r="14"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '<circle fill="none" stroke="#CFCFCF" stroke-width="1" cx="21" cy="20.5" r="14"/>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '</g>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '</g>';
TEMPLATE_RESTAURANT_WITH_AVATAR += '</svg>';

let TEMPLATE_COURIER =
  '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" x="0px" y="0px" width="41px" height="41.458px" viewBox="0 0 41 41.458" enable-background="new 0 0 41 41.458" xml:space="preserve"><g><g>';
TEMPLATE_COURIER +=
  '<g opacity="0.26"><circle cx="21.131" cy="21.587" r="19"/></g><g><circle fill="{{ bgcolor }}" cx="20.431" cy="20.887" r="19"/></g></g><g><g opacity="0.53"><circle cx="19.931" cy="20.386" r="10.333"/></g><g><circle fill="{{ circleColor }}" cx="20.431" cy="20.886" r="10.333"/>';
TEMPLATE_COURIER +=
  '</g></g><text transform="matrix(1 0 0 1 {{ position }} 25.2705)" fill="#FFFFFF" font-family="OpenSans-Bold" font-size="12">{{ dataText }}</text></g></svg>';

let TEMPLATE_COURIER_LETTER =
  '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" x="0px" y="0px" width="41px" height="41.458px" viewBox="0 0 41 41.458" enable-background="new 0 0 41 41.458" xml:space="preserve"><g><g><g opacity="0.26">';
TEMPLATE_COURIER_LETTER +=
  '<circle cx="21.131" cy="21.587" r="19"/></g><circle fill="{{ bgcolor }}" cx="20.431" cy="20.887" r="19"/></g><g><g opacity="0.53"><circle cx="19.931" cy="20.386" r="10.333"/></g><circle fill="{{ circleColor }}" cx="20.431" cy="20.886" r="10.333"/></g></g></svg>';

//let PLACE_URL = "../assets/images/icons/place_marker.png";

export default class MarkerIcons {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use MarkerIcons.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new MarkerIcons(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getOrderIconSvg(svgData) {
    return {
      url: "data:image/svg+xml;charset=UTF-8;base64," + window.btoa(svgData),
      fillOpacity: 1,
      strokeWeight: 0
      //anchor: new window.google.maps.Point(12, 31)
    };
  }

  addSvgEmpty(tmpTemplate) {
    var svgEmpty = tmpTemplate + TEMPLATE_EMPTY;
    return this.getOrderIconSvg(svgEmpty);
  }

  addSvgPoint(tmpTemplate) {
    var svgPoint = tmpTemplate + TEMPLATE_POINT;
    return this.getOrderIconSvg(svgPoint);
  }

  addSvgChecked(tmpTemplate) {
    var svgChecked = tmpTemplate + TEMPLATE_CHECKED;
    return this.getOrderIconSvg(svgChecked);
  }

  addSvgCanseled(tmpTemplate) {
    var svgCanseled = tmpTemplate + TEMPLATE_CANCELED;
    return this.getOrderIconSvg(svgCanseled);
  }

  addSvgRestouran() {
    return {
      url:
        "data:image/svg+xml;charset=UTF-8;base64," + BTOA_TEMPLATE_RESTAURANT,
      fillOpacity: 1,
      strokeWeight: 0
      //anchor: new window.google.maps.Point(20, 20)
    };
  }

  addSvgDepot(avatarBase64) {
    return {
      url:
        "data:image/svg+xml;charset=UTF-8;base64," +
        window.btoa(
          formatString(TEMPLATE_RESTAURANT_WITH_AVATAR, avatarBase64)
        ),
      fillOpacity: 1,
      strokeWeight: 0
    };
  }

  addSvgCourierLight(color) {
    var codes = ColorService.instance.getCodesByColorKey(color);
    return {
      path: "M7,-7 a16,16 0 1,0 5,0",
      fillColor: color ? codes[1] : whiteCode[1],
      fillOpacity: 1,
      strokeColor: color ? codes[0] : whiteCode[0],
      strokeWeight: 8
    };
  }

  addSvgCourier(color, textValue) {
    var codes = ColorService.instance.getCodesByColorKey(color);
    var svgData = TEMPLATE_COURIER.replace(
      "{{ bgcolor }}",
      codes ? codes[0] : whiteCode[0]
    ).replace("{{ circleColor }}", codes ? codes[1] : whiteCode[1]);
    try {
      svgData = svgData
          .replace(
              "{{ dataText }}",
              textValue ? unescape(encodeURIComponent(textValue)) : ""
          )
          .replace(
              "{{ position }}",
              textValue && textValue.length > 1 ? "12" : "16"
          );
      return {
        url: "data:image/svg+xml;charset=UTF-8;base64," + window.btoa(svgData),
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: null //new window.google.maps.Point(20, 20)
      };
    } catch (e) {
      console.error("Error marker icons:* ", e)
      return {
        url: "",
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: null //new window.google.maps.Point(20, 20)
      };
    }
  }

  addSvgCourierLetter(color) {
    var codes = ColorService.instance.getCodesByColorKey(color);
    var svgData = TEMPLATE_COURIER_LETTER.replace(
      "{{ bgcolor }}",
      codes ? codes[0] : whiteCode[0]
    ).replace("{{ circleColor }}", codes ? codes[1] : whiteCode[1]);
    return {
      url: "data:image/svg+xml;charset=UTF-8;base64," + window.btoa(svgData),
      fillOpacity: 1,
      strokeWeight: 0
      //anchor: new window.google.maps.Point(20, 20)
    };
  }

  addItemType(iconType, color, isGroup) {
    const isNotKey = (color && color.includes("#"));
    var colorKey = iconType + (color || "white");
    var svgMarker = cachedColors[colorKey];
    if (svgMarker) return svgMarker;
    var tmpTemplate;
    var codes = isNotKey
      ? [color, "#fff"]
      : ColorService.instance.getCodesByColorKey(color);
    
    if (isGroup && codes && !isNotKey) {
      tmpTemplate = TEMPLATE_GROUP.replace("{{ stcolor }}", codes[0]).replace(
        "{{ circleColor }}",
        codes[1]
      );
    } else {
      tmpTemplate = TEMPLATE.replace(
        "{{ bgcolor }}",
        codes ? codes[0] : whiteCode[0]
      ).replace("{{ circleColor }}", codes ? codes[1] : whiteCode[1]);
    }
    switch (iconType) {
      case ICONS_MARKER_TYPE.empty:
        svgMarker = this.addSvgEmpty(tmpTemplate);
        break;
      case ICONS_MARKER_TYPE.point:
        svgMarker = this.addSvgPoint(tmpTemplate);
        break;
      case ICONS_MARKER_TYPE.canseled:
        svgMarker = this.addSvgCanseled(tmpTemplate);
        break;
      case ICONS_MARKER_TYPE.checked:
        svgMarker = this.addSvgChecked(tmpTemplate);
        break;
      default:
        break;
    }
    svgMarker.markerType = iconType;
    svgMarker.color = color;
    cachedColors[colorKey] = svgMarker;
    return svgMarker;
  }

  addItemCourier(iconType, color, textValue) {
    var svgMarker;
    switch (iconType) {
      case ICONS_COURIER_MARKER.courierletter:
        svgMarker = this.addSvgCourierLetter(color);
        break;
      case ICONS_COURIER_MARKER.courier:
        svgMarker = this.addSvgCourier(color, textValue);
        break;
      case ICONS_COURIER_MARKER.courierlight:
        svgMarker = this.addSvgCourierLight(color);
        break;
      default:
        break;
    }

    return svgMarker;
  }

  convertStateToIcon(orderState, orderDispatched) {
    var iconState = ICONS_MARKER_TYPE.empty;
    switch (orderState) {
      case ORDER_STATE.new:
      case ORDER_STATE.kitchen:
      case ORDER_STATE.ready:
        iconState = ICONS_MARKER_TYPE.empty;
        break;
      case ORDER_STATE.onroute:
        iconState =
          orderDispatched > 0
            ? ICONS_MARKER_TYPE.empty
            : ICONS_MARKER_TYPE.point;
        break;
      case ORDER_STATE.delivered:
        iconState = ICONS_MARKER_TYPE.checked;
        break;
      case ORDER_STATE.canceled:
        iconState = ICONS_MARKER_TYPE.canseled;
        break;
      default:
        break;
    }
    return iconState;
  }

  convertPickUpStateToIcon(orderState, orderDispatched) {
    var iconState = ICONS_MARKER_TYPE.empty;
    switch (orderState) {
      case ORDER_STATE.new:
        iconState =
          orderDispatched > 0
            ? ICONS_MARKER_TYPE.empty
            : ICONS_MARKER_TYPE.point;
        break;
      case ORDER_STATE.kitchen:
        iconState = ICONS_MARKER_TYPE.checked;
        break;
      default:
        break;
    }
    return iconState;
  }

  getPlaceIcon() {
    return PLACE_URL;
  }
}
