import environment from "environment";

import { PUBLIC_DATA_KEYS, TYPE_STORAGE } from "./enums";
import Interval from "../services/interval";
import Pusher from "../services/pusher";
import {
  getStoreData,
  getDecodedData,
  removeData,
  checkDataExpires,
  removeStoredData
} from "../services/dataStorage";
import BaseNotification from "../services/baseNotification";
import User from "../services/user";
import SentryService from "../services/sentryService";
import UserPilotService from "../services/userPilotService";

export function getAuthorization() {
  const tokenType = getStoreData(
    TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.tokenType,
    true
  );
  const accessToken = getStoreData(
    TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.accessToken,
    true
  );
  return `${tokenType} ${accessToken}`;
}

export function getUserConnectionData() {
  return getDecodedData(PUBLIC_DATA_KEYS.accessToken, true);
}

export function getCurrentUserName() {
  return getStoreData(
    TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.currentUserName,
    true
  );
}

export function getRememberMe() {
  const rememberMe = getStoreData(
    TYPE_STORAGE.local,
    PUBLIC_DATA_KEYS.rememberMe,
    true
  );
  return String(rememberMe) === "true";
}

export function getRefreshToken() {
  return getStoreData(
    getRememberMe() ? TYPE_STORAGE.local : TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.refreshToken,
    true
  );
}

export function getAccessToken() {
  return getStoreData(
    getRememberMe() ? TYPE_STORAGE.local : TYPE_STORAGE.session,
    PUBLIC_DATA_KEYS.accessToken,
    true
  );
}

export function removeAccessToken() {
  removeData(PUBLIC_DATA_KEYS.accessToken);
}

export function checkAccessToken() {
  if (checkDataExpires(PUBLIC_DATA_KEYS.accessToken, true)) {
    removeAccessToken();
    return false;
  }
  return true;
}

export function checkRefreshToken() {
  return !!getRefreshToken();
}

export function getUserRole() {
  const token = getUserConnectionData();
  if(!token || Object.keys(token).length === 0) 
    return null;
  return token ? (Array.isArray(token.role) ? token.role : [token.role]) : [];
}

export function isAuthorized(authorizedRoles) {
  if(!authorizedRoles) return true;
  const roles = getUserRole();
  return authorizedRoles.some(value=> roles.includes(value) || value === environment.USER_ROLES.all);
}

export function logout() {
  try {
    SentryService.instance.stop();
    User.instance.setCurrentUserInfo(null);  
    Interval.instance.stop();  
    Pusher.instance.destroyPusher();
    removeStoredData();
    BaseNotification.instance.clearInterval();
    UserPilotService.instance.stop();
  } catch(error) {
    console.log("Error logout");
    console.log(error);
  }
}
