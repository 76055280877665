import {EXTRA_TYPES} from "../utils/enums";

export const reviewExtraModel = (data) => {
    const item = (data && [data]) || []
    const items = [];

    for(let extra of item) {
        items.push({...extra, tabMode: 0, extraType: EXTRA_TYPES.extra});
        for(let option of extra.options) {
            items.push({...option, tabMode: 1, extraType: EXTRA_TYPES.option});
            for(let suboption of option.suboptions) {
                items.push({...suboption, tabMode: 2, extraType: EXTRA_TYPES.suboption});
            }
        }
    }

    return {
        data: items
    };
};