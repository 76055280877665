import React from "react";
import classNames from "classnames";
import "./index.scss";

import { getConvertedCloudUrl } from "../../../utils/image-util";

const MapMarkerDepotIcon = ({ id, avatar, value }) => {
    if(!value) return (<img alt={id} src={avatar} />);
    return (
        <div className={classNames("mapMarkerDepotIcon")}>
            {!!value && (<img alt={id} src={getConvertedCloudUrl(value, 35)} />)}
        </div>
    );
}

export default MapMarkerDepotIcon;