import React, { useEffect, useState } from 'react';
import './index.scss';
import AudienceFilterItem from './AudienceFilterItem.jsx';
import classNames from 'classnames';
import IconButton from '../IconButton/IconButton.jsx';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { createAction } from '../../../utils/sagaHelper.js';
import { LOYALTY_CAMPAIGNS_AUDIENCE_FILTER_COUNT_GET } from '../../../store/reducers/restaurant/restaurant-actions.js';
import { isRTL } from '../../../services/userModel.js';
import { checkNullField, SimpleItemSelect, SwitchAudienceType } from './audienceFilterHelpers.js';
import {
  CAMPAIGN_AUDIENCE_FILTER_TYPE,
  CAMPAIGN_AUDIENCE_FILTER_TYPES,
  getAudienceFilterChannel,
} from '../../../utils/enums.js';
import { calcTimeSpan } from '../../../models/marketplaceCampaignModel.js';
import { useDebounce } from '../../hooks/useDebounce.js';

export const getAudienceFilterModel = data => ({
  field: data?.field !== undefined ? data?.field : null,
  operator: data?.operator || CAMPAIGN_AUDIENCE_FILTER_TYPES.equal,
});

const SectionItemAudienceFilter = ({ item, value, column, onPropChange, updateUserCount }) => {
  const [filtersGroup, setFiltersGroup] = useState(value || []);
  const debounceFilterGroup = useDebounce(filtersGroup, 500);
  const [usersCount, setUsersCount] = useState(0);

  const [audienceChannel, setAudienceChannel] = useState(item?.audience_channel);
  const [audienceType, setAudienceType] = useState(
    !!value ? CAMPAIGN_AUDIENCE_FILTER_TYPE.ByCriteria : CAMPAIGN_AUDIENCE_FILTER_TYPE.ByChannel
  );

  useEffect(() => {
    if (!filtersGroup && value) {
      setFiltersGroup(value || [getAudienceFilterModel()]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!filtersGroup?.length) {
      setUsersCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersCount]);

  useEffect(() => {
    if (
      !debounceFilterGroup?.length ||
      checkNullField(debounceFilterGroup) ||
      debounceFilterGroup.some(item => Object.values(item)?.length < 3)
    ) {
      setUsersCount(0);
      return;
    }

    const filterGroupForCount = calcTimeSpan(debounceFilterGroup, true);

    updateUserCount(filterGroupForCount).then(data => {
      setUsersCount(data?.Count || 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceFilterGroup]);

  useEffect(() => {
    if (audienceType === CAMPAIGN_AUDIENCE_FILTER_TYPE.ByChannel) {
      onPropChange({ [column.accessor]: null, audience_channel: audienceChannel });
    } else {
      onPropChange({ [column.accessor]: filtersGroup, audience_channel: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersGroup, audienceType, audienceChannel]);

  const addNewFilterGroup = () => {
    if (filtersGroup && Array.isArray(filtersGroup)) {
      setFiltersGroup([...filtersGroup, getAudienceFilterModel()]);
    } else {
      setFiltersGroup([getAudienceFilterModel()]);
    }
  };

  const removeFilterGroup = id => {
    setFiltersGroup(filtersGroup.filter((filter, index) => index !== id));
  };

  const handleChange = (id, data) => {
    const updateFiltersGroup = filtersGroup.map((filter, index) => (index === id ? data : filter));
    setFiltersGroup(updateFiltersGroup);
  };

  return (
    <div className={classNames('sectionItemAudienceFilter', { rtl: isRTL() })}>
      <div className={classNames('audienceFilterTitle')}>
        <div>{i18n.t('settings.campaigns.AUDIENCE_FILTER_LABEL')}</div>
        <SwitchAudienceType type={audienceType} setType={setAudienceType} />
      </div>
      {audienceType === CAMPAIGN_AUDIENCE_FILTER_TYPE.ByChannel && (
        <SimpleItemSelect
          value={audienceChannel}
          values={getAudienceFilterChannel()}
          handleChange={value => setAudienceChannel(value?.key)}
          width={'310px'}
        />
      )}
      {audienceType === CAMPAIGN_AUDIENCE_FILTER_TYPE.ByCriteria && (
        <>
          <div className={classNames('filterGroupItemsWrapper')}>
            {filtersGroup?.map((filter, index) => (
              <div className={classNames('filterGroupItem')} key={index}>
                <AudienceFilterItem index={index} filterState={filter} handleChange={handleChange} />
                <div className={classNames('buttonWrapper')}>
                  <IconButton
                    isIcon={true}
                    iconName={'deleteItem'}
                    tooltipText={i18n.t('orders.ORDER_DELETE_TOOLTIP')}
                    onClick={() =>
                      new Promise(resolve => {
                        resolve();
                        removeFilterGroup(index);
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={classNames('sectionItemAudienceFilterFooter')}>
            <IconButton
              isIcon={true}
              iconName={'iconAdd'}
              onClick={() =>
                new Promise(resolve => {
                  resolve();
                  addNewFilterGroup();
                })
              }
            />
            <div className={classNames('usersCount')}>
              <span>{usersCount + ' ' + i18n.t('settings.campaigns.AUDIENCE_FILTER_USERS_LABEL') + ' '}</span>
              <div>{i18n.t('settings.campaigns.AUDIENCE_FILTER_AFTER_USERS_LABEL')}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserCount: data => dispatch(createAction(LOYALTY_CAMPAIGNS_AUDIENCE_FILTER_COUNT_GET, data)),
  };
};

export default connect(null, mapDispatchToProps)(SectionItemAudienceFilter);
