export const MENU_PRIORITY_UPDATE = "MENU_PRIORITY_UPDATE";
export const TOGGLE_NESTED_MENU = "TOGGLE_NESTED_MENU";

export function actionMenuPriorityUpdate(data) {
  return {
    type: MENU_PRIORITY_UPDATE,
    data
  };
}

export function toggleNestedMenu(isOpen) {
  return {
    type: TOGGLE_NESTED_MENU,
    isOpen
  };
}
