export function  extraSuboptionModel(data) {
    let model = data || {};

    return {
        id: model.id || "",
        name: model.name || "",
        enabled: model.enabled || false,
        description: model.description || "",
        order: model.order || 0,
        price: model.price || 0,
		    sku: model.sku || null,
        max: model.max || 0,
        tag: model.tag || "",
    }
}

export function suboptionsOverrideModel(data) {
    let model = {...data} || {};

    return {
        id: model.id || "",
        name: model.name || "",
        description: model.description || "",
        price: model.price || 0,
        max: model.max || 0,
        sku: model.sku || null,
        tag: model.tag || "",
    }
}

export function suboptionsModelWithOverrides(data) {
    const model = {...data} || {};
    const overridesProperty = [];

    if(model && model.overrides) {
        model.overrides.forEach(ov => {
            if(ov.id === model.id) {
                model[ov.name] = ov.value;
                overridesProperty.push(ov.name);
            }
        })
    }

    return {
        ...suboptionsOverrideModel(model),
        overridesProperty: overridesProperty,
    }
}
