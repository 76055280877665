import { getDefaultLocation, getDefaultRegion } from "../services/restaurant";

import { DELIVERY_ZONE_TYPE } from "../utils/enums";

export function menuDeliveryZoneModel(data) {
  const item = data || {};
  const newItem  = {
    id: item.id || "",
    data: item.data || {
      region: data.setDefaultRegion && getDefaultRegion((item.businessMenu || {}).location, true),
      circle: null,
      reference: null,
      delivery_zone_reference: null
    },
    enabled: item.enabled || false,
    minimum: item.minimum || 0,
    name: item.name || "",
    operation_profile: item.operation_profile || "",
    price: item.price || 0,
    type: item.type || DELIVERY_ZONE_TYPE.polygon,
    price_calculation_mode_bool: item.price_calculation_mode === 0 
  };
  
  if(!newItem.data.circle) {
    newItem.data.circle = { center: getDefaultLocation((item.businessMenu || {}).location), radio: 5 }
  }

  return newItem;
}
