import i18n from "../../i18n";

import FilterOptionsModel from "../../models/filterOptionsModel";
import SectionModel from "../../models/sectionModel";

import {
    BUSINESS_PARENT_MENU,
    BUSINESS_PARENT_MENU_GET, BUSINESS_PARENT_MENU_UPDATE,
} from "../../store/reducers/restaurant/restaurant-actions";

import {
    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
    CONTROL_TYPE, MODEL_TYPE, MODEL_CONTROL_TYPE,
} from "../../utils/enums";
import { getDeliveryZonesFilter } from "../filter";
import {getInitModel} from "../initModels";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import {getMenuTemplateNameById} from "../restaurant";
import {createControlModel} from "../controlModelFactory";

export function createParentMenuSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        filterOptions: createDeliveryZonesSectionFilter(),
        isForceUpdate: true,
        columns: [
            {
                title: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU_NAME",
                accessor: "name",
                isEditable: true,
                nestedTab: 15,
                type: CONTROL_TYPE.nestedItem,
            },
            {
                accessor: "enabled",
                isEditable: true,
                component: function(item) {
                    return item && item.enabled
                        ? i18n.t("basic.ENABLED_STATUS")
                        : i18n.t("basic.DISABLED_STATUS")
                },
                type: CONTROL_TYPE.switch,
                width: "70px"
            },
            {
                title: "",
                controlTitle: "",
                accessor: "enabled",
                width: "200px",
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true
            }
        ],
        showDeleteButton: false,
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: BUSINESS_PARENT_MENU,
        editAction: BUSINESS_PARENT_MENU_UPDATE,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        typeAction: args.typeAction,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back
            }),
        ],
        label: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU",
        breadcrumbs: [
            createBreadcrumbModel({
                label: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU"
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getMenuTemplateNameById(id);
                }
            })
        ],
        mappingLambda: state => {
            const businessMenu = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu) || {};
            const parentMenu = (state.restaurant.data.parentMenu && state.restaurant.data.parentMenu) || {};
            return getInitModel(MODEL_TYPE.parentMenu, {businessMenu, parentMenu}).data;
        }
    });
}

export function createParentMenuEditSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU_NAME",
                accessor: "name",
                type: CONTROL_TYPE.text
            },
            {
                title: "settings.orderingmenu.DELIVERY_ZONE_ENABLED_LABEL",
                accessor: "enabled",
                isEditable: true,
                type: CONTROL_TYPE.switch
            },
        ],
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: BUSINESS_PARENT_MENU,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu) || {};
            const parentMenu = (state.restaurant.data.parentMenu && state.restaurant.data.parentMenu) || {};
            return getInitModel(MODEL_TYPE.parentMenu, {businessMenu, parentMenu}).data;
        }
    });
}

function createDeliveryZonesSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(deliveryZone) {
        return getDeliveryZonesFilter(this.selected, deliveryZone);
    });

    return filterOptionsModel;
}
