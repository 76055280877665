import React, {useEffect, useRef, useState} from 'react';
import DPiker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import {useTranslation} from "react-i18next";
import {useBubbleClickOutside} from "../../../services/hooks.js";
import {DATE_FORMAT_DMY2_HM} from "../../../utils/convertTime.js";
import './style.scss'
import Icon from "react-multi-date-picker/components/icon"
import classnames from "classnames";
import gregorian_en from "react-date-object/locales/gregorian_en";

const DatePicker = ({value, onSave, format, minDate, maxDate, showTimeControl, hideDateControl, placeholder, isIcon, iconClassNames, iconRender, classNames, calendarPosition, updateValueByParent}) => {
    const ref = useRef(null);
    const [canClose, setCanClose] = useState(true);
    const {t} = useTranslation();
    const [date, setDate] = useState(() => value || new Date());

    const handleOnClose = () => {
        setCanClose(true);
        setTimeout(() => {
            ref.current.closeCalendar();
            setDate(value);
        }, 0);
    }

    useEffect(() => {
        if(updateValueByParent) {
            setDate(value);
        }
    }, [value, updateValueByParent]);

    useBubbleClickOutside(['customDateTimePicker', 'customDateTimePickerIcon', 'rmdp-container'], handleOnClose)

    const handleOnSave = () => {
        setCanClose(true);
        onSave(date.toDate ? date.toDate() : date);
        setTimeout(() => ref.current.closeCalendar(), 0);
    }

    const getRenderComponent = () => {
        if(isIcon) {
            return <div onClick={() => ref.current.openCalendar()} className={classnames("customDateTimePickerIcon", iconClassNames)}>
                {iconRender
                    ? iconRender
                    : <Icon className={classnames("customDateTimePickerIcon", iconClassNames)}/>
                }
            </div>
        }
        return null;
    }

    return (
        <DPiker
            calendarPosition={calendarPosition || 'auto'}
            disableDayPicker={hideDateControl}
            portal
            onOpen={() => setCanClose(false)}
            ref={ref}
            format={format || DATE_FORMAT_DMY2_HM}
            value={date}
            placeholder={placeholder}
            locale={{
                name: 'custom_locale',
                digits: gregorian_en.digits,
                meridiems: gregorian_en.meridiems,
                weekDays: [
                    [t("datetimepicker.days.SUN"), t("datetimepicker.days.SUN")],
                    [t("datetimepicker.days.MON"), t("datetimepicker.days.MON")],
                    [t("datetimepicker.days.TUE"), t("datetimepicker.days.TUE")],
                    [t("datetimepicker.days.WED"), t("datetimepicker.days.WED")],
                    [t("datetimepicker.days.THU"), t("datetimepicker.days.THU")],
                    [t("datetimepicker.days.FRI"), t("datetimepicker.days.FRI")],
                    [t("datetimepicker.days.SAT"), t("datetimepicker.days.SAT")]
                ],
                months: [
                    [t("datetimepicker.month.JANUARY")],
                    [t("datetimepicker.month.FEBRUARY")],
                    [t("datetimepicker.month.MARCH")],
                    [t("datetimepicker.month.APRIL")],
                    [t("datetimepicker.month.MAY")],
                    [t("datetimepicker.month.JUNE")],
                    [t("datetimepicker.month.JULY")],
                    [t("datetimepicker.month.AUGUST")],
                    [t("datetimepicker.month.SEPTEMBER")],
                    [t("datetimepicker.month.OCTOBER")],
                    [t("datetimepicker.month.NOVEMBER")],
                    [t("datetimepicker.month.DECEMBER")]
                ]
            }}
            weekDays={[t("datetimepicker.days.SUN"), t("datetimepicker.days.MON"), t("datetimepicker.days.TUE"), t("datetimepicker.days.WED"), t("datetimepicker.days.THU"), t("datetimepicker.days.FRI"), t("datetimepicker.days.SAT")]}
            onChange={setDate}
            onClose={() => canClose}
            className={classnames('customDateTimePicker', classNames)}
            plugins={[].concat(showTimeControl ? <TimePicker hideSeconds /> : [])}
            minDate={minDate}
            maxDate={maxDate}
            render={getRenderComponent()}
        >
            <div className={'btns'}>
                <div className={'close'} onClick={handleOnClose}>{t('basic.CLOSE')}</div>
                <div className={'save'} onClick={handleOnSave}>{t('basic.SAVE_BUTTON')}</div>
            </div>
        </DPiker>
    )
}

export default React.memo(DatePicker);