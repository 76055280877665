import React from "react";
import { connect } from "react-redux";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { CONTROL_CENTER_REFRESH } from "../../../store/reducers/restaurant/restaurant-actions";
import {getStartedControlCenter, sortItemsByProp} from "../../../services/filter";
import { createAction } from "../../../utils/sagaHelper";

import IconButton from "../IconButton";
import DropdownMultiSelect from "../DropdownMultiSelect/index.js";

const ControlCenterSearchSelect = ({
    t,
    controlCenters, selectedControlCenterMulti,
    onSelect,
    refreshData
}) => {
    const handleOnSelect = (selectedControlCenterMulti) => {
        refreshData({ selectedControlCenterMulti: selectedControlCenterMulti === null ? [] : selectedControlCenterMulti });
    }

    //true if controlCenters.name contains letters
    const filteredControlCenters = controlCenters?.filter(el => el?.name?.replace(/\s/g, ''))

    return (
        <div className={classNames("controlCenterSearchSelect")}>
            <DropdownMultiSelect
                placeholderEmpty={t('controlCenters.SELECT_PLACEHOLDER_EMPTY')}
                placeholderMulti={t('controlCenters.SELECT_PLACEHOLDER')}
                onApply={handleOnSelect}
                values={(filteredControlCenters || controlCenters).map(cc => ({value: cc.controlCenterId, key: cc.name}))}
                selectedValues={selectedControlCenterMulti || []}
            />
            <div className={'closeButton'} onClick={()=> handleOnSelect(null)}>
                <IconButton isIcon={true} iconName="crossClose" tooltipText={t("basic.CANCEL_BUTTON")} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedControlCenter: state.restaurant.data.selectedControlCenter,
        selectedControlCenterMulti: state.restaurant.data.selectedControlCenterMulti,
        controlCenters: sortItemsByProp((state.restaurant.data.controlCenters || [])
            .filter(controlCenter=> getStartedControlCenter(controlCenter), "name")),
};
};

const mapDispatchToProps = dispatch => ({
    refreshData: data => dispatch(createAction(CONTROL_CENTER_REFRESH, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ControlCenterSearchSelect));