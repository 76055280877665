import { getRegions } from '../services/restaurant';
import { MODEL_TYPE, PRICE_CALCULATION_MODE } from '../utils/enums';

export function courierDeliveryFeesModel(data) {
    const objData = data || {};
    const regions = getRegions();

    return {
        calculationMode: objData.calculationMode || PRICE_CALCULATION_MODE.fixedRegionBased,
        basisPrice: objData.basisPrice || 0,
        maxDeliveryPrice: objData.maxDeliveryPrice || 0,
        pricePerKm: objData.pricePerKm || 0,
        basisDistanceInMeters: objData.basisDistanceInMeters || 0,
        regionConfigurations: (objData.regionConfigurations || new Array(0)).map(regionConfiguration=> {
            const region = regions.find(f=> f.id === regionConfiguration.regionId) || {};

            return {
                ...regionConfiguration,
                id: region.id || regionConfiguration.regionId,
                name: region.name || ""
            }
        }),
        modelType: MODEL_TYPE.courierDeliveryFees
    };
}

export function courierDeliveryFeesServerModel(data) {
    const objData = data || {};

    return {
        CalculationMode: objData.calculationMode || PRICE_CALCULATION_MODE.fixedRegionBased,
        BasisPrice: objData.basisPrice || 0,
        MaxDeliveryPrice: objData.maxDeliveryPrice || 0,
        deliveryPrice: objData.deliveryPrice || 0,
        PricePerKm: objData.pricePerKm || 0,
        BasisDistanceInMeters: objData.basisDistanceInMeters || 0,
        RegionConfigurations: (objData.regionConfigurations || []).map(val => ({
            RegionId: val.id || val.regionId,
            DeliveryPrice: val.deliveryPrice || 0
        }))
    };
}