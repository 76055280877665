import environment from "environment";

import AudioService from "./audio";
import { getPendingOrdersItems } from "./restaurant";
import { PENDING_ORDERS_FILTER_TYPE } from "../utils/enums";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class BaseNotification{
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer){
            throw new Error(
                "Instantiation failed: use BaseNotification.instance() instead of new."
            );
        }

        this.interval = undefined;
        this.pendingOrders = null;
    }
    
    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new BaseNotification(singletonEnforcer);
            return this[singleton];
        }
        return this[singleton];
    }

    playNotification() {
        this.interval = setInterval(() => {
            this.ordersCheck();
        }, environment.intervalNotification);
    }

    ordersCheck() {
        const pendingOrdersLength = getPendingOrdersItems(PENDING_ORDERS_FILTER_TYPE.pending).length
        if(pendingOrdersLength > this.pendingOrders) {
            AudioService.instance.playSound("newOrderCreated");
            this.pendingOrders = pendingOrdersLength
        }
    }

    clearInterval() {
        if(this.interval){
            clearInterval(this.interval);
        }
    }
}