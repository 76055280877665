import React, {useState} from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { actionAccountTopNotification } from "../../../store/reducers/notification/notification-actions";
import { accountTopNotificationColor } from "../../../utils/constants";

import IconButton from "../IconButton";
import {ModalPopup} from "../ModalPopup/ModalPopup";

const NotificationMessage = ({notification, colorTitle, useSpaces, linkBg}) => {
    return (
        <div className={classNames("data")}>
            {notification.mainMessage && (
                <div className={classNames("title")} style={{"color": colorTitle}}>{notification.mainMessage}</div>
            )}
            {useSpaces && (
                <span>
                    &nbsp;&nbsp;&nbsp;
                </span>
            )}
            {notification.secondaryMessage && (
                <a href={notification.secondaryMessageUrl || "#"}
                   style={{
                       background: linkBg
                   }}
                >
                    {notification.secondaryMessage}
                </a>
            )}
        </div>
    )
}

const AccountTopNotification = ({
    notification,
    updateAccountTopNotification
}) => {
    const [isOpenModal, setIsOpenModal] = useState(notification && notification.isShowModalDialog);

    const handleOnCloseModal = () => {
        setIsOpenModal(false);
    }

    const handleOnClose = () => {
        updateAccountTopNotification(null);
    }

    const accountTopColor = (notification && notification.color) || accountTopNotificationColor.color;

    return (
        <div className={classNames("accountTopNotification", { hide: !notification || !notification.mainMessage })}
            style={{ background: accountTopColor }}
        >
            {notification && (
                <NotificationMessage
                    useSpaces
                    notification={notification}
                    accountTopColor={accountTopColor}
                    linkBg={notification.color
                        ? (notification.secondaryColor || accountTopColor)
                        : accountTopNotificationColor.secondaryColor}
                />
            )}

            {notification && notification.isClosable && (
                <div className={classNames("controls")}>
                    <IconButton isIcon={true} iconName="cancelIcon" onClick={handleOnClose} />
                </div>
            )}

            <ModalPopup isOpen={isOpenModal} containerModalClass={"block-data"} content={
                !!notification && (
                    <div className={"block-data-container"}>
                        <NotificationMessage
                            notification={notification}
                            accountTopColor={accountTopColor}
                            colorTitle={notification.color}
                        />

                        {notification.isClosable && (
                            <button
                                onClick={handleOnCloseModal}
                                style={{background: notification.secondaryColor}}
                            >Close</button>
                        )}
                    </div>
                )
            }/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        notification: state.notifications.data.accountTopNotification
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateAccountTopNotification: data => dispatch(actionAccountTopNotification(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountTopNotification);
