import React, {useState} from "react";
import IconButton from "../IconButton";
import {connect} from "react-redux";
import {createAction} from "../../../utils/sagaHelper.js";
import ConfirmDialog from "../ConfirmDialog";
import './style.scss';

const LinkActionIcon = ({tooltip, iconName, withConfirm, data, actionName, confirmAction}) => {
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleOnConfirm = () => {
        return confirmAction(actionName, data).finally(() => setConfirmOpen(false));
    }
    const handleOnClick = () => {
        if(withConfirm) {
            setConfirmOpen(true);
            return;
        }
        return handleOnConfirm();
    }

    return (
        <div className={'linkActionIcon'}>
            <IconButton isIcon iconName={iconName} tooltipText={tooltip} onClick={handleOnClick}/>
            {confirmOpen && (
                <ConfirmDialog
                    handleOnCancel={() => setConfirmOpen(false)}
                    handleOnConfirm={handleOnConfirm}
                />
            )}
        </div>
    )
}

const mdtp = dispatch => ({
    confirmAction: (action, data) => dispatch(createAction(action, data))
})

export default connect(null, mdtp)(LinkActionIcon);