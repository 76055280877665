import {getISOTime} from "../utils/convertTime";

export const currentShiftServerModel = user => ({
    courierId: user.courierId || user.userId,
    shiftType: user.shiftType || 0,
    vehicleId: user.vehicleId || null,
    controlCenterId: user.controlCenterId || '',
    startTime: getISOTime(user.startTime),
    endTime: getISOTime(user.endTime),
    allowedDepots: user.allowedDepots
})
