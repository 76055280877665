import { SET_EMPTY_INFO } from "./emptyInfo-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    message: ""
};

export default function emptyInfo(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA:
            return initState;
        case SET_EMPTY_INFO:
            return { ...state, ...action.data } 
        default:
            return state;
    }
}