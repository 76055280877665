import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";

export function restaurantRegionServerModel(region) {
  return {
    Id: region.id,
    Name: region.name,
    MaxDeliveryTime: region.maxDeliveryTime,
    PendingOrdersGroupingTimeDelta: region.pendingOrdersGroupingTimeDelta,
    DefaultControlCenterId: region.defaultControlCenterId,
    Region: region.region.map(reg =>
      getInitModel(MODEL_TYPE.locationServerModel, reg)
    )
  };
}
