import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import User from "../../../services/user";
import { isRTL } from "../../../services/userModel";

import { getPages } from "../../../utils/routesHelper";

import AlarmsBar from "../AlarmsBar";

const AppContent = ({ matches, notification }) => {
    const Pages = getPages();
    return (
        <div className={classNames("appContent", { rtl: isRTL(), hasNotes: !!notification })}>
            <Pages isMobile={matches} />
            <AlarmsBar />
        </div>
    );
}

const mapStateToProps = state => ({
    language: User.instance.getCurrentUserConfigurationProp("language", true),
    notification: state.notifications.data.accountTopNotification
});

export default connect(mapStateToProps)(AppContent);