import SectionModel from "../../models/sectionModel.js";
import {
    CLOUD_PRINTERS_SETTING,
    CLOUD_PRINTERS_SETTING_GET
} from "../../store/reducers/restaurant/restaurant-actions.js";
import {
    CONTROL_TYPE,
    languages,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
    TYPE_SECTION_MODEL
} from "../../utils/enums.js";
import FilterOptionsModel from "../../models/filterOptionsModel.js";
import {createControlModel} from "../controlModelFactory.js";
import {getCloudPrinterById, isMobileWidth} from "../restaurant.js";
import {createBreadcrumbModel} from "../createBreadcrumbModel.js";
import {getInitModel} from "../initModels.js";
import i18n from "i18next";
import {getHistoryLocationParams} from "../../utils/routesHelper.js";

export function createCloudPrintersSectionModel() {
    return new SectionModel({
        filterOptions: new FilterOptionsModel(),
        columns: [
            {
                title: "settings.cloudPrinters.PRINTER_PRN",
                accessor: "prnID",
                width: "300px",
                fixedWidth: "150px",
            },
            {
                title: "settings.cloudPrinters.LANGUAGE",
                accessor: "language",
                width: "100%",
                fixedWidth: "150px",
            },
            {
                title: 'settings.cloudPrinters.ACTIONS',
                textAlign: 'center',
                width: '65px',
                headerWidth: '98px',
                type: CONTROL_TYPE.link,
                isShow: () => true,
                link: TYPE_SECTION_MODEL.cloudPrintersEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('basic.EDIT_BUTTON'),
            },
        ],
        actionName: CLOUD_PRINTERS_SETTING,
        actionLoad: CLOUD_PRINTERS_SETTING_GET,
        typeModel: TYPE_SECTION_MODEL.cloudPrinters,
        label: "settings.cloudPrinters.CLOUD_PRINTERS",
        breadcrumbs: [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.cloudPrintersNew
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        mappingLambda: state => {
            let result = state?.restaurant?.data?.cloudPrinters;
            return result ? result : [];
        }
    });
}

export function createCloudPrintersEditSectionModel({...args}) {
    return new SectionModel({
        filterOptions: new FilterOptionsModel(),
        columns: [
            {
                title: 'settings.cloudPrinters.PRINTER_PRN',
                accessor: 'prnID',
                isEditable: true,
                isRequired: true, 
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.cloudPrinters.LANGUAGE',
                accessor: 'language',
                connect: () => ({
                    values: languages(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
                isRequired: true
            },
            {
                title: 'settings.cloudPrinters.AUTOMATIC_PRINTING_OF_ORDER',
                accessor: 'autoPrintBon',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.cloudPrinters.AUTOMATIC_PRINTING_OF_NEW_ORDER_NOTIFICATION',
                accessor: 'autoPrintNewOrderNotification',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },],
        typeAction: args.typeAction,
        actionName: CLOUD_PRINTERS_SETTING,
        actionLoad: args.typeAction === TYPE_ACTION.edit ? CLOUD_PRINTERS_SETTING_GET : CLOUD_PRINTERS_SETTING,
        parentUrl: TYPE_SECTION_MODEL.cloudPrinters,
        label: args.typeAction === TYPE_ACTION.new ? "settings.cloudPrinters.CLOUD_PRINTERS" : "",
        getLabel: (data) => {
            if(data && data.id) {
                const result = getCloudPrinterById(data.id);
                return result ? result.prnID : ""
            }
            return "";
        },
        initNewItemModel: item =>
            getInitModel(MODEL_TYPE.cloudPrinters, item),
        breadcrumbs: args.typeAction === TYPE_ACTION.edit ? [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
            createBreadcrumbModel({label: "settings.cloudPrinters.CLOUD_PRINTERS"}),
        ] : [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
        ],
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? TYPE_SECTION_MODEL.cloudPrintersEdit
                : TYPE_SECTION_MODEL.cloudPrintersNew,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: () => true,
            }),
        ],
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const cloudPrinter = state?.restaurant?.data?.cloudPrinters;
            const result = cloudPrinter?.find((e) => e.id === id)

            return result ? result : null;
        }
    });
}

