import axios from "axios";
import environment from "environment";
import {toast} from "react-toastify";

import Store from "../store/store";

import {CHECK_USER_AUTHENTICATION} from "../store/reducers/authentication/authentication-actions";
import {getAuthorization, isAuthorized} from "./auth-util";
import {createAction} from "./sagaHelper";
import {EXCLUDED_CHECK_ERROR_APIS, REQUEST_METHOD} from "./constants";

async function defaultRequest(axiosInstance, requestConfig) {
  const request = axiosInstance.request(requestConfig);
  return await request;
}

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      if (error.config.skipRetry || !error.config.retryCount) {
        return error;
      }
      return Store.store
        .dispatch(createAction(CHECK_USER_AUTHENTICATION, { isRemoveAcccessToken: true }))
        .then(res => {
          delete error.config.headers.Authorization;
          error.config.retryCount--;
          return callServer({ ...error.config });
        });
    } else {
      if(!EXCLUDED_CHECK_ERROR_APIS.includes(error.config.url) &&
        (error.config.method === REQUEST_METHOD.post || error.config.method === REQUEST_METHOD.put)
      ) {
        toast.error(getErrorMessage(error));
      }
      return error;
    }
  }
);

export async function callServer(requestConfig, noAuth) {
  if(!isAuthorized(requestConfig && requestConfig.roles)) {
    return { status: 200 };
  }

  const headers = { ...getHeaders(), ...requestConfig.headers };

  if(requestConfig.isVideoType) {
    headers["content-type"] = "video/mp4";
  }

  if(noAuth) {
    delete headers.Authorization;
  }

  return await defaultRequest(axiosInstance, {
    retryCount: environment.unauthorizedRetryCount,
    ...requestConfig,
    headers
  });
}

export function getHeaders(props = null) {
  return {
    "content-type": "application/json",
    Authorization: getAuthorization(),
    ...props
  };
}

export function getErrorMessage(requestResult, customError) {
  if(!requestResult) return "Error";
  const response = requestResult.response || {};
  return (
    response.Error ||
    (response.data ? (response.data.Message || "Error") : customError || "Error")
  );
}

export function requestResultIsOk(requestResult, checkData = false) {
  return requestResult && requestResult.status === 200 && (!checkData || requestResult.data);
}

export function checkRequestResultStatus(requestResult, status) {
  return (requestResult && requestResult.status === status) ||
    (requestResult && requestResult.response && requestResult.response.status) === status;
}
