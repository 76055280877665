import { CONTROL_CENTER_REFRESH_LOADING } from "./loaders-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    controlCenterRefresh: false
};

export default function loaders(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA:
            return initState;
        case CONTROL_CENTER_REFRESH_LOADING:
            return { ...state, controlCenterRefresh: action.data }
        default:
            return state;
    }
}