import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { RESTAURANT_CONTROL_CENTER_STATUS_GET } from "../../../store/reducers/restaurant/restaurant-actions";

import { createAction } from "../../../utils/sagaHelper";

import AssociatePopup from "../../components/AssociatePopup/AssociatePopup";

const mapStateToProps = state => {
  return {
    couriers: state.userModel.data || [],
    controlCenters: state.restaurant.data.controlCenters || []
  };
};

const mapDispatchToProps = dispatch => ({
  controlCenterStatus: data => dispatch(createAction(RESTAURANT_CONTROL_CENTER_STATUS_GET, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AssociatePopup));
