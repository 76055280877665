import { EDIT } from "../common-actions";

export const LOAD_MARKERS = "LOAD_MARKERS";
export const LOAD_ORDER_MARKERS = "LOAD_ORDER_MARKERS";
export const LOAD_COURIER_MARKERS = "LOAD_COURIER_MARKERS";
export const LOAD_DEPOT_MARKERS = "LOAD_DEPOT_MARKERS";
export const MARKER_UPDATE = "MARKER_UPDATE";
export const UPDATE_ORDER_MARKER = "UPDATE_ORDER_MARKER";
export const UPDATE_ORDERS_MARKERS = "UPDATE_ORDERS_MARKERS";
export const UPDATE_EXTERNAL_CYCLES_MARKERS = "UPDATE_EXTERNAL_CYCLES_MARKERS";
export const UPDATE_EXTERNAL_CYCLES_MARKERS_EDIT = UPDATE_EXTERNAL_CYCLES_MARKERS + EDIT;
export const UPDATE_COURIER_MARKER = "UPDATE_COURIER_MARKER";
export const UPDATE_COURIERS_MARKERS = "UPDATE_COURIERS_MARKERS";
export const UPDATE_ALL_COURIERS_MARKERS = "UPDATE_ALL_COURIERS_MARKERS";
export const UPDATE_DEPOT_MARKER = "UPDATE_DEPOT_MARKER";
export const UPDATE_DEPOTS_MARKERS = "UPDATE_DEPOTS_MARKERS";
export const NEW_ORDER_MARKER = "NEW_ORDER_MARKER";
export const UPDATE_COURIER_LOCATION_MARKER = "UPDATE_COURIER_LOCATION_MARKER";
export const REMOVE_MARKER = "REMOVE_MARKER";
export const REMOVE_MARKERS = "REMOVE_MARKERS";
export const UPDATE_SHOW_COURIER_MARKER = "UPDATE_SHOW_COURIER_MARKER";
export const SET_SHAKE_MARKER = "SET_SHAKE_MARKER";
export const SHAKE_MARKER = "SHAKE_MARKER";
export const CHECK_MARKERS = "CHECK_MARKERS";

export function actionLoadMarkers(data) {
  return { type: LOAD_MARKERS, data };
}

export function actionLoadOrderMarkers(data) {
  return {
    type: LOAD_ORDER_MARKERS,
    data
  };
}

export function actionLoadCourierMarkers(data) {
  return {
    type: LOAD_COURIER_MARKERS,
    data
  };
}

export function actionLoadDepotMarkers(data) {
  return {
    type: LOAD_DEPOT_MARKERS,
    data
  };
}

export function actionUpdateOrderMarker(data) {
  return {
    type: UPDATE_ORDER_MARKER,
    data
  };
}

export function actionUpdateOrdersMarkers(data) {
  return {
    type: UPDATE_ORDERS_MARKERS,
    data
  };
}

export function actionUpdateCourierMarker(data) {
  return {
    type: UPDATE_COURIER_MARKER,
    data
  };
}

export function actionUpdateAllCouriersMarkers(data) {
  return {
    type: UPDATE_ALL_COURIERS_MARKERS,
    data
  };
}

export function actionUpdateDepotMarker(data) {
  return {
    type: UPDATE_DEPOT_MARKER,
    data
  };
}

export function actionUpdateDepotsMarkers(data) {
  return {
    type: UPDATE_DEPOTS_MARKERS,
    data
  };
}

export function actionNewOrderMarker(data) {
  return {
    type: NEW_ORDER_MARKER,
    data
  };
}

export function actionUpdateCourierLocationMarker(data) {
  return {
    type: UPDATE_COURIER_LOCATION_MARKER,
    data
  };
}

export function actionRemoveMarker(data) {
  return {
    type: REMOVE_MARKER,
    data
  };
}

export function actionRemoveMarkers() {
  return {
    type: REMOVE_MARKERS
  };
}

export function actionUpdateShowCourierMarker(data) {
  return {
    type: UPDATE_SHOW_COURIER_MARKER,
    data
  };
}

export function actionUpdateCouriersMarkers(data) {
  return {
    type: UPDATE_COURIERS_MARKERS,
    data
  };
}

export function actionSetShakeMarker(data) {
  return {
    type: SET_SHAKE_MARKER,
    data
  };
}

export function actionShakeMarker(data) {
  return {
    type: SHAKE_MARKER,
    data
  };
}

export function actionCheckMarkers() {
  return { type: CHECK_MARKERS };
}

export function actionExternalCyclesMarkersUpdate(data) {
  return { type: UPDATE_EXTERNAL_CYCLES_MARKERS, data };
}

export function actionMarkerUpdate(data) {
  return { type: MARKER_UPDATE, data }
}
