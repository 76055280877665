import environment from "environment";
import i18n from "../i18n";

import { SOUND_ERRORS, SOUND_NOTIFICATION } from "../utils/enums";
import { isNeedToAlarm } from "../services/notification";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class AudioService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use AudioService.instance() instead of new."
      );

    this.audioPlayStack = [];
    this.conveyorInProcess = false;

    this.processConveyor = this.processConveyor.bind(this);
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new AudioService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  playSound(audioEnum) {
    if (!audioEnum || !isNeedToAlarm(audioEnum)) return;

    this.audioPlayStack.push(SOUND_NOTIFICATION[audioEnum]);

    if (!this.conveyorInProcess) {
      this.processConveyor();
    }
  }

  processConveyor() {
    this.conveyorInProcess = true;
    const item = this.audioPlayStack.shift();
    if (item) {
      this.doPlaySound(item).then();
    } else {
      this.conveyorInProcess = false;
    }
  }

  doPlaySound(audioEnum) {
    return new Promise((resolve, reject) => {
      const audioPath = i18n.t(audioEnum);
      if (audioPath) {
        const audio = new Audio(audioPath);
        audio.addEventListener("ended", () => {
          resolve();
          this.processConveyor();
        });
        audio.play().then(() => {

        }, error => {
            const ontIncludeErrorList = [SOUND_ERRORS.notAllowedError];
            if (ontIncludeErrorList.includes(error.name)) {
              this.audioPlayStack.push(audioEnum);
              setTimeout(() => this.processConveyor(), environment.requestAudioTime);
            } else {
              this.processConveyor();
            }
          }
        );
      } else {
        reject(`Audio enum: ${audioEnum} do not contain sound path.`);
      }
    });
  }
}
