import { MODEL_TYPE } from "../utils/enums";

export function serviceFeeModel(data) {
    const item = data || {};
    return {
        enabled: item.enabled || false,
        fixValue: item.fixValue || 0,
        fixPercent: item.fixPercent || 0,
        modelType: MODEL_TYPE.serviceFeeModel
    };
}

export function serviceFeeServerModel(data) {
    const item = data || {};
    return {
        Enabled: item.enabled || false,
        FixValue: item.fixValue || 0,
        FixPercent: item.fixPercent || 0
    };
}