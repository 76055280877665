export const currentShift = user => {
  const data = user.cycle || {};
  const shift = {
    userId: user.userId,
    shiftId: data.shiftId || '',
    allowedDepots: data.allowedDepots || [],
    startTime: data.startTime || '',
    endTime: data.endTime || ''
  };

  return data ? { ...shift, ...data } : shift;
}