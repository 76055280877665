import i18n from "../../i18n";

import SectionModel from "../../models/sectionModel";
import FilterOptionsModel from "../../models/filterOptionsModel";

import {
  CONTROL_TYPE,
  TYPE_SECTION_MODEL,
  MODEL_TYPE,
  TYPE_ACTION,
  MODEL_CONTROL_TYPE
} from "../../utils/enums";
import { getNamedPlaceFilter, sortItemsByProp } from "../filter";
import { getAddress } from "../order";
import { getInitModel } from "../initModels";
import { NAMED_PLACES, NAMED_PLACES_GET } from "../../store/reducers/restaurant/restaurant-actions";
import { createControlModel } from "../controlModelFactory";
import {isMobileWidth} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createNamedPlacesSectionModel() {
  return new SectionModel({
    filterOptions: createNamedPlacesSectionFilter(),
    columns: [
      {
        title: "settings.PLACE_NAME_LABLE",
        accessor: "name"
      },
      {
        title: "settings.PLACE_ADDRESS",
        component: function(place) {
          if (!place || !place.address) return "";
          return getAddress(place.address.address);
        },
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: "90px",
        centered: true,
        type: CONTROL_TYPE.link,
        link: TYPE_SECTION_MODEL.namedPlacesEdit,
        iconName: "editItem",
        tooltipText: i18n.t("settings.users.USER_TOOLS_EDIT_TOOLTIP")
      }
    ],
    actionName: NAMED_PLACES,
    actionLoad: NAMED_PLACES_GET,
    tableMinWidth: 800,
    typeModel: TYPE_SECTION_MODEL.namedPlaces,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: TYPE_SECTION_MODEL.namedPlacesNew
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.NAMED_PLACES_TITLE",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
    ],
    mappingLambda: state => state?.restaurant?.data?.namedPlaces,
    sortByFunc: items=> sortItemsByProp(items)
  });
}

export function createNamedPlacesEditSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.PLACE_NAME_LABLE",
        accessor: "name",
        isEditable: true,
        isRequired: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "new-order-directive.ADDRESS_LABEL",
        accessor: "address",
        isEditable: true,
        isShowIcon: true,
        isCanBeEmpty: true,
        withEmpty: true,
        type: CONTROL_TYPE.address
      },
      {
        title: "",
        accessor: "address",
        isEditable: true,
        isFillContent: true,
        isHideSearch: true,
        type: CONTROL_TYPE.mapAddress
      }
    ],
    actionName: NAMED_PLACES,
    initNewItemModel: item => getInitModel(MODEL_TYPE.namedPlaces, item),
    typeAction: args.typeAction,
    actionLoad: NAMED_PLACES_GET,
    typeModel:
      args.typeAction === TYPE_ACTION.edit
        ? TYPE_SECTION_MODEL.namedPlacesEdit
        : TYPE_SECTION_MODEL.namedPlacesNew,
    parentUrl: TYPE_SECTION_MODEL.namedPlaces,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.NAMED_PLACES_TITLE",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
    ],
    mappingLambda: state => state.restaurant.data?.namedPlaces || []
  });
}

function createNamedPlacesSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(place) {
    return getNamedPlaceFilter(this.selected, place);
  });

  return filterOptionsModel;
}
