import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { DEFAULT_ORDERS_GROUPS, CYCLE_STATE, ORDER_STATE, WAITER_TYPES } from "../../../utils/enums";
import { createAction } from "../../../utils/sagaHelper";
import { defaultPromiseResolve } from "../../../utils/objects-util";

import { getUserByOrder } from "../../../services/userModel";
import { isAutoMode } from "../../../services/restaurant";

import { CHANGE_ORDER_STATE } from "../../../store/reducers/order/order-actions";

import Waiter from "../Waiter/Waiter";
import AssociatePopup from "../../containers/AssociatePopupContainer";

const OrderStateControl = ({
    t, 
    order, courier, associateMode, hideAssociate, isHideDeliveryAction,
    restaurant, users,
    onConfirm,
    changeOrderState
}) => {
    const targetRef = useRef();
    const [controlCenterId, setControlCenterId] = useState(null);
    const [isOpenAssociatePopup, setIsOpenAssociatePopup] = useState(false);

    const isShowOrderStateControl = () => {
        return (order && order.groupId !== DEFAULT_ORDERS_GROUPS.assistance);
    }

    const isShowAssociate = () => {
        return (order && !getAutoMode() && !order.type && isShowAssociateCourier() && !hideAssociate);
    }

    const getAutoMode = () => {
        return isAutoMode(restaurant);
    };

    const isCourierStarted = () => {
        const userModel = getUserByOrder(users, order);
        return userModel && (userModel.cycle && userModel.cycle.state > CYCLE_STATE.started && userModel.cycle.state < CYCLE_STATE.comingBack);
    }

    const isShowAssociateCourier = () => {
        if (!order) return false;    
        const userModel = getUserByOrder(users, order);
        return !userModel || userModel.state === CYCLE_STATE.started || userModel.state === CYCLE_STATE.away;
    };

    const isShowKitchen = () => {
        return order && order.state === ORDER_STATE.new && (getAutoMode() || hideAssociate || order.type);
    }

    const isShowDelivery = () => {
        return order && order.state === ORDER_STATE.kitchen && (getAutoMode() || hideAssociate || order.type);
    }

    const isShowReady = () => {
        return (order &&
            ((order.state === ORDER_STATE.ready && !isCourierStarted()) || order.state === ORDER_STATE.delivered || order.state === ORDER_STATE.canceled) &&
            !order.type && (getAutoMode() || !isShowAssociateCourier() || hideAssociate)
        );
    }

    const isShowDelivered = () => {
        return !isHideDeliveryAction && (
            order && !order.type &&
            ((order.state === ORDER_STATE.onroute || order.state === ORDER_STATE.ready) && isCourierStarted())
        );
    }

    const isShowPickedUp = () => {
        return order && order.state === ORDER_STATE.ready && order.type;
    }

    const handleOnAssociateConfirm = (courierId, isExternalType, provider, isExternalAssignment) => {
        return new Promise((resolve, reject)=> {
            if(onConfirm) {
                onConfirm(courierId, isExternalType, provider, isExternalAssignment)
                    .then(()=> {
                        return handleOnClose();
                    }, (err)=> {
                        handleOnClose().then(()=> reject(err), ()=> reject(err));
                    });
            } else {
                return handleOnClose();
            }
        });
    }

    const handleOnClose = () => {
        return new Promise((resolve, reject)=> {
            if(targetRef && targetRef.current) {
                setIsOpenAssociatePopup(false);
            }
            resolve();
        });
    }

    const handleAssociatePopupOpen = (e, controlCenterId) => {
        if(e) e.stopPropagation();

        return new Promise(resolve=> {
            resolve();
            setControlCenterId(controlCenterId);
            Modal.setAppElement("#modal-root");        
            setIsOpenAssociatePopup(true);
        });    
    }

    const handleOnChangeOrderState = state => {
        return new Promise((resolve, reject)=> {
            changeOrderState({ order, state }).then(()=> {
                resolve();
            }, error=> {
                reject(error || "Error");
                toast.error(error || "Error");
            })
        });
    }

    const getButtonConfig = () => {
        let config = {};
        if(isShowAssociate() && (order || {}).state > 1) {
            config = {
                type: WAITER_TYPES.buttonDiv,
                useClass: classNames("associate"),
                useIcon: classNames("associate-img"),
                tooltipText: t("dashboard.orders.ASSOCIATE_BUTTON"),
                handleOnClick: handleAssociatePopupOpen
            }
        }

        if(isShowAssociate() && order.state <= 1) {
            config = {
                type: WAITER_TYPES.buttonDiv,
                useClass: classNames("associate-grey"),
                useIcon: classNames("associate-img"),
                tooltipText: t("dashboard.orders.ASSOCIATE_BUTTON"),
                handleOnClick: handleAssociatePopupOpen
            }
        }

        if(isShowKitchen()) {
            config = {
                type: WAITER_TYPES.buttonSvg,
                useClass: classNames("associate"),
                useSvg: "#icon-pan",
                tooltipText: t("dashboard.orders.KITCHEN_BUTTON"),
                stopPropagation: true,
                handleOnClick: () => handleOnChangeOrderState(ORDER_STATE.kitchen)
            }
        }

        if(isShowDelivery()) {
            config = {
                type: WAITER_TYPES.buttonSvg,
                useClass: classNames("associate"),
                useSvg: "#icon-bag",
                tooltipText: t("dashboard.orders.DELIVERY_BUTTON"),
                stopPropagation: true,
                handleOnClick: () => handleOnChangeOrderState(ORDER_STATE.ready)
            } 
        }

        if(isShowReady()) {
            config = {
                type: WAITER_TYPES.buttonSvg,
                useClass: classNames("associate", "ready"),
                useSvg: "#icon-check",
                tooltipText: t("dashboard.orders.READY_LABEL"),
                stopPropagation: true,
                handleOnClick: defaultPromiseResolve
            } 
        }

        if(isShowDelivered()) {
            config = {
                type: WAITER_TYPES.buttonDiv,
                useClass: classNames("delivered"),
                useIcon: classNames("action-icon", "delivered-action-icon"),
                tooltipText: t("dashboard.orders.DELIVERED_BUTTON"),
                handleOnClick: () => handleOnChangeOrderState(ORDER_STATE.delivered)
            }
        }

        if(isShowPickedUp()) {
            config = {
                type: WAITER_TYPES.buttonSvg,
                useClass: classNames("pickedup"),
                useSvg: "#icon-arrow-top",
                stopPropagation: true,
                handleOnClick: () => handleOnChangeOrderState(ORDER_STATE.delivered)
            } 
        }

        return config;
    }

    const config = getButtonConfig();

    return (
        <div ref={targetRef}
            className={classNames("orderStateControl", { hide: !isShowOrderStateControl() })}
            onClick={e => e.stopPropagation()}
        >
            {!!order && (<Waiter 
                type={config.type}
                useClass={config.useClass}
                useIcon={config.useIcon}
                useSvg={config.useSvg}
                stopPropagation={config.stopPropagation}
                tooltipText={config.tooltipText}
                handleOnClick={(e) => config.handleOnClick(e, order.controlCenterId)}
            />)}
            {isOpenAssociatePopup && targetRef && targetRef.current && (
                <AssociatePopup isOpen={isOpenAssociatePopup}
                    selectedOrderIds={[order.id]}
                    isGetControlCenters={true}
                    courier={courier}
                    controlCenterIds={[controlCenterId]}
                    filterByControlCenterIds
                    associateMode={associateMode}
                    onConfirm={handleOnAssociateConfirm}
                    onClose={handleOnClose}
                />
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        users: state.userModel.data || [],
        restaurant: state.restaurant.data
    };
};

const mapDispatchToProps = dispatch => ({
    changeOrderState: data => dispatch(createAction(CHANGE_ORDER_STATE, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderStateControl));