import { removeStoredData } from "./dataStorage";

import BaseNotification from "./baseNotification";
import Interval from "./interval";
import Pusher from "./pusher";
import SentryService from "./sentryService";
import User from "./user";
import UserPilotService from "./userPilotService";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class InitService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use InitService.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new InitService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  init() {
    SentryService.instance.init();
  }

  logout() {
    try {
        SentryService.instance.stop();
        User.instance.setCurrentUserInfo(null);  
        Interval.instance.stop();  
        Pusher.instance.destroyPusher();
        removeStoredData();  
        BaseNotification.instance.clearInterval();
        UserPilotService.instance.stop();
    } catch (error) {
        console.log(error);
    }               
  }
}