import {callServer} from "../utils/request-util";
import environment from "environment";

export function fetchAuthSupportChat() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "chat/auth",
            method: "GET",
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchDialogsSupportChat() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "chat/pendingChats",
            method: "GET",
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function pullSupportDialog(chatId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + `chat/request/pull/${chatId}`,
            method: "POST",
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function resolveSupportDialog(chatId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + `chat/${chatId}/close`,
            method: "POST",
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function startChat(data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + `chat/start`,
            method: "POST",
            data: {
                Subject: data.subject,
                DepotId: data.depotId,
                CourierId: data.courierId,
                ConsumerId: data.consumerId
            },
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function addUserToChat(chatId, userId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + `chat/join/${chatId}/${userId}`,
            method: "POST",
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getUserDataByParticipantId(id) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "chat/user/" + id,
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function posrUserDataByParticipant(body) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "chat/users",
            data: body,
            method: "POST",
            roles: [environment.USER_ROLES.supportOperator]
        }).then(response => resolve(response), error => reject(error.response));
    });
}


