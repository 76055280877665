import React from "react";
import "./index.scss";
import IconButton from "../IconButton";
import {useDispatch} from "react-redux";
import {createAction} from "../../../utils/sagaHelper";
import {getTranslateTitle} from '../../../utils/objects-util.js';
import i18n from '../../../i18n.js';
import classNames from "classnames";

const ColumnTitleWithIcon = ({column, item, onPropChange}) => {
    const {title, clickAction, labelIconName, isShowIconLabel, isNoClickableIcon} = column;
    const dispatch = useDispatch();
    const translateTitle = getTranslateTitle(title)
    const additionalInformation = i18n.exists(title + '_TOOLTIP') ? i18n.t(title + '_TOOLTIP') : ''

    const handleOnClick = () => {
        if (clickAction) {
            dispatch(createAction(clickAction, {item, column, onPropChange}));
        }
    }

    if (!title) {
        return null;
    }

    return (
        <div className={"propItemLabelWithIcon"} style={{justifyContent: column.alignItems || ''}}>
            <div className={"propItemLabel"}>
                {translateTitle}
            </div>
            {additionalInformation && (
                <div className={"tooltip-icon"}>
                    <IconButton isIcon iconName={"info"} tooltipText={additionalInformation}/>
                </div>
            )}
            {isShowIconLabel && isShowIconLabel(item) && (
                <div className={classNames({propItemLabelWithIconBlock: isNoClickableIcon && isNoClickableIcon()})}>
                    <IconButton isIcon onClick={handleOnClick} iconName={labelIconName}/>
                </div>
            )}
        </div>

    )
}

export default ColumnTitleWithIcon;
