import {EXTRA_TYPES, PARENT_MENU_UNIT_TYPES} from "../utils/enums";

export const  parentMenuModel = ({businessMenu, parentMenu}) => {
    const items = [];
    const overriderProduct = businessMenu.overrides || [];
    for(let categories of (parentMenu && parentMenu.categories) || []) {
        items.push({...categories, type: PARENT_MENU_UNIT_TYPES.category, parentValue: ''});
        for(let product of categories.products) {
            items.push({...product, type: PARENT_MENU_UNIT_TYPES.product, tabMode: 1, parentValue: categories.name});
        }
        items.push(...getExtras(categories.extras, 1, categories.name))
    }

    items.push(...getExtras((parentMenu && parentMenu.extras) || [], 0))

    return {
        data: items.map(el => {
            const override = overriderProduct.find(f => f.object_id === el.id && f.name === "enabled");
            return {...el, enabled: override ? override.enabled : el.enabled}
        })
    }
}


const getExtras = (extras, startFrom = 0, nameValues = '') => {
    const items = [];
    for(let extra of extras) {
        let parentValue = nameValues || '';
        items.push({...extra, type: EXTRA_TYPES.extra, tabMode: startFrom, parentValue});
        parentValue = parentValue ? `${parentValue}, ${extra.name}` : extra.name;

        for(let option of extra.options) {
            const parentValueOption = `${parentValue}, ${option.name}`;
            items.push({...option, type: EXTRA_TYPES.option, tabMode: startFrom + 1, parentValue});

            for(let suboption of option.suboptions) {
                items.push({...suboption, type: EXTRA_TYPES.suboption, tabMode: startFrom + 2, parentValue: parentValueOption});
            }
        }
    }

    return items;
}
