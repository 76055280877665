import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import User from "../../../services/user";
import { getActiveUsers, getMapRegionsFiltered } from "../../../services/filter";

import Map from "../../components/Map";

const mapStateToProps = state => {
  return {
    restaurant: state.restaurant.data,
    location: state.restaurant.data.location,
    regions: getMapRegionsFiltered((state.restaurant.data.configuration || {}).regions, state.restaurant.data.selectedControlCenter),
    groups: state.order.data.groups || [],
    users: getActiveUsers(state.userModel.data, state.order.data.orders, state.restaurant.data.selectedControlCenter),
    orders: state.order.data.orders || [],
    depots: state.restaurant.data.depots || [],
    isHideDeliveryRegions: User.instance.getCurrentUserConfigurationProp("isHideDeliveryRegions"),
    isShowTraffic: User.instance.getCurrentUserConfigurationProp("isShowTraffic"),
    orderShakeId: state.marker.orderShakeId,
    language: User.instance.getCurrentUserInfo() && User.instance.getCurrentUserInfo().configuration.language,
    businessPolygones: ((state.restaurant.data.businessMenu || {}).delivery_zones) || [],
    alwaysShowPolygons: state.portalMap.alwaysShowPolygons,
  };
};

export default connect(mapStateToProps)(withTranslation()(Map));
