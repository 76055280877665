import React from 'react';
import "./style.scss";
import { useSelector } from "react-redux";
import IconButton from "../IconButton";

export const CountUnreadMessages = () => {
  const { unreadMessages, dialogs } = useSelector(state => state.supportChat);

  return (
    <div className="chatCounter">
      {unreadMessages ? (
        <div className={"countUnreadMessages"}>
          <IconButton isIcon iconName={"chat"} />
          <p>{unreadMessages}</p>
        </div>
      ) : null}
      {dialogs &&
        dialogs.length ? (
          <div className={"countUnreadMessages"}>
            <IconButton isIcon iconName={"unassignedChat"} />
            <p>{dialogs.length}</p>
          </div>
        ) : null}
    </div>
  );
};
