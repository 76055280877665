export const SET_MENU_TEMPLATES = "SET_MENU_TEMPLATES";
export const DELETE_MENU_TEMPLATE = "DELETE_MENU_TEMPLATES";
export const ADD_MENU_TEMPLATE = "ADD_MENU_TEMPLATE";
export const UPDATE_MENU_TEMPLATE = "UPDATE_MENU_TEMPLATE";
export const SET_GLOBAL_MENU_TEMPLATES = "SET_GLOBAL_MENU_TEMPLATES";

export const setMenuTemplatesAC = data => ({
    type: SET_MENU_TEMPLATES,
    payload: data
});

export const deleteMenuTemplateAC = id => ({
    type: DELETE_MENU_TEMPLATE,
    payload: id
});

export const addMenuTemplateAC = template => ({
    type: ADD_MENU_TEMPLATE,
    payload: template
});

export const updateMenuTemplateAC = newTemplate => ({
    type: UPDATE_MENU_TEMPLATE,
    payload: newTemplate
});

export const setGlobalMenuTemplates = data => ({
    type: SET_GLOBAL_MENU_TEMPLATES,
    payload: data
});
