import React from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { isNullOrWhitespace } from "../../../utils/objects-util";
import { DebounceInput } from "react-debounce-input";

const NumberInput = ({
    t,
    value,
    className,
    isReadonly,
    debounce,
    minlength, maxLength,
    min, minError,
    max, maxError,
    onChange
}) => {
    const handleOnChange = e => {
        const targetValue = e.target.value.toString() ?? '';
        const isChecked = !value && !targetValue.includes(".") && !targetValue.includes(",");
        const newValue = isChecked ? parseInt(targetValue) : parseFloat(targetValue || 0);

        onChange && onChange(newValue);

        if(isChecked) {
            e.target.value = newValue;
        }
    }

    const handleOnBlur = e => {
        if(Number.isInteger(min)) {
            if(isNullOrWhitespace(value.toString())) {
                toast.error(minError || `${t("basic.error.NUMBER_MIN_VALUE")}: ${min}`);
                onChange && onChange(min);
            } else if(value < min) {
                toast.error(minError || `${t("basic.error.NUMBER_MIN_VALUE")}: ${min}`);
                onChange && onChange(min);
            }
        }

        if(Number.isInteger(max) && isNullOrWhitespace(max.toString())) {
            if(isNullOrWhitespace(value.toString())) {
                toast.error(maxError || `${t("basic.error.NUMBER_MAX_VALUE")}: ${max}`);
                onChange && onChange(max);
            } else if(value > max) {
                toast.error(minError || `${t("basic.error.NUMBER_MAX_VALUE")}: ${max}`);
            }
        }
    }

    return (
        <DebounceInput
          debounceTimeout={debounce || 0}
          type="number"
          step="any"
          className={classNames(className || "")}
          value={value || 0}
          readOnly={isReadonly}
          minLength={minlength}
          maxLength={maxLength}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onWheel={(e) => e.target.blur()}
        />
    )
}

export default withTranslation()(NumberInput);
