import SectionModel from '../../models/sectionModel';
import {
    CONTROL_TYPE,
    CROP_MODE,
    getMarketplaceLanguages,
    getMarketplaceMessageTypes,
    getMarketplaceSearchTypes,
    MARKETPLACE_MESSAGE_TYPE,
    MARKETPLACE_SEARCH_TYPES,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
    TYPE_SECTION_MESSAGE_MODEL,
    TYPE_SWITCH_CONTROL_COLOR,
} from '../../utils/enums';
import {MARKETPLACE_MESSAGE, MESSAGE_GALLERY} from '../../store/reducers/restaurant/restaurant-actions';
import {createControlModel} from '../controlModelFactory';
import FilterOptionsModel from '../../models/filterOptionsModel';
import {getInitModel} from '../initModels';
import {createBreadcrumbModel} from '../createBreadcrumbModel';
import {getMessageGalleryNameById, getMessageNameById, isMobileWidth} from '../restaurant';
import {getLabelByLanguage, getMarketplaceMessageGalleryFilter, sortByPropNumber, sortItemsByProp} from '../filter';
import User from "../user";
import {
    GET_PRODUCTS_BY_ID_BUSINESS,
    MARKETPLACE_MESSAGE_GALLERY,
    MARKETPLACE_MESSAGE_GALLERY_REORDER,
    MARKETPLACE_MESSAGE_GET,
    PUSH_MESSAGE
} from "../../store/reducers/order/order-actions";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import ItemLogo from "../../app/components/ItemLogo";
import React from "react";
import i18n from 'i18next';

export function createMessageSettingsEditSectionModel({...args}) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.marketplaceMessage.BANNER_SETTINGS',
                accessor: 'banner',
                settingsAaccessor: 'banner_settings',
                type: CONTROL_TYPE.imageEdit,
                isEditable: true,
            },
            {
                isFillContent: true,
                title: 'settings.marketplaceMessage.MESSAGE',
                accessor: 'message',
                showExtraEdit: true,
                type: CONTROL_TYPE.richText,
                isEditable: true,
                isShow: data =>
                    data.type === MARKETPLACE_MESSAGE_TYPE.serviceShutdown ||
                    data.type === MARKETPLACE_MESSAGE_TYPE.generalWarning ||
                    data.type === MARKETPLACE_MESSAGE_TYPE.article,
            },
            {
                title: 'settings.marketplaceMessage.TITLE',
                accessor: 'title',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.marketplaceMessage.LABEL',
                accessor: 'label',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.marketplaceMessage.SECONDARY_TITLE',
                accessor: 'secondary_title',
                type: CONTROL_TYPE.text,
                isEditable: true,
            },
            {
                title: 'settings.marketplaceMessage.TAG',
                accessor: 'tag',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.marketplaceMessage.ACTION_BUTTON_TITLE',
                accessor: 'action_button_title',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.marketplaceMessage.TYPE',
                accessor: 'type',
                connect: () => ({
                    values: getMarketplaceMessageTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.marketplaceMessage.LANGUAGE',
                accessor: 'language',
                connect: () => ({
                    values: getMarketplaceLanguages(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.marketplaceMessage.ENABLED',
                accessor: 'enabled',
                width: '200px',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
                isShow: data => data.type !== MARKETPLACE_MESSAGE_TYPE.serviceShutdown,
            },
            {
                title: 'settings.marketplaceMessage.BUSINESS_LIST_ID',
                accessor: 'list_id',
                isEditable: true,
                connect: state => {
                    const language =
                        User.instance.getCurrentUserInfo() && User.instance.getCurrentUserInfo().configuration.language;
                    return {
                        values: sortItemsByProp(
                            ((state && state.restaurant.data && state.restaurant.data.listPromotion) || [])
                                .filter(item => item.list_type === MARKETPLACE_SEARCH_TYPES.none)
                                .map(item => ({
                                    key: item.id,
                                    value: getLabelByLanguage(language, item.name_translations) || item.label || '',
                                })),
                            'value'
                        ),
                    };
                },
                isShow: data => data.type === MARKETPLACE_MESSAGE_TYPE.customList,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.marketplaceMessage.ENABLED',
                accessor: 'enabled',
                width: '200px',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                typeColor: TYPE_SWITCH_CONTROL_COLOR.red,
                isInline: true,
                isShow: data => data.type === MARKETPLACE_MESSAGE_TYPE.serviceShutdown,
            },
            {
                title: 'settings.marketplaceMessage.LIST_TYPE',
                accessor: 'list_type',
                values: getMarketplaceSearchTypes(),
                isEditable: true,
                type: CONTROL_TYPE.select,
                isShow: data => data.type === MARKETPLACE_MESSAGE_TYPE.listPromotion
            },
            {
                title: 'settings.marketplaceMessage.PRODUCT_ID',
                accessor: 'product_id',
                connect: (state) => {
                    state.localValue.product_id = null;
                    return {
                        values: (state.marketplace.data.products || []).map(prod => ({ key: prod.id, value: prod.name,  })),
                    }
                },
                isEditable: true,
                type: CONTROL_TYPE.select,
                isShow: data => data.type === MARKETPLACE_MESSAGE_TYPE.bussinesPromotion,
            },
            {
                title: 'settings.marketplaceMessage.BUSINESS_ID',
                accessor: 'business_id',
                isEditable: true,
                action: GET_PRODUCTS_BY_ID_BUSINESS,
                connect: state => {
                    const restaurant = (state && state.restaurant && state.restaurant.data) || {};
                    const depots = restaurant.depots || [];
                    const giftCard = restaurant.giftCard &&
                        restaurant.giftCard.businessMenu && {
                            businessId: restaurant.giftCard.businessMenu.id,
                            name: restaurant.giftCard.businessMenu.name,
                        };

                    return {
                        values: sortItemsByProp(
                            (giftCard ? depots.concat([giftCard]) : depots)
                                .filter(f => f.businessId)
                                .map(m => ({ key: m.businessId, value: m.name })),
                            'value'
                        ),
                    };
                },
                isShow: data =>
                    data.type === MARKETPLACE_MESSAGE_TYPE.bussinesPromotion ||
                    data.type === MARKETPLACE_MESSAGE_TYPE.referral,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'title',
                accessor: 'id',
                clickAction: PUSH_MESSAGE,
                label: 'settings.marketplaceMessage.PUSH',
                type: CONTROL_TYPE.button,
            },
        ],
        typeAction: args.typeAction,
        actionLoad: MARKETPLACE_MESSAGE_GET,
        disableGlobalKeyDown: true,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.marketplace,
        typeModel: TYPE_SECTION_MESSAGE_MODEL.messageInfo,
        actionName: MARKETPLACE_MESSAGE,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                label: 'settings.marketplaceMessage.MARKETPLACE',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const {id} = getHistoryLocationParams(window.location.search);
                    return getMessageNameById(id);
                },
            })
        ],
        saveControlsConfig: {
            save: {
                action: (item, history, newItem)=> {
                    if(args.typeAction === TYPE_ACTION.new) {
                        history.push(TYPE_SECTION_MESSAGE_MODEL.messageInfo + '?id=' + newItem.id);
                    }
                }
            }
        },
        stayAfterSave: true,
        getLabel: () => {
            return args.typeAction === TYPE_ACTION.edit
                ? i18n.t('settings.marketplaceMessage.INFO')
                : i18n.t('settings.marketplaceMessage.NEW_MARKETPLACE');
        },
        mappingLambda: state => state.marketplace.data.message || [],
        initNewItemModel: item => getInitModel(MODEL_TYPE.marketplace, item),
    });
}

export function createMessageSettingsGalleySectionModel() {
    const {id} = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        filterOptions: createMessageSettingsSectionFilter(),
        columns: [
            {
                title: '',
                component: function(item) {
                    return (
                        <ItemLogo value={item.file} settings={{ width: 70, height: 45, crop_mode: CROP_MODE.pad }} />
                    );
                },
                width: '100px',
            },
            {
                accessor: 'title',
                title: 'settings.messageSettings.TITLE',
                type: CONTROL_TYPE.text,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MESSAGE_MODEL.messageGalleryEdit,
                iconName: 'editItem',
            },
        ],
        showDeleteButton: true,
        parentUrl: TYPE_SECTION_MESSAGE_MODEL.messageInfo,
        actionName: MESSAGE_GALLERY,
        typeModel: TYPE_SECTION_MESSAGE_MODEL.messageGallery,
        tableMinWidth: 600,
        dragActionName: MARKETPLACE_MESSAGE_GALLERY_REORDER,
        actionLoad: MARKETPLACE_MESSAGE_GET,
        isForceUpdate: true,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MESSAGE_MODEL.messageGalleryNew + '?parentId=' + id,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.APP_BUILDER',
            }),
            createBreadcrumbModel({
                label: 'settings.marketplaceMessage.MARKETPLACE',
            }),
        ],
        getLabel: () => {
            const {id} = getHistoryLocationParams(window.location.search);
            return getMessageNameById(id);
        },
        sortByFunc: items => sortByPropNumber(items, 'order', false),
        mappingLambda: state => {
            const {id} = getHistoryLocationParams(window.location.search);
            const messages = state.marketplace.data.message || [];
            const message = messages.find(m => m.id === id);
            return (message && message.gallery) || [];
        },
    })
}

export function createMessageSettingsGalleyEditSectionModel(args) {
    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'file',
                settingsAaccessor: 'file_settings',
                type: CONTROL_TYPE.imageEdit,
                isEditable: true,
            },
            {
                title: 'settings.messageSettings.DESCRIPTION',
                accessor: 'description',
                type: CONTROL_TYPE.richText,
                isEditable: true,
            },
            {
                title: 'settings.messageSettings.TITLE',
                accessor: 'title',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },

        ],
        typeAction: args.typeAction,
        actionLoad: MARKETPLACE_MESSAGE_GET,
        disableGlobalKeyDown: true,
        parentUrl: TYPE_SECTION_MESSAGE_MODEL.messageGallery,
        typeModel: args.typeAction === TYPE_ACTION.edit ? TYPE_SECTION_MESSAGE_MODEL.messageGalleryEdit : TYPE_SECTION_MESSAGE_MODEL.messageGalleryNew,
        actionName: MARKETPLACE_MESSAGE_GALLERY,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.APP_BUILDER',
            }),
            createBreadcrumbModel({
                label: 'settings.marketplaceMessage.MARKETPLACE',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId } = getHistoryLocationParams(window.location.search);
                    return getMessageNameById(parentId);
                }
            })
        ],
        getLabel: () => {
            const {id, parentId} = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit
              ? getMessageGalleryNameById(parentId, id)
              : i18n.t('settings.marketplaceMessage.NEW_MESSAGE');
        },
        mappingLambda: state => {
            const {parentId} = getHistoryLocationParams(window.location.search);
            const messages = state.marketplace.data.message || [];
            const message = messages.find(m => m.id === parentId);
            return (message && message.gallery) || [];
        },
        initNewItemModel: item => getInitModel(MODEL_TYPE.messageGallery, item),
    });
}


function createMessageSettingsSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(gallery) {
        return getMarketplaceMessageGalleryFilter(this.selected, gallery);
    });

    return filterOptionsModel;
}
