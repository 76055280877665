import React from "react";
import classNames from "classnames";
import "./index.scss";

import imgPhone from "../../../assets/images/images/Phone.png";
import imgAddress from "../../../assets/images/images/Address-grey.png";
import { TYPE_MARKER } from "../../../utils/enums";

const MapInfoBox = ({ content, markerType }) => {
  const items = content && Object.keys(content);
  const imageItems = { phone: imgPhone, address: imgAddress };
  const markersMargin = [TYPE_MARKER.order];
  const marginBottom = markersMargin.includes(markerType) ? "40px" : "25px";

  return (
    <div className={classNames("mapInfoBox")} style={{ marginBottom }}>
      {(items || []).map(key => key && (
        <div key={key} className="item">
          {key === "title" && (<div className="title">{content[key]}</div>)}
          {imageItems[key] && content[key] && (
            <div><img src={imageItems[key]} alt={key} /> {content[key]}</div>
          )}
          {!imageItems[key] && key !== "title" && <div className="overflowHiddenText">{content[key] || ""}</div>}
        </div>
      ))}
    </div>
  );
}

export default MapInfoBox;
