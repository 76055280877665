import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";

export function addonServerModel(addon) {
  const addonModel = getInitModel(MODEL_TYPE.addon);
  return {
    Item: addon.item || addonModel.item,
    Price: addon.price || addonModel.price,
    Quantity: addon.quantity || addonModel.quantity
  };
}
