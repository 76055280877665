import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import {
  actionResetTooltipData,
  actionUpdateTooltipData,
} from '../../../store/reducers/tooltipPortal/tooltipPortal-actions';
import { USER_ROLES } from '../../../utils/enums';
import { isShowControlCenterSearch } from '../../../services/userModel';

const RoleAvatar = ({ tooltipText, unapproved, role, active, onRoleClick, updateTooltipData, resetTooltipData }) => {
  const ref = useRef();
  const isMobile = window.innerWidth <= 768;
  const isSvgIcon = [USER_ROLES.supervisor, USER_ROLES.supportOperator, USER_ROLES.controlCenterManager].includes(role);

  const handleOnClick = () => {
    if (onRoleClick) {
      switch (role) {
        case USER_ROLES.supervisor:
          if (isShowControlCenterSearch()) onRoleClick();
          break;
        default:
          onRoleClick();
          break;
      }
    }
  };

  return (
    <div
      ref={ref}
      className={classNames('roleAvatar')}
      onClick={handleOnClick}
      onMouseEnter={() => !!tooltipText && !isMobile && updateTooltipData({ tooltipText, ref: ref.current })}
      onMouseLeave={() => !!tooltipText && !isMobile && resetTooltipData()}
      onTouchStart={() => !!tooltipText && isMobile && updateTooltipData({ tooltipText, ref: ref.current })}
      onTouchEnd={() => !!tooltipText && isMobile && resetTooltipData()}
    >
      {!isSvgIcon && <div className={classNames('icon', { unapproved: unapproved }, role, { active: active })} />}
      {isSvgIcon && (
        <div className={classNames('svgIcon', { unapproved: unapproved, active: active })}>
          <div className={classNames('icon', role)}></div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateTooltipData: data => dispatch(actionUpdateTooltipData(data)),
    resetTooltipData: () => dispatch(actionResetTooltipData()),
  };
};

export default connect(null, mapDispatchToProps)(RoleAvatar);
