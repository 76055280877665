import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import classNames from "classnames";
import "./index.scss";

import { actionGlobalPortalDataUpdate } from "../../../store/reducers/globalPortalData/globalPortalData-actions";
import { createAction } from "../../../utils/sagaHelper";

const IFrameControlPortal = ({ globalIFrameUrl, globalPortalDataUpdate, action }) => {
    const [popupWindow, setPopupWindow] = useState(null);
    const height = 500;
    const width = 500;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const handleOnClose = useCallback(urlData => {
        globalPortalDataUpdate({ globalIFrameUrl: null });
        setPopupWindow(null);

        if(urlData && urlData.action) {
            action(urlData.action, { ...urlData.actionData, isSecondTime: true });
        }
    }, [action, globalPortalDataUpdate]);

    useEffect(()=> {
        if(globalIFrameUrl && !popupWindow) {
            setPopupWindow(
                window.open(globalIFrameUrl.url, globalIFrameUrl.title, 
                    `resizable=no,location=no,toolbar=no,menubar=no,debug=true,width=${width},height=${height},left=${left},top=${top}`
                )
            );
        }

        const timer = setInterval(function() { 
            if(popupWindow && popupWindow.closed) {
                clearInterval(timer);
                handleOnClose(globalIFrameUrl);
            }
        }, 1000);
    }, [globalIFrameUrl, popupWindow, left, top, handleOnClose]);

    return createPortal(
        <div className={classNames("iFrameControlPortal", { hide: !globalIFrameUrl })}></div>,
        document.getElementById("iFrameControlPortal-container")
    );
}

const mapStateToProps = state => {
    return {
        globalIFrameUrl: state.globalPortalData.globalIFrameUrl
    };
};

const mapDispatchToProps = dispatch => {
    return {
        globalPortalDataUpdate: data=> dispatch(actionGlobalPortalDataUpdate(data)),
        action: (action, data)=> dispatch(createAction(action, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IFrameControlPortal);