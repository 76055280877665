import i18n from '../../i18n';

import SectionModel from '../../models/sectionModel';
import { GENERAL_SETTING, GENERAL_SETTING_GET } from '../../store/reducers/restaurant/restaurant-actions';
import {
  CONTROL_TYPE,
  COURIER_ASSOCIATION_MODE,
  courierAssociationModes,
  MODEL_CONTROL_TYPE,
  newOrderNotificationMode,
  TYPE_SECTION_MODEL,
} from '../../utils/enums';
import { isMobileWidth } from '../restaurant';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { createControlModel } from '../controlModelFactory';

export function createGeneralSettingsSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.settings.numericalSettings.LABEL",
        isEditable: true,
        type: CONTROL_TYPE.sectionTab,
        items: [
          {
            items: [
              [
                {
                  title: "settings.settings.numericalSettings.ALLOW_CITYONLY_ADDRESS",
                  accessor: "allowCityOnlyAddress",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
                {
                  title: "settings.settings.numericalSettings.TAKE_AWAY_SUPPORT",
                  accessor: "takeAwaySupported",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
                {
                  title: "settings.settings.numericalSettings.AUTOMATICALLY_ASSIGN_EXTRA_SIZE_ORDERS",
                  accessor: "useCarVehicleForExtraSizeOrders",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
                {
                  title: "controlCenters.AUTOMATIC_EXTEND_CYCLE",
                  accessor: "automaticExtendCycle",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
              ]
            ],
            isEditable: true,
            isInline: true,
            type: CONTROL_TYPE.switchGroups
          },
          {
            items: [
              [
                {
                  title: "settings.settings.numericalSettings.ASSIST_OUT_OF_REGION",
                  accessor: "assistOutOfRegion",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
                {
                  title: "settings.settings.numericalSettings.HIDE_READINESS_TIME",
                  accessor: "hideReadinessTimeOnOrderCard",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
                {
                  title: "settings.settings.numericalSettings.SHOW_ORDER_PAYMENT_STATUS",
                  accessor: "showOrderPaymentStatus",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                },
                {
                  title: "controlCenters.AUTOMATIC_APPROVE_CYCLE",
                  accessor: "automaticApproveCycle",
                  isEditable: true,
                  isInline: true,
                  type: CONTROL_TYPE.switch
                }
              ]
            ],
            isEditable: true,
            isInline: true,
            type: CONTROL_TYPE.switchGroups
          },
          {
            title: "settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE",
            accessor: "courierAssociationMode",
            connect: () => ({
              values: courierAssociationModes()
            }),
            isEditable: true,
            type: CONTROL_TYPE.select
          },
          {
            title: "settings.settings.numericalSettings.MAX_ORDERS_PER_COURIER_LABEL",
            measure: "settings.settings.numericalSettings.ORDERS_MEASURE",
            accessor: "maxOrdersPerCourier",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.MAX_PICKUPS_COLLECTION_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "maxPickupsCollectionTime",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.MAX_PICKUP_DISTANCE",
            measure: "settings.settings.numericalSettings.DISTANCE_MEASURE_M",
            accessor: "maxPickupDistance",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.PICKUP_LOAD_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "pickupLoadTime",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.ORDER_UNLOAD_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "orderUnloadTime",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.READINESS_HEADS_UP_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "readinessHeadsUpTime",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.READINESS_HEADS_UP_TIME_SCHEDULER_ORDERS",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "readinessHeadsUpTimeForScheduledOrders",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.MAX_BICYCLE_SERVICE_DISTANCE",
			      measure: "settings.settings.numericalSettings.DISTANCE_MEASURE_M",
            accessor: "maxBicycleServiceDistance",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.settings.numericalSettings.POST_REJECT_ORDER_COOL_DOWN_PERIOD",
			      measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "postRejectOrderCoolDownPeriod",
            isEditable: true,
            inlineType: 'inlineOdd',
            type: CONTROL_TYPE.number,
			      isShow: data => !(data.courierAssociationMode === COURIER_ASSOCIATION_MODE.push)
          },
          {
            title: "settings.settings.numericalSettings.NEW_ORDER_NOTIFICATION_PRIORITY_PERIOD",
			      measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "newOrderNotificationPriorityPeriod",
            isEditable: true,
            type: CONTROL_TYPE.number,
			      isShow: data => !(data.courierAssociationMode === COURIER_ASSOCIATION_MODE.push)
          },
          {
            title: "settings.settings.numericalSettings.NEW_ORDER_NOTIFICATION_PRIORITY_RADIUS_IN_METERS",
			      measure: "settings.settings.numericalSettings.DISTANCE_MEASURE_M",
            accessor: "newOrderNotificationPriorityRadiusInMeters",
            isEditable: true,
            type: CONTROL_TYPE.number,
			      isShow: data => !(data.courierAssociationMode === COURIER_ASSOCIATION_MODE.push)
          },
		      {
            title: "settings.settings.numericalSettings.NEW_ORDER_NOTIFICATION_MODE",
            accessor: "newOrderNotificationMode",
            values: newOrderNotificationMode(),
            isEditable: true,
            type: CONTROL_TYPE.select,
			      isShow: data => !(data.courierAssociationMode === COURIER_ASSOCIATION_MODE.push)
          }
        ]
      },
      {
        title: "settings.personalSettings.notificationSettings.LABEL",
        isEditable: true,
        type: CONTROL_TYPE.sectionTab,
        items: [
          {
            title: "settings.personalSettings.notificationSettings.LOST_ALERT_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "lostAlertTime",
            isEditable: true,
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.personalSettings.notificationSettings.STOP_ALERT_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "stopAlertTime",
            isEditable: true,
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.personalSettings.notificationSettings.ORDER_IS_LATE_WARNING",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "orderIsLateWarningTime",
            isEditable: true,
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.personalSettings.notificationSettings.ORDER_IS_LATE_ALERT",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "orderIsLateAlertTime",
            isEditable: true,
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.personalSettings.notificationSettings.COURIER_IS_READY_ALERT",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "courierReadyTimeDelay",
            isEditable: true,
            type: CONTROL_TYPE.number
          },
          {
            title: "settings.personalSettings.notificationSettings.ORDER_UNASSIGNED_TIME",
            measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            accessor: "orderIsUnassignedTime",
            isEditable: true,
            type: CONTROL_TYPE.number
          }
        ]
      }
    ],
    actionName: GENERAL_SETTING,
    actionLoad: GENERAL_SETTING_GET,
    initNewItemModel: item => item,
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: TYPE_SECTION_MODEL.generalSettings,
    saveControlsConfig: {
      cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
    },
    label: "settings.settings.LABEL",
    breadcrumbs: [
        createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
    ],
    controls: [
        createControlModel({
          type: MODEL_CONTROL_TYPE.back,
          isShow: isMobileWidth,
        })
    ],
    mappingLambda: state => {
      return ({
        id: "generalsettings",
        ...state.restaurant.data.configuration,
        ...state.restaurant.data.notificationConfiguration,
        ...state.restaurant.data.restaurantShipmentPriceConfiguration,
        ...state.restaurant.data.appShipmentPriceConfiguration
      });
    }
  });
}
