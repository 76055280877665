import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import MenuService from '../../../services/menu';
import User from '../../../services/user';
import { ROLES_MANAGER_TYPE, TYPE_MENU } from '../../../utils/enums';
import { isSameObjects } from '../../../utils/objects-util';
import { actionMenuPriorityUpdate } from '../../../store/reducers/menu/menu-actions';
import RoleManager from '../../../services/roleManage';
import { toggleNestedMenu } from '../../../store/reducers/menu/menu-actions';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuItems: []
    };

    this.headerMenuRefs = [];
    this.menuParentRef = React.createRef();
    this.handleOnResize = this.handleOnResize.bind(this);
  }

  componentDidMount() {
    this.updateMenuItems();
    window.addEventListener("resize", this.handleOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleOnResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { language } = this.props;

    if (language !== prevProps.language) {
      this.updateMenuItems(false, true);
    }
  }

  handleOnResize = () => {
    const { clientWidth } =
      (this.menuParentRef && this.menuParentRef.current) || {};
    const refsWidth = (this.headerMenuRefs || [])
      .filter(f => f && f.ref)
      .reduce((a, b) => a + b.ref.clientWidth, 0);
    this.updateMenuItems(clientWidth < refsWidth);
  };

  handleOnClick = path => {
    const { history, toggleNestedMenu } = this.props;
    if(this.props.toggleMobileMenu) {
      this.props.toggleMobileMenu(false);
    }
    toggleNestedMenu(false)
    history.push(path);
  };

  updateMenuItems = (isSort, isRecreate) => {
    const { menuItemState, rootState } = this.props;
    const { menuItems } = this.state;
    const newMenuItems = MenuService.instance.getMenu(
      TYPE_MENU.header,
      isSort,
      isRecreate
    );
    const filteredMenuItems = newMenuItems
      .filter(menu=> !menu.isShow || menu.isShow({ ...rootState, ...menuItemState }));

    if (isSameObjects(menuItems, filteredMenuItems)) return
    this.setState({ menuItems: filteredMenuItems });
  };


  render() {
    const { menuItems } = this.state;
    const { t, rootState, location, hideMenu, menuItemState } = this.props;
    this.headerMenuRefs = [];
    return (
      <>
        <menu className={classNames("headerMenu")} ref={this.menuParentRef}>
          {!hideMenu &&
            menuItems
              .filter(
                menu =>
                  RoleManager.instance.isAuthorized(
                    ROLES_MANAGER_TYPE.menu,
                    menu.pathname
                  ) &&
                  (!menu.isShow || menu.isShow({ ...rootState, ...menuItemState }))
              )
              .map(headerMenu => (
                <li
                  key={`headerMenu-${headerMenu.title}`}
                  className={classNames(`menuItem`, {
                    active: headerMenu.active(location)
                  })}
                  onClick={() => this.handleOnClick(headerMenu.pathname)}
                >
                  <div className={classNames("menuItemTitle")}>
                    {t(headerMenu.title)}
                    {headerMenu.badgeCount && headerMenu.badgeCount() && (
                        <div className={'badge'}>
                          {headerMenu.badgeCount()}
                        </div>
                    )}
                  </div>
                  {!!headerMenu.isWithIcon &&
                    !!headerMenu.isWithIcon(rootState) && (
                      <div className={classNames("menuItemIcon", {relativeIcon: headerMenu.isIconRelative})}>
                        {headerMenu.isWithIcon(rootState)}
                      </div>
                    )}
                </li>
              ))}
        </menu>
      </>
    );
  }
}

const mapStateToProps = state => ({
  menuItemState: {
    businessMenu: state.restaurant.data.businessMenu,
    consumerOrderMenu: state.restaurant.data.consumerOrderMenu,
    paymentsConfiguration: state.restaurant.data.paymentsConfiguration
  },
  rootState: state,
  language:
    User.instance.getCurrentUserInfo() &&
    User.instance.getCurrentUserInfo().configuration.language
});

const mapDispatchToProps = dispatch => {
  return {
    menuPriorityUpdate: data => dispatch(actionMenuPriorityUpdate(data)),
    toggleNestedMenu: data => dispatch(toggleNestedMenu(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(Menu))
);
