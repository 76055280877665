import {DELIVERY_TYPE, ORDER_TYPE} from "../utils/enums";

export function createOrderServerModel(order) {
    return {
        paymethod_id: order.paymethod_id,
        payment_data: order.id || order.token,
        comment: order.comment,
        cutlery: order.cutlery,
        business_id: order.businessId,
        delivery_type: order.type === ORDER_TYPE.delivery ? DELIVERY_TYPE.delivery : DELIVERY_TYPE.takeAway,
        delivery_datetime: order.targetTime,
        delivery_strategy: order.deliveryStrategy,
        location: order.location ? {
                lng: order.location.longitude || order.location.lng,
                lat: order.location.latitude || order.location.lat
            } : null,
        city: order.address.city,
        address: order.address.street,
        address_notes: order.address.address_notes,
        flat: order.address.flat,
        storey: order.address.storey,
        final_price: order.final_price,
        products: order.products.map(el => el.product),
        coupon: order.coupon,
        requested_bonus: order.requested_bonus,
        driver_tip: order.driver_tip,
        provider: order.paymentsConfiguration.provider,
        full_name: order.userName,
        phone: order.consumerPhone,
    }
}