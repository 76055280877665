import React from "react";
import { withTranslation } from "react-i18next";
import "./index.scss";
import i18n from '../../../i18n.js';
import { createAction } from '../../../utils/sagaHelper.js';
import IconButton from '../IconButton/IconButton.jsx';
import { useDispatch } from 'react-redux';

const RefreshDataIcon = ({ item, refreshAction, tooltipText }) => {
  const dispatch = useDispatch()

  return (
    <IconButton
      tooltipText={tooltipText || i18n.t('basic.REFRESH_DATA')}
      isIcon={true}
      iconName={'refresh'}
      onClick={() => dispatch(createAction(refreshAction, item))}
    />
  );
};

export default withTranslation()(RefreshDataIcon);
