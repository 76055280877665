
import SectionModel from '../../models/sectionModel';
import { CONTROL_TYPE, MARKETPLACE_SETTINGS_MODEL, MODEL_CONTROL_TYPE } from '../../utils/enums';
import { REFERRAL_SETTINGS, REFERRAL_SETTINGS_GET } from '../../store/reducers/restaurant/restaurant-actions';
import { createControlModel } from '../controlModelFactory';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { isMobileWidth } from '../restaurant';

export function createReferralSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.referral.REFERRAL_BONUS',
                accessor: 'referralBonus',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.referral.REFERRED_BONUS',
                accessor: 'referredBonus',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.referral.ENABLED_LABEL',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
        ],
        actionName: REFERRAL_SETTINGS,
        actionLoad: REFERRAL_SETTINGS_GET,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: MARKETPLACE_SETTINGS_MODEL.referral,
        saveControlsConfig: {
            cancel: { title: 'basic.RESET_BUTTON', action: () => {}, isInit: true },
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.marketplaceMessage.LOYALTY",
            })
        ],
        label: 'settings.referral.MENU_LABEL',
        mappingLambda: state => {
            return state.restaurant.data && state.restaurant.data.referral;
        },
    });
}
