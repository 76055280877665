import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import "./index.scss";

export const ModalPopup = ({
  isOpen,
  onClose,
  content,
  width,
  height,
  isOverModal,
  containerModalClass
}) => (
  <Modal
    isOpen={isOpen || false}
    onRequestClose={onClose}
    className="containerModal"
    overlayClassName={classNames("containerModalOverlay", {
      overModal: isOverModal
    }, containerModalClass || "")}
    style={{
      content: {
        width: width || "450px",
        height: height || "auto"
      }
    }}
  >
    {content}
  </Modal>
);
