import React, { useContext } from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";

import { PortalMapContext } from "../../../context/portalMap-context";

import Map from "../../containers/MapContainer";

const PortalMap = props => {
  const { zoom, markers, center, region, circle, isHidePolygon, isReadonly, renderPolygonsFromBusiness, businessPolygones, boundsByRegion, boundsByCircle, alwaysShowPolygons } = props;
  const portalMapContext = useContext(PortalMapContext);
  return createPortal(
    <Map childRef={ref => (portalMapContext.mapRef = ref)}
      markers={markers}
      center={center}
      region={region}
      circle={circle}
      isHidePolygon={isHidePolygon}
      isReadonly={isReadonly}
      renderPolygonsFromBusiness={renderPolygonsFromBusiness}
      alwaysShowPolygons={alwaysShowPolygons}
      businessPolygones={businessPolygones}
      zoom={zoom}
      boundsByRegion={boundsByRegion}
      boundsByCircle={boundsByCircle}
    />,
    document.getElementById("map-container")
  );
};

const mapStateToProps = state => ({
  markers: state.portalMap.markers || [],
  center: state.portalMap.center,
  region: state.portalMap.region,
  circle: state.portalMap.circle,
  isHidePolygon: state.portalMap.isHidePolygon,
  isReadonly: state.portalMap.isReadonly,
  renderPolygonsFromBusiness: state.portalMap.renderPolygonsFromBusiness,
  alwaysShowPolygons: state.portalMap.alwaysShowPolygons,
  zoom: state.portalMap.zoom,
  boundsByRegion: state.portalMap.boundsByRegion,
  boundsByCircle: state.portalMap.boundsByCircle,
});

export default connect(mapStateToProps)(PortalMap);
