export const ORDEREDIT_UPDATE = "ORDEREDIT_UPDATE";
export const ORDEREDIT_ORIGINAL_ORDER_UPDATE =
  "ORDEREDIT_ORIGINAL_ORDER_UPDATE";
export const ORDER_CYCLE_REORDER = "ORDER_CYCLE_REORDER";
export const ORDEREDIT_SET_NEW_ORDER = "ORDEREDIT_SET_NEW_ORDER";
export const ORDEREDIT_SET_ORDER = "ORDEREDIT_SET_ORDER";
export const DND_SET_DATA = "DND_SET_DATA";
export const DND_CLEAR_DATA = "DND_CLEAR_DATA";
export const ORDEREDIT_SET_PAYMENT_INTENT = "ORDEREDIT_SET_PAYMENT_INTENT";
export const ORDEREDIT_SET_CANCEL_OFFER_IDS = "ORDEREDIT_SET_CANCEL_OFFER_IDS";
export const CHANGE_RECALCULATED_DISCOUNTS = "CHANGE_RECALCULATED_DISCOUNTS";
export const CHANGE_ORDER_COUPON = "CHANGE_ORDER_COUPON";
export const ORDEREDIT_ADD_PRODUCTS = "ORDEREDIT_ADD_PRODUCTS";
export const ORDEREDIT_SET_ORDER_SUMMARY = "ORDEREDIT_SET_ORDER_SUMMARY";
export const ORDEREDIT_GET_SUMMARY_ORDER = "ORDEREDIT_GET_SUMMARY_ORDER";
export const CHECK_MENU_CREATE_ORDER = "CHECK_MENU_CREATE_ORDER";
export const RESET_ORDEREDIT_DATA = "RESET_ORDEREDIT_DATA";

export function actionOrderEditUpdate(data) {
  return {
    type: ORDEREDIT_UPDATE,
    data: data
  };
}

export function actionOriginalOrderUpdate(data) {
  return {
    type: ORDEREDIT_ORIGINAL_ORDER_UPDATE,
    data: data
  };
}

export function actionOrderEditSetNewOrder(data) {
  return {
    type: ORDEREDIT_SET_NEW_ORDER,
    data: data
  };
}

export function actionReorderCycle(data) {
  return {
    type: ORDER_CYCLE_REORDER,
    data: data
  };
}

export function actionOrderEditSetOrder(data) {
  return {
    type: ORDEREDIT_SET_ORDER,
    data: data
  };
}

export function actionDndSetData(data) {
  return {
    type: DND_SET_DATA,
    data: data
  };
}

export function actionDndClearData() {
  return {
    type: DND_CLEAR_DATA
  };
}

export function actionOrderEditAddProduct(data) {
  return {
    type: ORDEREDIT_ADD_PRODUCTS,
    data: data
  };
}

export function setOrderEditSummary(data) {
  return {
    type: ORDEREDIT_SET_ORDER_SUMMARY,
    data,
  }
}

export function setPaymentIntent(data) {
  return {
    type: ORDEREDIT_SET_PAYMENT_INTENT,
    data,
  }
}