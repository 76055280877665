import { MODEL_TYPE } from "../utils/enums";

export function refundModel(data) {
    const item = data || {};
    return {
        id: item.id,
        partial: item.partial || false,
        amount: item.amount || 0,
        notes: item.notes || "",
        payment_id: item.paymentId,
        modelType: MODEL_TYPE.refundModel
    };
}