import { GLOBAL_PORTAL_DATA_UPDATE } from "./globalPortalData-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    globalIFrameUrl: null
};

export default function globalPortalData(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA:
            return initState;
        case GLOBAL_PORTAL_DATA_UPDATE:
            return { ...state, ...(action.data || {}) } 
        default:
            return state;
    }
}