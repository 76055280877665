import {MODEL_TYPE} from "../utils/enums.js";

export function reconciliationModel(reconciliations) {
    const data = reconciliations || {};
    const region = {
        modelType: MODEL_TYPE.reconciliations
    };

    return data ? { ...region, ...data } : region;
}
