import {MODEL_TYPE} from '../utils/enums';

import {getOrderModel, orderModel} from '../models/orderModel';
import {routeEstimationModel} from '../models/routeEstimationModel';
import {addressModel} from '../models/addressModel';
import {paymentsModel} from '../models/paymentsModel';
import {contentModel} from '../models/contentModel';
import {addonModel} from '../models/addonModel';
import {locationModel} from '../models/location';
import {contentServerModel} from '../models/contentServerModel';
import {assistanceServerModel} from '../models/assistanceServerModel';
import {addonServerModel} from '../models/addonServerModel';
import {addressServerModel} from '../models/addressServerModel';
import {locationServerModel} from '../models/locationServerModel';
import {userModel, userServerModel} from '../models/userModel';
import {credentialsServerModel} from '../models/credentialsServerModel';
import {depotModel} from '../models/depotModel';
import {depotServerModel} from '../models/depotServerModel';
import {depotUserModel} from '../models/depotUserModel';
import {depotDeliveryPriceModel} from '../models/depotDeliveryPriceModel';
import {regionDeliveryPriceModel} from '../models/regionDeliveryPriceModel';
import {travelDataModel} from '../models/travelDataModel';
import {namedPlaces} from '../models/namedPlaces';
import {visualSettingsServerModel} from '../models/visualSettingsServerModel';
import {regionModel} from '../models/regionModel';
import {restaurantRegionServerModel} from '../models/restaurantRegionServerModel';
import {namedPlacesServerModel} from '../models/namedPlacesServerModel';
import {shiftModel} from '../models/shiftModel';
import {shiftServerModel} from '../models/shiftServerModel';
import {controlCenterActivateServerModel} from '../models/controlCenterServerModel';
import {controlCenterDeactivateServerModel} from '../models/controlCenterDeactivateServerModel';
import {menuDeliveryZoneModel} from '../models/menuDeliveryZoneModel';
import {offerModel} from '../models/offerModel';
import {businessInfoModel, businessInfoServerModel} from '../models/businessInfoModel';
import {categoryModel, categoryModelForOverride, categoryModelOverride} from '../models/categoryModel';
import {categoryServerModel} from '../models/categoryServerModel';
import {
  categoryProductModel,
  categoryProductModelForOverride,
  createProductOverrideModel,
} from '../models/categoryProductModel';
import {categoryProductServerModel} from '../models/categoryProductServerModel';
import {optionModelOverride, optionModelWithOverrides, productOptionModel} from '../models/productOptionModel';
import {productOptionServerModel} from '../models/productOptionServerModel';
import {extraModel, extraModelOverride, extraModelWithOverrides} from '../models/extraModel';
import {extraServerModel} from '../models/extraServerModel';
import {
  extraSuboptionModel,
  suboptionsModelWithOverrides,
  suboptionsOverrideModel,
} from '../models/extraSuboptionModel';
import {scheduleModel} from '../models/scheduleModel';
import {extraSuboptionServerModel} from '../models/extraSuboptionServerModel';
import {reshipmentOrder} from '../models/reshipmentOrder';
import {adminOrderProductServerModel} from '../models/adminOrderProductServerModel';
import {refundModel} from '../models/refundModel';
import {refundServerModel} from '../models/refundServerModel';
import {depotDeliveryPriceServerModel} from '../models/depotPriceServerModel';
import {courierDeliveryFeesModel, courierDeliveryFeesServerModel} from '../models/courierDeliveryFees';
import {listPromotionModel, listPromotionServerModel} from '../models/listPromotion';
import {serviceFeeModel, serviceFeeServerModel} from '../models/serviceFee';
import {driverTipModel} from '../models/driverTipModel';
import {marketplacePagesModel} from '../models/marketplacePagesModel';
import {depotIntegrationModel} from '../models/depotIntegrationModel';
import {menuTemplateModel} from '../models/menuTemplateModel';
import {driverInfoModel} from '../models/driverInfoModel';
import {priceInfoModel} from '../models/priceInfoModel';
import {externalCycleModel} from '../models/externalCycleModel';
import {depotsMenuCopyModel} from '../models/depotsMenuCopyModel';
import {marketplaceModel} from '../models/marketplaceModel';
import {parentMenuModel} from '../models/parentMenuModel';
import {reviewExtraModel} from '../models/reviewExtraModel';
import {billingAddressModel} from '../models/billingAddressModel';
import {galleryModel} from '../models/businessGalleryModel';
import {providerModel} from '../models/providerModel';
import {marketplaceSettingsServerModel} from '../models/marketplaceSettingsServerModel';
import {controlCenterEditServerModel} from '../models/controlCenterEditServerModel';
import {serviceCityServerModel} from '../models/serviceCityServerModel';
import {operationTimingModel} from '../models/operationTimingModel';
import {currentShiftServerModel} from '../models/currentShiftServerModel';
import {currentShift} from '../models/currentShiftModel';
import {currentShiftDepotServerModel} from '../models/currentShiftDepotServerModel';
import {getCourierControlSettingsModel} from '../models/getCourierControlSettingsModel';
import {getCourierInterfaceSettingsModel} from '../models/getCourierInterfaceSettingsModel';
import {messageGalleryModel, messageGalleryServerModel} from '../models/messageGalleryModel';
import {editCustomerOrderModel} from '../models/editCustomerOrderModel.js';
import {marketplaceCampaignModel} from '../models/marketplaceCampaignModel.js';
import {reconciliationModel} from '../models/reconciliationModel.js';
import {
  reconciliationsPeriodTemplateModel,
  reconciliationsSettingsTemplateModel
} from '../models/reconciliationsSettingsTemplateModel.js';
import {getCourierDisplaySettingsModel} from "../models/getCourierDisplaySettingsModel.js";
import {providersModel} from "../models/providersModel.js";
import {cloudPrintersModel} from "../models/cloudPrintersModel.js";
import {bulkConsumerModel} from "../models/bulkServerModels.js";
import {cardServerModel} from "../models/cardServerModel.js";
import {fleetModel, fleetTelemetryModel} from "../models/fleetModel.js";
import {loyaltyRegisterVoucherModel, loyaltyRegisterVoucherServerModel} from "../models/loyaltyRegisterVoucherModel.js";

export function getInitModel(modelType, data) {
  let model = {};
  let isLoadFromData = false;

  switch (modelType) {
    case MODEL_TYPE.consumerServerModel:
      isLoadFromData = true;
      model = bulkConsumerModel(data);
      break;
    case MODEL_TYPE.loyaltyRegisterVoucher:
      isLoadFromData = true;
      model = loyaltyRegisterVoucherModel(data);
      break;
    case MODEL_TYPE.loyaltyRegisterVoucherServerModel:
      isLoadFromData = true;
      model = loyaltyRegisterVoucherServerModel(data);
      break;
    case MODEL_TYPE.creditCard:
      isLoadFromData = true;
      model = cardServerModel(data);
      break;
    case MODEL_TYPE.editCustomerOrder:
      isLoadFromData = true;
      model = editCustomerOrderModel(data);
      break;
    case MODEL_TYPE.reconciliationsSettingsTemplateModel:
      isLoadFromData = true;
      model = reconciliationsSettingsTemplateModel(data);
      break;
    case MODEL_TYPE.reconciliationsPeriodTemplateModel:
      isLoadFromData = true;
      model = reconciliationsPeriodTemplateModel(data);
      break;
    case MODEL_TYPE.reconciliationsPeriodsTemplateModel:
      isLoadFromData = true;
      model = reconciliationsPeriodTemplateModel(data);
      break;
    case MODEL_TYPE.marketplaceCampaignModel:
      isLoadFromData = true;
      model = marketplaceCampaignModel(data);
      break;
    case MODEL_TYPE.messageGallery:
      isLoadFromData = true;
      model = messageGalleryModel(data);
      break;
    case MODEL_TYPE.messageGalleryServerModel:
      isLoadFromData = true;
      model = messageGalleryServerModel(data);
      break;
    case MODEL_TYPE.operationTiming:
      isLoadFromData = true;
      model = operationTimingModel(data);
      break;
    case MODEL_TYPE.order:
      model = orderModel;
      break;
    case MODEL_TYPE.orderEdit:
      model = getOrderModel(data);
      break;
    case MODEL_TYPE.location:
      isLoadFromData = true;
      model = locationModel(data);
      break;
    case MODEL_TYPE.routeEstimation:
      model = routeEstimationModel;
      break;
    case MODEL_TYPE.address:
      isLoadFromData = true;
      model = addressModel(data);
      break;
    case MODEL_TYPE.payments:
      isLoadFromData = true;
      model = paymentsModel(data);
      break;
    case MODEL_TYPE.content:
      model = contentModel;
      break;
    case MODEL_TYPE.addon:
      model = addonModel;
      break;
    case MODEL_TYPE.deliveryZoneModel:
      isLoadFromData = true;
      model = menuDeliveryZoneModel(data);
      break;
    case MODEL_TYPE.offerModel:
      isLoadFromData = true;
      model = offerModel(data);
      break;
    case MODEL_TYPE.businessInfoModel:
      isLoadFromData = true;
      model = businessInfoModel(data);
      break;
    case MODEL_TYPE.serviceCities:
      isLoadFromData = true;
      model = serviceCityServerModel(data);
      break;
    case MODEL_TYPE.businessInfoServerModel:
      isLoadFromData = true;
      model = businessInfoServerModel(data);
      break;
    case MODEL_TYPE.depotDeliveryPriceModel:
      model = depotDeliveryPriceModel();
      break;
    case MODEL_TYPE.regionDeliveryPriceModel:
      isLoadFromData = true;
      model = regionDeliveryPriceModel(data);
      break;
    case MODEL_TYPE.categoryProductModel:
      isLoadFromData = true;
      model = categoryProductModel(data);
      break;
    case MODEL_TYPE.categoryProductModelForOverride:
      isLoadFromData = true;
      model = categoryProductModelForOverride(data);
      break;
    case MODEL_TYPE.categoryProductModelOverride:
      isLoadFromData = true;
      model = createProductOverrideModel(data);
      break;
    case MODEL_TYPE.productOptionModel:
      isLoadFromData = true;
      model = productOptionModel(data);
      break;
    case MODEL_TYPE.extraModel:
      isLoadFromData = true;
      model = extraModel(data);
      break;
    case MODEL_TYPE.extraModelOverride:
      isLoadFromData = true;
      model = extraModelOverride(data);
      break;
    case MODEL_TYPE.extraModelWithOverrides:
      isLoadFromData = true;
      model = extraModelWithOverrides(data);
      break;
    case MODEL_TYPE.optionModelOverride:
      isLoadFromData = true;
      model = optionModelOverride(data);
      break;
    case MODEL_TYPE.optionModelWithOverrides:
      isLoadFromData = true;
      model = optionModelWithOverrides(data);
      break;
    case MODEL_TYPE.extraSuboptionModel:
      isLoadFromData = true;
      model = extraSuboptionModel(data);
      break;
    case MODEL_TYPE.suboptionsOverride:
      isLoadFromData = true;
      model = suboptionsOverrideModel(data);
      break;
    case MODEL_TYPE.suboptionsModelWithOverrides:
      isLoadFromData = true;
      model = suboptionsModelWithOverrides(data);
      break;
    case MODEL_TYPE.scheduleModel:
      isLoadFromData = true;
      model = scheduleModel(data);
      break;
    case MODEL_TYPE.refundModel:
      isLoadFromData = true;
      model = refundModel(data);
      break;
    case MODEL_TYPE.serviceFeeModel:
      isLoadFromData = true;
      model = serviceFeeModel(data);
      break;
    case MODEL_TYPE.listPromotionModel:
      isLoadFromData = true;
      model = listPromotionModel(data);
      break;
    case MODEL_TYPE.depotIntegrationModel:
      isLoadFromData = true;
      model = depotIntegrationModel(data);
      break;
    case MODEL_TYPE.driverInfoModel:
      isLoadFromData = true;
      model = driverInfoModel(data);
      break;
    case MODEL_TYPE.priceInfoModel:
      isLoadFromData = true;
      model = priceInfoModel(data);
      break;
    case MODEL_TYPE.externalCycleModel:
      isLoadFromData = true;
      model = externalCycleModel(data);
      break;
    case MODEL_TYPE.serviceFeeServerModel:
      isLoadFromData = true;
      model = serviceFeeServerModel(data);
      break;
    case MODEL_TYPE.listPromotionServerModel:
      isLoadFromData = true;
      model = listPromotionServerModel(data);
      break;
    case MODEL_TYPE.refundServerModel:
      isLoadFromData = true;
      model = refundServerModel(data);
      break;
    case MODEL_TYPE.extraSuboptionServerModel:
      isLoadFromData = true;
      model = extraSuboptionServerModel(data);
      break;
    case MODEL_TYPE.extraServerModel:
      isLoadFromData = true;
      model = extraServerModel(data);
      break;
    case MODEL_TYPE.productOptionServerModel:
      isLoadFromData = true;
      model = productOptionServerModel(data);
      break;
    case MODEL_TYPE.categoryProductServerModel:
      isLoadFromData = true;
      model = categoryProductServerModel(data);
      break;
    case MODEL_TYPE.contentServerModel:
      isLoadFromData = true;
      model = contentServerModel(data);
      break;
    case MODEL_TYPE.assistanceServerModel:
      isLoadFromData = true;
      model = assistanceServerModel(data);
      break;
    case MODEL_TYPE.addonServerModel:
      isLoadFromData = true;
      model = addonServerModel(data);
      break;
    case MODEL_TYPE.addressServerModel:
      isLoadFromData = true;
      model = addressServerModel(data);
      break;
    case MODEL_TYPE.adminOrderProductServerModel:
      isLoadFromData = true;
      model = adminOrderProductServerModel(data);
      break;
    case MODEL_TYPE.locationServerModel:
      isLoadFromData = true;
      model = locationServerModel(data);
      break;
    case MODEL_TYPE.restaurantRegionServerModel:
      isLoadFromData = true;
      model = restaurantRegionServerModel(data);
      break;
    case MODEL_TYPE.user:
      isLoadFromData = true;
      model = userModel(data);
      break;
    case MODEL_TYPE.userServerModel:
      isLoadFromData = true;
      model = userServerModel(data);
      break;
    case MODEL_TYPE.credentialsServerModel:
      isLoadFromData = true;
      model = credentialsServerModel(data);
      break;
    case MODEL_TYPE.depot:
      isLoadFromData = true;
      model = depotModel(data);
      break;
    case MODEL_TYPE.depotServerModel:
      isLoadFromData = true;
      model = depotServerModel(data);
      break;
    case MODEL_TYPE.visualSettingsServerModel:
      isLoadFromData = true;
      model = visualSettingsServerModel(data);
      break;
    case MODEL_TYPE.depotUserModel:
      model = depotUserModel;
      break;
    case MODEL_TYPE.travelDataModel:
      model = travelDataModel;
      break;
    case MODEL_TYPE.region:
      isLoadFromData = true;
      model = regionModel(data);
      break;
    case MODEL_TYPE.reshipmentOrder:
      isLoadFromData = true;
      model = reshipmentOrder(data);
      break;
    case MODEL_TYPE.namedPlaces:
      isLoadFromData = true;
      model = namedPlaces(data);
      break;
    case MODEL_TYPE.categoryModel:
      isLoadFromData = true;
      model = categoryModel(data);
      break;
    case MODEL_TYPE.categoryModelOverride:
      isLoadFromData = true;
      model = categoryModelOverride(data);
      break;
    case MODEL_TYPE.categoryModelForOverride:
      isLoadFromData = true;
      model = categoryModelForOverride(data);
      break;
    case MODEL_TYPE.categoryServerModel:
      isLoadFromData = true;
      model = categoryServerModel(data);
      break;
    case MODEL_TYPE.namedPlacesServerModel:
      isLoadFromData = true;
      model = namedPlacesServerModel(data);
      break;
    case MODEL_TYPE.shifts:
      isLoadFromData = true;
      model = shiftModel(data);
      break;
    case MODEL_TYPE.currentShift:
      isLoadFromData = true;
      model = currentShift(data);
      break;
    case MODEL_TYPE.shiftServerModel:
      isLoadFromData = true;
      model = shiftServerModel(data);
      break;
    case MODEL_TYPE.currentShiftServerModel:
      isLoadFromData = true;
      model = currentShiftServerModel(data);
      break;
    case MODEL_TYPE.currentShiftDepotServerModel:
      isLoadFromData = true;
      model = currentShiftDepotServerModel(data);
      break;
    case MODEL_TYPE.controlCenterActivateServerModel:
      isLoadFromData = true;
      model = controlCenterActivateServerModel(data);
      break;
    case MODEL_TYPE.controlCenterDeactivateServerModel:
      isLoadFromData = true;
      model = controlCenterDeactivateServerModel(data);
      break;
    case MODEL_TYPE.controlCenterEdit:
      isLoadFromData = true;
      model = controlCenterEditServerModel(data);
      break;
    case MODEL_TYPE.depotDeliveryPriceServerModel:
      isLoadFromData = true;
      model = depotDeliveryPriceServerModel(data);
      break;
    case MODEL_TYPE.courierDeliveryFees:
      isLoadFromData = true;
      model = courierDeliveryFeesModel(data);
      break;
    case MODEL_TYPE.courierDeliveryFeesServer:
      isLoadFromData = true;
      model = courierDeliveryFeesServerModel(data);
      break;
    case MODEL_TYPE.driverTip:
      model = driverTipModel(data);
      break;
    case MODEL_TYPE.marketplaceSettings:
      model = marketplaceSettingsServerModel(data);
      isLoadFromData = true;
      break
    case MODEL_TYPE.marketplacePages:
      model = marketplacePagesModel(data);
      break;
    case MODEL_TYPE.menuTemplate:
      model = menuTemplateModel(data);
      break;
    case MODEL_TYPE.depotsMenuCopy:
      model = depotsMenuCopyModel(data);
      break;
    case MODEL_TYPE.marketplace:
      model = marketplaceModel(data);
      break;
    case MODEL_TYPE.parentMenu:
      model = parentMenuModel(data);
      break;
    case MODEL_TYPE.gallery:
      model = galleryModel(data);
      break;
    case MODEL_TYPE.reviewExtra:
      model = reviewExtraModel(data);
      break;
    case MODEL_TYPE.billingAddressModel:
      isLoadFromData = true;
      model = billingAddressModel(data);
	  break;
    case MODEL_TYPE.provider:
      isLoadFromData = true;
      model = providerModel(data);
      break;
    case MODEL_TYPE.providers:
      isLoadFromData = true;
      model = providersModel(data);
      break;
    case MODEL_TYPE.cloudPrinters:
      isLoadFromData = true;
      model = cloudPrintersModel(data);
      break;
    case MODEL_TYPE.courierAppSettings:
      isLoadFromData = true;
      model = getCourierControlSettingsModel(data);
      break;
    case MODEL_TYPE.courierDisplaySettings:
      isLoadFromData = true;
      model = getCourierDisplaySettingsModel(data);
      break;
    case MODEL_TYPE.fleet:
      isLoadFromData = true;
      model = fleetModel(data);
      break;
    case MODEL_TYPE.fleetTelemetry:
      isLoadFromData = true;
      model = fleetTelemetryModel(data);
      break;
    case MODEL_TYPE.courierInterfaceSettings:
      isLoadFromData = true;
      model = getCourierInterfaceSettingsModel(data);
      break;
    case MODEL_TYPE.reconciliations:
      isLoadFromData = true;
      model = reconciliationModel(data);
      break;
    default:
      break;
  }

  return !isLoadFromData && data
    ? {
        ...model,
        ...data
      }
    : model;
}
