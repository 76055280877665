import {
  ORDEREDIT_UPDATE,
  ORDEREDIT_ORIGINAL_ORDER_UPDATE,
  DND_SET_DATA,
  DND_CLEAR_DATA,
  ORDEREDIT_ADD_PRODUCTS,
  ORDEREDIT_SET_ORDER_SUMMARY,
  RESET_ORDEREDIT_DATA
} from "./orderEdit-actions";
import { getInitOrderModel } from "../../../services/order";
import { minutesToTimeRelative } from "../../../utils/convertTime";
import { REMOVE_ALL_DATA } from "../common-actions";

const initDnd = {
  id: "",
  item: null,
  height: 0,
  width: 0,
  diffX: 0,
  diffY: 0
};

const initState = {
  data: {
    newOrder: getInitOrderModel(),
    originalOrder: getInitOrderModel(),
    products: null,
    orderSummary: null,
    paymentIntentData: null,
    dnd: initDnd
  }
};

export default function orderEdit(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case ORDEREDIT_UPDATE:
      let newOrder = action.data.isRemove
        ? {
            ...getInitOrderModel(),
            readinessTime: action.data.firstDepot
              ? minutesToTimeRelative(action.data.firstDepot.maximumArrivalTime)
              : ""
          }
        : { ...state.data.newOrder, ...action.data };

      return {
        ...state,
        data: {
          ...state.data,
          newOrder,
          originalOrder: action.data.isRemove ? newOrder : (action.data.originalOrder || state.data.originalOrder)
        }
      };
    case ORDEREDIT_SET_ORDER_SUMMARY: {
      return {
        ...state,
        data: {
          ...state.data,
          orderSummary: action.data
        }
      }
    }
    case ORDEREDIT_ADD_PRODUCTS: {
      return {
        ...state,
        data: {
          ...state.data,
          products: action.data
        }
      }
    }
    case ORDEREDIT_ORIGINAL_ORDER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          originalOrder: action.data.isRemove
            ? null
            : {
                ...state.data.originalOrder,
                ...action.data
              }
        }
      };
    case DND_SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          dnd: action.data.item ? action.data : initDnd
        }
      };
    case DND_CLEAR_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          dnd: initDnd
        }
      };
    case RESET_ORDEREDIT_DATA:
      return {
        ...initState
      }
    default:
      return state;
  }
}
