import { call, put, takeEvery } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import {
  AUTHENTICATION,
  LOGOUT,
  AUTHENTICATION_DEAUTHORIZE
} from "../reducers/authentication/authentication-actions";

import { authorize } from "../../api/auth-requests";
import {
  ErrorData,
  actionCreateSuccess,
  actionCreateError,
  createSaga
} from "../../utils/sagaHelper";
import { logout } from "../../utils/auth-util";

import { actionRemoveAllData } from "../reducers/common-actions";
import i18n from "i18next";
import { requestResultIsOk } from "../../utils/request-util";

function* authenticationSaga(action) {
  yield* createSaga(function*() {
    const { username, password, skipRetry, rememberMe} = action.data;    
    const requestResult = yield call(authorize, username, password, skipRetry);

    if (requestResultIsOk(requestResult)) {
      Sentry.configureScope(scope => scope.setUser({ username }));
      if (!action.data.skipSave) {
        yield put(actionCreateSuccess(AUTHENTICATION, { data: requestResult.data, rememberMe, username }));
      }
      return requestResult.data;
    } else {
      const errorMessage = new ErrorData("Error authentication");

      if (!action.data.skipSave) {
        yield put(actionCreateError(AUTHENTICATION_DEAUTHORIZE, errorMessage));
        return new ErrorData(i18n.t("basic.error.INVALID_AUTH"));
      }

      return errorMessage;
    }
  }, action.type);
}

function* logoutSaga() {
  try {
    logout();
    yield put(actionRemoveAllData());
  } catch (error) {
    console.log(error);
  }
}

export default function*() {
  yield takeEvery(AUTHENTICATION, authenticationSaga);
  yield takeEvery(LOGOUT, logoutSaga);
}
