import SectionModel from '../../models/sectionModel';

import {
  DEPOT_BUSINESS_TRANSLATION,
  DEPOT_BUSINESS_TRANSLATION_EXPORT,
  DEPOT_BUSINESS_TRANSLATION_GET,
  DEPOT_BUSINESS_TRANSLATION_IMPORT,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
  CONTROL_TYPE,
  languages,
  MODEL_CONTROL_TYPE,
  ORDERING_MENU_TYPE_SECTION_MODEL,
  TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import {createControlModel, filtrateControlByDepotRole} from '../controlModelFactory';
import { getHistoryLocationParams } from '../../utils/routesHelper';
import { getDepotNameById } from '../restaurant';
import FilterOptionsModel from '../../models/filterOptionsModel.js';
import i18n from '../../i18n.js';
import { translationsFilter } from '../filter.js';

export function createDepotBusinessTranslationsSectionModel({ ...args }) {
  return new SectionModel({
    filterOptions: createDepotBusinessTranslationsSectionFilter(),
    columns: [
      {
        columns: [
          {
            title: 'settings.translations.OBJECT_TYPE_LABEL',
            accessor: 'object_type',
            width: '150px',
            fixedWidth: '150px',
          },
          {
            title: 'settings.translations.PROPERTY_NAME_LABEL',
            accessor: 'property_name',
            width: '150px',
            fixedWidth: '150px',
          },
          {
            title: 'settings.translations.ORIGINAL_VALUES_LABEL',
            accessor: 'original_value',
            width: '40%',
            fixedWidth: '40%',
          },
          {
            title: 'settings.translations.TRANSLATION_LABEL',
            accessor: 'translated_value',
            width: '40%',
            fixedWidth: '40%',
            type: CONTROL_TYPE.inputText
          }
        ],
        accessor: 'translations',
        isEditable: true,
        type: CONTROL_TYPE.translationTable,
      },
    ],
    initNewItemModel: item => {
      return item;
    },
    actionName: DEPOT_BUSINESS_TRANSLATION,
    actionLoad: DEPOT_BUSINESS_TRANSLATION_GET,
    typeAction: args.typeAction,
    typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.translations,
    parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
    stayAfterSave: true,
    withSearch: true,
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'settings.map.DEPOT_SETTINGS_LABEL',
      }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return getDepotNameById(id);
        },
      }),
    ],
    label: 'settings.translations.LABEL',
    controlSelect: {
      values: languages(),
      action: DEPOT_BUSINESS_TRANSLATION_GET
    },
    controls: filtrateControlByDepotRole([
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.iconControl,
        icon: 'export',
        text: i18n.t('settings.translations.EXPORT_LABEL'),
        action: DEPOT_BUSINESS_TRANSLATION_EXPORT,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.importFile,
        icon: 'import',
        text: i18n.t('settings.translations.IMPORT_LABEL'),
        action: DEPOT_BUSINESS_TRANSLATION_IMPORT,
      }),
    ], [MODEL_CONTROL_TYPE.back, MODEL_CONTROL_TYPE.importFile]),
    mappingLambda: state => {
      return state.restaurant.data?.translations
    },
  });
}

function createDepotBusinessTranslationsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(translations) {
    return translationsFilter(this.selected, translations);
  });

  return filterOptionsModel;

}
