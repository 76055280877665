import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { COURIER_STATE, SHIFT_TYPE, WAITER_TYPES } from "../../../utils/enums";
import { createAction } from "../../../utils/sagaHelper";
import { END_SHIFT, START_SHIFT_BACKUP } from "../../../store/reducers/userModel/userModel-actions";

import Waiter from "../Waiter/Waiter";

const CourierShiftControl = ({ 
    t, 
    userId,
    type,
    controlCenterId,
    startShiftBackUp, endShift
}) => {
    const getShiftConfig = () => {
        switch (type) {
            case COURIER_STATE.startShift:
                return {
                    tooltipText: t("couriers.START_SHIFT_TOOLTIP"),
                    errorMessage: t("couriers.START_SHIFT_ERROR"),
                    actionShift: ()=> startShiftBackUp({ courierId: userId, shiftType: SHIFT_TYPE.Normal, controlCenterId: controlCenterId })
                }
            case COURIER_STATE.endShift:
                return {
                    tooltipText: t("couriers.END_SHIFT_TOOLTIP"),
                    errorMessage: t("couriers.END_SHIFT_ERROR"),
                    actionShift: ()=> endShift({ courierId: userId })
                }
            case COURIER_STATE.startBackup:
                return {
                    tooltipText: t("couriers.START_BACKUP_TOOLTIP"),
                    errorMessage: t("couriers.START_BACKUP_ERROR"),
                    actionShift: ()=> startShiftBackUp({ courierId: userId, shiftType: SHIFT_TYPE.Backup })
                }
            case COURIER_STATE.endBackup:
                return {
                    tooltipText: t("couriers.END_BACKUP_TOOLTIP"),
                    errorMessage: t("couriers.END_BACKUP_ERROR"),
                    actionShift: ()=> endShift({ courierId: userId })
                }
            default:
                return { tooltipText: "", errorMessage: "", actionShift: ()=> {} }
        }
    }
    const config = getShiftConfig();

    useEffect(()=> {
        
    }, [userId]);

    return (
        <div className={classNames("courierShiftControl")}>
            <Waiter type={WAITER_TYPES.span}
                useClass={classNames("courierShiftControlIcon", type)}
                tooltipText={config.tooltipText}                    
                errorMessage={config.errorMessage}
                handleOnClick={config.actionShift}                    
            />
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    startShiftBackUp: data => dispatch(createAction(START_SHIFT_BACKUP, data)),
    endShift: data => dispatch(createAction(END_SHIFT, data))
});

const mapStateToProps = state => ({
    controlCenterId: state.restaurant.data && 
        state.restaurant.data.activeControlCenter && 
        state.restaurant.data.activeControlCenter.controlCenterId
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourierShiftControl));
