import React from "react";
import { withTranslation } from "react-i18next";

import { getRelativeTime, isAfterDateByCurrent, timeToMinutesRelative } from "../../../utils/convertTime";
import { isRTL } from "../../../services/userModel";

class TimeBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setRelativeTime();
    this.intervalId = setInterval(this.setRelativeTime.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getTitle = (checkShortLabel = false) => {
    const { title, relativeTime, shortLabel } = this.props;
    return typeof title === "function"
      ? title(relativeTime)
      : (checkShortLabel ? ((!shortLabel && title) || "") : title);
  }

  setRelativeTime = () => {
    const { t, date, isRelativeFromCurrent, isHideTitle, shortLabel, onUpdate } = this.props;
    onUpdate && onUpdate();
    if (!date || !t) return;
    this.setState({
      relativeTime: isRelativeFromCurrent
        ? timeToMinutesRelative(date)
        : getRelativeTime(Date.parse(date), (!isHideTitle && this.getTitle()) || "", isRTL(), shortLabel)
    });
  };

  render() {
    const { relativeTime } = this.state;
    const { t, isRelativeFromCurrent, shortLabel, isAgoLabel, date, smallText } = this.props;

    return (
      <div className="inline">
        {!isRelativeFromCurrent && (
          <div className="inline" style={{fontSize: smallText ? '11px' : '' }}>
            {this.getTitle(true) && (<div className="inline">{this.getTitle(true) || ""}&nbsp;</div>)}
            <div className="inline">{relativeTime || ""}</div>
            {isAgoLabel && isAfterDateByCurrent(date) && !shortLabel && (<div className="inline">&nbsp;{t("covertTime.TIME_AGO_LABEL")}</div>)}
          </div>
        )}
        {isRelativeFromCurrent && !!relativeTime && (<div>
          <div className="inline">
            {date && this.getTitle() && (<div className="inline">&nbsp;{date && this.getTitle()}</div>)}
            {!!relativeTime && (<div className="inline">&nbsp;{relativeTime}</div>)}
            <div className="inline">&nbsp;{shortLabel ? t("covertTime.TIME_MIN_SHORT") : t("covertTime.TIME_MIN")}</div>
            {isAgoLabel && isAfterDateByCurrent(date) && (<div className="inline">&nbsp;{t("covertTime.TIME_AGO_LABEL")}</div>)}
          </div>
        </div>)}
      </div>
    );
  }
}

export default withTranslation()(TimeBox);
