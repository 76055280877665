import script from 'scriptjs';
import environment from 'environment';

import User from '../services/user';

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class GoogleMapsService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error(
        "Instantiation failed: use Color.getInstance() instead of new."
      );
    }

    if (window.google && window.google.maps) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new GoogleMapsService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getAutocompleteService = () => {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        const info = User.instance.getCurrentUserInfo();
        script(
          `https://maps.googleapis.com/maps/api/js?key=${
            environment.googleApiKey
          }&libraries=places&language=${
            info ? info.configuration.language : "HE"
          }&callback=Function.prototype`,
          () => {
            if(window.google.maps && window.google.maps.places) {
              this.autocompleteService = new window.google.maps.places.AutocompleteService();
            }
            
            resolve(this.autocompleteService);
          }
        );
      } else {
        if (!this.autocompleteService && window.google.maps && window.google.maps.places) {
          this.autocompleteService = new window.google.maps.places.AutocompleteService();
        }
        resolve(this.autocompleteService);
      }
    });
  };

  registerMap = () => {
    return new Promise((resolve, reject) => {
      if(!this.registerMapStarted && !this.registerMapFinished) {
        this.registerMapStarted = true;
        const info = User.instance.getCurrentUserInfo();
        script(
          `https://maps.googleapis.com/maps/api/js?key=${
            environment.googleApiKey
          }&libraries=places&language=${info ? info.configuration.language : "HE"}&callback=Function.prototype`, () => {
            this.registerMapFinished = true;
            resolve();
          }
        );
      } else {
        resolve();
      }      
    });
  };
}
