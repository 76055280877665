export const GLOBAL_POPUP_MESSAGE = "GLOBAL_POPUP_MESSAGE";
export const GLOBAL_POPUP_MESSAGE_INIT = GLOBAL_POPUP_MESSAGE + "_INIT";

export function actionGlobalPopupMessage(data) {
    return {
        type: GLOBAL_POPUP_MESSAGE,
        data: data
    };
}

export function actionGlobalPopupMessageInit() {
    return {
        type: GLOBAL_POPUP_MESSAGE_INIT,
        data: { isRemove: true }
    };
}