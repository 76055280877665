import { MARKETPLACE_SETTINGS_MODEL, TYPE_MENU } from '../utils/enums';

import MenuModel from "./menuModel";
export function createMarketplaceSettingsMenu() {
    return [
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.marketplaceSettings,
            title: "settings.marketplaceSettings.MENU_LABEL",
            icon: "generalsetting",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.marketplaceSettings),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration,
            title: "settings.paymentsConfigurationSettings.LABEL",
            icon: "paymentsConfiguration",
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.serviceFee,
            title: "settings.serviceFee.LABEL",
            icon: "serviceFee",
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.serviceFee)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.offers,
            title: "settings.orderingmenu.MENU_DISCOUNTS",
            icon: "menuDiscount",
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.offers),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.loyalty,
            nestedMenuType: TYPE_MENU.loyaltyMenu,
            title: "settings.marketplaceMessage.LOYALTY",
            // disable: !getRestaurantLoyaltyEnabled(),
            icon: "loyalty",
            searchParams: state => state,
            active: location => location.pathname.includes("/marketplace-settings/loyalty/"),
        }),

        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.delivery,
            nestedMenuType: TYPE_MENU.deliveryMenu,
            title: "settings.orderingmenu.DELIVERY",

            icon: "deliveryMenu",
            searchParams: state => state,
            active: location => location.pathname.includes("/marketplace-settings/delivery/"),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.appBuilder,
            nestedMenuType: TYPE_MENU.appBuilderMenu,
            title: "settings.orderingmenu.APP_BUILDER",

            icon: "appBuilder",
            searchParams: state => state,
            active: location => location.pathname.includes("/marketplace-settings/app-builder/"),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.deepLink,
            title: "settings.deepLink.LABEL",
            icon: "link",
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.deepLink)
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split("/");
    const splitType = type.split("/");
    return (splitCurrentLocation[1] === splitType[1] && splitCurrentLocation[2] === splitType[2]);
}
