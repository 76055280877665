export const marketplaceSettingsServerModel = (data = {}) => {
    return {
        AllowMultipleDiscounts: data.allowMultipleDiscounts || false,
        ScoringEnabled: data.scoringEnabled || false,
        RequireVerifiedPhoneOnCash: data.requireVerifiedPhoneOnCash || false,
        RequireVerifiedPhoneOnCard: data.requireVerifiedPhoneOnCard || false,
        RequirePhoneVerificationOnProfile: data.requirePhoneVerificationOnProfile || false,
        TermsAndConditionsUrl: data.termsAndConditionsUrl || null,
        PrivacyPolicyUrl: data.privacyPolicyUrl || null,
        HeaderSettings: data.headerSettings || null,
        AccessibilityPolicyUrl: data.accessibilityPolicyUrl || null,
        AccessibleSiteUrl: data.accessibleSiteUrl || null,
    }
}