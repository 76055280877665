import environment from "environment";
import {callServer} from "../utils/request-util";

export const getBillingPayment = () => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/payment",
            method: "GET",
            roles: [
                environment.USER_ROLES.deliveryManager,
            ]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const deleteBillingPayment = (data) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/payment",
            method: "DELETE",
            data: data,
            roles: [
                environment.USER_ROLES.deliveryManager,
            ]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const addBillingPayment = (data) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/payment",
            method: "POST",
            data: data,
            roles: [
                environment.USER_ROLES.deliveryManager,
            ]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const getBillingAddresses = () => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/addresses",
            method: "GET",
            roles: [
                environment.USER_ROLES.deliveryManager,
            ]
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const deleteBillingAddress = (id) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/address/" + id,
            method: "DELETE"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const addBillingAddress = (data) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/address",
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const editBillingAddress = (shippingAddressId, data) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/address/"+ shippingAddressId,
            method: "PUT",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const setBillingAddress = (shippingAddressId) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/shippingAddressSet/" + shippingAddressId,
            method: "PUT"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBillingInfo() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/baseInfo",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBillingPlans() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/plans",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function setAccountPlan(planId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/subscription/" + planId,
            method: "POST"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const getAccountCards = () => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/card",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const updateAccountCard = (cardId, card) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/card/" + cardId,
            method: "PUT",
            data: card,
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const billingCardAdd = (data) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/card",
            data,
            method: "POST"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const billingCardEdit = (billingInfoId, tokenId) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/card/" + billingInfoId + "/" + tokenId,
            method: "PUT"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const billingCardDelete = (cardId) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/card/" + cardId,
            method: "DELETE"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export const invoicePdf = (invoiceId) => {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/invoice/" + invoiceId,
            method: "GET",
            responseType: "blob",
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBillingUsages() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "billing/usages",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}
