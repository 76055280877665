import React from 'react';
import i18n from '../../i18n';

import {
    CONTROL_TYPE,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
    TYPE_PATTERN,
    TYPE_SECTION_MODEL,
    USER_ROLES,
} from '../../utils/enums';
import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';
import {getDefaultUserRolesValue, getFullName} from '../userModel';
import {getInitModel} from '../initModels';
import {getPattern} from '../../utils/objects-util';
import {USER_SETTING, USER_SETTING_PASSWORD, USERS_PAGINATION,} from '../../store/reducers/userModel/userModel-actions';

import {createControlModel} from '../controlModelFactory';

import {Avatar} from '../../app/components/Avatar/Avatar';
import GrCode from '../../app/components/GrCode';
import UserRoleAvatar from '../../app/components/UserRoleAvatar';

import {getUserNameById, isMobileWidth} from '../restaurant';
import {createBreadcrumbModel} from '../createBreadcrumbModel';
import {courierSettingsFilter} from '../filter.js';
import {getHistoryLocationParams} from '../../utils/routesHelper.js';
import {columnGlobalUserEdit} from "../../utils/sectionModelHelper.js";

export function createUsersSection() {
    return new SectionModel({
        filterOptions: createUsersSectionFilter(),
        columns: [
            {
                title: '',
                width: '55px',
                component: function(user) {
                    return <Avatar avatar={user.avatarUrl} color={user.color} />;
                },
                accessor: 'avatarUrl',
                type: CONTROL_TYPE.image,
                hideWidth: '620',
            },
            {
                title: 'settings.users.USER_NAME_LABEL',
                width: '18%',
                component: user => getFullName(user),
            },
            {
                title: 'settings.users.USER_WORKER_ID',
                accessor: 'workerId',
                width: '13%',
                hideWidth: '620',
            },
            {
                title: 'settings.users.USER_PHONE_LABEL',
                width: '13%',
                accessor: 'phoneNumber',
                type: CONTROL_TYPE.text,
                hideWidth: '620',
            },
            {
                title: 'settings.users.USER_ROLES_LABEL',
                component: function(user) {
                    return <UserRoleAvatar value={user} />;
                },
                accessor: 'roles',
                type: CONTROL_TYPE.text,
                hideWidth: '400',
            },
            {
                title: '',
                width: '35px',
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.userConfirmPassword,
                isShow: item => {
                    if(!item.roles) {
                        return false;
                    }
                    const roles = Object.keys(item.roles).map(v => item.roles[v]).filter(Boolean);
                    return !(roles.length === 1 && item.roles[USER_ROLES.courier]);
                },
                iconName: 'passwordIcon',
                wrapOnMobileWidth: '992',
                tooltipText: i18n.t('settings.users.USER_TOOLS_MANAGER_CHANGE_PASSWORD_TOOLTIP'),
            },
            {
                title: '',
                width: '40px',
                component: function(user) {
                    return <GrCode courier={user} />;
                },
                accessor: '',
                wrapOnMobileWidth: '992',
                type: CONTROL_TYPE.linkIconComponent,
                link: 'grCode',
            },
            {
                title: '',
                width: '40px',
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.usersEdit,
                iconName: 'editItem',
                iconFullWH: true,
                hideWidth: '620',
                wrapOnMobileWidth: '992',
                tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
            },
        ],
        key: 'userId',
        actionName: USER_SETTING,
        typeModel: TYPE_SECTION_MODEL.users,
        hideHeaderItemControls: true,
        actionPagination: USERS_PAGINATION,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.usersNew,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        label: 'settings.users.USERS',
        breadcrumbs: [createBreadcrumbModel({ label:'header.menu.SETTINGS_LABEL' })],
        isForceUpdate: true,
        mappingLambda: state => state.userModel.data,
    });
}

export function createUserEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: args.columns || columnGlobalUserEdit,
        key: 'userId',
        actionName: USER_SETTING,
        initNewItemModel: item => {
            return item
                ? {
                      ...item,
                      fullName: getFullName(item),
                      roles: {
                          ...getDefaultUserRolesValue(),
                          ...item.roles,
                      },
                  }
                : getInitModel(MODEL_TYPE.user);
        },
        typeAction: args.typeAction,
        typeModel: args.typeAction === TYPE_ACTION.edit ? TYPE_SECTION_MODEL.usersEdit : TYPE_SECTION_MODEL.usersNew,
        parentUrl: TYPE_SECTION_MODEL.users,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.SETTINGS_LABEL'
            }),
            createBreadcrumbModel({
                label: 'settings.users.USERS',
            }),
        ],
        getLabel: () => {
            const { id } = getHistoryLocationParams(window.location.search);
            return getUserNameById(id);
        },
        mappingLambda: state => state.userModel.data || [],
    });
}

export function createUserPasswordEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.users.CONFIRM_USER_NAME_LABEL',
                accessor: 'userName',
                pattern: getPattern(TYPE_PATTERN.userName),
                patternError: i18n.t('settings.users.USER_NAME_PATTERN_ERROR'),
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.map.NEW_PASSWORD_LABEL',
                accessor: 'newPassword',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.password,
            },
            {
                title: 'settings.map.RETYPE_PASSWORD_LABEL',
                accessor: 'retypePassword',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.password,
            },
        ],
        key: 'userId',
        actionName: USER_SETTING_PASSWORD,
        initNewItemModel: user => {
            return {
                userId: user.userId,
                userName: user.userName || '',
                newPassword: '',
                retypePassword: '',
            };
        },
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.userConfirmPassword,
        parentUrl: TYPE_SECTION_MODEL.users,
        mappingLambda: state => state.userModel.data || [],
    });
}

function createUsersSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(user) {
        return courierSettingsFilter(this.selected, user);
    });

    filterOptionsModel.addOptions(
        USER_ROLES.courier,
        CONTROL_TYPE.checkbox,
        'settings.users.USER_ROLE_COURIER_TOOLTIP',
        user => !!user.roles.courier
    );

    filterOptionsModel.addOptions(
        USER_ROLES.deliveryManager,
        CONTROL_TYPE.checkbox,
        'settings.users.USER_ROLE_MANAGER_TOOLTIP',
        user => !!user.roles.deliveryManager
    );

    filterOptionsModel.addOptions(
        USER_ROLES.supportOperator,
        CONTROL_TYPE.checkbox,
        'settings.users.USER_ROLE_SUPPORT_OPERATOR_TOOLTIP',
        user => !!user.roles.supportOperator
    );

    filterOptionsModel.addOptions(
        USER_ROLES.supervisor,
        CONTROL_TYPE.checkbox,
        'settings.users.USER_ROLE_SUPERVISOR_TOOLTIP',
        user => !!user.roles.supervisor
    );

    filterOptionsModel.addOptions(
        USER_ROLES.controlCenterManager,
        CONTROL_TYPE.checkbox,
        'settings.users.USER_ROLE_CONTROL_CENTER_MANAGER_TOOLTIP',
        user => !!user.roles.controlCenterManager
    );

    return filterOptionsModel;
}
