import React, {useState} from "react";
import classNames from "classnames";
import IconButton from "../IconButton";
import "./style.scss";
import SearchBox from "../SearchBox";
import {useTranslation} from "react-i18next";

const ShiftCourierControlDepotsList = ({selectedDepots, depots, onCancel, onConfirm}) => {
    const {t} = useTranslation();
    const [localSelectedDepots, setLocalSelectedDepots] = useState(() => selectedDepots || []);
    const [searchString, setSearchString] = useState("");
    const handleOnConfirm = () => {
        onConfirm(localSelectedDepots);
    }

    const handleOnAddDepot = (d) => {
        setLocalSelectedDepots(ds => [...ds, {name: d.name, id: d.id}]);
    }

    const handleOnRemoveFromSelected = id => {
        setLocalSelectedDepots(ds => ds.filter(d => d.id !== id));
    }

    const filterDepots = depots => {
        const selectedIds = localSelectedDepots.map(d => d.id);
        return depots
            .filter(d => !selectedIds.includes(d.id) && d.name?.toLowerCase().trim().includes(searchString?.toLowerCase().trim()))
    }

    return (
        <div className={classNames("shiftCourierControlDepotsList")}>
            <SearchBox placeholder={t("shifts.SEARCH_DEPOT")} useValue={searchString} onChange={setSearchString}/>
            <div className={"depots"}>
                <div className={classNames("selectedDepots")}>
                    {localSelectedDepots.map(d => (
                        <div
                            key={`${d.name}-${d.id}`}
                            className={classNames("depot")}
                        >
                            {d.name}
                            <IconButton isIcon iconName={"iconPlus"} onClick={() => handleOnRemoveFromSelected(d.id)}/>
                        </div>
                    ))}
                </div>
                <div className={"depotList"}>
                    {depots && filterDepots(depots).map(d => (
                        <div
                            key={`${d.name}-${d.id}`}
                            className={classNames("depot")}
                            onClick={() => handleOnAddDepot(d)}
                        >
                            {d.name}
                            <div className={"emptyCircle"}/>
                        </div>
                    ))}
                </div>
            </div>
            <div className={"controls"}>
                <div className={classNames("btn", "confirm")} onClick={handleOnConfirm}>
                    {t("basic.APPLY_BUTTON")}
                </div>
                <div className={classNames("btn", "denied")} onClick={onCancel}>
                    {t("basic.CANCEL_BUTTON")}
                </div>
            </div>
        </div>
    )
}

export default ShiftCourierControlDepotsList;