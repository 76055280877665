import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import InvitationPopup from "../InvitationPopup";
import IconButton from "../IconButton";

const GrCode = ({ t, courier }) => {
  const [invitationPopupIsOpen, setInvitationPopupIsOpen] = useState(null);

  const handleInvitationOpen = () => {
      setInvitationPopupIsOpen(true);
  };

  const handleInvitationPopup = () => {
    setInvitationPopupIsOpen(false);
  };

  return (
    <div className={classNames("grCodeContainer")}>
      <IconButton isIcon={true}
        iconName={"grCode"}
        tooltipText={t("settings.users.USER_TOOLS_COURIER_INVITE_TOOLTIP")}
        onClick={handleInvitationOpen}
      />
      {invitationPopupIsOpen && (
        <InvitationPopup
          isOpen={invitationPopupIsOpen}
          courier={courier}
          onClose={() => handleInvitationPopup()}
        />
      )}
    </div>
  );
};

export default withTranslation()(GrCode);
