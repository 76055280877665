import {call, put} from "redux-saga/effects";
import {ERROR_ACTION, WAIT_FOR_ACTION} from "redux-wait-for-action";

import {objectKeysToUpperLowerCase} from "./objects-util";
import {currentUserIsDepotRole, currentUserIsSupportOperatorRole} from "../services/userModel";
import {mainModuleInitConfig, restaurateurModuleInitConfig} from "./constants";

export function* createSaga(actionSaga, actionName) {
  try {
    yield call(createSagaInner, actionSaga, actionName);
  } catch (e) {
    console.log(e);
  }
}

export function* createSagaInner(actionSaga, actionName) {
  const data = yield* actionSaga();
  if (data instanceof ErrorData) {
    yield put(actionCreateError(actionName, data));
  } else {
    yield put(actionCreateSuccess(actionName, data));
  }
}

export function createAction(actionName, data, isTransformToUpperLowerCase = false) {
  return {
    type: actionName,
    [WAIT_FOR_ACTION]: actionName + "_SUCCESS",
    [ERROR_ACTION]: actionName + "_FAIL",
    data: isTransformToUpperLowerCase ? objectKeysToUpperLowerCase(data) : data
  };
}

export function actionCreateSuccess(actionName, data) {
  return {
    type: actionName + "_SUCCESS",
    data: data
  };
}

export function actionCreateError(actionName, error) {
  return {
    type: actionName + "_FAIL",
    error: error.getError().message
  };
}

export function getCurrentInitConfig() {
  const defaultConfig = currentUserIsDepotRole()
    ? restaurateurModuleInitConfig
    : mainModuleInitConfig;
  return {
    ...defaultConfig,
    pendingOrderCount: currentUserIsSupportOperatorRole(),
  };
}

export class ErrorData {
  constructor(message) {
    this.message = message;
  }

  getError = () => new Error(this.message);
}

export class ErrorDataTable {
  constructor(message, isShow = true) {
    this.message = message;
    this.isShow = isShow
  }

  getError = () => ({message: new Error(this.message), isShow: this.isShow});
}

export function batchedSubscribe(batch) {
  if (typeof batch !== 'function') {
    throw new Error('Expected batch to be a function.');
  }

  let currentListeners = [];
  let nextListeners = currentListeners;

  function ensureCanMutateNextListeners() {
    if (nextListeners === currentListeners) {
      nextListeners = currentListeners.slice();
    }
  }

  function subscribe(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function.');
    }

    let isSubscribed = true;

    ensureCanMutateNextListeners();
    nextListeners.push(listener);

    return function unsubscribe() {
      if (!isSubscribed) {
        return;
      }

      isSubscribed = false;

      ensureCanMutateNextListeners();
      const index = nextListeners.indexOf(listener);
      nextListeners.splice(index, 1);
    };
  }

  function notifyListeners() {
    const listeners = currentListeners = nextListeners;
    for (let i = 0; i < listeners.length; i++) {
      listeners[i]();
    }
  }

  function notifyListenersBatched(...dispatchArgs) {
    batch(notifyListeners, ...dispatchArgs);
  }

  return next => (...args) => {
    const store = next(...args);
    const subscribeImmediate = store.subscribe;

    function dispatch(...dispatchArgs) {
      const res = store.dispatch(...dispatchArgs);
      notifyListenersBatched(...dispatchArgs);
      return res;
    }

    return {
      ...store,
      dispatch,
      subscribe,
      subscribeImmediate
    };
  };
}
