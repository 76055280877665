import PusherJS from "pusher-js";
import environment from "environment";
import Store from "../store/store";

import User from "./user";
import { getPusherChannelName } from "./restaurant";
import { isShowControlCenterSearch } from "./userModel";

import { getAuthorization } from "../utils/auth-util";
import { PUSHER_CONNECTION_STATE } from "../utils/enums";

import { PUSHER_COURIER_LOCK_UPDATE, PUSHER_COURIER_SHIFT_CREATED, PUSHER_DEPOT_CYCLE_REFRESH, PUSHER_USER_DELETED, PUSHER_USER_UPDATED } from "../store/reducers/userModel/userModel-actions";
import { PUSHER_ASSISTANCE_UPDATE, PUSHER_CONSUMER_ORDER_ARRIVED, PUSHER_CYCLE_REORDER, PUSHER_GROUP_LOCK_UPDATE, PUSHER_GROUP_REORDER, PUSHER_GROUP_UPDATE, PUSHER_NEW_ORDER, PUSHER_ORDER_STATE_UPDATE, PUSHER_UPDATE_ORDER } from "../store/reducers/order/order-actions";
import { PUSHER_CYCLE_DISPATCHED, PUSHER_CYCLE_ROUTE_UPDATE, PUSHER_CYCLE_STATE_UPDATE, PUSHER_MANUAL_ASSOCIATE_UPDATE, PUSHER_MANUAL_UNASSOCIATE_UPDATE, PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST, PUSHER_SET_AWAY } from "../store/reducers/common-actions";
import { PUSHER_DEPOT_SERVICES_REFRESH, PUSHER_RESTAURANT_STATE_UPDATE } from "../store/reducers/restaurant/restaurant-actions";
import { NOTIFICATIONS_RESTART, NOTIFICATION_ADD, NOTIFICATION_DELETE } from "../store/reducers/notification/notification-actions";
import { PUSHER_EXTERNAL_CYCLE_CREATED, PUSHER_EXTERNAL_CYCLE_FINISHED, PUSHER_EXTERNAL_CYCLE_UPDATED } from "../store/reducers/externalCycles/externalCycles-actions";
import {GET_DIALOGS_SUPPORT} from "../store/reducers/supportChat/supprtChat-actions";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Pusher {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use Pucher.getInstance() instead of new."
      );

    this.subscribeAllChannels = this.subscribeAllChannels.bind(this);
    this.initCheckPusherChannels = this.initCheckPusherChannels.bind(this);
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Pusher(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  initPusher() {
    if(this.pusher) {
      if(this.pusher.connection && this.pusher.connection.state !== PUSHER_CONNECTION_STATE.connecting) {
        this.pusher.disconnect();
        this.pusher = null;
      }      
    } else {
      this.pusher = new PusherJS(environment.pusherAppKey, {
        authEndpoint: environment.pusherAuthEndpoint,
        cluster: environment.pusherCluster,
        encrypted: true,
        disableStats: true,
        enabledTransports: ["ws"],
        auth: { headers: { Authorization: getAuthorization() } },
      });
  
      this.pusher.connection.bind(PUSHER_CONNECTION_STATE.connected, ()=> {
        this.subscribeAllChannels();
        this.initCheckPusherChannels();
      });  
    }      
  }

  destroyPusher() {
    try {
      this.clearCheckPusherChannels();
      this.unsubscribeAllChannels();
      this.setControlCenterMultiChannel([]);
      
      if(this.pusher) {
        this.pusher.disconnect();
      }      
    } catch (err) {
    }
  }

  initCheckPusherChannels() {
    this.intervalId = setInterval(this.checkPusherChannels.bind(this), 20000);
  }

  clearCheckPusherChannels() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkPusherChannels() {
    const currentUser = User.instance.getCurrentUserInfo();
    if(currentUser) {
      this.resubscribeUpdatedControlCenters();
    }    
  }

  subscribeChannel(channelName) {
    const keys = Object.keys(this.pusherEvents());    
    if(channelName && !this.channelIsSubscribed(channelName) && !this.checkChannelState(channelName)) {
      if(this.pusher) {
        const channel = this.pusher.subscribe(channelName);
        keys.forEach(key => {
          const keyValue = this.pusherEvents()[key];
          if(key && keyValue) {
            channel.bind(key, (data) => {
              Store.store.dispatch({ type: keyValue, data});
            });
          }
        });
      }    
    }
  }

  unsubscribeChannel(channelName) {
    const keys = Object.keys(this.pusherEvents());
    if(channelName && 
      this.pusher && 
      this.pusher.connection && 
      this.pusher.connection.state === PUSHER_CONNECTION_STATE.connected
    ) {
      keys.forEach(key => {
        if(key && this.pusherEvents()[key]) {
          this.pusher.unsubscribe(channelName);
        }        
      });
    }
  }

  setControlCenterMultiChannel(controlCenter) {
    this.controlCenterChannelMulti = controlCenter;
  }

  getControlCenterChannel() {
    return this.controlCenterChannel;
  }

  getControlCenterMultiChannel() {
    return this.controlCenterChannelMulti;
  }

  setShiftHistoryFilterStateChannel(shiftHistoryFilterState) {
    this.shiftHistoryFilterState = shiftHistoryFilterState;
  }

  getShiftHistoryFilterStateChannel() {
    return this.shiftHistoryFilterState;
  }

  setShiftProviderHistoryFilterStateChannel(shiftProviderHistoryFilterState) {
    this.shiftProviderHistoryFilterState = shiftProviderHistoryFilterState;
  }

  getShiftProviderHistoryFilterStateChannel() {
    return this.shiftProviderHistoryFilterState;
  }

  subscribeAllChannels() {
    if(!this.channels) {
      this.channels = this.getAllChannels();
    }
    
    this.channels.forEach(channel => {
      this.subscribeChannel(channel);
    });
  }

  unsubscribeAllChannels() {
    const channels = this.channels || this.getAllChannels();
    channels.forEach(channel => {
      this.unsubscribeChannel(channel);
    });    
    this.channels = null;
    this.controlCenterChannel = undefined;
  }

  resubscribeUpdatedControlCenters() {
    if(!this.getMainChannelName()) 
      return;
      
    if(this.pusher) {
      const pusherChannels = this.pusher.allChannels();
      const controlCenterChannels = this.getAllChannels();

      pusherChannels.forEach(pusherChannel => {
        if(!controlCenterChannels.includes(pusherChannel.name)) {
          this.unsubscribeChannel(pusherChannel.name);
        }
      });

      controlCenterChannels.forEach(controlCenterChannel=> {
        const channel = this.pusher.channel(controlCenterChannel);
        if(!channel) {
          this.subscribeChannel(controlCenterChannel);
        }
      });
    } else {
      this.initPusher();
    }
  }

  getAllChannels() {
    const currentUser = User.instance.getCurrentUserInfo();

    let channels = [];
    if(this.getMainChannelName()) {
      channels.push(this.getMainChannelName());

      //check if current user has control center
      if(currentUser && currentUser.controlCenterId) {
        channels.push(`${this.getMainChannelName()}-${currentUser.controlCenterId}`);
      }

      //check if user select control center
      if(isShowControlCenterSearch() && this.controlCenterChannelMulti && this.controlCenterChannelMulti.length) {
        this.controlCenterChannelMulti.forEach(controlCenterChannel => {
          channels.push(`${this.getMainChannelName()}-${controlCenterChannel}`);
        })
      }
    }

    return channels;
  }

  getCurrentControlCenters = () => {
    const controlCenters = [];
    const currentUser = User.instance.getCurrentUserInfo();
    
    controlCenters.push(getPusherChannelName());
    if(currentUser && currentUser.controlCenterId) {
      controlCenters.push(currentUser.controlCenterId);
    }

    if(isShowControlCenterSearch() && this.controlCenterChannel) {
      controlCenters.push(this.controlCenterChannel);
    }

    return controlCenters;
  }

  checkChannelState(channelName, state = PUSHER_CONNECTION_STATE.connecting) {
    if(!channelName) return false;

    const channel = this.pusher && this.pusher.channel(channelName);    
    return channel && 
      channel.pusher && 
      channel.pusher.connection && 
      channel.pusher.connection.state === state;
  }

  channelIsSubscribed(channelName) {
    if(!channelName) return false;
    const channel = this.pusher && this.pusher.channel(channelName);
    return channel && channel.subscribed;
  }

  getMainChannelName = () => {
    const mainChannel = getPusherChannelName();
    return mainChannel && `private-${mainChannel}`;
  }

  pusherEvents = () => {
    return {
      courier_lock_update: PUSHER_COURIER_LOCK_UPDATE,
      group_update: PUSHER_GROUP_UPDATE,
      new_order: PUSHER_NEW_ORDER,
      order_state_update: PUSHER_ORDER_STATE_UPDATE,
      update_order: PUSHER_UPDATE_ORDER,
      cycle_state_update: PUSHER_CYCLE_STATE_UPDATE,
      cycle_dispatched: PUSHER_CYCLE_DISPATCHED,
      cycle_reorder: PUSHER_CYCLE_REORDER,
      cycle_route_update: PUSHER_CYCLE_ROUTE_UPDATE,
      courier_shift_created: PUSHER_COURIER_SHIFT_CREATED,
      manual_associate_update: PUSHER_MANUAL_ASSOCIATE_UPDATE,
      group_reorder: PUSHER_GROUP_REORDER,
      manual_unassociate_update: PUSHER_MANUAL_UNASSOCIATE_UPDATE,
      set_away: PUSHER_SET_AWAY,
      manual_unassociate_update_list: PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST,
      user_updated: PUSHER_USER_UPDATED,
      user_deleted: PUSHER_USER_DELETED,
      resaurant_state_update: PUSHER_RESTAURANT_STATE_UPDATE,
      group_lock_update: PUSHER_GROUP_LOCK_UPDATE,
      assistance_update: PUSHER_ASSISTANCE_UPDATE,
      depot_cycle_refresh: PUSHER_DEPOT_CYCLE_REFRESH,
      notification_added: NOTIFICATION_ADD,
      notification_deleted: NOTIFICATION_DELETE,
      notification_service_restart: NOTIFICATIONS_RESTART,
      consumer_order_arrived: PUSHER_CONSUMER_ORDER_ARRIVED,
      depots_services_refresh: PUSHER_DEPOT_SERVICES_REFRESH,
      external_cycle_created: PUSHER_EXTERNAL_CYCLE_CREATED,
      external_cycle_updated: PUSHER_EXTERNAL_CYCLE_UPDATED,
      external_cycle_finished: PUSHER_EXTERNAL_CYCLE_FINISHED,
      pending_chat_update: GET_DIALOGS_SUPPORT,
    }
  };
}
