import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import { isRTL } from '../../../services/userModel';
import { ModalPopup } from '../ModalPopup/ModalPopup';
import {
	WhatsappShareButton,
	WhatsappIcon,
	ViberShareButton,
	ViberIcon,
	TelegramShareButton,
	TelegramIcon,
} from 'react-share';
import {APPLICATION_TYPE} from "../../../utils/enums";
import {courierQRCode} from "../../../api/user-requests";
import {requestResultIsOk} from "../../../utils/request-util";
import environment from "environment";

Modal.setAppElement('#root');
const InvitationPopup = ({ t, courier, onClose, isOpen, isOverModal }) => {
	const [applicationType, setApplicationType] = useState(courier.roles.courier ? APPLICATION_TYPE.courier : APPLICATION_TYPE.business);

	const [qrState, setQrState] = useState({
		code: '',
		link: '',
		qrCodeSrc: '',
	});

	const shareUrl = `${t('couriers.INVITATION_LINK_NUMBER_LABEL')} ${encodeURI(
		qrState.link,
	)} ${t('couriers.INVITATION_LINK_CODE_LABEL')} ${qrState.code}`;

	useEffect(() => {
		if(courier && courier.userId) {
			courierQRCode(courier.userId, applicationType).then(response => {
				if (requestResultIsOk(response, true)) {
					const size = 350;
					setQrState({
						qrCodeSrc: `${environment.qrserverAPI}?size=${size}x${size}&data=${response.data.Link}`,
						code: response.data.Code,
						link: response.data.Link
					});
				}
			});
		}

	}, [applicationType, courier]);

	const onChangeType = type => {
		if(type === applicationType) {
			return;
		}

		setApplicationType(type);
	}

	return (
		<ModalPopup
			isOpen={isOpen}
			onClose={onClose}
			isOverModal={isOverModal}
			width={'340px'}
			height={'fit-content'}
			content={
				<div className={classNames('popupContainer', { rtl: isRTL() })}>
					<div className='header'>
						<span>{t('dialogs.invitation.HEADER')}</span>
						<span className='close' onClick={onClose}>
							{t('basic.CLOSE_BUTTON')}
						</span>
					</div>

					{!!(courier.roles.courier && courier.roles.deliveryManager) && (
						<div className='roles'>
							<div
								onClick={() => onChangeType(APPLICATION_TYPE.courier)}
								className={classNames('roleItem', { active: applicationType === APPLICATION_TYPE.courier })}
							>
								{t('dialogs.invitation.COURIER')}
							</div>
							<div
								onClick={() => onChangeType(APPLICATION_TYPE.business)}
								className={classNames('roleItem', { active: applicationType === APPLICATION_TYPE.business })}
							>
								{t('dialogs.invitation.MANAGER')}
							</div>
						</div>
					)}

					<div className='placeholder'>
						<img
							src={qrState.qrCodeSrc}
							className={classNames({ preloader: qrState.qrCodeSrc === '' })}
							alt='qr-code'
						/>
					</div>
					<div className='code'>
						<h5>
							{t('dialogs.invitation.CODE')} : {qrState.code}
						</h5>
					</div>
					<section className='share'>
						<div>
							<p>{t('dialogs.invitation.SHARE')}:</p>
						</div>
						<div className='share-btns-wrapper'>
							<WhatsappShareButton style={{ display: 'flex' }} url={shareUrl}>
								<WhatsappIcon size={25} round={true} />
							</WhatsappShareButton>
							<TelegramShareButton style={{ display: 'flex' }} url={shareUrl}>
								<TelegramIcon size={25} round={true} />
							</TelegramShareButton>
							<ViberShareButton style={{ display: 'flex' }} url={shareUrl}>
								<ViberIcon size={25} round={true} />
							</ViberShareButton>
						</div>
					</section>
				</div>
			}
		/>
	);
}

export default withTranslation()(InvitationPopup);
