import React, {useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { getIsShowReadinessTime } from "../../../services/order";
import { ORDER_STATE } from "../../../utils/enums";
import { isAfterDateByCurrent, isSameDateByCurrent } from "../../../utils/convertTime";

import TimeBox from "../TimeBox/TimeBox";
import IconButton from "../IconButton";

const ReadinessTimeInfo = ({ t, value, isComa, withIcon, smallText }) => {
    const [iconName, setIconName] = useState('');
    const [title, setTitle] = useState('');
    useEffect(() => {
        setIconName(() => value.state === ORDER_STATE.ready || isAfterDateByCurrent(value.readinessTime) ? "readinessTimeRed" : "readinessTime");
    }, [value.readinessTime, value.state]);

    const getTitle = (relativeTime) => {
        let title = `${isComa ? ", ": ""}`;
        if(value.state === ORDER_STATE.ready) {
            if(isSameDateByCurrent(value && value.readinessTime)) {                
                title += (relativeTime || "").toString().includes(t("covertTime.JUST_NOW_LABEL"))
                    ? `${t("orders.WAS_READY_LABEL")} `
                    : `${t("orders.READY_LABEL")} `;
            } else if(isAfterDateByCurrent(value && value.readinessTime)) {
                title += `${t("orders.WAS_READY_LABEL")} `;
            } else {
                title += t("orders.READY_IN");
            }            
        } else {
            title += t("orders.READY_IN");
        }

        return title;
    }

    const onUpdate = () => {
        let icon = '';
        if(value.state === ORDER_STATE.ready || isAfterDateByCurrent(value.readinessTime) || isSameDateByCurrent(value.readinessTime)) {
            icon = "readinessTimeRed";
        } else {
            icon = "readinessTime";
        }

        if(icon !== iconName) {
            setIconName(icon);
        }

        const newTitle = getTitle();
        if(title !== newTitle) {
            setTitle(getTitle());
        }
    }

    const isShow = value && getIsShowReadinessTime(value);
    return (
        <div className={classNames("readinessTimeInfo", "inline", { hide: !isShow || !value })}>
            {isShow && (
                <>
                    {withIcon && (
                        <div className={classNames("readinessTimeWishIcon", {"readinessTimeWishIcon-ago": isAfterDateByCurrent(value.readinessTime)})}>
                            <IconButton tooltipText={title} isIcon iconName={iconName}/>

                            <TimeBox
                                date={value.readinessTime}
                                shortLabel
                                title={""}
                                onUpdate={onUpdate}
                            />
                        </div>

                    )}
                    {!withIcon && (
                        <TimeBox
                            smallText={smallText}
                            date={value.readinessTime}
                            isRelativeFromCurrent={value.state !== ORDER_STATE.ready}
                            isAgoLabel={value.state === ORDER_STATE.ready}
                            isHideTitle={value.state === ORDER_STATE.ready}
                            title={getTitle}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default withTranslation()(ReadinessTimeInfo);
