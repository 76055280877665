import { MODEL_TYPE } from "../utils/enums";
import { getInitModel } from "../services/initModels";
export function namedPlaces(data) {
  const item = data || {};
  return {
    id: item.id,
    name: item.name || "",
    address: item.address || {
      address: getInitModel(MODEL_TYPE.address),
      location: getInitModel(MODEL_TYPE.location)
    },
    region: item.region || [],
    modelType: MODEL_TYPE.namedPlaces
  };
}
