export function driverInfoModel(data) {
    const { name, carModel, carNumber, carColorHex, ext, phone, ttlSeconds } = data || {};

    return {
        name: name || "",
        carModel: carModel || "",
        carNumber: carNumber || "",
        carColorHex: carColorHex || "",
        ext: ext || "",
        phone: phone || "",
        ttlSeconds: ttlSeconds || ""
    }
};