import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { getExternalCycleLogo } from "../../../services/restaurant";
import { VEHICLE_TYPE } from "../../../utils/enums";

import { Avatar } from "../Avatar/Avatar";
import VehicleIcon from "../VehicleIcon";

const AssociatePopupExternalProviders = ({
    t,
    checked,
    handleChecked,
    providers
}) => {
    const isLoading = useSelector(state => state.sectionsWrapperData.isLoading);

    return (
        <div className={classNames("associatePopupExternalProviders", { hide: !providers || !providers.length })}>
            <div className="headerGroup">
                <span>{t("dashboard.couriersbar.EXTERNAL_PROVIDERS_GROUP_LABEL")}</span>
                {isLoading && <div className={"loader"} />}
            </div>
            {providers?.map(provider=> (
                <div key={`${provider.name}-${provider.id}`} className="courierGroupItem"
                    onClick={e => handleChecked(provider.id, e, true, provider.provider)}
                >
                    <Avatar isObject={true} avatar={getExternalCycleLogo(provider)} color={""} />
                    <div className="courierInfo">
                        <VehicleIcon value={VEHICLE_TYPE.car} isActive={true} />&nbsp;&nbsp;
                        {provider?.name}
                    </div>
                    <div className="courierCheck">
                        <input
                            type="checkbox"
                            id={`checkbox-${provider.name}`}
                            name="check"
                            checked={checked === provider.id}
                            onChange={e => handleChecked(provider.id, e, true, provider.provider)}
                        />
                        <label htmlFor={`checkbox-${provider.name}`} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default withTranslation()(AssociatePopupExternalProviders);