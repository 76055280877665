import SectionModel from '../../models/sectionModel';
import {
    CONTROL_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
    TYPE_MENU,
    TYPE_SECTION_MENUTEMPLATE_MODEL, TYPE_SECTION_MODEL,
} from '../../utils/enums';
import i18n from '../../i18n';
import {
    MARKETPLACE_SETTINGS,
    MENU_TEMPLATE,
    MENU_TEMPLATE_GET
} from '../../store/reducers/restaurant/restaurant-actions';
import { createControlModel } from '../controlModelFactory';
import FilterOptionsModel from '../../models/filterOptionsModel';
import { getInitModel } from '../initModels';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { getMenuTemplateNameById, isMobileWidth } from '../restaurant';
import { getHistoryLocationParams } from '../../utils/routesHelper';
import { getMenuTemplatesFilter } from '../filter';

export const createMenuTemplateSectionModel = () => {
    return new SectionModel({
        filterOptions: createMenuTemplateSectionFilter(),
        columns: [
            {
                title: 'settings.menuTemplate.NAME',
                accessor: 'name',
                type: CONTROL_TYPE.text,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
                hideWidth: '700',
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.mobileMenu,
                menuType: TYPE_MENU.menuTemplate,
                key: 'business_id',
                tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
                iconName: 'editItem',
                isEditable: true,
                showWidth: '700',
            },
        ],
        actionName: MENU_TEMPLATE,
        isLayoutForm: true,
        key: 'business_id',
        actionLoad: MENU_TEMPLATE_GET,
        typeModel: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: MARKETPLACE_SETTINGS_MODEL.menuTemplateNew,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
        ],
        label: 'settings.menuTemplate.LABEL',
        mappingLambda: state => state.menuTemplate.data.templates || []
    });
};

export const createNewMenuTemplateSectionModel = ({ ...args }) => {
    return new SectionModel({
        columns: [
            {
                title: 'settings.menuTemplate.NAME',
                accessor: 'name',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
        ],
        typeAction: args.typeAction,
        actionName: MENU_TEMPLATE,
        typeModel: MARKETPLACE_SETTINGS_MODEL.menuTemplateNew,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
        ],
        label: 'settings.menuTemplate.LABEL',
        initNewItemModel: item => getInitModel(MODEL_TYPE.menuTemplate, item),
        mappingLambda: state => state.menuTemplate.data.templates || []
    });
};

export const createMenuTemplateEditSectionModel = ({ ...args }) => {
    return new SectionModel({
        key: "business_id",
        columns: [
            {
                title: 'settings.menuTemplate.NAME',
                accessor: 'name',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
        ],
        typeAction: args.typeAction,
        actionName: MENU_TEMPLATE,
        typeModel: TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                isShow: TYPE_ACTION.edit === args.typeAction,
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getMenuTemplateNameById(id);
                },
            }),
        ],
        label: 'marketplacesettings.menuTemplateLabel.INFO',
        initNewItemModel: item => getInitModel(MODEL_TYPE.menuTemplate, item),
        mappingLambda: state => state.menuTemplate.data.templates || []
    });
};

function createMenuTemplateSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(template) {
        return getMenuTemplatesFilter(this.selected, template);
    });

    return filterOptionsModel;
}

export function createMarketplaceSettingsModel(args) {
    return new SectionModel({
        columns: [
            {
                isEditable: true,
                accessor: "headerUrl",
                settingsAaccessor: "headerSettings",
                type: CONTROL_TYPE.imageEdit
            },
            {
                title: "settings.marketplaceSettings.SCORING_ENABLED",
                accessor: "scoringEnabled",
                isEditable: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: "settings.marketplaceSettings.REQUIRE_VERIFIED_PHONE_ON_CASH",
                accessor: "requireVerifiedPhoneOnCash",
                isEditable: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: "settings.marketplaceSettings.REQUIRE_VERIFIED_PHONE_ON_CARD",
                accessor: "requireVerifiedPhoneOnCard",
                isEditable: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: "settings.marketplaceSettings.REQUIRE_VERIFIED_PHONE_ON_PROFILE",
                accessor: "requirePhoneVerificationOnProfile",
                isEditable: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: "settings.marketplaceSettings.TERMS_CONDITIONS_URL",
                accessor: "termsAndConditionsUrl",
                isEditable: true,
                type: CONTROL_TYPE.text
            },
            {
                title: "settings.marketplaceSettings.PRIVACY_POLICY_URL",
                accessor: "privacyPolicyUrl",
                isEditable: true,
                type: CONTROL_TYPE.text
            },
        ],
        actionName: MARKETPLACE_SETTINGS,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: TYPE_SECTION_MODEL.marketplaceSettings,
        mappingLambda: state => state.restaurant.data.paymentsConfiguration,
    });
}