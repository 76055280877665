import queryString from "query-string";
import moduleMain from "../modules/main";
import moduleRestaurateur from "../modules/restaurateur";
import { currentUserIsDepotRole } from "../services/userModel";
import { getUserConnectionData } from "./auth-util";

export function excludedRoutes() {
  return ["/login"];
}

export function IsInitAllowed(path) {
  const user = getUserConnectionData();
  return (path === "/login" && user) ||
    !excludedRoutes().includes(path);
}

export function getPages() {
  return currentUserIsDepotRole() ? moduleRestaurateur : moduleMain;
}

export function getHistoryLocationParams(historyLocationSearch) {
  return historyLocationSearch ? (queryString.parse(historyLocationSearch) || {}) : {};
}
