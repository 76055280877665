export function refundServerModel(data) {
    const item = data || {};
    return {
        Partial: item.partial || false,
        Amount: item.amount || 0,
        Notes: item.notes || "",
        Refund_channel: item.refund_channel,
        Refund_type: item.refund_type,
        Payment_id: item.payment_id,
    };
}