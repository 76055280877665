import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import de from "../src/assets/languages/de.json";
import en from "../src/assets/languages/en.json";
import he from "../src/assets/languages/he.json";
import ru from "../src/assets/languages/ru.json";
import es from "../src/assets/languages/es.json";
import el from "../src/assets/languages/el.json";
import pl from "../src/assets/languages/pl.json";
import ar from "../src/assets/languages/ar.json";
import sound_de from "../src/assets/soundLocalization/de.json";
import sound_en from "../src/assets/soundLocalization/en.json";
import sound_he from "../src/assets/soundLocalization/he.json";
import sound_ru from "../src/assets/soundLocalization/ru.json";
import sound_es from "../src/assets/soundLocalization/es.json";
import sound_pl from "../src/assets/soundLocalization/pl.json";

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    defaultNS: ["translation"],

    react: {
      useSuspense: false
    },

    lng: "en", // language to use

    /*
        COMMENT
        By default package use resources namespace 'translation'. If it's not specified it isn't work.
    */
    resources: {
      de: { translation: Object.assign(de, sound_de) },
      en: { translation: Object.assign(en, sound_en) },
      he: { translation: Object.assign(he, sound_he) },
      ru: { translation: Object.assign(ru, sound_ru) },
      es: { translation: Object.assign(es, sound_es) },
      el: { translation: Object.assign(el, sound_en) },
      pl: { translation: Object.assign(pl, sound_pl) },
      ar: { translation: Object.assign(ar, sound_en) },
    }
  });

export default i18n;
