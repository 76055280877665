import ControlModel from '../../models/controlModel.js';
import {MODEL_CONTROL_TYPE} from '../../utils/enums.js';
import {useDispatch} from 'react-redux';
import {createAction} from '../../utils/sagaHelper.js';
import classNames from 'classnames';
import IconButton from '../../app/components/IconButton/IconButton.jsx';
import React, {useState} from 'react';
import preloader from '../../assets/images/preloader.GIF';

export function createActionButtonControlModel({ ...args }) {
  return new ControlModel({
    action: args.action || '',
    width: args.width || '',
    tooltipText: args.tooltipText || '',
    text: args.text || '',
    customStyles: args.customStyles || null,
    icon: args.icon || '',
    isShow:
      args.isShow ||
      function() {
        return true;
      },
    type: MODEL_CONTROL_TYPE.actionButton,
  });
}

export const ActionButtonControl = ({ control, model, models }) => {
  const dispatch = useDispatch();
  const { action, text, tooltipText, icon, width } = control;
  const [inProcess, setInProcess] = useState(false);
  const handleClick = event => {
    if (inProcess) {
      return;
    }
    setInProcess(true);
    new Promise((resolve, reject) => {
      return dispatch(createAction(action, models[model.typeModel])).then(
        () => {
          setInProcess(false);
          resolve();
        },
        error => {
          setInProcess(false);
        }
      );
    });
  };

  return (
    <div>
      <div
        style={{
          width: width ? width : 'auto',
          minWidth: width ? width : 'auto',
          justifyContent: icon ? 'space-around' : 'center',
        }}
        className={classNames('btn', 'confirm', 'filterTriggerControl', { disabled: inProcess })}
        onClick={handleClick}
      >
        <span className={'filterTriggerControlTitle'}>{text}</span>

        {inProcess ? (
          <img src={preloader} alt='preloader' />
        ) : (
          <IconButton isIcon iconName={icon} tooltipText={tooltipText} />
        )}
      </div>
    </div>
  );
};
