import i18n from '../../../i18n';

export const UPDATE_TOOLTIP_DATA = 'UPDATE_TOOLTIP_DATA';

export function actionUpdateTooltipData(data) {
    if (data && data.ref) {
        const position = data.ref.getBoundingClientRect();

        if (data.tooltipText === i18n.t('datetimepicker.TITLE_BUTTON')) {
            position.x -= position.width / 2 + 10;
        }

        return {
            type: UPDATE_TOOLTIP_DATA,
            data: {
                tooltipText: data.tooltipText,
                position: position,
                isNeverRtl: data.isNeverRtl,
                tooltipIsHTML: data.tooltipIsHTML,
            },
        };
    } else {
        return { type: UPDATE_TOOLTIP_DATA, data: { position: null, tooltipText: '', isNeverRtl: false } };
    }
}

export function actionResetTooltipData(data) {
    return { type: UPDATE_TOOLTIP_DATA, data: { ...data, position: null, tooltipText: '', isNeverRtl: false } };
}
