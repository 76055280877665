import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import {
  actionResetTooltipData,
  actionUpdateTooltipData,
} from '../../../store/reducers/tooltipPortal/tooltipPortal-actions';
import {isRTL} from '../../../services/userModel';
import {TYPE_SWITCH_CONTROL_COLOR} from '../../../utils/enums';

import IconButton from '../IconButton';
import preloader from '../../../assets/images/preloader.GIF';

const NoSelect = ({ title }) => <div className={classNames('title', 'noselect')}>{title}</div>;
const SwitchControl = ({
  value,
  typeColor,
  title,
  tooltipText,
  isReadonly,
  isNoRtl,
  isMinimize,
  onClick,
  updateTooltipData,
  resetTooltipData,
  withLoader,
  onlyParentState,
}) => {
  const hoverRef = useRef();
  const [curValue, setCurValue] = useState(value);
  const isDisabled = curValue !== value;

  useEffect(() => {
    setCurValue(value);
  }, [value]);

  const handleOnClick = () => {
    if (isReadonly || isDisabled) return;
    !onlyParentState && setCurValue(!curValue);
    onClick && onClick(!value);
  };

  const handleOnMouseEnter = () => {
    tooltipText && updateTooltipData({ tooltipText, ref: hoverRef.current });
  };

  const handleOnMouseLeave = e => {
    tooltipText && resetTooltipData({ event: e, context: this });
  };

  const getIcon = () => {
    switch (typeColor) {
      case TYPE_SWITCH_CONTROL_COLOR.blueSubmit:
        return value ? 'applyActive' : 'noIsPaid';
      case TYPE_SWITCH_CONTROL_COLOR.crown:
        return 'crown';
      case TYPE_SWITCH_CONTROL_COLOR.extraSize:
        return 'extraSize';
      default:
        return '';
    }
  };

  const titleText = (title && (curValue ? title.on : title.off)) || '';

  return (
    <>
      <div
        ref={hoverRef}
        className={classNames('switchControl', {
          isMinimize: isMinimize,
          rtl: isRTL(),
          active: curValue,
          isNoRtl: isNoRtl,
          blueType: !typeColor || typeColor === TYPE_SWITCH_CONTROL_COLOR.blue,
          blueSubmit: typeColor === TYPE_SWITCH_CONTROL_COLOR.blueSubmit,
          redType: typeColor === TYPE_SWITCH_CONTROL_COLOR.red,
          crownType: typeColor === TYPE_SWITCH_CONTROL_COLOR.crown,
          extraSizeType: typeColor === TYPE_SWITCH_CONTROL_COLOR.extraSize,
        })}
        onClick={handleOnClick}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {!isRTL() && (
            <NoSelect title={titleText} />
        )}
        <div className={classNames('iconContainer')}></div>
        <div className={classNames('icon')}>
          {getIcon() && (
            <IconButton
              disabled={isDisabled}
              isIcon={true}
              active={true}
              iconName={getIcon()}
              onClick={handleOnClick}
            />
          )}
        </div>
        {isRTL() && (
            <NoSelect title={titleText} />
        )}
      </div>
      {isDisabled && withLoader && (
        <div style={{ marginLeft: '10px', height: '18px' }}>
          <img src={preloader} alt='preloader' />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateTooltipData: data => dispatch(actionUpdateTooltipData(data)),
    resetTooltipData: data => dispatch(actionResetTooltipData(data)),
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(SwitchControl));
