import { callServer } from "../utils/request-util";
import environment from "environment";
import qs from "qs";
import { getRefreshToken } from "../utils/auth-util";

export function authorize(username, password, skipRetry) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.authOauth + "oauth2/token",
      method: "POST",
      headers: getHeaders(),
      data: qs.stringify({
        username: username,
        password: password,
        grant_type: "password",
        client_id: "D89DD832C1924B78B1F0EFADC56073F1"
      }),
      skipRetry
    }, true, true).then(response => resolve(response), error => reject(error.response));
  });
}

export function refreshAuth() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.authOauth + "oauth2/token",
      method: "POST",
      headers: getHeaders(),
      data: qs.stringify({
        client_id: "D89DD832C1924B78B1F0EFADC56073F1",
        grant_type: "refresh_token",
        refresh_token: getRefreshToken()
      })
    }, true).then(response => resolve(response), error => reject(error.response));
  });
}

function getHeaders() {
  return {
    "content-type": "application/x-www-form-urlencoded"
  };
}
