import React from "react";
import classNames from "classnames";
import {getStageByNumber} from "../../../services/restaurant.js";
import "./index.scss";

export const StagePeriods = ({stage}) => {

    return <div className={classNames("stagePeriodsTitle", `stagePeriodsTitleStage${stage}`)}>{getStageByNumber(stage)}</div>

}
