import {
  COURIER_LOCATION_COLORS,
  COURIER_LOCATION_STATE,
  CYCLE_STATE
} from "../utils/enums";

let singleton = Symbol();
let singletonEnforcer = Symbol();

let colors = {
  a: ["#4caf50", "#357a38"],
  b: ["#f44336", "#aa2f26"],
  d: ["#9c27b0", "#6d1b7b"],
  c: ["#e91e63", "#a31545"],
  e: ["#673ab7", "#482880"],
  o: ["#64dd17", "#469a10"],
  g: ["#2196f3", "#1769aa"],
  gg: ["#00BFFF", "#1bc0f7"],
  q: ["#c8ff00", "#8cb200"],
  w: ["#9e9e9e", "#6e6e6e"],
  i: ["#009986", "#006b5e"],
  s: ["#ffc107", "#b28705"],
  k: ["#00e5ff", "#00a0b2"],
  dd: ["#e040fb", "#9c2daf"],
  n: ["#00e599", "#00a06b"],
  f: ["#4862cc", "#32448e"],
  p: ["#cddc39", "#8f9a28"],
  h: ["#00bcd4", "#008394"],
  r: ["#f9e33d", "#ae9e2b"],
  l: ["#64ffda", "#46b298"],
  t: ["#bf360c", "#852608"],
  u: ["#827717", "#5b5310"],
  v: ["#774f5e", "#533742"],
  bb: ["#ccff90", "#8eb265"],
  x: ["#ff6d00", "#b24c00"],
  y: ["#607d8b", "#435761"],
  z: ["#fff200", "#b2a900"],
  aa: ["#80d8ff", "#5997b2"],
  j: ["#7d9af9", "#576bae"],
  cc: ["#f06292", "#a84466"],
  m: ["#7c4dff", "#5736b2"]
};
let colorsForState = {
  green: ["#69cc90", "#4f996c"],
  yellow: ["#ffe068", "#bfa84e"],
  red: ["#ed552b", "#b24020"],
  blue: ["#487ab8", "#365b8a"]
};
let colorKeys;
let usedColors = {};
let defaultColor = "white";
let currentColorIndex = 0;
export default class Color {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use Color.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Color(singletonEnforcer);
      colorKeys = Object.keys(colors);
      colors[defaultColor] = ["#fff", "#d8d8d8"];

      return this[singleton];
    }

    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  findMinUsesCount() {
    var min = usedColors[colorKeys[0]];
    for (var i = 1; i < colorKeys.length; i++) {
      var count = usedColors[colorKeys[i]];
      if (min > count) min = count;
    }
    return min;
  }

  isColorUsed(color) {
    return usedColors[color];
  }

  getColor() {
    var colorKey;
    var minCount = this.findMinUsesCount();

    do {
      colorKey = colorKeys[currentColorIndex++];
      if (currentColorIndex >= colorKeys.length) currentColorIndex = 0;
    } while (usedColors[colorKey] > minCount);
    usedColors[colorKey]++;
    return colorKey;
  }

  getCodesByColorKey(colorKey) {
    return colorKey
      ? colors[colorKey] || colorsForState[colorKey]
      : this.getDefaultCodes();
  }

  getCodeByColorKey(colorKey, isDefault = true) {
    var codes = this.getCodesByColorKey(colorKey);
    return codes ? codes[0] : (isDefault ? this.getDefaultCode() : null);
  }

  getDefaultCodes() {
    return this.getCodesByColorKey(defaultColor);
  }

  getDefaultCode() {
    var codes = this.getCodesByColorKey(defaultColor);
    return codes[0];
  }

  getColorForStateLocation(courier) {
    var resultColor = COURIER_LOCATION_COLORS.red;

    if(!courier) return resultColor;
    const travelData = courier.travelData || {};
    
    if (travelData.gpsDisabled) {
      return resultColor;
    }
    if ((courier.cycle || {}).state === CYCLE_STATE.started) {
      return COURIER_LOCATION_COLORS.yellow;
    }
    switch (travelData.state) {
      case COURIER_LOCATION_STATE.lost:
      case COURIER_LOCATION_STATE.standing:
        if (!travelData.stoppedAtAllowedLocation)
          resultColor = COURIER_LOCATION_COLORS.red;
        break;
      case COURIER_LOCATION_STATE.moving:
        resultColor = COURIER_LOCATION_COLORS.green;
        break;
      default:
        break;
    }

    return resultColor;
  }

  freeColor(colorKey) {
    usedColors[colorKey]--;
    if (usedColors[colorKey] < 0) usedColors[colorKey] = 0;
  }

  resetColors() {
    colorKeys.forEach(function(colorKey) {
      usedColors[colorKey] = 0;
    });
    currentColorIndex = 0;
  }

  getFontColorByBackground(bgColor) {
    const red = this.hexToRGB(bgColor).r;
    const green = this.hexToRGB(bgColor).g;
    const blue = this.hexToRGB(bgColor).b;
    const brightness = Math.sqrt((.241 * (red * red)) + (.671 * (green * green)) + (.068 * (blue * blue)));
    return brightness > 140 ? '#000000' : '#ffffff';
  }

  hexToRGB(hex) {
    // #ff00ff
    if (hex.length === 7) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return {r, g, b};
    }
    // #f0f
    const r = hex.slice(1, 2);
    const g = hex.slice(2, 3);
    const b = hex.slice(3, 4);
    return {
      r: parseInt(r + r, 16),
      g: parseInt(g + g, 16),
      b: parseInt(b + b, 16),
    };
  }
}
