import {ADD_MENU_TEMPLATE, DELETE_MENU_TEMPLATE, SET_GLOBAL_MENU_TEMPLATES, SET_MENU_TEMPLATES, UPDATE_MENU_TEMPLATE} from "./menuTemplate-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";
const initState = {
    data: {}
};
export default function menuTemplate(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA: {
            return initState;
        }
        case SET_MENU_TEMPLATES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    templates: action.payload
                }
            }
        }
        case DELETE_MENU_TEMPLATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    templates: state.data.templates.filter(tmp => tmp.id !== action.payload)
                }
            }
        }
        case ADD_MENU_TEMPLATE: {
            const oldTemplates = (state.data && state.data.templates) || [];
            return {
                ...state,
                data: {
                    ...state.data,
                    templates: [...oldTemplates, action.payload]
                }
            }
        }
        case UPDATE_MENU_TEMPLATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    templates: state.data.templates.map(el => el.id === action.payload.id ? action.payload : el)
                }
            }
        }
        case SET_GLOBAL_MENU_TEMPLATES: {
            return {
                ...state,
                data: { ...state.data, globalTemplates: action.payload }
            }
        }
        default:
            return state;
    }
}
