import React, { useRef } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./index.scss";

import { actionResetTooltipData, actionUpdateTooltipData } from "../../../store/reducers/tooltipPortal/tooltipPortal-actions";

import preloader from "../../../assets/images/preloader.GIF";

const Svg = ({   
  processing, 
  isShake, 
  useSvg, 
  useClass,
  tooltipText,
  handleOnClick,
  updateTooltipData,
  resetTooltipData
}) => {
  const hoverRef = useRef();

  return (
    <div className={classNames({ waiterShake: isShake })}>
      {!processing && (
        <svg ref={hoverRef} 
          className={classNames(useClass || "")} 
          onClick={handleOnClick}
          onMouseEnter={()=> !!tooltipText && updateTooltipData({ tooltipText, ref: hoverRef.current })} 
          onMouseLeave={e=> !!tooltipText && resetTooltipData({ event: e, context: this })}
        >
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={useSvg} />
        </svg>
      )}
      {processing && (
        <img src={preloader} alt="preloader" className="preloader" />
      )}
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateTooltipData: data => dispatch(actionUpdateTooltipData(data)),
    resetTooltipData: data=> dispatch(actionResetTooltipData(data))
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(Svg));
