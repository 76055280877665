import Store from "../store/store";
import { TYPE_STORE_DATA, DEFAULT_ORDERS_GROUPS } from "../utils/enums";

export function getStoreDataByType(type) {
  const state = Store.store.getState();
  if (!state) return [];

  switch (type) {
    case TYPE_STORE_DATA.orders:
      if (!state.order || !state.order.data) return [];
      return state.order.data.orders || [];
    case TYPE_STORE_DATA.noAssistanceOrders:
      if (!state.order || !state.order.data) return [];
      return (state.order.data.orders || []).filter(
        order => order.groupId !== DEFAULT_ORDERS_GROUPS.assistance
      );
    case TYPE_STORE_DATA.couriers:
      if (!state.userModel) return [];
      return state.userModel.data;
    case TYPE_STORE_DATA.billingInfo:
      return state.billing;
    default:
      break;
  }
}
