export const messageGalleryModel = (data) => {
    const {title, file_settings, description, type, order, file} = data || {};
    return {
        file: file || '',
        title: title || '',
        file_settings: file_settings || {
            'crop_mode': 0,
            'gravity_mode': 0,
            'quality': 0,
            'quality_mode': 0
        },
        description: description || '',
        type: type || 0,
        order: order || 0,
    };
}

export const messageGalleryServerModel = data => {
    const {title, file_settings, description, type, order} = data || {};
    return {
        title: title || '',
        file_settings: file_settings || {
            'crop_mode': 0,
            'gravity_mode': 0,
            'quality': 0,
            'quality_mode': 0
        },
        description: description || '',
        type: type || 0,
        order: order || 0,
    };
}