import * as Sentry from "@sentry/react";
import { all, put, takeEvery, call, select } from "redux-saga/effects";
import {
    addDialogsSupportChat,
    CREATE_NEW_CHAT, GET_DIALOGS_SUPPORT,
    MARK_RESOLVED_SUPPORT_DIALOG,
    PULL_SUPPORT_DIALOG, pulledSupportChat,
    saveSupportChatSession, setDialogsSupportChat,
    setShowSupportChat, setUnreadMessages,
    SUPPORT_CHAT_SAGA_INIT,
    FRESH_DESK_RESTORE_ID, SET_UNREAD_MESSAGE_SAGA, initSupportChat, AUTH_SUPPORT_CHAT,
} from '../reducers/supportChat/supprtChat-actions';
import {createAction, createSaga} from "../../utils/sagaHelper";
import {currentUserIsSupportOperatorRole} from "../../services/userModel";

import Talk from 'talkjs';
import {objectKeysToUpperLowerCase} from "../../utils/objects-util";
import {
    fetchAuthSupportChat,
    fetchDialogsSupportChat,
    pullSupportDialog,
    resolveSupportDialog, startChat,
} from '../../api/supportChat-request';
import {getErrorMessage, requestResultIsOk} from "../../utils/request-util";
import Store from "../store";
import { freshDeskRestoreId, freshDeskRestoreIdRemove } from "../../api/user-requests";
import { getUserModel } from "../selectors";
import User from "../../services/user";
import ChatService from "../../services/chatService";
import AudioService from "../../services/audio";

function* initSupportChatSaga(action) {
    yield* createSaga(function*() {
        if(currentUserIsSupportOperatorRole()) {
            const { appId, userId, signature } = action.payload;
            yield Talk.ready;

            const me = new Talk.User(userId);

            const session = new Talk.Session(({
                appId, me, signature
            }));

            session.unreads.onChange(unreads => {
                Store.store.dispatch(createAction(SET_UNREAD_MESSAGE_SAGA, {length: unreads.length}));
            });

            yield put(saveSupportChatSession(session));
            yield put(setShowSupportChat(true));
        }
    }, action.type);
}

function* setUnreadsMessageSaga(action) {
    yield* createSaga(function*() {
        const unreadsLength = yield select(state => state.supportChat.unreadMessages);
        const newLength = action.data.length;
        if(unreadsLength <= newLength) {
            AudioService.instance.playSound("newUnreadMessage");
        }
        yield put(setUnreadMessages(newLength));
    }, action.type);
}

function* authSupportChat(action) {
    yield* createSaga(function*() {
        const resultRequest = yield call(fetchAuthSupportChat);
        if(resultRequest && resultRequest.status === 200) {
            const result = objectKeysToUpperLowerCase(resultRequest.data)
            yield put(initSupportChat(result));
        } else {
            throw new Error(getErrorMessage(resultRequest));
        }
    }, action.type);
}

function* getDialogsSupportSaga(action) {
    yield* createSaga(function*() {
        const resultRequest = yield call(fetchDialogsSupportChat);
        if(resultRequest && resultRequest.status === 200) {
            const oldDialogs = yield select(state => state.supportChat.dialogs);
            const dialogs = objectKeysToUpperLowerCase(resultRequest.data);
            if(dialogs?.length >= oldDialogs?.length) {
                AudioService.instance.playSound("newDialogItem");
            }
            yield put(setDialogsSupportChat(dialogs));
        } else {
            throw new Error(getErrorMessage(resultRequest));
        }
    }, action.type);
}

function* pullSupportDialogSaga(action) {
    yield* createSaga(function*() {
        const { chatId } = action.payload;

        const resultRequest = yield call(pullSupportDialog, chatId);
        if(resultRequest && resultRequest.status === 200) {
            yield put(pulledSupportChat(chatId));
        } else {
            throw new Error(getErrorMessage(resultRequest));
        }
    }, action.type);
}

function* markResolvedSupportDialogSaga(action) {
    yield* createSaga(function*() {
        const { chatId } = action.payload;

        const resultRequest = yield call(resolveSupportDialog, chatId);
        if(resultRequest && resultRequest.status === 200) {

        } else {
            throw new Error(getErrorMessage(resultRequest));
        }
    }, action.type);
}

function* startDepotChatSaga(action) {
    yield* createSaga(function*() {
        const {id, subject} = action.payload;
        const body = {
          Subject: subject,
        };

        const resultRequest = yield call(startChat, id, body);

        if(resultRequest && resultRequest && resultRequest.status === 200) {
            if(resultRequest.data) {
                yield put(addDialogsSupportChat(objectKeysToUpperLowerCase(resultRequest.data)));
            }
        } else {
            throw new Error(getErrorMessage(resultRequest));
        }
    });
}


//FreshDesk section
function* freshDeskRestoreIdSaga(action) {
    yield* createSaga(function*() {
        const { userId, restoreId, config } = action.data;
        if(userId && restoreId) {
            const users = yield select(getUserModel);
            const usersWithRestoreIdList = users.filter(user=> user.supportChatUserId === restoreId);            
            
            if(usersWithRestoreIdList.length) {
                const otherUsersWithRestoreId = usersWithRestoreIdList.filter(f=> f.userId !== userId);
                
                //There are other users with this restoreId
                if(otherUsersWithRestoreId.length) {
                    Sentry.captureException("There are other users with this restoreId: " + usersWithRestoreIdList.map(m=> m.userId))
                    const apiCalls = usersWithRestoreIdList.map(user=> call(freshDeskRestoreIdRemove, user.userId));
                    yield all(apiCalls);
                }
            } else {
                const resultRequest = yield call(freshDeskRestoreId, userId, restoreId);
                if(requestResultIsOk(resultRequest)) {
                    const data = objectKeysToUpperLowerCase(resultRequest.data);
                    User.instance.setCurrentUserInfo(data, true);
                    ChatService.instance.setWidgetUserProperties({ ...config, restoreId: data.supportChatUserId });
                } else {
                    ChatService.instance.setWidgetUserProperties({ ...config });                    
                } 
            }
            
        }        
    }, action.type);
}

export default function*() {
    yield takeEvery(SUPPORT_CHAT_SAGA_INIT, initSupportChatSaga);
    yield takeEvery(GET_DIALOGS_SUPPORT, getDialogsSupportSaga);
    yield takeEvery(AUTH_SUPPORT_CHAT, authSupportChat);
    yield takeEvery(PULL_SUPPORT_DIALOG, pullSupportDialogSaga);
    yield takeEvery(MARK_RESOLVED_SUPPORT_DIALOG, markResolvedSupportDialogSaga);
    yield takeEvery(CREATE_NEW_CHAT, startDepotChatSaga);
    yield takeEvery(FRESH_DESK_RESTORE_ID, freshDeskRestoreIdSaga);    
    yield takeEvery(SET_UNREAD_MESSAGE_SAGA, setUnreadsMessageSaga);
}