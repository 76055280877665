import environment from "./base";

const authOauth = "https://api.delivapp.com/auth/";
const authAPI = "https://api.delivapp.com/auth/api/";
const backendAPI = "https://api.delivapp.com/rest/api/";
const pusherAuthEndpoint = "https://api.delivapp.com/auth/api/pusher";
const qrserverAPI = "http://api.qrserver.com/v1/create-qr-code/";

const env = environment({
  authOauth,
  authAPI,
  backendAPI,
  pusherAuthEndpoint,
  qrserverAPI
});

export default {
  ...env,
  requestLocationTime: 30000,
  requestPendingCount: 30000,
  requestPendingOrdersTime: 30000,
  requestCustomerServiceOrders: 30000,
};
