import {DELIVERY_STRATEGY, MODEL_TYPE} from "../utils/enums.js";

export const editCustomerOrderModel = (infoData) => {
    return {
        orderInfo: infoData,
        products: infoData.products,
        deliveryType: infoData.delivery_type,
        deliveryStrategy: infoData.delivery_strategy,
        courierTip: infoData.driver_tip,
        territory: infoData.entity_id,
        cutlery: infoData.cutlery,
        comment: infoData.comment,
        address: {
            location: {
                longitude: infoData.location && infoData.location.lng,
                latitude: infoData.location && infoData.location.lat,
            },
            city: infoData.city,
            address: infoData.address,
            address_notes: infoData.address_notes,
            flat: infoData.flat,
            storey: infoData.storey,
            modelType: MODEL_TYPE.order,
        },
        menu: infoData.menu || null,
        deliveryTime: infoData.delivery_strategy === DELIVERY_STRATEGY.asap ? null : infoData.delivery_datetime,
        giftOption: infoData.gift_option ? {
            isGift: true,
            name: infoData.gift_option.full_name,
            greeting: infoData.gift_option.greeting,
            phone: infoData.gift_option.phone,
        } : null,
        enabledPayments: [],
        payments: [],
        coupon: infoData.coupon || "",
    }
}