export function categoryModel(category) {
    const data = category || {};

    return {
        enabled: data.enabled || false,
        id: data.id || "",
        image: data.image,
        image_settings: data.image_settings,
        name: data.name || "",
        description: data.description || "",
        order: data.order || 0,
        products: data.products || [],
        rank: data.rank || 0
    };
}

export function categoryModelForOverride(data) {
    const model = data || {};

    return {
        id: model.id || "",
        name: model.name || "",
        description: model.description || "",
    }
}

export function categoryModelOverride(data) {
    const model = data || {};
    const overridesProperty = [];

    if(model && model.overrides) {
        model.overrides.forEach(ov => {
            if(ov.id === model.id) {
                model[ov.name] = ov.value;
                overridesProperty.push(ov.name);
            }
        })
    }

    return {
        ...categoryModelForOverride(model),
        overridesProperty: overridesProperty,
    }
}
