// The basics
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ToastContainer } from "react-toastify";
import MediaQuery from "react-responsive";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
import "./app.scss";
import "react-toastify/dist/ReactToastify.css";

import InitService from "../services/initService";
import mainFunction from "../utils/extend";
import { createAction } from "../utils/sagaHelper";
import { IsInitAllowed } from "../utils/routesHelper";
import { excludedUrls } from "../utils/constants";
import { INIT_START } from "../store/reducers/init/init-actions";
import { CHECK_USER_AUTHENTICATION } from "../store/reducers/authentication/authentication-actions";
import { GET_INITIAL_DATA } from "../store/reducers/common-actions";

import Header from "./components/Header";
import AppContent from "./components/AppContent";
import DraggableWrapper from "./components/DraggableWrapper";
import PortalMap from "./components/PortalMap/PortalMap";
import TooltipPortal from "./components/TooltipPortal";
import GlobalPopupMessagePortal from "./components/GlobalPopupMessagePortal";
import IFrameControlPortal from "./components/IFrameControlPortal";
import DndPreviewControl from "./components/DndPreviewControl";
import { getAccessToken } from '../utils/auth-util';
import { GET_DIALOGS_SUPPORT } from '../store/reducers/supportChat/supprtChat-actions.js';
import {TranslationUIProvider} from "delivapp-ordering";
import translationLib from "../utils/translationLib.js";
import i18n from "../i18n.js";
import moment from "moment/moment.js";

const App = ({ history, checkUserAuthentication, initStart, getInitialData, getDialogs }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isAccessTokenExist, setIsAccessTokenExist] = useState(false)
  const [intervalState, setIntervalState] = useState(0)
  
  const getShowMenu = () => !excludedUrls.includes(history.location.pathname);

  const HTML5toTouch = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: {
          enableMouseEvents: true,
          ignoreContextMenu: true,
          delayTouchStart: 300
        },
        preview: true,
        transition: TouchTransition,
        skipDispatchOnTransition: true
      },
    ],
  }

  useEffect(() => {
    let interval = 0
    if(!getAccessToken() && history.location.pathname !== "/login") {
      interval = setInterval(() => {
        setIsAccessTokenExist(!!getAccessToken())
      }, 1000)
      setIntervalState(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    moment.locale(i18n.language, {
      relativeTime : {
        future : i18n.t('time.future'),
        past : i18n.t('time.past'),
        s : i18n.t('time.s'),
        m : i18n.t('time.m'),
        mm : i18n.t('time.mm'),
        h : i18n.t('time.h'),
        hh : i18n.t('time.hh'),
        d : i18n.t('time.d'),
        dd : i18n.t('time.dd'),
        M : i18n.t('time.M'),
        MM : i18n.t('time.MM'),
        y : i18n.t('time.y'),
        yy : i18n.t('time.yy')
      },
    })
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(()=> {
    mainFunction();
    checkUserAuthentication()
      .then(() => {
        InitService.instance.init();
        if (IsInitAllowed(history.location.pathname)) {
          initStart().then(() => {
            clearInterval(intervalState)
            setIsChecked(true);
            getInitialData();

            if(history.location.pathname === "/login") {
              history.push("/");
            }
          }).then(() => {
            getDialogs()
          });
        } else {
          setIsChecked(true);
        }
      })
      .catch(() => {
        setIsChecked(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, checkUserAuthentication, initStart, getInitialData, isAccessTokenExist]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
          <button onClick={() => resetError()}>Reset error</button>
        </React.Fragment>
      )}
    >
      <TranslationUIProvider value={translationLib()}>
        <MediaQuery maxWidth={900}>
          {matches => (
              <DndProvider options={HTML5toTouch}>
                <div id="app">
                  <ToastContainer
                      hideProgressBar={true}
                      toastClassName="toast"
                      autoClose={3000}
                  />
                  <DraggableWrapper />
                  {isChecked && <Header showMenu={getShowMenu()} />}
                  {isChecked && <AppContent matches={matches} />}
                  <DndPreviewControl />
                  <div style={{ height: "0", width: "0", overflow: "hidden" }}>
                    <PortalMap />
                    <TooltipPortal />
                    <GlobalPopupMessagePortal />
                    <IFrameControlPortal />
                  </div>
                </div>
              </DndProvider>
          )}
        </MediaQuery>
      </TranslationUIProvider>
    </Sentry.ErrorBoundary>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    initStart: data => dispatch(createAction(INIT_START, data)),
    checkUserAuthentication: data => dispatch(createAction(CHECK_USER_AUTHENTICATION, data)),
    getInitialData: ()=> dispatch(createAction(GET_INITIAL_DATA, {})),
    getDialogs: () => dispatch(createAction(GET_DIALOGS_SUPPORT, {}))
  };
};

export default Sentry.withProfiler(withRouter(connect(null, mapDispatchToProps)(App)));
