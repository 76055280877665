import React from "react";
import Waiter from "../Waiter/Waiter";
import {WAITER_TYPES} from "../../../utils/enums";

import "./style.scss";
import classnames from "classnames";

const TextWithTooltip = ({text, tooltip, width, inOneString}) => {
    return (
        <div style={{width: width || "100%"}} className={"wrapTextWithTooltip"}>
            <Waiter
                useClass={classnames("textWithTooltip", {inOneString})}
                type={WAITER_TYPES.span}
                spanText={text}
                tooltipText={tooltip}
            />
        </div>
    )
};

export default TextWithTooltip;