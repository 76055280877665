export const cardServerModel = (model = {}) => {
    const card = {
        id: model?.id || "",
        cardMask: model?.cardMask  || "",
        cardholderName: model?.cardholderName || "",
        expiry: model?.expiry || "",
        token: model?.token || "",
        type: model?.type || "",
        default: model?.default || false,
        provider: model?.provider || null,
    }

    if(!card.id) {
        delete card.id;
    }

    return card;
}