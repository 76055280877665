export function marketplaceModel(data) {
    const {
        enabled, tag, title, secondary_title, message, list_type, type, business_id, banner, banner_settings, language, product_id, label, action_button_title
    } = data || {};

    return {
        enabled: enabled,
        tag: tag || "",
        title: title || "",
        label: label || "",
        secondary_title: secondary_title || "",
        message: message || "",
        list_type: list_type || 0,
        type: type || 0,
        business_id: business_id || "",
        banner: banner || null,
        banner_settings: banner_settings || null,
        language: language || "",
        product_id: product_id || null,
        action_button_title: action_button_title || "",
    }
}
