import React from "react";
import "./index.scss";
import {getFleetProducerType} from "../../../services/restaurant.js";
import IconButton from "../IconButton/IconButton.jsx";

export const FleetProducerType = (producerId) => {
    const producer = getFleetProducerType();

    if(producer && producer[producerId]){
        return <div className={'fleetProducerTypeBlock'}>
            <IconButton isIcon={true} iconName={`${producer[producerId].icon}`} />
            <p>{producer[producerId].value}</p>

        </div>

    }

    return <></>;
}
