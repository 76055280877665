import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import {
    BASIC_PATH,
    BILLING_PATHS,
    MARKETPLACE_SETTINGS_MODEL,
    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
    ORDERING_MENU_TYPE_SECTION_MODEL, RECONCILIATIONS_MODEL, SHIFTS_MODEL,
    TYPE_SECTION_DEPOT_MODEL,
    TYPE_SECTION_MENUTEMPLATE_MODEL,
    TYPE_SECTION_MODEL,
} from '../../utils/enums';
import { getHistoryLocationParams } from '../../utils/routesHelper';

const PrivateRoute = ({ component: Component, ...args }) => {
  return (
    <Route
      {...args}
      render={props =>
        args.isAuthenticated ? (
          <Component {...args} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

class Pages extends Component {
  loginpage = () =>
    Loadable({
      loader: () => import("../../app/pages/loginpage"),
      loading: () => null,
      modules: ["loginpage"]
    });

  dashboardpage = () =>
    Loadable({
      loader: () => import("../../app/pages/dashboardpage"),
      loading: () => null,
      modules: ["dashboardpage"]
    });

  billingpage = () =>
      Loadable({
         loader: () => import("../../app/pages/billingpage"),
         loading: () => null,
         modules: ["billingpage"]
      });

  neworderpage = () =>
    Loadable({
      loader: () => import("../../app/pages/neworderpage"),
      loading: () => null,
      modules: ["neworderpage"]
    });

  createorder = () =>
    Loadable({
      loader: () => import("../../app/pages/createorderpage"),
      loading: () => null,
      modules: ["createorderpage"]
    });

  editorderpage = () =>
    Loadable({
      loader: () => import("../../app/pages/editorderpage"),
      loading: () => null,
      modules: ["editorderpage"]
    });

  editcustomerorderpage = () =>
    Loadable({
      loader: () => import("../../app/pages/editcustomerorderpage"),
      loading: () => null,
      modules: ["editcustomerorder"]
    });

  orderspage = () =>
    Loadable({
      loader: () => import("../../app/pages/orderspage"),
      loading: () => null,
      modules: ["orderspage"]
    });
  assistancepage = () =>
    Loadable({
      loader: () => import("../../app/pages/assistancepage"),
      loading: () => null,
      modules: ["assistancepage"]
    });
  settingspage = () =>
    Loadable({
      loader: () => import("../../app/pages/settingspage"),
      loading: () => null,
      modules: ["settingspage"]
    });
  shiftspage = () =>
    Loadable({
      loader: () => import("../../app/pages/shiftspage"),
      loading: () => null,
      modules: ["shiftspage"]
    });
    marketplacesettingspage = () =>
        Loadable({
            loader: () => import("../../app/pages/marketplacesettingspage"),
            loading: () => null,
            modules: ["marketplacesettingspage"]
        });
    reconciliationpage = () =>
        Loadable({
            loader: () => import("../../app/pages/reconciliationpage"),
            loading: () => null,
            modules: ["reconciliationpage"]
        });
  mobileSettingspage = () =>
    Loadable({
      loader: () => import("../../app/pages/mobileSettingspage"),
      loading: () => null,
      modules: ["mobileSettingspage"]
    });
  mobileMarketplacepage = () =>
    Loadable({
      loader: () => import("../../app/pages/mobileMarketplacepage"),
      loading: () => null,
      modules: ["mobileMarketplacepage"]
    });
  reportpage = () =>
    Loadable({
      loader: () => import("../../app/pages/reportpage"),
      loading: () => null,
      modules: ["reportpage"]
    });
  adminorderspage = () =>
    Loadable({
      loader: () => import("../../app/pages/adminorderspage"),
      loading: () => null,
      modules: ["adminorderspage"]
    });

  // orderingmenupage = () =>
  //   Loadable({
  //     loader: () => import("../../app/pages/orderingmenupage"),
  //     loading: () => null,
  //     modules: ["orderingmenupage"]
  //   });
  infopage = () =>
    Loadable({
      loader: () => import("../../app/pages/infopage"),
      loading: () => null,
      modules: ["infopage"]
    });
  squareauthpage = () =>
    Loadable({
      loader: () => import("../../app/pages/squareauthpage"),
      loading: () => null,
      modules: ["squareauthpage"]
    });

  getRedirect = path => {
    const { sectionModels, prevModelType } = this.props;
    let pathname = "";
    let search = "";
    switch (path) {
      case "/settings":
        const typeSectionModel =
          sectionModels[prevModelType.TYPE_SECTION_MODEL];
        const id =
          typeSectionModel.model &&
          sectionModels[typeSectionModel.model.parentUrl] &&
          sectionModels[typeSectionModel.model.parentUrl].id;

        pathname = prevModelType.TYPE_SECTION_MODEL;
        search = id && id !== typeSectionModel.id
          ? `?parentId=${id}${(typeSectionModel.id &&
              `&id=${typeSectionModel.id}`) ||
              ""}`
          : `${(typeSectionModel.id && `?id=${typeSectionModel.id}`) || ""}`;
        break;
      case "/orderingmenu":
      case ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit:
        pathname = ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu;
        search =
          "?id=" + getHistoryLocationParams(window.location.search)["id"];
        break;
      case "/marketplace-settings":
        const typeSectionModelPrev =
          sectionModels[prevModelType.MARKETPLACE_SETTINGS_MODEL];
        const idFromModel =
          typeSectionModelPrev.model &&
          sectionModels[typeSectionModelPrev.model.parentUrl] &&
          sectionModels[typeSectionModelPrev.model.parentUrl].id;

        pathname = prevModelType.MARKETPLACE_SETTINGS_MODEL;
        search = id && id !== typeSectionModelPrev.id
          ? `?parentId=${idFromModel}${(typeSectionModelPrev.id &&
              `&id=${typeSectionModelPrev.id}`) ||
              ""}`
          : `${(typeSectionModelPrev.id && `?id=${typeSectionModelPrev.id}`) ||
              ""}`;
        break;
      default:
        break;
    }

    return <Redirect to={{ pathname, search }} />;
  };

  render() {
    const { isAuthenticated, ...otherProps } = this.props;

    return (
      <Switch>
        <PrivateRoute
          component={this.dashboardpage()}
          exact
          path="/"
          isAuthenticated={isAuthenticated}
          {...otherProps}
        />
        <PrivateRoute
          component={this.dashboardpage()}
          exact
          path="/dashboard"
          isAuthenticated={isAuthenticated}
          {...otherProps}
        />
          {Object.keys(BILLING_PATHS).map(key => (
              <PrivateRoute key={`PrivateRoute-${key}`} path={BILLING_PATHS[key]} isAuthenticated={isAuthenticated}
                            component={this.billingpage()}
              />
          ))}
        <PrivateRoute
          component={this.neworderpage()}
          exact
          path="/new-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.createorder()}
          exact
          path="/create-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.editorderpage()}
          path="/edit-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.editcustomerorderpage()}
          path="/edit-customer-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.assistancepage()}
          path="/assistance"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.orderspage()}
          path="/orders"
          isAuthenticated={isAuthenticated}
        />
        <Route
          exact
          path="/settings"
          component={() => this.getRedirect("/settings")}
        />
        <PrivateRoute
          path={"/settings/list"}
          isAuthenticated={isAuthenticated}
          component={this.mobileSettingspage()}
        />
        {Object.keys(TYPE_SECTION_MODEL).map(key => (
          <PrivateRoute
            key={`PrivateRoute-${key}`}
            path={TYPE_SECTION_MODEL[key]}
            isAuthenticated={isAuthenticated}
            component={this.settingspage()}
          />
        ))}
        {Object.keys(TYPE_SECTION_DEPOT_MODEL).map(key => (
          <PrivateRoute
            key={`PrivateRoute-${key}`}
            path={TYPE_SECTION_DEPOT_MODEL[key]}
            isAuthenticated={isAuthenticated}
            component={this.settingspage()}
          />
        ))}
        {Object.keys(ORDERING_MENU_TYPE_SECTION_MODEL).map(key => (
          <PrivateRoute
            key={`PrivateRoute-${key}`}
            path={ORDERING_MENU_TYPE_SECTION_MODEL[key]}
            isAuthenticated={isAuthenticated}
            component={this.settingspage()}
          />
        ))}
        <Route
            exact
            path="/shifts"
            component={() => this.getRedirect("/shifts")}
        />
          {Object.keys(SHIFTS_MODEL).map(key => (
              <PrivateRoute
                  key={`PrivateRoute-${key}`}
                  path={SHIFTS_MODEL[key]}
                  isAuthenticated={isAuthenticated}
                  component={this.shiftspage()}
              />
          ))}
          <Route
          exact
          path="/marketplace-settings"
          component={() => this.getRedirect("/marketplace-settings")}
        />
        <PrivateRoute
          path={"/marketplace-settings/list"}
          isAuthenticated={isAuthenticated}
          component={this.mobileMarketplacepage()}
        />
        {Object.keys(MARKETPLACE_SETTINGS_MODEL).map(key => (
          <PrivateRoute
            key={`PrivateRoute-${key}`}
            path={MARKETPLACE_SETTINGS_MODEL[key]}
            isAuthenticated={isAuthenticated}
            component={this.marketplacesettingspage()}
          />
        ))}
        {Object.keys(TYPE_SECTION_MENUTEMPLATE_MODEL).map(key => (
          <PrivateRoute
            key={`PrivateRoute-${key}`}
            path={TYPE_SECTION_MENUTEMPLATE_MODEL[key]}
            isAuthenticated={isAuthenticated}
            component={this.marketplacesettingspage()}
          />
        ))}
        {Object.keys(ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL).map(key => (
          <PrivateRoute
            key={`PrivateRoute-${key}`}
            path={ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL[key]}
            isAuthenticated={isAuthenticated}
            component={this.marketplacesettingspage()}
          />
        ))}
        <PrivateRoute
          component={this.reportpage()}
          path="/reports"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.adminorderspage()}
          path={BASIC_PATH.adminOrders}
          isAuthenticated={isAuthenticated}
        />
        <Route
          exact
          path="/orderingmenu"
          component={() => this.getRedirect("/orderingmenu")}
        />
        <PrivateRoute
          path="/info"
          isAuthenticated={isAuthenticated}
          component={this.infopage()}
        />
          <Route
              exact
              path="/reconciliations"
              component={() => this.getRedirect("/reconciliations")}
          />
          {Object.keys(RECONCILIATIONS_MODEL).map(key => (
              <PrivateRoute
                  key={`PrivateRoute-${key}`}
                  path={RECONCILIATIONS_MODEL[key]}
                  isAuthenticated={isAuthenticated}
                  component={this.reconciliationpage()}
              />
          ))}
        <Route path="/square/oauth" component={this.squareauthpage()} />
        <Route exact path="/login" component={this.loginpage()} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authentication.isAuthenticated,
  sectionModels: state.sectionModel.sectionModels,
  prevModelType: state.sectionModel.prevModelType
});

export default connect(mapStateToProps)(Pages);
