import React from 'react';
import classNames from 'classnames';
import { ModalPopup } from '../ModalPopup/ModalPopup';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserModel } from '../../../store/selectors';
import CurrentShiftDepotAssignSelect from '../CurrentShiftDepotAssignSelect';

const CurrentShiftDepotAssignPopup = ({ popupOpen, handleOnPopupClose, onClose, depotCouriers }) => {
  const couriers = useSelector(getUserModel).filter(user => user.cycle);

  const onCloseModal = selectedCouriers => {
    return handleOnPopupClose({
      courierIds: selectedCouriers?.map(c => c.userId) || [],
    });
  };

  return (
    <ModalPopup
      isOpen={popupOpen}
      width={'450px'}
      onClose={onCloseModal}
      content={
        <div className={classNames('currentShiftDepotAssignPopup')}>
          <CurrentShiftDepotAssignSelect
            selectedCouriers={depotCouriers || []}
            couriers={couriers}
            onConfirm={onCloseModal}
            onCancel={onClose}
          />
        </div>
      }
    />
  );
};

export default withTranslation()(CurrentShiftDepotAssignPopup);
