import { GET, UPDATE } from "../common-actions";

export const EXTERNAL_CYCLES = "EXTERNAL_CYCLES";
export const EXTERNAL_CYCLES_GET = EXTERNAL_CYCLES + GET;
export const EXTERNAL_CYCLES_UPDATE = EXTERNAL_CYCLES + UPDATE;
export const EXTERNAL_CYCLES_UPDATE_LIST = EXTERNAL_CYCLES_UPDATE + "_LIST";
export const EXTERNAL_CYCLES_UPDATE_FROM_COURIER_LOCATIONS = EXTERNAL_CYCLES + "_UPDATE_FROM_COURIER_LOCATIONS";
export const EXTERNAL_CYCLE_UPDATE = "EXTERNAL_CYCLE_UPDATE";
export const EXTERNAL_CYCLE_DISBAND = "EXTERNAL_CYCLE_DISBAND";
export const EXTERNAL_CYCLE_REMOVE = "EXTERNAL_CYCLE_REMOVE";
export const EXTERNAL_CYCLE_CONFIRM = "EXTERNAL_CYCLE_CONFIRM";
export const ASSOCIATE_ORDER_TO_EXTERNAL_CYCLE = "ASSOCIATE_ORDER_TO_EXTERNAL_CYCLE";
export const ADD_ORDER_TO_EXTERNAL_CYCLE = "ADD_ORDER_TO_EXTERNAL_CYCLE";
export const DISBAND_ORDER_FROM_EXTERNAL_CYCLE = "DISBAND_ORDER_FROM_EXTERNAL_CYCLE";
export const EXTRACT_EXTERNAL_CYCLE_DATA = "EXTRACT_EXTERNAL_CYCLE_DATA";
export const PUSHER_EXTERNAL_CYCLE_CREATED = "PUSHER_EXTERNAL_CYCLE_CREATED";
export const PUSHER_EXTERNAL_CYCLE_UPDATED = "PUSHER_EXTERNAL_CYCLE_UPDATED";
export const PUSHER_EXTERNAL_CYCLE_FINISHED = "PUSHER_EXTERNAL_CYCLE_FINISHED";

export function actionExternalCyclesGet(data) {
  return { type: EXTERNAL_CYCLES_GET, data, additionalParams: { batched: true } };
}

export function actionExternalCyclesUpdate(data) {
  return { type: EXTERNAL_CYCLES_UPDATE, data, additionalParams: { batched: true } };
}

export function actionExternalCyclesUpdateList(data) {
  return { type: EXTERNAL_CYCLES_UPDATE_LIST, data, additionalParams: { batched: true } };
}

export function actionExternalCycleUpdate(data) {
  return { type: EXTERNAL_CYCLE_UPDATE, data, additionalParams: { batched: true } };
}

export function actionAssociateOrderToExternalCycle(data) {
  return { type: ASSOCIATE_ORDER_TO_EXTERNAL_CYCLE, data };
}

export function actionDisbandOrderFromExternalCycle(data) {
  return { type: DISBAND_ORDER_FROM_EXTERNAL_CYCLE, data };
} 

export function actionExtractExternalCycleData(data) {
  return { type: EXTRACT_EXTERNAL_CYCLE_DATA, data };
}

export function actionExternalCycleUpdateFromCourierLocations(data) {
  return { type: EXTERNAL_CYCLES_UPDATE_FROM_COURIER_LOCATIONS, data };
}

export function actionExternalCycleRemove(data) {
  return { type: EXTERNAL_CYCLE_REMOVE, data };
}

export function actionExternalCycleConfirm(data) {
  return { type: EXTERNAL_CYCLE_CONFIRM, data };
}

export function actionExternalCycleDisband(data) {
  return { type: EXTERNAL_CYCLE_DISBAND, data };
}
