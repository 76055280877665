import { put, takeEvery, select, call } from "redux-saga/effects";
import {
  ORDEREDIT_SET_NEW_ORDER,
  ORDEREDIT_SET_ORDER,
  ORDEREDIT_UPDATE,
  ORDEREDIT_ORIGINAL_ORDER_UPDATE,
  actionOrderEditUpdate,
  actionOrderEditSetOrder,
  setOrderEditSummary,
  ORDEREDIT_GET_SUMMARY_ORDER,
  CHECK_MENU_CREATE_ORDER,
  ORDER_CYCLE_REORDER
} from "../reducers/orderEdit/orderEdit-actions";
import { getDepot } from "../../services/restaurant";
import { getInitOrderModel } from "../../services/order";
import { minutesToTimeRelative } from "../../utils/convertTime";
import { getOrderEdit, getRestaurant, getRestaurantBusinessMenu } from "../selectors";
import {getOrderPrice} from "../../api/order-requests";
import {orderPriceServerModel} from "../../models/orderPriceServerModel";
import { getErrorMessage, requestResultIsOk } from "../../utils/request-util";
import { objectKeysToUpperLowerCase } from "../../utils/objects-util";
import { createSaga, ErrorData } from "../../utils/sagaHelper";
import { SET_MENU_CREATE_ORDER } from "../reducers/restaurant/restaurant-actions";
import {ORDER_TYPE, SOURCE_TYPE} from "../../utils/enums";
import i18n from "../../i18n";
import {fetchExternalOrderReorder} from "../../api/user-requests";
import {actionExternalCycleUpdate} from "../reducers/externalCycles/externalCycles-actions";
import {getOrderModel} from "../../models/orderModel.js";

function* setNewOrderEditSaga(action) {
  try {
    const restaurant = yield select(getRestaurant);
    const firstDepot = getDepot(restaurant.depots, null, true);
    const firstDepotCity = firstDepot.address && firstDepot.address.address && firstDepot.address.address.city;
    const serviceCities = (restaurant && restaurant.configuration && restaurant.configuration.serviceCities) || [];      
    const order = action.data
      ? action.data
      : {
          ...getInitOrderModel(),
          readinessTime: minutesToTimeRelative(firstDepot.maximumArrivalTime),
          address: {
            ...getInitOrderModel().address,
            city: (firstDepotCity &&
              (serviceCities.find(f=> f.replace(/\s/g, '') === firstDepotCity.replace(/\s/g, '')) || firstDepotCity)) || serviceCities[0]
          },
          depotId: firstDepot.id
        };

    yield put(actionOrderEditUpdate({ isRemove: true }));
    yield put(actionOrderEditSetOrder(order));
  } catch (error) {
    console.log(error);
  }
}

function* setOrderEditSaga(action) {
  try {
    const order = action.data?.order_source === SOURCE_TYPE.logistic ? getOrderModel(action.data) : action.data;
    yield put({ type: ORDEREDIT_UPDATE, data: order });
    yield put({ type: ORDEREDIT_ORIGINAL_ORDER_UPDATE, data: order });
  } catch (error) {
    console.log(error);
  }
}

function* getSummaryOrderSaga(action) {
  yield* createSaga(function*() {
    const requestResult = yield call(getOrderPrice, orderPriceServerModel(action.data));
    if(requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(setOrderEditSummary(data));
    } else {
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}

function* orderCycleReorderSaga(action) {
  yield* createSaga(function*() {
    const { group, reorderedItem, index } = action.data;
    const requestResult = yield call(fetchExternalOrderReorder, {
      Id: group.id,
      OrderId: reorderedItem.id,
      Index: index,
    });

    if(requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(actionExternalCycleUpdate(data));
    } else {
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}

function* checkMenuOrderCreateSaga(action) {
  yield* createSaga(function*() {
    yield put({ type: SET_MENU_CREATE_ORDER, data: action.data });

    const businessMenu = yield select(getRestaurantBusinessMenu);
    let orderEdit = yield select(getOrderEdit);
    let newOrder = (orderEdit || {}).newOrder || {};
    
    const requestResult = yield call(getOrderPrice, orderPriceServerModel({ 
      ...action.data, 
      id: newOrder.id || businessMenu.id, 
      products: newOrder.products || [], 
      coupon: newOrder.coupon || "", 
      driverTip: newOrder.driverTip || 0 
    }));

    if(requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(setOrderEditSummary(data));
      
      switch (newOrder.type) {
        case ORDER_TYPE.delivery:
          if(!data.delivery_allowed) 
            return new ErrorData(i18n.t("create-order.summary.DELIVERY_NOT_ALLOWED"));
          break;
        case ORDER_TYPE.takeAway:
          if(!data.takeaway_allowed) 
            return new ErrorData(i18n.t("create-order.summary.TAKEAWAY_NOT_ALLOWED"));
          break;     
        default:
          break;
      }
    } else {
      return new ErrorData(getErrorMessage(requestResult));
    }    
  }, action.type);
}

export default function*() {
  yield takeEvery(ORDEREDIT_SET_NEW_ORDER, setNewOrderEditSaga);
  yield takeEvery(ORDER_CYCLE_REORDER, orderCycleReorderSaga);
  yield takeEvery(ORDEREDIT_SET_ORDER, setOrderEditSaga);
  yield takeEvery(ORDEREDIT_GET_SUMMARY_ORDER, getSummaryOrderSaga);
  yield takeEvery(CHECK_MENU_CREATE_ORDER, checkMenuOrderCreateSaga);
}
