import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';
import classNames from 'classnames';
import './index.scss';

import Dropdown from '../Dropdown';
import IconButton from '../IconButton';
import Waiter from '../Waiter/Waiter';
import { WAITER_TYPES } from '../../../utils/enums';
import ControlCenterSearchSelect from '../ControlCenterSearchSelect/ControlCenterSearchSelect';

const SearchBox = ({
  t,
  useValue,
  searchShortCutValue,
  debounce,
  placeholder,
  isSearchByEnter,
  isShowControlCenterSearch,
  shortCuts,
  searchShortCuts,
  onChange,
  onSearchShortCutChange,
  isHideBorder = false,
}) => {
  const [searchString, setSearchString] = useState(useValue || '');
  const [searchShortCut, setSearchShortCut] = useState(searchShortCutValue || '');
  const [loading, setLoading] = useState(false);

  const handleOnSearchChange = (value, isClear) => {
    setLoading(false);
    setSearchString(value);
    if (!isSearchByEnter || isClear) onChange(value);
  };

  const handleOnSearchShortCutChange = value => {
    return new Promise(resolve => {
      resolve();
      setSearchShortCut(value);
      onSearchShortCutChange && onSearchShortCutChange(value);
    });
  };

  const handleOnKeyDown = e => {
    setLoading(true);

    if (e.keyCode === 13) {
      e.preventDefault();
      onChange(searchString);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchString !== useValue) {
      setSearchString(useValue);
    }
  }, [useValue, searchString]);

  const showSearchInputShortcuts = searchShortCuts && searchShortCuts.length > 0;

  return (
    <div className={classNames('searchBox')}>
      <div
        className={classNames('searchBoxContainer', { full: !showSearchInputShortcuts || !isShowControlCenterSearch })}
      >
        <div
          className={classNames('searchBoxInputContainer', { full: !!isShowControlCenterSearch })}
          style={{ border: isHideBorder ? 'none' : '' }}
        >
          {loading && <Waiter inProcess type={WAITER_TYPES.buttonDiv} />}
          {!loading && <div className={classNames('searchBoxIcon')} onClick={() => onChange(searchString)} />}
          <form autoComplete='off'>
            <DebounceInput
              debounceTimeout={debounce || 200}
              type='text'
              autoComplete='new-password'
              list='autocompleteOff'
              value={searchString || ''}
              placeholder={placeholder}
              className={classNames('searchBoxInput')}
              onKeyDown={handleOnKeyDown}
              onChange={e => handleOnSearchChange(e.target.value)}
            />
          </form>
          {searchString && <div className={classNames('clearSearch')} onClick={() => handleOnSearchChange('', true)} />}
        </div>
        {showSearchInputShortcuts && (
          <div className={classNames('filters')}>
            <Dropdown
              value={searchShortCut && searchShortCuts.find(f => f.key === searchShortCut)['value']}
              values={searchShortCuts}
              onSelect={item => handleOnSearchShortCutChange(item.key)}
              onRemove={() => handleOnSearchShortCutChange(null)}
            />
            <IconButton
              isIcon={true}
              iconName='clearSearch'
              tooltipText={t('basic.CANCEL_BUTTON')}
              onClick={() => handleOnSearchShortCutChange(null)}
            />
          </div>
        )}
        {isShowControlCenterSearch && (
          <ControlCenterSearchSelect onSelect={controlCenterId => handleOnSearchShortCutChange(controlCenterId)} />
        )}
      </div>
      {(shortCuts || []).length > 0 && (
        <div className={classNames('searchBoxFilters')}>
          {shortCuts.map(shortCut => (
            <div
              key={`searchBoxFilter-${shortCut}`}
              className={classNames('filterItem', { selected: shortCut === searchString })}
              onClick={() => handleOnSearchChange(shortCut === searchString ? '' : shortCut)}
            >
              {shortCut}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SearchBox);
