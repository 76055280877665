import React from "react";
import i18n from "../../i18n";

import {
  checkForControlCenter,
  getDepotFilter,
  getDepotUsersSectionFilter,
  getShiftsFilter,
  getShiftsHistoryFilter,
  getUsersFilteredByDepotId,
  sortUsers,
} from '../filter';
import {getFullName, getTimePerDay, isRTL, isShowControlCenterSearch} from "../userModel";
import {getInitModel} from "../initModels";
import {getAmountForType, getDeliveredDate, getFormattedDeliveryDuration} from "../order";
import {createControlModel} from "../controlModelFactory";

import SectionModel from "../../models/sectionModel";
import FilterOptionsModel, {FILTER_OPTION_TYPE} from '../../models/filterOptionsModel';

import {
  USER_CURRENT_SHIFTS,
  USER_SHIFT_COURIERS_CSV,
  USER_SHIFT_DOWNLOAD_PDF,
  USER_SHIFT_HISTORY,
  USER_SHIFTS,
} from '../../store/reducers/userModel/userModel-actions';
import {
  CURRENT_SHIFT_USERS,
  GET_USER_SHIFT_HISTORY,
  SHIFT_PROVIDERS,
  SHIFT_PROVIDERS_GET,
  SHIFTS_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
  CONTROL_STYLE_TYPE,
  CONTROL_TYPE,
  COURIER_STATE,
  DATECONTROL_TYPE,
  getCurrencyIcons,
  getExternalCourierProviderName,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  SHIFT_TYPE_VALUES,
  SHIFTS_MODEL,
  USER_FILTER_ROLES,
} from '../../utils/enums';
import {
  DATE_FORMAT, DATE_FORMAT_DMY_SHORT,
  DATE_TIME_FORMAT_DAYTIME,
  getFormattedDate,
  getStaticTime,
  getWeekPeriod, prepareMonth, preparePreviousMonth, prepareToday, prepareYesterday,
  TIME_FORMAT_USER,
} from '../../utils/convertTime';

import StatusOrder from "../../app/components/StatusOrder";
import LinkDownload from "../../app/components/LinkDownload";
import OrderTitleInfo from "../../app/components/OrderTitleInfo";
import {Avatar} from "../../app/components/Avatar/Avatar";
import {
  getControlCenterNameById,
  getCourierNameByShiftId,
  getExternalCycleLogoByProvider,
  getHistoryCourierNameByShiftHistory,
  isMobileWidth,
} from '../restaurant';
import CourierShiftControl from "../../app/components/CourierShiftControl/CourierShiftControl";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {getHistoryLocationParams} from '../../utils/routesHelper';
import TextWithTooltip from '../../app/components/TextWithTooltip';
import CurrentShiftCourierEdit from '../../app/components/CurrentShiftCourierEdit';
import CurrentShiftDepotAssign from '../../app/components/CurrentShiftDepotAssign';
import Pusher from '../pusher';
import {getUserRole} from '../../utils/auth-util.js';

export function createShiftsSectionModel() {
  return new SectionModel({
    filterOptions: createShiftsSectionFilter(),
    skipUpdateFilterOptions: true,
    columns: [
      {
        width: '150px',
        fixedWidth: '150px',
        title: "shifts.WORKER_NAME_LABEL",
        isHideDeleteButton: item => item.isProvider,
        component: function(shift) {
          if(shift.isProvider) {
            return getExternalCourierProviderName(shift);
          } else {
            return shift.courierName;
          }
        },
      },
      {
        width: '100px',
        fixedWidth: '100px',
        title: "shifts.START_DATE_LABEL",
        component: function(shift) {
          return (
            getFormattedDate(shift.startDate, DATE_FORMAT_DMY_SHORT) ||
            i18n.t("shifts.ACTIVE_LABEL")
          );
        },
        centered: true,
      },
      {
        width: '100px',
        fixedWidth: '100px',
        title: "shifts.START_TIME_LABEL",
        component: function(shift) {
          return (
            getFormattedDate(shift.startDate, TIME_FORMAT_USER) ||
            i18n.t("shifts.ACTIVE_LABEL")
          );
        },
        centered: true,
      },
      {
        width: '100px',
        fixedWidth: '100px',
        title: "shifts.END_DATE_LABEL",
        component: function(shift) {
          return (
            getFormattedDate(shift.endDate, DATE_FORMAT_DMY_SHORT) ||
            i18n.t("shifts.ACTIVE_LABEL")
          );
        },
        centered: true,
      },
      {
        width: '100px',
        fixedWidth: '100px',
        title: "shifts.END_TIME_LABEL",
        component: function(shift) {
          return (
            getFormattedDate(shift.endDate, TIME_FORMAT_USER) ||
            i18n.t("shifts.ACTIVE_LABEL")
          );
        },
        centered: true,
      },
      {
        title: "shifts.SHIFT_TYPE",
        width: '115px',
        fixedWidth: '115px',
        component: (shift) => (
          <div>
            {SHIFT_TYPE_VALUES[shift.shiftType]}
          </div>
        ),
        centered: true
      },
      {
        width: '10%',
        fixedWidth: '10%',
        title: "shifts.VEHICLE_PLATE_ID_LABEL",
        accessor: "vehiclePlateId",
        centered: true,
      },
      {
        width: '15%',
        fixedWidth: '15%',
        title: "shifts.NOTES_LABEL",
        accessor: "notes",
        centered: true,
      },
      {
        width: '10%',
        fixedWidth: '10%',
        title: "shifts.ADVANCED_PAYMENT_LABEL",
        accessor: "advancePayment",
        centered: true,
      },
      {
        width: '100px',
        fixedWidth: '100px',
        title: "shifts.HOURS_PER_DAY_LABEL",
        component: function(shift) {
          return getTimePerDay(shift);
        },
        centered: true,
      },
      {
        title: "",
        width: "40px",
        fixedWidth: "40px",
        hideInHeader: true,
        component: function(shift) {
          return (
            <LinkDownload
              title={i18n.t("shifts.SAVE_BUTTON")}
              actionName={USER_SHIFT_DOWNLOAD_PDF}
              data={shift}
              icon={"excel"}
            />
          );
        },
        link: USER_SHIFT_DOWNLOAD_PDF,
        type: CONTROL_TYPE.linkAction
      },
      {
        title: "",
        width: "45px",
        fixedWidth: "45px",
        hideInHeader: true,
        type: CONTROL_TYPE.link,
        link: SHIFTS_MODEL.shiftHistory,
        iconName: "eyeIcon",
        iconFullWH: true,
        tooltipText: i18n.t("shifts.SHIFTS_HISTORY"),
      },
      {
        width: "40px",
        fixedWidth: "40px",
        type: CONTROL_TYPE.link,
        link: SHIFTS_MODEL.historyEdit,
        iconName: "editItem",
        isShow: shift => shift.endDate,
        tooltipText: i18n.t("settings.users.USER_TOOLS_EDIT_TOOLTIP"),
      },
      {
        title: '',
        width: "40px",
        fixedWidth: "40px",
        hideInHeader: true,
        isShow: shift => !shift.endDate,
        component: (user) => (
            <CurrentShiftCourierEdit courier={user} updateHistoryShift />
        ),
      }
    ],
    key: "shiftId",
    label: 'shifts.HISTORY',
    breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SHIFTS_LABEL' })],
    actionName: USER_SHIFTS,
    footer: "shiftsSummary",
    hideHeaderItemControls: true,
    tableMinWidth: 1200,
    typeModel: SHIFTS_MODEL.history,
    isForceUpdate: true,
    controls: [
      createControlModel({
        action: USER_SHIFT_COURIERS_CSV,
        icon: "excelIcon",
        text: i18n.t("shifts.SAVE_BUTTON"),
        tooltipText: i18n.t("shifts.SAVE_BUTTON"),
        type: MODEL_CONTROL_TYPE.iconControl
      })
    ],
    mappingLambda: state => {
      const selectedControlCenterIds = state.restaurant.data.selectedControlCenterMulti || [];
      const depots = state.restaurant.data.depots;
      const users = state.userModel.data.filter(user =>
          user.cycle
          && (!!selectedControlCenterIds.length && user?.cycle?.controlCenterId ? checkForControlCenter(user.cycle, selectedControlCenterIds) : true)
      );

      const couriers = users.map(user => ({
        depotsNames: depots.filter(depot => user?.cycle?.allowedDepots?.includes(depot.id)) || '-',
        shiftId: user.cycle?.shiftId || '-',
        showControlCenterName: selectedControlCenterIds.length !== 1,
        ...user,
      }));

      return (state.restaurant.data.shifts || []).reduce((acc, shift) => {
        const find = couriers.find(c => c.shiftId === shift.shiftId);
        return find && !shift.endDate ? acc.concat({...shift, userId: find.userId, cycle: {...shift, ...find.cycle}}) : acc.concat(shift);
      }, []);
    },
    actionLoad: SHIFTS_GET
  });
}

export function createShiftsEditSectionModel({ ...args }) {
  return new SectionModel({
    key: "shiftId",
    columns: [
      {
        title: "shifts.START_DATE_LABEL",
        accessor: "startDate",
        isEditable: true,
        type: CONTROL_TYPE.date
      },
      {
        title: "shifts.START_TIME_LABEL",
        accessor: "startDate",
        isEditable: true,
        timeType: DATECONTROL_TYPE.time,
        type: CONTROL_TYPE.date
      },
      {
        title: "shifts.END_DATE_LABEL",
        accessor: "endDate",
        isEditable: true,
        type: CONTROL_TYPE.date
      },
      {
        title: "shifts.END_TIME_LABEL",
        accessor: "endDate",
        isEditable: true,
        timeType: DATECONTROL_TYPE.time,
        type: CONTROL_TYPE.date
      },
      {
        title: "shifts.NOTES_LABEL",
        accessor: "notes",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "shifts.ADVANCED_PAYMENT_LABEL",
        accessor: "advancePayment",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "shifts.VEHICLE_PLATE_ID_LABEL",
        accessor: "vehiclePlateId",
        isEditable: true,
        type: CONTROL_TYPE.number
      },
      {
        title: "shifts.SAVE_BUTTON",
        isEditable: true,
        accessor: "shiftId",
        actionName: USER_SHIFT_DOWNLOAD_PDF,
        type: CONTROL_TYPE.linkDownload
      }
    ],
    actionName: USER_SHIFTS,
    initNewItemModel: item => getInitModel(MODEL_TYPE.shifts, item),
    typeAction: args.typeAction,
    typeModel: SHIFTS_MODEL.historyEdit,
    parentUrl: SHIFTS_MODEL.history,
    mappingLambda: state => state.restaurant.data.shifts || [],
    actionLoad: SHIFTS_GET
  });
}

export function createShiftHistorySectionModel({ ...args }) {
  return new SectionModel({
    filterOptions: createShiftHistorySectionFilter(),
    columns: [
      {
        title: "couriers.NAME_TABLE_TITLE",
        width: "400px",
        component: function(order) {
          return (<div className="overflowNextRowText"><OrderTitleInfo order={order} /></div>);
        }
      },
      {
        title: "couriers.PAYMENT_CASH",
        width: "80px",
        component: function(order) {
          return (<div className="overflowNextRowText">{`${getAmountForType(order, true)}${getCurrencyIcons(order.currency)}`}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.PAYMENT_CREDIT_CARD",
        width: "100px",
        component: function(order) {
          return (<div className="overflowNextRowText">{`${getAmountForType(order)}${getCurrencyIcons(order.currency)}`}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.DELIVERY_TIME_TABLE_TITLE",
        width: "120px",
        component: function(order) {
          return (<div className="overflowNextRowText">{getFormattedDeliveryDuration(order)}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.DELIVERY_AT_TABLE_TITLE",
        width: "150px",
        component: function(order) {
          return (<div className="overflowNextRowText">{getStaticTime(getDeliveredDate(order), isRTL())}</div>);
        }
      },
      {
        title: "couriers.STATUS_TABLE_TITLE",
        width: "80px",
        component: function(order) {
          return <StatusOrder order={order} />;
        }
      }
    ],
    actionName: USER_SHIFT_HISTORY,
    actionLoad: GET_USER_SHIFT_HISTORY,
    urlNewItem: SHIFTS_MODEL.shiftHistory + `/new${args.params}`,
    showDeleteButton: false,
    hideHeaderItemControls: true,
    footer: "shiftsHistorySummary",
    typeModel: SHIFTS_MODEL.shiftHistory,
    parentUrl: SHIFTS_MODEL.history,
    label: 'shifts.HISTORY',
    breadcrumbs: [
      createBreadcrumbModel({ label: 'header.menu.SHIFTS_LABEL' }),
      createBreadcrumbModel({ label: 'shifts.HISTORY' }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return getCourierNameByShiftId(id) || getHistoryCourierNameByShiftHistory();
        },
      })
    ],
    controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back })],
    mappingLambda: state => {
      const depots = state.restaurant?.data?.depots || []
      const orders = state.restaurant.data.shiftsHistory
        ? state.restaurant.data.shiftsHistory.orders || []
        : []

      return orders?.map(order => ({
        ...order,
        depotName: depots?.find(depot => depot?.id === order?.depotId)?.name || ''
      }))
    },
  })
}

export function createCurrentShiftUsersSectionModel() {
  return new SectionModel({
    filterOptions: createCurrentShiftUsersSectionFilter(),
    isForceUpdate: true,
    hideHeaderItemControls: true,
    columns: [
      {
        title: "invitation.USERNAME_LABEL",
        width: '185px',
        fixedWidth: '185px',
        component: function(user) {
          return <Avatar avatar={user.avatarUrl} color={user.color} title={getFullName(user) || user.name}/>;
        },
        accessor: 'avatarUrl',
        type: CONTROL_TYPE.image,
      },
      {
        title: "assistance.DEPOT_LABEL",
        width: '170px',
        fixedWidth: '170px',
        component: (user) => {
          const names = user?.depotsNames?.map(depot => depot.name)
          return names && <div className={'overflowHiddenText'}>
            {names.join(', ')}
          </div>
        },
      },
      {
        title: "shifts.CONTROL_CENTER",
        width: '170px',
        fixedWidth: '170px',
        isShow: user => {
          return user.showControlCenterName;
        },
        component: (user) => {
          return user?.cycle?.controlCenterId ? getControlCenterNameById(user?.cycle?.controlCenterId) : '';
        },
      },
      {
        width: '120px',
        fixedWidth: '120px',
        title: "shifts.START_DATE_LABEL",
        component: function(user) {
          return (
            getFormattedDate(user.cycle?.startTime, DATE_FORMAT) ||
            i18n.t("shifts.ACTIVE_LABEL")
          );
        },
        centered: true,
        isShow: () => false,
      },
      {
        title: "shifts.START_TIME_LABEL",
        width: '100px',
        fixedWidth: '100px',
        component: function(user) {
          return (
            getFormattedDate(user.cycle?.startTime, TIME_FORMAT_USER) ||
            '-'
          );
        },
        centered: true,
        isShow: () => false,
      },
      {
        width: '120px',
        fixedWidth: '120px',
        title: "shifts.END_DATE_LABEL",
        component: function(user) {
          return (
            getFormattedDate(user.cycle?.endTime, DATE_FORMAT) ||
            i18n.t("shifts.ACTIVE_LABEL")
          );
        },
        centered: true,
        isShow: () => false,
      },
      {
        title: "shifts.END_TIME_LABEL",
        width: '91px',
        fixedWidth: '91px',
        component: function(user) {
          return (
              getFormattedDate(user.cycle?.endTime, TIME_FORMAT_USER) ||
              '-'
          );
        },
        centered: true,
        isShow: () => false,
      },
      {
        title: "shifts.SHIFT_TYPE",
        width: '100px',
        fixedWidth: '100px',
        component: (user) => (
          <div>
            {SHIFT_TYPE_VALUES[user.cycle.shiftType]}
          </div>
        ),
        centered: true
      },
      {
        title: ''
      },
      {
        title: "shifts.ACTIONS",
        width: "160px",
        // textRight: true,
        // textAlign: isRTL() ? 'left' : 'right',
      },
      {
        title: "",
        width: "40px",
        fixedWidth: "40px",
        component: function(user) {
          return (
              <LinkDownload
                  title={i18n.t("shifts.SAVE_BUTTON")}
                  actionName={USER_SHIFT_DOWNLOAD_PDF}
                  data={user.cycle}
                  icon={"excel"}
              />
          );
        },
        link: USER_SHIFT_DOWNLOAD_PDF,
        type: CONTROL_TYPE.linkAction,
        hideInHeader: true,
        isShow: user => !!user.cycle
      },
      {
        title: "",
        width: "45px",
        fixedWidth: "45px",
        type: CONTROL_TYPE.link,
        iconFullWH: true,
        link: SHIFTS_MODEL.currentShiftHistory,
        iconName: "eyeIcon",
        tooltipText: i18n.t("shifts.SHIFTS_HISTORY"),
        hideInHeader: true,
      },
      {
        title: '',
        width: "40px",
        fixedWidth: "40px",
        hideInHeader: true,
        component: (user) => (
          <CurrentShiftCourierEdit courier={user} />
        ),
      },
      {
        title: '',
        width: '40px',
        fixedWidth: "40px",
        component: (user) => (
              <div>
                <CourierShiftControl userId={user.userId} type={COURIER_STATE.endShift} />
              </div>
        ),
        type: CONTROL_TYPE.linkIconComponent,
        wrapOnMobileWidth: '992',
        link: 'courierShiftControl',
        hideInHeader: true,
      }
    ],
    key: "shiftId",
    actionName: USER_SHIFTS,
    actionLoad: CURRENT_SHIFT_USERS,
    typeModel: SHIFTS_MODEL.currentShiftUsers,
    tableMinWidth: 1200,
    hideCellForSort: true,
    label: 'shifts.CURRENT_SHIFT',
    breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SHIFTS_LABEL' })],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        openCourierShiftPopup: true
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.filter
      }),
    ],
    mappingLambda: state => {
      const selectedControlCenterIds = state.restaurant.data.selectedControlCenterMulti || [];
      const depots = state.restaurant.data.depots;
      const users = state.userModel.data.filter(user =>
          user.cycle
          && (!!selectedControlCenterIds.length && user?.cycle?.controlCenterId ? checkForControlCenter(user.cycle, selectedControlCenterIds) : true)
      );

      const couriers = users.map(user => ({
        depotsNames: depots.filter(depot => user?.cycle?.allowedDepots?.includes(depot.id)) || '-',
        shiftId: user.cycle?.shiftId || '-',
        showControlCenterName: selectedControlCenterIds.length !== 1,
        ...user,
      }))

      return couriers;
    },
  });
}

export function createSummaryShiftProviderSectionModel() {
  return new SectionModel({
    filterOptions: createShiftsProviderSectionFilter(),
    skipUpdateFilterOptions: true,
    isForceUpdate: true,
    columns: [
      {
        title: "invitation.USERNAME_LABEL",
        width: '185px',
        fixedWidth: '185px',
        component: function(user) {
          return <Avatar
              avatar={user.avatarUrl || getExternalCycleLogoByProvider(user.provider)}
              color={user.color}
              title={user.name || user.providerId}
          />;
        },
      },
      {
        title: "shifts.COUNT",
        width: '100px',
        fixedWidth: '100px',
        accessor: 'count',
        centered: true
      },
      {
        title: "shifts.START_TIME_LABEL",
        width: '100px',
        fixedWidth: '100px',
        component: function(user) {
          return (
              getFormattedDate(user?.startDate, DATE_TIME_FORMAT_DAYTIME) ||
              '-'
          );
        },
        centered: true
      },
      {
        title: "shifts.END_TIME_LABEL",
        width: '100px',
        fixedWidth: '100px',
        component: function(user) {
          return (
              getFormattedDate(user?.endDate, DATE_TIME_FORMAT_DAYTIME) ||
              '-'
          );
        },
        centered: true
      },
      {
        title: "",
        width: "40px",
        fixedWidth: "40px",
        hideInHeader: true,
        component: function(shift) {
          return (
              <LinkDownload
                  title={i18n.t("shifts.SAVE_BUTTON")}
                  actionName={USER_SHIFT_DOWNLOAD_PDF}
                  data={{...shift, id: shift.providerId, isProvider: true}}
                  icon={"excel"}
              />
          );
        },
        link: USER_SHIFT_DOWNLOAD_PDF,
        type: CONTROL_TYPE.linkAction
      },
      {
        title: "",
        width: "45px",
        fixedWidth: "45px",
        type: CONTROL_TYPE.link,
        iconFullWH: true,
        link: SHIFTS_MODEL.currentShiftProviderHistory,
        iconName: "eyeIcon",
        tooltipText: i18n.t("shifts.SHIFTS_PROVIDER_HISTORY"),
        hideInHeader: true,
      },
    ],
    key: "providerId",
    actionName: SHIFT_PROVIDERS,
    actionLoad: SHIFT_PROVIDERS_GET,
    typeModel: SHIFTS_MODEL.providers,
    tableMinWidth: 1200,
    hideCellForSort: true,
    label: 'shifts.PROVIDERS',
    breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SHIFTS_LABEL' })],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.filter
      }),
    ],
    mappingLambda: state => {
      const providers = state.restaurant.data.shiftProviders || [];
      const ordersProvider = state.restaurant.data.ordersProvider || [];
      return providers.map(provider => {
        const find = ordersProvider.find(p => p.id === provider.providerId);
        return {
          ...find,
          ...provider,
        }
      });
    },
  });
}

export function createCurrentShiftEditSectionModel({ ...args }) {
  return new SectionModel({
    isForceUpdate: true,
    columns: [
      {
        title: "shifts.START_TIME_LABEL",
        accessor: "startTime",
        isEditable: true,
        timeType: DATECONTROL_TYPE.time,
        type: CONTROL_TYPE.date
      },
      {
        title: "shifts.END_TIME_LABEL",
        accessor: "endTime",
        isEditable: true,
        timeType: DATECONTROL_TYPE.time,
        type: CONTROL_TYPE.date
      },
      {
        title: 'Allowed depots',
        accessor: "allowedDepots",
        isEditable: true,
        type: CONTROL_TYPE.allowedDepotsList,
      },
      {
        title: "shifts.SAVE_BUTTON",
        isEditable: true,
        accessor: "shiftId",
        actionName: USER_SHIFT_DOWNLOAD_PDF,
        type: CONTROL_TYPE.linkDownload,
        isShow: item => !!item.shiftId
      }
    ],
    actionName: USER_CURRENT_SHIFTS,
    initNewItemModel: user => getInitModel(MODEL_TYPE.currentShift, user),
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: SHIFTS_MODEL.currentShiftDepotEdit,
    parentUrl: SHIFTS_MODEL.currentShiftUsers,
    mappingLambda: state => {
      const { id } = getHistoryLocationParams(window.location.search);
      const users = getUsersFilteredByDepotId(state.userModel.data, false)
      return users.find(user => user.userId === id)
    }
  });
}

export function createCurrentShiftHistorySectionModel({...args}) {
  return new SectionModel({
    filterOptions: createShiftHistorySectionFilter(),
    columns: [
      {
        title: "couriers.NAME_TABLE_TITLE",
        width: "400px",
        component: function(order) {
          return (<div className="overflowNextRowText"><OrderTitleInfo order={order} /></div>);
        }
      },
      {
        title: "shifts.ORDER_ID",
        width: "80px",
        component: function(order) {
          return (<div className="overflowNextRowText">{order.orderId}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.PAYMENT_CASH",
        width: "80px",
        component: function(order) {
          return (<div className="overflowNextRowText">{`${getAmountForType(order, true)}${getCurrencyIcons(order.currency)}`}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.PAYMENT_CREDIT_CARD",
        width: "100px",
        component: function(order) {
          return (<div className="overflowNextRowText">{`${getAmountForType(order)}${getCurrencyIcons(order.currency)}`}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.DELIVERY_TIME_TABLE_TITLE",
        width: "120px",
        component: function(order) {
          return (<div className="overflowNextRowText">{getFormattedDeliveryDuration(order)}</div>);
        },
        hideWidth: "570"
      },
      {
        title: "couriers.DELIVERY_AT_TABLE_TITLE",
        width: "150px",
        component: function(order) {
          return (<div className="overflowNextRowText">{getStaticTime(getDeliveredDate(order), isRTL())}</div>);
        }
      },
      {
        title: "couriers.STATUS_TABLE_TITLE",
        width: "80px",
        component: function(order) {
          return <StatusOrder order={order} />;
        }
      }
    ],
    showDeleteButton: false,
    footer: "shiftsHistorySummary",
    hideHeaderItemControls: true,
    actionLoad: args.actionLoad,
    typeModel: SHIFTS_MODEL.currentShiftHistory,
    parentUrl: args.parentUrl,
    label: 'shifts.HISTORY',
    breadcrumbs: [
      createBreadcrumbModel({ label: 'header.menu.SHIFTS_LABEL' }),
      createBreadcrumbModel({ label: 'shifts.CURRENT_SHIFT' }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return args.getLabel ? args.getLabel(id) : id;
        },
      })
    ],
    controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back })],
    mappingLambda: state => {
      const { id } = getHistoryLocationParams(window.location.search);
      const depots = state.restaurant?.data?.depots || []
      const orders = state.restaurant.data.shiftsHistory
        ? state.restaurant.data.shiftsHistory.orders || []
        : []

      const ordersWithDepotName = orders.map(order => ({
        ...order,
        depotName: depots?.find(depot => depot?.id === order?.depotId)?.name || ''
      }))

      return id === state.restaurant.data?.shiftsHistory?.id ? ordersWithDepotName : []
    }
  });
}


export function createCurrentShiftDepotsSectionModel() {
  return new SectionModel({
    filterOptions: createCurrentShiftDepotsSectionFilter(),
    isForceUpdate: true,
    hideHeaderItemControls: true,
    columns: [
      {
        title: "",
        width: '52px',
        fixedWidth: '52px',
        component: function(depot) {
          return <Avatar avatar={depot.logoUrl} color={depot.color} />;
        },
        type: CONTROL_TYPE.image,
      },
      {
        title: 'settings.map.DEPOT_EDIT_NAME_LABEL',
        accessor: 'name',
        width: '200px',
        fixedWidth: '200px',
        component: depot => <TextWithTooltip text={depot.name} tooltip={depot.name} />,
      },
      {
        title: "controlCenters.SELECT_COURIERS_LABEL",
        component: (depot) => {
          const names = depot.users.map(user => getFullName(user))
          return names.join(', ')
        }
      },
      {
        title: 'controlCenters.CHOOSE_COURIER',
        width: '150px',
        // hideInHeader: true,
        centered: true,
        component: (depot) => (
            <CurrentShiftDepotAssign depot={depot} />
        ),
        isShow: item => !item.isProvider,
      },
    ],
    key: "businessId",
    actionName: USER_SHIFTS,
    typeModel: SHIFTS_MODEL.currentShiftUsers,
    tableMinWidth: 1200,
    hideCellForSort: true,
    label: 'shifts.CURRENT_SHIFT',
    breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SHIFTS_LABEL' })],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        openCourierShiftPopup: true
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.filter
      }),
    ],
    mappingLambda: state => {
      const depots = state.restaurant.data.depots || []
      const users = state.userModel.data
      const allowedDepotsUsers = users.filter(user => user?.cycle?.allowedDepots);
      return depots.map(depot => ({
        users: allowedDepotsUsers.filter(user => user ? user.cycle.allowedDepots.includes(depot.id) : '-'),
        ...depot
      }))
    },
    sortByFunc: items => sortUsers(items)
  });
}


function createCurrentShiftUsersSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    user
  ) {
    return getDepotUsersSectionFilter(this.selected, user);
  });

  //Control centers
  let selectControlCenterOption = filterOptionsModel.addOptions("controlCenterIds", CONTROL_TYPE.multiselect, i18n.t("shifts.CONTROL_CENTER"));
  selectControlCenterOption.selected = Pusher.instance.getControlCenterMultiChannel() || [];
  selectControlCenterOption.mappingLambda = state =>
      sortUsers((state.restaurant && state.restaurant.data && state.restaurant.data.controlCenters) || [])
          .map(controlCenter=> ({
                key: controlCenter.name,
                value: controlCenter.controlCenterId
              })
          );
  selectControlCenterOption.isShow = isShowControlCenterSearch;

  return filterOptionsModel;
}
function createCurrentShiftDepotsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    depot
  ) {
    return getDepotFilter(this.selected, depot);
  });

  return filterOptionsModel;
}

function createShiftsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    shift
  ) {
    return getShiftsFilter(this.selected, shift);
  });

  filterOptionsModel.addOptions(
    "todayLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.TODAY_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const {from, to} = prepareToday();
      onChangeOption(
        "dateStart",
        "selected",
        new Date(from),
      );
      onChangeOption(
        "endDate",
        "selected",
        new Date(to),
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "yesterdayLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.YESTERDAY_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = prepareYesterday();

      onChangeOption(
        "dateStart",
        "selected",
        from,
      );
      onChangeOption(
        "endDate",
        "selected",
        to,
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "weekLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.WEEK_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = getWeekPeriod();
      onChangeOption("dateStart", "selected", new Date(from));
      onChangeOption("endDate", "selected", new Date(to));
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "monthLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.MONTH_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = prepareMonth();

      onChangeOption(
        "dateStart",
        "selected",
        from,
      );
      onChangeOption(
        "endDate",
        "selected",
        to,
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "previousMonthLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.PREVIOUS_MONTH_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = preparePreviousMonth();

      onChangeOption(
        "dateStart",
        "selected",
        from,
      );
      onChangeOption(
        "endDate",
        "selected",
        to,
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "dateStart",
    CONTROL_TYPE.date,
    "shifts.START_DATE_LABEL",
    null,
    null,
    null,
    true
  );

  filterOptionsModel.addOptions(
    "endDate",
    CONTROL_TYPE.date,
    "shifts.END_DATE_LABEL",
    null,
    null,
    null,
    true
  );

  //Users
  filterOptionsModel.addOptions(
    "user",
    CONTROL_TYPE.usersPopup,
    i18n.t("shifts.USER"),
    null,
    null,
    null,
    true
    );

  //Control centers
  const userRole = getUserRole();
  const isControlCenterManager = (userRole || []).some(item => item === USER_FILTER_ROLES.controlCenterManager);

  let selectControlCenterOption = filterOptionsModel.addOptions(FILTER_OPTION_TYPE.controlCenter, CONTROL_TYPE.select, i18n.t("shifts.CONTROL_CENTER"));
  selectControlCenterOption.selected = isControlCenterManager ? null : undefined;
  selectControlCenterOption.mappingLambda = state => [{ key: "-", value: null }].concat(
    sortUsers((state.restaurant && state.restaurant.data && state.restaurant.data.controlCenters) || [])
      .map(controlCenter=> ({
        key: controlCenter.name,
        value: controlCenter.controlCenterId
      }))
  );
  selectControlCenterOption.isShow = isShowControlCenterSearch;

  //use filter state
  const filterState = Pusher.instance.getShiftHistoryFilterStateChannel() || {}
  for(let key in filterState){
    filterOptionsModel.options[key].selected = filterState[key]?.selected ? filterState[key]?.selected : null
  }

  const {from, to} = prepareToday();
  filterOptionsModel.options["dateStart"].selected = from;
  filterOptionsModel.options["endDate"].selected = to;

  return filterOptionsModel;
}

function createShiftsProviderSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    shift
  ) {
    return getShiftsFilter(this.selected, shift);
  });

  filterOptionsModel.addOptions(
    "todayLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.TODAY_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const {from, to} = prepareToday();
      onChangeOption(
        "dateStart",
        "selected",
        from
      );
      onChangeOption(
        "endDate",
        "selected",
        to
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "yesterdayLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.YESTERDAY_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = prepareYesterday();

      onChangeOption(
        "dateStart",
        "selected",
        from
      );
      onChangeOption(
        "endDate",
        "selected",
        to
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "weekLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.WEEK_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = getWeekPeriod();
      onChangeOption("dateStart", "selected", from);
      onChangeOption("endDate", "selected", to);
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "monthLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.MONTH_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = prepareMonth();

      onChangeOption(
        "dateStart",
        "selected",
        from,
      );
      onChangeOption(
        "endDate",
        "selected",
        to,
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "previousMonthLink",
    CONTROL_TYPE.link,
    i18n.t("shifts.PREVIOUS_MONTH_LINK"),
    null,
    false,
    null,
    false,
    function(onChangeOption) {
      const { from, to } = preparePreviousMonth();

      onChangeOption(
        "dateStart",
        "selected",
        from,
      );
      onChangeOption(
        "endDate",
        "selected",
        to,
      );
    },
    CONTROL_STYLE_TYPE.inline
  );

  filterOptionsModel.addOptions(
    "dateStart",
    CONTROL_TYPE.date,
    "shifts.START_DATE_LABEL",
    null,
    null,
    null,
    true
  );

  filterOptionsModel.addOptions(
    "endDate",
    CONTROL_TYPE.date,
    "shifts.END_DATE_LABEL",
    null,
    null,
    null,
    true
  );

  //Control centers
  const userRole = getUserRole();
  const isControlCenterManager = (userRole || []).some(item => item === USER_FILTER_ROLES.controlCenterManager);

  let selectControlCenterOption = filterOptionsModel.addOptions(FILTER_OPTION_TYPE.controlCenter, CONTROL_TYPE.select, i18n.t("shifts.CONTROL_CENTER"));
  selectControlCenterOption.selected = isControlCenterManager ? null : undefined;
  selectControlCenterOption.mappingLambda = state => [{ key: "-", value: null }].concat(
    sortUsers((state.restaurant && state.restaurant.data && state.restaurant.data.controlCenters) || [])
      .map(controlCenter=> ({
        key: controlCenter.name,
        value: controlCenter.controlCenterId
      }))
  );
  selectControlCenterOption.isShow = isShowControlCenterSearch;

  const filterState = Pusher.instance.getShiftProviderHistoryFilterStateChannel() || {}
  for(let key in filterState){
    filterOptionsModel.options[key].selected = filterState[key]?.selected ? filterState[key]?.selected : null
  }

  const {from, to} = prepareToday();
  filterOptionsModel.options["dateStart"].selected = from;
  filterOptionsModel.options["endDate"].selected = to;

  return filterOptionsModel;
}

function createShiftHistorySectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    order
  ) {
    return getShiftsHistoryFilter(this.selected, order);
  });

  return filterOptionsModel;
}
