import { getISOTime } from "../utils/convertTime";

export function shiftServerModel(shift) {
  return {
    ShiftId: shift.shiftId,
    StartDate: getISOTime(shift.startDate),
    EndDate: getISOTime(shift.endDate),
    VehiclePlateId: shift.vehiclePlateId,
    AdvancePayment: parseFloat(shift.advancePayment),
    Notes: shift.notes
  };
}
