import React from "react";
import classNames from "classnames";
import "./index.scss";

import {VEHICLE_CYCLE_TYPE, VEHICLE_TYPE} from "../../../utils/enums";
import {getKeyByValue} from "../../../utils/objects-util";

export default ({isCycle, value, isActive })=> (
    <div className={classNames("vehicleIcon")}>
        {Number.isInteger(value) && (
            <div className={classNames("vehicleTypeIcon", getKeyByValue(isCycle ? VEHICLE_CYCLE_TYPE : VEHICLE_TYPE, value), { active: isActive})}></div>
        )}
    </div>
);
