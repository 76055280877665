import {call, put, takeEvery, takeLatest} from "redux-saga/effects";

import {fetchAdminOrders} from "../../api/order-requests";

import {
    actionCustomerReviewUpdate,
    actionCustomersUpdate,
    actionCustomerUpdate,
    CUSTOMER_ADD_BONUS_CARD, CUSTOMER_BLOCK_USER, CUSTOMER_BLOCK_USER_STATUS,
    CUSTOMER_ORDERS_GET,
    CUSTOMER_REVIEWS_GET, CUSTOMER_UPDATE_COMMENT, CUSTOMER_UPDATE_LOYALTY_LEVEL,
    CUSTOMER_WALLET_INFO_GET,
    CUSTOMER_WALLET_OPERATION,
    CUSTOMERS_GET,
} from '../reducers/customer/customer-actions';

import {objectKeysToUpperLowerCase} from "../../utils/objects-util";
import {getErrorMessage, requestResultIsOk} from "../../utils/request-util";
import {createSaga, ErrorData} from "../../utils/sagaHelper";
import {
    blockConsumerUser,
    customerAddBonusCard,
    customerWalletInfo,
    customerWalletOperation, getblockConsumerUserStatus,
    getCustomers, unblockConsumerUser, updateCommentConsumerUser, updateLoyaltyLevel,
} from '../../api/customer-requests';
import {CUSTOMER_WALLET_OPERATION_TYPE} from "../../utils/constants";

export function* customerReviewGetSaga(action) {
    yield* createSaga(function*() {
        const {periodFilter, ...filter} = action.data || {};
        const requestResult = yield call(fetchAdminOrders, objectKeysToUpperLowerCase({
            ...(filter || {}),
            end: new Date(action.data.end).toISOString(),
            start: new Date(action.data.start).toISOString(),
            withRating: true
        }, true));

        if(requestResultIsOk(requestResult)) {
            yield put(actionCustomerReviewUpdate(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* customersGetSaga(action) {
    yield* createSaga(function*() {
        const data = action.data || {};
        const requestResult = yield call(getCustomers, data);
        if(requestResultIsOk(requestResult)) {
            const response = objectKeysToUpperLowerCase(requestResult.data);
            yield put(actionCustomersUpdate(response));
            return response;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* customerOrdersGetSaga(action) {
    yield* createSaga(function*() {
        const { customerId, rating, deliveryRating } = action.data || {};
        if(customerId) {
            const requestResult = yield call(fetchAdminOrders, objectKeysToUpperLowerCase({
                search: customerId,
                rating, deliveryRating,
                withRating: !!(rating || deliveryRating)
            }, true));

            if(requestResultIsOk(requestResult)) {
                yield put(actionCustomerUpdate({ consumerId: customerId, orders: objectKeysToUpperLowerCase(requestResult.data) }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerWalletOperationSaga(action) {
    yield* createSaga(function*() {
        const { customerId, type, data } = action.data;
        if(customerId && type && data) {
            const requestResult = yield call(customerWalletOperation, customerId, {
                ...data,
                is_deduct: type === CUSTOMER_WALLET_OPERATION_TYPE.spend
            });

            if(!requestResultIsOk(requestResult)) {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerAddBonusCardSaga(action) {
    yield* createSaga(function*() {
        const { customerId, data } = action.data;
        if(customerId && data) {
            const requestResult = yield call(customerAddBonusCard, customerId, data);

            if(!requestResultIsOk(requestResult)) {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerUpdateLoyaltyLevelSaga(action) {
    yield* createSaga(function*() {
        const { customerId, loyaltyLevel } = action.data;
        if(customerId) {
            const requestResult = yield call(updateLoyaltyLevel, customerId, loyaltyLevel);
            yield put(actionCustomerUpdate({ consumerId: customerId, loyaltyLevel: requestResult.data.LoyaltyLevel}));

            if(!requestResultIsOk(requestResult)) {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerBlockUserSaga(action) {
    yield* createSaga(function*() {
        const { customerId, notes, isBlocked } = action.data;
        if(customerId) {
            const requestResult = yield call(isBlocked ? unblockConsumerUser : blockConsumerUser, customerId, notes);
            yield put(actionCustomerUpdate({ consumerId: customerId, blockInfo: !isBlocked ? { note: notes, isBlocked: true } :{ isBlocked: false, note: '' } }));

            if(!requestResultIsOk(requestResult)) {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerUpdateCommentSaga(action) {
    yield* createSaga(function*() {
        const { customerId, notes } = action.data;
        if(customerId) {
            const requestResult = yield call(updateCommentConsumerUser, customerId, notes);
            if(requestResultIsOk(requestResult)) {
                const response = objectKeysToUpperLowerCase(requestResult.data);
                delete response.wallet;
                yield put(actionCustomerUpdate({ consumerId: customerId, ...response }));
            }

            if(!requestResultIsOk(requestResult)) {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerWalletInfoGetSaga(action) {
    yield* createSaga(function*() {
        const { customerId } = action.data;

        if(customerId) {
            const requestResult = yield call(customerWalletInfo, customerId);
            if(requestResultIsOk(requestResult)) {
                yield put(actionCustomerUpdate({ consumerId: customerId, wallet: objectKeysToUpperLowerCase(requestResult.data) }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* customerBlockInfoGetSaga(action) {
    yield* createSaga(function*() {
        const { customerId } = action.data;

        if(customerId) {
            const requestResult = yield call(getblockConsumerUserStatus, customerId);
            if(requestResultIsOk(requestResult)) {
                const data = objectKeysToUpperLowerCase(requestResult.data);
                yield put(actionCustomerUpdate({ consumerId: customerId, blockInfo: data ? { note: data.notes, isBlocked: true } :{ isBlocked: false, note: '' } }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export default function*() {
    yield takeEvery(CUSTOMER_REVIEWS_GET, customerReviewGetSaga);
    yield takeEvery(CUSTOMER_ORDERS_GET, customerOrdersGetSaga);
    yield takeLatest(CUSTOMERS_GET, customersGetSaga);
    yield takeEvery(CUSTOMER_WALLET_OPERATION, customerWalletOperationSaga);
    yield takeEvery(CUSTOMER_ADD_BONUS_CARD, customerAddBonusCardSaga);
    yield takeEvery(CUSTOMER_UPDATE_LOYALTY_LEVEL, customerUpdateLoyaltyLevelSaga);
    yield takeEvery(CUSTOMER_BLOCK_USER, customerBlockUserSaga);
    yield takeEvery(CUSTOMER_UPDATE_COMMENT, customerUpdateCommentSaga);
    yield takeEvery(CUSTOMER_WALLET_INFO_GET, customerWalletInfoGetSaga);
    yield takeEvery(CUSTOMER_BLOCK_USER_STATUS, customerBlockInfoGetSaga);
}
