import i18n from '../../i18n';

import { createControlModel } from '../controlModelFactory';
import { getDeliveryZonesFilter, sortItemsByProp } from '../filter';
import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';

import {
  SETTINGS_DELIVERY_ZONES,
  SETTINGS_DELIVERY_ZONES_GET,
  SETTINGS_DELIVERY_ZONES_LIST_UPDATE,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
  CONTROL_TYPE,
  DELIVERY_ZONE_TYPE,
  getDeliveryZoneTypes,
  MARKETPLACE_SETTINGS_MODEL,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  TYPE_ACTION,
  TYPE_SECTION_MODEL,
} from '../../utils/enums';
import { getInitModel } from '../initModels';
import {
  getDeliveryZonesNameById,
  getRestaurantLocation,
  isMobileWidth,
} from '../restaurant';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { getHistoryLocationParams } from '../../utils/routesHelper.js';

export function createDeliveryZonesSection() {
  return new SectionModel({
    filterOptions: createDeliveryZonesSectionFilter(),
    columns: [
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_NAME_LABEL',
        accessor: 'name',
      },
      {
        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_STATUS_LABEL',
        accessor: 'enabled',
        tooltipText: i18n.t('settings.deliveryZones.DELIVERY_ZONE_STATUS_CHANGE_TOOLTIP'),
        isEditable: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_MINIMUM_LABEL',
        accessor: 'minimum',
        centered: true,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_PRICE_LABEL',
        accessor: 'price',
        centered: true,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_OPERATION_LABEL',
        accessor: 'operation_profile',
        centered: true,
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: '90px',
        centered: true,
        type: CONTROL_TYPE.link,
        link: MARKETPLACE_SETTINGS_MODEL.deliveryZonesEdit,
        iconName: 'editItem',
        tooltipText: i18n.t('settings.orderingmenu.DELIVERY_ZONE_EDIT_TOOLTIP_LABEL'),
      },
    ],
    actionLoad: SETTINGS_DELIVERY_ZONES_GET,
    actionName: SETTINGS_DELIVERY_ZONES,
    editAction: SETTINGS_DELIVERY_ZONES_LIST_UPDATE,
    typeModel: MARKETPLACE_SETTINGS_MODEL.deliveryZones,
    isForceUpdate: true,
    parentUrl: TYPE_SECTION_MODEL.users,
    sortByFunc: sortItemsByProp,
    controls: [
      createControlModel({ type: MODEL_CONTROL_TYPE.add, url: MARKETPLACE_SETTINGS_MODEL.deliveryZonesNew }),
      createControlModel({ type: MODEL_CONTROL_TYPE.back, isShow: isMobileWidth }),
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: 'settings.orderingmenu.DELIVERY',
      }),
    ],
    label: 'settings.deliveryZones.LABEL',
    mappingLambda: state => state.restaurant.data.deliveryZones || [],
  });
}

export function createDeliveryZoneEditSection({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_NAME_LABEL',
        accessor: 'name',
        isEditable: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_MINIMUM_LABEL',
        accessor: 'minimum',
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.number,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_PRICE_LABEL',
        accessor: 'price',
        isEditable: true,
        type: CONTROL_TYPE.number,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_ENABLED_LABEL',
        accessor: 'enabled',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_USER_PRICE_FOR_MIXED_MODE_LABEL',
        accessor: 'price_calculation_mode_bool',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_TYPE_LABEL',
        accessor: 'type',
        connect: () => ({
          values: getDeliveryZoneTypes(),
        }),
        isEditable: true,
        type: CONTROL_TYPE.select,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_OPERATION_LABEL',
        accessor: 'operation_profile',
        connect: state => ({
          values: [{ key: '', value: '-' }]
            .concat((state.restaurant.data.operations && state.restaurant.data.operations.profiles) || [])
            .map(operation => ({
              key: operation.name,
              value: operation.name,
            })),
        }),
        isEditable: true,
        type: CONTROL_TYPE.select,
      },
      {
        title: 'settings.orderingmenu.DELIVERY_ZONE_REFERENCE_REGION_LABEL',
        accessor: 'reference',
        connect: state => {
          return {
            values: [{ key: '', value: '-' }]
              .concat((state.restaurant.data.configuration && state.restaurant.data.configuration.regions) || [])
              .map(region => ({ key: region.id, value: region.name })),
          };
        },
        isShow: item => item.type === DELIVERY_ZONE_TYPE.reference,
        isEditable: true,
        type: CONTROL_TYPE.select,
      },
      {
        title: '',
        accessor: 'region',
        isEditable: true,
        isFillContent: true,
        isHidePolygon: true,
        isHideDepots: true,
        boundsByRegion: true,
        isShow: item => item.type === DELIVERY_ZONE_TYPE.polygon,
        type: CONTROL_TYPE.mapRegion,
      },
      {
        title: '',
        accessor: 'circle',
        isEditable: true,
        isFillContent: true,
        isHidePolygon: true,
        isHideDepots: true,
        boundsByCircle: true,
        isShow: item => item.type === DELIVERY_ZONE_TYPE.circle,
        type: CONTROL_TYPE.mapRegion,
      },
      {
        title: '',
        accessor: 'reference',
        isFillContent: true,
        isHidePolygon: true,
        isHideDepots: true,
        isShow: item => item.type === DELIVERY_ZONE_TYPE.reference,
        type: CONTROL_TYPE.mapRegion,
      },
    ],
    actionLoad: SETTINGS_DELIVERY_ZONES_GET,
    actionName: SETTINGS_DELIVERY_ZONES,
    initNewItemModel: item => {
      const location = getRestaurantLocation();
      const initModel = getInitModel(MODEL_TYPE.deliveryZoneModel, {
        ...item,
        businessMenu: {
          location,
        },
        setDefaultRegion: true,
      });

      return {
        ...initModel,
        region: initModel.data.region,
        circle: initModel.data.circle
          ? {
              center: initModel.data.circle.center,
              radius: initModel.data.circle.radio * 1000,
            }
          : null,
        reference: initModel.data.reference && initModel.data.reference.region_id,
      };
    },
    typeAction: args.typeAction,
    typeModel:
      args.typeAction === TYPE_ACTION.edit
        ? MARKETPLACE_SETTINGS_MODEL.deliveryZonesEdit
        : MARKETPLACE_SETTINGS_MODEL.deliveryZonesNew,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.deliveryZones,
    controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back })],
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.MARKETPLACE_SETTINGS',
      }),
      createBreadcrumbModel({
        label: 'settings.orderingmenu.DELIVERY',
      }),
      createBreadcrumbModel({
        label: 'settings.deliveryZones.LABEL',
      })
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return args.typeAction === TYPE_ACTION.edit ? getDeliveryZonesNameById(id) : i18n.t('settings.deliveryZones.NEW_DELIVERY_ZONE');
    },
    mappingLambda: state => {
      return state.restaurant.data.deliveryZones || [];
    },
  });
}

function createDeliveryZonesSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(deliveryZone) {
    return getDeliveryZonesFilter(this.selected, deliveryZone);
  });

  return filterOptionsModel;
}
