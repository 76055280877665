import Store from '../store/store';

import {
  DEFAULT_ORDERS_GROUPS,
  DELIVERY_STRATEGY,
  ICONS_COURIER_MARKER,
  ICONS_MARKER_TYPE,
  MODEL_TYPE,
  ORDER_STATE,
  ORDER_STATE_COLOR,
  SHIFT_TYPE,
  TYPE_MARKER,
} from '../utils/enums';
import { getRelativeTime, getStaticTime } from '../utils/convertTime';
import { isSameObjects } from '../utils/objects-util';

import MarkerIcons from '../services/markericons';
import ColorService from '../services/color';
import {
  getCourierColor,
  getETATimeForOrder,
  getExternalCycleShortName,
  getShortName,
  getUserByOrder,
  isRTL,
} from './userModel';
import {
  getAddress,
  getGroupColor,
  getUIName,
  isLastExternalCycleOrder,
  isLastGroupOrder,
  locationIsEmpty,
} from './order';
import { getDepot, getDepotName, getExternalCycleDriverInfo, isDepotMode } from './restaurant';
import i18n from '../i18n';
import { getInitModel } from './initModels';

let singleton = Symbol();
let singletonEnforcer = Symbol();
var orderDispatched = 0;

export default class Markers {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use Markers.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Markers(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  createMarker(typeMarker, data, ...args) {
    let marker = null;

    switch (typeMarker) {
      case TYPE_MARKER.restaurant:
        marker = this.createMarkerRestaurant(data, ...args);
        break;
      case TYPE_MARKER.depotFromBusiness:
        marker = this.createMarkerDepotFromBusiness(data, ...args);
        break;
      case TYPE_MARKER.depot:
        marker = this.createMarkerDepot(data, ...args);
        break;
      case TYPE_MARKER.courier:
        marker = this.createMarkerCourier(data, ...args);
        break;
      case TYPE_MARKER.order:
        marker = this.createMarkerOrder(data, ...args);
        break;
      default:
        break;
    }

    return !marker || locationIsEmpty(marker.location) ? null : marker;
  }

  createMarkerByModelType(typeMarker, data, ...args) {
    let marker = null;

    switch (typeMarker) {
      case MODEL_TYPE.depot:
        marker = this.createMarkerDepot(data, ...args);
        break;
      case MODEL_TYPE.user:
        marker = this.createMarkerCourier(data, ...args);
        break;
      case MODEL_TYPE.serviceCities:
        marker = this.createMarkerServiceCity(data, ...args);
        break;
      case MODEL_TYPE.order:
        marker = this.createMarkerOrder(data, ...args);
        break;
      case MODEL_TYPE.namedPlaces:
        marker = this.createMarkerNamedPlace(data, ...args);
        break;
      case MODEL_TYPE.businessInfoModel:
        marker = this.createBusinessInfoMarker(data, ...args);
        break;
      default:
        break;
    }

    return !marker || locationIsEmpty(marker.location) ? null : marker;
  }

  createMarkerDepotFromBusiness(business, isWithContent = false) {
    return {
      id: business.id,
      businessId: business.id,
      location: business.location,
      icon: MarkerIcons.instance.addSvgRestouran(),
      cloudUrl: business.header || business.header_mini,
      applicableControlCenters: (business?.applicableControlCenters || []).filter(f=> f),
      content: isWithContent
        ? {
            title: business.name,
            phone: business.phone,
            address: getAddress(business)
          }
        : null,
      markerType: TYPE_MARKER.depot,
      zIndex: 0
    };
  }

  createMarkerDepot(depot, isWithContent = false) {
    return {
      id: depot.id,
      businessId: depot.businessId,
      location: depot.address && depot.address.location,
      icon: MarkerIcons.instance.addSvgRestouran(),
      cloudUrl: depot.logoUrl,
      applicableControlCenters: (depot.applicableControlCenters || []).filter(f=> f),
      content: isWithContent
        ? {
            title: depot.name,
            phone: depot.phone,
            address: getAddress(depot.address && depot.address.address)
          }
        : null,
      markerType: TYPE_MARKER.depot,
      zIndex: 0
    };
  }

  createMarkerCourier(user, isWithContent = false) {
    let marker = {};
    if(!user || !user.userId || !user.travelData || locationIsEmpty(user.travelData.location)) return null;

    user.color = user.color || ColorService.instance.getColor();
    const textValue = getShortName(user);
    const courierColor = getCourierColor(user);

    marker.id = user.userId;
    marker.groupId = user.userId;
    marker.icon = MarkerIcons.instance.addItemCourier(
      ICONS_COURIER_MARKER.courier,
      courierColor,
      textValue
    );
    marker.content = isWithContent ? this.getCourierContent(user) : null;
    marker.markerType = TYPE_MARKER.courier;
    marker.location = user.travelData.location;
    marker.controlCenterId = user.controlCenterId || (user.cycle && user.cycle.controlCenterId);
    marker.zIndex = 1;

    return marker;
  }

  createMarkerServiceCity(order, ...args) {
    return {
      id: order.id,
      location: getInitModel(MODEL_TYPE.location, order.location),
      icon: this.getIconMarker(null, '#007AFF', ICONS_MARKER_TYPE.empty, false),
      markerType: TYPE_MARKER.serviceCity,
    }
  }

  createMarkerOrder(order, group, user, active, isWithContent = false, depot) {
    if (!order || !group || !order.location || order.type) return;
    let color = active ? ORDER_STATE_COLOR.blue : getGroupColor(group, order);
    if(!group.isCourierGroup && depot && depot.color) {
      color = depot.color;
    }

    const icon = this.getIconMarker(
      order.state,
      color,
      order.markerType,
      group.isCourierGroup || false
    );

    return {
      id: order.id,
      orderId: order.orderId,
      icon,
      location: getInitModel(MODEL_TYPE.location, order.location),
      groupId: (group || {}).id,
      controlCenterId: order.controlCenterId,
      isLarge: (users, orders)=> isLastGroupOrder(order.id, orders, getUserByOrder(users, order)),
      markerType: TYPE_MARKER.order
    };
  }

  createExternalCycleMarkerOrder(order, externalCycle) {
    if (!order.location || order.type) return;
    return {
      id: order.id,
      orderId: order.orderId,
      icon: this.getIconMarker(order.state, ((externalCycle || {}).color || order.color || ORDER_STATE_COLOR.white), order.markerType, true),
      location: order.location,
      groupId: (externalCycle || {}).id,
      controlCenterId: order.controlCenterId,
      isExternalGroup: true,
      isLarge: (users, orders)=> isLastExternalCycleOrder(order, orders, externalCycle),
      markerType: TYPE_MARKER.order,
      zIndex: 2
    };
  }

  createExternalCycleMarkerCourier(externalCycle) {
    const marker = {};
    marker.id = externalCycle.id;
    marker.groupId = externalCycle.id;
    marker.icon = MarkerIcons.instance.addItemCourier(
      ICONS_COURIER_MARKER.courier,
      externalCycle.color,
      getExternalCycleShortName(externalCycle)
    );
    marker.content = {
      title: getExternalCycleDriverInfo(externalCycle, "name"),
      info: `${getExternalCycleDriverInfo(externalCycle, "carModel")} ${getExternalCycleDriverInfo(externalCycle, "carNumber")}`
    };
    marker.markerType = TYPE_MARKER.courier;
    marker.location = externalCycle.travelData && externalCycle.travelData.location;
    marker.isExternalGroup = true;
    marker.controlCenterId = externalCycle.controlCenterId || (externalCycle.travelData && externalCycle.travelData.controlCenterId);
    marker.zIndex = 1;

    return marker;
  }

  createMarkerRestaurant(id, title, location) {
    return {
      id: id,
      location: location,
      icon: MarkerIcons.instance.addSvgRestouran(),
      content: {
        title: title
      },
      markerType: TYPE_MARKER.restaurant,
      zIndex: 0
    };
  }

  createMarkerNamedPlace(place) {
    return {
      id: place.id,
      location: place.address ? place.address.location : null,
      icon: {
        url: MarkerIcons.instance.getPlaceIcon()
      },
      content: {
        title: place.name,
        address: place.address ? getAddress(place.address.address) : ""
      },
      draggable: true,
      markerType: TYPE_MARKER.place,
      zIndex: 100
    };
  }

  createBusinessInfoMarker(data) {
    return {
      id: data.id,
      icon: data.avatar
        ? MarkerIcons.instance.addSvgDepot(data.avatar)
        : MarkerIcons.instance.addSvgRestouran(),
      location: data.address && data.address.location,
      content: {
        title: data.name,
        address: getAddress(data.address && data.address.address)
      },
      markerType: TYPE_MARKER.depot,
    };
  }

  createMarkerByModel = (item, active, id) => {
    if (!item.location) return;
    const location = item.location;
    const addressUser = getAddress(item.address);
    const name = getUIName(item);

    let text = "";
    const orderId = item.orderId || "";
    if (isDepotMode() && item.depotId) {
      text = "#" + orderId + " / " + getDepotName(item);
    } else if (orderId || name) {
      text = "#" + orderId + " / " + name;
    }

    return {
      id: id || item.id,
      icon: MarkerIcons.instance.addItemType(
        ICONS_MARKER_TYPE.empty,
        active ? "blue" : "white"
      ),
      location: location,
      address: item.address,
      groupId: DEFAULT_ORDERS_GROUPS.Unsorted,
      content: {
        title: text,
        phone: item.consumerPhone ? item.consumerPhone : "",
        address: addressUser ? addressUser : ""
      },
      zIndex: active ? 10000 : null,
      markerType: TYPE_MARKER.order
    };
  };

  createDefaultMarker(data, markerType = TYPE_MARKER.order) {
    const marker = {
      id: data.id,
      location: {
        latitude: (data.location && (data.location.latitude || data.location.lat)) || 0,
        longitude: (data.location && (data.location.longitude || data.location.lng)) || 0
      },
      content: { title: data.title },
      markerType
    }

    switch (markerType) {
      case TYPE_MARKER.order:
        marker.icon = this.getIconMarker(null, null, ICONS_MARKER_TYPE.empty, false);
        break; 
      case TYPE_MARKER.depot:
        marker.icon = MarkerIcons.instance.addSvgRestouran();
        break; 
      default:
        break;
    }

    return marker;
  }

  getOrderContent(order) {
    if (!order) return {};
    const depotName = getDepotName(order);
    const contentTitle = isDepotMode() && depotName ? depotName : getUIName(order);
    const orderTime = getRelativeTime(
      Date.parse(order.date),
      i18n.t("covertTime.TIME_AGO_LABEL"),
      isRTL()
    );

    return {
      title: `${order.orderId ? ("#" + order.orderId + " / ") : ""} ${contentTitle} - ${orderTime}`,
      clientName: `${order.firstName} ${order.lastName}`.trim(),
      eta: getETATimeForOrder(order),
      strategy: order.deliveryStrategy,
      phone: order.consumerPhone,
      address: getAddress(order.address)
    };
  }

  getPendingOrderContent(order) {
    if (!order) return {};

    return {
      title: (order.id && ("#" + order.id)) || "",
      strategy: order.deliveryStrategy,
      phone: order.phone || "",
      address: getAddress(order.address)
    };
  }

  getDepotContent(depot) {
    if (!depot) return {};

    return {
      title: depot.name,
      phone: depot.phone,
      address: getAddress(depot.address.address)
    };
  }

  getCourierContent(user) {
    if (!user) return {};

    return {
      title: `${user.firstName} ${user.lastName}`,
      phone: user.phoneNumber
    };
  }

  getIconMarker(state, color, iconState, isMarkerCourier) {
    var iconMarker;
    if (state) {
      iconState = iconState || MarkerIcons.instance.convertStateToIcon(state, orderDispatched);
      iconMarker = (isMarkerCourier || state === ORDER_STATE.ready)
          ? MarkerIcons.instance.addItemType(iconState, color, false)
          : MarkerIcons.instance.addItemType(iconState, color, true);
      if (state === ORDER_STATE.onroute) orderDispatched++;
    } else if (isMarkerCourier) {
      iconMarker = MarkerIcons.instance.addItemType(ICONS_MARKER_TYPE.empty, color, false);
    } else {
      iconMarker = MarkerIcons.instance.addItemType(ICONS_MARKER_TYPE.empty, color, true);
    }

    return iconMarker;
  }

  getConvertedTargetTime(order) {
    return order.deliveryStrategy === DELIVERY_STRATEGY.concreteTime
      ? getStaticTime(order.targetTime, false, false)
      : null;
  }

  getRestaurantMarkers(restaurant, isFromState = false) {
    let markers = [];
    if (!restaurant) {
      if(!isFromState)
        return markers;
      const state = Store.store.getState();
      restaurant = state.restaurant.data;
    }

    if (isDepotMode()) {
      markers = restaurant.depots
        .map(depot => this.createMarker(TYPE_MARKER.depot, depot))
        .filter(marker=> marker);
    } else {
      const depot = getDepot(restaurant.depots, null, true);
      
      if(depot) {
        const depotMarker = this.createMarker(
          TYPE_MARKER.restaurant,
          depot.id,
          restaurant.friendlyName,
          (depot.address || {}).location
        );

        if(depotMarker) {
          markers.push(depotMarker);
        }       
      }     
    }

    return markers;
  }

  canShowMarker(courier, orders) {
    return (
      courier &&
      courier.travelData &&
      !locationIsEmpty(courier.travelData.location) &&
      courier.cycle &&
      (courier.cycle.shiftType === SHIFT_TYPE.Normal || (orders && orders.length))
    );
  }

  isNotSameMarkers = (newMarkers, markers, isCheckIcon = true, isCheckContent = false) => {
    return ((newMarkers || []).length !== (markers || []).length) || 
      (newMarkers || []).some(newMarker=> {
        const oldMarker = (markers || [])
          .find(marker => marker && newMarker && (marker.id === newMarker.id || (marker.orderId && newMarker.orderId && marker.orderId === newMarker.orderId)));          
        const isSame = (!oldMarker && !newMarker) ||
          (oldMarker && newMarker && isSameObjects(oldMarker.location, newMarker.location) &&
            (!isCheckIcon || isSameObjects(oldMarker.icon, newMarker.icon)) &&
            (!isCheckContent || isSameObjects(oldMarker.content, newMarker.content))
          );

        switch ((newMarker || {}).markerType) {
          case TYPE_MARKER.order:
            return !isSame || (oldMarker || {}).groupId !== (newMarker || {}).groupId;
          case TYPE_MARKER.courier:
            return !isSame || (oldMarker || {}).groupId !== (newMarker || {}).groupId;
          case TYPE_MARKER.depot:
            return !isSame;
          default:
            return false;
        }
      });
  }
}
