import React from "react";
import i18n from "../../i18n";

import SectionModel from "../../models/sectionModel";
import {
    OPERATION_TIMING_SETTING,
    OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY, OPERATION_TIMING_SETTINGS_GET
} from "../../store/reducers/restaurant/restaurant-actions";
import {CONTROL_TYPE, MODEL_CONTROL_TYPE, MODEL_TYPE, TYPE_ACTION, TYPE_SECTION_MODEL} from "../../utils/enums";
import {createControlModel} from "../controlModelFactory";
import {isMobileWidth,getRestaurantPaymentEnabled} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import FilterOptionsModel from "../../models/filterOptionsModel";
import OperationTimingSwitch from "../../app/components/OperationTimingSwitch";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import {getInitModel} from "../initModels";
import { getOperationModeSectionFilter } from '../filter';

export function createOperationTimingSettingsSectionModelEdit({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "settings.operationTiming.NAME",
                accessor: "name",
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: "settings.operationTiming.MAX_DELIVERY_TIME",
                accessor: "maxDeliveryTime",
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: "settings.operationTiming.WAITING_TIMES",
                accessor: "waitingTimes",
                isEditable: true,
                type: CONTROL_TYPE.arrayItems,
            },
            {
                title: "settings.operationTiming.MARKETPLACE_WAITING_TIMES",
                accessor: "marketplaceWaitingTimes",
                isEditable: true,
                type: CONTROL_TYPE.arrayItems,
                isShow: () => getRestaurantPaymentEnabled()
            }
        ],
        actionLoad: OPERATION_TIMING_SETTINGS_GET,
        actionName: OPERATION_TIMING_SETTING,
        initNewItemModel: item => item || getInitModel(MODEL_TYPE.operationTiming),
        typeAction: args.typeAction,
        showDeleteButton: true,
        typeModel:  args.typeAction === TYPE_ACTION.edit ? TYPE_SECTION_MODEL.operationTimingEdit : TYPE_SECTION_MODEL.operationTimingNew,
        controls: [
            createControlModel({
              type: MODEL_CONTROL_TYPE.back,
              isShow: isMobileWidth,
            }),
        ],
        key: "name",
        parentUrl: TYPE_SECTION_MODEL.operationTiming,
        getLabel: () => {
            const params = getHistoryLocationParams(window.location.search)
            return params.id;
        },
        breadcrumbs: [
            createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
            createBreadcrumbModel({ label: "settings.operationTiming.LABEL" }),
        ],
        mappingLambda: state => ((state.restaurant.data.operations || {}).profiles || [])
            .map(p => ({
              ...p,
              id: p.name,
              activeProfile: (state.restaurant.data.operations && state.restaurant.data.operations.activeProfile) || null,
              defaultProfile: (state.restaurant.data.operations && state.restaurant.data.operations.defaultProfile) || null
            })),
    });
}

export function createOperationTimingSettingsSectionModel({ ...args }) {
    return new SectionModel({
        filterOptions: createOperationModeSectionFilter(),
        columns: [
            {
                title: "settings.operationTiming.NAME",
                accessor: "name",
            },
            {
                title: "settings.operationTiming.MAX_DELIVERY_TIME",
                accessor: "maxDeliveryTime",
                centered: true,
            },
            {
                title: "settings.operationTiming.DEFAULT_PROFILE",
                component: data => {
                    return <OperationTimingSwitch
                        value={data.name}
                        defaultValue={data.defaultProfile}
                        action={OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY}
                        paramToToggle={"defaultProfile"}
                    />
                }
            },
            {
                title: "settings.operationTiming.ACTIVE_PROFILE",
                component: data => {
                    return <OperationTimingSwitch
                        value={data.name}
                        defaultValue={data.activeProfile}
                        action={OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY}
                        paramToToggle={"activeProfile"}
                    />
                }
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                fixedWidth: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.operationTimingEdit,
                iconName: "editItem",
                tooltipText: i18n.t("settings.operationTiming.EDIT_TOOLTIP"),
                isHideDeleteButton: item => item.name === item.activeProfile
            },
            {
                //empty item for align items
                title: "",
                accessor: "enabled",
                width: "90px",
                fixedWidth: "90px",
                type: CONTROL_TYPE.label,
                hideInHeader: true,
                isInline: true,
                isShow: item => item.name === item.activeProfile
            },
        ],
        key: "name",
        typeModel: TYPE_SECTION_MODEL.operationTiming,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.operationTimingNew,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
        ],
        showDeleteButton: true,
        isForceUpdate: true,
        tableMinWidth: 900,
        label: "settings.operationTiming.LABEL",
        actionName: OPERATION_TIMING_SETTING,
        actionLoad: OPERATION_TIMING_SETTINGS_GET,
        mappingLambda: state => ((state.restaurant.data.operations || {}).profiles || [])
            .map(p => ({
                ...p,
                id: p.name,
                activeProfile: (state.restaurant.data.operations && state.restaurant.data.operations.activeProfile) || null,
                defaultProfile: (state.restaurant.data.operations && state.restaurant.data.operations.defaultProfile) || null
            })),
    })
}

function createOperationModeSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(operationMode) {
        return getOperationModeSectionFilter(this.selected, operationMode);
    });

    return filterOptionsModel;
}
