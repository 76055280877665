import React from "react";
import "./index.scss";
import classNames from "classnames";

import preloader from "../../../assets/images/preloader.GIF";

export const WaiterSpan = ({
  useClass,
  processing,
  spanText,
  handleOnClick,
  isShake
}) => {
  return (
      <>
        <span className={classNames("spanImg", "waiter-tooltip")}>
        {processing && (
            <img className="preloader" src={preloader} alt="preloader"/>
        )}
          <span
              className={classNames(useClass, {waiterShake: isShake})}
              onClick={handleOnClick}
          >
          {spanText}
        </span>
      </span>
      </>
  );
};
