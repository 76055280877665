import { ADD, DELETE, EDIT, GET, UPDATE } from "../common-actions";

export const BILLING_INFO = "BILLING_BASE_INFO";
export const BILLING_INFO_GET = BILLING_INFO + GET;
export const BILLING_INFO_UPDATE = BILLING_INFO + UPDATE;
export const BILLING_INFO_UPDATE_DATA = BILLING_INFO_UPDATE + "_DATA";
export const BILLING_INFO_PLANS_GET = BILLING_INFO + "_PLANS_GET";
export const BILLING_INFO_PLANS_SET = BILLING_INFO + "_PLANS_SET";
export const BILLING_INFO_USAGE = "BILLING_INFO_USAGE";
export const BILLING_INFO_USAGE_GET = BILLING_INFO_USAGE + GET;
export const GET_BILLING_CARDS = "GET_BILLING_CARDS";
export const BILLING_CARD = "BILLING_CARD";
export const BILLING_CARD_ADD = BILLING_CARD + ADD;
export const BILLING_CARD_DELETE = BILLING_CARD + DELETE;
export const BILLING_CARD_EDIT = BILLING_CARD + EDIT;
export const BILLING_CARD_SET = BILLING_CARD + "_SET";
export const BILLING_ADDRESSES = "BILLING_ADDRESSES";
export const BILLING_ADDRESSES_GET = BILLING_ADDRESSES + GET;
export const BILLING_ADDRESSES_ADD = BILLING_ADDRESSES + ADD;
export const BILLING_ADDRESSES_DELETE = BILLING_ADDRESSES + DELETE;
export const BILLING_ADDRESSES_EDIT = BILLING_ADDRESSES + EDIT;
export const BILLING_ADDRESSES_SET_ADDRESS = BILLING_ADDRESSES + "_SET_ADDRESS";
export const SET_BILLING_CARDS = "SET_BILLING_CARDS";
export const ADD_NEW_BILLING_CARD = "ADD_NEW_BILLING_CARD";
export const DELETE_BILLING_CARD = "DELETE_BILLING_CARD";
export const SET_BILLING_ADDRESSES = "SET_BILLING_ADDRESSES";
export const ADD_NEW_BILLING_ADDRESS = "ADD_NEW_BILLING_ADDRESS";
export const DELETE_BILLING_ADDRESS = "DELETE_BILLING_ADDRESS";
export const SET_BILLING_IS_FETCH = "SET_BILLING_IS_FETCH";

export function updateBillingInfo(data) {
    return { type: BILLING_INFO_UPDATE, data };
};

export const setBillingPayments = (data) => ({
    type: SET_BILLING_CARDS,
    payload: data
});

export const deleteBillingPaymentAC = (payment) => ({
    type: DELETE_BILLING_CARD,
    payload: payment
});

export const addNewBillingPayment = (payment) => ({
    type: ADD_NEW_BILLING_CARD,
    payload: payment
});

export const setBillingAddresses = (data) => ({
    type: SET_BILLING_CARDS,
    payload: data
});

export const deleteBillingAddressAC = (payment) => ({
    type: DELETE_BILLING_CARD,
    payload: payment
});

export const addNewBillingAddress = (payment) => ({
    type: ADD_NEW_BILLING_CARD,
    payload: payment
});

export const setIsBillingFetch = isFetch => ({
    type: SET_BILLING_IS_FETCH,
    payload: isFetch
});

export function updateBillingInfoData(data) {
    return { type: BILLING_INFO_UPDATE_DATA, data };
};