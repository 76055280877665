import MenuModel from './menuModel';

import { MARKETPLACE_SETTINGS_MODEL } from '../utils/enums';

export function createAppBuilderMenu() {
    return [
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.listPromotion,
            title: "settings.listPromotion.LABEL",
            icon: "listPromotion",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.listPromotion)
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.marketplace,
            title: "settings.marketplaceMessage.MARKETPLACE",
            icon: "marketplace",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.marketplace),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.businessCategories,
            title: "settings.businessCategories.LABEL",
            icon: "businessCategories",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.businessCategories),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
            title: "settings.menuTemplate.LABEL",
            icon: "menuTemplate",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.menuTemplate),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.cancelReason,
            title: "settings.cancelReason.LABEL",
            icon: "cancelReason",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.cancelReason),
        }),
        new MenuModel({
            pathname: MARKETPLACE_SETTINGS_MODEL.pages,
            title: "settings.pages.LABEL",
            icon: "marketplace_page",
            searchParams: state => state,
            active: location => getIsActive(location, MARKETPLACE_SETTINGS_MODEL.pages)
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
