import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import classNames from "classnames";
import "./index.scss";

import {currentUserIsDepotRole, currentUserIsSupervisorRole, getFullName} from "../../../services/userModel";
import {getUserConnectionData} from "../../../utils/auth-util";
import {getConvertedCloudUrl} from "../../../utils/image-util";
import {createAction} from "../../../utils/sagaHelper";

import {LOGOUT} from "../../../store/reducers/authentication/authentication-actions";
import {DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK} from "../../../store/reducers/userModel/userModel-actions";
import {RECONCILIATIONS_MODEL} from "../../../utils/enums.js";
import {getRestaurantPaymentEnabled} from "../../../services/restaurant.js";

const UserControlsMenu = ({
  t,
  users,
  restaurant,
  history,
  logout,
  getDepotSquareAssociatedLocation,
  showItemsInPlace,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const ref = useRef();
  const userConnectionData = getUserConnectionData();
  const userId = userConnectionData.unique_name;
  const user = users && users.find(user => user.userId === userId);
  const userName =
    (user && user.userName) || getFullName(user) || userConnectionData.sub;
  const avatar = getConvertedCloudUrl(user && user.avatarUrl);

  const getAccountName = () => {
    let name = (restaurant && restaurant.friendlyName) || "";
    const activeCenter =
      restaurant &&
      restaurant.activeControlCenter &&
      restaurant.controlCenters &&
      restaurant.controlCenters.find(
        controlCenter =>
          controlCenter.controlCenterId ===
          restaurant.activeControlCenter.controlCenterId
      );
    if (activeCenter) name += " " + (activeCenter.name || "");
    return name;
  };

  const isShowBillingItem = () => {
    return restaurant && restaurant.recurlyConfiguration && restaurant.recurlyConfiguration.accountId;
  };

  const handleOnLogout = () => {
    logout();
    history.push("/login");
  };

  const handleOnBilling = () => {
    history.push("/billing");
  };
  const handleOnReconciliations = () => {
    history.push(RECONCILIATIONS_MODEL.settings);
  };

  const handleOnDepotSquareIntegration = () => {
    getDepotSquareAssociatedLocation();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target))
        setMenuOpen(false);
    }

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const Items = () => {
    return <ul>
      {currentUserIsDepotRole() && (
          <li onClick={handleOnDepotSquareIntegration}>
            <div className={classNames("icon", "square")} />
            <div>{t("settings.square.ICON_TOOLTIP")}</div>
          </li>
      )}
      {isShowBillingItem() && (
          <li onClick={handleOnBilling}>
            <div className={classNames("icon", "billing")} />
            <div>{t("header.menu.BILLING_LABEL")}</div>
          </li>
      )}
      {(currentUserIsSupervisorRole() && getRestaurantPaymentEnabled()) && (
          <li onClick={handleOnReconciliations}>
            <div className={classNames("icon", "billing")} />
            <div>{t("header.menu.RECONCILIATIONS_LABEL")}</div>
          </li>
      )}
      <li onClick={handleOnLogout}>
        <div className={classNames("icon", "logoutIcon")} />
        <div>{t("header.user.LOG_OUT_LABEL")}</div>
      </li>
    </ul>
  }

  return (
      <div ref={ref} className={classNames("userControlsMenu")}
           onClick={() => setMenuOpen(!isMenuOpen)}
      >
        <div className={'userContents'}>
          <div>
            {avatar && (
                <img src={avatar} alt="avatar" className={classNames("avatar")} />
            )}
          </div>
          <div className={classNames("info")}>
            <div
                className={classNames("datauser", "restaurant")}
                title={getAccountName()}
            >
              {getAccountName()}
            </div>
            <div className={classNames("datauser", "userName")} title={userName}>
              <div>{userName}</div>
              <div className={classNames("menuArrow", { open: isMenuOpen })} />
            </div>
          </div>
        </div>
        {/*showItemsInPlace*/}
        {isMenuOpen && !showItemsInPlace && <Items/>}
        {showItemsInPlace && <Items/>}
      </div>
  );
};

const mapStateToProps = state => ({
  users: state.userModel.data || [],
  restaurant: state.restaurant.data,
  menuHeaderPriority: state.menu.menuHeaderPriority
});

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch({ type: LOGOUT }),
    getDepotSquareAssociatedLocation: data =>
      dispatch(createAction(DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK, data))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(UserControlsMenu))
);
