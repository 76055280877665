import React from "react";
import classNames from "classnames";
import "./index.scss";

import { getFullName } from "../../../services/userModel";

import { Avatar } from "../Avatar/Avatar";

export default ({ isShowName = true, value }) => (
  <div className={classNames("userInfoControl")}>
    <Avatar avatar={value?.avatarUrl} color={value?.color} />
    {isShowName && (
      <div className={classNames("info")}>{getFullName(value)}</div>
    )}
  </div>
);
