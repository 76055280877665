import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";

export function assistanceServerModel(order) {
  return {
    Id: order.id,
    ParsedOrder: {
      Notes: order.notes,
      FirstName: order.firstName,
      LastName: order.lastName,
      CustomerPhone: order.customerPhone,
      OrderId: order.orderId,
      Date: order.date,
      PaymentInfo: order.paymentInfo,
      Amount: order.amount,
      Content: getInitModel(MODEL_TYPE.contentServerModel, order.content),
      Currency: order.currency,
      Takeaway: !!order.type,
      IsCache: order.isCache,
      IsHighPriority: order.isHighPriority,
      IsExtraSize: order.isExtraSize,
      TargetTime: order.targetTime,
      Payments: order.payments
    }
  };
}
