export function adminOrderProductServerModel(product) {
    const item = product || {};
    const newItem  = {
        id: item.id,
        name: item.name,
        ingredients: item.ingredients || [],
        options: (item.options || []).map(option=> ({
            id: option.id,
            suboptions: option.suboptions.map(suboption=> ({
                id: suboption.id,
                price: suboption.price,
                quantity: suboption.quantity,
                half_option: suboption.half_option
            }))
        })),
        price: item.price,
        full_price: item.full_price,
        quantity: item.quantity
    };
  
    return newItem;
}