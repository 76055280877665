import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./index.scss";

import { getFormattedDate, TIME_FORMAT_USER } from "../../../utils/convertTime";

const CurrentTime = () => {
  const [time, setTime] = useState(new Date());
  useEffect(
    () => {
      const id = setInterval(() => {
        setTime(new Date());
      }, 60000);
      return () => {
        clearInterval(id);
      };
    },
    [time]
  );
  return (
    <span className={classNames("currentTime")}>
      {getFormattedDate(time, TIME_FORMAT_USER)}
    </span>
  );
};

export default CurrentTime;
