export function depotsMenuCopyModel(data) {
    const { id, template_id, global_template_id, business_id, type } = data || {};

    return {
        id: id || "",
        template_id: template_id || "",
        global_template_id: global_template_id || "",
        business_id: business_id || "",
        type: type || null
    }
}
