import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";
export function contentServerModel(content) {
  const contentModel = getInitModel(MODEL_TYPE.content);
  if (!content) {
    return {
      Addons: contentModel.addons,
      Item: contentModel.item,
      Price: contentModel.price,
      Quantity: contentModel.quantity
    };
  } else {
    return {
      Addons: content.addons
        ? content.addons.map(addon =>
            getInitModel(MODEL_TYPE.addonServerModel, addon)
          )
        : contentModel.addons,
      Item: content.item || contentModel.item,
      Price: content.price || contentModel.price,
      Quantity: content.quantity || contentModel.quantity
    };
  }
}
