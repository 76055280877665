import React from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import "./index.scss";

import { ORDER_TYPE, DELIVERY_STRATEGY, INTEGRATION_TYPE } from "../../../utils/enums";
import { defaultPromiseResolve } from "../../../utils/objects-util";
import {
  getPlaceOrAddress,
  getPickupPlaceOrAddress,
  getOrderTime,
  getConvertedTargetTime,
  getIsShowReadinessTime
} from "../../../services/order";
import { getAppartmentNumber } from './../../../services/order';
import { getDepotName, getDepotPhone, getDepotAvatar } from "../../../services/restaurant";

import TimeAsap from "../TimeAsap";
import OrderStateControl from "../OrderStateControl";
import IconButton from "../IconButton";
import ReadinessTimeInfo from "../ReadinessTimeInfo";

const GroupbarOrderItemPreview = ({
  t,
  order,
  selected,
  changeOrderIndex,
  onOrderTitleClick,
  onSetBaseWaiter
}) => {
  const handleOnChangeOrderIndex = newIndex => {
    onSetBaseWaiter(true).then(()=> {
      changeOrderIndex({ order, newIndex }).then(()=> {
        onSetBaseWaiter(false);
      }, error=> {
        toast.error(error);
        onSetBaseWaiter(false);
      });
    });    
  }

  const depotAvatar = order.type === ORDER_TYPE.pickUp ? getDepotAvatar(order) : null;

  return (
    <div id={order.id} key={order.id}
      className={classNames("groupbarOrderItem", { selected: selected })}
      style={{width: "360px"}}
    >
      <div className={classNames("data")}>
        <div className={classNames("leftSide")}>
          <div className={classNames("gripper")} />
          <div className={classNames("arrow-top")} onClick={() => handleOnChangeOrderIndex(order.index - 1) } />
          <div className={classNames("arrow-bottom")} onClick={() => handleOnChangeOrderIndex(order.index + 1) } />
        </div>
        <div className={classNames("centerSide")}>
          <div className={classNames("title", { vip: order.isHighPriority })} onClick={() => onOrderTitleClick(order)}>
            {order.isHighPriority && (<IconButton isIcon={true} iconName="crown" onClick={defaultPromiseResolve} />)}
            {getDepotName(order)}
            {order.type !== ORDER_TYPE.pickUp && (
              <span className={classNames("divider")}>&nbsp;/&nbsp;</span>
            )}
            {order.type !== ORDER_TYPE.pickUp && (
              <span>{order.orderId}</span>
            )}
          </div>
          <div className={classNames("info")}>
            <div className={classNames("infoItem")}>
              <span className={classNames("addressIcon")} />
              <span>
                {order.type !== ORDER_TYPE.pickUp ? getPlaceOrAddress(order) : getPickupPlaceOrAddress(order)}
                {getAppartmentNumber(order) && (
                  <span>&nbsp;{t("dashboard.orders.APPARTMENT")}:&nbsp;{getAppartmentNumber(order)}</span>
                )}
              </span>
            </div>
            <div className={classNames("infoItem")}>
              <span className={classNames("notesIcon")} />
              <span className={classNames("notes")}>
                {order.type !== ORDER_TYPE.pickUp && (order.notes)}
              </span>
            </div>
            <div className={classNames("infoItem")}>
              <span className={classNames("phoneIcon")} />
              <span>{order.type !== ORDER_TYPE.pickUp ? order.consumerPhone || "--" : getDepotPhone(order) || "--"}</span>
            </div>
          </div>
        </div>
        {order.type === ORDER_TYPE.pickUp && (
          <div className={classNames("rightSide", "depotRightSide")}>
            {depotAvatar && (<img alt="depot-avatar" src={depotAvatar} />)}
          </div>
        )}
        {order.type !== ORDER_TYPE.pickUp && (
          <div className={classNames("rightSide")}>
            <div className={classNames("block-time", { readiness: getIsShowReadinessTime(order) })}>
              <TimeAsap value={order} isWithIcon={true} />
              {order.deliveryStrategy === DELIVERY_STRATEGY.concreteTime && (
                <div className={classNames("targetTimeBlock")}>
                  <IconButton tooltipText={t("restaurateur.orders.PREOPDER_TARGET_TIME")} isIcon={true} iconName={"targetTimes"}/>
                  <span>{getConvertedTargetTime(order, true)}</span>
                </div>
              )}
              <ReadinessTimeInfo withIcon value={order} />
            </div>
            <div className={classNames("associateContainer")}>
              <OrderStateControl order={order} hideAssociate={true} />
              <div className={classNames("order-arrow")}>
                <div className={classNames("arrow-icon")} />
              </div>
            </div>
            <div className={classNames("block-time")}>
              {order.integrationType === INTEGRATION_TYPE.ordering && (
                <IconButton isIcon={true} iconName={"appStore"} onClick={defaultPromiseResolve} />
              )}
              {order.integrationType === INTEGRATION_TYPE.kiosk && (
                <IconButton isIcon={true} iconName={"kiosk"} onClick={defaultPromiseResolve} />
              )}&nbsp;
              <div className={classNames("segment-img")} />
              <div>{getOrderTime(order)}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(GroupbarOrderItemPreview);
