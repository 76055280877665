import React, {useState} from "react";
import classNames from "classnames";
import "./index.scss";

import { getConvertedCloudUrlBySettings } from "../../../utils/image-util";
import { CROP_MODE } from "../../../utils/enums";
import { getKeyByValue } from "../../../utils/objects-util";

const ItemLogo = ({ value, settings }) => {
    const [mediaError, setMediaError] = useState(false);

    return (
        <div className={classNames("itemLogo")}>
            {!mediaError && value && value.includes("/image/") && (
                <img alt="itemLogo"
                    onError={() => setMediaError(true)}
                    src={settings 
                        ? getConvertedCloudUrlBySettings(value, settings.width, settings.height, getKeyByValue(CROP_MODE, settings.crop_mode)) 
                        : value} 
                />
            )}
            {!mediaError && value && value.includes("/video/") && (
                <video alt="itemLogo"
                     onError={() => setMediaError(true)}
                     src={settings
                         ? getConvertedCloudUrlBySettings(value, settings.width, settings.height, getKeyByValue(CROP_MODE, settings.crop_mode))
                         : value}
                />
            )}
            {(!value || mediaError) && <div className={classNames("imageEmpty")}></div>}
        </div>
    );
}

export default ItemLogo;