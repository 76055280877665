import React from "react";
import "./index.scss";
import classNames from "classnames";
import preloader from "../../../assets/images/preloader.GIF";

export const ButtonDiv = ({
  useClass,
  useIcon,
  processing,
  handleOnClick,
  isShake
}) => (
  <div className={classNames(useClass, "waiter-tooltip", { waiterShake: isShake })} onClick={handleOnClick}>
    <div className={classNames(useIcon)} />
    {processing && (<img className="preloader" src={preloader} alt="preloader" />)}
  </div>
);
