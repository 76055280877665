import React from "react";
import classNames from "classnames";
import "./index.scss";

import preloader from "../../../assets/images/preloader.GIF";

export const Button = ({
  useClass,
  processing,
  spanText,
  handleOnClick,
  disabled,
  isShake
}) => (
  <button className={classNames(useClass ? useClass : "", {  disabled: processing || disabled, waiterShake: isShake })}
    onClick={handleOnClick}
    disabled={disabled}
    type="submit"
  >
    <span>{spanText}</span>
    {processing && <img src={preloader} alt="preloader" />}
  </button>
);
