import i18n from '../../i18n';

import SectionModel from '../../models/sectionModel';
import { PERSONAL_SETTING, PERSONAL_SETTING_PASSWORD } from '../../store/reducers/userModel/userModel-actions';
import { CONTROL_TYPE, languages, MODEL_CONTROL_TYPE, TYPE_PATTERN, TYPE_SECTION_MODEL } from '../../utils/enums';
import {getFullName, getPersonalSettings, getUserName} from '../userModel';
import { getPattern } from '../../utils/objects-util';
import User from '../user';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import { createControlModel } from '../controlModelFactory';
import { isMobileWidth } from '../restaurant';

export function createPersonalSettingsSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
              title: "settings.personalSettings.personalSettings.LABEL",
              isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: '',
                        accessor: 'avatarUrl',
                        isEditable: true,
                        type: CONTROL_TYPE.image,
                    },
                    {
                        title: 'settings.personalSettings.personalSettings.NAME_LABEL',
                        accessor: 'userFirstAndLastname',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'settings.personalSettings.personalSettings.PHONE_NUMBER_LABEL',
                        accessor: 'phoneNumber',
                        isEditable: true,
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'settings.personalSettings.personalSettings.EMAIL_LABEL',
                        accessor: 'email',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'settings.users.USER_TOOLS_MANAGER_CHANGE_PASSWORD_TOOLTIP',
                        width: 35,
                        isEditable: true,
                        type: CONTROL_TYPE.link,
                        link: TYPE_SECTION_MODEL.personalSettingsConfirmPassword,
                        iconName: 'passwordIcon',
                        tooltipText: i18n.t('settings.users.USER_TOOLS_MANAGER_CHANGE_PASSWORD_TOOLTIP'),
                    },
                ],
            },
            {
                title: 'settings.personalSettings.visualSettings.LABEL',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.personalSettings.visualSettings.TAKE_AWAY_LABEL',
                        accessor: 'isHideTakeawayGroup',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.TIME_TILL_AUTO_SNOOZE',
                        measure: i18n.t('settings.settings.numericalSettings.TIME_MEASURE'),
                        accessor: 'timeTillAutoSnooze',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.LANGUAGE_LABEL',
                        accessor: 'language',
                        connect: () => ({
                            values: languages(),
                        }),
                        isEditable: true,
                        type: CONTROL_TYPE.select,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.SNOOZE_TIMEOUT',
                        measure: i18n.t('settings.settings.numericalSettings.TIME_MEASURE'),
                        accessor: 'snoozeTimeout',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.HIDE_DELIVERY_REGIONS',
                        accessor: 'isHideDeliveryRegions',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.ENABLE_ALARM_POPUPS',
                        accessor: 'enableAlarmPopups',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.ORDERS_COLORS',
                        accessor: 'ordersColors',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.NEW_ORDER_SOUND',
                        accessor: 'newOrderSoundNotification',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },

                    {
                        title: 'settings.personalSettings.visualSettings.NEW_CONSUMER_ORDER_NOTIFICATION_ENABLED',
                        accessor: 'consumerOrderCreatedNotificationEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.ORDER_NOT_DELIVERED_IN_TIME_NOTIFICATION_ENABLED',
                        accessor: 'orderNotDeliveredInTimeNotificationEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.COURIERS_COMING_BACK_NOTIFICATION_ENABLED',
                        accessor: 'courierComingBackEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.COURIERS_WRONG_ROUTE_NOTIFICATION_ENABLED',
                        accessor: 'courierWrongRouteEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.COURIERS_READY_TO_DISPATCH_NOTIFICATION_ENABLED',
                        accessor: 'courierReadyToDispatchEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.ASSISTANCE_EXISTS_NOTIFICATION_ENABLED',
                        accessor: 'assistanceExistsEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.COURIERS_STOPPED_NOTIFICATION_ENABLED',
                        accessor: 'courierStoppedEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.COURIERS_LOST_NOTIFICATION_ENABLED',
                        accessor: 'courierLostEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.ORDER_CAN_LATE_NOTIFICATION_ENABLED',
                        accessor: 'orderCanLateEnabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.SORT_ORDER_BY_READINESS_TIME',
                        accessor: 'sortOrdersBasedOnReadinessTime',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.SHOW_ALL_DEPOTS_ON_MAP_LABEL',
                        accessor: 'showAllDepotsOnMap',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.SHOW_EXTRA_SIZE_ORDERS_SEPARATELY_ENABLED',
                        accessor: 'isShowExtraSizeGroup',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.IS_HIDE_ORDER_ID',
                        accessor: 'isHideOrderId',
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.SHOW_ORDER_ID_ON_MAP',
                        accessor: 'showOrderIdOnMap',
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.personalSettings.visualSettings.FILTER_MAP_OBJECTS_BY_CONTROL_CENTER',
                        accessor: 'filterMapObjectsByControlCenter',
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: "settings.personalSettings.visualSettings.ALWAYS_SHOW_ORDER_TARGET_TIME",
                        accessor: "alwaysShowOrderTargetTime",
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switch
                    },
                ],
            },
        ],
        key: 'userId',
        actionName: PERSONAL_SETTING,
        initNewItemModel: user => {
            return {
                ...user,
                userFirstAndLastname: getFullName(user),
            };
        },
        typeAction: args.typeAction,
        isPasswordConfirm: true,
        stayAfterSave: false,
        parentUrl: TYPE_SECTION_MODEL.personalSettings,
        typeModel: TYPE_SECTION_MODEL.personalSettings,
        saveControlsConfig: {
            cancel: { title: 'basic.RESET_BUTTON', action: () => {}, isInit: true },
        },
        label: 'settings.personalSettings.LABEL',
        breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SETTINGS_LABEL' })],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        mappingLambda: state =>
            getPersonalSettings(
                state.userModel.data.find(f => f.userId === User.instance.getCurrentUserInfo()['userId'])
            ),
    });
}

export function createPersonalSettingsConfirmPasswordSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.map.NAME_LABEL',
                accessor: 'userName',
                pattern: getPattern(TYPE_PATTERN.userName),
                patternError: i18n.t('settings.users.USER_NAME_PATTERN_ERROR'),
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.personalSettings.personalSettings.NEW_PASSWORD_LABEL',
                accessor: 'newPassword',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.password,
            },
            {
                title: 'settings.personalSettings.personalSettings.RETYPE_PASSWORD_LABEL',
                accessor: 'retypePassword',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.password,
            },
        ],
        key: 'userId',
        actionName: PERSONAL_SETTING_PASSWORD,
        initNewItemModel: user => {
            return {
                userId: user.userId,
                userName: user.userName || getUserName(user),
                newPassword: '',
                retypePassword: '',
            };
        },
        typeAction: args.typeAction,
        isPasswordConfirm: true,
        typeModel: TYPE_SECTION_MODEL.personalSettingsConfirmPassword,
        parentUrl: TYPE_SECTION_MODEL.personalSettings,
        mappingLambda: () => User.instance.getCurrentUserInfo(),
    });
}
