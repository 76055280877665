import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import User from "../../../services/user";

import { TYPE_MARKER } from "../../../utils/enums";
import { includedMarkerTypesToCheck } from "../../../utils/constants";

import MapMarkerDepotIcon from "../MapMarkerDepotIcon";
import MapMarkerOrderIcon from "../MapMarkerOrderIcon";

const MapMarker = ({
  id,
  key,
  avatar,
  zIndex,
  orderShakeId,
  marker,
  users,
  orders,
  showOrderIdOnMap,
  onMouseOver,
  onMouseOut,
  onClick  
}) => {
  const isLarge = marker.isLarge && marker.isLarge(users, orders);  
  
  const handleOnMouseOver = () => {
    onMouseOver && onMouseOver(marker);
  }

  const handleOnMouseOut = () => {
    onMouseOut && onMouseOut(null);
  }

  const handleOnClick = e => {
    onClick && onClick(e, marker);
  }

  useEffect(()=> {
  
  }, [id, orderShakeId, marker, avatar, showOrderIdOnMap]); 

  return (
    <div key={key} 
      id={`marker-${id}`} 
      className={classNames("markerContainer", marker.markerType, 
        { 
          markerShake: id && id === orderShakeId, 
          isLarge: isLarge,
          orderIdOnMap: showOrderIdOnMap && (marker || {}).orderId
        }
      )}
      style={{ zIndex: zIndex }}
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
      onClick={handleOnClick}    
    >
      {avatar && includedMarkerTypesToCheck.includes(marker.markerType) && (<img alt={id} src={avatar} />)}
      {marker.markerType === TYPE_MARKER.depot && (
        <MapMarkerDepotIcon id={id} avatar={avatar} value={marker.cloudUrl} />
      )}
      {marker.markerType === TYPE_MARKER.order && (<MapMarkerOrderIcon id={id} avatar={avatar} marker={marker} />)}
    </div>
  );
}

const mapStateToProps = state => ({
  orderShakeId: state.marker.orderShakeId,
  showOrderIdOnMap: User.instance.getCurrentUserConfigurationProp("showOrderIdOnMap")
});

export default connect(mapStateToProps)(MapMarker);
