import {
    ADD_DIALOG_SUPPORT_CHAT,
    PULLED_SUPPORT_DIALOG,
    SAVE_SUPPORT_CHAT_SESSION,
    SET_DIALOGS_SUPPORT_CHAT,
    SET_SHOW_SUPPORT_CHAT, SET_UNREAD_MESSAGES
} from "./supprtChat-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    session: null,
    isShowChat: false,
    dialogs: [],
    unreadMessages: 0,
};

const supportChat = (state = initState, {type, payload}) => {
    switch (type) {
        case REMOVE_ALL_DATA: {
            return initState;
        }
        case SAVE_SUPPORT_CHAT_SESSION: {
            return {
                ...state,
                session: payload,
            }
        }
        case SET_UNREAD_MESSAGES: {
            return {
                ...state,
                unreadMessages: payload ,
            }
        }
        case SET_SHOW_SUPPORT_CHAT: {
            return {
                ...state,
                isShowChat: payload,
            }
        }
        case SET_DIALOGS_SUPPORT_CHAT: {
            return {
                ...state,
                dialogs: payload,
            }
        }
        case ADD_DIALOG_SUPPORT_CHAT: {
            return {
                ...state,
                dialogs: [...state.dialogs, payload],
            }
        }
        case PULLED_SUPPORT_DIALOG: {
            return {
                ...state,
                dialogs: state.dialogs.filter(d => d.id !== payload)
            }
        }
        default: {
            return state;
        }
    }
}

export default supportChat;