import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";
export function addressServerModel(address) {
  const addressModel = getInitModel(MODEL_TYPE.address);
  return {
    City: address.city || addressModel.city,
    Country: address.country || addressModel.country,
    Entrance: address.entrance || addressModel.entrance,
    Notes: address.notes || addressModel.notes,
    Organization: address.organization || addressModel.organization,
    Postal: address.postal || addressModel.postal,
    State: address.state || addressModel.state,
    Street: address.street || addressModel.street,
    StreetNumber: address.streetNumber || addressModel.streetNumber
  };
}
