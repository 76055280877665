import React from 'react';
import {useDispatch} from "react-redux";
import {createAction} from "../../../utils/sagaHelper";
import SwitchControl from "../SwitchControl";
const OperationTimingSwitch = ({value, defaultValue, action, paramToToggle}) => {
    const dispatch = useDispatch();
    const handleOnChange = () => {
        if(value !== defaultValue) {
            dispatch(createAction(action, {name: value, paramToToggle: paramToToggle}));
        }
    }

    return <SwitchControl onlyParentState onClick={handleOnChange} value={value === defaultValue} />
}
export default OperationTimingSwitch;