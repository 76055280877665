import i18n from "../i18n";
import { BILLING_PATHS, TYPE_INFO_MESSAGE, TYPE_STORE_DATA } from "../utils/enums";
import BillingService from "./billing";
import { getStoreDataByType } from "./storeData";
import {currentUserIsWithoutControlCenter} from "./userModel.js";
import {excludedUrls} from "../utils/constants.js";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class InfoMessageService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error(
        "Instantiation failed: use Color.getInstance() instead of new."
      );
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new InfoMessageService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getRoutePath = type => {
    switch (type) {
      case TYPE_INFO_MESSAGE.emptyControlcenter:
        return "/info";
      default:
        return "/";
    }
  }

  getMessage = type => {
    switch (type) {
      case TYPE_INFO_MESSAGE.emptyControlcenter:
        return i18n.t("infoMessage.EMPTY_CONTROL_CENTER");
      default:
        return "";
    }
  }

  checkAction = pathname => {
    if(pathname) {
      const billingData = getStoreDataByType(TYPE_STORE_DATA.billingInfo);
      if(billingData && billingData.info &&
        BillingService.instance.checkIsNoActiveAccount(billingData)) {
          return {
            pathname: BILLING_PATHS.payment,
            message: "",
            skipCheckPathname: BILLING_PATHS.payment
          }
      }
    }

    return this.checkRedirect(pathname);
  }

  checkRedirect = pathname => {
    if(!excludedUrls.includes(pathname) && currentUserIsWithoutControlCenter()) {
      return {
        pathname: this.getRoutePath(TYPE_INFO_MESSAGE.emptyControlcenter),
        message: this.getMessage(TYPE_INFO_MESSAGE.emptyControlcenter),
        skipCheckPathname: "/info"
      }
    }
    return null;
  }
}