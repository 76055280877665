import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./index.scss";

import { DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK } from "../../../store/reducers/userModel/userModel-actions";
import { createAction } from "../../../utils/sagaHelper";

import IconButton from "../IconButton";

const SquareUpControl = ({ t, getDepotSquareAssociatedLocation, id }) => {
    const handleOnClick = () => {
        return new Promise(resolve=> {
            resolve();            
            if(id) {
                getDepotSquareAssociatedLocation({ id });
            }
        });
    }

    return (
        <IconButton isIcon={true} iconName="square"
            tooltipText={t("settings.square.ICON_TOOLTIP")}
            onClick={handleOnClick}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getDepotSquareAssociatedLocation: data=> dispatch(createAction(DEPOT_SQUARE_ASSOCIATED_LOCATION_CHECK, data))
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(SquareUpControl));