import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { WAITER_TYPES } from "../../../utils/enums";
import {
  getCurrentUserName,
  getRememberMe,
  getAuthorization
} from "../../../utils/auth-util";

import Waiter from "../Waiter/Waiter";

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: ""
    };
  }

  handleOnConfirm = () => {
    const { isWithPassword, actionAuthentication } = this.props;

    return new Promise((resolve, reject) => {
      if (isWithPassword) {
        actionAuthentication({
          username: getCurrentUserName(),
          password: this.state.password,
          rememberMe: getRememberMe(),
          skipSave: true,
          skipRetry: true
        })
          .then(data => {
            this.props
              .handleOnConfirm(
                data.token_type + " " + data.access_token,
                this.state.password
              )
              .then(
                () => {
                  resolve();
                },
                error => {
                  reject(error);
                }
              );
          })
          .catch(() => {
            reject();
          });
      } else {
        this.props
          .handleOnConfirm(getAuthorization())
          .then(() => resolve(), error => reject(error));
      }
    });
  };

  render() {
    const {
      t,
      isWithPassword,
      isCancelFirst,
      wishThreeButtons,
      handleOnLayout,
      handleOnCancel,
      labelText,
      useWrapperClass,
      useBtnConfirmClass,
      useBtnCancelClass,
      useInputClass,
    } = this.props;
    return (
      <div className={classNames("confirmDialogContainer", { cancelFirst: isCancelFirst }, useWrapperClass || "")}>
        <input type="email" name="email" style={{ width: 0, height: 0 }} />
        <div className={classNames("confirmDialogWrapper", { cancelFirst: isCancelFirst })}>
          {labelText && <span className={classNames("labelText")}>{labelText}</span>}
          {isWithPassword && (
            <input className={classNames("confirmInput", useInputClass ? useInputClass : "")}
              placeholder="Password"
              type="password"
              value={this.state.password}
              onChange={e => this.setState({ password: e.currentTarget.value })}
            />
          )}
          <button className={classNames("btnConfirmCancel", useBtnCancelClass || "")} onClick={handleOnCancel}>
            {t("dashboard.couriersbar.CANCEL_BUTTON")}
          </button>
          {!isCancelFirst && (
            <Waiter useClass={classNames("btnConfirmApply", useBtnConfirmClass || "")}
              type={WAITER_TYPES.button}
              handleOnClick={this.handleOnConfirm}
              stopPropagation={true}
              spanText={t("dashboard.couriersbar.SURE_BUTTON")}
            />
          )}
          {wishThreeButtons && (
            <Waiter useClass={classNames("btnConfirmApply", useBtnConfirmClass || "")}
              type={WAITER_TYPES.button}
              handleOnClick={handleOnLayout}
              stopPropagation={true}
              spanText={t("settings.depots.DEPOT_MENU_COPY_FROM")}
            />
          )}        
        </div>
        {isCancelFirst && (
          <Waiter useClass={classNames("btnConfirmApply", useBtnConfirmClass || "")}
            type={WAITER_TYPES.button}
            handleOnClick={this.handleOnConfirm}
            stopPropagation={true}
            spanText={t("dashboard.couriersbar.SURE_BUTTON")}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(ConfirmDialog);
