import React, {useEffect, useState} from 'react';
import SectionItemSelect from "../SectionItemSelect/SectionItemSelect";
import {getMergedBusinessMenuById} from "../../../api/order-requests";

const MarketplaceDiscountProductsItem = ({item, onLayout, onPropChange, column}) => {
    const [products, setProducts] = useState([]);

    const fetchData = async () => {
        const response = await getMergedBusinessMenuById(item.business_id);
        if(!response.data) {
            return;
        }

        const categories = response && response.data && response.data.categories;
        setProducts(categories.flatMap(cat => cat.products).map(prod => ({key: prod.id, value: prod.name})));
    }

    useEffect(() => {
        if(item.business_id) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [item.business_id]);

    return (
        <SectionItemSelect value={item.product_id} values={products} column={column} item={item} onPropChange={onPropChange} />
    )
}

export default MarketplaceDiscountProductsItem;