import React from "react";
import GoogleMapReact from "google-map-react";
import * as Sentry from "@sentry/react";
import classNames from "classnames";
import "./index.scss";

import Marker from "../MapMarker";
import MarkerInfoBox from "../MapInfoBox";
import Waiter from "../Waiter/Waiter";

import {
  mapPolygonFill,
  mapPolygonStroke,
  ZOOM_MAX
} from "../../../utils/constants";
import {
  isSameObjects,
  getRegionFromGoogle
} from "../../../utils/objects-util";
import { DELIVERY_ZONE_TYPE, TYPE_MARKER, WAITER_TYPES } from "../../../utils/enums";
import { latRad, zoom } from "../../../utils/math-util";

import User from "../../../services/user";
import {
  getRestaurantBounds,
  getDepotsBounds,
  getGoogleLatLngRegion,
  getOrdersBounds, getDefaultRegion, getDefaultGoogleLatLngBounds
} from "../../../services/restaurant";
import { getLatLngFromLocation, locationIsEmpty } from "../../../services/order";
import Markers from "../../../services/markers";
import GoogleMapsService from "../../../services/googleMapsService";

const WORLD_DIM = { height: 256, width: 256 };
const defaultZoom = 12;

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      showPolygon:
        !props.isHidePolygon &&
        !User.instance.getCurrentUserConfigurationProp("isHideDeliveryRegions"),
      markerInfo: {},
      location: { latitude: 0, longitude: 0 },
      polygones: [],
      map: undefined,
      isRegistered: this.isGoogleMapRefExist(),
      propsCenter: null,
      center: this.getMapCenter(),
      bounds: null,
      zoom: defaultZoom,
      size: null,
    };

    this.onPolygonEdited = this.onPolygonEdited.bind(this);
  }

  componentDidMount() {
    const { childRef } = this.props;

    if (childRef) {
      childRef(this);
    }

    this.updateBounds();
    document.addEventListener("dblclick", this.handleOnDblClick);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    this.unsetMapPolygones();
    if (childRef) {
      childRef(undefined);
    }
    
    document.removeEventListener("dblclick", this.handleOnDblClick);    
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      showInfo,
      markerInfo,
      polygones,
      showPolygon,
      map,
      isRegistered,
      propsCenter,
      bounds,
      zoom,
      size
    } = this.state;
    const {
      markers,
      cursor,
      restaurant,
      location,
      regions,
      region,
      circle,
      isHideDeliveryRegions,
      isReadonly,
      language,
      orderShakeId,
      alwaysShowPolygons,
    } = this.props;
    return isHideDeliveryRegions !== nextProps.isHideDeliveryRegions ||
      isReadonly !== nextProps.isReadonly ||
      markers !== nextProps.markers ||
      showPolygon !== nextState.showPolygon ||
      alwaysShowPolygons !== nextProps.alwaysShowPolygons ||
      cursor !== nextProps.cursor ||
      restaurant !== nextProps.restaurant ||
      location !== nextProps.location ||
      regions !== nextProps.regions ||
      region !== nextProps.region ||      
      circle !== nextProps.circle ||
      language !== nextProps.language ||
      orderShakeId !== nextProps.orderShakeId ||
      showInfo !== nextState.showInfo ||
      markerInfo !== nextState.markerInfo ||
      polygones !== nextState.polygones ||
      map !== nextState.map ||
      isRegistered !== nextState.isRegistered ||
      propsCenter !== nextState.propsCenter ||
      bounds !== nextState.bounds ||
      zoom !== nextState.zoom ||
      size !== nextState.size;
  }

  componentDidUpdate(prevProps, prevState) {
    const { map, size, polygones, showPolygon } = this.state;
    const {
      center,
      region,
      circle,
      regions,
      isHidePolygon,
      isHideDeliveryRegions,
      boundsByRegion,
      boundsByCircle,
      isShowTraffic,
      language, 
      depots,
      orderShakeId,
      restaurant,
      alwaysShowPolygons
    } = this.props;

    if(language && language !== prevProps.language) {
      GoogleMapsService.instance.registerMap().then(() => {
        this.setState({ isRegistered: true });
        this.forceUpdate();
      });
    }

    if(circle !== prevProps.circle && boundsByCircle) {
      this.updateBounds(null, false, false);
    }

    if(region !== prevProps.region && boundsByRegion) {
      this.updateBounds(null, false, false);
    }

    if (
      map !== prevState.map ||
      region !== prevProps.region ||
      regions !== prevProps.regions ||
      circle !== prevProps.circle
    ) {
      if (map) {
        this.fillMapPolygones();
      }
    } else if (map && (polygones !== prevState.polygones || !isSameObjects(prevState.showPolygon, showPolygon))) {
      this.setMapPolygones();
    }

    if (isHideDeliveryRegions !== prevProps.isHideDeliveryRegions || alwaysShowPolygons !== prevProps.alwaysShowPolygons) {
      const isShowPolygone = alwaysShowPolygons || (!isHidePolygon && !isHideDeliveryRegions);
      this.setState({ showPolygon: isShowPolygone });

      if (isShowPolygone) {
        // this.setMapPolygones();
      } else {
        this.unsetMapPolygones();
      }
    }

    if(center !== prevProps.center && !orderShakeId && !this.isOnMapClick) {
      this.updateBounds(null, false);
    } else {
      this.isOnMapClick = false;
    }

    if(this.isSizeChanged(prevState.size, size) && !this.isOnMapClick) {
      this.updateBounds();
    } else {
      this.isOnMapClick = false;
    }

    if(orderShakeId !== prevProps.orderShakeId) {
      const map = document.getElementById("map-container-service");
      const el = document.getElementById(orderShakeId);
      const rect = el && el.getBoundingClientRect();
      const mapRect = map && map.getBoundingClientRect();

      if(rect && mapRect &&
        !(rect.top >= mapRect.top &&
          rect.left >= mapRect.left &&
          rect.bottom <= mapRect.bottom &&
          rect.right <= mapRect.right
        )
      ) {
        this.updateBounds(orderShakeId, false);
      }     
    }

    if (isShowTraffic !== prevProps.isShowTraffic) {
      this.setTrafficLayer();
    }

    if(depots && depots.length && depots !== prevProps.depots && locationIsEmpty(this.state.center)) {
      this.setState({ center: this.getMapCenter() });
    }

    if(restaurant !== prevProps.restaurant && (!restaurant || !restaurant.depots)) {
      this.unsetMapPolygones();
    }
  }

  updateBounds = (orderShakeId, isChangeZoom = true, isChangeCenter = true) => {
    const { center, markers } = this.props;   
    try {
      const orderBounds = getOrdersBounds((markers || [])
        .filter(marker => marker.markerType === TYPE_MARKER.order)
        .map(marker => marker.location)
      );

      let marker = orderShakeId && markers && markers.find(f=> f.id === orderShakeId);
      let bounds = this.getBounds(marker, orderBounds);

      if (bounds) {
        const newBounds = this.getGoogleMapReactBounds(bounds);
        const boundsCenter = bounds.getCenter();
        const newCenter = boundsCenter
          ? { lat: boundsCenter.lat() || 0, lng: boundsCenter.lng() || 0 }
          : { lat: 0, lng: 0 }

        const newState = {
          zoom: isChangeZoom ? this.getZoom(marker, bounds, orderBounds) : (this.props.zoom || this.state.zoom),
          bounds: newBounds,
        }
        if(isChangeCenter) {
          newState.propsCenter = center;
          newState.center = center ? this.getMapCenter(marker) : newCenter;
        }

        this.setState(newState, ()=> {
          this.isOnMapClick = false;
        });
      } else {
        this.isOnMapClick = false
      }
    } catch(e) {
      
    }
  };

  getBounds = (marker, orderBounds) => {
    const { center, region, boundsByRegion, boundsByCircle, circle} = this.props;
    if(!window.google) return null;
    const bounds = new window.google.maps.LatLngBounds();

    try {

      if(boundsByRegion && region && region.locations) {
        region.locations.forEach(r => {
          bounds.extend(getGoogleLatLngRegion(r));
        });
        return bounds;
      }

      if(boundsByCircle && circle) {
        const circlePolygon = this.getCirclePolygon(circle);
        const circleBounds = circlePolygon.getBounds();
        const circleNorthEast = circleBounds.getNorthEast();
        const circleGetSouthWest = circleBounds.getSouthWest();
        bounds.extend(getGoogleLatLngRegion({lat: circleNorthEast.lat(), lng: circleNorthEast.lng()}));
        bounds.extend(getGoogleLatLngRegion({lat: circleGetSouthWest.lat(), lng: circleGetSouthWest.lng()}));
        return bounds;
      }

      if(marker && !locationIsEmpty(marker.location, true)) {
        bounds.extend(getGoogleLatLngRegion(marker.location));
        return bounds;
      }

      const restBounds = getRestaurantBounds(false);
      if(restBounds) {
        bounds.union(restBounds);
      }

      if(orderBounds) {
        bounds.union(orderBounds);
      }

      if (!locationIsEmpty(center, true)) {
        bounds.extend(getGoogleLatLngRegion(center));
      }

      return bounds;
    }
    catch(e) {
      return null;
    }
  }

  getZoom = (marker, bounds, orderBounds) => {
    const { size, zoom } = this.state; 

    if(!window.google) return ZOOM_MAX;

    const newZoom = this.getBoundsZoomLevel(bounds, size, true);
    const restBounds = getRestaurantBounds();
    const regionsZoom = (restBounds && orderBounds)
      ? this.getBoundsZoomLevel(getRestaurantBounds().union(orderBounds), size)
      : 0;  

    return marker 
      ? zoom
      : Math.max(newZoom, Number.isInteger(regionsZoom) ? regionsZoom : 0)
  }

  setTrafficLayer = () => {
    const { map } = this.state;
    const { isShowTraffic } = this.props;
    if (isShowTraffic) {
      if(!window.google || !window.google.maps) {
        GoogleMapsService.instance.getAutocompleteService().then(() => {
          this.trafficLayer = new window.google.maps.TrafficLayer();
          this.trafficLayer.setMap(map);
        });
      } else {
        this.trafficLayer = new window.google.maps.TrafficLayer();
        this.trafficLayer.setMap(map);
      }     
    } else {
      this.trafficLayer = null;
    }
  };

  getMapCenter = marker => {
    const { restaurant, center, location } = this.props;
    try {
      const bounds = getDefaultGoogleLatLngBounds();

      if (!locationIsEmpty(center, true)) {
        bounds.extend(getGoogleLatLngRegion(center));

        const newCenter = bounds.getCenter();
        if(newCenter) {
          return { lat: newCenter.lat() || 0, lng: newCenter.lng() || 0 };
        }
      }


      if(!locationIsEmpty((marker || {}).location, true)) {
        const markerLtLn = getGoogleLatLngRegion(marker.location);

        if(markerLtLn)
          bounds.extend(markerLtLn);

        const markerCenter = bounds.getCenter();
        return {
          lat: markerCenter.lat() || 0,
          lng: markerCenter.lng() || 0
        };
      }

      const depotBounds = getDepotsBounds(restaurant);      
      if (!depotBounds || !bounds) {
        return location
          ? {
              lat: location.latitude || (location.center && location.center.lat) || 0,
              lng: location.longitude || (location.center && location.center.lng) || 0
            }
          : { lat: 0, lng: 0 };
      } else {
        bounds.extend(depotBounds);
      }

      const newCenter = bounds.getCenter();
      return { lat: newCenter.lat() || 0, lng: newCenter.lng() || 0 };
    } catch(e) {}    
  };

  getBoundsZoomLevel = (latLngBounds, mapDim, isDefaultZoom) => {
    const ne = latLngBounds.getNorthEast();
    const sw = latLngBounds.getSouthWest();
    const latFraction = (latRad(ne.lat() || 0) - latRad(sw.lat() || 0)) / Math.PI;
    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    const latZoom = zoom((mapDim || {}).height || 0, WORLD_DIM.height, latFraction);
    const lngZoom = zoom((mapDim || {}).width || 0, WORLD_DIM.width, lngFraction);

    return isDefaultZoom
      ? Math.min(latZoom, lngZoom, defaultZoom, ZOOM_MAX)
      : Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  handleGoogleApiLoaded(map) {
    this.setState({ map });
  }

  getGoogleMapReactBounds = latLngBounds => {
    if (!latLngBounds) return null;
    const NECorner = latLngBounds.getNorthEast();
    const SWCorner = latLngBounds.getSouthWest();

    return {
      ne: { lat: NECorner.lat() || 0, lng: NECorner.lng() || 0 },
      nw: { lat: NECorner.lat() || 0, lng: SWCorner.lng() || 0 },
      se: { lat: SWCorner.lat() || 0, lng: NECorner.lng() || 0 },
      sw: { lat: SWCorner.lat() || 0, lng: SWCorner.lng() || 0 }
    };
  };

  isGoogleMapRefExist = () => !!window.google && !!window.google.maps;

  isSizeChanged = (prevSize, size) => {
    const { height, width } = size || {};
    return (!prevSize || !prevSize.height || !prevSize.width) && height && width;
  }

  fillMapPolygones = () => {
    const { regions, region, circle, isReadonly, renderPolygonsFromBusiness, businessPolygones } = this.props;

    if(this.isGoogleMapRefExist()) {
      let polygones = [];

      if(renderPolygonsFromBusiness){
        polygones = businessPolygones
          .reduce((accumulator, businessPolygon) => {
            let polygon = null;
            switch (businessPolygon.type) {
              case DELIVERY_ZONE_TYPE.circle:
                const data = (businessPolygon.data || {}).circle;
                if(data)
                  polygon = this.getCirclePolygon({ ...data, radius: (data.radio || 0) * 1000, id: businessPolygon.id });
                break;
              case DELIVERY_ZONE_TYPE.polygon:
                polygon = this.getPolygon(businessPolygon.id, (businessPolygon.data || {}).region, false);
                break;
              default:
                break;
            }

            if(polygon)
              accumulator.push(polygon);
            return accumulator;
          }, [])
      } else {
        if(circle) {
          polygones.push(this.getCirclePolygon(circle, true))
        } else {
          polygones = (region ? (regions || []).filter(f => f.id !== region.id) : (regions || [])).reduce((acc, regionPolygon)=> {
            const item = this.getPolygon(regionPolygon.id, regionPolygon.region, false);
            if(item)
              acc.push(item);
            return acc;
          }, []);

          if(((region || {}).locations || []).length) {
            polygones.push(this.getPolygon(region.id, region.locations, !isReadonly));
          } else {
            if(region) this.getPolygon(region.id, getDefaultRegion(this.state.center), true);
          }
        }
      }

      this.unsetMapPolygones();
      this.setState({ polygones });
    }
  };

  getPolygon = (id, locations, isEditable) => {
    if (!locations) return
    return new window.google.maps.Polygon({
      id: id,
      editable: isEditable,
      paths: locations.filter(location=> !locationIsEmpty(location)).map(location => {
        return {
          lat: location.lat || location.latitude,
          lng: location.lng || location.longitude
        };
      }),
      strokeColor: mapPolygonFill.color,
      strokeOpacity: mapPolygonFill.opacity,
      strokeWeight: mapPolygonFill.weight,
      fillColor: mapPolygonStroke.color,
      fillOpacity: mapPolygonStroke.opacity
    });
  };

  getCirclePolygon = (circle, isEditable = false) => {
    const { lat, latitude, lng, longitude } = (circle || {}).center || {};

    return new window.google.maps.Circle({
      id: (circle || {}).id || "CirclePolygon",
      editable: isEditable,
      strokeColor: mapPolygonFill.color,
      strokeOpacity: mapPolygonFill.opacity,
      strokeWeight: 2,
      fillColor: mapPolygonStroke.color,
      fillOpacity: 0.3,
      center: { lat: lat || latitude || 0, lng: lng || longitude || 0 },
      radius: (circle || {}).radius || (circle || {}).radio
    });
  };

  setMapPolygones = () => {
    const { map, showPolygon, polygones } = this.state;
    const { isHidePolygon, region, circle, isReadonly } = this.props;

    if (map) {
      polygones.forEach(polygon => {
        if (circle || (region && region.id === polygon.id)) {
          polygon.setMap(map);

          if(!isReadonly) {            
            this.polygon = polygon;  
            if(circle) {
              if(!this.infoWindowForCircleRadius) {
                this.infoWindowForCircleRadius = new window.google.maps.InfoWindow({
                  content: "",
                });
              }

              if(this.infoWindowForCircleRadius){
                polygon.addListener("mouseover", () => this.onShowRadius(circle))
                polygon.addListener("mouseout", this.onHideRadius)
              }


              polygon.center_changed = this.onCircleEdited;
              polygon.radius_changed = this.onCircleEdited;
            } else {
              this.path = polygon.getPath();
              if (this.path) {
                window.google.maps.event.addListener(this.path, "insert_at", this.onPolygonEdited);
                window.google.maps.event.addListener(this.path, "remove_at", this.onPolygonEdited);
                window.google.maps.event.addListener(this.path, "set_at", this.onPolygonEdited);
              }
    
              window.google.maps.event.addListener(this.polygon, "rightclick", this.onPolygonDelete);
            }
          }                  
        } else {
          if(map) {
            polygon.setMap(!isHidePolygon && showPolygon ? map : null);
          }          
        }      
      });      
    }
  };

  unsetMapPolygones = () => {
    const { polygones } = this.state;
    if (polygones) {
      polygones.forEach(polygon => {
        polygon.setMap(null);
      });
    }

    if (this.polygon) {
      let oldPath = null;
      if (this.polygon.getPath) oldPath = this.polygon.getPath();
      if (window.google && window.google.maps && oldPath) {
        window.google.maps.event.clearListeners(oldPath, "insert_at");
        window.google.maps.event.clearListeners(oldPath, "remove_at");
        window.google.maps.event.clearListeners(oldPath, "set_at");
        window.google.maps.event.clearListeners(this.polygon, "rightclick");
      }
      if (this.polygon.setMap) this.polygon.setMap(null);
    }

    this.polygon = null;
    this.path = null;
  };

  onCircleEdited = () => {
    const center = this.polygon.getCenter();
    const radius = this.polygon.getRadius();
    this.onHideRadius();

    this.onRegionChange({
      center: center
        ? { lat: center.lat(), lng: center.lng() }
        : this.state.center,
      radius
    });
  }

  onShowRadius = (circle) => {
    const {map} = this.state;
    if(map && this.infoWindowForCircleRadius) {
      this.infoWindowForCircleRadius.open(map);
      const convertedRadius = (Math.round(circle.radius || 0) / 1000).toFixed(1);
      this.infoWindowForCircleRadius.setContent(convertedRadius + " km");
      this.infoWindowForCircleRadius.setPosition(circle.center);
    }
  }

  onHideRadius = () => {
    this.infoWindowForCircleRadius && this.infoWindowForCircleRadius.close()
  }

  onPolygonEdited = () => {
    if (this.onRegionChange) {
      const path = this.polygon.getPath();
      this.onRegionChange(getRegionFromGoogle(path));
    }
  };

  onPolygonDelete = event => {
    if (event.path != null && event.vertex != null) {
      const path = this.polygon.getPaths().getAt(event.path);
      if (path.getLength() > 3) {
        path.removeAt(event.vertex);
      }
    }
  };

  setMapBounds = mapBounds => {
    const { onChangeMapCenter } = this.props;
    const { center, bounds, zoom, size } = mapBounds;
    if (onChangeMapCenter) onChangeMapCenter(mapBounds);
    this.setState({ center, bounds, zoom, size });
  };

  getMarkerContentInfo = (type, id) => {
    const { t, orders, depots, users } = this.props;
    let content = {};

    switch (type) {
      case TYPE_MARKER.order:
        content = Markers.instance.getOrderContent(orders.find(f => f.id === id));
        break;
      case TYPE_MARKER.depot:
        const depot = depots.find(f => f.id === id);
        const depotOrders = orders.filter(order => order.depotId === id) || [];
        content = Markers.instance.getDepotContent(depot);
        content.title = depot
          ? depot?.name + " - " + depotOrders.length + " " + t("map.TITLE_ORDERS")
          : t("map.TITLE_ORDERS");
        break;
      case TYPE_MARKER.courier:
        content = Markers.instance.getCourierContent(users.find(f => f.userId === id));
        break;
      default:
        break;
    }

    return content;
  };

  handleOnMarkerClick = (e, marker) => {
    e.stopPropagation();

    if (marker) {
      this.clickLocation = {
        lat: marker.location.latitude,
        lng: marker.location.longitude
      };
      if (this.onMarkerClick) this.onMarkerClick(marker);
    }
  };

  registerHandlers = ({ onMarkerClick, onMapClick, onRegionChange }) => {
    this.onMarkerClick = onMarkerClick;
    this.onMapClick = onMapClick;
    this.onRegionChange = onRegionChange;
  };

  unregisterHandlers = () => {
    this.onMarkerClick = null;
    this.onMapClick = null;
    this.onRegionChange = null;
  };

  handlerOnShowMarkerInfo = marker => {
    const { markers } = this.props;
    const existMarker = marker ? markers.find(f => f.id === marker.id) : null;
    const existMarkerContent = (existMarker && existMarker.content);
    const isShowMarker = !!marker;

    this.setState({
      showInfo: isShowMarker,
      markerInfo: isShowMarker
        ? {
            content: existMarkerContent || this.getMarkerContentInfo(marker.markerType, marker.id),
            location: marker.location,
            markerType: marker.markerType
          }
        : null
    });
  };

  handleOnSetCenter = e => {
    if (e) e.stopPropagation();
    return new Promise(resolve => {
      resolve();
      this.setState({ center: this.getMapCenter() }, () => this.updateBounds());
    });
  };

  handleOnMapClick = clickLocation => {
    this.clickLocation = clickLocation;
    if (this.onMapClick) {
      this.isOnMapClick = true;
      this.onMapClick(clickLocation);
    }
  };

  handleOnDblClick = e => {
    const { zoom, center } = this.state || {};
    if(e.ctrlKey) {
      this.setState({ 
        zoom: (zoom || 0) + 3, 
        center: this.clickLocation
          ? { lng: this.clickLocation.lng, lat: this.clickLocation.lat }
          : center
      });
    }
  }

  render() {
    const { t, restaurant, markers, users, orders, isHidePolygon, cursor, isHideDeliveryRegions } = this.props;
    const { markerInfo, showInfo, showPolygon, center, zoom, bounds, isRegistered } = this.state;
    return (
      <div className="map">
        {isRegistered && (
          <GoogleMapReact
            options={{
              draggableCursor: cursor || "grab",
              fullscreenControl: false
            }}
            yesIWantToUseGoogleMapApiInternals
            resetBoundsOnResize={true}
            bounds={bounds}
            center={locationIsEmpty(center, true) ? getLatLngFromLocation((restaurant && restaurant.location)) : center}
            zoom={zoom || defaultZoom}
            onGoogleApiLoaded={({ map }) => this.handleGoogleApiLoaded(map)}
            onClick={this.handleOnMapClick.bind(this)}
            onChange={this.setMapBounds}
            shouldUnregisterMapOnUnmount={false}
            style={{ height: "100%", width: "100%" }}
          >
            {(markers || []).map((marker, index) => {
                if(locationIsEmpty(marker.location)) {
                  Sentry.captureException(`Error marker location. Marker id: ${marker.id}, type: ${marker.markerType}. Location: ${marker.location}`);
                }
                return (
                  <Marker
                    zIndex={marker.zIndex || 0}
                    key={marker.id || index}
                    id={marker.id || index}
                    lat={marker.location.latitude || marker.location.lat}
                    lng={marker.location.longitude || marker.location.lng}
                    avatar={marker.icon && marker.icon.url}
                    marker={marker}
                    users={users}
                    orders={orders}
                    onMouseOver={this.handlerOnShowMarkerInfo}
                    onMouseOut={this.handlerOnShowMarkerInfo}
                    onClick={this.handleOnMarkerClick}
                  />
                )
              })}

            {showInfo && markerInfo && (
              <MarkerInfoBox
                content={markerInfo.content}
                lat={markerInfo.location.latitude}
                lng={markerInfo.location.longitude}
                markerType={markerInfo.markerType}
              />
            )}
          </GoogleMapReact>
        )}
        {!isHidePolygon && this.state.isRegistered && !isHideDeliveryRegions && (
          <button className={classNames("button", "showPolygon")}
            onClick={() => this.setState({ showPolygon: !this.state.showPolygon })}
          >
            {showPolygon ? t("map.HIDE_REGIONS_LABEL") : t("map.SHOW_REGIONS_LABEL")}
          </button>
        )}
        <div className={classNames("locationControl")} onClick={e => this.handleOnSetCenter(e)}>
          <Waiter type={WAITER_TYPES.svg}
            useClass={"locationControlSvg"}
            useSvg={"#icon-location"}
            stopPropagation={true}
            handleOnClick={this.handleOnSetCenter}
          />
        </div>
      </div>
    );
  }
}

export default Map;

