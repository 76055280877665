import { ADD, DELETE, UPDATE } from "../common-actions";

export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const NOTIFICATIONS_LOAD = NOTIFICATIONS + "_LOAD";
export const NOTIFICATIONS_UPDATE = NOTIFICATIONS + "_UPDATE";
export const NOTIFICATIONS_RESTART = NOTIFICATIONS + "_RESTART";
export const NOTIFICATION_ADD = NOTIFICATION + ADD;
export const NOTIFICATION_DELETE = NOTIFICATION + DELETE;
export const ALARMS = "ALARMS";
export const ALARMS_ADD = ALARMS + ADD;
export const ALARMS_UPDATE_ACTIVE = ALARMS + UPDATE + "_ACTIVE";
export const ALARMS_UPDATE = ALARMS + UPDATE;
export const ALARMS_DELETE = ALARMS + DELETE;
export const ALARMS_RESTART = ALARMS + "_RESTART";
export const ALARMS_BAR_SET_SHOW = "ALARMS_BAR_SET_SHOW";
export const ACCOUNT_TOP_NOTIFICATION = "ACCOUNT_TOP_NOTIFICATION";
export const ACCOUNT_TOP_NOTIFICATION_UPDATE = "ACCOUNT_TOP_NOTIFICATION" + UPDATE;

export function actionNotificationUpdate(data) {
  return { type: NOTIFICATIONS_UPDATE, data, additionalParams: { batched: true } };
}

export function actionAlarmUpdate(data) {
  return { type: ALARMS_UPDATE, data, additionalParams: { batched: true } };
}

export function actionAlarmUpdateActive(data) {
  return {
    type: ALARMS_UPDATE_ACTIVE,
    data
  };
}

export function actionAlarmAdd(data) {
  return {
    type: ALARMS_ADD,
    data
  };
}

export function actionAlarmDelete(data) {
  return {
    type: ALARMS_DELETE,
    data
  };
}

export function actionAlarmRestart(data) {
  return {
    type: ALARMS_RESTART,
    data
  };
}

export function actionAlarmsBarSetShow(data) {
  return {
    type: ALARMS_BAR_SET_SHOW,
    data
  };
}

export function actionAccountTopNotification(data) {
  return { type: ACCOUNT_TOP_NOTIFICATION_UPDATE, data };
}
