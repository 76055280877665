export const INIT_SECTION_ITEMS_HEIGHTS = "INIT_SECTION_ITEMS_HEIGHTS";
export const REFRESH_SECTION_ITEMS_HEIGHTS = "REFRESH_SECTION_ITEMS_HEIGHTS";
export const SAVE_SECTION_ITEM_HEIGHT = "SAVE_SECTION_ITEM_HEIGHT";
export const UPDATE_SECTION_ITEM_ROW_HEIGHT_DEFAULT =
  "UPDATE_SECTION_ITEM_ROW_HEIGHT_DEFAULT";
export const SET_SECTION_ITEMS_LOADING = "SET_SECTION_ITEMS_LOADING";
export const SAVE_SECTION_ITEM = "SAVE_SECTION_ITEM";

export function actionInitSectionItemsHeights() {
  return {
    type: INIT_SECTION_ITEMS_HEIGHTS
  };
}

export function actionRefreshSectionItemsHeights() {
  return {
    type: REFRESH_SECTION_ITEMS_HEIGHTS
  };
}

export function actionSaveSectionItemHeight(data) {
  return {
    type: SAVE_SECTION_ITEM_HEIGHT,
    data: data
  };
}

export function actionSaveSectionItemRowHeightDefault(data) {
  return {
    type: UPDATE_SECTION_ITEM_ROW_HEIGHT_DEFAULT,
    data: data
  };
}

export function actionSetSectionItemsLoading(data) {
  return {
    type: SET_SECTION_ITEMS_LOADING,
    data: data
  };
}

export function actionSaveSectionItem(data) {
  return {
    type: SAVE_SECTION_ITEM,
    data: data
  };
}
