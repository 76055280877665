import { ROLES_MANAGER_TYPE } from "../utils/enums";
import { createMenuModel } from "./menuModelFactory";
import RoleManager from "./roleManage";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Menu {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use Menu.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Menu(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  removeMenu(type) {
    this[type] = null;
  }

  getMenu(type, isSortByPriority, isRecreate) {
    if (!this[type] || isRecreate) {
      this[type] = createMenuModel(type);
    }

    const authorizedMenuItems = (this[type] || [])
      .filter(menuItem=> RoleManager.instance.isAuthorized(ROLES_MANAGER_TYPE.menu, menuItem.pathname));

    return isSortByPriority
      ? authorizedMenuItems?.sort((a, b) => (a.priority || 0) - (b.priority || 0))
      : authorizedMenuItems;
  }

  getMenuItemsByPriority(type, priority) {
    if (priority === 0) return this.getMenu(type);
    return priority
      ? this.getMenu(type).filter(f => (f.priority || 0) > (priority || 0)) || []
      : [];
  }
}
