export default class MenuModel {
  constructor({ pathname, title, titleInfo, disable, icon, actionLoad, priority, active, isShow, isTitleInfoShow, isWithIcon, useSeparator, nestedMenuType, searchParams, isIconRelative, badgeCount }) {
    this.title = title;
    this.titleInfo = titleInfo;
    this.pathname = pathname;
    this.icon = icon;
    this.priority = priority;
    this.useSeparator = useSeparator;
    this.nestedMenuType = nestedMenuType;
    this.actionLoad = actionLoad;
    this.isIconRelative = isIconRelative;
    this.disable = disable;
    if (active) this.active = active.bind(this);
    if (isShow) this.isShow = isShow.bind(this);
    if (isTitleInfoShow) this.isTitleInfoShow = isTitleInfoShow.bind(this);
    if (isWithIcon) this.isWithIcon = isWithIcon.bind(this);
    if (searchParams) this.searchParams = searchParams.bind(this);
    if (badgeCount) this.badgeCount = badgeCount.bind(this);
  }
}
