import { GLOBAL_POPUP_MESSAGE, GLOBAL_POPUP_MESSAGE_INIT } from "./globalPopupMessage-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    isShow: false,
    isLink: false,
    title: "",
    message: null,
    onClose: null
};

export default function globalPopupMessage(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA:
        case GLOBAL_POPUP_MESSAGE_INIT:
            return initState;
        case GLOBAL_POPUP_MESSAGE:
            return { ...state, ...action.data } 
        default:
            return state;
    }
}