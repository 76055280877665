import MenuModel from './menuModel';

import { TYPE_MENU, TYPE_SECTION_MENUTEMPLATE_MODEL } from '../utils/enums';
import { getCurrentDepotByBusiness, getRestaurantPaymentEnabled } from '../services/restaurant';
import { ORDERING_MENU_GET } from '../store/reducers/restaurant/restaurant-actions';

export function createMenuTemplateMenu() {
    return [
        new MenuModel({
            pathname: TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
            title: 'marketplacesettings.menuTemplateLabel.INFO',
            actionLoad: ORDERING_MENU_GET,
            icon: 'depotInfo',
            isShow: () => getRestaurantPaymentEnabled(),
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateMenu,
            nestedMenuType: TYPE_MENU.orderingMenuMenuTemplate,
            actionLoad: ORDERING_MENU_GET,
            title: 'Menu',
            icon: 'parentMenu',
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            isShow: () => getRestaurantPaymentEnabled(),
            active: location => location.pathname.includes('marketplace-settings/app-builder/orderingmenu/'),
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
