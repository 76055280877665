export function visualSettingsServerModel(data) {
  return {
    IsHideDeliveryRegions: data.isHideDeliveryRegions,
    IsShowTraffic: data.isShowTraffic,
    IsHideTakeawayGroup: !data.isHideTakeawayGroup,
    IsHidePreordersGroup: !data.isHidePreordersGroup,
    PendingOrdersPanelLocation: data.pendingOrdersPanelLocation ? 0 : 1,
    CouriersPanelLocation: data.couriersPanelLocation ? 0 : 1,
    CourierColorScheme: data.courierColorScheme ? 0 : 1,
    DefaultDashboardMode: data.defaultDashboardMode ? 0 : 1,
    TimeTillAutoSnooze: data.timeTillAutoSnooze,
    EnableAlarmPopups: data.enableAlarmPopups,
    VisualizeReadinessTime: !data.visualizeReadinessTime,
    OrdersColors: data.ordersColors,
    newOrderSoundNotification: data.newOrderSoundNotification,
    SnoozeTimeout: data.snoozeTimeout,
    OrderNotDeliveredInTimeNotificationEnabled: data.orderNotDeliveredInTimeNotificationEnabled,
    CourierComingBackEnabled: data.courierComingBackEnabled,
    CourierWrongRouteEnabled: data.courierWrongRouteEnabled,
    CourierReadyToDispatchEnabled: data.courierReadyToDispatchEnabled,
    AssistanceExistsEnabled: data.assistanceExistsEnabled,
    CourierStoppedEnabled: data.courierStoppedEnabled,
    BoxLostEnabled: data.boxLostEnabled,
    CourierLostEnabled: data.courierLostEnabled,
    OrderCanLateEnabled: data.orderCanLateEnabled,
    SortOrdersBasedOnReadinessTime: data.sortOrdersBasedOnReadinessTime,
    ShowAllDepotsOnMap: data.showAllDepotsOnMap,
    ConsumerOrderCreatedNotificationEnabled : data.consumerOrderCreatedNotificationEnabled,
    IsShowExtraSizeGroup: data.isShowExtraSizeGroup,
    IsHideOrderId: data.isHideOrderId,
    ShowOrderIdOnMap: data.showOrderIdOnMap,
    FilterMapObjectsByControlCenter: data.filterMapObjectsByControlCenter,
    AlwaysShowOrderTargetTime: data.alwaysShowOrderTargetTime
  };
}
