import React from "react";
import classNames from "classnames";
import "./index.scss";

import search_blue_small from "../../../assets/images/icons/search_blue_small.png";
import {courierAssociateFilter} from "../../../services/filter";
import User from "../../../services/user";
import {futureRouteInfo, isRTL} from "../../../services/userModel";
import {isSingleDepotMode} from "../../../services/restaurant";
import {CYCLE_STATE, SHIFT_TYPE, WAITER_TYPES} from "../../../utils/enums";

import AssociatePopupGroup from "./AssociatePopupGroup";
import {ModalPopup} from "../ModalPopup/ModalPopup";
import AssociatePopupControlCenters from "./associatePopupControlCenters";
import Waiter from "../Waiter/Waiter";
import AssociatePopupExternalProviders from "../AssociatePopupExternalProviders";
import AssociatePopupExternalAssignment from '../AssociatePopupExternalAssignment/index.js';
import {fetchExternalAssignmentsByIds} from '../../../api/user-requests.js';
import {requestResultIsOk} from '../../../utils/request-util.js';
import {objectKeysToUpperLowerCase} from '../../../utils/objects-util.js';
import {nullOrEmpty} from "../../../utils/helpers.js";

class AssociatePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: undefined,
      checked: undefined,
      extendableCyclesId: [],

    };
  }

  componentDidMount() {
    this.getControlCenters();
    this.initState()
  }

  get activeCouriers() {
    if (!this.props.couriers) return [];
    return this.props.couriers.filter(courier => {
      let isActive =
        courier.cycle &&
        !courier.cycle.shiftType &&
        (!this.props.courier || courier.userId !== this.props.courier.userId) &&
        courier.cycle.state === CYCLE_STATE.started;

      return (
        isActive && courierAssociateFilter(this.state.searchString, courier)
      );
    });
  }

  get onRouteCouriers() {
    if (!this.props.couriers) return [];
    return this.props.couriers.filter(courier => {
      return (
        (!this.props.courier || courier.userId !== this.props.courier.userId) &&
        courier.cycle &&
        (courier.cycle.state === CYCLE_STATE.approved ||
          courier.cycle.state === CYCLE_STATE.deliveryStart) &&
        courierAssociateFilter(this.state.searchString, courier)
      );
    });
  }

  get backUpCouriers() {
    const { couriers } = this.props;
    const { searchString } = this.state;
    if (!couriers) return [];

    const result = couriers.filter(courier => {
      let isActive =
        (!this.props.courier || courier.userId !== this.props.courier.userId) &&
        courier.cycle &&
        ((courier.cycle.shiftType === SHIFT_TYPE.Backup &&
          courier.cycle.state === CYCLE_STATE.started) ||
          (courier.cycle.shiftType === SHIFT_TYPE.Normal &&
            courier.cycle.state === CYCLE_STATE.away));

      return isActive && courierAssociateFilter(searchString, courier);
    });

    return result;
  }

  get controlCenters() {
    const { controlCenters } = this.props;
    const currentUser = User.instance.getCurrentUserInfo();

    return controlCenters
      ? controlCenters.filter(
          center =>
            center.info != null &&
            center.controlCenterId !== currentUser.controlCenterId
        )
      : [];
  }

  initState = () => {
    const { selectedOrderIds } = this.props

    fetchExternalAssignmentsByIds(selectedOrderIds).then(response => {
      if (requestResultIsOk(response, true)) {
        const extendableCyclesId = response?.data?.ExtendableCycles;
        const providers = objectKeysToUpperLowerCase(response?.data?.Providers)

        this.setState({extendableCyclesId: extendableCyclesId, providers: providers})
      }
    })
  }

  getControlCenters = () => {
    const { isGetControlCenters, controlCenterStatus, selectedOrderIds } = this.props;
    if(isGetControlCenters) {
      controlCenterStatus(selectedOrderIds);
    }
  }

  handleChecked = (checked, e, isExternalType, provider, isExternalAssignment) => {
    this.setState({ checked, isExternalType, provider, isExternalAssignment });
  };

  handleOnClose = e => {
    this.props.onClose();
  };

  handleOnConfirm = () => {
    const { onConfirm } = this.props;
    const { checked, isExternalType, provider, isExternalAssignment } = this.state;

    return onConfirm(checked, isExternalType, provider, isExternalAssignment);
  };

  filterByControlCenterId = items => {
    const {controlCenterIds, filterByControlCenterIds} = this.props;
    if(!filterByControlCenterIds) {
      return items;
    }
    const ids = [...new Set(controlCenterIds.filter(Boolean))];
    const idIsNull = ids.length === 0 && controlCenterIds.includes(null);

    if(ids.length === 1 || idIsNull) {
      return items.filter(c =>
          (idIsNull ? nullOrEmpty(c.cycle.controlCenterId) : c.cycle.controlCenterId === ids[0])
          || (c.cycle.shiftType === SHIFT_TYPE.Backup && c.cycle.controlCenterId === null)
      );
    }
    return [];
  }

  render() {
    const { t, isOpen, isShowFutureRoute, controlCenterIds, isAlwaysShowExternalAssignment } = this.props;
    const {extendableCyclesId, providers} = this.state;

    return (
      <ModalPopup
        isOpen={isOpen}
        onClose={this.handleOnClose}
        content={
          <div className={classNames("popupContainer", { rtl: isRTL()})}>
            <div className="searchCourier">
              <div className="searchCourierContainer">
                <img
                  src={search_blue_small}
                  className="searchIcon"
                  alt="search"
                />
                <input
                  type="search"
                  placeholder={t("couriers.SEARCH_PLACEHOLDER")}
                  className="searchInput"
                  value={this.state.searchString}
                  onChange={e =>
                    this.setState({ searchString: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="couriersContainer">
              {isShowFutureRoute && isSingleDepotMode() && (
                <AssociatePopupGroup
                  title={t("dialogs.couriers.FUTURE_ROUTE_LABEL")}
                  emptyTitleText=""
                  couriers={futureRouteInfo()}
                  checked={this.state.checked}
                  handleChecked={(value, e) => this.handleChecked(value, e)}
                />
              )}
              <AssociatePopupGroup
                title={t("dialogs.couriers.AVAILABLE_COURIERS_LABEL")}
                emptyTitleText={t("dialogs.couriers.RESTAURANT_NOT_AVAILABLE")}
                couriers={this.filterByControlCenterId(this.activeCouriers)}
                checked={this.state.checked}
                handleChecked={(value, e) => this.handleChecked(value, e)}
              />
              <AssociatePopupGroup
                title={t("dialogs.couriers.APPROVED_COURIERS_LABEL")}
                emptyTitleText={t("dialogs.couriers.RESTAURANT_NOT_APPROVED")}
                couriers={this.filterByControlCenterId(this.onRouteCouriers)}
                checked={this.state.checked}
                handleChecked={(value, e) => this.handleChecked(value, e)}
              />
              <AssociatePopupGroup
                title={t("dialogs.couriers.BACK_UP_COURIERS_LABEL")}
                emptyTitleText={t("dialogs.couriers.RESTAURANT_NOT_BACK_UP")}
                couriers={this.filterByControlCenterId(this.backUpCouriers)}
                checked={this.state.checked}
                handleChecked={(value, e) => this.handleChecked(value, e)}
              />
              {((controlCenterIds && controlCenterIds.length === 1) || isAlwaysShowExternalAssignment) && <AssociatePopupControlCenters
                  title={t("dialogs.couriers.CONTROL_CENTERS_LABEL")}
                  emptyTitleText={t("dialogs.couriers.NO_CONTROL_CENTERS")}
                  controlCenters={this.controlCenters}
                  checked={this.state.checked}
                  handleChecked={(value, e) => this.handleChecked(value, e)}
              />}
              <AssociatePopupExternalAssignment
                checked={this.state.checked}
                extendableCyclesId={extendableCyclesId}
                handleChecked={this.handleChecked}
              />
              <AssociatePopupExternalProviders
                checked={this.state.checked}
                providers={providers}
                handleChecked={this.handleChecked}
              />
            </div>
            <div className="courierContainerFooter">
              <button
                className={classNames(
                  "btn",
                  "denied",
                  "courierContainerButton"
                )}
                onClick={e => this.handleOnClose(e)}
              >
                {t("basic.CANCEL_BUTTON")}
              </button>
              <Waiter
                useClass="btn confirm courierContainerButton"
                type={WAITER_TYPES.button}
                handleOnClick={this.handleOnConfirm}
                stopPropagation={true}
                disabled={!this.state.checked}
                spanText={t("basic.OK_BUTTON")}
              />
            </div>
          </div>
        }
      />
    );
  }
}

export default AssociatePopup;
