import MenuModel from "./menuModel.js";
import {RECONCILIATIONS_MODEL} from "../utils/enums.js";

export function createReconciliationMenu() {
    return [
        new MenuModel({
            pathname: RECONCILIATIONS_MODEL.settings,
            title: "reconciliations.SETTINGS",
            icon: "generalsetting",
            searchParams: state => state,
            active: location => getIsActive(location, RECONCILIATIONS_MODEL.settings),
        }),
        new MenuModel({
            pathname: RECONCILIATIONS_MODEL.periods,
            title: "reconciliations.PERIODS",
            icon: "calendar",
            searchParams: state => state,
            active: location => getIsActive(location, RECONCILIATIONS_MODEL.periods),
        }),
    ];
}
function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split("/");
    const splitType = type.split("/");
    return (splitCurrentLocation[1] === splitType[1] && splitCurrentLocation[2] === splitType[2]);
}
