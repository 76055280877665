export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_MARKETPLACE_PAGES = "SET_MARKETPLACE_PAGES";

export const setProductAction = (data) => ({
  type: SET_PRODUCTS,
  data: data
});

export const setMarketplace = data => ({
  type: SET_MESSAGES,
  data: data
})

export const setMarketplacePages = data => ({
  type: SET_MARKETPLACE_PAGES,
  data: data
})
