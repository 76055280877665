import React from 'react';
import {
  AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES,
  AUDIENCE_FILTER_SELECT_GROUP_TYPES,
  CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES,
  CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES,
  CAMPAIGN_PERIOD_TYPES,
  CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES,
  getAudienceFilterType,
} from '../../../utils/enums.js';
import {toast} from 'react-toastify';
import classNames from 'classnames';
import Dropdown from '../Dropdown/index.js';
import {getAudienceFilterModel} from './SectionItemAudienceFilter.jsx';
import {getReconciliationsDepots, getTerritoriesEntity} from '../../../services/restaurant.js';
import i18n from 'i18next';

// date
export const calculateStringTimespan = (value, period) => {
  if (!value || !period) return '00:00:00';
  let totalDays = 0;

  switch (period) {
    case CAMPAIGN_PERIOD_TYPES.hours:
      if (value > 24) {
        const hours = value.toString().padStart(2, '0');
        const days = Math.floor(hours / 24) || 0;
        const setDays = days ? days + '.' : '';
        return setDays + (hours - days * 24) + ':00:00';
      }

      return value.toString().padStart(2, '0') + ':00:00';
    case CAMPAIGN_PERIOD_TYPES.days:
      totalDays = value;
      break;
    case CAMPAIGN_PERIOD_TYPES.weeks:
      totalDays = value * 7;
      break;
    case CAMPAIGN_PERIOD_TYPES.months:
      totalDays = value * 30; // assuming 30 days in a month
      break;
    default:
      return '00:00:00';
  }

  return totalDays + '.00:00:00';
};

export const calculateNumber = (value, period) => {
  if (!value || !period) return 0;

  if (!value?.includes('.')) {
    const [hours, minutes, seconds] = value?.split(':');
    return +hours + +minutes / 60 + +seconds / 3600;
  }

  // Convert the time value into hours
  const [days, time] = value?.split('.');
  const [hours, minutes, seconds] = time?.split(':');
  const totalHours = +days * 24 + +hours + +minutes / 60 + +seconds / 3600;

  // Calculate the number based on the given period
  switch (period) {
    case CAMPAIGN_PERIOD_TYPES.weeks:
      return totalHours / 168; // 168 hours in a week
    case CAMPAIGN_PERIOD_TYPES.months:
      return totalHours / 720; // 720 hours in a month (approximation)
    case CAMPAIGN_PERIOD_TYPES.days:
      return totalHours / 24;
    case CAMPAIGN_PERIOD_TYPES.hours:
      return totalHours;
    default:
      throw new Error('Invalid period. Please enter "weeks", "months", "days", or "hours".');
  }
};

export const getPeriodByDuration = value => {
  if (!value) {
    return 0;
  }

  if (!value.includes('.')) {
    return CAMPAIGN_PERIOD_TYPES.hours;
  }

  const [days, time] = value.split('.');
  const [hours] = time.split(':');

  if (+hours || (+days && +hours)) {
    return CAMPAIGN_PERIOD_TYPES.hours;
  }

  if (+days % 30 === 0) {
    return CAMPAIGN_PERIOD_TYPES.months;
  } else if (+days % 7 === 0) {
    return CAMPAIGN_PERIOD_TYPES.weeks;
  } else if (+days % 1 === 0) {
    return CAMPAIGN_PERIOD_TYPES.days;
  }

  return CAMPAIGN_PERIOD_TYPES.hours;
};

// date end

// territories select

export const getTerritoriesSelect = () => {
  const territories = getTerritoriesEntity() || [];
  territories.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  return (
    territories?.map(territory => {
      if (!territory?.entityId || !territory?.name) return [];

      return {
        key: territory.entityId,
        value: territory.name,
      };
    }) || []
  );
};

export const getReconciliationsDepotsSelect = () => {

  const depots = getReconciliationsDepots();

  return (
    depots?.map(depot => {
      if (!depot?.depotId || !depot?.depotName) return [];

      return {
        key: depot.depotName,
        value: depot.depotId,
      };
    }) || []
  );
};

// utils

export const checkNullField = state => {
  for (let item of state) {
    if (Object.values(item).includes(null)) {
      return true;
    }
  }
  return false;
};

export const handleMaxValueError = maxValue => {
  const errorString = i18n
    .t('basic.error.NUMBER_MAX_VALUE_ERROR')
    .replace("„{prop}“", '')
    .replace('{max}', maxValue);

  return toast.error(errorString);
};

export const handleMinValueError = minValue => {
  const errorString = i18n
    .t('basic.error.NUMBER_MIN_VALUE_ERROR')
    .replace("for '{prop}'", '')
    .replace('{min}', minValue);

  return toast.error(errorString);
};

// utils end

// components

export const SwitchAudienceType = ({ type, setType }) => {
  return (
    <div className={'audienceSwitchGroup'}>
      {getAudienceFilterType().map(item => (
        <div
          key={item.key}
          className={classNames('audienceSwitchItem', { active: type === item.key })}
          onClick={() => setType(item.key)}
        >
          {item.value}
        </div>
      ))}
    </div>
  );
};

export const SimpleItemSelect = ({ value, values, handleChange, width }) => {
  const getLabelValue = values?.find(el => el?.key === value);

  return (
    <div className={classNames('sectionItemSelect')} style={{ width: width ? width : '100px' }}>
      <Dropdown
        isCanBeEmpty
        labelField='value'
        valueField='key'
        searchBy='value'
        values={values}
        value={getLabelValue?.value || ''}
        onSelect={val => handleChange(val)}
      />
    </div>
  );
};

export const SimpleInputItem = ({ value, handleChange, width, type = 'number', isError }) => {
  return (
    <div className={classNames('sectionItemText')} style={{ width: width || '100px' }}>
      <form autoComplete='off' className={classNames('inputItem', { isError: isError })}>
        <input type={type} value={value || ''} onChange={event => handleChange(event.target.value)} />
      </form>
    </div>
  );
};

// components end

// AudienceFilterItem utils

export const getDaysOffsetTypeByNumber = days => {
  if (days < AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.yesterday) return AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.before;
  if (days > AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.tomorrow) return AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.after;

  return days;
};

export const getTimeSpanModel = ({ data, isEqual }) => {
  if (isEqual) {
    return {
      ...getAudienceFilterModel(data),
      days_offset: data?.days_offset || AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.today,
    };
  }
  return {
    ...getAudienceFilterModel(data),
    string_value: data?.string_value || CAMPAIGN_PERIOD_TYPES.hours,
    timespan_value: data?.timespan_value || 0,
  };
};

export const getLookupModel = data => ({
  ...getAudienceFilterModel(data),
  string_value: data?.string_value || null,
});

export const getNumericModel = data => ({
  ...getAudienceFilterModel(data),
  double_value: data?.double_value || 0,
});

export const getGroupType = selectGroup => {
  const isTimeSpan = Object.values(CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES).includes(selectGroup);
  if (isTimeSpan) return AUDIENCE_FILTER_SELECT_GROUP_TYPES.timeSpan;

  const isLookup = Object.values(CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES).includes(selectGroup);
  if (isLookup) return AUDIENCE_FILTER_SELECT_GROUP_TYPES.lookup;

  const isNumeric = Object.values(CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES).includes(selectGroup);
  if (isNumeric) return AUDIENCE_FILTER_SELECT_GROUP_TYPES.numeric;

  return null;
};
