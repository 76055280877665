import environment from "environment";
import { Userpilot } from "userpilot";
import User from "./user";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class UserPilotService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use UserPilotService.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new UserPilotService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  init = () => {
    Userpilot.initialize(environment.userPilotToken);
    const currUser = User.instance.getCurrentUserInfo();
    const currUserName = sessionStorage.getItem("currentUserName");

    if(currUser && currUserName) {        
        Userpilot.identify(currUserName, {
            name: currUser.firstName, 
            email: currUser.email,
            created_at: new Date()
        });
    }
  }

  stop = () => {
    try {
      if(!Userpilot) return
        Userpilot.clean();
        Userpilot.destroy();
    } catch (error) {
        console.log(error);
    }               
  }
}