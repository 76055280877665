import React from "react";

import i18n from "../../i18n";

import FilterOptionsModel from "../../models/filterOptionsModel";
import SectionModel from "../../models/sectionModel";

import {
  ORDERING_MENU_DELIVERY_ZONES,
  ORDERING_MENU_DELIVERY_ZONES_LIST_UPDATE,
  ORDERING_MENU_DELIVERY_ZONES_UPDATE,
  ORDERING_MENU_GET
} from "../../store/reducers/restaurant/restaurant-actions";

import {
  CONTROL_TYPE,
  DELIVERY_ZONE_TYPE,
  getDeliveryZoneTypes,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  ORDERING_MENU_TYPE_SECTION_MODEL,
  TYPE_ACTION,
  TYPE_MARKER,
  TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import { getHistoryLocationParams } from "../../utils/routesHelper";
import {createControlModel, filtrateControlByDepotRole} from "../controlModelFactory";
import {getDeliveryZonesFilter} from "../filter";
import {getInitModel} from "../initModels";
import {getCurrentBusinessMenu, getDepotNameById, getNameByIdsBusinessMenu} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import SwitchEnableDeliveryZone from "../../app/components/SwitchEnableDeliveryZone";
import {currentUserIsDepotRole} from "../userModel.js";

export function createMenuDeliveryZonesSection({ ...args }) {
  const { id } = getHistoryLocationParams(args.params);

  return new SectionModel({
    filterOptions: createDeliveryZonesSectionFilter(),
    columns: [
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_NAME_LABEL",
        accessor: "name",
        isHideDeleteButton: item=> item.isMarkerplace,
        width: '230px',
        fixedWidth: '230px',
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_MINIMUM_LABEL",
        accessor: "minimum",
        centered: true,
        width: '230px',
        fixedWidth: '230px',
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_PRICE_LABEL",
        accessor: "price",
        centered: true,
        width: '230px',
        fixedWidth: '230px',
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_OPERATION_LABEL",
        accessor: "operation_profile",
        centered: true
      },
      {
        //empty item for align items
        accessor: "enabled",
        width: "100px",
        fixedWidth: "100px",
        hideInHeader: true,
        type: CONTROL_TYPE.label,
        isInline: true,
        isShow: item => item.isGlobalZone && !item.enabledZone
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_ENABLED_LABEL",
        accessor: "enabled",
        isEditable: true,
        width: "100px",
        fixedWidth: "100px",
        centered: true,
        // isShow: item => currentUserIsDepotRole() ? false : (item.isGlobalZone ? item.enabledZone : true),
        isShow: item => item.isGlobalZone ? item.enabledZone : true,
        component: (item) => {
          return <SwitchEnableDeliveryZone item={item} action={ORDERING_MENU_DELIVERY_ZONES_UPDATE}/>
        }
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: "90px",
        centered: true,
        type: CONTROL_TYPE.link,
        link: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit,
        iconName: "editItem",
        tooltipText: i18n.t("settings.orderingmenu.DELIVERY_ZONE_EDIT_TOOLTIP_LABEL"),
        isShow: (item)=> !item.isMarkerplace && !item.isGlobalZone && !currentUserIsDepotRole()
      },
      {
        title: "",
        controlTitle: "",
        accessor: "enabledZone",
        tooltipText: i18n.t("settings.orderingmenu.DELIVERY_ZONE_ATTACH_TOOLTIP"),
        label: {
          onLabel: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_ADDED_LABEL",
          offLabel: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_ADD_LABEL",
        },
        width: "180px",
        fixedWidth: "180px",
        isEditable: true,
        hideInHeader: true,
        type: CONTROL_TYPE.switch,
        centered: true,
        isInline: true,
        isShow: (item)=> item.isMarkerplace && !item.isGlobalZone && !currentUserIsDepotRole()
      },
      {
        title: "",
        width: "180px",
        fixedWidth: "180px",
        accessor: "enabledZone",
        hideInHeader: true,
        isEditable: true,
        centered: true,
        type: CONTROL_TYPE.switch,
        label: {
          onLabel: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_ADDED_LABEL",
          offLabel: "settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_ADD_LABEL",
        },
        isShow: (item)=> item.isGlobalZone && !currentUserIsDepotRole(),
        isHideDeleteButton: () => true,
      }
    ],
    actionLoad: ORDERING_MENU_GET,
    tableHeaderControlsWidth: 0,
    tableMinWidth: 1200,
    actionName: ORDERING_MENU_DELIVERY_ZONES,
    editAction: ORDERING_MENU_DELIVERY_ZONES_LIST_UPDATE,
    typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones,
    typeAction: args.typeAction,
    parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
    breadcrumbs: [
      createBreadcrumbModel({
        label: "settings.map.DEPOT_SETTINGS_LABEL"
      }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return getDepotNameById(id);
        }
      })
    ],
    controls: filtrateControlByDepotRole([
      createControlModel({
        type: MODEL_CONTROL_TYPE.back
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew + `?parentId=${id}`
      })
    ]),
    isForceUpdate: true,
    label: "settings.orderingmenu.MENU_DELIVERY_ZONES",
    mappingLambda: state => {
      const zones = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.delivery_zones) || [];
      const globalZones = ((state.restaurant.data && state.restaurant.data.deliveryZones) || []);
      const deliveryZoneReferenceIds = zones
          .filter(zone => zone.data.delivery_zone_reference && zone.data.delivery_zone_reference.zone_id)

      const globalZonesWithFlag = globalZones.map(zone => {
        const find = deliveryZoneReferenceIds.find(z => z.data.delivery_zone_reference.zone_id === zone.id);
        return ({
          ...zone,
          data: {
            ...zone.data,
            delivery_zone_reference: {
              zone_id: zone.id
            }
          },
          enabled: find && find.enabled,
          zoneIdReference: zone.id,
          id: find && find.id,
          enabledZone: !!find,
          type: DELIVERY_ZONE_TYPE.deliveryZoneReference,
          isGlobalZone: true
        })
      });

      return zones.filter(zone => zone.type !== DELIVERY_ZONE_TYPE.deliveryZoneReference).concat(globalZonesWithFlag) || [];
    }
  });
}

export function createMenuDeliveryZoneEditSection({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_NAME_LABEL",
        accessor: "name",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_MINIMUM_LABEL",
        accessor: "minimum",
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_PRICE_LABEL",
        accessor: "price",
        isEditable: true,
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_ENABLED_LABEL",
        accessor: "enabled",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_USER_PRICE_FOR_MIXED_MODE_LABEL",
        accessor: "price_calculation_mode_bool",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_TYPE_LABEL",
        accessor: "type",
        connect: ()=> ({
          values: getDeliveryZoneTypes()
        }),
        isEditable: true,
        type: CONTROL_TYPE.select
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_OPERATION_LABEL",
        accessor: "operation_profile",
        connect: state=> ({
          values: [{ key: "", value: "-" }]
            .concat((state.restaurant.data.operations && state.restaurant.data.operations.profiles) || [])
            .map(operation => ({
              key: operation.name,
              value: operation.name
            }))
        }),
        isEditable: true,
        type: CONTROL_TYPE.select
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_REFERENCE_REGION_LABEL",
        accessor: "reference",
        connect: state=> {
          return ({
            values: [{ key: "", value: "-" }]
              .concat((state.restaurant.data.configuration && state.restaurant.data.configuration.regions) || [])
              .map(region => ({ key: region.id, value: region.name }))
          });
        },
        isShow: item=> item.type === DELIVERY_ZONE_TYPE.reference,
        isEditable: true,
        type: CONTROL_TYPE.select
      },
      {
        title: "",
        accessor: "region",
        isEditable: true,
        isFillContent: true,
        isHidePolygon: true,
        isHideDepots: true,
        isShow: item=> item.type === DELIVERY_ZONE_TYPE.polygon,
        type: CONTROL_TYPE.mapRegion,
        typeMarker: TYPE_MARKER.depot
      },
      {
        title: "",
        accessor: "circle",
        isEditable: true,
        isFillContent: true,
        isHidePolygon: true,
        isHideDepots: true,
        isShow: item=> item.type === DELIVERY_ZONE_TYPE.circle,
        type: CONTROL_TYPE.mapRegion,
        typeMarker: TYPE_MARKER.depot
      },
      {
        title: "",
        accessor: "reference",
        isFillContent: true,
        isHidePolygon: true,
        isHideDepots: true,
        isShow: item=> item.type === DELIVERY_ZONE_TYPE.reference,
        type: CONTROL_TYPE.mapRegion
      },
      {
        title: "settings.orderingmenu.DELIVERY_ZONE_GLOBAL_REFERENCE_TYPE_LABEL",
        accessor: "zone_id",
        connect: state=> {
          return ({
            values: (state.restaurant.data.deliveryZones || [])
              .filter(f=> f.enabled)
              .map(deliveryZone=> ({ key: deliveryZone.id, value: deliveryZone.name }))
          });
        },
        isShow: item=> item.type === DELIVERY_ZONE_TYPE.deliveryZoneReference,
        isEditable: true,
        type: CONTROL_TYPE.select
      }
    ],
    actionLoad: ORDERING_MENU_GET,
    actionName: ORDERING_MENU_DELIVERY_ZONES,
    initNewItemModel: item => {
      const businessMenu = (item && item.businessMenu) || getCurrentBusinessMenu();
      const initModel = getInitModel(MODEL_TYPE.deliveryZoneModel, { businessMenu, ...item, setDefaultRegion: true });
      return {
        ...initModel,
        marker: {
          id: businessMenu.id,
          title: businessMenu.name,
          location: businessMenu.location
        },
        center: businessMenu.location,
        region: initModel.data.region,
        circle: initModel.data.circle && {
          center: initModel.data.circle.center,
          radius: initModel.data.circle.radio * 1000
        },
        reference: (initModel.data.reference || {}).region_id,
        zone_id: (initModel.data.delivery_zone_reference || {}).zone_id
      };
    },
    typeAction: args.typeAction,
    typeModel: args.typeAction === TYPE_ACTION.edit
        ? ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit
        : ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew,
    parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones,
    breadcrumbs: [
      createBreadcrumbModel({
        label: "settings.map.DEPOT_SETTINGS_LABEL"
      }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id, parentId } = getHistoryLocationParams(window.location.search);
          return getNameByIdsBusinessMenu( parentId || id);
        }
      })
    ],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back
      }),
    ],
    label: "settings.orderingmenu.MENU_DELIVERY_ZONES",
    mappingLambda: state => {
      const businessMenu = state.restaurant.data.businessMenu || {};
      return (businessMenu.delivery_zones || []).map(el => ({ ...el, businessMenu }))
    }
  });
}

function createDeliveryZonesSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(deliveryZone) {
    return getDeliveryZonesFilter(this.selected, deliveryZone);
  });

  return filterOptionsModel;
}
