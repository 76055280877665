import {
  ACTIVE_STATE_CYCLE_UPDATE,
  ACTIVE_STATE_CYCLES_SET,
  ACTIVE_STATE_CYCLES_UPDATE,
} from './cycles-actions.js';
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
  data: [],
};

export default function activeStateCycles(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case ACTIVE_STATE_CYCLES_SET:
      if (!action.data) return state;
      return {
        ...state,
        data: action.data,
      };
    case ACTIVE_STATE_CYCLES_UPDATE:
      if (!action.data) return state;
      return {
        ...state,
        data: [...state.data, action.data],
      };
    case ACTIVE_STATE_CYCLE_UPDATE:
      if (!action.data) return state;
      return {
        ...state,
        data: state.data.map(cycle => (cycle.courierId === action.data.courierId ? {...cycle, ...action.data} : cycle)),
      };
    default:
      return state;
  }
}
