const formatField = (field, currency) => {
  if (typeof field === 'string') return field;
  if (typeof field === 'number') return `${currency ? currency : ""} ${field.toFixed(2)}`;
  return field;
};
const formatedSettingField = (value) => (value !== 0 ? value : null);

export const feesTypes = {
  canceledRefundsMarketplaceResp: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_REFUNDS_MARKETPLACE_RESP_DEPOT_LABEL',
  canceledRefundsBusinessResp: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_REFUNDS_BUSINESS_RESP_DEPOT_LABEL',
  productPriceCard: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_PRODUCT_PRICE_CARD_LABEL',
  deliveryPriceCashPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_PRICE_CASH_PERCENT',
  deliveryPriceCardPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_PRICE_CARD_PERCENT',
  productPriceCash: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_PRODUCT_PRICE_CASH_LABEL',
  deliveryPriceFix: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_DELIVERY_PRICE_FIX_LABEL',
  deliveryPriceLeftover: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_DELIVERY_PRICE_LEFTOVER_LABEL',
  deliveryPricePercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_DELIVERY_PRICE_PERCENT_LABEL',
  orderPriceFix: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_ORDER_PRICE_FIX_LABEL',
  orderPriceLeftover: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_ORDER_PRICE_LEFTOVER_LABEL',
  orderPricePercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_ORDER_PRICE_PERCENT_LABEL',
  businessRefundPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_BUSINESS_REFUND_PERCENT_LABEL',
  marketplaceRefundPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_MARKETPLACE_REFUND_PERCENT_LABEL',
  businessDiscountPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_BUSINESS_DISCOUNT_PERCENT_LABEL',
  marketplaceDiscountPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_MARKETPLACE_DISCOUNT_PERCENT_LABEL',
  businessReshipmentPercent: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_BUSINESS_RESHIPMENT_PERCENT_LABEL',
  marketplaceReshipmentPercent:
    'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_MARKETPLACE_RESHIPMENT_PERCENT_LABEL',
  serviceFee: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_SERVICE_FEE_LABEL',
  tax: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_TAX_LABEL',
  percentPeriodFix: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_PERCENT_PERIOD_FIX_LABEL',
  flatPeriodFix: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_FLAT_PERIOD_FIX_LABEL',
  creditCardClearingFee: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_CREDIT_CARD_CLEARING_FEE_LABEL',
  topUpToMinimumDepot: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_TOP_UP_TO_MINIMUM_DEPOT',
  driverTip: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_DRIVER_TIP',
  topUpToMinimumCash: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_TOP_UP_TO_MINIMUM_CASH',
  topUpToMinimumCard: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_TOP_UP_TO_MINIMUM_CARD',
  marketplaceCashDiscount: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_MARKETPLACE_CASH_DISCOUNT',
  marketplaceCardDiscount: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_MARKETPLACE_CARD_DISCOUNT',
  businessCardDiscount: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_BUSINESS_CARD_DISCOUNT',
  businessCashDiscount: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_BUSINESS_CASH_DISCOUNT',
};

export const reconciliationsSettingsTemplateModel = data => {
  let depotIds = [];
  if(data?.depotIds && data?.depotIds.length > 0) {
    depotIds = [...data?.depotIds];

  } else {
    depotIds = data?.depotData && data?.depotData.length > 0 ? data.depotData.map((e) => e.depotId) : [];
  }

  return {
    templateName: data?.templateName || '',
    depotIds: depotIds,
    territoryId: data?.territoryId || null,
    isDirty: false,
    refreshedValue: null,
    parentId: data?.parentId || null,
    fees: {
      canceledRefundsMarketplaceRespDepot: formatedSettingField(data?.fees?.canceledRefundsMarketplaceRespDepot),
      canceledRefundsMarketplaceRespTerritory: formatedSettingField(data?.fees?.canceledRefundsMarketplaceRespTerritory),
      canceledRefundsMarketplaceRespGlobal: formatedSettingField(data?.fees?.canceledRefundsMarketplaceRespGlobal),
      canceledRefundsBusinessRespDepot: formatedSettingField(data?.fees?.canceledRefundsBusinessRespDepot),
      canceledRefundsBusinessRespTerritory: formatedSettingField(data?.fees?.canceledRefundsBusinessRespTerritory),
      canceledRefundsBusinessRespGlobal: formatedSettingField(data?.fees?.canceledRefundsBusinessRespGlobal),
      productPriceCardDepot: formatedSettingField(data?.fees?.productPriceCardDepot),
      deliveryPriceCashPercentDepot: formatedSettingField(data?.fees?.deliveryPriceCashPercentDepot),
      deliveryPriceCashPercentTerritory: formatedSettingField(data?.fees?.deliveryPriceCashPercentTerritory),
      deliveryPriceCashPercentGlobal: formatedSettingField(data?.fees?.deliveryPriceCashPercentGlobal),
      deliveryPriceCardPercentDepot: formatedSettingField(data?.fees?.deliveryPriceCardPercentDepot),
      deliveryPriceCardPercentTerritory: formatedSettingField(data?.fees?.deliveryPriceCardPercentTerritory),
      deliveryPriceCardPercentGlobal: formatedSettingField(data?.fees?.deliveryPriceCardPercentGlobal),
      productPriceCardGlobal: formatedSettingField(data?.fees?.productPriceCardGlobal),
      productPriceCardTerritory: formatedSettingField(data?.fees?.productPriceCardTerritory),
      productPriceCashDepot: formatedSettingField(data?.fees?.productPriceCashDepot),
      productPriceCashTerritory: formatedSettingField(data?.fees?.productPriceCashTerritory),
      productPriceCashGlobal: formatedSettingField(data?.fees?.productPriceCashGlobal),
      deliveryPriceFixDepot: formatedSettingField(data?.fees?.deliveryPriceFixDepot),
      deliveryPriceFixTerritory: formatedSettingField(data?.fees?.deliveryPriceFixTerritory),
      deliveryPriceFixGlobal: formatedSettingField(data?.fees?.deliveryPriceFixGlobal),
      deliveryPriceLeftoverDepot: formatedSettingField(data?.fees?.deliveryPriceLeftoverDepot),
      deliveryPriceLeftoverTerritory: formatedSettingField(data?.fees?.deliveryPriceLeftoverTerritory),
      deliveryPriceLeftoverGlobal: formatedSettingField(data?.fees?.deliveryPriceLeftoverGlobal),
      deliveryPricePercentDepot: formatedSettingField(data?.fees?.deliveryPricePercentDepot),
      deliveryPricePercentTerritory: formatedSettingField(data?.fees?.deliveryPricePercentTerritory),
      deliveryPricePercentGlobal: formatedSettingField(data?.fees?.deliveryPricePercentGlobal),
      orderPriceFixDepot: formatedSettingField(data?.fees?.orderPriceFixDepot),
      orderPriceFixTerritory: formatedSettingField(data?.fees?.orderPriceFixTerritory),
      orderPriceFixGlobal: formatedSettingField(data?.fees?.orderPriceFixGlobal),
      orderPriceLeftoverDepot: formatedSettingField(data?.fees?.orderPriceLeftoverDepot),
      orderPriceLeftoverTerritory: formatedSettingField(data?.fees?.orderPriceLeftoverTerritory),
      orderPriceLeftoverGlobal: formatedSettingField(data?.fees?.orderPriceLeftoverGlobal),
      orderPricePercentDepot: formatedSettingField(data?.fees?.orderPricePercentDepot),
      orderPricePercentTerritory: formatedSettingField(data?.fees?.orderPricePercentTerritory),
      orderPricePercentGlobal: formatedSettingField(data?.fees?.orderPricePercentGlobal),
      businessRefundPercentDepot: formatedSettingField(data?.fees?.businessRefundPercentDepot),
      businessRefundPercentTerritory: formatedSettingField(data?.fees?.businessRefundPercentTerritory),
      businessRefundPercentGlobal: formatedSettingField(data?.fees?.businessRefundPercentGlobal),
      marketplaceRefundPercentDepot: formatedSettingField(data?.fees?.marketplaceRefundPercentDepot),
      marketplaceRefundPercentTerritory: formatedSettingField(data?.fees?.marketplaceRefundPercentTerritory),
      marketplaceRefundPercentGlobal: formatedSettingField(data?.fees?.marketplaceRefundPercentGlobal),
      businessDiscountPercentDepot: formatedSettingField(data?.fees?.businessDiscountPercentDepot),
      businessDiscountPercentTerritory: formatedSettingField(data?.fees?.businessDiscountPercentTerritory),
      businessDiscountPercentGlobal: formatedSettingField(data?.fees?.businessDiscountPercentGlobal),
      marketplaceDiscountPercentDepot: formatedSettingField(data?.fees?.marketplaceDiscountPercentDepot),
      marketplaceDiscountPercentTerritory: formatedSettingField(data?.fees?.marketplaceDiscountPercentTerritory),
      marketplaceDiscountPercentGlobal: formatedSettingField(data?.fees?.marketplaceDiscountPercentGlobal),
      businessReshipmentPercentDepot: formatedSettingField(data?.fees?.businessReshipmentPercentDepot),
      businessReshipmentPercentTerritory: formatedSettingField(data?.fees?.businessReshipmentPercentTerritory),
      businessReshipmentPercentGlobal: formatedSettingField(data?.fees?.businessReshipmentPercentGlobal),
      marketplaceReshipmentPercentDepot: formatedSettingField(data?.fees?.marketplaceReshipmentPercentDepot),
      marketplaceReshipmentPercentTerritory: formatedSettingField(data?.fees?.marketplaceReshipmentPercentTerritory),
      marketplaceReshipmentPercentGlobal: formatedSettingField(data?.fees?.marketplaceReshipmentPercentGlobal),
      serviceFeeDepot: formatedSettingField(data?.fees?.serviceFeeDepot),
      serviceFeeTerritory: formatedSettingField(data?.fees?.serviceFeeTerritory),
      serviceFeeGlobal: formatedSettingField(data?.fees?.serviceFeeGlobal),
      taxDepot: formatedSettingField(data?.fees?.taxDepot),
      taxTerritory: formatedSettingField(data?.fees?.taxTerritory),
      taxGlobal: formatedSettingField(data?.fees?.taxGlobal),
      percentPeriodFixDepot: formatedSettingField(data?.fees?.percentPeriodFixDepot),
      percentPeriodFixTerritory: formatedSettingField(data?.fees?.percentPeriodFixTerritory),
      percentPeriodFixGlobal: formatedSettingField(data?.fees?.percentPeriodFixGlobal),
      flatPeriodFixDepot: formatedSettingField(data?.fees?.flatPeriodFixDepot),
      flatPeriodFixTerritory: formatedSettingField(data?.fees?.flatPeriodFixTerritory),
      flatPeriodFixGlobal: formatedSettingField(data?.fees?.flatPeriodFixGlobal),
      creditCardClearingFeeDepot: formatedSettingField(data?.fees?.creditCardClearingFeeDepot),
      creditCardClearingFeeTerritory: formatedSettingField(data?.fees?.creditCardClearingFeeTerritory),
      creditCardClearingFeeGlobal: formatedSettingField(data?.fees?.creditCardClearingFeeGlobal),

      topUpToMinimumDepot: formatedSettingField(data?.fees?.topUpToMinimumDepot),
      topUpToMinimumTerritory: formatedSettingField(data?.fees?.topUpToMinimumTerritory),
      topUpToMinimumGlobal: formatedSettingField(data?.fees?.topUpToMinimumGlobal),
      driverTipDepot: formatedSettingField(data?.fees?.driverTipDepot),
      driverTipTerritory: formatedSettingField(data?.fees?.driverTipTerritory),
      driverTipGlobal: formatedSettingField(data?.fees?.driverTipGlobal),
      topUpToMinimumCashDepot: formatedSettingField(data?.fees?.topUpToMinimumCashDepot),
      topUpToMinimumCashTerritory: formatedSettingField(data?.fees?.topUpToMinimumCashTerritory),
      topUpToMinimumCashGlobal: formatedSettingField(data?.fees?.topUpToMinimumCashGlobal),
      topUpToMinimumCardDepot: formatedSettingField(data?.fees?.topUpToMinimumCardDepot),
      topUpToMinimumCardTerritory: formatedSettingField(data?.fees?.topUpToMinimumCardTerritory),
      topUpToMinimumCardGlobal: formatedSettingField(data?.fees?.topUpToMinimumCardGlobal),
      marketplaceCashDiscountDepot: formatedSettingField(data?.fees?.marketplaceCashDiscountDepot),
      marketplaceCashDiscountTerritory: formatedSettingField(data?.fees?.marketplaceCashDiscountTerritory),
      marketplaceCashDiscountGlobal: formatedSettingField(data?.fees?.marketplaceCashDiscountGlobal),
      marketplaceCardDiscountDepot: formatedSettingField(data?.fees?.marketplaceCardDiscountDepot),
      marketplaceCardDiscountTerritory: formatedSettingField(data?.fees?.marketplaceCardDiscountTerritory),
      marketplaceCardDiscountGlobal: formatedSettingField(data?.fees?.marketplaceCardDiscountGlobal),
      businessCardDiscountDepot: formatedSettingField(data?.fees?.businessCardDiscountDepot),
      businessCardDiscountTerritory: formatedSettingField(data?.fees?.businessCardDiscountTerritory),
      businessCardDiscountGlobal: formatedSettingField(data?.fees?.businessCardDiscountGlobal),
      businessCashDiscountDepot: formatedSettingField(data?.fees?.businessCashDiscountDepot),
      businessCashDiscountTerritory: formatedSettingField(data?.fees?.businessCashDiscountTerritory),
      businessCashDiscountGlobal: formatedSettingField(data?.fees?.businessCashDiscountGlobal),

    },
  };
};

export const reconciliationsPeriodTemplateModel = data => {
  const stats = data?.stats;
  return {...data, stats: [...stats]};
};

export const reconciliationsPeriodFormatPriceModel = (data, currency) => {
  return {...data,
    depotReconciliation: formatField(data.depotReconciliation, currency),
    depotRevenue: formatField(data.depotRevenue, currency),
    globalReconciliation: formatField(data.globalReconciliation, currency),
    globalRevenue: formatField(data.globalRevenue, currency),
    territoryReconciliation: formatField(data.territoryReconciliation, currency),
    territoryRevenue: formatField(data.territoryRevenue, currency),
    totalBonusPayments: formatField(data.totalBonusPayments, currency),
    totalCanceledRefundsBusiness: formatField(data.totalCanceledRefundsBusiness, currency),
    totalCanceledRefundsMarketplace: formatField(data.totalCanceledRefundsMarketplace, currency),
    totalBusinessDiscount: formatField(data.totalBusinessDiscount, currency),
    totalBusinessRefundCardAmount: formatField(data.totalBusinessRefundCardAmount, currency),
    totalBusinessRefundLoyaltyAmount: formatField(data.totalBusinessRefundLoyaltyAmount, currency),
    totalBusinessReshipment: formatField(data.totalBusinessReshipment, currency),
    totalCardDeliveryPrice: formatField(data.totalCardDeliveryPrice, currency),
    totalCashDeliveryPrice: formatField(data.totalCashDeliveryPrice, currency),
    totalCashPayments: formatField(data.totalCashPayments, currency),
    totalCashProductPrices: formatField(data.totalCashProductPrices, currency),
    totalCreditCardPayments: formatField(data.totalCreditCardPayments, currency),
    totalCreditCardProductPrices: formatField(data.totalCreditCardProductPrices, currency),
    totalDeliveryPrice: formatField(data.totalDeliveryPrice, currency),
    totalDiscountPayments: formatField(data.totalDiscountPayments, currency),
    totalMarketplaceDiscount: formatField(data.totalMarketplaceDiscount, currency),
    totalMarketplaceReshipment: formatField(data.totalMarketplaceReshipment, currency),
    totalPrice: formatField(data.totalPrice, currency),
    totalProductPrices: formatField(data.totalProductPrices, currency),
    totalMarketPlaceRefundLoyaltyAmount: formatField(data.totalMarketPlaceRefundLoyaltyAmount, currency),
    totalMarketPlaceRefundCardAmount: formatField(data.totalMarketPlaceRefundCardAmount, currency),
    totalServiceFee: formatField(data.totalServiceFee, currency),
    totalTax: formatField(data.totalTax, currency),
    totalTopUpToMinimum: formatField(data.totalTopUpToMinimum, currency),
    totalMarketplaceCashDiscount: formatField(data.totalMarketplaceCashDiscount, currency),
    totalMarketplaceCardDiscount: formatField(data.totalMarketplaceCardDiscount, currency),
    totalBusinessCardDiscount: formatField(data.totalBusinessCardDiscount, currency),
    totalBusinessCashDiscount: formatField(data.totalBusinessCashDiscount, currency),
    totalTopUpToMinimumCash: formatField(data.totalTopUpToMinimumCash, currency),
    totalTopUpToMinimumCard: formatField(data.totalTopUpToMinimumCard, currency),

  };
};

export const reconciliationsPeriodFormatTerritoryPriceModel = (data, currency, title, territoryData) => {

  return {...data,
    tableName: title,
    territoryId: data?.territoryId,
    offset: (territoryData?.offset || territoryData?.offset === "") ? territoryData?.offset : data.offset ? data.offset :0,
    depotRevenue: formatField(data.totalDepotRevenue, currency),
    depotReconciliation: formatField(data.totalDepotReconciliation, currency),
    territoryRevenue: formatField(data.totalTerritoryRevenue, currency),
    territoryReconciliation: formatField(data.totalTerritoryReconciliation, currency),
    globalRevenue: formatField(data.totalGlobalRevenue, currency),
    globalReconciliation: formatField(data.totalGlobalReconciliation, currency),

    totalCanceledRefundsBusiness: formatField(data?.territorySummaryStats?.totalCanceledRefundsBusiness, currency),
    totalCanceledRefundsMarketplace: formatField(data?.territorySummaryStats?.totalCanceledRefundsMarketplace, currency),
    totalBonusPayments: formatField(data?.territorySummaryStats?.totalBonusPayments, currency),
    totalBusinessDiscount: formatField(data?.territorySummaryStats?.totalBusinessDiscount, currency),
    totalBusinessRefundCardAmount: formatField(data?.territorySummaryStats?.totalBusinessRefundCardAmount, currency),
    totalBusinessRefundLoyaltyAmount: formatField(data?.territorySummaryStats?.totalBusinessRefundLoyaltyAmount, currency),
    totalBusinessReshipment: formatField(data?.territorySummaryStats?.totalBusinessReshipment, currency),
    totalCardDeliveryPrice: formatField(data?.territorySummaryStats?.totalCardDeliveryPrice, currency),
    totalCashDeliveryPrice: formatField(data?.territorySummaryStats?.totalCashDeliveryPrice, currency),
    totalCashPayments: formatField(data?.territorySummaryStats?.totalCashPayments, currency),
    totalCashProductPrices: formatField(data?.territorySummaryStats?.totalCashProductPrices, currency),
    totalCreditCardPayments: formatField(data?.territorySummaryStats?.totalCreditCardPayments, currency),
    deliveryCount: data?.territorySummaryStats?.deliveryCount ? data?.territorySummaryStats?.deliveryCount : 0,
    totalAcceptedOrdersCount: data?.territorySummaryStats?.totalAcceptedOrdersCount ? data?.territorySummaryStats?.totalAcceptedOrdersCount : 0,
    takeawayCount: data?.territorySummaryStats?.takeawayCount ? data?.territorySummaryStats?.takeawayCount : 0,
    totalCanceledOrdersCount: data?.territorySummaryStats?.totalCanceledOrdersCount ? data?.territorySummaryStats?.totalCanceledOrdersCount : 0,
    totalDriverTip: data?.territorySummaryStats?.totalDriverTip ? data?.territorySummaryStats?.totalDriverTip : 0,
    totalCreditCardProductPrices: formatField(data?.territorySummaryStats?.totalCreditCardProductPrices, currency),
    totalDeliveryPrice: formatField(data?.territorySummaryStats?.totalDeliveryPrice, currency),
    totalDiscountPayments: formatField(data?.territorySummaryStats?.totalDiscountPayments, currency),
    totalMarketplaceDiscount: formatField(data?.territorySummaryStats?.totalMarketplaceDiscount, currency),
    totalMarketplaceReshipment: formatField(data?.territorySummaryStats?.totalMarketplaceReshipment, currency),
    totalPrice: formatField(data?.territorySummaryStats?.totalPrice, currency),
    totalProductPrices: formatField(data?.territorySummaryStats?.totalProductPrices, currency),
    totalMarketPlaceRefundLoyaltyAmount: formatField(data?.territorySummaryStats?.totalMarketPlaceRefundLoyaltyAmount, currency),
    totalMarketPlaceRefundCardAmount: formatField(data?.territorySummaryStats?.totalMarketPlaceRefundCardAmount, currency),
    totalServiceFee: formatField(data?.territorySummaryStats?.totalServiceFee, currency),
    totalTax: formatField(data?.territorySummaryStats?.totalTax, currency),
    totalTopUpToMinimum: formatField(data?.territorySummaryStats?.totalTopUpToMinimum, currency),
    totalMarketplaceCashDiscount: formatField(data?.territorySummaryStats?.totalMarketplaceCashDiscount, currency),
    totalMarketplaceCardDiscount: formatField(data?.territorySummaryStats?.totalMarketplaceCardDiscount, currency),
    totalBusinessCardDiscount: formatField(data?.territorySummaryStats?.totalBusinessCardDiscount, currency),
    totalBusinessCashDiscount: formatField(data?.territorySummaryStats?.totalBusinessCashDiscount, currency),
    totalTopUpToMinimumCash: formatField(data?.territorySummaryStats?.totalTopUpToMinimumCash, currency),
    totalTopUpToMinimumCard: formatField(data?.territorySummaryStats?.totalTopUpToMinimumCard, currency),
  };
};

export const reconciliationsPeriodGrandTotalModel = (data, currency, title) => {
  return {
    id: null,
    tableName: title,
    territoryId: data?.territoryId,
    depotRevenue: formatField(data.totalDepotRevenue, currency),
    depotReconciliation: formatField(data.totalDepotReconciliation, currency),
    territoryRevenue: formatField(data.totalTerritoryRevenue, currency),
    territoryReconciliation: formatField(data.totalTerritoryReconciliation, currency),
    globalRevenue: formatField(data.totalGlobalRevenue, currency),
    globalReconciliation: formatField(data.totalGlobalReconciliation, currency),
    offset: null,
    count: 0,
  };
};


export const reconciliationsSettingsAddModel = (data, allConfigurations) => {
  let isRoot = false;
  const list = {...data, fees: {...data.fees}};
  if(allConfigurations.length > 0) {
    allConfigurations.forEach((e) => {
      if(!e.depotId && !e.territoryId){
        // isRoot = false;
      }
    });
  }

  if(data.parentId === null && data.territoryId === null){
    isRoot = true;
  }

  if(isRoot){
    for (let key in list.fees) {
      if (list.fees[key] === null) {
        list.fees[key] = 0;
      }
    }
  }

  return {
    ...list
  };
};

