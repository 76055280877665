import React from 'react';
import i18n from '../../i18n';

import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';

import GrCodeDepot from '../../app/components/GrCodeDepot';

import {getAddress} from '../order';
import {
    getBusinesMenuCategoriesFilter,
    getDepotDefaultRegionControlCenterNameFilter,
    getDepotFilter,
    getDepotIntegrationsSectionFilter,
    getProvidersSectionFilter,
    sortByProp,
    sortItemsByProp,
} from '../filter';
import {
    CONTROL_TYPE,
    CREDIT_ACCEPT,
    EXTERNAL_COURIER_PROVIDER,
    getDepotIntegrationAddTypes,
    getDepotIntegrationTypes,
    getMerchantDeliveryCostCalculationModeItems,
    getNewBusinessMenuTypes,
    INTEGRATION_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    NEW_BUSINESS_MENU_TYPE,
    PRICE_CALCULATION_MODE,
    sellerBankCodes,
    sellerIncList,
    sellerPersonBusinessTypes,
    TYPE_ACTION,
    TYPE_MENU,
    TYPE_SECTION_DEPOT_MODEL,
    TYPE_SECTION_MODEL,
} from '../../utils/enums';
import {getFullName} from '../userModel';
import {getInitModel} from '../initModels';
import {
    generateRandomString,
    getAllDepotRegions,
    getDepotDefaultRegionControlCenterName,
    getDepotNameById,
    getDepots,
    getDepotUserNameById,
    getExternalCycleLogo,
    getMenuCredentials,
    getMenuTemplateBusinessCategoryNameById,
    getRegionCenter,
    getRestaurantConfigPropValue,
    isMobileWidth,
} from '../restaurant';
import {
    BUSINESS_CATEGORY,
    BUSINESS_CATEGORY_GET,
    BUSINESS_CATEGORY_UPDATE,
    COPY_TELEGRAM_COMMAND,
    DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING,
    DEPOT_INIT_DATA_GET,
    DEPOT_MENU_COPY,
    DEPOT_SETTING,
    DEPOTMENU_SETTING,
    DEPOTPAYMECREDIT_SETTING,
    DEPOTPAYMEWALLET_SETTING,
    DEPOTPRICE_SETTING,
    DEPOTPRICE_SETTING_APPLY_TO,
    DEPOTUSERS_SETTING,
    DEPOTUSERS_SETTING_PASSWORD,
    GET_DEPOT_PRICE,
    GET_DEPOT_USERS,
    LOAD_DEPOT_USER_BY_ID,
    MENU_TEMPLATE_GET,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {createControlModel} from '../controlModelFactory';
import {getHistoryLocationParams} from '../../utils/routesHelper';
import {defaultPromiseResolve} from '../../utils/objects-util';

import {Avatar} from '../../app/components/Avatar/Avatar';
import RegionDeliveryPrice from '../../app/components/RegionDeliveryPrice';
import {defaultDepotColors} from '../../utils/constants';
import IconButton from '../../app/components/IconButton';
import SquareUpControl from '../../app/components/SquareUpControl';
import {
    DEPOT_INTEGRATIONS,
    DEPOT_INTEGRATIONS_GET,
    DEPOT_INTEGRATIONS_UPDATE,
    DEPOT_PROVIDER,
    DEPOT_PROVIDER_UPDATE,
    DEPOT_PROVIDERS,
    DEPOT_PROVIDERS_GET,
    DEPOT_PROVIDERS_REORDER,
    DEPOT_PROVIDERS_UNMOUNT,
    DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION_SHORT,
    DEPOT_SQUARE_ASSOCIATED_LOCATION,
    DEPOT_SQUARE_ASSOCIATED_REVOKE,
} from '../../store/reducers/userModel/userModel-actions';
import {createBreadcrumbModel} from '../createBreadcrumbModel';
import UserRoleAvatar from '../../app/components/UserRoleAvatar';
import TextWithTooltip from '../../app/components/TextWithTooltip';
import {columnsDepotUserEdit} from "../../utils/sectionModelHelper.js";

export function createDepotMenuCopy() {
    return new SectionModel({
        columns: [
            {
                title: 'settings.createMenu.CHOOSE_OPTION',
                accessor: 'type',
                type: CONTROL_TYPE.select,
                isEditable: true,
                values: getNewBusinessMenuTypes(),
            },
            {
                title: 'settings.createMenu.CHOOSE_TEMPLATE',
                accessor: 'template_id',
                type: CONTROL_TYPE.select,
                isEditable: true,
                isShow: data => data.type === NEW_BUSINESS_MENU_TYPE.template ||  data.type === NEW_BUSINESS_MENU_TYPE.fromtemplate,
                connect: state => {
                    const menuTemplates = state.menuTemplate.data.templates || [];
                    return {
                        values: menuTemplates.map(el => ({ value: el?.name, key: el.business_id })),
                    };
                },
              onPropChange: (selected, column, onPropChange)=> {
                onPropChange({ [column.accessor]: selected["key"], global_template_id: null  });
              }
            },
          {
            title: "settings.createMenu.CHOOSE_GLOBAL_TEMPLATE",
            accessor: "global_template_id",
            type: CONTROL_TYPE.select,
            isEditable: true,
            isShow: data => data.type === NEW_BUSINESS_MENU_TYPE.template ||  data.type === NEW_BUSINESS_MENU_TYPE.fromtemplate,
            isHideByLength: true,
            connect: state => ({
              values: (state.menuTemplate.data.globalTemplates || [])
                  .map(el => ({ value: el?.name, key: el.business_id }))
            }),
            onPropChange: (selected, column, onPropChange)=> {
              onPropChange({ [column.accessor]: selected["key"], template_id: null  });
            }
          },
            {
                title: 'settings.createMenu.CHOOSE_BUSINESS',
                accessor: 'business_id',
                type: CONTROL_TYPE.select,
                isEditable: true,
                isShow: data => data.type === NEW_BUSINESS_MENU_TYPE.business,
                connect: state => {
                    const depots = (state.restaurant.data && state.restaurant.data.depots) || [];
                    return {
                        values: depots
                            .filter(depot => depot.businessId)
                            .map(depot => ({ id: depot.id, key: depot.businessId, value: depot?.name, selected: false }))
                            ?.sort((a, b) => (a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1)),
                    };
                },
            },
        ],
        actionLoad: MENU_TEMPLATE_GET,
        actionName: DEPOT_MENU_COPY,
        initNewItemModel: item => getInitModel(MODEL_TYPE.depotsMenuCopy, item),
        typeAction: TYPE_ACTION.edit,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotsMenuCopy,
        parentUrl: TYPE_SECTION_MODEL.depots,
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createDepotsSectionModel() {
    return new SectionModel({
        filterOptions: createDepotsSectionFilter(),
        columns: [
            {
                title: "settings.map.DEPOT_EDIT_NAME_LABEL",
                width: '240px',
                fixedWidth: '240px',
                component: function(depot) {
                    return <Avatar avatar={depot.logoUrl} color={depot.color} title={depot?.name}/>;
                },
                accessor: 'avatarUrl',
                type: CONTROL_TYPE.image,
                showWidth: '768',
            },
            {
                title: '',
                width: '55px',
                component: function(depot) {
                    return <Avatar avatar={depot.logoUrl} color={depot.color} />;
                },
                type: CONTROL_TYPE.image,
                hideWidth: '768',
            },
            {
                title: 'settings.map.DEPOT_EDIT_NAME_LABEL',
                accessor: 'name',
                width: window.innerWidth < 1200 ? 'auto' : '300px',
                component: data => <TextWithTooltip text={data?.name} tooltip={data?.name} />,
                hideWidth: '768',
            },
            {
                title: 'settings.map.DEPOT_EDIT_PHONE_LABEL',
                accessor: 'phone',
                width: window.innerWidth < 1200 ? 'auto' : '150px',
                fixedWidth: '150px',
            },
            {
                title: 'settings.map.LABEL',
                component: function(depot) {
                    if (!depot || !depot.address) return '';
                    return getAddress(depot.address.address);
                },
            },
            {
                title: '',
                width: '40px',
                component: function() {
                    return <IconButton isIcon={true} iconName={'warn'} onClick={defaultPromiseResolve} />;
                },
                isShow: depot => depot.disable_delivery || depot.disable_takeaway,
            },
            {
                title: '',
                width: '40px',
                fixedWidth: '40px',
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
                hasWarningIcon: data => {
                    return data.squareUpAuthorizationRequired;
                },
                hideWidth: '700',
            },
            {
                title: '',
                width: '40px',
                fixedWidth: '40px',
                type: CONTROL_TYPE.mobileMenu,
                menuType: TYPE_MENU.depotSettings,
                key: 'id',
                iconName: 'editItem',
                isEditable: true,
                showWidth: '700',
            },
        ],
        actionName: DEPOT_SETTING,
        isForceUpdate: true,
        isLayoutForm: true,
        hideHeaderItemControls: true,
        tableMinWidth: 900,
        url: TYPE_SECTION_MODEL.depotsNew,
        typeModel: TYPE_SECTION_MODEL.depots,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.depotsNew,
                isLayoutForm: true,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        label: 'settings.map.DEPOT_SETTINGS_LABEL',
        breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.SETTINGS_LABEL' })],
        mappingLambda: state => state.restaurant.data.depots || [],
        sortByFunc: items => sortItemsByProp(items),
    });
}

export function createDepotEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'logoUrl',
                isEditable: true,
                type: CONTROL_TYPE.image,
            },
            {
                title: 'settings.depots.DEPOT_AS_CONTROL_CENTER',
                accessor: 'depotAsControlCenter',
                isEditable: true,
                isShow: () => getRestaurantConfigPropValue('allowDepotAsControlCenter'),
                type: CONTROL_TYPE.switch,
            },
            {
                isEditable: true,
                type: CONTROL_TYPE.switchGroups,
                items: [
                    [
                        {
                            title: 'settings.depots.AUTO_ASSIGNMENT',
                            accessor: 'autoExternalAssignment',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.depots.HIDE_COURIERS',
                            accessor: 'hideCouriers',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.depots.ALLOW_ORDER_CREATION',
                            accessor: 'allowOrderCreation',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                    ],
                ],
            },
            {
                title: 'settings.map.DEPOT_EDIT_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
                patternError: i18n.t('settings.map.DEPOT_NAME_ERROR_MSG'),
            },
            {
                title: 'settings.map.DEPOT_EDIT_ADMINISTRATIVE_NAME_LABEL',
                accessor: 'administrativeName',
                isEditable: true,
                isRequired: false,
                type: CONTROL_TYPE.text,
                patternError: i18n.t('settings.map.DEPOT_NAME_ERROR_MSG'),
            },
            {
                title: 'settings.map.DEPOT_SMS_BRAND',
                accessor: 'smsBrand',
                isEditable: true,
                isRequired: false,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.map.DEPOT_EDIT_PHONE_LABEL',
                accessor: 'phone',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.map.DEPOT_EDIT_DELIVERY_TIME_LABEL',
                measure: i18n.t('settings.settings.numericalSettings.TIME_MEASURE'),
                accessor: 'maxDeliveryTime',
                isEditable: true,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.map.DEPOT_EDIT_COOLING_TIME_LABEL',
                accessor: 'maxOrderCoolingTime',
                measure: i18n.t('settings.settings.numericalSettings.TIME_MEASURE'),
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.map.DEPOT_EDIT_ARRIVAL_TIME_LABEL',
                measure: i18n.t('settings.settings.numericalSettings.TIME_MEASURE'),
                accessor: 'maximumArrivalTime',
                isEditable: true,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.map.DEPOT_EDIT_DEFAULT_CONTROL_CENTER',
                accessor: 'defaultControlCenterId',
                connect: state => ({
                    values: [{ key: null, value: '-' }]
                        .concat(state.restaurant.data.controlCenters || [])
                        .map(center => ({
                            key: center.controlCenterId,
                            value: center?.name,
                        })),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'new-order-directive.ADDRESS_LABEL',
                accessor: 'address',
                isEditable: true,
                isShowIcon: true,
                isCanBeEmpty: true,
                withEmpty: true,
                withNotes: true,
                type: CONTROL_TYPE.address,
            },
            {
                title: 'settings.depots.DEPOT_COLOR',
                accessor: 'color',
                connect: () => ({
                    values: [{ key: null, value: '-' }].concat(
                        defaultDepotColors.map(depotColor => ({ key: depotColor, value: depotColor }))
                    ),
                }),
                isEditable: true,
                isColors: true,
                isAccentuationByValue: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: '',
                accessor: 'address',
                isEditable: true,
                isFillContent: true,
                isHideSearch: true,
                type: CONTROL_TYPE.mapAddress,
            },
        ],
        actionName: DEPOT_SETTING,
        initNewItemModel: (item, id, items) => {
            const firstDepotInfo = (items && items[0]) || {
                maximumArrivalTime: 0,
                maxDeliveryTime: 0,
                maxOrderCoolingTime: 0,
                administrativeName: '',
            };

            return getInitModel(
                MODEL_TYPE.depot,
                item
                    ? item
                    : {
                          address: {
                              address: getInitModel(MODEL_TYPE.address),
                              location: getRegionCenter(),
                          },
                          maximumArrivalTime: firstDepotInfo.maximumArrivalTime,
                          maxDeliveryTime: firstDepotInfo.maxDeliveryTime,
                          maxOrderCoolingTime: firstDepotInfo.maxOrderCoolingTime,
                      }
            );
        },
        stayAfterSave: args.typeAction === TYPE_ACTION.edit,
        typeAction: args.typeAction,
        actionLoad: DEPOT_INIT_DATA_GET,
        typeModel:
            args.typeAction === TYPE_ACTION.edit ?
                TYPE_SECTION_DEPOT_MODEL.depotsEdit :
                TYPE_SECTION_MODEL.depotsNew,
        parentUrl: TYPE_SECTION_MODEL.depots,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                isShow: TYPE_ACTION.edit === args.typeAction,
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        label: 'settings.depotSettingsLabel.INFO',
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createDepotTelegramSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.map.DEPOT_TELEGRAM_LINK_LABEL',
                accessor: '',
                type: CONTROL_TYPE.copyToClipboard,
                icon: 'telegram',
                actionName: COPY_TELEGRAM_COMMAND,
            },
        ],
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: TYPE_SECTION_DEPOT_MODEL.telegram,
        parentUrl: TYPE_SECTION_MODEL.depots,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        label: "settings.depotSettingsLabel.TELEGRAM",
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        mappingLambda: state => state.restaurant.data.depots,
    });
}

export function createDepotPaymeWalletNewSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.paymeWallet.SELLER_FIRST_NAME',
                accessor: 'sellerFirstName',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_LAST_NAME',
                accessor: 'sellerLastName',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_SOCIAL_ID',
                accessor: 'sellerSocialId',
                minLength: 9,
                maxLength: 9,
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_BANK_CODE',
                accessor: 'sellerBankCode',
                connect: () => ({
                    values: sellerBankCodes(),
                }),
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.paymeWallet.SELLER_BANK_BRANCH',
                accessor: 'sellerBankBranch',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.paymeWallet.SELLER_BANK_ACCOUNT_NUMBER',
                accessor: 'sellerBankAccountNumber',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_EMAIL',
                accessor: 'sellerEmail',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_PHONE',
                accessor: 'sellerPhone',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_BIRTHDAY',
                accessor: 'sellerBirthdate',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.paymeWallet.SELLER_SOCIAL_ID_ISSUED',
                accessor: 'sellerSocialIdIssued',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.paymeWallet.SELLER_GENDER',
                label: {
                    onLabel: 'Female',
                    offLabel: 'Male',
                },
                accessor: 'sellerGender',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.paymeWallet.SELLER_INC',
                accessor: 'sellerInc',
                connect: () => ({
                    values: sellerIncList(),
                }),
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.paymeWallet.SELLER_MERCHANT_NAME',
                accessor: 'sellerMerchantName',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_INC_CODE',
                accessor: 'sellerIncCode',
                isEditable: true,
                isFuncRequired: function(item) {
                    return !!item.sellerInc;
                },
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_PERSON_BUSINESS_TYPE',
                accessor: 'sellerPersonBusinessType',
                connect: () => ({
                    values: sellerPersonBusinessTypes(),
                }),
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.paymeWallet.SELLER_SITE_URL',
                accessor: 'sellerSiteUrl',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_ADDRESS_CITY',
                accessor: 'sellerAddressCity',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_ADDRESS_STREET',
                accessor: 'sellerAddressStreet',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.paymeWallet.SELLER_ADDRESS_STREET_NUMBER',
                accessor: 'sellerAddressStreetNumber',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
        ],
        actionName: DEPOTPAYMEWALLET_SETTING,
        initNewItemModel: () => ({
            sellerFirstName: '',
            sellerLastName: '',
            sellerSocialId: '',
            sellerBankCode: 0,
            sellerBankBranch: 0,
            sellerBankAccountNumber: '',
            sellerEmail: '',
            sellerPhone: '',
            sellerBirthdate: '',
            sellerSocialIdIssued: '',
            sellerGender: false,
            sellerInc: 0,
            sellerMerchantName: '',
            sellerIncCode: '',
            sellerPersonBusinessType: 0,
            sellerSiteUrl: '',
            sellerAddressCity: '',
            sellerAddressStreet: '',
            sellerAddressStreetNumber: '',
        }),
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.depotpaymewalletNew,
        parentUrl: TYPE_SECTION_MODEL.depots,
        stayAfterSave: true,
        saveControlsConfig: {
            cancel: {
                action: (item, history) => {
                    history.push(TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit + history.location.search);
                },
            },
        },
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createDepotPaymeCreditInfoNewSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.paymeWallet.CREDIT_ACCEPT_LABEL',
                accessor: 'creditAcceptType',
                values: [
                    {
                        title: 'settings.paymeWallet.CREDIT_ACCEPT_OFF',
                        value: CREDIT_ACCEPT.off,
                        className: 'warning',
                    },
                    {
                        title: 'settings.paymeWallet.CREDIT_ACCEPT_INFO',
                        value: CREDIT_ACCEPT.infoOnly,
                        className: 'warning',
                    },
                    {
                        title: 'settings.paymeWallet.CREDIT_ACCEPT_ORDERS',
                        value: CREDIT_ACCEPT.selling,
                    },
                ],
                isEditable: true,
                type: CONTROL_TYPE.paymeRadioButtons,
            },
            {
                title: '',
                accessor: 'creditAcceptType',
                type: CONTROL_TYPE.depotPaymentSelector,
                isFillContent: true,
            }
        ],
        actionName: DEPOTPAYMECREDIT_SETTING,
        initNewItemModel: depot => ({
            ...depot,
            creditAcceptType: {
                value: depot.onlineState,
                acceptCash: depot.acceptCash,
                acceptCreditCard: depot.acceptCreditCard,
                acceptCashOnTakeAway: depot.acceptCashOnTakeAway,
                selected: []
                    .concat(depot.acceptCash ? ['acceptCash'] : [])
                    .concat(depot.acceptCreditCard ? ['acceptCreditCard'] : []),
            },
        }),
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit,
        parentUrl: TYPE_SECTION_MODEL.depots,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        label: "settings.depotSettingsLabel.PAYMENTS",
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createBusinessCategoriesSectionModel() {
    return new SectionModel({
        filterOptions: createBusinessCategoriesSectionFilter(),
        columns: [
            {
                title: '',
                width: '70px',
                fixedWidth: true,
                component: function(businessCategory) {
                    return <Avatar avatar={businessCategory.logo} hideIconColor={true} isDefaultImageEmpty={true} />;
                },
                type: CONTROL_TYPE.image,
            },
            {
                title: 'settings.businessCategories.CATEGORY_NAME',
                accessor: 'name'
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: MARKETPLACE_SETTINGS_MODEL.businessCategoriesEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
            },
        ],
        actionName: BUSINESS_CATEGORY,
        actionLoad: BUSINESS_CATEGORY_GET,
        tableMinWidth: 300,
        dragActionName: BUSINESS_CATEGORY_UPDATE,
        typeModel: MARKETPLACE_SETTINGS_MODEL.businessCategories,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: MARKETPLACE_SETTINGS_MODEL.businessCategoriesNew,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
        ],
        label: 'settings.businessCategories.LABEL',
        sortByFunc: function(items) {
            return sortByProp(items);
        },
        mappingLambda: state => {
            const data = state.restaurant.data.businessCategory || []
            return data.map(category => {
                const name = category?.nameTranslations === null ? category?.name : category.nameTranslations[i18n.language]
                return {
                    name: name || '',
                    ...category
                }
            })
        }
    });
}

export function createBusinessCategoryEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'logo',
                settingsAaccessor: 'logoSettings',
                isEditable: true,
                type: CONTROL_TYPE.imageEdit,
            },
            {
                title: 'settings.businessCategories.CATEGORY_NAME',
                accessor: 'nameTranslations',
                isEditable: true,
                isFillContent: true,
                type: CONTROL_TYPE.languageEditor,
            },
            {
                title: 'settings.businessCategories.IS_STORE',
                accessor: 'isStore',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
        ],
        actionName: BUSINESS_CATEGORY,
        actionLoad: BUSINESS_CATEGORY_GET,
        initNewItemModel: item => {
            return (
                item || {
                    logo: '',
                    nameTranslations: [],
                }
            );
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                label: 'settings.businessCategories.LABEL',
            }),
        ],
        getLabel: () => {
            const { id } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit ? getMenuTemplateBusinessCategoryNameById(id) : i18n.t('settings.businessCategories.NEW_LABEL');
        },
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? MARKETPLACE_SETTINGS_MODEL.businessCategoriesEdit
                : MARKETPLACE_SETTINGS_MODEL.businessCategoriesNew,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.businessCategories,
        mappingLambda: state => state.restaurant.data.businessCategory || [],
    });
}

export function createDepotUsersSectionModel({ ...args }) {
    const { id } = getHistoryLocationParams(args.params);
    return new SectionModel({
        key: "userId",
        isForceUpdate: true,
        filterOptions: createDepotUsersSectionFilter(),
        columns: [
            {
                title: "",
                width: "55px",
                fixedWidth: "55px",
                component: function(user) {
                    return <Avatar avatar={user.avatarUrl} color={user.color} />;
                },
                accessor: "avatarUrl",
                type: CONTROL_TYPE.image,
            },
            {
                title: "settings.users.USER_NAME_LABEL",
                width: "18%",
                fixedWidth: "18%",
                component: user=> getFullName(user)
            },
            {
                title: "settings.users.USER_WORKER_ID",
                accessor: "workerId",
                width: "13%",
                fixedWidth: "13%",
            },
            {
                title: "settings.users.USER_PHONE_LABEL",
                width: "13%",
                fixedWidth: "13%",
                accessor: "phoneNumber",
                type: CONTROL_TYPE.text,
            },
            {
                title: "settings.users.USER_ROLES_LABEL",
                component: function(user) {
                    return <UserRoleAvatar value={user} />;
                },
                accessor: "roles",
                type: CONTROL_TYPE.text,
            },
            {
                title: "",
                width: "45px",
                fixedWidth: "45px",
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_DEPOT_MODEL.depotusersConfirmPassword,
                iconName: "passwordIcon",
                tooltipText: i18n.t("settings.users.USER_TOOLS_MANAGER_CHANGE_PASSWORD_TOOLTIP")
            },
            {
                title: "",
                width: "40px",
                fixedWidth: "40px",
                component: function(userInfo) {
                    return <GrCodeDepot userInfo={userInfo} />;
                },
                accessor: "",
                type: CONTROL_TYPE.linkIconComponent,
                link: "grCode"
            },
            {
                width: "40px",
                fixedWidth: "40px",
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_DEPOT_MODEL.depotusersEdit,
                iconName: "editItem",
                tooltipText: i18n.t("settings.users.USER_TOOLS_EDIT_TOOLTIP")
            }
        ],
        actionPagination: GET_DEPOT_USERS,
        actionName: DEPOTUSERS_SETTING,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotusers,
        stayAfterSave: false,
        tableMinWidth: 800,
        hideHeaderItemControls: true,
        parentUrl: TYPE_SECTION_MODEL.depots,
        label: 'settings.depotSettingsLabel.USERS',
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_DEPOT_MODEL.depotusersNew + `?parentId=${id}`
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.back })
        ],
        mappingLambda: (state) => {
            const { id } = getHistoryLocationParams(window.location.search);
            const depots = state?.restaurant?.data?.depots
            const depot = depots.find(depot => depot?.id === id)
            return depot?.depotUsers || []
        },
    });
}

export function createDepotUserEditSectionModel({ ...args }) {
    return new SectionModel({
        key: "userId",
        columns: columnsDepotUserEdit,
        actionName: DEPOTUSERS_SETTING,
        actionLoad: LOAD_DEPOT_USER_BY_ID,
        initNewItemModel: (item, id) => ({...getInitModel(MODEL_TYPE.user, item), depotId: item?.depotId || id}),
        typeAction: args.typeAction,
        typeModel: args.typeAction === 'edit' ?
            TYPE_SECTION_DEPOT_MODEL.depotusersEdit :
            TYPE_SECTION_DEPOT_MODEL.depotusersNew,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotusers,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(parentId);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.depotSettingsLabel.USERS',
            }),
        ],
        getLabel: () => {
            const { id, parentId } = getHistoryLocationParams(window.location.search);
            return getDepotUserNameById(id, parentId);
        },
        mappingLambda: state => {
            const { id, parentId } = getHistoryLocationParams(window.location.search);

            //find user in depotUsers
            const depots = state?.restaurant?.data?.depots
            const depot = depots?.find(depot => depot?.id === parentId)
            const depotUser = depot?.depotUsers?.find(user => user?.userId === id)
            //find user in depotUsers end

            if(depotUser) {
                return depotUser
            }

            //find user in userModel
            return state.userModel.data.find(user => user.userId === id) || {}
            //find user in userModel end
        }
    });
}


export function createDepotUserPasswordEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.users.CONFIRM_USER_NAME_LABEL',
                accessor: 'userName',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.map.NEW_PASSWORD_LABEL',
                accessor: 'newPassword',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.password,
            },
            {
                title: 'settings.map.RETYPE_PASSWORD_LABEL',
                accessor: 'retypePassword',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.password,
            },
        ],
        actionName: DEPOTUSERS_SETTING_PASSWORD,
        actionLoad: LOAD_DEPOT_USER_BY_ID,
        initNewItemModel: user => {
            return {
                id: user.id,
                userId: user.userId,
                depotId: user.depotId,
                userName: user.userName,
                newPassword: '',
                retypePassword: '',
            };
        },
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotusersConfirmPassword,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotusers,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        mappingLambda: state => {
            const { id, parentId } = getHistoryLocationParams(window.location.search);

            //find user in depotUsers
            const depots = state?.restaurant?.data?.depots
            const depot = depots?.find(depot => depot?.id === parentId)
            const depotUser = depot?.depotUsers?.find(user => user?.userId === id)
            //find user in depotUsers end

            if(depotUser) {
                return depotUser
            }

            //find user in userModel
            return state.userModel.data.find(user => user.userId === id) || {}
            //find user in userModel end
        },
    });
}

export function createDepotPriceEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.map.DEPOT_DELIVERY_PRICE_HEADER_LABEL',
                isEditable: true,
                inlineType: 'inlineOdd',
                type: CONTROL_TYPE.sectionGroup,
                items: [
                    {
                        title: 'settings.map.DEPOT_PRICE_CALCULATION_MODE',
                        accessor: 'calculationMode',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        isInline: true,
                        connect: () => ({
                        values: getMerchantDeliveryCostCalculationModeItems()
                        }),
                        type: CONTROL_TYPE.select,
                    },
                    {
                        title: 'settings.map.DEPOT_BASIS_PRICE_LABEL',
                        accessor: 'basisPrice',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                        isShow: data => !(data.calculationMode === PRICE_CALCULATION_MODE.fixedRegionBased),
                    },
                    {
                        title: 'settings.map.DEPOT_DELIVERY_PRICE_LABEL',
                        accessor: 'deliveryPrice',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                        isShow: data => !(data.calculationMode === PRICE_CALCULATION_MODE.fixedRegionBased),
                    },
                    {
                        title: 'settings.map.GENERAL_DELIVERY_PRICE',
                        accessor: 'deliveryPrice',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                        isShow: data => data.calculationMode === PRICE_CALCULATION_MODE.fixedRegionBased,
                    },
                    {
                        title: 'settings.personalSettings.shipmentPriceSettings.BASIS_DISTANCE_IN_METERS',
                        accessor: 'basisDistanceInMeters',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                        isShow: data => !(data.calculationMode === PRICE_CALCULATION_MODE.fixedRegionBased),
                    },
                    {
                        title: 'settings.personalSettings.shipmentPriceSettings.PRICE_PER_KM',
                        accessor: 'pricePerKm',
                        isEditable: true,
                        inlineType: 'inlineOdd',
                        type: CONTROL_TYPE.number,
                        isShow: data => !(data.calculationMode === PRICE_CALCULATION_MODE.fixedRegionBased),
                    },
                ],
            },
            {
                title: 'settings.map.DEPOT_DELIVERY_PRICE_PERREGION_HEADER_LABEL',
                isEditable: true,
                type: CONTROL_TYPE.sectionGroup,
                isInline: true,
                isShow: value => !(value.calculationMode === PRICE_CALCULATION_MODE.distanceBased),
                items: [
                    {
                        accessor: 'regionConfigurations',
                        itemValue: {
                            key: 'id',
                            component: function(item, onChange, onDelete) {
                                return (
                                    <RegionDeliveryPrice
                                        item={item}
                                        inlineType={'inlineOdd'}
                                        handleOnChange={onChange}
                                        handleOnDeleteItem={onDelete}
                                    />
                                );
                            },
                        },
                        isEditable: true,
                        type: CONTROL_TYPE.listItem,
                    },
                    {
                        title: 'settings.users.DEPOT_USERS_ADD_BUTTON',
                        accessor: 'regionConfigurations',
                        connect: state => {
                            const params = getHistoryLocationParams(window.location.search);
                            const regionConfigurationsIds = ((state.localValue || {}).regionConfigurations || []).map(region => region.id);
                            const depot = (state.restaurant.data.depots || []).find(f => f.id === params.id);
                            return {
                                values: getAllDepotRegions(depot && depot.price, state)
                                    .filter(region => !regionConfigurationsIds.includes(region.id))
                                    .map(region => ({
                                        ...region,
                                        key: region.id,
                                        value: region?.name,
                                })),
                            };
                        },

                        isEditable: true,
                        type: CONTROL_TYPE.selectWithAdd,
                    },
                ],
            },
        ],
        stayAfterSave: true,
        actionName: DEPOTPRICE_SETTING,
        initNewItemModel: item => {
            return item && item.price
                ? {
                      ...item.price,
                      id: item.id,
                  }
                : getInitModel(MODEL_TYPE.depotDeliveryPriceModel);
        },
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotsEditPrice,
        parentUrl: TYPE_SECTION_MODEL.depots,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        label: 'settings.depotSettingsLabel.DELIVERY_COST',
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        saveControlsConfig: {
            apply: {
                title: 'settings.map.DEPOT_PRICE_CONFIG_APPLY_TO',
                action: (editItem, onLayout) => {
                    onLayout(TYPE_SECTION_MODEL.depotsPriceApplyTo, editItem);
                },
            },
        },
        mappingLambda: state => state.restaurant.data.depots || [],
        actionLoad: GET_DEPOT_PRICE,
    });
}

export function createDepotPriceCopy({ ...args }) {
    return new SectionModel({
        columns: [
            {
                isShowSearch: true,
                onSearch: (value, searchString) => {
                    return value.filter(depot => depot?.name?.toLowerCase().includes(searchString?.toLowerCase()));
                },
                accessor: 'depots',
                isEditable: true,
                model: new SectionModel({
                    typeModel: TYPE_SECTION_MODEL.depotsPriceApplyTo,
                    isEditable: true,
                    columns: [
                        {
                            title: '',
                            width: 'calc(100% - 40px)',
                            accessor: 'name',
                            type: CONTROL_TYPE.text,
                        },
                        {
                            title: '',
                            width: '40px',
                            accessor: 'selected',
                            isEditable: true,
                            type: CONTROL_TYPE.checkbox,
                        },
                    ],
                }),
                isFillContent: true,
                type: CONTROL_TYPE.editTable,
            },
        ],
        isShowSaveConfirmDialog: true,
        actionName: DEPOTPRICE_SETTING_APPLY_TO,
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.depotsPriceApplyTo,
        initNewItemModel: (item, id) => {
            return {
                id,
                depots: getDepots()
                    .filter(depot => !id || depot.id !== id)
                    .map(depot => ({ id: depot.id, name: depot?.name, selected: false }))
                    .sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1)),
            };
        },
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createDepotMenuEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                accessor: 'url',
                isEditable: true,
                isFillContent: true,
                isFull: true,
                type: CONTROL_TYPE.frame,
            },
        ],
        actionName: DEPOTMENU_SETTING,
        initNewItemModel: (item, id) => {
            const { login, password } = getMenuCredentials();
            return {
                id,
                url: `https://delivapp-menu.azurewebsites.net/signin`,
                urlTo: `/businessedit/${(item && item.businessId) || 'grilled'}`,
                login,
                password,
            };
        },
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_MODEL.depotsMenu,
        parentUrl: TYPE_SECTION_MODEL.depots,
        mappingLambda: state => state.restaurant.data.depots || [],
        saveControlsConfig: {
            save: {
                title: 'basic.APPLY_BUTTON',
                isAlwaysActive: true,
            },
            cancel: {
                title: 'basic.CLOSE_BUTTON',
            },
        },
    });
}

export function createRestaurateurMenuEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                accessor: 'url',
                isEditable: true,
                isFillContent: true,
                isFull: true,
                type: CONTROL_TYPE.frame,
            },
        ],
        actionName: DEPOTMENU_SETTING,
        initNewItemModel: (item, id) => {
            const { login, password } = getMenuCredentials();
            return {
                id,
                url: `https://delivapp-menu.azurewebsites.net/signin`,
                urlTo: `/businessedit/${(item && item.businessId) || 'grilled'}`,
                login,
                password,
            };
        },
        typeAction: args.typeAction,
        typeModel: '/menu',
        parentUrl: '/',
        mappingLambda: state => state.restaurant.data.depots || [],
        saveControlsConfig: {
            save: {
                title: 'basic.APPLY_BUTTON',
                isAlwaysActive: true,
            },
            cancel: {
                title: 'basic.CLOSE_BUTTON',
            },
        },
    });
}

export function createDepotDefaultRegionControlcentersSectionModel({ ...args }) {
    const { id } = getHistoryLocationParams(args.params);
    return new SectionModel({
        isForceUpdate: true,
        filterOptions: createDepotDefaultRegionControlcentersSectionFilter(),
        columns: [
            {
                title: 'settings.depots.DEPOT_DEFAULT_REGION_CONTROL_CENTER_NAME_LABEL',
                component: item => getDepotDefaultRegionControlCenterName(item),
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.depots.DEPOT_DEFAULT_REGION_CONTROL_CENTER_EDIT_TOOLTIP'),
            },
        ],
        actionLoad: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_GET,
        actionName: DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters,
        parentUrl: TYPE_SECTION_MODEL.depots,
        tableMinWidth: 600,
        label: 'settings.depotSettingsLabel.REGIONS',
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersNew + `?parentId=${id}`,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.back }),
        ],
        mappingLambda: (state, id) => {
            const depot = state.restaurant.data.depots.find(f => f.id === id);
            return depot?.depotControlCenter || [];
        },
    });
}

export function createDepotDefaultRegionControlcentersEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'placeEditor.DEPOT_REGION_LABEL',
                accessor: 'deliveryRegionId',
                connect: state => ({
                    values: [{ key: null, value: '-' }]
                        .concat(
                            (state.restaurant.data.configuration && state.restaurant.data.configuration.regions) || []
                        )
                        .map(region => ({ key: region.id, value: region?.name })),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.map.DEPOT_EDIT_DEFAULT_CONTROL_CENTER',
                accessor: 'controlCenterId',
                connect: state => ({
                    values: [{ key: null, value: '-' }]
                        .concat(state.restaurant.data.controlCenters || [])
                        .map(center => ({ key: center.controlCenterId, value: center?.name })),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_DEPOT_SHEDULE_ENABLED_LABEL',
                accessor: 'enableSchedule',
                isEditable: true,
                isFillContent: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL',
                accessor: 'schedule',
                isEditable: true,
                isFillContent: true,
                isShow: (item) => item.enableSchedule,
                type: CONTROL_TYPE.schedule,
            },
        ],
        actionName: DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING,
        initNewItemModel: (item) => {
            return item
                ? { ...item, schedule: getInitModel(MODEL_TYPE.scheduleModel, item.schedule) }
                : {
                    id: '',
                    deliveryRegionId: '',
                    controlCenterId: '',
                    schedule: getInitModel(MODEL_TYPE.scheduleModel),
                };
        },
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersEdit
                : TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersNew,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters,
        label: 'settings.depotSettingsLabel.REGIONS',
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, parentId } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(parentId || id);
                },
            }),
        ],
        mappingLambda: state => {
            const { parentId } = getHistoryLocationParams(window.location.search);
            const depot = state.restaurant.data.depots.find(f => f.id === parentId);
            return depot?.depotControlCenter || [];
        },
    });
}

export function createDepotIntegrationsSectionModel({ ...args }) {
    const { id } = getHistoryLocationParams(args.params);
    return new SectionModel({
        filterOptions: createDepotIntegrationsSectionFilter(),
        columns: [
            {
                title: 'settings.integrations.TYPE_LABEL',
                accessor: 'type',
                width: '250px',
                fixedWidth: '250px',
                isHideDeleteButton: item => item.integrationType !== INTEGRATION_TYPE.squareUp,
            },
            {
                title: 'enums.integrationTypes.SOURCE_ID',
                isShow: item => item.integrationType !== INTEGRATION_TYPE.squareUp,
                accessor: 'sourceId',
            },
            {
                title: 'settings.integrations.IS_DISABLED',
                controlTitle: '',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                centered: true,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                fixedWidth: "90px",
                centered: true,
                component: () => (
                    <IconButton
                        isIcon
                        iconName={'editItem'}
                        tooltipText={i18n.t('settings.integrations.EDIT_INTEGRATION_TOOLTIP')}
                    />
                ),
                isShow: item => item.integrationType !== INTEGRATION_TYPE.squareUp,
            },
            {
                title: '',
                width: '40px',
                fixedWidth: '40px',
                hideInHeader: item => item.integrationType !== INTEGRATION_TYPE.squareUp,
                component: item => {
                    if(item && item.integrationType === INTEGRATION_TYPE.squareUp){
                        return <SquareUpControl id={id} />;
                    }
                },
                isShow: item => item.integrationType === INTEGRATION_TYPE.squareUp,
            },
        ],
        actionName: DEPOT_INTEGRATIONS,
        actionLoad: DEPOT_INTEGRATIONS_GET,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotIntegrations,
        parentUrl: TYPE_SECTION_MODEL.depots,
        editAction: DEPOT_INTEGRATIONS_UPDATE,
        tableMinWidth: 600,
        hideHeaderItemControls: true,
        key: 'sourceId',
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_DEPOT_MODEL.depotIntegrationsNew + `?parentId=${id}`,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.iconControl,
                text: i18n.t('settings.square.AUTHORIZE'),
                action: DEPOT_SQUARE_ASSOCIATED_AUTHORIZATION_SHORT,
                isShow: items => {
                    return items.some(
                        el => el.integrationType === INTEGRATION_TYPE.squareUp && el.squareUpAuthorizationRequired
                    );
                },
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.iconControl,
                text: i18n.t('settings.square.REVOKE_LABEL'),
                action: DEPOT_SQUARE_ASSOCIATED_REVOKE,
                isShow: items => {
                    return items.some(
                        el => el.integrationType === INTEGRATION_TYPE.squareUp && !el.squareUpAuthorizationRequired
                    );
                },
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.back }),
        ],
        isForceUpdate: true,
        label: 'settings.depotSettingsLabel.INTEGRATIONS',
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const depot = state.restaurant?.data?.depots.find(f => f.id === id);
            return depot?.integrations?.length ? depot?.integrations?.map(el => {
                const type = getDepotIntegrationTypes().find(f => f.key === el.integrationType);
                return {
                    ...el,
                    type: (type && type['value']) || '',
                    squareUpAuthorizationRequired: depot.squareUpAuthorizationRequired,
                }
            }) : [];
        },
    });
}

export function createDepotIntegrationAddSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'enums.integrationTypes.LABEL',
                accessor: 'integrationType',
                isEditable: true,
                values: sortItemsByProp(getDepotIntegrationAddTypes(), 'value'),
                type: CONTROL_TYPE.select,
            },
            {
                title: 'enums.integrationTypes.SOURCE_ID',
                placeholder: i18n.t('enums.integrationTypes.PLACEHOLDER_TOKEN'),
                isShow: value => value.integrationType && value.integrationType !== INTEGRATION_TYPE.squareUp,
                accessor: 'sourceId',
                isEditable: true,
                type: CONTROL_TYPE.text,
                formatedFn: () => generateRandomString(12),
                firmatedIconTooltip: i18n.t('enums.integrationTypes.GENERATE_ID'),
                formatedIcon: 'generateRandom',
            },
        ],
        actionName: DEPOT_INTEGRATIONS,
        actionLoad: DEPOT_INTEGRATIONS_GET,
        initNewItemModel: item => getInitModel(MODEL_TYPE.depotIntegrationModel, item),
        typeAction: args.typeAction,
        typeModel: TYPE_SECTION_DEPOT_MODEL.depotIntegrationsNew,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotIntegrations,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, parentId } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id || parentId);
                },
            }),
        ],
        label: 'settings.depotSettingsLabel.INTEGRATIONS',
        stayAfterSaveFn: item => {
            return item.integrationType === INTEGRATION_TYPE.squareUp;
        },
        mappingLambda: state => {
            const { parentId } = getHistoryLocationParams(args.params);
            const depot = state.restaurant.data.depots.find(f => f.id === parentId);
            return (depot && depot.integrations) || [];
        },
    });
}

export function createDepotSquareAssociatedLocationSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                accessor: 'squareAssociatedLocations',
                isEditable: true,
                type: CONTROL_TYPE.squareAssociatedLocations,
            },
        ],
        actionName: DEPOT_SQUARE_ASSOCIATED_LOCATION,
        typeAction: args.typeAction,
        saveControlsConfig: {
            cancel: {
                action: (item, history) => {
                    history.push(TYPE_SECTION_DEPOT_MODEL.depotIntegrations + '?id=' + item.id);
                },
            },
        },
        typeModel: TYPE_SECTION_MODEL.depotSquareAssociatedLocation,
        initNewItemModel: (item) => item,
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createDepotProvidersSectionModel({typeModel, parentUrl, pathForNew, pathForEdit, isHideBack, breadcrumbs, label,...args}) {
    const { id } = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        filterOptions: createProvidersSectionFilter(),
        columns: [
            {
                title: "",
                accessor: "provider",
                width: "50px",
                fixedWidth: "50px",
                component: item => {
                    return (
                        <Avatar hideIconColor avatar={getExternalCycleLogo(item)}/>
                    )
                }
            },
            {
                title: "settings.orderingmenu.PROVIDER",
                accessor: "providerName",
                type: CONTROL_TYPE.text,
            },
            {
                title: "settings.orderingmenu.AUTO_ASSIGMENT_RETRIES",
                accessor: "autoAssignmentRetries",
                type: CONTROL_TYPE.text,
                centered: true,
            },
            {
                title: "settings.orderingmenu.ENABLED",
                isEditable: true,
                withLoader: true,
                accessor: "enabled",
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.orderingmenu.AUTO_ASSIGMENT",
                isEditable: true,
                withLoader: true,
                accessor: "autoAssignment",
                type: CONTROL_TYPE.switch
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: pathForEdit || TYPE_SECTION_DEPOT_MODEL.providerEdit,
                iconName: "editItem",
                tooltipText: "Edit",
                isHideDeleteButton: item=> !item.virtual
            },
            {
                //empty item for align items
                title: "",
                accessor: "enabled",
                width: "90px",
                fixedWidth: "90px",
                type: CONTROL_TYPE.label,
                hideInHeader: true,
                isInline: true,
                isShow: item => !item.virtual
            },
        ],
        isForceUpdate: true,
        editAction: DEPOT_PROVIDER_UPDATE,
        dragActionName: DEPOT_PROVIDERS_REORDER,
        actionName: DEPOT_PROVIDERS,
        actionLoad: DEPOT_PROVIDERS_GET,
        typeAction: args.typeAction,
        tableMinWidth: 800,
        typeModel: typeModel || TYPE_SECTION_DEPOT_MODEL.provider,
        parentUrl: parentUrl || TYPE_SECTION_MODEL.depots,
        actionOnUnmount: DEPOT_PROVIDERS_UNMOUNT,
        dragSortPropName: "priority",
        label: label || 'settings.orderingmenu.PROVIDER',
        sortByFunc: function(items) {
            return sortByProp(items, "priority");
        },
        breadcrumbs: breadcrumbs || [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            })
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: (pathForNew || TYPE_SECTION_DEPOT_MODEL.providerNew) + `?parentId=${id}`,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
            createControlModel({ type: MODEL_CONTROL_TYPE.back, isShow: () => !isHideBack })
        ],
        initNewItemModel: (item) => item,
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const depot = (state.restaurant.data.depots || []).find(f=> f.id === id) || {};
            return depot.providers || [];
        }
    });
}

export function createDepotProviderEditSectionModel({parentUrl, typeModel, breadcrumbs, ...args}) {
    return new SectionModel({
        columns: [
            {
                title: "settings.orderingmenu.PROVIDER",
                accessor: "providerName",
                isShow: item=> !item.virtual,
                type: CONTROL_TYPE.text
            },
            {
                title: "settings.orderingmenu.PROVIDER",
                accessor: "providerName",
                isEditable: true,
                isShow: item=> item.virtual,
                type: CONTROL_TYPE.text
            },
            {
                title: "settings.orderingmenu.AUTO_ASSIGMENT_TIMEOUT",
                accessor: "autoAssignmentTimeout",
                isEditable: true,
                type: CONTROL_TYPE.number
            },
            {
                title: "settings.orderingmenu.ENABLED",
                accessor: "enabled",
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: "settings.orderingmenu.AUTO_ASSIGMENT",
                accessor: "autoAssignment",
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch
            },
            {
                title: "settings.orderingmenu.AUTO_ASSIGMENT_RETRIES",
                accessor: "autoAssignmentRetries",
                isEditable: true,
                type: CONTROL_TYPE.number
            },
            {
                title: "settings.orderingmenu.VENUE_ID",
                accessor: "venueId",
                isEditable: true,
                type: CONTROL_TYPE.text,
                isShow: item =>  item.provider === EXTERNAL_COURIER_PROVIDER.wolt
            },
            {
                title: "settings.orderingmenu.USER",
                accessor: "user",
                isEditable: true,
                type: CONTROL_TYPE.text,
                isShow: item =>  item.provider === EXTERNAL_COURIER_PROVIDER.haat
            },
            {
                title: "settings.orderingmenu.PASSWORD",
                accessor: "password",
                isEditable: true,
                type: CONTROL_TYPE.password,
                isShow: item =>  item.provider === EXTERNAL_COURIER_PROVIDER.haat
            },
            {
                title: "settings.orderingmenu.VIRTUAL_AUTOMATIC_DELIVERY",
                accessor: "virtualAutomaticDelivery",
                isEditable: true,
                type: CONTROL_TYPE.number,
                isShow: item => !!item.virtual
            },
            {
                title: 'placeEditor.DEPOT_REGION_LABEL',
                isShow: item => item.autoAssignment,
                accessor: 'autoAssignmentRegionIds',
                refreshedId: 'autoAssignmentRegionIds',
                placeholderMulti: (selectedData, data) => {
                    const filteredDepots = data.filter(depot => selectedData.includes(depot.value));

                    return filteredDepots.map(depot => depot.value).join(', ');
                },
                getDefaultData: (state, selected) => {
                    const result = [];
                    const regions = state.restaurant.data.configuration.regions || [];
                    const autoAssignmentRegions = selected?.autoAssignmentRegionIds;

                    if(regions && autoAssignmentRegions) {
                        regions.forEach((e) => {
                            if(autoAssignmentRegions.includes(e.name)) {
                                result.push({key: `${e.id}`, value: e?.name});
                            }
                        });
                    }

                    return result;
                },
                connect: state => {
                    return {
                        values: []
                            .concat(
                                (state.restaurant.data.configuration && state.restaurant.data.configuration.regions) || []
                            )
                            .map(region => ({key: `${region.id}`, value: region?.name})),
                    }
                },
                isEditable: true,
                type: CONTROL_TYPE.defaultMultiSelect,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_DEPOT_SHEDULE_ENABLED_LABEL',
                accessor: 'enableSchedule',
                isEditable: true,
                isFillContent: true,
                isShow: item => item.autoAssignment,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL',
                accessor: 'autoAssignmentSchedule',
                isEditable: true,
                isFillContent: true,
                isShow: item => item.autoAssignment && item.enableSchedule,
                type: CONTROL_TYPE.schedule,
            },
        ],
        actionName: DEPOT_PROVIDER,
        actionLoad: DEPOT_PROVIDERS_GET,
        typeAction: args.typeAction,
        parentUrl: parentUrl || TYPE_SECTION_DEPOT_MODEL.provider,
        typeModel: typeModel || (args.typeAction === 'edit' ?
            TYPE_SECTION_DEPOT_MODEL.providerEdit :
            TYPE_SECTION_DEPOT_MODEL.providerNew)
        ,
        getLabel: (data) => {
            return data ? data.providerName : ""
        },
        controls: [
            createControlModel({ type: MODEL_CONTROL_TYPE.back })
        ],
        breadcrumbs: breadcrumbs || [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, parentId } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(parentId || id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL_PROVIDERS',
            }),
        ],
        initNewItemModel: (item) => item ?
            {...getInitModel(MODEL_TYPE.provider, item), autoAssignmentSchedule: getInitModel(MODEL_TYPE.scheduleModel, item.autoAssignmentSchedule)} :
            {...getInitModel(MODEL_TYPE.provider, { virtual: true }), autoAssignmentSchedule: getInitModel(MODEL_TYPE.scheduleModel)}
        ,
        mappingLambda: state => {
            const { parentId } = getHistoryLocationParams(window.location.search);
            const depot = (state.restaurant.data.depots || []).find(f => f.id === parentId) || {};
            return depot.providers || [];
        }
    });
}

function createDepotsSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(depot) {
        return getDepotFilter(this.selected, depot);
    });

    filterOptionsModel.addOptions(
        'withMenu',
        CONTROL_TYPE.checkbox,
        'settings.depots.WITH_MENU_FILTER_LABEL',
        depot => !!depot.businessId,
        false,
        true
    );

    filterOptionsModel.addOptions(
        'withoutMenu',
        CONTROL_TYPE.checkbox,
        'settings.depots.WITHOUT_MENU_FILTER_LABEL',
        depot => !depot.businessId,
        false,
        true
    );

    /*
  filterOptionsModel.addOptions(
    "withPay",
    CONTROL_TYPE.checkbox,
    "settings.depots.PAY_ENABLED",
    depot => depot.onlineState === CREDIT_ACCEPT.selling,
    false,
    true
  );

  filterOptionsModel.addOptions(
    "withoutPay",
    CONTROL_TYPE.checkbox,
    "settings.depots.PAY_DISABLED",
    depot => depot.onlineState !== CREDIT_ACCEPT.selling,
    false,
    true
  );
  */

    /* filterOptionsModel.addOptions(
    "disable_delivery",
    CONTROL_TYPE.checkbox,
    "settings.depots.SERVICES_ENABLED",
    depot => !depot.disable_delivery && !depot.disable_takeaway,
    false,
    true
  );

  filterOptionsModel.addOptions(
    "disable_takeaway",
    CONTROL_TYPE.checkbox,
    "settings.depots.SERVICES_DISABLED",
    depot => depot.disable_delivery || depot.disable_takeaway,
    false,
    true
  );  */

    return filterOptionsModel;
}

function createBusinessCategoriesSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(businessCategory) {
        return getBusinesMenuCategoriesFilter(this.selected, businessCategory);
    });

    return filterOptionsModel;
}

function createProvidersSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(provider) {
        return getProvidersSectionFilter(this.selected, provider);
    });

    return filterOptionsModel;
}

function createDepotUsersSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function() {
        // check filterSearchConfig in model
        return true
    });

    return filterOptionsModel;
}

function createDepotIntegrationsSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(integration) {
        return getDepotIntegrationsSectionFilter(this.selected, integration);
    });

    return filterOptionsModel;
}

function createDepotDefaultRegionControlcentersSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(depotControlCenter) {
        return getDepotDefaultRegionControlCenterNameFilter(this.selected, depotControlCenter);
    });

    return filterOptionsModel;
}
