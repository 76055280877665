import {
  DELIVERY_STRATEGY, DELIVERY_TYPE,
  ICONS_MARKER_TYPE,
  MODEL_TYPE,
  ORDER_STATE,
  ORDER_TYPE, PAY_METHOD
} from "../utils/enums";
import {getPaymentModel} from "./paymentsModel.js";

export const orderModel = {
  id: null,
  depotId: null,
  orderId: null,
  controlCenterId: null,
  type: ORDER_TYPE.delivery,
  deliveryStrategy: DELIVERY_STRATEGY.asap,
  date: "",
  readinessTime: "",
  state: ORDER_STATE.new,
  firstName: "",
  lastName: "",
  notes: "",
  payments: [
    {
      amount: 0,
      paymentInfo: "",
      isCache: true,
      modelType: MODEL_TYPE.payments
    },
    {
      amount: 0,
      paymentInfo: "",
      isCache: false,
      modelType: MODEL_TYPE.payments
    }
  ],
  consumerPhone: "",
  editUserName: "",
  targetTime: null,
  address: {
    city: "",
    country: "",
    entrance: "",
    notes: "",
    organization: "",
    postal: "",
    state: "",
    street: "",
    streetNumber: "",
    location: null,
    modelType: MODEL_TYPE.address
  },
  isHighPriority: false,
  isExtraSize: false,
  markerType: ICONS_MARKER_TYPE.empty,
  modelType: MODEL_TYPE.order
};

export const getOrderModel = (data) => {
  const cashPayment = getPaymentModel(data.payments, PAY_METHOD.cash);
  const cardPayment = getPaymentModel(data.payments, PAY_METHOD.creditCard);

  return {
    id: data.id,
    depotId: data.depot_id,
    orderId: data.sequence_id,
    controlCenterId: data.control_center_id,
    type: data.delivery_type === DELIVERY_TYPE.delivery ? ORDER_TYPE.delivery : ORDER_TYPE.takeAway,
    deliveryStrategy: data.delivery_strategy,
    readinessTime: data.readiness_time,
    state: data.status,
    firstName: data.customer_name,
    lastName: "",
    notes: data.comment,
    consumerPhone: data.phone,
    isHighPriority: data.is_high_priority,
    isExtraSize: data.is_extrasize,
    date: data.creation_datetime,
    address: {
      city: data.city,
      country: "",
      entrance: "",
      notes: data.address_notes || 'test',
      organization: data.organization,
      postal: "",
      state: "",
      street: data.address,
      streetNumber: "",
      location: data.location ? {
        latitude: data.location.lat,
        longitude: data.location.lng,
      } : null,
      modelType: MODEL_TYPE.address
    },
    location: data.location ? {
      latitude: data.location.lat,
      longitude: data.location.lng,
    } : null,
    payments: [cashPayment, cardPayment],
    editUserName: "",
    targetTime: data.delivery_datetime,
    markerType: ICONS_MARKER_TYPE.empty,
    modelType: MODEL_TYPE.order
  }
}