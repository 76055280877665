import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import SectionItemNumber from "../SectionItemNumber";

const RegionDeliveryPrice = ({
  item,
  t,
  handleOnChange,
  handleOnDeleteItem,
  inlineType
}) => (
  <div key={`regionDeliveryPrice-${item.id}`} className={classNames("regionDeliveryPrice")}>
    <SectionItemNumber item={item}
      value={item.deliveryPrice}
      column={{
        accessor: "deliveryPrice",
        title: `${t("settings.map.DEPOT_FIX_DELIVERY_PRICE_LABEL")} ${(item && item.name) || ""}`,
        inlineType: inlineType || ''
      }}
      onPropChange={itemValue=> handleOnChange({ ...item, ...itemValue })}
    />
    <div className={classNames("delete")} onClick={() => handleOnDeleteItem(item.id)} />
  </div>
);

export default withTranslation()(RegionDeliveryPrice);
