import environment from 'environment';

import {getRestaurantConfigPropValue} from '../services/restaurant';
import {
  COPY_TELEGRAM_COMMAND,
  DEPOTMENU_SETTING_CHECK, EXECUTE_COMPAIGN,
  PUSHER_DEPOT_SERVICES_REFRESH,
  PUSHER_RESTAURANT_STATE_UPDATE,
} from '../store/reducers/restaurant/restaurant-actions';
import {
  PUSHER_CYCLE_DISPATCHED,
  PUSHER_CYCLE_ROUTE_UPDATE,
  PUSHER_CYCLE_STATE_UPDATE,
  PUSHER_MANUAL_ASSOCIATE_UPDATE,
  PUSHER_MANUAL_UNASSOCIATE_UPDATE,
  PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST,
  PUSHER_SET_AWAY,
} from '../store/reducers/common-actions';
import {
  NOTIFICATION_ADD,
  NOTIFICATION_DELETE,
  NOTIFICATIONS_RESTART,
} from '../store/reducers/notification/notification-actions';
import {
  PUSHER_ASSISTANCE_UPDATE,
  PUSHER_CONSUMER_ORDER_ARRIVED,
  PUSHER_CYCLE_REORDER,
  PUSHER_GROUP_LOCK_UPDATE,
  PUSHER_GROUP_REORDER,
  PUSHER_GROUP_UPDATE,
  PUSHER_NEW_ORDER,
  PUSHER_ORDER_STATE_UPDATE,
  PUSHER_UPDATE_ORDER,
} from '../store/reducers/order/order-actions';
import {
  PUSHER_COURIER_LOCK_UPDATE,
  PUSHER_COURIER_SHIFT_CREATED,
  PUSHER_DEPOT_CYCLE_REFRESH,
  PUSHER_USER_DELETED,
  PUSHER_USER_UPDATED,
} from '../store/reducers/userModel/userModel-actions';

import {getPeriodByType} from './convertTime';
import {
  BASIC_PATH,
  BILLING_PATHS,
  CONTROL_TYPE,
  COURIER_STATE, DELIVERY_TYPE,
  MARKETPLACE_SETTINGS_MODEL,
  ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
  ORDERING_MENU_TYPE_SECTION_MODEL,
  PERIOD_TYPE,
  RECONCILIATIONS_MODEL,
  ROLES_MANAGER_TYPE,
  SHIFTS_MODEL,
  TYPE_MARKER,
  TYPE_SECTION_DEPOT_MODEL,
  TYPE_SECTION_MENUTEMPLATE_MODEL,
  TYPE_SECTION_MESSAGE_MODEL,
  TYPE_SECTION_MODEL,
  USER_ROLES,
} from './enums';
import i18n from '../i18n';

export const MAP_POLYGON_DIFF = 0.02;
export const ZOOM_MAX = 21;
export const settingsRowHeightDefault = 56;
export const ordersRowHeightDefault = 95;
export const ordersRowHeightDefaultResize = 235;
export const ordersRowHeightExpand = 210;
export const ordersRowHeightExpandResize = 460;
export const activeOrderRowWidthResize = 768;
export const TIME_INFO_BOX_WARNING_MINUTES = 5;
export const SEARCH_RADIUS = 30000;
export const RATE_CONTROL_WIDTH_HIDE = 600;

export const NEW_ASSOCIATE_GROUP_ID = "newAssociateGroup";

export const mapPolygonFill = {
  color: "#ed552b",
  opacity: 0.2,
  weight: undefined
};

export const mapPolygonStroke = {
  color: "#ed552b",
  opacity: undefined,
  weight: 1
};

export function emptyImage() {
  const img = new Image();
  img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
  return img;
}

export const defaultControlCenterColors = [
  "#b87333",
  "silver",
  "gold",
  "#e5e4e2",
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6"
];

export const defaultIcons = {
  default: "/assets/defaulticons/logo_UK.svg",
  foodrush: "/assets/defaulticons/foodrush.png",
  thabazimbi: "/assets/defaulticons/thabazimbi.png",
  yango: "/assets/defaulticons/yango.png",
  wolt: "/assets/defaulticons/wolt_logo.png",
  doorDash: "/assets/defaulticons/doorDash.png",
  "smartioo.ro" : "/assets/defaulticons/smartioo.jpeg",
  "eatnow.ro" : "/assets/defaulticons/eatnow.png",
  "zipprex.do" : "/assets/defaulticons/zipprex.do.png",
  "zipgo.do" : "/assets/defaulticons/zipgo.do.png",
  "deliverly.il" : "/assets/defaulticons/deliverly.il.png",
  "fuelitup.au" : "/assets/defaulticons/fuelitup.au.jpeg",
  "indrive.kz" : "/assets/defaulticons/indrive.kz.png",
  "indrive.astana.kz" : "/assets/defaulticons/indrive.kz.png",
  "zooz.il" : "/assets/defaulticons/zooz.jpg",
  "gett" : "/assets/defaulticons/gett.png",
  "michelin2" : "/assets/defaulticons/michelin2.jpg",
}

export const getDepotReadonlySectionPages = () => {
  const orderingPages = Object.values(ORDERING_MENU_TYPE_SECTION_MODEL).reduce((acc, cv) => {
    acc[cv] = true;
    return acc;
  }, {});

  return {
    ...orderingPages,
  }
}

export const defaultRolesManagerConfig = {
  [ROLES_MANAGER_TYPE.menu]: {
    "/": true,
    "/orders": false,
    "/new-order": false,
    "/create-order": false,
    "/edit-order": false,
    "/edit-customer-order": true,
    "/assistance": false,
    "/pendingorders": false,
    [BASIC_PATH.adminOrders]: false,
    "/menu": false,
    "/reports": false,
    "/settings": false,
    "/info": false,
    [BILLING_PATHS.billing]: false,
    [BILLING_PATHS.address]: false,
    [BILLING_PATHS.payment]: false,
    [BILLING_PATHS.plan]: false,
    [BILLING_PATHS.usages]: false,
    [TYPE_SECTION_MODEL.users]: false,
    [TYPE_SECTION_MODEL.usersEdit]: false,
    [TYPE_SECTION_MODEL.usersNew]: false,
    [TYPE_SECTION_MODEL.cloudPrinters]: false,
    [TYPE_SECTION_MODEL.cloudPrintersNew]: false,
    [TYPE_SECTION_MODEL.cloudPrintersEdit]: false,
    [TYPE_SECTION_MODEL.userConfirmPassword]: false,
    [TYPE_SECTION_MODEL.depots]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotsEdit]: false,
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit]: false,
    [TYPE_SECTION_MESSAGE_MODEL]: false,
    [TYPE_SECTION_MODEL.depotsNew]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersNew]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersEdit]: false,
    [TYPE_SECTION_DEPOT_MODEL.provider]: false,
    [TYPE_SECTION_DEPOT_MODEL.providerNew]: false,
    [TYPE_SECTION_DEPOT_MODEL.providerEdit]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotsEditPrice]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotMenu]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotsMenuCopy]: false,
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateMenuCopy]: false,
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateMenu]: false,
    [TYPE_SECTION_DEPOT_MODEL.telegram]: false,
    [TYPE_SECTION_DEPOT_MODEL.operationMode]: false,
    [TYPE_SECTION_DEPOT_MODEL.operationModeNew]: false,
    [TYPE_SECTION_DEPOT_MODEL.operationModeActive]: false,
    [TYPE_SECTION_DEPOT_MODEL.operationModeEdit]: false,
    [TYPE_SECTION_MODEL.depotDefaultRegionControlCenters]: false,
    [TYPE_SECTION_MODEL.depotDefaultRegionControlCentersNew]: false,
    [TYPE_SECTION_MODEL.depotDefaultRegionControlCentersEdit]: false,
    [TYPE_SECTION_MODEL.provider]: false,
    [TYPE_SECTION_MODEL.providerNew]: false,
    [TYPE_SECTION_MODEL.providerEdit]: false,
    [TYPE_SECTION_MODEL.depotsEditPrice]: false,
    [TYPE_SECTION_MODEL.depotsPriceApplyTo]: false,
    [TYPE_SECTION_MODEL.depotsMenu]: false,
    [TYPE_SECTION_MODEL.depotpaymewallet]: false,
    [TYPE_SECTION_MODEL.depotpaymewalletNew]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfo]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotusers]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotusersEdit]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotusersNew]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotusersConfirmPassword]: false,
    [TYPE_SECTION_MODEL.depotsCategories]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotIntegrations]: false,
    [TYPE_SECTION_DEPOT_MODEL.depotIntegrationsNew]: false,
    [TYPE_SECTION_MODEL.depotSquareAssociatedLocation]: false,
    [TYPE_SECTION_MODEL.depotsCategoriesEdit]: false,
    [TYPE_SECTION_MODEL.generalSettings]: false,
    [TYPE_SECTION_MODEL.courierAppSettings]: false,
    [TYPE_SECTION_MODEL.businessAppSettings]: false,
    [TYPE_SECTION_MODEL.personalSettings]: false,
    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: false,
    [TYPE_SECTION_MODEL.telegramSettings]: false,
    [MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration]: false,
    [TYPE_SECTION_MODEL.restaurantPaymeWallet]: false,
    [TYPE_SECTION_MODEL.restaurantPaymeWalletNew]: false,
    [TYPE_SECTION_MODEL.regions]: false,
    [TYPE_SECTION_MODEL.regionsEdit]: false,
    [TYPE_SECTION_MODEL.regionsNew]: false,
    [TYPE_SECTION_MODEL.fleet]: false,
    [TYPE_SECTION_MODEL.fleetEdit]: false,
    [TYPE_SECTION_MODEL.fleetTelemetry]: false,
    [TYPE_SECTION_MODEL.fleetNew]: false,
    [TYPE_SECTION_MODEL.providers]: false,
    [TYPE_SECTION_MODEL.providersEdit]: false,
    [TYPE_SECTION_MODEL.providersNew]: false,
    [TYPE_SECTION_MODEL.deliveryProviders]: false,
    [TYPE_SECTION_MODEL.deliveryProvidersNew]: false,
    [TYPE_SECTION_MODEL.deliveryProvidersEdit]: false,
    [TYPE_SECTION_MODEL.serviceCities]: false,
    [TYPE_SECTION_MODEL.serviceCitiesNew]: false,
    [TYPE_SECTION_MODEL.serviceCitiesEdit]: false,
    [TYPE_SECTION_MODEL.searchShortcuts]: false,
    [TYPE_SECTION_MODEL.searchShortcutsNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.marketplace]: false,
    [MARKETPLACE_SETTINGS_MODEL.loyalty]: false,
    [MARKETPLACE_SETTINGS_MODEL.delivery]: false,
    [MARKETPLACE_SETTINGS_MODEL.appBuilder]: false,
    [MARKETPLACE_SETTINGS_MODEL.deepLink]: false,
    [MARKETPLACE_SETTINGS_MODEL.pages]: false,
    [MARKETPLACE_SETTINGS_MODEL.pagesEdit]: false,
    [MARKETPLACE_SETTINGS_MODEL.pagesNew]: false,
    [TYPE_SECTION_MODEL.marketplace]: false,
    [TYPE_SECTION_MODEL.marketplaceSettings]: false,
    [TYPE_SECTION_MODEL.pages]: false,
    [TYPE_SECTION_MODEL.pagesEdit]: false,
    [TYPE_SECTION_MODEL.pagesNew]: false,
    [TYPE_SECTION_MODEL.namedPlaces]: false,
    [MARKETPLACE_SETTINGS_MODEL.marketplaceNew]: false,
    [TYPE_SECTION_MODEL.namedPlacesEdit]: false,
    [SHIFTS_MODEL.providers]: false,
    [SHIFTS_MODEL.history]: false,
    [SHIFTS_MODEL.historyEdit]: false,
    [SHIFTS_MODEL.shiftHistory]: false,
    [SHIFTS_MODEL.currentShiftDepotEdit]: false,
    [SHIFTS_MODEL.currentShiftHistory]: false,
    [SHIFTS_MODEL.currentShiftProviderHistory]: false,
    [TYPE_SECTION_MODEL.controlCenters]: false,
    [TYPE_SECTION_MODEL.controlCentersEdit]: false,
    [TYPE_SECTION_MODEL.controlCentersActivate]: false,
    [TYPE_SECTION_MODEL.controlCentersDeactivate]: false,
    [TYPE_SECTION_MODEL.controlCentersChangeuser]: false,
    [MARKETPLACE_SETTINGS_MODEL.businessCategories]: false,
    [MARKETPLACE_SETTINGS_MODEL.businessCategoriesNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.businessCategoriesEdit]: false,
    [TYPE_SECTION_MODEL.operationTiming]: false,
    [TYPE_SECTION_MODEL.operationTimingEdit]: false,
    [TYPE_SECTION_MODEL.operationTimingNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.offers]: false,
    [MARKETPLACE_SETTINGS_MODEL.offersNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.offersEdit]: false,
    [MARKETPLACE_SETTINGS_MODEL.giftCard]: false,
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts]: false,
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit]: false,
    [MARKETPLACE_SETTINGS_MODEL.campaignsNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.campaignsEdit]: false,
    [MARKETPLACE_SETTINGS_MODEL.campaignsExecutions]: false,
    [TYPE_SECTION_MODEL.courierDeliveryFees]: false,
    [MARKETPLACE_SETTINGS_MODEL.listPromotion]: false,
    [MARKETPLACE_SETTINGS_MODEL.listPromotionNew]: false,
    [MARKETPLACE_SETTINGS_MODEL.listPromotionEdit]: false,
    [MARKETPLACE_SETTINGS_MODEL.deliveryZones]: false,
    [MARKETPLACE_SETTINGS_MODEL.deliveryZonesEdit]: false,
    [MARKETPLACE_SETTINGS_MODEL.deliveryZonesNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenu]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsDeepLinkEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.reviewProductExtra]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideExtra]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideOption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideSuboption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuCategoryOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuSuboptionsOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuOptionsOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuExtraOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuProductOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.gallery]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryNew]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenu]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuTranslations]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: false,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideExtra]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideOption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideSuboption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryEdit]: true,
    [RECONCILIATIONS_MODEL.periodsView]: true,
    [RECONCILIATIONS_MODEL.periodsEdit]: true,
  },
  [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkAction]]: {
      [DEPOTMENU_SETTING_CHECK]: false,
      [COPY_TELEGRAM_COMMAND]: false,
      USER_SHIFT_DOWNLOAD_PDF: false
  },
  [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
    [`${[TYPE_SECTION_MODEL.users]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.depots]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotusers]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotIntegrations]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.offers]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.regions]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.providers]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.deliveryProviders]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.serviceCities]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.searchShortcuts]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.namedPlaces]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.fleet]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.marketplace]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.pages]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.providers]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.history]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftUsers]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftDepots]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.businessCategories]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.cancelReason]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.listPromotion]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.deliveryZones]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}DeleteButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient]}DeleteButton`]: false,

    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.provider]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.telegram]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.operationMode]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.operationTiming]}DeleteButton`]: false,

    [`${[TYPE_SECTION_MODEL.users]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.depots]}AddButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotusers]}AddButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotIntegrations]}AddButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]}AddButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.provider]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.offers]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.regions]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.providers]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.deliveryProviders]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.serviceCities]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.operationTiming]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.searchShortcuts]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.fleet]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.marketplace]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.pages]}AddButton`]: false,
    [`${[TYPE_SECTION_MODEL.namedPlaces]}AddButton`]: false,
    [`${[SHIFTS_MODEL.history]}AddButton`]: false,
    [`${[SHIFTS_MODEL.providers]}AddButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftUsers]}AddButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftDepots]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.businessCategories]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.cancelReason]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.listPromotion]}AddButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.deliveryZones]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.reviewProductExtra]}AddButton`]: false,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.gallery]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra]}AddButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]}AddButton`]: false,
    [`${[RECONCILIATIONS_MODEL.settings]}AddButton`]: false,
    [`${[RECONCILIATIONS_MODEL.settingsTemplateEdit]}AddButton`]: false,
    [`${[RECONCILIATIONS_MODEL.settingsTemplateNew]}AddButton`]: false,
    [`${[RECONCILIATIONS_MODEL.periods]}AddButton`]: false,
    [`${[RECONCILIATIONS_MODEL.periodsView]}AddButton`]: false,
    [`${[RECONCILIATIONS_MODEL.periodsEdit]}AddButton`]: false,
    grCode: false
  },
  [ROLES_MANAGER_TYPE.sectionItemEdit]: {

  }
}

export const initRolesManagerConfig = {
  [ROLES_MANAGER_TYPE.menu]: {
    "/": true,
    "/orders": true,
    "/new-order": true,
    "/create-order": true,
    "/edit-order": true,
    "/edit-customer-order": true,
    "/assistance": true,
    "/pendingorders": true,
    "/marketplace-settings": true,
    "/adminorders": true,
    [BASIC_PATH.adminOrders]: true,
    "/menu": true,
    "/reports": true,
    "/settings": true,
    "/info": true,
    "/settings/list": true,
    "/marketplace-settings/list": true,
    [RECONCILIATIONS_MODEL.settings]: true,
    [RECONCILIATIONS_MODEL.settingsTemplateNew]: true,
    [RECONCILIATIONS_MODEL.settingsTemplateEdit]: true,
    [RECONCILIATIONS_MODEL.periods]: true,
    [RECONCILIATIONS_MODEL.periodsView]: true,
    [RECONCILIATIONS_MODEL.periodsEdit]: true,
    [BILLING_PATHS.billing]: true,
    [BILLING_PATHS.address]: true,
    [BILLING_PATHS.payment]: true,
    [BILLING_PATHS.plan]: true,
    [BILLING_PATHS.usages]: true,
    [TYPE_SECTION_MODEL.users]: true,
    [TYPE_SECTION_MODEL.usersEdit]: true,
    [TYPE_SECTION_MODEL.usersNew]: true,
    [TYPE_SECTION_MODEL.cloudPrinters]: true,
    [TYPE_SECTION_MODEL.cloudPrintersNew]: true,
    [TYPE_SECTION_MODEL.cloudPrintersEdit]: true,
    [TYPE_SECTION_MODEL.userConfirmPassword]: true,
    [TYPE_SECTION_MODEL.depots]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotsEdit]: true,
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageInfo]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageGallery]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageGalleryNew]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageGalleryEdit]: true,
    [TYPE_SECTION_MODEL.depotsNew]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersNew]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCentersEdit]: true,
    [TYPE_SECTION_DEPOT_MODEL.provider]: true,
    [TYPE_SECTION_DEPOT_MODEL.providerNew]: true,
    [TYPE_SECTION_DEPOT_MODEL.providerEdit]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotsEditPrice]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotMenu]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotsMenuCopy]: true,
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateMenuCopy]: true,
    [TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateMenu]: true,
    [TYPE_SECTION_DEPOT_MODEL.telegram]: true,
    [TYPE_SECTION_DEPOT_MODEL.operationMode]: true,
    [TYPE_SECTION_DEPOT_MODEL.operationModeNew]: true,
    [TYPE_SECTION_DEPOT_MODEL.operationModeActive]: true,
    [TYPE_SECTION_DEPOT_MODEL.operationModeEdit]: true,
    [TYPE_SECTION_MODEL.depotDefaultRegionControlCenters]: true,
    [TYPE_SECTION_MODEL.depotDefaultRegionControlCentersNew]: true,
    [TYPE_SECTION_MODEL.depotDefaultRegionControlCentersEdit]: true,
    [TYPE_SECTION_MODEL.provider]: true,
    [TYPE_SECTION_MODEL.providerNew]: true,
    [TYPE_SECTION_MODEL.providerEdit]: true,
    [TYPE_SECTION_MODEL.depotsEditPrice]: true,
    [TYPE_SECTION_MODEL.depotsPriceApplyTo]: true,
    [TYPE_SECTION_MODEL.depotsMenu]: true,
    [TYPE_SECTION_MODEL.depotpaymewallet]: true,
    [TYPE_SECTION_MODEL.depotpaymewalletNew]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfo]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotusers]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotusersEdit]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotusersNew]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotusersConfirmPassword]: true,
    [TYPE_SECTION_MODEL.depotsCategories]: true,
    [TYPE_SECTION_MODEL.depotsCategoriesEdit]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotIntegrations]: true,
    [TYPE_SECTION_DEPOT_MODEL.depotIntegrationsNew]: true,
    [TYPE_SECTION_MODEL.depotSquareAssociatedLocation]: true,
    [TYPE_SECTION_MODEL.generalSettings]: true,
    [TYPE_SECTION_MODEL.courierAppSettings]: true,
    [TYPE_SECTION_MODEL.businessAppSettings]: true,
    [TYPE_SECTION_MODEL.personalSettings]: true,
    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
    [TYPE_SECTION_MODEL.telegramSettings]: true,
    [MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration]: true,
    [TYPE_SECTION_MODEL.restaurantPaymeWallet]: true,
    [TYPE_SECTION_MODEL.restaurantPaymeWalletNew]: true,
    [TYPE_SECTION_MODEL.regions]: true,
    [TYPE_SECTION_MODEL.regionsEdit]: true,
    [TYPE_SECTION_MODEL.regionsNew]: true,
    [TYPE_SECTION_MODEL.providers]: true,
    [TYPE_SECTION_MODEL.providersEdit]: true,
    [TYPE_SECTION_MODEL.providersNew]: true,
    [TYPE_SECTION_MODEL.deliveryProviders]: true,
    [TYPE_SECTION_MODEL.deliveryProvidersNew]: true,
    [TYPE_SECTION_MODEL.deliveryProvidersEdit]: true,
    [TYPE_SECTION_MODEL.serviceCities]: true,
    [TYPE_SECTION_MODEL.serviceCitiesNew]: true,
    [TYPE_SECTION_MODEL.serviceCitiesEdit]: true,
    [TYPE_SECTION_MODEL.searchShortcuts]: true,
    [TYPE_SECTION_MODEL.fleet]: true,
    [TYPE_SECTION_MODEL.fleetEdit]: true,
    [TYPE_SECTION_MODEL.fleetTelemetry]: true,
    [TYPE_SECTION_MODEL.fleetNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.marketplace]: true,
    [MARKETPLACE_SETTINGS_MODEL.loyalty]: true,
    [MARKETPLACE_SETTINGS_MODEL.delivery]: true,
    [MARKETPLACE_SETTINGS_MODEL.com]: true,
    [MARKETPLACE_SETTINGS_MODEL.deepLink]: true,
    [MARKETPLACE_SETTINGS_MODEL.pages]: true,
    [MARKETPLACE_SETTINGS_MODEL.pagesEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.pagesNew]: true,
    [TYPE_SECTION_MODEL.marketplace]: true,
    [TYPE_SECTION_MODEL.marketplaceSettings]: true,
    [TYPE_SECTION_MODEL.pages]: true,
    [TYPE_SECTION_MODEL.pagesEdit]: true,
    [TYPE_SECTION_MODEL.pagesNew]: true,
    [TYPE_SECTION_MODEL.searchShortcutsNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.marketplaceNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.marketplaceEdit]: true,
    [TYPE_SECTION_MODEL.namedPlaces]: true,
    [TYPE_SECTION_MODEL.namedPlacesNew]: true,
    [TYPE_SECTION_MODEL.namedPlacesEdit]: true,
    [SHIFTS_MODEL.history]: true,
    [SHIFTS_MODEL.providers]: true,
    [SHIFTS_MODEL.historyEdit]: true,
    [SHIFTS_MODEL.shiftHistory]: true,
    [SHIFTS_MODEL.currentShiftUsers]: true,
    [SHIFTS_MODEL.currentShiftDepotEdit]: true,
    [SHIFTS_MODEL.currentShiftDepots]: true,
    [SHIFTS_MODEL.currentShiftProviderHistory]: true,
    [SHIFTS_MODEL.currentShiftHistory]: true,
    [TYPE_SECTION_MODEL.controlCenters]: true,
    [TYPE_SECTION_MODEL.controlCentersEdit]: true,
    [TYPE_SECTION_MODEL.controlCentersActivate]: true,
    [TYPE_SECTION_MODEL.controlCentersDeactivate]: true,
    [TYPE_SECTION_MODEL.controlCentersChangeuser]: true,
    [MARKETPLACE_SETTINGS_MODEL.businessCategories]: true,
    [MARKETPLACE_SETTINGS_MODEL.businessCategoriesNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.businessCategoriesEdit]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageInfo]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageGallery]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageGalleryEdit]: true,
    [TYPE_SECTION_MESSAGE_MODEL.messageGalleryNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.menuTemplate]: true,
    [MARKETPLACE_SETTINGS_MODEL.menuTemplateNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.menuTemplateEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.marketplaceSettings]: true,
    [MARKETPLACE_SETTINGS_MODEL.cancelReason]: true,
    [MARKETPLACE_SETTINGS_MODEL.cancelReasonEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.cancelReasonNew]: true,
    [TYPE_SECTION_MODEL.operationTiming]: true,
    [TYPE_SECTION_MODEL.operationTimingEdit]: true,
    [TYPE_SECTION_MODEL.operationTimingNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.offers]: true,
    [MARKETPLACE_SETTINGS_MODEL.offersNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.offersEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.giftCard]: true,
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts]: true,
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit]: true,
    [TYPE_SECTION_MODEL.courierDeliveryFees]: true,
    [MARKETPLACE_SETTINGS_MODEL.referral]: true,
    [MARKETPLACE_SETTINGS_MODEL.loyaltySettingsRegistrationBonus]: true,
    [MARKETPLACE_SETTINGS_MODEL.loyaltySettingsCashback]: true,
    [MARKETPLACE_SETTINGS_MODEL.campaigns]: true,
    [MARKETPLACE_SETTINGS_MODEL.campaignsNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.campaignsEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.campaignsExecutions]: true,
    [MARKETPLACE_SETTINGS_MODEL.customerDeliveryPrice]: true,
    [MARKETPLACE_SETTINGS_MODEL.driverTip]: true,
    [MARKETPLACE_SETTINGS_MODEL.serviceFee]: true,
    [MARKETPLACE_SETTINGS_MODEL.listPromotion]: true,
    [MARKETPLACE_SETTINGS_MODEL.listPromotionNew]: true,
    [MARKETPLACE_SETTINGS_MODEL.listPromotionEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.deliveryZones]: true,
    [MARKETPLACE_SETTINGS_MODEL.deliveryZonesEdit]: true,
    [MARKETPLACE_SETTINGS_MODEL.deliveryZonesNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenu]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.translations]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsDeepLinkEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.reviewProductExtra]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideExtra]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideOption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.overrideSuboption]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuCategoryOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuSuboptionsOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuOptionsOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuExtraOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuProductOverride]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.gallery]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryNew]: true,
    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenu]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZonesNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuTranslations]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideExtra]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideOption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideSuboption]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryNew]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryEdit]: true,
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuTranslations]: true,
    [RECONCILIATIONS_MODEL.periodsEdit]: true,
    [RECONCILIATIONS_MODEL.periodsView]: true,
    [MARKETPLACE_SETTINGS_MODEL.appBuilder]: false,
  },
  [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkAction]]: {
      [DEPOTMENU_SETTING_CHECK]: true,
      [COPY_TELEGRAM_COMMAND]: true,
      USER_SHIFT_DOWNLOAD_PDF: true,
      [EXECUTE_COMPAIGN]: true,
  },
  [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
    [`${[TYPE_SECTION_MODEL.users]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.depots]}DeleteButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotusers]}DeleteButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotIntegrations]}DeleteButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]}DeleteButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.provider]}DeleteButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.telegram]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.operationMode]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.offers]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.regions]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.providers]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.deliveryProviders]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.serviceCities]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.searchShortcuts]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.marketplace]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.pages]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.fleet]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.namedPlaces]}DeleteButton`]: true,
    [`${[SHIFTS_MODEL.history]}DeleteButton`]: true,
    [`${[SHIFTS_MODEL.providers]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftUsers]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftDepots]}DeleteButton`]: false,
    [`${[MARKETPLACE_SETTINGS_MODEL.businessCategories]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.cancelReason]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.menuTemplate]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.listPromotion]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.deliveryZones]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]}DeleteButton`]: true,

    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]}DeleteButton`]: false,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]}DeleteButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]}DeleteButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.gallery]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MESSAGE_MODEL.messageGallery]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.operationTiming]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.users]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.menuTemplate]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.depots]}AddButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotusers]}AddButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotIntegrations]}AddButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters]}AddButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.provider]}AddButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.operationMode]}AddButton`]: true,
    [`${[TYPE_SECTION_DEPOT_MODEL.telegram]}DeleteButton`]: false,
    [`${[TYPE_SECTION_DEPOT_MODEL.operationMode]}DeleteButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.offers]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.regions]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.providers]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.deliveryProviders]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.serviceCities]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.operationTiming]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.marketplace]}AddButton`]: true,
    [`${[TYPE_SECTION_MESSAGE_MODEL.messageGallery]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.pages]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.searchShortcuts]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.namedPlaces]}AddButton`]: true,
    [`${[SHIFTS_MODEL.providers]}AddButton`]: false,
    [`${[SHIFTS_MODEL.history]}AddButton`]: true,
    [`${[SHIFTS_MODEL.currentShiftUsers]}AddButton`]: true,
    [`${[SHIFTS_MODEL.currentShiftDepots]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.businessCategories]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.cancelReason]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProducts]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.listPromotion]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.deliveryZones]}AddButton`]: true,
    [`${[MARKETPLACE_SETTINGS_MODEL.campaigns]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.reviewProductExtra]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.gallery]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.fleet]}AddButton`]: true,

    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery]}AddButton`]: true,

    [`${[RECONCILIATIONS_MODEL.settings]}AddButton`]: true,
    [`${[RECONCILIATIONS_MODEL.settings]}DeleteButton`]: true,
    [`${[RECONCILIATIONS_MODEL.periods]}AddButton`]: true,
    [`${[RECONCILIATIONS_MODEL.periods]}DeleteButton`]: true,


    courierShiftControl: true,
    grCode: true
  },
  [ROLES_MANAGER_TYPE.sectionItemEdit]: {

  }
}

export const configurationForDeliveryManagerWithDepot = {
  id: environment.USER_ROLES.deliveryManager,
  ...defaultRolesManagerConfig,
  [ROLES_MANAGER_TYPE.sectionItemEdit]: getDepotSectionItemEditConfiguration(),
  [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
    ...defaultRolesManagerConfig[ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]],
    [`${[TYPE_SECTION_MODEL.users]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.users]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.deliveryProviders]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.cloudPrinters]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.cloudPrinters]}AddButton`]: true,
    [`${[TYPE_SECTION_MODEL.deliveryProviders]}AddButton`]: true,
    courierShiftControl: true,
    grCode: true,
  },
  [ROLES_MANAGER_TYPE.menu]: {
    ...defaultRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
    [TYPE_SECTION_MODEL.deliveryProviders]: true,
    [TYPE_SECTION_MODEL.deliveryProvidersEdit]: true,
    [TYPE_SECTION_MODEL.deliveryProvidersNew]: true,
    [TYPE_SECTION_MODEL.userConfirmPassword]: true,
    [TYPE_SECTION_MODEL.personalSettings]: true,
    [TYPE_SECTION_MODEL.users]: true,
    [TYPE_SECTION_MODEL.usersNew]: true,
    [TYPE_SECTION_MODEL.usersEdit]: true,
    [TYPE_SECTION_MODEL.cloudPrinters]: true,
    [TYPE_SECTION_MODEL.cloudPrintersNew]: true,
    [TYPE_SECTION_MODEL.cloudPrintersEdit]: true,
    "/": true,
    "/orders": true,
    "/new-order": true,
    "/edit-order": true,
    "/edit-customer-order": true,
    "/assistance": true,
    "/menu": true,
    "/settings": true,
    "/marketplace-settings": true,
    "/info": true,
    "/reports": true,
    "/pendingorders": false,
  },
  [ROLES_MANAGER_TYPE.readonlyPage]: {
    ...getDepotReadonlySectionPages(),
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: false,
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: false,
  },
}
export const configurationForDeliveryManager = {
    id: environment.USER_ROLES.deliveryManager,
    ...initRolesManagerConfig,
    [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
      ...initRolesManagerConfig[ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]],
      courierShiftControl: true,
      grCode: true,
    },
    [ROLES_MANAGER_TYPE.menu]: {
      ...initRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
      [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
      [TYPE_SECTION_MODEL.userConfirmPassword]: true,
      [TYPE_SECTION_MODEL.personalSettings]: true,
      [TYPE_SECTION_MODEL.users]: true,
      [TYPE_SECTION_MODEL.usersNew]: true,
      [TYPE_SECTION_MODEL.usersEdit]: true,
      [TYPE_SECTION_MODEL.cloudPrinters]: true,
      [TYPE_SECTION_MODEL.cloudPrintersNew]: true,
      [TYPE_SECTION_MODEL.cloudPrintersEdit]: true,
      "/": true,
      "/orders": true,
      "/new-order": true,
      "/edit-order": true,
      "/edit-customer-order": true,
      "/assistance": true,
      "/menu": true,
      "/settings": true,
      "/marketplace-settings": false,
      "/info": true,
      "/reports": true,
      "/pendingorders": false,
    },
}


export function getDepotSectionItemEditConfiguration() {
  const isCanEdit = !!getRestaurantConfigPropValue("isCanEditBusinessInfo");

  return {
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: {
        "header": isCanEdit,
        "name": isCanEdit,
        "message": isCanEdit,
        "about": isCanEdit,
        "kosher": isCanEdit,
        "always_deliver": isCanEdit,
        "email": isCanEdit,
        "description": isCanEdit,
        "phone": isCanEdit,
        "minimum": isCanEdit,
        "zipcode": isCanEdit,
        "address_notes": isCanEdit,
        "address": isCanEdit,
        "enabled": isCanEdit,
        "message_enabled": isCanEdit,
        "disable_scheduled_orders": isCanEdit,
        "disable_takeaway_scheduled_orders": isCanEdit,
        "allow_gift": isCanEdit,
        "minimum_takeaway": isCanEdit,
    },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: {
        "images": isCanEdit,
        "name": isCanEdit,
        "price": isCanEdit,
        "description": isCanEdit,
        "upselling": isCanEdit,
        "sku": isCanEdit,
        "price_before_discount": isCanEdit,
        "max": isCanEdit,
        "vegan": isCanEdit,
        "gluten_free": isCanEdit,
        "allow_comments": isCanEdit,
        "vegetarian": isCanEdit,
        "categoryIngredients": isCanEdit,
        "spicy": isCanEdit,
        "categoryExtras": isCanEdit,
        "enable_schedule": isCanEdit,
    },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: {
      "name": isCanEdit,
      "description": isCanEdit
    },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: {
      "name": isCanEdit,
      "description": isCanEdit
    },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: {
      "name": isCanEdit,
      "description": isCanEdit,
      "price": isCanEdit,
      "min": isCanEdit,
      "max": isCanEdit,
      "sku": isCanEdit,
      "respect_to": isCanEdit,
      "with_half_option": isCanEdit,
      "allow_suboption_quantity": isCanEdit,
      "limit_suboptions_by_max": isCanEdit,
      "conditioned": isCanEdit,
    },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: {
      "name": isCanEdit,
      "description": isCanEdit,
      "price": isCanEdit,
      "min": isCanEdit,
      "max": isCanEdit,
      "sku": isCanEdit,
      "respect_to": isCanEdit,
      "with_half_option": isCanEdit,
      "allow_suboption_quantity": isCanEdit,
      "limit_suboptions_by_max": isCanEdit,
      "reduce_price_for_half_option": isCanEdit,
      "conditioned": isCanEdit,
  },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: {
      "name": isCanEdit,
      "description": isCanEdit,
      "price": isCanEdit,
      "min": isCanEdit,
      "max": isCanEdit,
      "sku": isCanEdit,
      "respect_to": isCanEdit,
      "with_half_option": isCanEdit,
      "allow_suboption_quantity": isCanEdit,
      "reduce_price_for_half_option": isCanEdit,
      "limit_suboptions_by_max": isCanEdit,
      "conditioned": isCanEdit,
  },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: {
      "name": isCanEdit
    },
    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: {
      "name": isCanEdit,
      "type": isCanEdit,
      "rate_type": isCanEdit,
      "rate": isCanEdit,
      "minimum": isCanEdit,
      "limit": isCanEdit,
      "start": isCanEdit,
      "end": isCanEdit,
      "coupon": isCanEdit,
      "single_user": isCanEdit,
      "order_type":isCanEdit,
      "enable_schedule": isCanEdit,
      "discount_target": isCanEdit,
      "calculation_base": isCanEdit,
      "show_in_marketplace": isCanEdit,
      "address": isCanEdit,
      "product_id": isCanEdit,
      "schedule": isCanEdit,
    },



    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: {
      "header": isCanEdit,
      "name": isCanEdit,
      "message": isCanEdit,
      "about": isCanEdit,
      "kosher": isCanEdit,
      "always_deliver": isCanEdit,
      "email": isCanEdit,
      "description": isCanEdit,
      "phone": isCanEdit,
      "minimum": isCanEdit,
      "zipcode": isCanEdit,
      "address_notes": isCanEdit,
      "address": isCanEdit,
      "disable_cutlery":isCanEdit,
      "cutlery_sku":isCanEdit,
      "general_item_sku": isCanEdit,
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: {
      "images": isCanEdit,
      "name": isCanEdit,
      "price": isCanEdit,
      "description": isCanEdit,
      "upselling": isCanEdit,
      "sku": isCanEdit,
      "price_before_discount": isCanEdit,
      "max": isCanEdit,
      "vegan": isCanEdit,
      "gluten_free": isCanEdit,
      "allow_comments": isCanEdit,
      "vegetarian": isCanEdit,
      "categoryIngredients": isCanEdit,
      "spicy": isCanEdit,
      "categoryExtras": isCanEdit,
      "enable_schedule": isCanEdit,
      "enabled": isCanEdit,
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: {
      "name": isCanEdit,
      "description": isCanEdit
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: {
      "name": isCanEdit,
      "description": isCanEdit
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: {
      "name": isCanEdit,
      "description": isCanEdit,
      "price": isCanEdit,
      "min": isCanEdit,
      "max": isCanEdit,
      "sku": isCanEdit,
      "respect_to": isCanEdit,
      "with_half_option": isCanEdit,
      "allow_suboption_quantity": isCanEdit,
      "limit_suboptions_by_max": isCanEdit,
      "conditioned": isCanEdit,
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: {
      "name": isCanEdit,
      "description": isCanEdit,
      "price": isCanEdit,
      "min": isCanEdit,
      "max": isCanEdit,
      "sku": isCanEdit,
      "respect_to": isCanEdit,
      "with_half_option": isCanEdit,
      "allow_suboption_quantity": isCanEdit,
      "limit_suboptions_by_max": isCanEdit,
      "reduce_price_for_half_option": isCanEdit,
      "conditioned": isCanEdit,
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: {
      "name": isCanEdit,
      "description": isCanEdit,
      "price": isCanEdit,
      "min": isCanEdit,
      "max": isCanEdit,
      "sku": isCanEdit,
      "respect_to": isCanEdit,
      "with_half_option": isCanEdit,
      "allow_suboption_quantity": isCanEdit,
      "limit_suboptions_by_max": isCanEdit,
      "conditioned": isCanEdit,
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: {
      "name": isCanEdit
    },
    [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: {
      "name": isCanEdit,
      "type": isCanEdit,
      "rate_type": isCanEdit,
      "rate": isCanEdit,
      "minimum": isCanEdit,
      "limit": isCanEdit,
      "start": isCanEdit,
      "end": isCanEdit,
      "coupon": isCanEdit,
      "single_user": isCanEdit,
      "order_type":isCanEdit,
      "enable_schedule": isCanEdit
    }
  }
}

export function getDepotLinksConfiguration() {
  const isCanEdit = !!getRestaurantConfigPropValue("isCanEditBusinessInfo");

  return {
    [`${ORDERING_MENU_TYPE_SECTION_MODEL.gallery}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.providers]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.history]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftUsers]}DeleteButton`]: false,
    [`${[SHIFTS_MODEL.currentShiftDepots]}DeleteButton`]: false,
    [`${[TYPE_SECTION_MODEL.cloudPrinters]}DeleteButton`]: true,
    [`${[TYPE_SECTION_MODEL.cloudPrinters]}AddButton`]: true,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]}AddButton`]: isCanEdit,
    [`${[ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]}AddButton`]: isCanEdit,

    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}DeleteButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]}AddButton`]: true,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption]}AddButton`]: isCanEdit,
    [`${[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]}AddButton`]: isCanEdit
  }
}

export function getPendingOrdersIsDesktop() {
  return window.innerWidth >= 1600;
}

export function getPendingOrdersIsIpad() {
  return document.documentElement.clientWidth > 600 && document.documentElement.clientWidth < 1025 && document.documentElement.clientWidth < document.documentElement.clientHeight;
}

export function getIsIOS(){
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export const defaultDepotColors = [
  "#191919",
  "#686868",
  "#3f3f3f",
  "#545454",
  "#707070",
  "#6a6a6a",
  "#808080",
  "#949494",
  "#a9a9a9",
  "#bdbebd",
  "#d3d3d3",
  "#e9e9e9"
];

export const shiftCourierErrorMessage = {
  [COURIER_STATE.startShift]: "couriers.START_SHIFT_ERROR",
  [COURIER_STATE.startBackup]: "couriers.START_BACKUP_ERROR",
  [COURIER_STATE.endShift]: "couriers.END_SHIFT_ERROR",
  [COURIER_STATE.endBackup]: "couriers.END_BACKUP_ERROR",
  [COURIER_STATE.cancelShift]: "couriers.CANCEL_ERROR",
  [COURIER_STATE.finishShift]: "couriers.FINISH_ERROR",
  [COURIER_STATE.goAwayShift]: "couriers.GOAWAY_ERROR"
}

export const shiftCourierTooltipText = {
  [COURIER_STATE.startShift]: "couriers.START_SHIFT_TOOLTIP",
  [COURIER_STATE.startBackup]: "couriers.START_BACKUP_TOOLTIP",
  [COURIER_STATE.endShift]: "couriers.END_SHIFT_TOOLTIP",
  [COURIER_STATE.endBackup]: "couriers.END_BACKUP_TOOLTIP",
  [COURIER_STATE.cancelShift]: "couriers.CANCEL_TOOLTIP",
  [COURIER_STATE.finishShift]: "couriers.FINISH_TOOLTIP",
  [COURIER_STATE.goAwayShift]: "couriers.GOAWAY_TOOLTIP"
}

export const pusherEvents = {
  courier_lock_update: PUSHER_COURIER_LOCK_UPDATE,
  group_update: PUSHER_GROUP_UPDATE,
  new_order: PUSHER_NEW_ORDER,
  order_state_update: PUSHER_ORDER_STATE_UPDATE,
  update_order: PUSHER_UPDATE_ORDER,
  cycle_state_update: PUSHER_CYCLE_STATE_UPDATE,
  cycle_dispatched: PUSHER_CYCLE_DISPATCHED,
  cycle_reorder: PUSHER_CYCLE_REORDER,
  cycle_route_update: PUSHER_CYCLE_ROUTE_UPDATE,
  courier_shift_created: PUSHER_COURIER_SHIFT_CREATED,
  manual_associate_update: PUSHER_MANUAL_ASSOCIATE_UPDATE,
  group_reorder: PUSHER_GROUP_REORDER,
  manual_unassociate_update: PUSHER_MANUAL_UNASSOCIATE_UPDATE,
  set_away: PUSHER_SET_AWAY,
  manual_unassociate_update_list: PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST,
  user_updated: PUSHER_USER_UPDATED,
  user_deleted: PUSHER_USER_DELETED,
  resaurant_state_update: PUSHER_RESTAURANT_STATE_UPDATE,
  group_lock_update: PUSHER_GROUP_LOCK_UPDATE,
  assistance_update: PUSHER_ASSISTANCE_UPDATE,
  depot_cycle_refresh: PUSHER_DEPOT_CYCLE_REFRESH,
  notification_added: NOTIFICATION_ADD,
  notification_deleted: NOTIFICATION_DELETE,
  notification_service_restart: NOTIFICATIONS_RESTART,
  consumer_order_arrived: PUSHER_CONSUMER_ORDER_ARRIVED,
  depots_services_refresh: PUSHER_DEPOT_SERVICES_REFRESH
};

export const excludedUrls = ["/login", "", "/square/oauth"];


export const defaultInitConfig = {
  activeState: false,
  consumerActiveOrders: false,
  consumerOrderMenu: false,
  notifications: false,
  pendingPreOrders: false,
  restaurant: false,
  users: false,
  externalProviders: true,
  authSupportChat: false,
}

export const mainModuleInitConfig = {
  ...defaultInitConfig,
  activeState: true,
  notifications: true,
  restaurant: true,
};

export const restaurateurModuleInitConfig = {
  ...defaultInitConfig,
  activeState: true,
  consumerOrderMenu: true,
  consumerActiveOrders: true,
  pendingPreOrders: true,
  restaurant: true,
  users: true,
  externalProviders: true
};

export const groupUpdateConfig = {
  ...defaultInitConfig,
  activeState: true
}

export const depotCycleRefreshConfig = {
  ...defaultInitConfig,
  activeState: true,
  consumerOrders: true,
  pendingPreOrders: true,
}

export const controlCenterUpdateConfig = {
  ...defaultInitConfig,
  activeState: true
}

export const accountTopNotificationColor = {
  color: "#2F7CF6",
  secondColor: "#1D68DF"
};

export const defaultRoleSelectorConfig = {
  [USER_ROLES.controlCenterManager]: true,
  [USER_ROLES.deliveryManager]: true,
  [USER_ROLES.courier]: true,
  [USER_ROLES.supervisor]: true,
  [USER_ROLES.supportOperator]: true
}

export const REQUEST_METHOD = {
  get: "get",
  post: "post",
  put: "put"
}

export const EXCLUDED_CHECK_ERROR_APIS = [
  `${environment.backendAPI}orders/place`,
  `${environment.authOauth}oauth2/token`
];

export const DND_TYPES = {
  group_bar_order: "group_bar_order",
  groups_bar_group: "groups_bar_group",
  groups_bar_order: "groups_bar_order"
}

export const includedMarkerTypesToCheck = [
  TYPE_MARKER.courier,
  TYPE_MARKER.place,
  TYPE_MARKER.restaurant,
  TYPE_MARKER.serviceCity,
];

export const ADMIN_ORDERS_TABS = {
  orders: "orders",
  chat: "chat",
  reviews: "reviews",
  customers: "customers",
  sales: "sales",
}

export const currentShiftSwitchModeItems = [
  {
    title: "shifts.CURRENT_SHIFT_MODE_USER", link: SHIFTS_MODEL.currentShiftUsers
  },
  {
    title: "shifts.CURRENT_SHIFT_MODE_DEPOT", link: SHIFTS_MODEL.currentShiftDepots
  }
]

export const rates = [1,2,3,4,5];

export const DEFAULT_PERIOD_STATE = {
  [PERIOD_TYPE.today]: false,
  [PERIOD_TYPE.today]: false,
  [PERIOD_TYPE.week]: false,
  [PERIOD_TYPE.month]: false,
  [PERIOD_TYPE.year]: false
};

export const getDefaultReviewsFilter = () => ({
  start: null,
  end: null,
  deliveryRating: null,
  rating: null,
  periodFilter: DEFAULT_PERIOD_STATE,
  ...getPeriodByType(PERIOD_TYPE.today)
});

export const getDefaultCustomersFilter = () => ({
  search: null
});

export const getDefaultSalesFilter = () => ({
  start: null,
  end: null,
  periodFilter: DEFAULT_PERIOD_STATE,
  ...getPeriodByType(PERIOD_TYPE.today)
});

export const getDefaultAdminHistoryFilter = () => ({
  search: "",
  ...getPeriodByType(PERIOD_TYPE.today)
});

export const CUSTOMER_WALLET_OPERATION_TYPE = {
  bonusCardsList: "bonusCardsList",
  bonusCard: "bonusCard",
  accrue: "accrue",
  spend: "spend",
  block: "block"
};

export const SALES_TYPE = {
  marketplace: "marketplace",
  product: "product",
  business: "business",
  suboption: "suboption",
  template: "template",
};

export const defaultCustomerServiceSalesItem = () => ({
  id: "ROOT", type: SALES_TYPE.marketplace, name: i18n.t("sales.MARKETPLACE")
});

export const getDefaultBatchRow = (id) => ({
  temporaryId: id,
  name: "",
  business_id: "",
  phone_valid: {
    is_valid: false,
    error_message: "",
    original_value: "",
    value: {
      InternationalNumber: "",
      NationalFormatedNumber: "",
      CountryCode: "",
      CountryPrefix: ""
    }
  },
  address_valid: {
    is_valid: false,
    error_message: "",
    original_city: "",
    original_street: "",
    original_notes: "",
    address: {
      city: "",
      street: "",
      entrance: "",
      postal: "",
      state: "",
      country: "",
      notes: "",
      organization: "",
      placeId: "",
      flat: "",
      floor: ""
    },
    location: {
      lat: 0,
      lng: 0
    }
  },
  datetime: {
    is_valid: false,
    error_message: "",
    original_date: "",
    original_time: "",
    original_datetime: new Date().toISOString(),
  },
  delivery_type: 1,
});

export const getDeliveryTypes = () => ([
  { key: DELIVERY_TYPE.delivery, value: i18n.t('orders.summary.DELIVERY_LABEL')},
  { key: DELIVERY_TYPE.takeAway, value: i18n.t('orders.summary.TAKEAWAY_LABEL') },
]);
