import {
    ADD_NEW_BILLING_ADDRESS,
    ADD_NEW_BILLING_CARD, BILLING_INFO_UPDATE, DELETE_BILLING_ADDRESS,
    DELETE_BILLING_CARD, SET_BILLING_ADDRESSES,
    SET_BILLING_CARDS,
    SET_BILLING_IS_FETCH,
} from "./billing-actions";
import {REMOVE_ALL_DATA} from "../common-actions.js";

const initState = {
    cards: null,
    addresses: null,
    isFetch: false,
    creditCards: [],
};

export default function billing(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA: {
            return initState;
        }
        case BILLING_INFO_UPDATE: {
            return {
                ...state,
                ...action.data
            }
        }
        case SET_BILLING_CARDS: {
            return {
                ...state,
                creditCards: action.payload
            }
        }
        case DELETE_BILLING_CARD: {
            return {
                ...state,
                creditCards: state.creditCards.filter(p => p.id !== action.payload.id)
            }
        }
        case ADD_NEW_BILLING_CARD: {
            return {
                ...state,
                creditCards: (state.creditCards || []).map(p => ({...p, default: false})).concat(action.payload)
            }
        }
        case SET_BILLING_ADDRESSES: {
            return {
                ...state,
                addresses: action.payload
            }
        }
        case DELETE_BILLING_ADDRESS: {
            return {
                ...state,
                addresses: state.addresses.filter(p => p.id !== action.payload.id)
            }
        }
        case ADD_NEW_BILLING_ADDRESS: {
            return {
                ...state,
                addresses: (state.addresses || []).concat(action.payload)
            }
        }

        case SET_BILLING_IS_FETCH: {
            return {
                ...state,
                isFetch: action.payload
            }
        }
        default:
            return state;
    }
}
