
import SectionModel from '../../models/sectionModel';

import {
    DEPOT_BUSINESS_DEEP_LINK,
    DEPOT_BUSINESS_DEEP_LINK_CREATE,
    DEPOT_BUSINESS_PRODUCT_DEEP_LINK,
    DEPOT_BUSINESS_PRODUCT_DEEP_LINK_CREATE,
    MARKETPLACE_DEEP_LINK_CREATE,
    ORDERING_MENU_GET,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    ORDERING_MENU_TYPE_SECTION_MODEL,
    TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import {createControlModel, filtrateControlByDepotRole} from '../controlModelFactory';
import { getHistoryLocationParams } from '../../utils/routesHelper';
import { getBusinessName, getDepotNameById, getNameForProductsByIds, isMobileWidth } from '../restaurant';

export function createDepotBusinessDeepLinkEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "",
                accessor: "id",
                clickAction: DEPOT_BUSINESS_DEEP_LINK_CREATE,
                label: "settings.deepLink.CREATE",
                type: CONTROL_TYPE.button,
                isShow: item => !item.link,
            },
            {
                title: "",
                accessor: "link",
                type: CONTROL_TYPE.deepLinkStat,
                isEditable: true,
                isFillContent: true,
                isShow: item => !!item.link,
            }
        ],
        initNewItemModel: item => {
            return item;
        },
        label: "settings.deepLink.DEPOT_LABEL",
        breadcrumbs: [
            createBreadcrumbModel({
                label: "settings.map.DEPOT_SETTINGS_LABEL"
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                }
            })
        ],
        controls: filtrateControlByDepotRole([
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ]),
        actionName: DEPOT_BUSINESS_DEEP_LINK,
        actionLoad: ORDERING_MENU_GET,
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
        stayAfterSave: true,
        mappingLambda: state => {
            return state.restaurant.data.businessMenu || [];
        },
    });
}

export function createDepotBusinessProductDeepLinkEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "",
                accessor: "id",
                clickAction: DEPOT_BUSINESS_PRODUCT_DEEP_LINK_CREATE,
                label: "settings.deepLink.CREATE",
                type: CONTROL_TYPE.button,
                isShow: item => !item.link,
            },
            {
                title: "",
                accessor: "link",
                type: CONTROL_TYPE.deepLinkStat,
                isEditable: true,
                isFillContent: true,
                isShow: item => !!item.link,
            }
        ],
        initNewItemModel: item => {
            return item;
        },
        label: "settings.deepLink.LABEL",
        breadcrumbs: [
            createBreadcrumbModel({
                label: "settings.map.DEPOT_SETTINGS_LABEL"
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId } = getHistoryLocationParams(window.location.search);
                    return getBusinessName(parentId);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameForProductsByIds(parentId, id);
                },
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id, subId } = getHistoryLocationParams(window.location.search);
                    return getNameForProductsByIds(parentId, id, subId);
                },
            })
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        actionName: DEPOT_BUSINESS_PRODUCT_DEEP_LINK,
        actionLoad: ORDERING_MENU_GET,
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
        stayAfterSave: true,
        mappingLambda: state => {
            const businessMenu = state.restaurant.data.businessMenu || {};
            return (businessMenu.categories || []).flatMap(c => c.products || []);
        },
    });
}

export function createMarketplaceDeepLinkEditSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "",
                accessor: "id",
                clickAction: MARKETPLACE_DEEP_LINK_CREATE,
                label: "settings.deepLink.CREATE",
                type: CONTROL_TYPE.button,
                isShow: item => !item.marketplaceLink,
            },
            {
                title: "",
                accessor: "marketplaceLink",
                type: CONTROL_TYPE.deepLinkStat,
                isEditable: true,
                isFillContent: true,
                isShow: item => !!item.marketplaceLink,
            }
        ],
        initNewItemModel: item => {
            return item;
        },
        label: "settings.deepLink.LABEL",
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            })
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth
            }),
        ],
        saveControlsConfig: {
            cancel: { title: "basic.CANCEL_BUTTON", action: ()=> {}, isInit: true }
        },
        actionName: DEPOT_BUSINESS_PRODUCT_DEEP_LINK,
        typeAction: args.typeAction,
        typeModel: MARKETPLACE_SETTINGS_MODEL.deepLink,
        stayAfterSave: true,
        mappingLambda: state => state.restaurant.data.paymentsConfiguration,
    });
}
